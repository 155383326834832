var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import BootstrapTooltop from './BootstrapTooltip';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  height: 12px;\n  top: 20px;\n  left: 0px;\n  right: 0;\n  z-index: 10;\n  border-radius: 20px;\n"], ["\n  position: absolute;\n  height: 12px;\n  top: 20px;\n  left: 0px;\n  right: 0;\n  z-index: 10;\n  border-radius: 20px;\n"])));
var OverlayBar = function (_a) {
    var color = _a.color, info = _a.info, value = _a.value;
    return (React.createElement(BootstrapTooltop, { title: info, placement: "right-end" },
        React.createElement(Root, { style: { width: value, backgroundColor: color } })));
};
export default OverlayBar;
var templateObject_1;
