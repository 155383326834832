var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Tooltip as MuiTooltip, withStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { color, fontSize, fontWeight } from 'styles';
import InfoIcon from 'assets/information.svg';
var BlackTooltip = withStyles(function () { return ({
    arrow: {
        color: color.black,
    },
    tooltip: {
        backgroundColor: color.black,
        color: color.white,
        fontSize: fontSize.fs14,
        fontWeight: fontWeight.light,
        lineHeight: 1.43,
        padding: '15px',
    },
}); })(MuiTooltip);
var InfoCircleWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  cursor: pointer;\n\n  svg {\n    fill: ", ";\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  cursor: pointer;\n\n  svg {\n    fill: ", ";\n  }\n"])), color.green);
var Tooltip = function (_a) {
    var text = _a.text;
    return (React.createElement(BlackTooltip, { title: text, arrow: true },
        React.createElement(InfoCircleWrapper, null,
            React.createElement(InfoIcon, null))));
};
export default Tooltip;
var templateObject_1;
