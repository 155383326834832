var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  iframe {\n    height: 100vh;\n    border: 0;\n  }\n"], ["\n  iframe {\n    height: 100vh;\n    border: 0;\n  }\n"])));
var SagePayIframe = function (_a) {
    var sagePayLink = _a.sagePayLink;
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(Iframe, { url: sagePayLink, width: "100%" })))));
};
export default SagePayIframe;
var templateObject_1;
