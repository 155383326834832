var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import content from './gwContent.json';
import YearOne from './YearOne';
import YearTwo from './YearTwo';
import YearThree from './YearThree';
import YearFour from './YearFour';
import YearFive from './YearFive';
import YearSixPlus from './YearSixPlus';
import { Dialog, Slide } from '@material-ui/core';
import Close from 'assets/close.svg';
import moment from 'moment';
var ncdAverageDiscount = [
    {
        year: '1',
        percent: '32%',
    },
    {
        year: '2',
        percent: '38%',
    },
    {
        year: '3',
        percent: '40%',
    },
    {
        year: '4',
        percent: '42%',
    },
    {
        year: '5',
        percent: '43%',
    },
    {
        year: '6',
        percent: '45%',
    },
    {
        year: '7',
        percent: '47%',
    },
    {
        year: '8',
        percent: '48%',
    },
    {
        year: '9',
        percent: '49%',
    },
    {
        year: '10+',
        percent: '50%',
    },
];
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n\n  table {\n    border: 1px solid black;\n    border-spacing: 0;\n    margin-bottom: 10px;\n  }\n\n  th {\n    border: 1px solid black;\n    background-color: #4a0;\n    color: white;\n    font-weight: 400;\n  }\n\n  td {\n    border: 1px solid black;\n  }\n\n  th > p > span {\n    border-bottom: 1px solid;\n  }\n"], ["\n  padding: 20px;\n\n  table {\n    border: 1px solid black;\n    border-spacing: 0;\n    margin-bottom: 10px;\n  }\n\n  th {\n    border: 1px solid black;\n    background-color: #4a0;\n    color: white;\n    font-weight: 400;\n  }\n\n  td {\n    border: 1px solid black;\n  }\n\n  th > p > span {\n    border-bottom: 1px solid;\n  }\n"])));
var Note = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: solid 1px black;\n  margin: 20px 0;\n"], ["\n  border: solid 1px black;\n  margin: 20px 0;\n"])));
var CloseWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n\n  svg {\n    fill: #007f49;\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n\n  svg {\n    fill: #007f49;\n    cursor: pointer;\n  }\n"])));
var Transition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Slide, __assign({ direction: "up", ref: ref }, props));
});
var PNCDAppendix = function (_a) {
    var ncdYears = _a.ncdYears, open = _a.open, setOpen = _a.setOpen, startDate = _a.startDate;
    var uninsuredBulletpoint = moment({ day: 7, month: 6, year: 2022 });
    var defactoDate = moment('20230601', 'YYYYMMDD');
    return (React.createElement(Dialog, { maxWidth: "lg", open: open, TransitionComponent: Transition },
        React.createElement(Root, null,
            React.createElement(CloseWrapper, null,
                React.createElement(Close, { onClick: function () { return setOpen(false); } })),
            React.createElement("div", null,
                React.createElement("h3", null, content['and.quoteandbind.pm.views.quote.pncdAppendix.title']),
                React.createElement("p", null, content['and.quoteandbind.pm.views.quote.pncdAppendix.info'])),
            React.createElement("div", null,
                React.createElement("h3", null, content['and.quoteandbind.pm.views.quote.pncdAppendix.tableone.title']),
                React.createElement("table", { width: "100%" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { align: "left" }, content['and.quoteandbind.pm.views.quote.pncdAppendix.numberofncd.title']),
                            React.createElement("th", { align: "left" }, content['and.quoteandbind.pm.views.quote.pncdAppendix.averagencd.title']))),
                    React.createElement("tbody", null, ncdAverageDiscount.map(function (item) { return (React.createElement("tr", { key: item.year },
                        React.createElement("td", null, item.year),
                        React.createElement("td", null, item.percent))); })))),
            React.createElement("div", null,
                moment(startDate).isSameOrAfter(defactoDate) ? (React.createElement("h3", null, content['and.quoteandbind.pm.views.quote.pncdAppendix.tabletwo.title.postdefacto'])) : React.createElement("h3", null, content['and.quoteandbind.pm.views.quote.pncdAppendix.tabletwo.title']),
                React.createElement(ReactMarkdown, { escapeHtml: false, source: content['and.quoteandbind.pm.view.quote.pncdAppendix.tabletwo.info'] })),
            React.createElement("div", null,
                React.createElement("h3", null, content['and.quoteandbind.pm.views.quote.pncdAppendix.tabletwo.info2.itb'])),
            ncdYears === 1 && React.createElement(YearOne, { content: content }),
            ncdYears === 2 && React.createElement(YearTwo, { content: content }),
            ncdYears === 3 && React.createElement(YearThree, { content: content }),
            ncdYears === 4 && React.createElement(YearFour, { content: content }),
            ncdYears === 5 && React.createElement(YearFive, { content: content }),
            ncdYears >= 6 && React.createElement(YearSixPlus, { content: content }),
            React.createElement(Note, null,
                React.createElement("p", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.1'])),
            React.createElement("div", null,
                React.createElement("span", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.2']),
                React.createElement("ul", null,
                    React.createElement("li", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.3']),
                    React.createElement("li", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.4']),
                    React.createElement("li", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.5']),
                    moment(startDate).isSameOrAfter(uninsuredBulletpoint) && (React.createElement("li", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.9'])),
                    moment(startDate).isSameOrAfter(defactoDate) && (React.createElement("li", null, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.defacto'])))),
            React.createElement("div", null,
                React.createElement("p", null,
                    content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.7'],
                    ".")),
            React.createElement("div", null,
                React.createElement("h3", { style: { fontWeight: 400 } }, content['and.quoteandbind.pm.views.quote.AddOns.PNCD.footer.8'])))));
};
export default PNCDAppendix;
var templateObject_1, templateObject_2, templateObject_3;
