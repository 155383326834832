import { action } from '@storybook/addon-actions';
import { boolean } from '@storybook/addon-knobs';
import { storiesOf } from '@storybook/react';
import React from 'react';
import ExcessSection from './ExcessSection';
import ExcessTable from './ExcessTable';
import ExcessTableMobile from './ExcessTableMobile';
import OtherExcess from './OtherExcess';
export var tableHeaders = [
    {
        label: 'Driver(s)',
        value: 'driver',
    },
    {
        label: 'Compulsory',
        value: 'compulsoryExcess',
        info: 'compulsory excess',
    },
    {
        label: 'Voluntary',
        value: 'voluntaryExcess',
        info: 'voluntary excess',
    },
    {
        label: 'Total',
        value: 'totalExcess',
    },
];
export var otherExcessTitle = 'Other compulsory excesses';
var excesses = [
    {
        driver: 'Mr Joe Mdtomlmriq',
        compulsoryExcess: 100,
        voluntaryExcess: 10,
        totalExcess: 110,
    },
    {
        driver: 'Miss Joe Bloggs',
        compulsoryExcess: 100,
        voluntaryExcess: 10,
        totalExcess: 110,
    },
    {
        driver: 'Mrs Sally Mdtomlmriq',
        compulsoryExcess: 100,
        voluntaryExcess: 10,
        totalExcess: 110,
    },
];
var otherExcesses = [
    {
        name: 'Windscreen replacement',
        amount: '£100',
    },
    {
        name: 'Windscreen repair',
        amount: '£25',
    },
];
export var data = { excesses: excesses, otherExcesses: otherExcesses };
export var content = {
    title: 'My Excess',
    intro: 'Excess is the amount you will need to pay in the event of a claim for damage to your car.',
    tableHeaders: tableHeaders,
    otherExcessTitle: otherExcessTitle,
};
export var selectOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
];
storiesOf('ITB Policy|organisms/Excess Section', module)
    .add('default', function () { return (React.createElement(ExcessSection, { data: data, content: content, expandable: boolean('dropdown', false), selectOptions: selectOptions, onSelectExcess: function (value) { return action('onSelectExcess')(value); } })); })
    .add('other excess', function () { return React.createElement(OtherExcess, { title: otherExcessTitle, otherExcesses: otherExcesses }); })
    .add('excess table', function () { return React.createElement(ExcessTable, { headers: tableHeaders, excesses: excesses }); })
    .add('excess table mobile', function () { return React.createElement(ExcessTableMobile, { headers: tableHeaders, excesses: excesses }); });
