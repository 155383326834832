var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { TagManagerArgs } from 'analytics';
import Button from 'components/atoms/Button';
import Title from 'components/atoms/Title';
import React from 'react';
import styled from 'styled-components';
import { Group } from '../Form';
import { RenewalDriversDetails } from './RenewalDriversDetails';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var GroupOverride = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
var ListTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 20px;\n  font-weight: 400;\n"], ["\n  margin-top: 0;\n  margin-bottom: 20px;\n  font-weight: 400;\n"])));
var RenewalDrivers = function (_a) {
    var content = _a.content, drivers = _a.drivers, newDrivers = _a.newDrivers, buttonClick = _a.buttonClick, numberOfDriversText = _a.numberOfDriversText, _b = _a.disableButton, disableButton = _b === void 0 ? false : _b, onRemoveAddedDriver = _a.onRemoveAddedDriver, onEditAddedDriver = _a.onEditAddedDriver, onRemoveExistingDriver = _a.onRemoveExistingDriver, onEditExistingDriver = _a.onEditExistingDriver, cancelRemoveDriver = _a.cancelRemoveDriver, onSetAdditionalDriverToMain = _a.onSetAdditionalDriverToMain, onCancelAdditionalDriverToMain = _a.onCancelAdditionalDriverToMain;
    var rows = content.list[0].listItems;
    var replacePlacholderText = function (value, replaceValue) {
        var placeholders = {
            NUMBER_OF_DRIVERS: replaceValue,
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/NUMBER_OF_DRIVERS/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null,
            React.createElement(Title, null, replacePlacholderText(content.title, numberOfDriversText)),
            React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                    buttonClick();
                    TagManagerArgs('Button', window.location.pathname + " - Renewal policy", 'Add a driver');
                }, disabled: disableButton }, "Add driver")),
        React.createElement(Group, null,
            drivers && drivers.length > 0 && (React.createElement(GroupOverride, null,
                React.createElement(ListTitle, null, "Additional drivers"),
                drivers.map(function (item, index) {
                    return (React.createElement(RenewalDriversDetails, { key: item.firstName + " " + item.lastName, data: item, rows: rows, type: "driver", showRemoveButton: true, driverIndex: index, onRemoveDriver: onRemoveExistingDriver, onEditAddedDriver: onEditExistingDriver, status: item.updateOrRemove, cancelRemoveDriver: cancelRemoveDriver, setDriverToMain: onSetAdditionalDriverToMain, cancelAdditionalDriverToMain: onCancelAdditionalDriverToMain, chosenAsMain: item.mainDriver }));
                }))),
            newDrivers && newDrivers.length > 0 && (React.createElement(GroupOverride, null,
                React.createElement(ListTitle, null, "New"),
                newDrivers.map(function (item, index) {
                    return (React.createElement(RenewalDriversDetails, { key: item.obj.firstName + " " + item.obj.lastName, data: item.obj, rows: rows, type: "driver", showRemoveButton: true, driverIndex: index, onRemoveDriver: onRemoveAddedDriver, onEditAddedDriver: onEditAddedDriver }));
                }))))));
};
export default RenewalDrivers;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
