import axios from 'axios';
import { handlePromise, POLICY_BASE_URL } from './api';
import bindPaymentResponse from './mock/bindPayment.json';
/**
 * Validate credit card:
 *
 * This validates the saved card that was used for an orginal Annual payment
 * It also validates the number of remaining payments for Monthly installments
 * The job variable is made up of the job number found in a quote and the api endpoint
 * ..../${jobNumber}/validatecard
 * ..../${jobNumber}/getremaininginstalments
 *
 */
export var getPaymentValidation = function (job, validationEndpoint, token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: true,
            });
        });
    }
    return handlePromise(axios.get(POLICY_BASE_URL + "/policies/" + job + "/" + validationEndpoint, {
        headers: { 'x-authorization': token, 'Content-Type': 'application/json' },
    }));
};
/**
 * bind payment
 */
export var submitPayment = function (jobId, data, token, topupMilesPath) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: bindPaymentResponse,
            });
        });
    }
    return handlePromise(axios({
        url: POLICY_BASE_URL + "/policies" + (topupMilesPath || '') + "/" + jobId + "/bindpayment",
        method: 'POST',
        data: JSON.stringify(data),
        headers: { 'x-authorization': token, 'Content-Type': 'application/json' },
    }));
};
/**
 * Add new card payment
 */
export var getSagePay = function (jobId, data, token, topupMilesPath) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: 'https://test.sagepay.com/gateway/service/cardselection?vpstxid={54EA050A-7986-7F57-36D2-8A7827CA2C36}',
            });
        });
    }
    return handlePromise(axios({
        url: POLICY_BASE_URL + "/policies" + (topupMilesPath || '') + "/" + jobId + "/addbillingdetailsandgetsagepayurl",
        method: 'POST',
        data: JSON.stringify(data),
        headers: { 'x-authorization': token, 'Content-Type': 'application/json' },
    }));
};
