var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import styled from 'styled-components';
import { color, fontFamily } from '../../../styles';
import moment from 'moment';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label,\n  .MuiInputBase-input {\n    color: ", ";\n    font-family: ", ";\n    margin-left: 10px;\n  }\n\n  .MuiIconButton-label {\n    svg {\n      fill: #30a369;\n    }\n  }\n\n  .MuiInputBase-root.Mui-disabled {\n    opacity: 0.38;\n  }\n"], ["\n  label,\n  .MuiInputBase-input {\n    color: ", ";\n    font-family: ", ";\n    margin-left: 10px;\n  }\n\n  .MuiIconButton-label {\n    svg {\n      fill: #30a369;\n    }\n  }\n\n  .MuiInputBase-root.Mui-disabled {\n    opacity: 0.38;\n  }\n"])), color.black, fontFamily.base);
var MtaDatePicker = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.label, label = _c === void 0 ? 'Please select' : _c, onSelect = _a.onSelect, _d = _a.disablePast, disablePast = _d === void 0 ? false : _d, _e = _a.disableFuture, disableFuture = _e === void 0 ? false : _e, mtaEffectiveDate = _a.mtaEffectiveDate;
    var _f = React.useState(null), selectedDate = _f[0], setSelectedDate = _f[1];
    var handleDateChange = function (date) {
        var formatted = moment(date)
            .utcOffset(0, true)
            .toISOString();
        setSelectedDate(date);
        onSelect(formatted);
    };
    return (React.createElement(Root, null,
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(KeyboardDatePicker, { style: { width: '240px' }, disableToolbar: true, disabled: disabled, disablePast: disablePast, disableFuture: disableFuture, variant: "inline", format: "dd/MM/yyyy", id: "date-picker-inline", label: label, value: mtaEffectiveDate ? mtaEffectiveDate : selectedDate, onChange: handleDateChange, minDateMessage: "Please choose a date that is current or one that is up to 7 days in the future", maxDate: moment().add(7, 'days'), maxDateMessage: "Please choose a date that is less then 7 days from today", KeyboardButtonProps: {
                    'aria-label': 'change date',
                }, InputLabelProps: {
                    shrink: true,
                }, placeholder: "dd / mm / yyyy" }))));
};
export default MtaDatePicker;
var templateObject_1;
