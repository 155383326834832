var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import HideOn from 'components/atoms/HideOn';
import SectionButtonWrapper from 'components/molecules/SectionButtonWrapper';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { fontSize, fontWeight, media } from 'styles';
import Table from './Table';
import TableMobile from './TableMobile';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var NoQuote = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: 10px;\n\n  ", "\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: ", ";\n  font-weight: ", ";\n  padding-bottom: 10px;\n\n  ",
    "\n"])), fontSize.fs14, fontWeight.light, media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: ", ";\n    padding-bottom: 20px;\n  "], ["\n    font-size: ", ";\n    font-weight: ", ";\n    padding-bottom: 20px;\n  "])), fontSize.fs16, fontWeight.medium));
var SavedQuoteSection = function (_a) {
    var content = _a.content, savedQuotes = _a.savedQuotes, onQuoteAction = _a.onQuoteAction, onGetNewQuote = _a.onGetNewQuote, newButtonDisplay = _a.newButtonDisplay;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var title = content.title, savedQuotesHeaders = content.savedQuotesHeaders, amendDetailsButtonText = content.amendDetailsButtonText, buyButtonText = content.buyButtonText, resumeButtonText = content.resumeButtonText, noQuoteText = content.noQuoteText, getNewQuoteButtonText = content.getNewQuoteButtonText, moreInfoText = content.moreInfoText;
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(SectionHeader, { expandable: true, open: open, title: title, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(PaddingVertical, null,
                    savedQuotes && savedQuotes.length > 0 ? (React.createElement(React.Fragment, null,
                        React.createElement(HideOn, { smallOnly: true },
                            React.createElement(Table, { quotes: savedQuotes, headers: savedQuotesHeaders, amendDetailsButtonText: amendDetailsButtonText, buyButtonText: buyButtonText, resumeButtonText: resumeButtonText, onQuoteAction: onQuoteAction })),
                        React.createElement(HideOn, { medium: true },
                            React.createElement(TableMobile, { headers: savedQuotesHeaders, resumeButtonText: resumeButtonText, buyButtonText: buyButtonText, amendDetailsButtonText: amendDetailsButtonText, quotes: savedQuotes, onQuoteAction: onQuoteAction, moreInfoText: moreInfoText })))) : (React.createElement(NoQuote, null, noQuoteText)),
                    newButtonDisplay ? (React.createElement(SectionButtonWrapper, null,
                        React.createElement(HideOn, { smallOnly: true },
                            React.createElement(Button, { onClick: function () {
                                    onGetNewQuote();
                                    TagManagerArgs('Button', window.location.pathname, getNewQuoteButtonText);
                                } }, getNewQuoteButtonText)),
                        React.createElement(HideOn, { medium: true },
                            React.createElement(Button, { fullWidth: true, onClick: function () {
                                    onGetNewQuote();
                                    TagManagerArgs('Button', window.location.pathname, getNewQuoteButtonText);
                                } }, getNewQuoteButtonText)))) : (React.createElement(React.Fragment, null))))))));
};
export default SavedQuoteSection;
var templateObject_1, templateObject_2, templateObject_3;
