var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  background-color: ", ";\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  border-bottom: 4px solid ", ";\n  color: ", ";\n  cursor: pointer;\n  padding: 0 20px;\n  justify-content: center;\n  height: 50px;\n  align-items: center;\n  box-sizing: border-box;\n"], ["\n  display: flex;\n  flex: 1;\n  background-color: ", ";\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  border-bottom: 4px solid ", ";\n  color: ", ";\n  cursor: pointer;\n  padding: 0 20px;\n  justify-content: center;\n  height: 50px;\n  align-items: center;\n  box-sizing: border-box;\n"])), function (props) { return (props.selected ? color.green : 'rgba(52, 53, 52, 0.04)'); }, color.green, function (props) { return (props.selected ? 'white' : color.black); });
var Tab = function (_a) {
    var onClick = _a.onClick, _b = _a.selected, selected = _b === void 0 ? false : _b, text = _a.text, tabKey = _a.tabKey;
    return (React.createElement(Root, { onClick: function () { return onClick(tabKey); }, selected: selected }, text));
};
export default Tab;
var templateObject_1;
