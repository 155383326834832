var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 12px;\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 12px;\n"])));
var HintWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 15px;\n"], ["\n  margin-left: 15px;\n"])));
var DrivingScoreHint = function (_a) {
    var hint = _a.hint, icon = _a.icon;
    return (React.createElement(Root, null,
        icon,
        React.createElement(HintWrapper, null, hint)));
};
export default DrivingScoreHint;
var templateObject_1, templateObject_2;
