var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useWindowSize } from '@react-hook/window-size/throttled';
import React from 'react';
import styled from 'styled-components';
import Layout, { PaddingVertical, PaddingHorizontal } from 'components/organisms/Layout';
import { media, screenMediumMin } from 'styles';
import Notifications from './Notifications';
import { isArray } from 'lodash';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  > * {\n    flex: 10;\n  }\n\n  ", "\n"], ["\n  > * {\n    flex: 10;\n  }\n\n  ",
    "\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n  "], ["\n    display: flex;\n  "]))));
var HorizontalSpace = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var VerticalSpace = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 20px;\n"], ["\n  height: 20px;\n"])));
var NotificationsSection = function (_a) {
    var notifications = _a.notifications;
    var width = useWindowSize()[0];
    var redNotifications = isArray(notifications) ? notifications.filter(function (noti) { return noti.red; }) : [];
    var yellowNotifications = isArray(notifications) ? notifications.filter(function (noti) { return noti.yellow; }) : [];
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(Root, null,
                        redNotifications.length > 0 && React.createElement(Notifications, { notifications: redNotifications }),
                        width > screenMediumMin && redNotifications.length > 0 && yellowNotifications.length > 0 && (React.createElement(HorizontalSpace, null)),
                        width <= screenMediumMin && React.createElement(VerticalSpace, null),
                        yellowNotifications.length > 0 && React.createElement(Notifications, { notifications: yellowNotifications })))))));
};
export default NotificationsSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
