var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { AddDriverWatchedFieldIds, ContentPageURL } from 'types';
import { getPageContent } from 'services';
import { getDriverValues } from '../MtaAddDriverPage/getDriverValues';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import ShareComponent from 'pages/ShareComponent';
import { AddDriverConvictionsAndClaims, AddDriverDetails, AddDriverNewDriver, AddDriverConvictions, AddDriverClaims, } from 'components/organisms/MtaAddDriver';
import Button from 'components/atoms/Button';
import { TagManagerArgs } from 'analytics';
// styled components
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var RenewalAddDriverPage = function (_a) {
    var renewalPolicy = _a.renewalPolicy, setRenewalState = _a.setRenewalState, renewalState = _a.renewalState;
    var history = useHistory();
    var editValues = history.location.state ? history.location.state.driver.data : undefined;
    var editMode = history.location.state ? history.location.state.edit : false;
    var driverIndex = history.location.state ? history.location.state.driverIndex : undefined;
    var drivers = history.location.state ? history.location.state.drivers : undefined;
    // CMS
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    // Submision type (add new driver, edit a driver)
    var _d = useState(''), submitType = _d[0], setSubmitType = _d[1];
    var _e = useState({
        display: [],
        data: [],
    }), convictions = _e[0], setConvictions = _e[1];
    var _f = useState({
        display: [],
        data: [],
    }), claims = _f[0], setClaims = _f[1];
    var _g = useForm({
        defaultValues: editValues,
    }), register = _g.register, handleSubmit = _g.handleSubmit, control = _g.control, watch = _g.watch;
    // Which fields trigger hidden fields to show / hide
    var watchedFields = watch([
        AddDriverWatchedFieldIds.UK_RESIDENT_FIVE_YEARS,
        AddDriverWatchedFieldIds.LICENSE_TYPE,
        AddDriverWatchedFieldIds.TEST_PASSED_TEN_YEARS,
        AddDriverWatchedFieldIds.HAS_MOTOR_CONVICTIONS,
        AddDriverWatchedFieldIds.HAS_MOTOR_CLAIMS,
    ]);
    // Form submission
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var obj, driver, editedDrivers;
        return __generator(this, function (_a) {
            obj = getDriverValues(data);
            // Adds convictions and claims, if there are none the array will be empty in the request, GW is ok with this
            obj.pmClaims = claims.data;
            obj.pmMotorConvictions = convictions.data;
            driver = {
                data: data,
                obj: obj,
            };
            if (submitType === 'addNew') {
                setRenewalState(__assign(__assign({}, renewalState), { addedDrivers: __spreadArrays(renewalState.addedDrivers, [driver]) }));
                history.goBack();
            }
            if (submitType === 'editDriver') {
                if (isEqual(driver, drivers[driverIndex]))
                    history.goBack();
                else {
                    editedDrivers = drivers;
                    editedDrivers[driverIndex] = driver;
                    setRenewalState(__assign(__assign({}, renewalState), { addedDrivers: editedDrivers }));
                    history.goBack();
                }
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.ADD_DRIVER_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
    }, [cmsContent, renewalPolicy]);
    return (React.createElement(React.Fragment, null, cmsContent && renewalPolicy && (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(React.Fragment, null,
            React.createElement(ShareComponent, { headerContent: cmsContent.header }),
            React.createElement(Layout, null,
                React.createElement(PaddingHorizontal, null,
                    formGroups && (React.createElement(React.Fragment, null,
                        React.createElement(AddDriverNewDriver, { formGroups: formGroups, register: register, control: control, watchedFields: watchedFields }),
                        React.createElement(AddDriverDetails, { formGroups: formGroups, control: control, watchedFields: watchedFields }),
                        React.createElement(AddDriverConvictionsAndClaims, { formGroups: formGroups, control: control }),
                        renewalPolicy && watchedFields.hasMotorConvictions === 'Yes' && (React.createElement(AddDriverConvictions, { onChange: function (data) { return setConvictions(data); }, formGroups: formGroups, currentPolicy: renewalPolicy })),
                        watchedFields.hasMotorClaims === 'Yes' && (React.createElement(AddDriverClaims, { onChange: function (data) { return setClaims(data); }, formGroups: formGroups })))),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { type: "button", buttonStyle: "warning-clear", onClick: function () {
                                history.goBack();
                                TagManagerArgs('Button', "" + window.location.pathname, 'Cancel');
                            } }, "Cancel"),
                        !editMode && (React.createElement(Button, { type: "submit", onClick: function () {
                                setSubmitType('addNew');
                                TagManagerArgs('Button', "" + window.location.pathname, 'Add driver');
                            } }, "Add driver")),
                        editMode && (React.createElement(Button, { type: "submit", onClick: function () {
                                setSubmitType('editDriver');
                                TagManagerArgs('Button', "" + window.location.pathname, 'Confirm Changes');
                            } }, "Confirm changes"))))))))));
};
var mapStateToProps = function (state) { return ({
    renewalState: state.renewalState,
    renewalPolicy: state.renewalPolicy,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRenewalState: function (formState) { return dispatch(actions.setAmendRenewalState(formState)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(RenewalAddDriverPage);
var templateObject_1, templateObject_2;
