var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { FormInput, Group } from 'components/organisms/Form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PolicyHolderSection = function (_a) {
    var content = _a.content, data = _a.data;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var title = content.title, fields = content.fields;
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(SectionHeader, { expandable: true, open: open, title: title, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(React.Fragment, null,
                    React.createElement(Group, { noBorder: true }, fields.map(function (item) { return (React.createElement(FormInput, { label: item.label, value: data[item.value], name: item.value, key: item.value, disabled: true })); }))))))));
};
export default PolicyHolderSection;
var templateObject_1;
