var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { FormInput, getSingleFormGroupContent, Group } from 'components/organisms/Form';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { VehicleLookup } from 'components/organisms/Vehicle';
import VehicleFormDetails from 'components/organisms/VehicleFormDetails';
import { ChangeVehicleSectionIds, SessionStorageKey, ContentPageURL, } from 'types';
import { getPageContent, getRenewalAmendQuote, mtaTokenRefresh } from 'services';
import { getVehicleValues } from 'pages/MtaChangeVehiclePage/getVehicleValues';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import { registrationLookup } from 'guidewire/registrationLookup';
import { TagManagerArgs } from 'analytics';
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var convertValues = function (data) {
    return Object.fromEntries(Object.keys(data).map(function (key) {
        if (key === 'marketValue')
            return [key, data[key].amount];
        if (data[key] === true)
            return [key, 'Yes'];
        if (data[key] === false)
            return [key, 'No'];
        return [key, data[key]];
    }));
};
var RenewalTwo = function (_a) {
    var renewalAmendDetails = _a.renewalAmendDetails, renewalState = _a.renewalState, setRenewalState = _a.setRenewalState, renewalPolicy = _a.renewalPolicy, setAmendedRenewalQuote = _a.setAmendedRenewalQuote;
    var history = useHistory();
    // current details
    var carRenewalDetails = renewalAmendDetails.vehicles[0];
    if (renewalState.vehicleDetails)
        carRenewalDetails = renewalState.vehicleDetails;
    // converted for display with form
    var carRenewalDisplay = convertValues(carRenewalDetails);
    // For the car puchase date, to display correctly in a single field
    var monthString = renewalAmendDetails.vehicles[0].purchasedMonth;
    var purchasedMonth = moment()
        .month(monthString)
        .month();
    var purchasedYear = renewalAmendDetails.vehicles[0].purchasedYear;
    var purchasedDateObject = {
        month: purchasedMonth,
        year: purchasedYear,
    };
    var purchasedDate = moment(purchasedDateObject).format();
    //////////////////////
    var ncdYears = renewalAmendDetails.vehicles[0].ncdYears;
    var registrationNumber = renewalState.vehicleRegistration
        ? renewalState.vehicleRegistration
        : renewalAmendDetails.vehicles[0].registrationNumber;
    // CMS
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), carInformationOneContent = _d[0], setCarInformationOneContent = _d[1];
    var _e = useState(undefined), carInformationTwoContent = _e[0], setCarInformationTwoContent = _e[1];
    var _f = useState(undefined), registrationLookupContent = _f[0], setRegistrationLookupContent = _f[1];
    var _g = useState(undefined), ncdContent = _g[0], setNcdContent = _g[1];
    // Car / registration info from the lookup component
    var _h = useState(carRenewalDetails), carDetails = _h[0], setCarDetails = _h[1];
    var _j = useState(registrationNumber), registration = _j[0], setRegistration = _j[1];
    var currentVehicleValues = __assign(__assign({}, carRenewalDisplay), { purchasedDate: purchasedDate, ncdYears: ncdYears });
    // remove unneeded default values so the Form is not bloated with confusing info
    delete currentVehicleValues.abiCode;
    delete currentVehicleValues.advisoryGroup1to50;
    delete currentVehicleValues.bodyType;
    delete currentVehicleValues.colour;
    delete currentVehicleValues.description;
    delete currentVehicleValues.engineCapacity;
    delete currentVehicleValues.existingVehicleUpdated;
    delete currentVehicleValues.fixedId;
    delete currentVehicleValues.fuelType;
    delete currentVehicleValues.hasChanged;
    delete currentVehicleValues.make;
    delete currentVehicleValues.model;
    delete currentVehicleValues.ncdProtection;
    delete currentVehicleValues.publicID;
    delete currentVehicleValues.purchasedMonth;
    delete currentVehicleValues.purchasedYear;
    delete currentVehicleValues.regNumUnknown;
    delete currentVehicleValues.registrationNumber;
    delete currentVehicleValues.transmission;
    delete currentVehicleValues.voluntaryExcess;
    delete currentVehicleValues.voluntaryExcessOptions;
    delete currentVehicleValues.firstRegisteredMonth;
    delete currentVehicleValues.firstRegisteredYear;
    delete currentVehicleValues.numberOfDoors;
    var formDefaultValues = undefined;
    formDefaultValues = currentVehicleValues;
    // if there have been edits made, user moves to another page then returns here
    if (renewalState && renewalState.pageTwoFormValues) {
        formDefaultValues = renewalState.pageTwoFormValues;
    }
    // Form functions and controllers
    var _k = useForm({
        defaultValues: formDefaultValues,
    }), register = _k.register, handleSubmit = _k.handleSubmit, control = _k.control, watch = _k.watch, reset = _k.reset, errors = _k.errors;
    var watchedFields = watch();
    var carRenewalState = function (car) {
        setCarDetails(car);
    };
    var regRenewalState = function (reg) {
        setRegistration(reg);
        reset({
            classOfUse: 'none',
            dayTimeParking: 'none',
            hasBeenModified: '',
            imported: '',
            lessThanEightSeats: '',
            marketValue: '',
            modification: 'none',
            nightTimeParking: 'none',
            purchasedDate: null,
            registeredKeeper: '',
            rightHandDrive: '',
            securityDevices: 'none',
            securityTracker: '',
        });
        setRenewalState(__assign(__assign({}, renewalState), { registrationEdited: true }));
    };
    // form usage
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var previousValues, values, dataValues, obj, vehicleDetails, carData, policy, currentDetails, accountHolder, accountHolderDriver, nonAccountHolderDrivers, newDrivers, policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    previousValues = {
                        old: formDefaultValues,
                        changed: watchedFields,
                    };
                    values = undefined;
                    if (renewalState.registrationEdited) {
                        values = watchedFields;
                    }
                    else {
                        values = __assign(__assign({}, previousValues.old), previousValues.changed);
                    }
                    setRenewalState(__assign(__assign({}, renewalState), { pageTwoFormValues: values, vehicleRegistration: registration, vehicleDetails: carDetails }));
                    dataValues = data;
                    // this makes sure the initial values and data returned by the form are the same type for comparison
                    dataValues.marketValue = parseInt(data.marketValue);
                    dataValues.ncdYears = ncdYears;
                    obj = undefined;
                    vehicleDetails = undefined;
                    if (!isEqual(dataValues, currentVehicleValues)) {
                        obj = getVehicleValues(dataValues, registration);
                        carData = renewalState.vehicleDetails ? renewalState.vehicleDetails : carDetails;
                        vehicleDetails = __assign(__assign({}, carData), obj);
                        if (registration === registrationNumber)
                            vehicleDetails.existingVehicleUpdated = true;
                        else
                            vehicleDetails.existingVehicleUpdated = false;
                    }
                    policy = renewalAmendDetails;
                    currentDetails = renewalState.currentDetails;
                    accountHolder = currentDetails.accountHolder, accountHolderDriver = currentDetails.accountHolderDriver, nonAccountHolderDrivers = currentDetails.nonAccountHolderDrivers;
                    if (vehicleDetails !== undefined) {
                        delete vehicleDetails.purchasedDate;
                        delete vehicleDetails.costNew;
                        policy.vehicles = [vehicleDetails];
                    }
                    if (renewalState.accountHolderPrimaryAddress) {
                        policy.baseData.policyAddress = renewalState.accountHolderPrimaryAddress;
                    }
                    if (renewalState.accountHolder) {
                        accountHolder = __assign(__assign({}, accountHolder), renewalState.accountHolder);
                        if (!renewalState.accountHolderDriver)
                            accountHolderDriver.updateOrRemove = 'update';
                    }
                    if (renewalState.accountHolderDriver) {
                        accountHolderDriver = __assign(__assign({}, accountHolderDriver), renewalState.accountHolderDriver);
                    }
                    if (renewalState.claims) {
                        accountHolderDriver.pmClaims = renewalState.claims.data;
                        accountHolderDriver.updateOrRemove = 'update';
                    }
                    if (renewalState.convictions) {
                        accountHolderDriver.pmMotorConvictions = renewalState.conVictions.data;
                        accountHolderDriver.updateOrRemove = 'update';
                    }
                    if (renewalState.nonAccountHolderDrivers) {
                        nonAccountHolderDrivers = renewalState.nonAccountHolderDrivers;
                        if (renewalState.additionalDriverToMain)
                            accountHolderDriver.mainDriver = false;
                    }
                    newDrivers = [];
                    if (renewalState.addedDrivers) {
                        newDrivers = renewalState.addedDrivers.map(function (driver) { return driver.obj; });
                    }
                    policy.accountHolder = accountHolder;
                    policy.drivers = __spreadArrays([accountHolderDriver], nonAccountHolderDrivers, newDrivers);
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, getRenewalAmendQuote(policyNumber, policy, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        setAmendedRenewalQuote(quote.data);
                        history.push('/quote-summary');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        onSubmit(data);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var goBack = function () {
        var previousValues = {
            old: formDefaultValues,
            changed: watchedFields,
        };
        var values = undefined;
        if (renewalState.registrationEdited) {
            values = watchedFields;
        }
        else {
            values = __assign(__assign({}, previousValues.old), previousValues.changed);
        }
        setRenewalState(__assign(__assign({}, renewalState), { pageTwoFormValues: values, vehicleRegistration: registration, vehicleDetails: carDetails }));
        history.push('/renewal-about-you');
    };
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.RENEWAL_YOUR_CAR).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent) {
            setCarInformationOneContent(getSingleFormGroupContent('carInformationOneSection', formGroups));
            setCarInformationTwoContent(getSingleFormGroupContent('carInformationTwoSection', formGroups));
            setRegistrationLookupContent(getSingleFormGroupContent(ChangeVehicleSectionIds.REGISTRATION_LOOKUP_SECTION, formGroups));
            var ncdField = getSingleFormGroupContent('ncdSection', formGroups);
            if (ncdField)
                setNcdContent(ncdField.fields[0]);
        }
    }, [cmsContent, carDetails, formGroups, watchedFields.hasBeenModified, registration, formDefaultValues]);
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(RenewalJourneyProgress, { step: 2 }),
        cmsContent && (React.createElement(React.Fragment, null,
            React.createElement(Layout, null,
                React.createElement(PaddingHorizontal, null,
                    React.createElement(PaddingVertical, null,
                        carInformationOneContent && carInformationTwoContent && (React.createElement(React.Fragment, null,
                            React.createElement(VehicleLookup, { content: registrationLookupContent, carToParent: function (car) { return carRenewalState(car); }, registrationToParent: function (reg) { return regRenewalState(reg); }, initialValues: renewalState.vehicleDetails ? renewalState.vehicleDetails : carDetails, initialRegistration: registrationNumber, currentRegistration: registrationNumber, registrationLookup: registrationLookup }),
                            React.createElement(VehicleFormDetails, { sectionOne: carInformationOneContent, sectionTwo: carInformationTwoContent, control: control, register: register, watchedFields: watchedFields, errors: errors }),
                            React.createElement(Group, { title: ncdContent.title },
                                React.createElement(FormInput, { label: ncdContent.label, toolTip: ncdContent.toolTip, name: ncdContent.value, value: ncdYears, additionalToTooltip: ncdContent.additionalToTooltip, readOnly: true })))),
                        React.createElement(ButtonWrapper, null,
                            React.createElement(Button, { type: "button", buttonStyle: "secondary", onClick: function () {
                                    goBack();
                                    TagManagerArgs('Button', "" + window.location.pathname, 'Go back');
                                } }, "Go back"),
                            React.createElement(Button, { type: "submit", onClick: function () { return TagManagerArgs('Button', "" + window.location.pathname, 'Continue'); } }, "Continue")))))))));
};
var mapStateToProps = function (state) { return ({
    renewalAmendDetails: state.renewalAmendDetails,
    renewalState: state.renewalState,
    renewalPolicy: state.renewalPolicy,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRenewalState: function (formState) { return dispatch(actions.setAmendRenewalState(formState)); },
    setAmendedRenewalQuote: function (quote) { return dispatch(actions.setAmendedRenewalQuote(quote)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(RenewalTwo);
var templateObject_1, templateObject_2;
