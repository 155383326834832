var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/atoms/Button';
import { TagManagerArgs } from 'analytics';
var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 30px;\n"], ["\n  padding: 30px;\n"])));
var ModalTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var ModalBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n    margin-bottom: 10px;\n  }\n"], ["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n    margin-bottom: 10px;\n  }\n"])));
var ModalButtons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var MtaModal = function (_a) {
    var modalOpen = _a.modalOpen, setModalOpen = _a.setModalOpen, modalContent = _a.modalContent, modalContinue = _a.modalContinue, removeDriverName = _a.removeDriverName;
    var replacePlacholderText = function (value) {
        var placeholders = {
            DRIVER_NAME: removeDriverName,
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/DRIVER_NAME/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    return (React.createElement(Dialog, { open: modalOpen },
        React.createElement(ModalWrapper, null,
            React.createElement(ModalTitle, null, modalContent.title),
            React.createElement(ModalBody, null,
                React.createElement(ReactMarkdown, { linkTarget: "_blank", source: replacePlacholderText(modalContent.body) })),
            React.createElement(ModalButtons, null,
                React.createElement(Button, { buttonStyle: "clear", onClick: function () {
                        setModalOpen(false);
                        TagManagerArgs('Button', window.location.pathname + " - Mta Modal", 'Cancel');
                    } }, "Cancel"),
                React.createElement(Button, { buttonStyle: removeDriverName === undefined ? 'primary' : 'warning-clear', onClick: function () {
                        modalContinue();
                        setModalOpen(false);
                        TagManagerArgs('Button', window.location.pathname + " - Mta Modal", 'Continue');
                    } }, "Continue")))));
};
export default MtaModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
