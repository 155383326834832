var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { fontWeight } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 30px;\n  text-align: center;\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 30px;\n  text-align: center;\n"])), fontWeight.medium);
var HintWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 50px;\n"], ["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 50px;\n"])));
var CheckEmail = function (_a) {
    var onSignIn = _a.onSignIn, content = _a.content;
    var passwordChangedTitle = content.passwordChangedTitle, passwordChangedHint = content.passwordChangedHint, signInText = content.signInText;
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null, passwordChangedTitle),
        React.createElement(HintWrapper, null,
            React.createElement(ReactMarkdown, { source: passwordChangedHint, escapeHtml: false })),
        React.createElement(Button, { onClick: function () {
                onSignIn();
                TagManagerArgs('Button', window.location.pathname + " - Login Password Changed", signInText);
            }, fullWidth: true }, signInText)));
};
export default CheckEmail;
var templateObject_1, templateObject_2, templateObject_3;
