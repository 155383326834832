var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var SagePaySuccess = function () {
    useEffect(function () {
        window.top.postMessage(JSON.stringify({
            error: false,
            message: 'success',
        }), '*');
    }, []);
    return (React.createElement(Root, null,
        React.createElement("h1", null, "Processing successful...")));
};
export default SagePaySuccess;
var templateObject_1;
