export var getPaymentSectionContent = function (content) {
    var paragraph1 = content.paymentParagraph1, paragraph2 = content.paymentParagraph2, options = content.paymentOptions, monthlyPaymentDetailsTitle = content.monthlyPaymentDetailsTitle, monthlyPaymentDetails = content.monthlyPaymentDetails;
    return { paragraph1: paragraph1, paragraph2: paragraph2, options: options, monthlyPaymentDetailsTitle: monthlyPaymentDetailsTitle, monthlyPaymentDetails: monthlyPaymentDetails };
};
export var getMileageSectionContent = function (content) {
    var paragraph1 = content.mileageParagraph1, paragraph2 = content.mileageParagraph2, options = content.mileageOptions, title = content.mileageSectionTitle;
    return { paragraph1: paragraph1, paragraph2: paragraph2, options: options, title: title };
};
export var getNCDSectionContent = function (content) {
    var title = content.ncdSectionTitle, intro = content.ncdSectionIntroText, options = content.ncdOptions;
    return { title: title, intro: intro, options: options };
};
export var getAddonsSectionContent = function (content) {
    var title = content.addonsSectionTitle, intro = content.addonsSectionIntroText, addCoverText = content.addCoverText, removeCoverText = content.removeCoverText, chargeTitle = content.chargeTitle, chargeText = content.chargeText, addons = content.addons;
    return {
        title: title,
        intro: intro,
        addCoverText: addCoverText,
        removeCoverText: removeCoverText,
        chargeTitle: chargeTitle,
        chargeText: chargeText,
        addons: addons,
    };
};
export var getBreakdownCoverSectionContent = function (content) {
    var title = content.breakdownCoverSectionTitle, intro = content.breakdownCoverSectionIntroText, chooseCoverText = content.chooseCoverText, benefits = content.benefits, coverOptions = content.coverOptions, chargeText = content.chargeText, chargeTitle = content.chargeTitle;
    return {
        title: title,
        intro: intro,
        chooseCoverText: chooseCoverText,
        benefits: benefits,
        coverOptions: coverOptions,
        chargeText: chargeText,
        chargeTitle: chargeTitle,
    };
};
export var getInformationUsageSectionContent = function (content) {
    var title = content.informationUsageSectionTitle, intro = content.informationUsageSectionIntroText;
    return {
        title: title,
        intro: intro,
    };
};
export var getMyCoverSectionContent = function (content) {
    var coverTitle = content.coverTitle, coverIntro = content.coverIntro, coverDetails = content.coverDetails, pncdInfo = content.pncdInfo, addonsSectionTitle = content.addonsSectionTitle, includesText = content.includesText, addons = content.addons, coverOptions = content.coverOptions, benefits = content.benefits;
    return {
        coverTitle: coverTitle,
        coverIntro: coverIntro,
        coverDetails: coverDetails,
        pncdInfo: pncdInfo,
        addonTitle: addonsSectionTitle,
        includesText: includesText,
        addons: addons,
        coverOptions: coverOptions,
        benefits: benefits,
    };
};
