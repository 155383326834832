var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Tooltip from 'components/molecules/Tooltip';
import { color, fontSize, space } from 'styles';
export var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n"])));
var TopWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n"])));
export var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-bottom: ", ";\n"], ["\n  font-size: ", ";\n  margin-bottom: ", ";\n"])), fontSize.fs14, space.s10);
var FieldWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 50px;\n  border-radius: 2px;\n  border: solid 1px rgba(137, 137, 137, 0.3);\n  box-sizing: border-box;\n\n  input {\n    width: -webkit-fill-available;\n    height: 100%;\n    padding: 0;\n    padding-left: ", ";\n\n    ::placeholder {\n      color: ", ";\n    }\n  }\n"], ["\n  width: 100%;\n  height: 50px;\n  border-radius: 2px;\n  border: solid 1px rgba(137, 137, 137, 0.3);\n  box-sizing: border-box;\n\n  input {\n    width: -webkit-fill-available;\n    height: 100%;\n    padding: 0;\n    padding-left: ", ";\n\n    ::placeholder {\n      color: ", ";\n    }\n  }\n"])), space.s20, color.greyMedium);
var InputField = function (_a) {
    var label = _a.label, disabled = _a.disabled, onChange = _a.onChange, placeholder = _a.placeholder, tooltipText = _a.tooltipText, value = _a.value, type = _a.type;
    var handleChange = function (event) {
        onChange && onChange(event.target.value);
    };
    return (React.createElement(Root, null,
        React.createElement(TopWrapper, null,
            React.createElement(LabelWrapper, null, label),
            tooltipText && React.createElement(Tooltip, { text: tooltipText })),
        React.createElement(FieldWrapper, null,
            React.createElement("input", { placeholder: placeholder, disabled: disabled, onChange: function (event) { return handleChange(event); }, value: value, type: type }))));
};
export default InputField;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
