var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Modal from '@material-ui/core/Modal';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import styled from 'styled-components';
import CloseIcon from 'assets/close.svg';
import HideOn from 'components/atoms/HideOn';
import Title from 'components/atoms/Title';
import Dropdown from 'components/molecules/Dropdown';
import MapRenderer from 'components/molecules/MapRenderer';
import Pager, { getPage } from 'components/molecules/Pager';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import MapConsent from 'components/organisms/MapConsent';
import { color, fontWeight, fontSize, media } from 'styles';
import TabBar from './TabBar';
import Table from './Table';
import TableMobile from './TableMobile';
import DailyMiles from './DailyMiles';
import MonthlyMiles from './MonthlyMiles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), color.greyBackground);
var IntroTextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  line-height: 20px;\n  margin: 20px 0 30px 0;\n\n  strong {\n    font-weight: ", ";\n  }\n\n  ", "\n"], ["\n  font-size: ", ";\n  line-height: 20px;\n  margin: 20px 0 30px 0;\n\n  strong {\n    font-weight: ", ";\n  }\n\n  ",
    "\n"])), fontSize.fs14, fontWeight.bold, media.large(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 20px 0 50px 0;\n  "], ["\n    margin: 20px 0 50px 0;\n  "]))));
var MapContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: 1440px;\n  border: none;\n  margin: auto;\n  outline: none;\n  padding: 15px;\n  text-align: right;\n\n  ", "\n\n  svg {\n    cursor: pointer;\n    fill: white;\n  }\n"], ["\n  max-width: 1440px;\n  border: none;\n  margin: auto;\n  outline: none;\n  padding: 15px;\n  text-align: right;\n\n  ",
    "\n\n  svg {\n    cursor: pointer;\n    fill: white;\n  }\n"])), media.large(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 50px 135px;\n  "], ["\n    padding: 50px 135px;\n  "]))));
var TabBarWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 1184px;\n"], ["\n  max-width: 1184px;\n"])));
var TabContentContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 20px;\n\n  ", "\n"], ["\n  margin-top: 20px;\n\n  ",
    "\n"])), media.large(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 50px;\n  "], ["\n    margin-top: 50px;\n  "]))));
var DropdownWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: calc(100% -30px);\n  box-sizing: border-box;\n  cursor: pointer;\n  margin: 0 15px 20px 15px;\n\n  ", "\n"], ["\n  width: calc(100% -30px);\n  box-sizing: border-box;\n  cursor: pointer;\n  margin: 0 15px 20px 15px;\n\n  ",
    "\n"])), media.large(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    width: 150px;\n    margin: 40px 0 40px auto;\n  "], ["\n    width: 150px;\n    margin: 40px 0 40px auto;\n  "]))));
var MonthlyMilesWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 50px;\n  margin-bottom: 50px;\n"], ["\n  margin-top: 50px;\n  margin-bottom: 50px;\n"])));
var PagerWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 30px;\n"], ["\n  margin-top: 30px;\n"])));
var NoDataHintWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 20px;\n  line-height: 40px;\n  margin-bottom: 40px;\n"], ["\n  font-weight: ", ";\n  font-size: 20px;\n  line-height: 40px;\n  margin-bottom: 40px;\n"])), fontWeight.bold);
var MyJourneysSection = function (_a) {
    var cmsContent = _a.cmsContent, latestJourney = _a.latestJourney, previousJourneys = _a.previousJourneys, summaries = _a.summaries, monthlyMiles = _a.monthlyMiles, dailyMiles = _a.dailyMiles, monthName = _a.monthName, totalPages = _a.totalPages, _b = _a.mapDisplayAllowed, mapDisplayAllowed = _b === void 0 ? false : _b, _c = _a.tripsDisplayAllowed, tripsDisplayAllowed = _c === void 0 ? false : _c, _d = _a.hideCharts, hideCharts = _d === void 0 ? false : _d, _e = _a.singleDriver, singleDriver = _e === void 0 ? false : _e, onAgreeMapConsent = _a.onAgreeMapConsent, onMonthlySummarySelected = _a.onMonthlySummarySelected, onMonthlyJourneySelected = _a.onMonthlyJourneySelected, onPageSelect = _a.onPageSelect;
    var sectionTitle = cmsContent.sectionTitle, introText = cmsContent.introText, tabs = cmsContent.tabs, showMapButtonText = cmsContent.showMapButtonText, journeyHeaders = cmsContent.journeyHeaders, summaryHeaders = cmsContent.summaryHeaders, monthlyMilesContent = cmsContent.monthlyMilesContent, dailyMilesContent = cmsContent.dailyMilesContent, mapConsentTitle = cmsContent.mapConsentTitle, mapConsentTextForMultiDrivers = cmsContent.mapConsentTextForMultiDrivers, mapConsentTextForSingleDriver = cmsContent.mapConsentTextForSingleDriver, mapConsentDisagreeButtonText = cmsContent.mapConsentDisagreeButtonText, mapConsentAgreeButtonText = cmsContent.mapConsentAgreeButtonText;
    var _f = useState(1), page = _f[0], setPage = _f[1];
    var _g = useState(false), consentVisible = _g[0], setConsentVisible = _g[1];
    var _h = useState(mapDisplayAllowed), agreedConsent = _h[0], setAgreedConsent = _h[1];
    var _j = useState(tripsDisplayAllowed ? tabs[0].value : 'previous'), selectedTab = _j[0], setSelectedTab = _j[1];
    var _k = useState(undefined), journey = _k[0], setJourney = _k[1];
    var _l = useState(false), mapVisible = _l[0], setMapVisible = _l[1];
    var _m = useState(undefined), selectedTimePeriod = _m[0], setTimePeriod = _m[1];
    useEffect(function () {
        setAgreedConsent(mapDisplayAllowed);
        setSelectedTab(tripsDisplayAllowed ? tabs[0].value : 'previous');
    }, [mapDisplayAllowed, tripsDisplayAllowed]);
    var dropdownOptions = [];
    for (var i = 0; i < 12; i++) {
        var date = moment().subtract(i, 'month');
        if (tripsDisplayAllowed) {
            if (date.isAfter(moment('2021-02-28T23:59:59'))) {
                dropdownOptions.push({
                    label: moment()
                        .subtract(i, 'month')
                        .format('MMM yy'),
                    value: moment()
                        .subtract(i, 'month')
                        .format('yyyy-MM'),
                });
            }
        }
        else {
            dropdownOptions.push({
                label: moment()
                    .subtract(i, 'month')
                    .format('MMM yy'),
                value: moment()
                    .subtract(i, 'month')
                    .format('yyyy-MM'),
            });
        }
    }
    var onShowMap = function (id) {
        var journey = selectedTab === 'latest' ? latestJourney : previousJourneys === null || previousJourneys === void 0 ? void 0 : previousJourneys.find(function (journey) { return journey.id === id; });
        if (journey) {
            setJourney(journey);
            agreedConsent ? setMapVisible(true) : setConsentVisible(true);
        }
    };
    var renderNoDataHint = function () { return (React.createElement(NoDataHintWrapper, null, "You will be able to see your journeys once we have collected enough data from your black box. This usually takes 5-6 weeks.")); };
    return (React.createElement(Layout, null,
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    journey && journey.origin && journey.destination && journey.brakingPoints && journey.waypoints && (React.createElement(Modal, { open: mapVisible, onClose: function () { return setMapVisible(false); } },
                        React.createElement(MapContainer, null,
                            React.createElement(CloseIcon, { onClick: function () { return setMapVisible(false); } }),
                            React.createElement(MapRenderer, { origin: journey.origin, destination: journey.destination, acceleratingPoints: journey.acceleratingPoints, brakingPoints: journey.brakingPoints, waypoints: journey.waypoints })))),
                    React.createElement(Modal, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }, open: consentVisible, onClose: function () { return setConsentVisible(false); } },
                        React.createElement(MapConsent, { checkable: true, title: mapConsentTitle, text: singleDriver ? mapConsentTextForSingleDriver : mapConsentTextForMultiDrivers, disAgreeButtonText: mapConsentDisagreeButtonText, agreeButtonText: mapConsentAgreeButtonText, onAgree: function () {
                                setMapVisible(true);
                                setConsentVisible(false);
                                setAgreedConsent(true);
                                onAgreeMapConsent();
                            }, onClose: function () { return setConsentVisible(false); } })),
                    React.createElement(Title, null, sectionTitle),
                    React.createElement(IntroTextWrapper, null,
                        React.createElement(ReactMarkdown, { source: introText })),
                    React.createElement(React.Fragment, null,
                        React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
                            React.createElement(TabBarWrapper, null,
                                React.createElement(TabBar, { defaultTab: selectedTab, onSelect: function (key) { return setSelectedTab(key); }, tabBarContent: tripsDisplayAllowed ? tabs : tabs.filter(function (tab) { return tab.value !== 'latest'; }) }))),
                        React.createElement(HideOn, { large: true },
                            React.createElement(DropdownWrapper, null,
                                React.createElement(Dropdown, { options: tripsDisplayAllowed ? tabs : tabs.filter(function (tab) { return tab.value !== 'latest'; }), onSelect: function (value) {
                                        setSelectedTab(value);
                                    }, defaultOption: tabs.filter(function (tab) { return tab.value === selectedTab; })[0] })))),
                    React.createElement(TabContentContainer, null,
                        selectedTab === 'latest' && tripsDisplayAllowed && (React.createElement(React.Fragment, null,
                            React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
                                !latestJourney && renderNoDataHint(),
                                React.createElement(Table, { headers: journeyHeaders, latestJourney: latestJourney, showMapButtonText: showMapButtonText, onShowMap: function (id) { return onShowMap(id); }, tab: "latest" })),
                            React.createElement(HideOn, { large: true },
                                React.createElement(TableMobile, { tab: "latest", headers: journeyHeaders, latestJourney: latestJourney, showMapButtonText: showMapButtonText, onShowMap: function (id) { return onShowMap(id); } })))),
                        selectedTab === 'previous' && (React.createElement(React.Fragment, null,
                            latestJourney || !tripsDisplayAllowed
                                ? dropdownOptions && (React.createElement(DropdownWrapper, null,
                                    React.createElement(Dropdown, { options: dropdownOptions, onSelect: function (value) {
                                            onMonthlyJourneySelected(value);
                                            setTimePeriod(value);
                                        }, defaultOption: dropdownOptions.find(function (option) { return option.value === selectedTimePeriod; }) ||
                                            dropdownOptions[0] })))
                                : renderNoDataHint(),
                            React.createElement(React.Fragment, null,
                                React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
                                    React.createElement(Table, { headers: journeyHeaders, journeys: previousJourneys, showMapButtonText: showMapButtonText, onShowMap: function (id) { return onShowMap(id); }, tab: "previous" })),
                                React.createElement(HideOn, { large: true },
                                    React.createElement(TableMobile, { tab: "previous", headers: journeyHeaders, journeys: previousJourneys, showMapButtonText: showMapButtonText, onShowMap: function (id) { return onShowMap(id); } }))),
                            totalPages > 0 && previousJourneys && (React.createElement(PagerWrapper, null,
                                React.createElement(Pager, { currentPage: page, totalPages: totalPages, onPageSelect: function (type) {
                                        var newPage = getPage(page, totalPages, type);
                                        setPage(newPage);
                                        onPageSelect(newPage);
                                    } }))))),
                        selectedTab === 'summary' && (React.createElement(React.Fragment, null,
                            !monthlyMiles && renderNoDataHint(),
                            React.createElement(Table, { headers: summaryHeaders, summaries: summaries, tab: "summary" }),
                            monthlyMiles && !hideCharts && (React.createElement(MonthlyMilesWrapper, null,
                                React.createElement(MonthlyMiles, { cmsContent: monthlyMilesContent, data: monthlyMiles, onMonthSelected: onMonthlySummarySelected }))),
                            dailyMiles && !hideCharts && (React.createElement(DailyMiles, { cmsContent: dailyMilesContent, data: dailyMiles, monthName: monthName }))))))))));
};
export default MyJourneysSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
