var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getPageContent } from 'services';
import { ContentPageURL } from 'types';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { getSingleFormGroupContent, Group } from 'components/organisms/Form';
import Button from 'components/atoms/Button';
import RenewalDrivers from 'components/organisms/RenewalDrivers';
import RenewalOvernightParking from 'components/organisms/RenewalOvernightParking';
import RenewalCoverStartDate from 'components/organisms/RenewalCoverStartDate';
import { getDateObjectDisplay, getDriverDisplayValues } from 'guidewirePolicyUtils';
import { getPolicyDriverEditableValues, getPolicyDriverFixedValues, getPolicyHolderEditableValues, getPolicyHolderFixedValues, getRenewalPolicyValuesForDisplay, numberOfDrivers, } from './utils';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import EditDriverForm from 'components/organisms/EditDriverForm';
import { getDriverValues } from 'pages/MtaAddDriverPage/getDriverValues';
import { TagManagerArgs } from 'analytics';
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var RenewalOne = function (_a) {
    var renewalAmendDetails = _a.renewalAmendDetails, renewalPolicy = _a.renewalPolicy, renewalState = _a.renewalState, setRenewalState = _a.setRenewalState;
    var history = useHistory();
    var _b = useState(undefined), selectedAddress = _b[0], setSelectedAddress = _b[1];
    // CMS content
    var _c = useState(undefined), cmsContent = _c[0], setCmsContent = _c[1];
    var _d = useState(undefined), formGroups = _d[0], setFormGroups = _d[1];
    var _e = useState(undefined), yourQuoteIntroText = _e[0], setYourQuoteIntroText = _e[1];
    var _f = useState(undefined), policyHolderContent = _f[0], setPolicyHolderContent = _f[1];
    var _g = useState(undefined), driverDetailsContent = _g[0], setDriverDetailsContent = _g[1];
    var _h = useState(undefined), driversContent = _h[0], setDriversContent = _h[1];
    var _j = useState(undefined), overnightParkingContent = _j[0], setOvernightParkingContent = _j[1];
    var _k = useState(undefined), addressLookupContent = _k[0], setAddressLookupContent = _k[1];
    var _l = useState(undefined), coverStartDateContent = _l[0], setCoverStartDateContent = _l[1];
    // Account holder / driver objects
    var accountHolder = renewalAmendDetails.accountHolder;
    var accountHolderDriver = find(renewalAmendDetails.drivers, function (obj) {
        return obj.accountHolder === true;
    });
    // Other drivers on this policy
    var nonAccountHolderDrivers = filter(renewalAmendDetails.drivers, function (obj) {
        return obj.accountHolder !== true;
    });
    // get the newly added drivers from the formState
    var totalCurrentDrivers = renewalAmendDetails.drivers.length;
    var addedDrivers = renewalState.addedDrivers;
    var addedDriversTotal = renewalState.addedDrivers ? renewalState.addedDrivers.length : 0;
    // formDisplay retrieves all the values that will be used on this pages form
    // initialDisplayValues takes formDisplay and converts GW values to something more readable in the FE
    var formDisplay = undefined;
    formDisplay = getRenewalPolicyValuesForDisplay(accountHolder, accountHolderDriver);
    var initialDisplayValues = formDisplay !== undefined ? getDriverDisplayValues(formDisplay) : {};
    var policyHolderFixed = getPolicyHolderFixedValues(initialDisplayValues);
    var emailAddress1 = renewalAmendDetails.accountHolder.emailAddress1;
    var policyHolderEditable = getPolicyHolderEditableValues(initialDisplayValues, emailAddress1);
    var policyDriverFixed = getPolicyDriverFixedValues(initialDisplayValues);
    var policyDriverEditable = getPolicyDriverEditableValues(initialDisplayValues);
    var addedConvictions = renewalState.convictions ? renewalState.convictions : undefined;
    var addedClaims = renewalState.claims ? renewalState.claims : undefined;
    var convictionsAndClaimsEditable = {
        hasMotorConvictions: initialDisplayValues.hasMotorConvictions,
        hasMotorClaims: initialDisplayValues.hasMotorClaims,
    };
    var overnightAddress = renewalAmendDetails.baseData.policyAddress;
    var overnightAddressDisplay = {
        addressLine1: overnightAddress.addressLine1,
        city: overnightAddress.city,
        postalCode: overnightAddress.postalCode,
    };
    var periodStartDate = getDateObjectDisplay(renewalAmendDetails.baseData.periodStartDate);
    var initialDefaultValues = __assign(__assign(__assign(__assign({}, policyHolderEditable), policyDriverEditable), convictionsAndClaimsEditable), overnightAddressDisplay);
    var formDefaultValues = undefined;
    if (renewalState && renewalState.pageOneFormValues) {
        formDefaultValues = renewalState.pageOneFormValues;
        if (renewalState.accountHolderPrimaryAddress) {
            var _m = renewalState.accountHolderPrimaryAddress, addressLine1 = _m.addressLine1, city = _m.city, postalCode = _m.postalCode;
            formDefaultValues = __assign(__assign({}, formDefaultValues), { addressLine1: addressLine1,
                city: city,
                postalCode: postalCode });
        }
    }
    if (renewalState && !renewalState.pageOneFormValues) {
        formDefaultValues = initialDefaultValues;
    }
    var _o = useForm({
        defaultValues: __assign({}, formDefaultValues),
    }), register = _o.register, control = _o.control, watch = _o.watch, setValue = _o.setValue, handleSubmit = _o.handleSubmit, errors = _o.errors;
    var watchedFields = watch();
    var onConvictionsChange = function (selectedConvictions) {
        setRenewalState(__assign(__assign({}, renewalState), { convictions: selectedConvictions.display.length === 0 && selectedConvictions.data.length === 0
                ? undefined
                : selectedConvictions }));
    };
    var onClaimsChange = function (selectedClaims) {
        setRenewalState(__assign(__assign({}, renewalState), { claims: selectedClaims.display.length === 0 && selectedClaims.data.length === 0 ? undefined : selectedClaims }));
    };
    var setAddressChange = function (data) {
        var changedAddress = data;
        data.changed = true;
        setSelectedAddress(changedAddress);
        setRenewalState(__assign(__assign({}, renewalState), { accountHolderPrimaryAddress: changedAddress }));
        setValue('addressLine1', data.addressLine1);
        setValue('city', data.city);
        setValue('postalCode', data.postalCode);
    };
    var resetAddressChange = function () {
        setSelectedAddress(undefined);
        setValue('addressLine1', overnightAddressDisplay.addressLine1);
        setValue('city', overnightAddressDisplay.city);
        setValue('postalCode', overnightAddressDisplay.postalCode);
        setRenewalState(__assign(__assign({}, renewalState), { accountHolderPrimaryAddress: undefined }));
    };
    // Handle Add / Remove newly added drivers
    var onAddDriver = function () {
        var previousValues = {
            old: formDefaultValues,
            changed: watchedFields,
        };
        setRenewalState(__assign(__assign({}, renewalState), { pageOneFormValues: __assign(__assign({}, previousValues.old), previousValues.changed) }));
        history.push('/renewal-add-driver');
    };
    var onRemoveAddedDriver = function (index) {
        var removed = addedDrivers.filter(function (value, listIndex) { return listIndex !== index; });
        setRenewalState(__assign(__assign({}, renewalState), { addedDrivers: removed.length > 0 ? removed : [] }));
    };
    var onEditAddedDriver = function (index) {
        var previousValues = {
            old: formDefaultValues,
            changed: watchedFields,
        };
        setRenewalState(__assign(__assign({}, renewalState), { pageOneFormValues: __assign(__assign({}, previousValues.old), previousValues.changed) }));
        history.push({
            pathname: '/renewal-add-driver',
            state: {
                driver: addedDrivers[index],
                driverIndex: index,
                edit: true,
                drivers: addedDrivers,
            },
        });
    };
    var onRemoveExistingDriver = function (index) {
        var drivers = nonAccountHolderDrivers;
        var driverToRemove = nonAccountHolderDrivers[index];
        driverToRemove.updateOrRemove = 'remove';
        drivers[index] = driverToRemove;
        setRenewalState(__assign(__assign({}, renewalState), { nonAccountHolderDrivers: drivers }));
    };
    var onCancelRemoveExisitingDriver = function (index) {
        var drivers = renewalState.nonAccountHolderDrivers;
        var cancelRemoval = renewalState.nonAccountHolderDrivers[index];
        delete cancelRemoval.updateOrRemove;
        drivers[index] = cancelRemoval;
        setRenewalState(__assign(__assign({}, renewalState), { nonAccountHolderDrivers: drivers }));
    };
    var onEditExistingDriver = function (index) {
        var previousValues = {
            old: formDefaultValues,
            changed: watchedFields,
        };
        var driverFormValues = { data: getDriverDisplayValues(nonAccountHolderDrivers[index], true) };
        if (driverFormValues.data.passedTestMonth && driverFormValues.data.passedTestYear) {
            driverFormValues.data.passDate = moment({
                month: driverFormValues.data.passedTestMonth,
                year: driverFormValues.data.passedTestYear,
            }).format();
        }
        setRenewalState(__assign(__assign({}, renewalState), { pageOneFormValues: __assign(__assign({}, previousValues.old), previousValues.changed) }));
        history.push({
            pathname: '/renewal-edit-driver',
            state: {
                driver: driverFormValues,
                driverIndex: index,
                drivers: nonAccountHolderDrivers,
                personalContent: policyHolderContent,
                driverContent: driverDetailsContent,
                policy: renewalPolicy,
                formGroups: formGroups,
                watchedFields: watchedFields,
            },
        });
    };
    var onSetAdditionalDriverToMain = function (index) {
        var drivers = nonAccountHolderDrivers;
        var driverToMain = nonAccountHolderDrivers[index];
        driverToMain.mainDriver = true;
        drivers[index] = driverToMain;
        if (renewalState.additionalDriverToMain) {
            var undoMainStatus = nonAccountHolderDrivers[renewalState.additionalDriverToMainIndex];
            driverToMain.mainDriver = false;
            drivers[renewalState.additionalDriverToMainIndex] = undoMainStatus;
        }
        setRenewalState(__assign(__assign({}, renewalState), { nonAccountHolderDrivers: drivers, additionalDriverToMain: true, additionalDriverToMainIndex: index }));
    };
    var onCancelAdditionalDriverToMain = function (index) {
        var drivers = nonAccountHolderDrivers;
        var driverToMain = nonAccountHolderDrivers[index];
        driverToMain.mainDriver = false;
        drivers[index] = driverToMain;
        setRenewalState(__assign(__assign({}, renewalState), { nonAccountHolderDrivers: drivers, additionalDriverToMain: undefined, additionalDriverToMainIndex: undefined }));
    };
    var onSubmit = function (data) {
        // policy holder
        var phValues = {
            maritalStatus: data.maritalStatus,
            gender: data.gender,
            occupation: data.occupation.value,
            emailAddress1: data.emailAddress1,
        };
        var phCurrent = __assign(__assign({}, policyHolderEditable), { occupation: policyHolderEditable.occupation.value });
        // policy driver
        var pdValues = {
            accessToOtherVehicles: data.accessToOtherVehicles,
            drivingQualifications: data.drivingQualifications,
            medicalConditions: data.medicalConditions,
            nonMotoringConvictions: data.nonMotoringConvictions,
            cancelledPreviousInsurance: data.cancelledPreviousInsurance,
        };
        var accountHolderFormData = undefined;
        if (!isEqual(phValues, phCurrent))
            accountHolderFormData = __assign(__assign({}, phValues), { updated: true });
        var accountHolderDriverFormData = undefined;
        if (!isEqual(pdValues, policyDriverEditable)) {
            var values = {
                accessToOtherVehicles: data.accessToOtherVehicles,
                drivingQualifications: data.drivingQualifications,
                medicalConditions: data.medicalConditions,
                nonMotoringConvictions: data.nonMotoringConvictions,
                cancelledPreviousInsurance: data.cancelledPreviousInsurance,
            };
            accountHolderDriverFormData = getDriverValues(values);
            accountHolderDriverFormData === null || accountHolderDriverFormData === void 0 ? true : delete accountHolderDriverFormData.newlyAdded;
            accountHolderDriverFormData.updateOrRemove = 'update';
        }
        var accountHolderPrimaryAddress = undefined;
        if (renewalState.accountHolderPrimaryAddress) {
            accountHolderPrimaryAddress = renewalState.accountHolderPrimaryAddress;
        }
        if (selectedAddress !== undefined && accountHolderPrimaryAddress === undefined) {
            var addressForComparison = {
                addressLine1: selectedAddress.addressLine1,
                city: selectedAddress.city,
                postalCode: selectedAddress.postalCode,
            };
            if (!isEqual(overnightAddressDisplay, addressForComparison)) {
                accountHolderPrimaryAddress = selectedAddress;
                accountHolderPrimaryAddress.changed = true;
            }
        }
        setRenewalState(__assign(__assign({}, renewalState), { accountHolder: accountHolderFormData, accountHolderDriver: accountHolderDriverFormData, accountHolderPrimaryAddress: accountHolderPrimaryAddress, pageOneFormValues: data }));
        history.push('renewal-your-car');
    };
    useEffect(function () {
        console.clear();
        if (cmsContent === undefined) {
            // setCmsContent(content as RenewalOneContentType)
            getPageContent(ContentPageURL.RENEWAL_ABOUT_YOU).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        }
        if (cmsContent !== undefined) {
            var groups = cmsContent.contentSections[0].formGroups;
            setFormGroups(groups);
        }
        if (formGroups !== undefined) {
            setYourQuoteIntroText(getSingleFormGroupContent('quoteInformationSection', formGroups));
            setPolicyHolderContent(getSingleFormGroupContent('policyHolderSection', formGroups));
            setDriverDetailsContent(getSingleFormGroupContent('driverDetailsSection', formGroups));
            setDriversContent(getSingleFormGroupContent('numberOfDriversSection', formGroups));
            setOvernightParkingContent(getSingleFormGroupContent('overnightParkingSection', formGroups));
            setAddressLookupContent(getSingleFormGroupContent('addressLookupSection', formGroups));
            setCoverStartDateContent(getSingleFormGroupContent('coverStartDateSection', formGroups));
        }
        if (accountHolder && accountHolderDriver && nonAccountHolderDrivers) {
            setRenewalState(__assign(__assign({}, renewalState), { currentDetails: {
                    accountHolder: accountHolder,
                    accountHolderDriver: accountHolderDriver,
                    nonAccountHolderDrivers: nonAccountHolderDrivers,
                } }));
        }
    }, [cmsContent, formGroups]);
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(RenewalJourneyProgress, { step: 1 }),
        cmsContent && (React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(React.Fragment, null,
                    yourQuoteIntroText && React.createElement(Group, { title: yourQuoteIntroText.title, introText: yourQuoteIntroText.text }),
                    policyHolderContent && driverDetailsContent && formGroups && (React.createElement(EditDriverForm, { personalContent: policyHolderContent, personalFixedValues: policyHolderFixed, driverContent: driverDetailsContent, driverFixedValues: policyDriverFixed, control: control, register: register, policy: renewalPolicy, formGroups: formGroups, watchedFields: watchedFields, onConvictionsChange: function (data) { return onConvictionsChange(data); }, onClaimsChange: function (data) { return onClaimsChange(data); }, addedConvictions: addedConvictions, addedClaims: addedClaims, errors: errors })),
                    driversContent && renewalPolicy && (React.createElement(RenewalDrivers, { content: driversContent, drivers: renewalState.nonAccountHolderDrivers
                            ? renewalState.nonAccountHolderDrivers
                            : nonAccountHolderDrivers, buttonClick: function () { return onAddDriver(); }, numberOfDriversText: numberOfDrivers('textNumberDisplay', totalCurrentDrivers, addedDriversTotal), disableButton: numberOfDrivers('fullNumberDisplay', totalCurrentDrivers, addedDriversTotal) === 5 ||
                            renewalPolicy.productMiles === 20000, newDrivers: addedDrivers, onRemoveAddedDriver: onRemoveAddedDriver, onEditAddedDriver: onEditAddedDriver, onRemoveExistingDriver: onRemoveExistingDriver, onEditExistingDriver: onEditExistingDriver, cancelRemoveDriver: onCancelRemoveExisitingDriver, onSetAdditionalDriverToMain: onSetAdditionalDriverToMain, onCancelAdditionalDriverToMain: onCancelAdditionalDriverToMain })),
                    overnightParkingContent && addressLookupContent && (React.createElement(RenewalOvernightParking, { content: overnightParkingContent, register: register, addressLookupContent: addressLookupContent, addressToParent: function (data) { return setAddressChange(data); }, resetAddressChange: resetAddressChange })),
                    coverStartDateContent && (React.createElement(RenewalCoverStartDate, { content: coverStartDateContent, startDate: periodStartDate, disabled: true })),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { type: "submit", onClick: function () { return TagManagerArgs('Button', "" + window.location.pathname, 'Continue'); } }, "Continue"))))))));
};
var mapStateToProps = function (state) { return ({
    renewalAmendDetails: state.renewalAmendDetails,
    renewalPolicy: state.renewalPolicy,
    renewalState: state.renewalState,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRenewalState: function (formState) { return dispatch(actions.setAmendRenewalState(formState)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(RenewalOne);
var templateObject_1, templateObject_2;
