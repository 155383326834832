var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import HideOn from 'components/atoms/HideOn';
import HeaderSection from 'components/organisms/HeaderSection';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { MessageList, MessageListMobile, MessageDetail, MessageDetailMobile, } from 'components/organisms/Messages';
import { ContentPageURL } from 'types';
import { fetchMessages } from 'services/messageClient';
import { getPolicyIndex, getTokens } from 'utils';
import { getUuid } from 'utils/math';
import { fetchDocumentById, fetchPolicies } from 'services/client';
import PdfPager from 'components/organisms/Messages/PdfPager';
import { client, getPageContent } from 'services';
import { getDocumentType } from 'guidewirePolicyUtils';
var MessagesPage = function () {
    var _a = useState(undefined), message = _a[0], setMessage = _a[1];
    var _b = useState(undefined), visibleMessages = _b[0], setVisibleMessages = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var _d = useState(undefined), content = _d[0], setContent = _d[1];
    var preProcessMessage = function (message) {
        if (message.from === 'PolicyMigration') {
            if (message != null && message.documentKey != null) {
                var kvp = getDocumentType(message.documentKey.toLocaleLowerCase());
                message.subject = kvp.label;
            }
            else {
                message.subject = 'no subject';
            }
        }
        return message;
    };
    var fetchMessagesCallback = function (messages) {
        var transformedMessages = messages.map(function (msg) { return (msg.messageId ? msg : __assign(__assign({}, msg), { messageId: getUuid() })); });
        setVisibleMessages(transformedMessages === null || transformedMessages === void 0 ? void 0 : transformedMessages.map(function (msg) { return preProcessMessage(msg); }));
    };
    var fetchPoliciesCallback = function (policies) {
        var ids = policies.map(function (policy) { return policy.periods[0].policyId; });
        var policyIndex = getPolicyIndex();
        var tokens = getTokens();
        if (tokens) {
            fetchMessages(ids[policyIndex], tokens.accessToken, tokens.refreshToken, function (messages) {
                return fetchMessagesCallback(messages);
            });
        }
    };
    useEffect(function () {
        getPageContent(ContentPageURL.MESSAGES_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200 && payload) {
                setContent(payload.contentSections[0]);
            }
            else {
                console.log('Error fetching messages page content | status code ', status);
            }
        });
        var tokens = getTokens();
        if (tokens) {
            fetchPolicies(tokens.accessToken, tokens.refreshToken, fetchPoliciesCallback);
        }
    }, []);
    var showMessageDetails = function (message, mobile) {
        if (message.source === 'DMS') {
            var tokens = getTokens();
            if (tokens) {
                if (mobile) {
                    client.openDocument(message.content, tokens.accessToken, tokens.refreshToken);
                }
                else {
                    fetchDocumentById(message.content, tokens.accessToken, tokens.refreshToken, function (data, contentType) {
                        setMessage(__assign(__assign({}, message), { content: data, contentType: contentType }));
                    });
                }
            }
        }
        else {
            setMessage(message);
        }
    };
    // const showPDF = () => console.log('showPDF')
    // const printMessage = () => console.log('printMessage')
    return visibleMessages && content ? (React.createElement(React.Fragment, null,
        React.createElement(HeaderSection, { title: content.headerTitle, body: content.headerMessage }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(HideOn, { smallOnly: true }, message ? (React.createElement(MessageDetail, { header: content.messageListHeader, message: message, messageContent: message.source === 'DMS' && message.contentType === 'PDF' ? (React.createElement(PdfPager, { data: message.content })) : (React.createElement("div", { dangerouslySetInnerHTML: { __html: message.content } })), closeButtontext: content.closeButtonText, onMessageClose: function () { return setMessage(undefined); } })) : (React.createElement(MessageList, { header: content.messageListHeader, messages: visibleMessages, page: visibleMessages.length > 0 ? page : 0, selectPage: function (newPage) { return setPage(newPage); }, showMessageDetail: function (message) { return showMessageDetails(message, false); } }))),
                    React.createElement(HideOn, { medium: true }, message ? (React.createElement(MessageDetailMobile, { header: content.messageListHeader, message: message, messageContent: message.source === 'DMS' && message.contentType === 'PDF' ? (message.content) : (React.createElement("div", { dangerouslySetInnerHTML: { __html: message.content } })), closeButtontext: content.closeButtonText, onMessageClose: function () { return setMessage(undefined); } })) : (React.createElement(MessageListMobile, { header: content.messageListHeader, messages: visibleMessages, page: visibleMessages.length > 0 ? page : 0, selectPage: function (newPage) { return setPage(newPage); }, showMessageDetail: function (message) { return showMessageDetails(message, true); }, viewMessageButtonText: content.viewMessageButtonText })))))))) : null;
};
export default MessagesPage;
