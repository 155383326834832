var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { getPolicies } from '../services';
import { setPolicySummaries } from '../reduxStore/actions';
import { createBrowserHistory } from 'history';
var history = createBrowserHistory();
var AUTHORIZATION_BASE_URL = "https://loginservice." + process.env.MIDDLEWARE_URL;
export var isTokenValid = function () { return __awaiter(void 0, void 0, void 0, function () {
    var refreshToken, refreshAfterTime, refresh;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                refreshToken = sessionStorage.getItem('refresh_token');
                refreshAfterTime = parseInt(sessionStorage.getItem('refresh_after'));
                if (Date.now() < refreshAfterTime) {
                    return [2 /*return*/, { response: true }];
                }
                return [4 /*yield*/, axios({
                        url: AUTHORIZATION_BASE_URL + "/refresh-token?brand=itb",
                        method: 'get',
                        headers: {
                            'x-authorization': refreshToken,
                        },
                    })
                        .then(function (response) {
                        if (response.status === 200) {
                            var _a = response.data, access_token = _a.access_token, refresh_token = _a.refresh_token, expires_in = _a.expires_in;
                            var expiresSeconds = parseInt(expires_in) * 1000 - 3000;
                            sessionStorage.setItem('access_token', access_token);
                            sessionStorage.setItem('refresh_token', refresh_token);
                            sessionStorage.setItem('refresh_after', JSON.stringify(Date.now() + expiresSeconds));
                            return { response: true };
                        }
                        else
                            return { response: false };
                    })
                        .catch(function (error) {
                        console.log(error);
                        return { response: true };
                    })];
            case 1:
                refresh = _a.sent();
                return [2 /*return*/, refresh];
        }
    });
}); };
export var documentsRefresh = function () { return __awaiter(void 0, void 0, void 0, function () {
    var access_token, docsRefreshed;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                access_token = sessionStorage.getItem('access_token');
                return [4 /*yield*/, getPolicies(access_token).then(function (result) {
                        var status = result.status, policies = result.payload;
                        if (status === 200) {
                            setPolicySummaries(policies);
                            return { response: true };
                        }
                        return { response: false };
                    })];
            case 1:
                docsRefreshed = _a.sent();
                return [2 /*return*/, docsRefreshed];
        }
    });
}); };
export var authorizationCheck = function () { return __awaiter(void 0, void 0, void 0, function () {
    var token, docs;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, isTokenValid()];
            case 1:
                token = (_a.sent()).response;
                if (!(token === true)) return [3 /*break*/, 3];
                return [4 /*yield*/, documentsRefresh()];
            case 2:
                docs = (_a.sent()).response;
                if (docs === true)
                    return [2 /*return*/, { response: true }];
                return [3 /*break*/, 4];
            case 3:
                history.push('/');
                _a.label = 4;
            case 4: return [2 /*return*/, { response: false }];
        }
    });
}); };
