var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getSingleButtonContent } from 'components/organisms/Form';
import Title from 'components/atoms/Title';
import Button from 'components/atoms/Button';
import HideOn from 'components/atoms/HideOn';
import ListRow from 'components/molecules/ListRow';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 40px;\n"], ["\n  margin-bottom: 40px;\n"])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #202020;\n"], ["\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #202020;\n"])));
var FullWidthRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  box-sizing: border-box;\n\n  border-bottom: ", ";\n  padding-bottom: ", ";\n  background-color: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  box-sizing: border-box;\n\n  border-bottom: ", ";\n  padding-bottom: ", ";\n  background-color: ", ";\n"])), function (props) { return (props.tableHeader ? 'solid 1px #898989' : 'solid 1px rgba(137, 137, 137, 0.2)'); }, function (props) { return (props.tableHeader ? '20px' : '0'); }, function (props) { return (props.newDriver ? 'rgba(246, 246, 246, 0.5)' : 'white'); });
var LeftWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 70%;\n"], ["\n  width: 70%;\n"])));
var RightWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 30%;\n  margin: auto 0;\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 10px;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  width: 30%;\n  margin: auto 0;\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 10px;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var TableRow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: repeat(4, minmax(0, 1fr));\n"], ["\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: repeat(4, minmax(0, 1fr));\n"])));
var TableHeader = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n"], ["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n"])));
var Cell = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 80px;\n  display: flex;\n  align-items: center;\n  font-weight: ", ";\n  font-stretch: normal;\n  font-style: ", ";\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n  overflow-wrap: anywhere;\n\n  color: ", ";\n"], ["\n  height: 80px;\n  display: flex;\n  align-items: center;\n  font-weight: ", ";\n  font-stretch: normal;\n  font-style: ", ";\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n  overflow-wrap: anywhere;\n\n  color: ",
    ";\n"])), function (props) { return (props.driverStatus ? '400' : '300'); }, function (props) { return (props.driverStatus ? 'italic' : 'normal'); }, function (props) {
    if (props.driverStatus === 'main')
        return '#025c8d';
    if (props.driverStatus === 'additional')
        return '#943e00';
    if (props.driverStatus === 'new')
        return '#007f49';
    else
        return '#202020';
});
var MobileDriverButtons = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  button {\n    margin-bottom: 10px;\n  }\n"], ["\n  button {\n    margin-bottom: 10px;\n  }\n"])));
var MobileSpace = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
var YourDrivers = function (_a) {
    var yourDriversGroup = _a.yourDriversGroup, currentDriversData = _a.currentDriversData, newDriversData = _a.newDriversData, removeNewDriver = _a.removeNewDriver;
    var headers = yourDriversGroup.list[0].listItems;
    var addDriverBtn = getSingleButtonContent('addDriverBtn', yourDriversGroup.buttons);
    var amendDriverBtn = getSingleButtonContent('amendDriverBtn', yourDriversGroup.buttons);
    var removeDriverBtn = getSingleButtonContent('removeDriverBtn', yourDriversGroup.buttons);
    var history = useHistory();
    var currentDrivers = currentDriversData.map(function (driver) {
        return Object.fromEntries(headers.map(function (key) {
            if (key.value === 'mainDriver' && driver[key.value] === true)
                return [key.value, 'Main driver'];
            if (key.value === 'mainDriver' && driver[key.value] === false)
                return [key.value, 'Additional'];
            if (key.value === 'dateOfBirth')
                return [key.value, moment(driver[key.value]).format('DD/MM/YYYY')];
            return [key.value, driver[key.value]];
        }));
    });
    var newDrivers = undefined;
    if (newDriversData)
        newDrivers = newDriversData.map(function (driver) {
            return Object.fromEntries(headers.map(function (key) {
                if (key.value === 'mainDriver')
                    return [key.value, 'New details'];
                if (key.value === 'dateOfBirth')
                    return [key.value, moment(driver[key.value]).format('DD/MM/YYYY')];
                return [key.value, driver[key.value]];
            }));
        });
    var getMobileValueStyle = function (status) {
        var mainDriverStyles = { color: '#025c8d', fontStyle: 'italic', fontWeight: 'normal' };
        var additionalDriverStyles = { color: '#943e00', fontStyle: 'italic', fontWeight: 'normal' };
        var newDriverStyles = { color: '#30A369', fontStyle: 'italic', fontWeight: 'normal' };
        if (status === true)
            return mainDriverStyles;
        if (status === false)
            return additionalDriverStyles;
        if (status === 'new')
            return newDriverStyles;
        return undefined;
    };
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null,
            React.createElement(Title, null, yourDriversGroup.title)),
        React.createElement(HideOn, { smallOnly: true },
            React.createElement(FullWidthRow, { tableHeader: true },
                React.createElement(LeftWrapper, null,
                    React.createElement(TableRow, null, headers.map(function (item) { return (React.createElement(TableHeader, { key: item.label }, item.label)); }))),
                React.createElement(RightWrapper, null,
                    React.createElement(Button, { buttonStyle: addDriverBtn.style, onClick: function () {
                            history.push('/add-driver');
                            TagManagerArgs('Button', "" + window.location.pathname, addDriverBtn.text);
                        } }, addDriverBtn.text))),
            currentDrivers.map(function (row, index) { return (React.createElement(FullWidthRow, { key: row.firstName + " " + row.lastName + " " + row.dateOfBirth },
                React.createElement(LeftWrapper, null,
                    React.createElement(TableRow, null, headers.map(function (cell) { return (React.createElement(React.Fragment, { key: row.firstName + " " + row.lastName + " " + row.dateOfBirth + " " + cell.value }, cell.value !== 'mainDriver' ? (React.createElement(Cell, null, row[cell.value])) : (React.createElement(Cell, { driverStatus: currentDriversData[index].mainDriver === true ? 'main' : 'additional' }, row.mainDriver)))); }))))); }),
            newDrivers &&
                newDrivers.map(function (row, index) { return (React.createElement(FullWidthRow, { key: row.firstName + " " + row.lastName + " " + row.dateOfBirth, newDriver: true },
                    React.createElement(LeftWrapper, null,
                        React.createElement(TableRow, null, headers.map(function (cell) { return (React.createElement(React.Fragment, { key: row.firstName + " " + row.lastName + " " + row.dateOfBirth + " " + cell.value }, cell.value !== 'mainDriver' ? (React.createElement(Cell, null, row[cell.value])) : (React.createElement(Cell, { driverStatus: "new" }, row.mainDriver)))); }))),
                    React.createElement(RightWrapper, null,
                        React.createElement(Button, { onClick: function () {
                                removeNewDriver(newDriversData[index], index);
                                TagManagerArgs('Button', window.location.pathname + " - Remove driver", 'Remove');
                            }, buttonStyle: removeDriverBtn.style }, "Remove")))); })),
        React.createElement(HideOn, { medium: true },
            React.createElement(MobileSpace, null,
                React.createElement(Button, { buttonStyle: addDriverBtn.style, rightIcon: addDriverBtn.iconUrl, fullWidth: true }, addDriverBtn.text)),
            currentDrivers.map(function (driver, driverIndex) { return (React.createElement(React.Fragment, { key: driver.firstName + " " + driver.lastName + " " + driver.dateOfBirth + " " + driverIndex },
                React.createElement(MobileSpace, null, headers.map(function (item, index) { return (React.createElement(ListRow, { key: item.label + " " + index, label: item.label, value: driver[item.value], style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' }, valueStyle: item.value === 'mainDriver'
                        ? getMobileValueStyle(currentDriversData[driverIndex].mainDriver)
                        : undefined })); })),
                React.createElement(MobileSpace, null, currentDriversData[driverIndex].mainDriver === true && (React.createElement(MobileDriverButtons, null,
                    React.createElement(Button, { fullWidth: true, buttonStyle: amendDriverBtn.style, rightIcon: amendDriverBtn.iconUrl }, amendDriverBtn.text)))))); }),
            newDrivers && (React.createElement(React.Fragment, null,
                React.createElement(TitleWrapper, null,
                    React.createElement(Title, null, "New drivers")),
                newDrivers.map(function (driver, driverIndex) { return (React.createElement(React.Fragment, { key: driver.firstName + " " + driver.lastName + " " + driver.dateOfBirth + " " + driverIndex },
                    React.createElement(MobileSpace, null, headers.map(function (item, index) { return (React.createElement(ListRow, { key: item.label + " " + index, label: item.label, value: driver[item.value], style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' }, valueStyle: item.value === 'mainDriver' ? getMobileValueStyle('new') : undefined })); })),
                    React.createElement(MobileSpace, null,
                        React.createElement(MobileDriverButtons, null,
                            React.createElement(Button, { fullWidth: true, buttonStyle: removeDriverBtn.style, rightIcon: removeDriverBtn.iconUrl }, removeDriverBtn.text),
                            React.createElement(Button, { fullWidth: true, buttonStyle: amendDriverBtn.style, rightIcon: amendDriverBtn.iconUrl }, amendDriverBtn.text))))); }))))));
};
export default YourDrivers;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
