var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { color, fontWeight, media } from 'styles';
import CloseIcon from 'assets/close_light.svg';
import CheckIcon from 'assets/check.svg';
import PlusIcon from 'assets/plus.svg';
import RemoveIcon from 'assets/remove.svg';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  user-select: none;\n  margin-bottom: 12px;\n\n  ul {\n    font-size: 14px;\n    font-weight: ", ";\n\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 6px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  a {\n    color: ", ";\n  }\n"], ["\n  user-select: none;\n  margin-bottom: 12px;\n\n  ul {\n    font-size: 14px;\n    font-weight: ", ";\n\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 6px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  a {\n    color: ", ";\n  }\n"])), fontWeight.light, color.green);
var TitleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n  border: solid 1px rgba(59, 156, 115, 0.5);\n  border-radius: 1px;\n  font-weight: ", ";\n  padding: 16px 24px;\n"], ["\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n  border: solid 1px rgba(59, 156, 115, 0.5);\n  border-radius: 1px;\n  font-weight: ", ";\n  padding: 16px 24px;\n"])), function (props) { return (props.selected ? 'rgba(59, 156, 115, 0.06)' : 'rgba 137 137 137, 0.06'); }, fontWeight.medium);
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 4;\n"], ["\n  flex: 4;\n"])));
var IconContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  flex: 1;\n  justify-content: space-between;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  flex: 1;\n  justify-content: space-between;\n\n  ",
    "\n"])), function (props) { return (props.selected ? color.green : '#ff122f'); }, media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 4;\n  "], ["\n    flex: 4;\n  "]))));
var DetailsWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 2px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  margin-top: 12px;\n"], ["\n  border-radius: 2px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  margin-top: 12px;\n"])));
var IncludesWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: 'white';\n  font-size: 14px;\n  font-weight: ", ";\n  padding: 8px 24px;\n"], ["\n  background: 'white';\n  font-size: 14px;\n  font-weight: ", ";\n  padding: 8px 24px;\n"])), fontWeight.light);
var IconWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  background-color: ", ";\n  color: white;\n  cursor: pointer;\n"], ["\n  width: 24px;\n  height: 24px;\n  background-color: ", ";\n  color: white;\n  cursor: pointer;\n"])), color.green);
var Price = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  strong {\n    display: block;\n    font-size: 25px;\n  }\n"], ["\n  strong {\n    display: block;\n    font-size: 25px;\n  }\n"])));
var AddonForMyCover = function (_a) {
    var title = _a.title, addonsList = _a.addonsList, includes = _a.includes, _b = _a.selected, selected = _b === void 0 ? false : _b, price = _a.price, monthlyBreakdown = _a.monthlyBreakdown, showAddonsSection = _a.showAddonsSection;
    var _c = useState(false), expanded = _c[0], setExpanded = _c[1];
    return (React.createElement(Root, null, showAddonsSection ?
        React.createElement(React.Fragment, null, !selected && title !== 'Key Protect' ?
            React.createElement(React.Fragment, null,
                React.createElement(TitleContainer, { selected: selected },
                    React.createElement(TitleWrapper, null, title),
                    React.createElement(IconContainer, { selected: selected },
                        selected ? React.createElement(CheckIcon, null) : React.createElement(CloseIcon, null),
                        React.createElement(IconWrapper, { onClick: function () { return setExpanded(!expanded); } }, expanded ? React.createElement(RemoveIcon, null) : React.createElement(PlusIcon, null)))),
                expanded && (React.createElement(DetailsWrapper, null,
                    React.createElement(IncludesWrapper, null,
                        addonsList && React.createElement(ReactMarkdown, { source: addonsList }),
                        includes && includes.map(function (item) { return React.createElement(ReactMarkdown, { key: item, source: "- " + item }); }),
                        React.createElement(Price, null,
                            React.createElement(ReactMarkdown, { source: price + " " + (monthlyBreakdown ? monthlyBreakdown : '') }))))))
            : React.createElement(React.Fragment, null))
        :
            React.createElement(React.Fragment, null,
                React.createElement(TitleContainer, { selected: selected },
                    React.createElement(TitleWrapper, null, title),
                    React.createElement(IconContainer, { selected: selected },
                        selected ? React.createElement(CheckIcon, null) : React.createElement(CloseIcon, null),
                        React.createElement(IconWrapper, { onClick: function () { return setExpanded(!expanded); } }, expanded ? React.createElement(RemoveIcon, null) : React.createElement(PlusIcon, null)))),
                expanded && (React.createElement(DetailsWrapper, null,
                    React.createElement(IncludesWrapper, null,
                        addonsList && React.createElement(ReactMarkdown, { source: addonsList }),
                        includes && includes.map(function (item) { return React.createElement(ReactMarkdown, { key: item, source: "- " + item }); }),
                        React.createElement(Price, null,
                            React.createElement(ReactMarkdown, { source: price + " " + (monthlyBreakdown ? monthlyBreakdown : '') }))))))));
};
export default AddonForMyCover;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
