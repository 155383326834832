var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import HideOn from 'components/atoms/HideOn';
import ListRow from 'components/molecules/ListRow';
import ArrowUpIcon from 'assets/arrow_up.svg';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ItbLogo from '../../../assets/itb-logo.svg';
import { fontWeight, media } from 'styles';
import HelpInfos from './HelpInfos';
var Root = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: rgba(137, 137, 137, 0.1);\n  padding: 30px 20px 20px 20px;\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  background-color: rgba(137, 137, 137, 0.1);\n  padding: 30px 20px 20px 20px;\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n"])), media.smallOnly(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 1200px;\n  "], ["\n    height: 1200px;\n  "]))), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: white;\n    padding: 20px;\n  "], ["\n    background-color: white;\n    padding: 20px;\n  "]))), media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: white;\n    padding: 10px 40px 30px;\n  "], ["\n    background-color: white;\n    padding: 10px 40px 30px;\n  "]))), media.large(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 10px 75px 30px;\n  "], ["\n    padding: 10px 75px 30px;\n  "]))));
var ImageWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0 auto;\n\n  img {\n    object-fit: cover;\n    width: 150px;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  margin: 0 auto;\n\n  img {\n    object-fit: cover;\n    width: 150px;\n  }\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 178px;\n    margin-bottom: 5px;\n  "], ["\n    width: 178px;\n    margin-bottom: 5px;\n  "]))), media.large(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 225px;\n    margin-bottom: 10px;\n  "], ["\n    width: 225px;\n    margin-bottom: 10px;\n  "]))));
var FeatureWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-bottom: 20px;\n\n  ", "\n\n  ", "\n"], ["\n  margin-bottom: 20px;\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin-bottom: 20px;\n  "], ["\n    margin-bottom: 20px;\n  "]))), media.large(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-bottom: 40px;\n  "], ["\n    margin-bottom: 40px;\n  "]))));
var FeatureTitle = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 10px;\n\n  ", "\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 10px;\n\n  ",
    "\n"])), fontWeight.medium, media.large(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    font-size: 30px;\n  "], ["\n    font-size: 30px;\n  "]))));
var HelpTitle = styled(FeatureTitle)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-bottom: 20px;\n\n  ", "\n"], ["\n  margin-bottom: 20px;\n\n  ",
    "\n"])), media.large(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    margin-bottom: 30px;\n  "], ["\n    margin-bottom: 30px;\n  "]))));
var FeatureText = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-weight: ", ";\n  line-height: 1.5;\n\n  ", "\n\n  ", "\n"], ["\n  font-weight: ", ";\n  line-height: 1.5;\n\n  ",
    "\n\n  ",
    "\n"])), fontWeight.light, media.medium(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    font-size: 14px;\n  "], ["\n    font-size: 14px;\n  "]))), media.large(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    font-size: 16px;\n  "], ["\n    font-size: 16px;\n  "]))));
var LegalInfoWrapper = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: ", ";\n  line-height: 20px;\n  margin-top: 20px;\n\n  ", "\n"], ["\n  font-size: 12px;\n  font-weight: ", ";\n  line-height: 20px;\n  margin-top: 20px;\n\n  ",
    "\n"])), fontWeight.light, media.medium(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    margin-top: 30px;\n  "], ["\n    margin-top: 30px;\n  "]))));
var HelpSection = function (_a) {
    var content = _a.content;
    var _b = useState(false), openHelp = _b[0], setHelpOpen = _b[1];
    var 
    // imageUrl,
    features = content.features, helpTitle = content.helpTitle, helpSubtitle = content.helpSubtitle, chatText = content.chatText, callUsText = content.callUsText, claimCallIntroText = content.claimCallIntroText, claimCallText = content.claimCallText, claimCallNumber = content.claimCallNumber, repairCallText = content.repairCallText, repairCallNumber = content.repairCallNumber, legalInfo = content.legalInfo;
    return (React.createElement(Root, null,
        React.createElement(HideOn, { smallOnly: true },
            React.createElement(ImageWrapper, null,
                React.createElement(ItbLogo, null))),
        features.map(function (feature) { return (React.createElement(FeatureWrapper, { key: feature.label },
            React.createElement(FeatureTitle, null, feature.label),
            React.createElement(FeatureText, null, feature.value))); }),
        React.createElement(HelpTitle, null, helpTitle),
        React.createElement(ListRow, { style: { borderTop: '1px solid rgba(137, 137, 137, 0.5)', borderBottom: '1px solid rgba(137, 137, 137, 0.5)' }, label: helpSubtitle, labelStyle: { fontWeight: fontWeight.light }, icon: openHelp ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null), onClick: function () { return setHelpOpen(!openHelp); } }),
        openHelp && (React.createElement(HelpInfos, { chatText: chatText, callUsText: callUsText, claimCallIntroText: claimCallIntroText, claimCallText: claimCallText, claimCallNumber: claimCallNumber, repairCallText: repairCallText, repairCallNumber: repairCallNumber })),
        React.createElement(LegalInfoWrapper, null, legalInfo)));
};
export default HelpSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
