export var TypeKeyEnums;
(function (TypeKeyEnums) {
    TypeKeyEnums["TYPE_NAME"] = "typeName";
    TypeKeyEnums["TYPE_KEY_VEHICLE_CLASS_OF_USE"] = "typekey.VehicleClassOfUseType_itb";
    TypeKeyEnums["TYPE_KEY_VEHICLE_SECURITY_DEVICE"] = "typekey.VehicleSecurityDeviceType_itb";
    TypeKeyEnums["TYPE_KEY_NAME_PREFIX"] = "typekey.NamePrefix";
    TypeKeyEnums["TYPE_KEY_MARITAL_STATUS"] = "typekey.MaritalStatus";
    TypeKeyEnums["TYPE_KEY_DRIVING_LICENSE"] = "typekey.DrivingLicenseType_itb";
    TypeKeyEnums["TYPE_KEY_DRIVING_QUALIFICATIONS"] = "typekey.DrivingQualifications_itb";
    TypeKeyEnums["TYPE_KEY_MEDICAL_CONDITIONS"] = "typekey.MedicalConditions_itb";
    TypeKeyEnums["TYPE_KEY_OCCUPATION"] = "typekey.OccupationType_itb";
    TypeKeyEnums["TYPE_KEY_NIGHTTIME_PARKING"] = "typekey.VehicleNightTimeParkingType_itb";
    TypeKeyEnums["TYPE_KEY_DAYTIME_PARKING"] = "typekey.VehicleDayTimeParkingType_itb";
    TypeKeyEnums["TYPE_KEY_DOCUMENT_TYPE"] = "typekey.DocumentType";
})(TypeKeyEnums || (TypeKeyEnums = {}));
export var DateFormat;
(function (DateFormat) {
    DateFormat["DD_MM_YYYY"] = "DD / MM / YYYY";
    DateFormat["MM_YYYY"] = "MM / YYYY";
})(DateFormat || (DateFormat = {}));
export var StringValue;
(function (StringValue) {
    StringValue["YEAR"] = "year";
    StringValue["YEARS"] = "years";
    StringValue["MILES"] = "miles";
})(StringValue || (StringValue = {}));
