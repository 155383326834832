import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import ListRow from 'components/molecules/ListRow';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
export var QuotePrice = function (_a) {
    var quote = _a.quote, cmsContent = _a.cmsContent;
    var quotePrices = find(cmsContent, function (obj) {
        return obj.formGroupId === 'quotePrices';
    });
    var list = quotePrices.list[0].listItems;
    var replacePlacholderText = function (value) {
        var placeholders = {
            CHANGE_IN_COST_AMOUNT_OR_REFUND: quote.changeInCost.amount < 0 ? 'Return premium' : 'Additional premium',
            TOTAL_COST_OR_REFUND: quote.totalCost.amount < 0 ? 'Total return premium' : 'Total cost',
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/CHANGE_IN_COST_AMOUNT_OR_REFUND|TOTAL_COST_OR_REFUND/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null, list.map(function (row) { return (React.createElement(ListRow, { key: row.label, label: replacePlacholderText(row.label), value: "\u00A3 " + get(quote, row.value).toFixed(2), style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' } })); })))));
};
