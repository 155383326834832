export var NotificationLevel;
(function (NotificationLevel) {
    NotificationLevel["INFO"] = "info";
    NotificationLevel["WARNING"] = "warning";
    NotificationLevel["ERROR"] = "error";
})(NotificationLevel || (NotificationLevel = {}));
export var PaymentValidationEndpoint;
(function (PaymentValidationEndpoint) {
    PaymentValidationEndpoint["VALIDATE_CARD"] = "validatecard";
    PaymentValidationEndpoint["MONTHLY_REMAINING_INSTALLMENTS"] = "getremaininginstalments";
})(PaymentValidationEndpoint || (PaymentValidationEndpoint = {}));
