var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import HideOn from 'components/atoms/HideOn';
import React from 'react';
import styled from 'styled-components';
import Layout, { PaddingHorizontal, PaddingVertical } from '../Layout';
var content = [
    {
        stepNumber: '1',
        label: 'Policy and drivers',
    },
    {
        stepNumber: '2',
        label: 'Your car',
    },
    {
        stepNumber: '3',
        label: 'Quote',
    },
    {
        stepNumber: '4',
        label: 'Payment',
    },
    {
        stepNumber: '5',
        label: 'Renewal confirmation',
    },
];
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #007f49;\n  font-weight: 300;\n  color: white;\n"], ["\n  background-color: #007f49;\n  font-weight: 300;\n  color: white;\n"])));
var ProgressWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n"], ["\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n"])));
var Step = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var Circle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 30px;\n  width: 30px;\n  min-width: 30px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  margin-left: ", ";\n  margin-right: ", ";\n  padding: 6px;\n  border: solid 2px rgba(255, 255, 255, 0.5);\n  border-radius: 100%;\n  box-sizing: border-box;\n  background-color: ", ";\n  background-clip: padding-box;\n  color: ", ";\n  font-weight: 400;\n"], ["\n  height: 30px;\n  width: 30px;\n  min-width: 30px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  margin-left: ", ";\n  margin-right: ", ";\n  padding: 6px;\n  border: solid 2px rgba(255, 255, 255, 0.5);\n  border-radius: 100%;\n  box-sizing: border-box;\n  background-color: ", ";\n  background-clip: padding-box;\n  color: ", ";\n  font-weight: 400;\n"])), function (props) { return (props.mobile ? '0' : '5px'); }, function (props) { return (props.mobile ? '10px' : '5px'); }, function (props) { return (props.stepStyle ? 'white' : '#007f49'); }, function (props) { return (props.stepStyle ? '#007f49' : 'white'); });
var StepLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: ", ";\n  text-align: center;\n"], ["\n  margin-top: ", ";\n  text-align: center;\n"])), function (props) { return (props.mobile ? '0' : '10px;'); });
var FirstOrLastStep = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  align-items: center;\n  display: flex;\n  justify-content: ", ";\n"], ["\n  width: 100%;\n  align-items: center;\n  display: flex;\n  justify-content: ",
    ";\n"])), function (props) {
    if (props.firstOrLast === 'first')
        return 'flex-end';
    if (props.firstOrLast === 'last')
        return 'flex-start';
    return '';
});
var FirstOrLastLine = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 1px;\n  background-color: rgba(255, 255, 255, 0.5);\n  width: calc(100% / 2 - 20px);\n"], ["\n  height: 1px;\n  background-color: rgba(255, 255, 255, 0.5);\n  width: calc(100% / 2 - 20px);\n"])));
var CenterStep = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n"])));
var Line = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 1px;\n  width: 100%;\n  background-color: rgba(255, 255, 255, 0.5);\n"], ["\n  height: 1px;\n  width: 100%;\n  background-color: rgba(255, 255, 255, 0.5);\n"])));
var MobileStep = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var MobileLine = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 1px;\n  height: 10px;\n  margin-left: 15px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  background-color: rgba(255, 255, 255, 0.5);\n"], ["\n  width: 1px;\n  height: 10px;\n  margin-left: 15px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  background-color: rgba(255, 255, 255, 0.5);\n"])));
var RenewalJourneyProgress = function (_a) {
    var step = _a.step;
    return (React.createElement(Root, null,
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(HideOn, { smallOnly: true },
                        React.createElement(ProgressWrapper, null, content.map(function (item, index) {
                            if (index === 0 || index === content.length - 1) {
                                return (React.createElement(Step, { key: item.label },
                                    React.createElement(FirstOrLastStep, { firstOrLast: index === 0 ? 'first' : 'last' },
                                        index !== 0 && React.createElement(FirstOrLastLine, null),
                                        React.createElement(Circle, { stepStyle: index < step }, item.stepNumber),
                                        index === 0 && React.createElement(FirstOrLastLine, null)),
                                    React.createElement(StepLabel, null, item.label)));
                            }
                            return (React.createElement(Step, { key: item.label },
                                React.createElement(CenterStep, null,
                                    React.createElement(Line, null),
                                    React.createElement(Circle, { stepStyle: index < step }, item.stepNumber),
                                    React.createElement(Line, null)),
                                React.createElement(StepLabel, null, item.label)));
                        }))),
                    React.createElement(HideOn, { medium: true }, content.map(function (item, index) { return (React.createElement(React.Fragment, { key: item.label },
                        React.createElement(MobileStep, { key: item.label },
                            React.createElement(Circle, { mobile: true, stepStyle: index < step }, item.stepNumber),
                            React.createElement(StepLabel, { mobile: true }, item.label)),
                        index !== content.length - 1 && React.createElement(MobileLine, null))); })))))));
};
export default RenewalJourneyProgress;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
