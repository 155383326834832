var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ReactDOM from 'react-dom';
import { MapLayer, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import Legend from './Legend';
var acceleratingIconUrl = 'https://images.ctfassets.net/vjsx7b53r6h5/4MNTPP69xDVnuPTAfZDtVe/72a7b77cf8331364ff483212128fa991/acceleration.png';
var brakingIconUrl = 'https://images.ctfassets.net/vjsx7b53r6h5/1A1XlwMyrW77x0aFwhhOY5/fdadb3fbabee79718794f8320bbca47a/braking.png';
var startIconUrl = 'https://images.ctfassets.net/vjsx7b53r6h5/5tF6OjD1pUQu1MoFFxH5B/f3ec626a40b458ccd2f796aa237b41f4/start_location.svg';
var endIconUrl = 'https://images.ctfassets.net/vjsx7b53r6h5/LjxIvImxIOMuNOrO8hQcN/55d02bfa9968d892bfce135a42542ff6/end.png';
var startIcon = L.icon({
    iconUrl: startIconUrl,
    iconSize: [24, 41],
    iconAnchor: [12, 20],
});
var endIcon = L.icon({
    iconUrl: endIconUrl,
    iconSize: [20, 20],
    iconAnchor: [20, 20],
});
var acceleratingIcon = L.icon({
    iconUrl: acceleratingIconUrl,
    iconSize: [20, 20],
    iconAnchor: [20, 20],
});
var brakingIcon = L.icon({
    iconUrl: brakingIconUrl,
    iconSize: [20, 20],
    iconAnchor: [20, 20],
});
var Routing = /** @class */ (function (_super) {
    __extends(Routing, _super);
    function Routing() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Routing.prototype.createLeafletElement = function () {
        var _a = this.props, map = _a.map, origin = _a.origin, destination = _a.destination, acceleratingPoints = _a.acceleratingPoints, brakingPoints = _a.brakingPoints, waypoints = _a.waypoints;
        // Draw a INVISIBLE route between origin and destination. This is route is used
        // to determine the zoom level of the map.
        var journeyPane = map.leafletElement.createPane('journeyPane');
        journeyPane.style.zIndex = 200;
        var routingControl = L.Routing.control({
            waypoints: [origin, destination],
            lineOptions: {
                styles: [{ pane: 'journeyPane', color: '#227BA4', opacity: 0 }],
                addWaypoints: false,
            },
            createMarker: function (i, wp) {
                var options = {
                    icon: i === 0 ? startIcon : endIcon,
                };
                return L.marker(wp.latLng, options);
            },
        });
        var leafletElement = routingControl.addTo(map.leafletElement);
        // Draw a polyline between nodes to construct the route
        var totalPoints = waypoints.map(function (point) { return L.latLng(point.lat, point.lng); });
        totalPoints.map(function (point, index) {
            if (index < totalPoints.length - 2) {
                L.polyline([
                    [point.lat, point.lng],
                    [totalPoints[index + 1].lat, totalPoints[index + 1].lng],
                ], { color: '#227BA4', weight: 7 }).addTo(map.leafletElement);
            }
        });
        // render acceleratingPoints and brakingPoints
        // acceleratingPoints?.map(point => {
        //   L.marker([point.lat, point.lng], { icon: acceleratingIcon }).addTo(map.leafletElement)
        // })
        // render brakingPoints
        // brakingPoints?.map(point => {
        //   L.marker([point.lat, point.lng], { icon: brakingIcon }).addTo(map.leafletElement)
        // })
        // render map legend
        var legend = new L.Control({ position: 'bottomleft' });
        legend.onAdd = function () {
            var div = L.DomUtil.create('div', 'info legend');
            ReactDOM.render(React.createElement(Legend, null), div);
            return div;
        };
        legend.addTo(map.leafletElement);
        return leafletElement.getPlan();
    };
    return Routing;
}(MapLayer));
export default withLeaflet(Routing);
