var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { withStyles } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';
import React, { useState } from 'react';
import styled from 'styled-components';
import { color } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var AntSwitch = withStyles(function () { return ({
    root: {
        width: 42,
        height: 22,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 3,
        color: 'white',
        '&$checked': {
            color: 'white',
            '& + $track': {
                opacity: 1,
                backgroundColor: color.green,
            },
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: 12,
        opacity: 1,
        backgroundColor: color.green,
    },
    checked: {},
}); })(MuiSwitch);
var Switch = function (_a) {
    var _b = _a.defaultValue, defaultValue = _b === void 0 ? false : _b, onChange = _a.onChange;
    var _c = useState(defaultValue), checked = _c[0], setChecked = _c[1];
    var handleChange = function (e) {
        setChecked(!checked);
        onChange(e.target.checked);
    };
    return (React.createElement(Root, null,
        React.createElement(AntSwitch, { checked: checked, onChange: handleChange, name: "switch" })));
};
export default Switch;
var templateObject_1;
