var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from 'styles';
export var Root = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n"])), function (props) {
    return props.smallOnly && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
        "\n    "])), props.media.smallOnly(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        display: none;\n      "], ["\n        display: none;\n      "]))));
}, function (props) {
    return props.medium && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
        "\n    "])), props.media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "]))));
}, function (props) {
    return props.mediumOnly && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
        "\n    "])), props.media.mediumOnly(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "]))));
}, function (props) {
    return props.large && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
        "\n    "])), props.media.large(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "]))));
});
export var HideOnContext = React.createContext({ media: styles.media });
var HideOn = function (_a) {
    var children = _a.children, large = _a.large, medium = _a.medium, mediumOnly = _a.mediumOnly, smallOnly = _a.smallOnly;
    return (React.createElement(HideOnContext.Consumer, null, function (context) { return (React.createElement(Root, { smallOnly: smallOnly, medium: medium, mediumOnly: mediumOnly, large: large, media: context.media }, children)); }));
};
export default HideOn;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
