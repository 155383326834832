var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import find from 'lodash/find';
import moment from 'moment';
import Layout, { PaddingHorizontal, PaddingVertical } from '../Layout';
import Title from 'components/atoms/Title';
import { DropDownSection } from './DropDownSection';
import Cross from 'assets/close.svg';
import Tick from 'assets/check.svg';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  * {\n    box-sizing: border-box;\n  }\n"], ["\n  * {\n    box-sizing: border-box;\n  }\n"])));
var IntroText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 30px 0;\n  font-size: 16px;\n\n  strong {\n    font-weight: 500;\n  }\n"], ["\n  margin: 30px 0;\n  font-size: 16px;\n\n  strong {\n    font-weight: 500;\n  }\n"])));
var MileageBottomBorder = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 50px;\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 50px;\n"])));
var AddOnsWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  margin-top: 20px;\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  margin-top: 20px;\n"])));
var AddOnRow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 15px 16px;\n  border-top: ", ";\n  display: flex;\n  justify-content: space-between;\n"], ["\n  padding: 15px 16px;\n  border-top: ", ";\n  display: flex;\n  justify-content: space-between;\n"])), function (props) { return (props.isFirst ? '0' : '1px solid rgba(137, 137, 137, 0.2)'); });
var SVGWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var SVGRed = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  svg {\n    fill: red;\n  }\n  display: flex;\n  align-items: center;\n"], ["\n  svg {\n    fill: red;\n  }\n  display: flex;\n  align-items: center;\n"])));
var SVGGreen = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  svg {\n    fill: green;\n  }\n  display: flex;\n  align-items: center;\n"], ["\n  svg {\n    fill: green;\n  }\n  display: flex;\n  align-items: center;\n"])));
var RenewalYourCover = function (_a) {
    var content = _a.content, accHolderDropDown = _a.accHolderDropDown, driversValues = _a.driversValues, coverDropDownValues = _a.coverDropDownValues, selectedCover = _a.selectedCover, _b = _a.showBreakdown, showBreakdown = _b === void 0 ? true : _b, renewalEffectiveDate = _a.renewalEffectiveDate;
    var defactoDate = moment('20230601', 'YYYYMMDD');
    var showAddonsSection = (renewalEffectiveDate.isSameOrAfter(defactoDate));
    var yourCoverTitle = find(content, function (obj) {
        return obj.formGroupId === 'yourCoverTitle';
    });
    var aboutYouDropdown = find(content, function (obj) {
        return obj.formGroupId === 'aboutYouDropdown';
    });
    var driversDropdown = find(content, function (obj) {
        return obj.formGroupId === 'driversDropdown';
    });
    var yourCoverDropdown = find(content, function (obj) {
        return obj.formGroupId === 'yourCoverDropdown';
    });
    var mileageDropdown = find(content, function (obj) {
        return obj.formGroupId === 'mileageDropdown';
    });
    var addOns = find(content, function (obj) {
        return obj.formGroupId === 'addOnsList';
    });
    var addOnsList = (addOns === null || addOns === void 0 ? void 0 : addOns.list) ? addOns === null || addOns === void 0 ? void 0 : addOns.list[0].listItems : undefined;
    var aboutYouTitle = (aboutYouDropdown === null || aboutYouDropdown === void 0 ? void 0 : aboutYouDropdown.fields) ? aboutYouDropdown.fields[0] : undefined;
    var aboutYouList = (aboutYouDropdown === null || aboutYouDropdown === void 0 ? void 0 : aboutYouDropdown.list) ? aboutYouDropdown.list[0].listItems : undefined;
    var driversTitle = (driversDropdown === null || driversDropdown === void 0 ? void 0 : driversDropdown.fields) ? driversDropdown.fields[0] : undefined;
    var driversList = (driversDropdown === null || driversDropdown === void 0 ? void 0 : driversDropdown.list) ? driversDropdown.list[0].listItems : undefined;
    var coverTitle = (yourCoverDropdown === null || yourCoverDropdown === void 0 ? void 0 : yourCoverDropdown.fields) ? yourCoverDropdown.fields[0] : undefined;
    var coverList = (yourCoverDropdown === null || yourCoverDropdown === void 0 ? void 0 : yourCoverDropdown.list) ? yourCoverDropdown.list[0].listItems : undefined;
    // milieage has no list
    var mileageTitle = (mileageDropdown === null || mileageDropdown === void 0 ? void 0 : mileageDropdown.fields) ? mileageDropdown.fields[0] : undefined;
    var getSelected = function (id, method) {
        var isSelected = find(selectedCover, function (obj) {
            return obj.id === id;
        });
        if (method === 'icon') {
            if (isSelected) {
                if (isSelected.selected === false || isSelected.selected === null || isSelected.selected === undefined)
                    return (React.createElement(SVGRed, null,
                        React.createElement(Cross, null)));
                if (isSelected.selected === true)
                    return (React.createElement(SVGGreen, null,
                        React.createElement(Tick, null)));
            }
            return (React.createElement(SVGRed, null,
                React.createElement(Cross, null)));
        }
    };
    return (React.createElement(Root, null,
        React.createElement(Layout, { shadow: true, withSeperator: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(Title, null, yourCoverTitle === null || yourCoverTitle === void 0 ? void 0 : yourCoverTitle.title),
                    React.createElement(IntroText, null,
                        React.createElement(ReactMarkdown, { source: yourCoverTitle === null || yourCoverTitle === void 0 ? void 0 : yourCoverTitle.text })),
                    aboutYouTitle && (React.createElement(DropDownSection, { label: aboutYouTitle.label, list: aboutYouList, values: accHolderDropDown })),
                    driversTitle && driversValues && (React.createElement(React.Fragment, null, driversValues.map(function (item) { return (React.createElement(DropDownSection, { key: item.lastName, label: "Driver: " + item.firstName + " " + item.lastName, list: driversList, values: item })); }))),
                    coverTitle && React.createElement(DropDownSection, { label: coverTitle.label, list: coverList, values: coverDropDownValues }),
                    mileageTitle && (React.createElement(MileageBottomBorder, null,
                        React.createElement(DropDownSection, { label: mileageTitle.label, mileageText: 'Equivalent to 115 miles a week, or about 16 miles per day. Top Up Miles cost £28.10 for every 250 additional miles.' }))),
                    React.createElement(Title, null, addOns === null || addOns === void 0 ? void 0 : addOns.title),
                    addOnsList && (React.createElement(AddOnsWrapper, null, addOnsList.map(function (item, index) {
                        if (showAddonsSection) {
                            if (item.value === 'PMRoadRescueCov' && showBreakdown === false) {
                                return React.createElement(React.Fragment, null);
                            }
                            if (item.value === 'PMKeyProtect') {
                                return React.createElement(React.Fragment, null);
                            }
                            if (!getSelected(item.value, 'list')) {
                                return (React.createElement(AddOnRow, { isFirst: index === 0, key: item.label },
                                    React.createElement("div", null, item.label),
                                    React.createElement(SVGWrapper, null, getSelected(item.value, 'icon'))));
                            }
                        }
                        else {
                            if (item.value === 'PMRoadRescueCov' && showBreakdown === false) {
                                return React.createElement(React.Fragment, null);
                            }
                            return (React.createElement(AddOnRow, { isFirst: index === 0, key: item.label },
                                React.createElement("div", null, item.label),
                                React.createElement(SVGWrapper, null, getSelected(item.value, 'icon'))));
                        }
                    }))))))));
};
export default RenewalYourCover;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
