import { renewToken } from './client';
import { getNotifications, getMessages } from './messageAPIs';
var fetchNotificationsAttempts = 0;
export var fetchNotifications = function (policyId, accessToken, tokenForRefresh, callBack) {
    getNotifications(policyId, accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200) {
            callBack(payload);
            fetchNotificationsAttempts = 0;
        }
        else if (status === 401 && fetchNotificationsAttempts < 5) {
            fetchNotificationsAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return fetchNotifications(policyId, newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error fetching notification for " + policyId + " | status code " + status);
        }
    });
};
var fetchMessagesAttempts = 0;
export var fetchMessages = function (policyId, accessToken, tokenForRefresh, callBack) {
    getMessages(policyId, accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            fetchMessagesAttempts = 0;
            callBack(payload);
        }
        else if (status === 401 && fetchMessagesAttempts < 5) {
            fetchMessagesAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return fetchMessages(policyId, newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error fetching messages for " + policyId + " | status code " + status);
        }
    });
};
