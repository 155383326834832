var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
export var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 1px;\n  background-color: rgba(137, 137, 137, 0.5);\n  width: 100%;\n\n  ", "\n"], ["\n  height: 1px;\n  background-color: rgba(137, 137, 137, 0.5);\n  width: 100%;\n\n  ",
    "\n"])), function (props) {
    return props.light && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: rgba(137, 137, 137, 0.1);\n    "], ["\n      background-color: rgba(137, 137, 137, 0.1);\n    "])));
});
var Separator = function (_a) {
    var light = _a.light;
    return React.createElement(Root, { light: light });
};
export default Separator;
var templateObject_1, templateObject_2;
