var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { css } from 'styled-components';
import { breakpoints } from './variables';
var toMediaQuery = function (_a) {
    var min = _a[0], max = _a[1];
    return "(min-width: " + min + "px)" + (max ? " and (max-width: " + max + "px)" : '');
};
var getStaticKeys = function (obj) { return Object.keys(obj); };
export var createMedia = function (mediaBreakpoints) {
    return getStaticKeys(mediaBreakpoints).reduce(function (acc, name) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[name] = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        @media ", " {\n          ", "\n        }\n      "], ["\n        @media ", " {\n          ", "\n        }\n      "])), toMediaQuery(mediaBreakpoints[name]), css.apply(void 0, args));
        }, _a)));
    }, {});
};
export var media = createMedia(breakpoints);
var templateObject_1;
