var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  margin-left: ", ";\n  padding: 0 20px;\n"], ["\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  margin-left: ", ";\n  padding: 0 20px;\n"])), function (props) { return (props.noIcon ? '20px' : '0'); });
var IconWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 20px;\n  line-height: 20px;\n\n  strong {\n    font-weight: ", ";\n  }\n"], ["\n  margin-left: 20px;\n  line-height: 20px;\n\n  strong {\n    font-weight: ", ";\n  }\n"])), fontWeight.regular);
var Row = function (_a) {
    var style = _a.style, icon = _a.icon, text = _a.text, textStyle = _a.textStyle;
    return (React.createElement(Root, { style: style, noIcon: icon === undefined },
        React.createElement(IconWrapper, null, icon),
        React.createElement(TextWrapper, { style: textStyle },
            React.createElement(ReactMarkdown, { source: text, escapeHtml: false }))));
};
export default Row;
var templateObject_1, templateObject_2, templateObject_3;
