var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: 300;\n  color: red;\n"], ["\n  font-size: 14px;\n  font-weight: 300;\n  color: red;\n"])));
var ErrorMessage = function (_a) {
    var errorMessage = _a.errorMessage;
    return React.createElement(Root, null, errorMessage);
};
export default ErrorMessage;
var templateObject_1;
