import moment from 'moment';
export var getMonthFromDate = function (dateString, short) {
    var date = new Date(dateString);
    var monthFormat = new Intl.DateTimeFormat('en-US', short ? { month: 'short' } : {}).format;
    return monthFormat(date) + " " + date
        .getFullYear()
        .toString()
        .slice(0 - 2);
};
export var getMonthAndYearFromString = function (monthString) {
    // month string should have format like "Jun 20"
    var dateStrings = monthString.split(' ');
    var newDate = new Date(Date.parse(dateStrings[0] + ' 1, ' + dateStrings[1]));
    return { month: newDate.getMonth() + 1, year: newDate.getFullYear() };
};
export var secondsToHms = function (timeInSeconds) {
    var digit = Number(timeInSeconds);
    var h = Math.floor(digit / 3600);
    var m = Math.floor((digit % 3600) / 60);
    var s = Math.floor((digit % 3600) % 60);
    var hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '00:';
    var mDisplay = m > 0 ? (m < 10 ? '0' : '') + m + ':' : '00:';
    var sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '';
    return hDisplay + mDisplay + sDisplay;
};
export var getFriendlyDisplayDate = function (dateString) { return moment(new Date(dateString)).format('DD MMM YY'); };
export var formatDateOfBirth = function (dateOfBirth, format) {
    var year = dateOfBirth.year, month = dateOfBirth.month, day = dateOfBirth.day;
    var fullDate = new Date().setFullYear(year, month, day);
    return moment(fullDate).format(format);
};
