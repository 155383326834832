var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Separator from 'components/atoms/Separator';
import Title from 'components/atoms/Title';
import Dropdown from 'components/molecules/Dropdown';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { fontWeight, fontSize, media } from 'styles';
import BarChart from './BarChart';
import CircularProgress from './CircularProgress';
import LegendWidget from './LegendWidget';
import { getFriendlyDisplayDate } from 'utils/date';
import SectionButtonWrapper from 'components/molecules/SectionButtonWrapper';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var IntroTextWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  line-height: 20px;\n  margin: 20px 0;\n\n  strong {\n    font-weight: ", ";\n  }\n"], ["\n  font-size: ", ";\n  line-height: 20px;\n  margin: 20px 0;\n\n  strong {\n    font-weight: ", ";\n  }\n"])), fontSize.fs14, fontWeight.bold);
var DropdownWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  cursor: pointer;\n  margin: 40px 0;\n\n  ", "\n"], ["\n  width: 100%;\n  cursor: pointer;\n  margin: 40px 0;\n\n  ",
    "\n"])), media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 280px;\n    margin: 40px 0 40px auto;\n  "], ["\n    width: 280px;\n    margin: 40px 0 40px auto;\n  "]))));
var CircularBarSection = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-align: center;\n\n  ", "\n"], ["\n  text-align: center;\n\n  ",
    "\n"])), media.medium(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    margin-bottom: 60px;\n  "], ["\n    display: flex;\n    margin-bottom: 60px;\n  "]))));
var InformationWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  margin-left: 20px;\n"], ["\n  flex: 1;\n  margin-left: 20px;\n"])));
var LegendWidgets = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  justify-content: space-between;\n  padding: 20px;\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  justify-content: space-between;\n  padding: 20px;\n"])));
var TextWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: ", ";\n  font-style: italic;\n  line-height: 20px;\n  margin: 20px 0;\n  padding: 0 20px;\n\n  ", "\n"], ["\n  font-size: ", ";\n  font-style: italic;\n  line-height: 20px;\n  margin: 20px 0;\n  padding: 0 20px;\n\n  ",
    "\n"])), fontSize.fs14, media.medium(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    padding: 0 40px;\n    text-align: left;\n  "], ["\n    padding: 0 40px;\n    text-align: left;\n  "]))));
var AdditionalTextWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
var NoBonusMilesText = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 18px;\n  font-style: normal;\n  font-weight: ", ";\n  margin-bottom: 10px;\n"], ["\n  font-size: 18px;\n  font-style: normal;\n  font-weight: ", ";\n  margin-bottom: 10px;\n"])), fontWeight.medium);
var BonusMilesSection = function (_a) {
    var cmsContent = _a.cmsContent, milesData = _a.milesData, _b = _a.hideChart, hideChart = _b === void 0 ? false : _b, _c = _a.hideButton, hideButton = _c === void 0 ? false : _c, showDrivingData = _a.showDrivingData, onMonthlyMilesSelectedById = _a.onMonthlyMilesSelectedById;
    var sectionTitle = cmsContent.sectionTitle, introText = cmsContent.introText, unit = cmsContent.unit, currentMonthMilesText = cmsContent.currentMonthMilesText, yearMilesText = cmsContent.yearMilesText, additionalText = cmsContent.additionalText, noBonusMilsText = cmsContent.noBonusMilsText, noBonusMilsAdditionalText = cmsContent.noBonusMilsAdditionalText, xAxisLabel = cmsContent.xAxisLabel, yAxisLabel = cmsContent.yAxisLabel;
    var options = milesData.map(function (item) { return ({
        label: moment(item.effective).format('DD/MM/YYYY') + " - " + moment(item.expiration).format('DD/MM/YYYY'),
        value: item.year,
    }); });
    var _d = useState(milesData[0]), miles = _d[0], setMiles = _d[1];
    var _e = useState(milesData[0].data[0]), monthMiles = _e[0], setMonthMiles = _e[1];
    var noMilesAvailable = miles.yearlyMiles === 0;
    var _f = useState(milesData.length === 1 && milesData[0].data.length === 0), isNewCustomer = _f[0], setNewCustomer = _f[1];
    var _g = useState(milesData.length > 1 && milesData.every(function (data) { return data.data.length === 0; })), invalidData = _g[0], setInvalidData = _g[1];
    useEffect(function () {
        setMiles(milesData[0]);
        setMonthMiles(milesData[0].data[0]);
        setNewCustomer(milesData.length === 1 && milesData[0].data.length === 0);
        setInvalidData(milesData.length > 1 && milesData.every(function (data) { return data.data.length === 0; }));
    }, [milesData]);
    return (React.createElement(Layout, { shadow: true },
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(Title, null, sectionTitle),
                    React.createElement(IntroTextWrapper, null,
                        React.createElement(ReactMarkdown, { source: introText })),
                    React.createElement(Separator, null),
                    invalidData ? (React.createElement(IntroTextWrapper, null, "We are experiencing technical difficulties, if the error persists please contact us.")) : isNewCustomer ? (React.createElement(IntroTextWrapper, null, "You will be able to see your bonus miles data after your first month")) : (React.createElement(React.Fragment, null,
                        React.createElement(DropdownWrapper, null,
                            React.createElement(Dropdown, { options: options, onSelect: function (value) {
                                    var yearData = milesData.find(function (item) { return item.year === value; });
                                    if (yearData) {
                                        setMiles(yearData);
                                        setMonthMiles(yearData.data[0]);
                                        if (yearData.data.length > 0) {
                                            onMonthlyMilesSelectedById &&
                                                onMonthlyMilesSelectedById(yearData.data[0].from, yearData.data[0].to, yearData.data[0].bonusMilesId);
                                        }
                                    }
                                }, defaultOption: options.find(function (option) { return option.value === miles.year; }) })),
                        miles && miles.data.length > 0 ? (React.createElement(CircularBarSection, null,
                            React.createElement(CircularProgress, { innerValue: miles.data[0].miles / 100, outerValue: miles.yearlyMiles / 1200 }),
                            React.createElement(InformationWrapper, null,
                                React.createElement(LegendWidgets, null,
                                    React.createElement(LegendWidget, { color: monthMiles.miles === 0 ? '#dfdfdf' : '#52be80', value: monthMiles.miles, unit: unit, text: currentMonthMilesText, date: getFriendlyDisplayDate(monthMiles.from) + " - " + getFriendlyDisplayDate(monthMiles.to) }),
                                    React.createElement(LegendWidget, { color: miles.yearlyMiles === 0 ? '#dfdfdf' : '#268657', value: miles.yearlyMiles, unit: unit, text: yearMilesText, date: getFriendlyDisplayDate(miles.effective) + " - " + getFriendlyDisplayDate(miles.expiration) })),
                                React.createElement(TextWrapper, null,
                                    noMilesAvailable && React.createElement(NoBonusMilesText, null, noBonusMilsText),
                                    React.createElement(AdditionalTextWrapper, null, noMilesAvailable ? noBonusMilsAdditionalText : additionalText))))) : (React.createElement(IntroTextWrapper, null, "You will be able to see your bonus miles data after your first month. You can see you previous policy years by selecting the policy year from the drop down.")),
                        !hideButton && (React.createElement(SectionButtonWrapper, null,
                            React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                    showDrivingData && showDrivingData();
                                    TagManagerArgs('Button', window.location.pathname + " - Bonus Miles", 'View driving data');
                                } }, "View driving data"))),
                        onMonthlyMilesSelectedById && !hideChart && miles && miles.data && miles.data.length > 0 && (React.createElement(BarChart, { data: miles.data, xAxisLabel: xAxisLabel, yAxisLabel: yAxisLabel, onClick: function (bonusMilesForMonth) {
                                onMonthlyMilesSelectedById(bonusMilesForMonth.from, bonusMilesForMonth.to, bonusMilesForMonth.bonusMilesId);
                            } })))))))));
};
export default BonusMilesSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
