import TagManager from 'react-gtm-module';
import { GtmId } from './enums';
export var TagManagerArgs = function (event, action, label, value) {
    if (value === void 0) { value = {}; }
    TagManager.initialize({
        gtmId: GtmId.DEV,
        // gtmId: GtmId.PROD,
        events: {
            event: event,
        },
        dataLayer: {
            action: action,
            label: label,
        },
    });
};
