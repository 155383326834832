var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { FormInput, getSingleFieldContent, Group } from 'components/organisms/Form';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import SectionHeader from 'components/molecules/SectionHeader';
import { TagManagerArgs } from 'analytics';
var ButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n"])));
var ConfirnationWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  button {\n    margin-left: 10px;\n  }\n"])));
export var MyContactDetails = function (_a) {
    var myContactDetailsContent = _a.myContactDetailsContent, emailDisplay = _a.emailDisplay, mobileDisplay = _a.mobileDisplay, homePhoneDisplay = _a.homePhoneDisplay, getValues = _a.getValues;
    var _b = useState(false), editMode = _b[0], setEditmode = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(false), emailChange = _d[0], setEmailChange = _d[1];
    var _e = useState(false), mobileChange = _e[0], setMobileChange = _e[1];
    var _f = useState(false), homePhoneChange = _f[0], setHomePhoneChange = _f[1];
    var _g = useState(false), saveButton = _g[0], setSaveButton = _g[1];
    var _h = useState(''), emailNew = _h[0], setEmailNew = _h[1];
    var _j = useState(''), mobileNew = _j[0], setMobileNew = _j[1];
    var _k = useState(''), homePhoneNew = _k[0], setHomePhoneNew = _k[1];
    var _l = useState(''), emailConfirm = _l[0], setEmailConfirm = _l[1];
    var _m = useState(''), mobileConfirm = _m[0], setMobileConfirm = _m[1];
    var _o = useState(''), homePhoneConfirm = _o[0], setHomePhoneConfirm = _o[1];
    var emailAddress = getSingleFieldContent('emailAddress', myContactDetailsContent.fields);
    var newEmailAddress = getSingleFieldContent('newEmailAddress', myContactDetailsContent.fields);
    var confirmNewEmailAddress = getSingleFieldContent('confirmNewEmailAddress', myContactDetailsContent.fields);
    var mobilePhone = getSingleFieldContent('mobilePhone', myContactDetailsContent.fields);
    var newMobilePhone = getSingleFieldContent('newMobilePhone', myContactDetailsContent.fields);
    var confirmNewMobilePhone = getSingleFieldContent('confirmNewMobilePhone', myContactDetailsContent.fields);
    var homeNumber = getSingleFieldContent('homeNumber', myContactDetailsContent.fields);
    var newHomeNumber = getSingleFieldContent('newHomeNumber', myContactDetailsContent.fields);
    var confirmNewHomeNumber = getSingleFieldContent('confirmNewHomeNumber', myContactDetailsContent.fields);
    var setNewValueControl = function (type, value) {
        if (type === 'email') {
            setEmailNew(value);
            if (value === '') {
                setEmailChange(false);
                setEmailConfirm('');
            }
            else
                setEmailChange(true);
            if (value !== emailConfirm)
                setSaveButton(false);
            if (value === emailConfirm)
                setSaveButton(true);
        }
        if (type === 'mobile') {
            setMobileNew(value);
            if (value === '') {
                setMobileChange(false);
                setMobileConfirm('');
            }
            else
                setMobileChange(true);
            if (value !== mobileConfirm)
                setSaveButton(false);
            if (value === mobileConfirm)
                setSaveButton(true);
        }
        if (type === 'homePhone') {
            setHomePhoneNew(value);
            if (value === '') {
                setHomePhoneChange(false);
                setHomePhoneConfirm('');
            }
            else
                setHomePhoneChange(true);
            if (value !== homePhoneConfirm)
                setSaveButton(false);
            if (value === homePhoneConfirm)
                setSaveButton(true);
        }
    };
    var setConfirmValueControl = function (type, value) {
        if (type === 'email') {
            if (value === '' || emailConfirm === '')
                setSaveButton(false);
            if (value !== emailNew)
                setSaveButton(false);
            if (value === emailNew)
                setSaveButton(true);
            setEmailConfirm(value);
        }
        if (type === 'mobile') {
            if (value === '')
                setSaveButton(false);
            if (value === mobileNew)
                setSaveButton(true);
            if (value !== mobileNew)
                setSaveButton(false);
            setMobileConfirm(value);
        }
        if (type === 'homePhone') {
            if (value === '')
                setSaveButton(false);
            if (value === homePhoneNew)
                setSaveButton(true);
            if (value !== homePhoneNew)
                setSaveButton(false);
            setHomePhoneConfirm(value);
        }
    };
    var sendChangesToParent = function () {
        var values = {
            emailAddress: emailConfirm,
            contacts: { mobilePhone: mobileConfirm, homePhone: homePhoneConfirm },
        };
        getValues(values);
    };
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(SectionHeader, { title: myContactDetailsContent.title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
            open && (React.createElement(Group, { noBorder: true },
                React.createElement(FormInput, { name: emailAddress.value, label: emailAddress.label, value: emailDisplay, readOnly: true }),
                editMode ? (React.createElement(FormInput, { name: newEmailAddress.value, label: newEmailAddress.label, onChange: function (value) { return setNewValueControl('email', value); }, value: emailNew, type: "email" })) : (React.createElement("div", null)),
                editMode ? (React.createElement(FormInput, { name: confirmNewEmailAddress.value, label: confirmNewEmailAddress.label, readOnly: emailChange === false && emailNew === '', disabled: emailChange === false && emailNew === '', onChange: function (value) { return setConfirmValueControl('email', value); }, value: emailConfirm, type: "email" })) : (React.createElement("div", null)),
                React.createElement(FormInput, { name: mobilePhone.value, label: mobilePhone.label, value: mobileDisplay, readOnly: true }),
                editMode ? (React.createElement(FormInput, { name: newMobilePhone.value, label: newMobilePhone.label, onChange: function (value) { return setNewValueControl('mobile', value); }, type: "number" })) : (React.createElement("div", null)),
                editMode ? (React.createElement(FormInput, { name: confirmNewMobilePhone.value, label: confirmNewMobilePhone.label, readOnly: mobileChange === false && mobileNew === '', disabled: mobileChange === false && mobileNew === '', onChange: function (value) { return setConfirmValueControl('mobile', value); }, type: "number" })) : (React.createElement("div", null)),
                React.createElement(FormInput, { name: homeNumber.value, label: homeNumber.label, value: homePhoneDisplay, readOnly: true }),
                editMode ? (React.createElement(FormInput, { name: newHomeNumber.value, label: newHomeNumber.label, onChange: function (value) { return setNewValueControl('homePhone', value); }, type: "number" })) : (React.createElement("div", null)),
                editMode ? (React.createElement(FormInput, { name: confirmNewHomeNumber.value, label: confirmNewHomeNumber.label, readOnly: homePhoneChange === false && homePhoneNew === '', disabled: homePhoneChange === false && homePhoneNew === '', onChange: function (value) { return setConfirmValueControl('homePhone', value); }, type: "number" })) : (React.createElement("div", null)),
                !editMode && (React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                            setEditmode(true);
                            TagManagerArgs('Button', window.location.pathname + " - My contact details", 'Edit');
                        } }, myContactDetailsContent.buttons && myContactDetailsContent.buttons[0].text))),
                editMode && (React.createElement(ButtonWrapper, null,
                    React.createElement(ConfirnationWrapper, null,
                        React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                                setEditmode(false);
                                TagManagerArgs('Button', window.location.pathname + " - My contact details", 'Cancel');
                            } }, "Cancel"),
                        React.createElement(Button, { disabled: saveButton === false, onClick: function () {
                                sendChangesToParent();
                                TagManagerArgs('Button', window.location.pathname + " - My contact details", 'Save changes');
                            } }, "Save changes")))))))));
};
var templateObject_1, templateObject_2;
