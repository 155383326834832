export var getRenewalPolicyValuesForDisplay = function (accountHolder, accountHolderDriver) {
    return {
        title: accountHolder.title,
        firstName: accountHolder.firstName,
        lastName: accountHolder.lastName,
        dateOfBirth: accountHolder.dateOfBirth,
        maritalStatus: accountHolder.maritalStatus,
        gender: accountHolder.gender,
        occupation: accountHolder.occupation,
        ukResident5Years: accountHolder.ukResident5Years,
        emailAddress1: accountHolder.emailAddress1,
        licenseType: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.licenseType,
        testPassed10Years: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.testPassed10Years,
        accessToOtherVehicles: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.accessToOtherVehicles,
        drivingQualifications: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.drivingQualifications,
        medicalConditions: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.medicalConditions,
        nonMotoringConvictions: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.nonMotoringConvictions,
        cancelledPreviousInsurance: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.cancelledPreviousInsurance,
        hasMotorConvictions: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.hasMotorConvictions,
        hasMotorClaims: accountHolderDriver === null || accountHolderDriver === void 0 ? void 0 : accountHolderDriver.hasMotorClaims,
    };
};
export var getPolicyHolderFixedValues = function (initialDisplayValues) {
    return {
        title: initialDisplayValues.title,
        firstName: initialDisplayValues.firstName,
        lastName: initialDisplayValues.lastName,
        dateOfBirth: initialDisplayValues.dateOfBirth,
        ukResident5Years: initialDisplayValues.ukResident5Years,
    };
};
export var getPolicyHolderEditableValues = function (initialDisplayValues, emailAddress1) {
    return {
        maritalStatus: initialDisplayValues.maritalStatus,
        gender: initialDisplayValues.gender,
        occupation: initialDisplayValues.occupation,
        emailAddress1: emailAddress1,
    };
};
export var getPolicyDriverFixedValues = function (initialDisplayValues) {
    return { licenseType: initialDisplayValues.licenseType, testPassed10Years: initialDisplayValues.testPassed10Years };
};
export var getPolicyDriverEditableValues = function (initialDisplayValues) {
    return {
        accessToOtherVehicles: initialDisplayValues.accessToOtherVehicles,
        drivingQualifications: initialDisplayValues.drivingQualifications,
        medicalConditions: initialDisplayValues.medicalConditions,
        nonMotoringConvictions: initialDisplayValues.nonMotoringConvictions,
        cancelledPreviousInsurance: initialDisplayValues.cancelledPreviousInsurance,
    };
};
export var numberOfDrivers = function (format, totalCurrentDrivers, totalAddedDrivers) {
    var text = ['None', 'One', 'Two', 'Three', 'Four', 'Five'];
    if (format === 'fullNumberDisplay')
        return totalCurrentDrivers + totalAddedDrivers;
    if (format === 'textNumberDisplay')
        return text[totalCurrentDrivers + totalAddedDrivers];
    return 0;
};
