var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Root = styled.svg.attrs({ width: 34, height: 34, viewBox: '0 0 34 34' })(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var InnerCircle = styled.circle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fill: ", ";\n"], ["\n  fill: ", ";\n"])), function (props) { return props.color; });
var OuterCircle = styled.circle(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fill: ", "11;\n"], ["\n  fill: ", "11;\n"])), function (props) { return props.color; });
var Point = function (_a) {
    var color = _a.color, innerRadius = _a.innerRadius, outerRadius = _a.outerRadius;
    return (React.createElement(Root, null,
        React.createElement(InnerCircle, { color: color, r: innerRadius, cx: 17, cy: 17 }),
        React.createElement(OuterCircle, { color: color, r: outerRadius, cx: 17, cy: 17 })));
};
export default Point;
var templateObject_1, templateObject_2, templateObject_3;
