var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getDetailedPolicy, getPageContent, mtaTokenRefresh, removeDriver } from 'services';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import ShareComponent from 'pages/ShareComponent';
import MtaDateAndTime from 'components/organisms/MtaDateAndTime';
import MtaModal from 'components/molecules/MtaModal';
import Button from 'components/atoms/Button';
import { SessionStorageKey } from 'types';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PageButtons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var MtaRemoveDriverPage = function (_a) {
    var currentPolicy = _a.currentPolicy, driverIndex = _a.driverIndex, setQuoteDetails = _a.setQuoteDetails, setMtaDate = _a.setMtaDate, setMta = _a.setMta, mta = _a.mta;
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formButtons = _c[0], setFormButtons = _c[1];
    var _d = useState(undefined), modalContent = _d[0], setModalContent = _d[1];
    var _e = useState(undefined), driverPublicId = _e[0], setDriverPublicId = _e[1];
    var _f = useState(undefined), detailedInfo = _f[0], setDetailedInfo = _f[1];
    var _g = useState(false), modalOpen = _g[0], setModalOpen = _g[1];
    var _h = useState(''), effectiveDate = _h[0], setEffectiveDate = _h[1];
    var history = useHistory();
    var onSubmit = function () {
        setModalOpen(true);
    };
    var modalContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, token, publicId, quote, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    publicId = driverPublicId.replace('pc:', '');
                    return [4 /*yield*/, removeDriver(policyNumber, publicId, effectiveDate, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        // set quote data to redux
                        setQuoteDetails(quote.data);
                        mtaFlag = __assign(__assign({}, mta), { removeDriver: true });
                        setMta(mtaFlag);
                        // set dates in redux
                        setMtaDate(effectiveDate);
                        // redirect
                        history.push('/your-quote');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        modalContinue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent('drivingdatapages/remove-driver').then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormButtons(cmsContent.contentSections[0].buttons);
        if (cmsContent !== undefined)
            setModalContent(cmsContent.modals[0]);
        if (currentPolicy !== undefined && detailedInfo === undefined) {
            var getDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
                var policyNumber, token, date, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                            token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                            date = new Date().toISOString();
                            return [4 /*yield*/, getDetailedPolicy(policyNumber, date, token)];
                        case 1:
                            result = _a.sent();
                            setDetailedInfo(result.data);
                            return [2 /*return*/];
                    }
                });
            }); };
            getDetails();
        }
        if (detailedInfo !== undefined)
            setDriverPublicId(detailedInfo.drivers[driverIndex].publicID);
        if (currentPolicy === undefined && driverIndex === undefined)
            history.push('/mypolicy');
    }, [cmsContent, currentPolicy, driverIndex, detailedInfo]);
    return (React.createElement(Root, null, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(MtaDateAndTime, { getEffectiveDate: function (date) { return setEffectiveDate(date); } }),
        formButtons && effectiveDate !== '' && (React.createElement(PageButtons, null,
            React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () { return TagManagerArgs('Button', window.location.pathname + " - Mta remove Driver", 'Cancel'); } }, "Cancel"),
            React.createElement(Button, { onClick: function () {
                    onSubmit();
                    TagManagerArgs('Button', window.location.pathname + " - Mta remove Driver", 'Continue');
                } }, "Continue"))),
        modalContent && (React.createElement(MtaModal, { modalOpen: modalOpen, setModalOpen: setModalOpen, modalContent: modalContent, modalContinue: modalContinue }))))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    driverIndex: state.driverIndex,
    mta: state.mta,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setQuoteDetails: function (quote) { return dispatch(actions.setQuote(quote)); },
    setMtaDate: function (date) { return dispatch(actions.setMtaEffectiveDate(date)); },
    setMta: function (mta) { return dispatch(actions.setMta(mta)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MtaRemoveDriverPage);
var templateObject_1, templateObject_2;
