import filter from 'lodash/filter';
import find from 'lodash/find';
export var driverUtils = function (drivers) {
    var getTotalDrivers = function () { return drivers.length; };
    var getPolicyHolderDriver = function () {
        return find(drivers, function (obj) {
            return obj.accountHolder === true;
        });
    };
    var getNonPolicyHolderDrivers = function () {
        return filter(drivers, function (obj) {
            return obj.accountHolder !== true;
        });
    };
    return {
        getTotalDrivers: getTotalDrivers,
        getPolicyHolderDriver: getPolicyHolderDriver,
        getNonPolicyHolderDrivers: getNonPolicyHolderDrivers,
    };
};
