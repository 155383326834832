var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Bar, BarChart as ReactBarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import Title from 'components/atoms/Title';
import { fontSize, fontWeight } from 'styles';
import TooltipContent from 'components/organisms/BonusMilesSection/TooltipContent';
import BarShape from 'components/organisms/BonusMilesSection/BarShape';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 1024px;\n\n  .axis-label {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n"], ["\n  max-width: 1024px;\n\n  .axis-label {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n"])), fontSize.fs12, fontWeight.medium);
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 86px;\n  padding: 0 80px;\n"], ["\n  margin-bottom: 86px;\n  padding: 0 80px;\n"])));
var horizontalPoints = [35, 163, 291, 419];
var MonthlyMiles = function (_a) {
    var data = _a.data, cmsContent = _a.cmsContent, onMonthSelected = _a.onMonthSelected;
    var title = cmsContent.title, xAxisLabel = cmsContent.xAxisLabel, yAxisLabel = cmsContent.yAxisLabel;
    var _b = useState(false), showTooltip = _b[0], setTooltipShow = _b[1];
    var _c = useState(''), barHovered = _c[0], setHoveredBar = _c[1];
    var getTicks = function () {
        var maxMiles = data.reduce(function (max, journeyMiles) { return (journeyMiles.miles > max.miles ? journeyMiles : max); });
        var ticks = [250, 500, 750, 1000];
        if (maxMiles.miles > 1000 && maxMiles.miles <= 2000) {
            ticks = [500, 1000, 1500, 2000];
        }
        if (maxMiles.miles > 2000 && maxMiles.miles <= 4000) {
            ticks = [1000, 2000, 3000, 4000];
        }
        if (maxMiles.miles > 4000 && maxMiles.miles <= 6000) {
            ticks = [1500, 3000, 4500, 6000];
        }
        if (maxMiles.miles > 6000 && maxMiles.miles <= 8000) {
            ticks = [2000, 4000, 6000, 8000];
        }
        if (maxMiles.miles > 8000 && maxMiles.miles <= 10000) {
            ticks = [2500, 5000, 7500, 10000];
        }
        return ticks;
    };
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null,
            React.createElement(Title, null, title)),
        React.createElement(ReactBarChart, { barSize: 60, width: 980, height: 600, data: data, margin: { top: 25, right: 30, left: 20, bottom: 25 } },
            React.createElement(CartesianGrid, { vertical: false, horizontal: true, stroke: "rgba(137, 137, 137, 0.1)", horizontalPoints: horizontalPoints }),
            React.createElement(YAxis, { dataKey: "miles", type: "number", tick: { fontSize: '14px', fill: 'rgba(32, 32, 32, 1)' }, tickLine: false, tickCount: 4, domain: [0, 1000], ticks: getTicks(), axisLine: { stroke: 'rgba(137, 137, 137)' }, padding: { top: 10, bottom: 0 }, label: {
                    value: yAxisLabel,
                    position: 'insideTop',
                    offset: -20,
                    className: 'axis-label',
                } }),
            React.createElement(XAxis, { dataKey: "month", type: "category", tick: { fontSize: '14px', fill: 'rgba(32, 32, 32, 1)' }, tickLine: false, axisLine: { stroke: 'rgba(137, 137, 137)' }, padding: { left: 10, right: 10 }, label: { value: xAxisLabel, position: 'insideBottom', offset: -20, className: 'axis-label' } }),
            showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) { return React.createElement(TooltipContent, __assign({}, props)); }, isAnimationActive: false })),
            React.createElement(Bar, { dataKey: "miles", onMouseOver: function (target) {
                    setTooltipShow(true);
                    setHoveredBar(target.month);
                }, onMouseLeave: function () {
                    setTooltipShow(false);
                    setHoveredBar('');
                }, onMouseEnter: function (target) {
                    setTooltipShow(true);
                    setHoveredBar(target.month);
                }, onClick: function (target) {
                    onMonthSelected(target.month);
                }, shape: function (props) { return (React.createElement(BarShape, { rectangle: true, fill: props.month === barHovered ? '#52be80' : '#268657', x: props.x || 0, y: props.y || 0, width: props.width || 0, height: props.height || 0, radius: 30 })); } }))));
};
export default MonthlyMiles;
var templateObject_1, templateObject_2;
