var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FormControl, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React from 'react';
import styled, { css } from 'styled-components';
import { Controller } from 'react-hook-form';
import FormLabel from './FormLabel';
import ArrowIcon from 'assets/arrow_down.svg';
import { color, fontFamily, media } from 'styles';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  ", "\n\n  .MuiFormControl-root {\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    padding: 0 17px;\n    border: solid 1px ", ";\n    border-radius: 2px;\n    box-sizing: border-box;\n    background-color: ", ";\n    transition: 0.3s;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiInput-input {\n    color: ", ";\n    font-family: ", ";\n    padding: 0;\n\n    :focus {\n      background-color: white;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  ",
    "\n\n  .MuiFormControl-root {\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    padding: 0 17px;\n    border: solid 1px ", ";\n    border-radius: 2px;\n    box-sizing: border-box;\n    background-color: ", ";\n    transition: 0.3s;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiInput-input {\n    color: ", ";\n    font-family: ", ";\n    padding: 0;\n\n    :focus {\n      background-color: white;\n    }\n  }\n"])), function (props) {
    return props.spanTwoCols && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ",
        "\n    "])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        grid-column: span 2;\n      "], ["\n        grid-column: span 2;\n      "]))));
}, function (props) { return (props.error ? 'red' : 'rgba(137, 137, 137, 0.3)'); }, function (props) { return (props.disabledStyle ? 'rgba(0,0,0,.05)' : 'white'); }, function (props) { return (props.disabledStyle ? '#898989' : "" + color.black); }, fontFamily.base, function (props) { return (props.disabledStyle ? '#898989' : "" + color.black); }, fontFamily.base);
var FormSelect = function (_a) {
    var label = _a.label, _b = _a.options, options = _b === void 0 ? [] : _b, placeholder = _a.placeholder, toolTip = _a.toolTip, name = _a.name, control = _a.control, onChange = _a.onChange, spanTwoCols = _a.spanTwoCols, additionalToTooltip = _a.additionalToTooltip, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.readOnly, readOnly = _d === void 0 ? false : _d, value = _a.value, _e = _a.isRequired, isRequired = _e === void 0 ? false : _e, _f = _a.error, error = _f === void 0 ? false : _f;
    var handleOnChange = function (value) { return onChange === null || onChange === void 0 ? void 0 : onChange(value); };
    return (React.createElement(Root, { spanTwoCols: spanTwoCols, disabledStyle: readOnly, error: error },
        label && (React.createElement(FormLabel, { label: label, toolTip: toolTip, additionalToTooltip: additionalToTooltip, isRequired: isRequired })),
        control && (React.createElement(FormControl, { fullWidth: true },
            React.createElement(Controller, { as: React.createElement(Select, { onChange: function (e) { return e.target.value; }, IconComponent: ArrowIcon },
                    React.createElement(MenuItem, { value: "none", disabled: true }, placeholder || 'Please select'),
                    options.map(function (option) { return (React.createElement(MenuItem, { key: option.label, value: option.value }, option.label)); })), name: name, control: control, defaultValue: "none", rules: { required: isRequired, validate: function (value) { return value !== 'none'; } }, disabled: disabled }))),
        !control && (React.createElement(FormControl, null,
            React.createElement(Select, { onChange: function (e) { return handleOnChange(e.target.value); }, IconComponent: ArrowIcon, defaultValue: "none", disabled: disabled, readOnly: readOnly, value: value },
                React.createElement(MenuItem, { value: "none", disabled: true }, placeholder || 'Please select'),
                options &&
                    options.map(function (option) { return (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)); }))))));
};
export default FormSelect;
var templateObject_1, templateObject_2, templateObject_3;
