var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { color, fontWeight } from 'styles';
import { TableCellType } from '../types';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  text-align: center;\n  box-sizing: border-box;\n"], ["\n  background-color: ",
    ";\n  color: ", ";\n  font-weight: ", ";\n  text-align: center;\n  box-sizing: border-box;\n"])), function (props) {
    return props.selected ? color.green : props.cellType === TableCellType.TEXT ? 'white' : 'rgba(137, 137, 137, 0.04)';
}, function (props) { return (props.selected ? 'white' : '#202020'); }, fontWeight.light);
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n"], ["\n  position: relative;\n  height: 100%;\n"])));
var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: left;\n  padding: 20px 10px;\n\n  strong {\n    font-weight: ", ";\n  }\n"], ["\n  text-align: left;\n  padding: 20px 10px;\n\n  strong {\n    font-weight: ", ";\n  }\n"])), fontWeight.medium);
var IconCell = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  svg {\n    fill: ", ";\n  }\n"], ["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  svg {\n    fill: ", ";\n  }\n"])), function (props) { return (props.selected ? 'white' : '#202020'); });
var TableCell = function (_a) {
    var text = _a.text, icon = _a.icon, _b = _a.selected, selected = _b === void 0 ? false : _b, cellType = _a.cellType, style = _a.style, borderStyle = _a.borderStyle;
    return (React.createElement(Root, { style: style, selected: selected, cellType: cellType },
        React.createElement(ContentContainer, { style: borderStyle },
            text && cellType === TableCellType.TEXT && (React.createElement(TextWrapper, { selected: selected },
                React.createElement(ReactMarkdown, { source: text }))),
            icon && cellType !== TableCellType.TEXT && React.createElement(IconCell, { selected: selected }, icon))));
};
export default TableCell;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
