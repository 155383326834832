var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import InputField from 'components/molecules/Input';
import { color, fontWeight, fontFamily } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 30px;\n  text-align: center;\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 30px;\n  text-align: center;\n"])), fontWeight.medium);
var HintWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 30px;\n"], ["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 30px;\n"])));
var FieldWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
var Buttons = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n\n  ", " {\n    width: 160px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n\n  ", " {\n    width: 160px;\n  }\n"])), ButtonRoot);
var BackButton = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 160px;\n  color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  padding: 16px;\n"], ["\n  width: 160px;\n  color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  padding: 16px;\n"])), color.greyMedium, fontFamily.condensed);
var ForgotPassword = function (_a) {
    var onBackToSignIn = _a.onBackToSignIn, onReset = _a.onReset, content = _a.content, _b = _a.shouldSetNewPassword, shouldSetNewPassword = _b === void 0 ? false : _b;
    var forgotPasswordTitle = content.forgotPasswordTitle, forgotPasswordHint = content.forgotPasswordHint, emailLabel = content.emailLabel, emailPlaceholder = content.emailPlaceholder, backToSignInText = content.backToSignInText, resetButtonText = content.resetButtonText, newPasswordTitle = content.newPasswordTitle, newPasswordHint = content.newPasswordHint, newPasswordLabel = content.newPasswordLabel, newPasswordPlaceholder = content.newPasswordPlaceholder, confirmPasswordLabel = content.confirmPasswordLabel, confirmPasswordPlaceholder = content.confirmPasswordPlaceholder;
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState(''), password = _d[0], setPassword = _d[1];
    var _e = useState(''), passwordRepeat = _e[0], setPasswordRepeat = _e[1];
    var shouldDisableButton = shouldSetNewPassword
        ? password === '' || passwordRepeat === '' || password !== passwordRepeat
        : email === '';
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null, shouldSetNewPassword ? newPasswordTitle : forgotPasswordTitle),
        React.createElement(HintWrapper, null, shouldSetNewPassword ? newPasswordHint : forgotPasswordHint),
        shouldSetNewPassword ? (React.createElement(React.Fragment, null,
            React.createElement(FieldWrapper, null,
                React.createElement(InputField, { type: "password", label: newPasswordLabel, placeholder: newPasswordPlaceholder, onChange: function (value) { return setPassword(value); } })),
            React.createElement(FieldWrapper, null,
                React.createElement(InputField, { type: "password", label: confirmPasswordLabel, placeholder: confirmPasswordPlaceholder, onChange: function (value) { return setPasswordRepeat(value); } })))) : (React.createElement(FieldWrapper, null,
            React.createElement(InputField, { type: "email", label: emailLabel, placeholder: emailPlaceholder, onChange: function (value) { return setEmail(value); } }))),
        React.createElement(Buttons, null,
            React.createElement(BackButton, { onClick: function () { return onBackToSignIn(); } }, backToSignInText),
            React.createElement(Button, { onClick: function () {
                    if (shouldSetNewPassword) {
                        onReset(password);
                    }
                    else {
                        onReset(email);
                    }
                    TagManagerArgs('Button', window.location.pathname + " - Forgot Password", resetButtonText);
                }, disabled: shouldDisableButton }, resetButtonText))));
};
export default ForgotPassword;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
