var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import HideOn from 'components/atoms/HideOn';
import Point from 'components/atoms/Point';
import { color, fontSize, fontWeight, media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  padding: 0 16px;\n  text-align: center;\n"], ["\n  flex: 1;\n  padding: 0 16px;\n  text-align: center;\n"])));
var MilesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n  "], ["\n    display: flex;\n  "]))));
var MilesWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding-top: 4px;\n"], ["\n  display: flex;\n  padding-top: 4px;\n"])));
var ValueWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 25px;\n  font-weight: ", ";\n  margin-left: auto;\n"], ["\n  font-size: 25px;\n  font-weight: ", ";\n  margin-left: auto;\n"])), fontWeight.medium);
var UnitWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 18px;\n  margin-left: 4px;\n  margin-right: auto;\n  padding-top: 4px;\n"], ["\n  font-size: 18px;\n  margin-left: 4px;\n  margin-right: auto;\n  padding-top: 4px;\n"])));
var TextContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n  line-height: 1.5;\n  margin-top: 12px;\n  text-align: center;\n\n  span {\n    color: ", ";\n  }\n\n  ", "\n"], ["\n  font-size: ", ";\n  line-height: 1.5;\n  margin-top: 12px;\n  text-align: center;\n\n  span {\n    color: ", ";\n  }\n\n  ",
    "\n"])), fontSize.fs14, color.green, media.medium(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    text-align: left;\n  "], ["\n    text-align: left;\n  "]))));
var TextWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.medium(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin-left: 36px;\n  "], ["\n    margin-left: 36px;\n  "]))));
var LegendWidget = function (_a) {
    var color = _a.color, text = _a.text, date = _a.date, unit = _a.unit, value = _a.value;
    return (React.createElement(Root, null,
        React.createElement(MilesContainer, null,
            React.createElement(Point, { color: color, innerRadius: 8, outerRadius: 16 }),
            React.createElement(MilesWrapper, null,
                React.createElement(ValueWrapper, null, new Intl.NumberFormat().format(value)),
                React.createElement(UnitWrapper, null, unit))),
        React.createElement(TextContainer, null,
            React.createElement(TextWrapper, null,
                React.createElement(HideOn, { medium: true },
                    text,
                    React.createElement("br", null),
                    React.createElement("span", null,
                        "\u00A0",
                        date)),
                React.createElement(HideOn, { smallOnly: true },
                    text,
                    React.createElement("span", null,
                        "\u00A0",
                        date))))));
};
export default LegendWidget;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
