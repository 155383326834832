var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import ListRow from 'components/molecules/ListRow';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import PageViewIcon from 'assets/pageview.svg';
import { color, fontWeight } from 'styles';
import Tab from 'components/molecules/Tab';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Switcher = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: 10px;\n  margin-bottom: 40px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: 10px;\n  margin-bottom: 40px;\n"])));
var DocumentsSection = function (_a) {
    var content = _a.content, documents = _a.documents, renewalDocuments = _a.renewalDocuments, downloadDocument = _a.downloadDocument;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var _c = useState(false), renewalVisible = _c[0], setRenewalVisible = _c[1];
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(SectionHeader, { expandable: true, open: open, title: content.title, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(PaddingVertical, null,
                    renewalDocuments && (React.createElement(Switcher, null,
                        React.createElement(Tab, { key: "policyDocuments", tabKey: "policyDocuments", selected: !renewalVisible, text: "Policy Documents", onClick: function () { return setRenewalVisible(false); } }),
                        React.createElement(Tab, { key: "renewalDocuments", tabKey: "reneDocuments", selected: renewalVisible, text: "Renewal Documents", onClick: function () { return setRenewalVisible(true); } }))),
                    React.createElement(ListRow, { style: { borderBottom: "1px solid " + color.greyMedium }, label: content.title, labelStyle: { fontWeight: fontWeight.medium }, value: content.downloadText, valueStyle: { fontWeight: fontWeight.medium } }),
                    (renewalVisible && renewalDocuments ? renewalDocuments : documents).map(function (doc, index) { return (React.createElement(ListRow, { key: doc.docUID, style: { borderBottom: index === documents.length - 1 ? '' : '1px solid rgba(137, 137, 137, 0.2)' }, label: doc.name, labelStyle: { fontWeight: fontWeight.light }, icon: React.createElement(PageViewIcon, null), onIconClick: function () { return downloadDocument(doc); } })); })))))));
};
export default DocumentsSection;
var templateObject_1, templateObject_2;
