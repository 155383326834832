import { renewToken } from './client';
import { getPaymentValidation, getSagePay, submitPayment } from './paymentAPIs';
var validateCardOrInstallmentsAttempts = 0;
export var validateCardOrInstallments = function (jobId, validationEndpoint, accessToken, tokenForRefresh, callBack) {
    getPaymentValidation(jobId, validationEndpoint, accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            validateCardOrInstallmentsAttempts = 0;
            callBack(payload);
        }
        else if (status === 401 && validateCardOrInstallmentsAttempts < 5) {
            validateCardOrInstallmentsAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return validateCardOrInstallments(jobId, validationEndpoint, newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error during validating payment | status code " + status);
        }
    });
};
var bindPaymentAttempts = 0;
export var bindPayment = function (jobId, data, accessToken, tokenForRefresh, callBack, topupMilesPath) {
    submitPayment(jobId, data, accessToken, topupMilesPath).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            callBack(payload);
            bindPaymentAttempts = 0;
        }
        else if (status === 401 && bindPaymentAttempts < 5) {
            bindPaymentAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return bindPayment(jobId, data, newToken, newRefreshToken, callBack, topupMilesPath);
            });
        }
        else {
            console.log("Error during binding payment | status code " + status);
        }
    });
};
var fetchSagePayAttempts = 0;
export var fetchSagePay = function (jobId, data, accessToken, tokenForRefresh, callBack, topupMilesPath) {
    getSagePay(jobId, data, accessToken, topupMilesPath).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            callBack(payload);
            fetchSagePayAttempts = 0;
        }
        else if (status === 401 && fetchSagePayAttempts < 5) {
            fetchSagePayAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return fetchSagePay(jobId, data, newToken, newRefreshToken, callBack, topupMilesPath);
            });
        }
        else {
            console.log("Error during fetching sagepay link | status code " + status);
        }
    });
};
