var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { fontWeight } from 'styles';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: rgba(0, 127, 73, 0.05);\n  font-size: 30px;\n  font-weight: ", ";\n  padding: 30px 0;\n"], ["\n  background: rgba(0, 127, 73, 0.05);\n  font-size: 30px;\n  font-weight: ", ";\n  padding: 30px 0;\n"])), fontWeight.medium);
var TotalPriceBanner = function (_a) {
    var title = _a.title, price = _a.price;
    return (React.createElement(Layout, null,
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null, "" + title + price))));
};
export default TotalPriceBanner;
var templateObject_1;
