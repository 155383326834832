var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useWindowSize } from '@react-hook/window-size/throttled';
import { color, fontWeight, media, screenMediumMin } from 'styles';
import PlusIcon from 'assets/plus.svg';
import RemoveIcon from 'assets/remove.svg';
import Button from 'components/atoms/Button';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: ", ";\n  border-top-width: 6px;\n\n  ul {\n    font-size: 14px;\n    font-weight: ", ";\n\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 6px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  a {\n    color: ", ";\n  }\n"], ["\n  border: ", ";\n  border-top-width: 6px;\n\n  ul {\n    font-size: 14px;\n    font-weight: ", ";\n\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 6px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  a {\n    color: ", ";\n  }\n"])), function (props) { return (props.selected ? '1px solid rgba(59, 156, 115, 0.5)' : '1px solid rgba(137, 137, 137, 0.2)'); }, fontWeight.light, color.green);
var AddonContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border-bottom: ", ";\n  padding: 16px 40px;\n\n  ", "\n"], ["\n  background: ", ";\n  border-bottom: ",
    ";\n  padding: 16px 40px;\n\n  ",
    "\n"])), function (props) { return (props.selected ? 'rgba(59, 156, 115, 0.07)' : 'rgba(137, 137, 137, 0.06)'); }, function (props) {
    return props.selected ? '1px solid rgba(59, 156, 115, 0.07)' : '1px solid rgba(137, 137, 137, 0.2)';
}, media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    background: ", ";\n    justify-content: space-between;\n  "], ["\n    display: flex;\n    align-items: center;\n    background: ", ";\n    justify-content: space-between;\n  "])), function (props) { return (props.selected ? 'rgba(59, 156, 115, 0.07)' : 'white'); }));
var TitleContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.smallOnly(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-bottom: 16px;\n  "], ["\n    margin-bottom: 16px;\n  "]))));
var TitleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n"])), fontWeight.bold);
var ChargeTitleWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: ", ";\n  margin-top: 8px;\n"], ["\n  font-size: 18px;\n  font-weight: ", ";\n  margin-top: 8px;\n"])), fontWeight.medium);
var ChargeTextWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: ", ";\n"], ["\n  font-size: 14px;\n  font-weight: ", ";\n"])), fontWeight.light);
var ContentTextWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 16px;\n"], ["\n  padding: 16px;\n"])));
var IncludesTextWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: 'white';\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 0 24px;\n"], ["\n  background: 'white';\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 0 24px;\n"])));
var DescriptionWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: 'white';\n  font-size: 14px;\n  font-weight: ", ";\n  padding: 16px 24px 0 24px;\n\n  p {\n    margin-bottom: 10px;\n  }\n"], ["\n  background: 'white';\n  font-size: 14px;\n  font-weight: ", ";\n  padding: 16px 24px 0 24px;\n\n  p {\n    margin-bottom: 10px;\n  }\n"])), fontWeight.light);
var Addon = function (_a) {
    var title = _a.title, charge = _a.charge, chargeTitle = _a.chargeTitle, chargeText = _a.chargeText, addCoverText = _a.addCoverText, removeCoverText = _a.removeCoverText, includesText = _a.includesText, description = _a.description, onSelect = _a.onSelect, onRemove = _a.onRemove, _b = _a.initialSelection, initialSelection = _b === void 0 ? false : _b;
    var _c = useState(initialSelection), added = _c[0], setAdded = _c[1];
    var width = useWindowSize()[0];
    var onButtonClick = function () {
        added ? onRemove() : onSelect();
        setAdded(!added);
    };
    return (React.createElement(Root, { selected: added },
        React.createElement(AddonContainer, { selected: added },
            React.createElement(TitleContainer, null,
                React.createElement(TitleWrapper, null, title),
                React.createElement(ChargeTitleWrapper, null, "\u00A3" + charge + " " + chargeTitle),
                charge && (React.createElement(ChargeTextWrapper, null, chargeText.replace('${chargeMonthly}', (charge / 12).toFixed(2).toString())))),
            React.createElement(Button, { onClick: function () { return onButtonClick(); }, buttonStyle: added ? 'warning secondary' : 'secondary', fullWidth: width < screenMediumMin, leftIcon: added ? React.createElement(RemoveIcon, null) : React.createElement(PlusIcon, null) }, added ? removeCoverText : addCoverText)),
        React.createElement(ContentTextWrapper, null,
            React.createElement(IncludesTextWrapper, null,
                React.createElement(ReactMarkdown, { linkTarget: "__blank", source: includesText })),
            React.createElement(DescriptionWrapper, null,
                React.createElement(ReactMarkdown, { linkTarget: "__blank", source: description })))));
};
export default Addon;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
