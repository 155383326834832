var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import styled from 'styled-components';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import InfoIcon from 'assets/info.svg';
import { color, fontSize, fontWeight, media } from 'styles';
import HideOn from 'components/atoms/HideOn';
import BootstrapTooltip from './BootstrapTooltip';
import InfoWidget from './InfoWidget';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var DesktopRoot = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var MobileRoot = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var InfoContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 20px 0 10px 0;\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 20px 0 10px 0;\n"])));
var ValueWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n"])), fontWeight.bold);
var LabelWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: 0.5px;\n  margin: 0 20px;\n  text-align: left;\n\n  ", "\n"], ["\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: 0.5px;\n  margin: 0 20px;\n  text-align: left;\n\n  ",
    "\n"])), color.greyMedium, fontSize.fs14, media.large(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 100px;\n    text-align: right;\n  "], ["\n    width: 100px;\n    text-align: right;\n  "]))));
var ArrowWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-left: auto;\n\n  svg {\n    fill: ", ";\n  }\n"], ["\n  margin-left: auto;\n\n  svg {\n    fill: ", ";\n  }\n"])), color.black);
var BarWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: relative;\n  flex: 1;\n  border-top: ", ";\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 20px 0;\n\n  ", "\n\n  ", "\n"], ["\n  position: relative;\n  flex: 1;\n  border-top: ", ";\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 20px 0;\n\n  ",
    "\n\n  ",
    "\n"])), function (props) { return (props.firstBar ? '1px solid rgba(137, 137, 137, 0.2)' : 'none'); }, media.smallOnly(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    border-top: 1px solid rgba(137, 137, 137, 0.2);\n  "], ["\n    border-top: 1px solid rgba(137, 137, 137, 0.2);\n  "]))), media.mediumOnly(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    border-top: 1px solid rgba(137, 137, 137, 0.2);\n  "], ["\n    border-top: 1px solid rgba(137, 137, 137, 0.2);\n  "]))));
var InfoWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 70px;\n"], ["\n  width: 70px;\n"])));
var MilesBar = function (_a) {
    var color = _a.color, _b = _a.expandable, expandable = _b === void 0 ? false : _b, _c = _a.expanded, expanded = _c === void 0 ? false : _c, _d = _a.firstBar, firstBar = _d === void 0 ? false : _d, info = _a.info, label = _a.label, onArrowClick = _a.onArrowClick, overlayBars = _a.overlayBars, value = _a.value, milesScale = _a.milesScale;
    var BorderLinearProgress = withStyles({
        root: {
            height: '12px',
            backgroundColor: '#F3F3F6',
            borderRadius: '7.5px',
        },
        bar: {
            backgroundColor: color,
            borderRadius: 20,
        },
    })(LinearProgress);
    return (React.createElement(Root, null,
        React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
            React.createElement(DesktopRoot, null,
                React.createElement(LabelWrapper, null, label),
                React.createElement(BarWrapper, { firstBar: firstBar },
                    !expanded && overlayBars,
                    React.createElement(BorderLinearProgress, { variant: "determinate", color: "secondary", value: (value < 0 ? 0 : value / milesScale) * 100 })),
                React.createElement(InfoWrapper, null, info && React.createElement(InfoWidget, { expandable: expandable, expanded: expanded, info: info, onArrowClick: onArrowClick })))),
        React.createElement(HideOn, { large: true },
            React.createElement(MobileRoot, null,
                React.createElement(InfoContainer, null,
                    React.createElement(ValueWrapper, null, value),
                    React.createElement(LabelWrapper, null, label),
                    info && (React.createElement(BootstrapTooltip, { title: info },
                        React.createElement(InfoIcon, null))),
                    expandable && (React.createElement(ArrowWrapper, { onClick: function () { return onArrowClick && onArrowClick(); } }, expanded ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null)))),
                React.createElement(BarWrapper, { firstBar: firstBar },
                    !expanded && overlayBars,
                    React.createElement(BorderLinearProgress, { variant: "determinate", color: "secondary", value: (value < 0 ? 0 : value / milesScale) * 100 }))))));
};
export default MilesBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
