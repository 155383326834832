var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Title from 'components/atoms/Title';
import Selector from 'components/molecules/Selector';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { color, fontFamily, fontWeight, media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  position: relative;\n  background-color: ", ";\n  color: ", ";\n"])), function (props) { return (props.blueSection ? '#1d5995' : "" + color.greenDark); }, color.white);
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 90%;\n  "], ["\n    width: 90%;\n  "]))));
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 12px;\n  font-family: ", ";\n  font-stretch: condensed;\n  font-weight: ", ";\n  line-height: 1.25;\n  letter-spacing: 0.5px;\n\n  p {\n    margin-bottom: 20px;\n  }\n\n  a {\n    color: white;\n    font-weight: 400;\n  }\n\n  h2 {\n    margin: 0px;\n    padding: 0;\n  }\n\n  pre {\n    margin: 0px;\n  }\n"], ["\n  margin-top: 12px;\n  font-family: ", ";\n  font-stretch: condensed;\n  font-weight: ", ";\n  line-height: 1.25;\n  letter-spacing: 0.5px;\n\n  p {\n    margin-bottom: 20px;\n  }\n\n  a {\n    color: white;\n    font-weight: 400;\n  }\n\n  h2 {\n    margin: 0px;\n    padding: 0;\n  }\n\n  pre {\n    margin: 0px;\n  }\n"])), fontFamily.condensed, fontWeight.light);
var SelectorWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 20px;\n\n  ", "\n"], ["\n  width: 100%;\n  margin-top: 20px;\n\n  ",
    "\n"])), media.medium(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 300px;\n    min-width: 300px;\n    margin-top: 0;\n    margin-left: 20px;\n  "], ["\n    width: 300px;\n    min-width: 300px;\n    margin-top: 0;\n    margin-left: 20px;\n  "]))));
var HeaderWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 20px 0;\n\n  ", "\n"], ["\n  padding: 20px 0;\n\n  ",
    "\n"])), media.medium(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    padding: 50px 0;\n    align-items: center;\n  "], ["\n    display: flex;\n    justify-content: space-between;\n    padding: 50px 0;\n    align-items: center;\n  "]))));
var HeaderSection = function (_a) {
    var onSelect = _a.onSelect, options = _a.options, body = _a.body, title = _a.title, optionsIndex = _a.optionsIndex, _b = _a.blueSection, blueSection = _b === void 0 ? false : _b;
    return (React.createElement(Layout, null,
        React.createElement(Root, { blueSection: blueSection },
            React.createElement(PaddingHorizontal, null,
                React.createElement(HeaderWrapper, null,
                    React.createElement(TitleWrapper, null,
                        React.createElement(Title, null, title),
                        React.createElement(TextWrapper, null,
                            React.createElement(ReactMarkdown, { source: body }))),
                    onSelect && options && (React.createElement(SelectorWrapper, null,
                        React.createElement(Selector, { rounded: true, defaultIndex: optionsIndex, options: options, onSelect: function (value, index) { return onSelect(value, index); } }))))))));
};
export default HeaderSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
