var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import find from 'lodash/find';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormInput, FormSelect, Group } from 'components/organisms/Form';
import Button from 'components/atoms/Button';
import { getAddressesFromPostCode, getDetailedAddressFromId, mtaTokenRefresh } from 'services';
import { SessionStorageKey, AddressContentIds } from 'types';
import { TagManagerArgs } from 'analytics';
export var AddressLookup = function (_a) {
    var content = _a.content, addressToParent = _a.addressToParent, _b = _a.noBorder, noBorder = _b === void 0 ? false : _b;
    var _c = useState(''), postCodeValue = _c[0], setPostCodeValue = _c[1];
    var _d = useState(undefined), addressList = _d[0], setAddressList = _d[1];
    var _e = useState(undefined), address = _e[0], setAddress = _e[1];
    var history = useHistory();
    // Fields form CMS
    // Retrieving them individually stops the cms from changing the order or having to loop
    var postcode = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.POSTCODE;
    });
    var findAddress = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.FIND_ADDRESS;
    });
    var addressSelect = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.ADDRESS_SELECT;
    });
    // Sends postcode to GW, returns a list of addresses in that street/area
    var lookupPostCodeValue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, addressResults, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressList(undefined);
                    setAddress(undefined);
                    addressToParent(undefined);
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, getAddressesFromPostCode(postCodeValue, token)];
                case 1:
                    addressResults = _a.sent();
                    if (addressResults.status === 200) {
                        if (Object.keys(addressResults.data).length > 0) {
                            setAddressList(addressResults.data);
                        }
                        else {
                            setAddressList(undefined);
                        }
                    }
                    if (!(addressResults.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        lookupPostCodeValue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3:
                    if (addressResults.status === 500 || addressResults.data.status === 500) {
                        setAddressList(undefined);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    // Lookup full address details after dropdown option selected
    var lookupAddressId = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var token, detailedAddress, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (address !== undefined) {
                        setAddress(undefined);
                        addressToParent(undefined);
                    }
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, getDetailedAddressFromId(id, token)];
                case 1:
                    detailedAddress = _a.sent();
                    if (detailedAddress.status === 200) {
                        if (Object.keys(detailedAddress.data).length > 0) {
                            setAddress(detailedAddress.data);
                            addressToParent(detailedAddress.data);
                        }
                        else {
                            setAddress(undefined);
                            addressToParent(undefined);
                        }
                    }
                    if (!(detailedAddress.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        lookupAddressId(id);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3:
                    if (detailedAddress.status === 500 || detailedAddress.data.status === 500) {
                        setAddress(undefined);
                        addressToParent(undefined);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    // makes sure the address select options have the correct label/value attributes assigned to them
    var addressListValues = function (obj) {
        return obj.map(function (item) {
            return {
                label: item.address.description_itb,
                value: item.address.id_itb,
            };
        });
    };
    return (React.createElement(Group, { title: content.title, noBorder: noBorder },
        postcode && (React.createElement(FormInput, { name: postcode.value, placeholder: postcode.placeholder, onChange: function (value) { return setPostCodeValue(value.toUpperCase()); }, value: postCodeValue })),
        findAddress && (React.createElement(Button, { disabled: postCodeValue === '', type: "button", onClick: function () {
                lookupPostCodeValue();
                TagManagerArgs('Button', window.location.pathname + " - Postcode lookup", findAddress.label);
            } }, findAddress.label)),
        addressList && (addressSelect === null || addressSelect === void 0 ? void 0 : addressSelect.fieldId) && (React.createElement(FormSelect, { name: addressSelect.fieldId, options: addressListValues(addressList), onChange: function (value) { return lookupAddressId(value); }, placeholder: "Please select", spanTwoCols: addressSelect.spanTwoColumns }))));
};
