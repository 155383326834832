var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color, fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: ", ";\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 2px rgba(209, 209, 209, 0.28);\n  cursor: pointer;\n  padding: 32px 32px 16px 32px;\n  text-align: center;\n"], ["\n  border: ", ";\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 2px rgba(209, 209, 209, 0.28);\n  cursor: pointer;\n  padding: 32px 32px 16px 32px;\n  text-align: center;\n"])), function (props) { return (props.selected ? "1px solid " + color.green : 'none'); });
var MilesWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: 25px;\n  font-weight: ", ";\n  padding-bottom: 20px;\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: 25px;\n  font-weight: ", ";\n  padding-bottom: 20px;\n"])), fontWeight.medium);
var CostWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #007f49;\n  font-size: 20px;\n  font-weight: ", ";\n  margin-top: 20px;\n"], ["\n  color: #007f49;\n  font-size: 20px;\n  font-weight: ", ";\n  margin-top: 20px;\n"])), fontWeight.medium);
var MilesBox = function (_a) {
    var cost = _a.cost, miles = _a.miles, selected = _a.selected, onClick = _a.onClick;
    return (React.createElement(Root, { selected: selected, onClick: function () { return onClick(miles); } },
        React.createElement(MilesWrapper, null,
            miles,
            " Miles"),
        React.createElement(CostWrapper, null,
            "\u00A3",
            cost)));
};
export default MilesBox;
var templateObject_1, templateObject_2, templateObject_3;
