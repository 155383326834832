var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Title from 'components/atoms/Title';
import { getSectionContent } from 'components/organisms/Form/utils';
import { InfoIds } from './types';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    margin-bottom: 10px;\n    line-height: 1.25;\n  }\n\n  h3 {\n    font-weight: 400;\n  }\n"], ["\n  p {\n    margin-bottom: 10px;\n    line-height: 1.25;\n  }\n\n  h3 {\n    font-weight: 400;\n  }\n"])));
var InfoWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: solid 1px rgba(137, 137, 137, 0.5);\n  padding: 20px 0;\n"], ["\n  border-bottom: solid 1px rgba(137, 137, 137, 0.5);\n  padding: 20px 0;\n"])));
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
export var InformationText = function (_a) {
    var titleType = _a.titleType, information = _a.information;
    var title = '';
    if (titleType === InfoIds.NEW_DRIVER)
        title = 'New driver';
    if (titleType === InfoIds.NEW_CAR)
        title = 'New car';
    if (titleType === InfoIds.OVERNIGHT_PARKING)
        title = 'Overnight parking';
    if (titleType === InfoIds.OVERNIGHT_PARKING_WITHOUT_CA)
        title = 'Overnight parking';
    if (titleType === InfoIds.REMOVED_DRIVER)
        title = 'A driver has been removed';
    var infoText = getSectionContent(titleType, information);
    return (React.createElement(Root, null,
        React.createElement(InfoWrapper, null,
            React.createElement(TitleWrapper, null,
                React.createElement(Title, null, title)),
            React.createElement(ReactMarkdown, { escapeHtml: false, source: infoText.body }))));
};
var templateObject_1, templateObject_2, templateObject_3;
