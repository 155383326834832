var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var SagePayFailure = function () {
    var cancelled = window.location.search.includes('cancelled');
    useEffect(function () {
        setTimeout(function () {
            if (cancelled) {
                window.top.postMessage(JSON.stringify({
                    error: false,
                    message: 'cancelled',
                }), '*');
            }
            else {
                window.top.postMessage(JSON.stringify({
                    error: false,
                    message: 'failure',
                }), '*');
            }
        }, 5000);
    }, []);
    return React.createElement(Root, null, React.createElement("h1", null, cancelled ? 'Payment cancelled' : 'Payment failed'));
};
export default SagePayFailure;
var templateObject_1;
