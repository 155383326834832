var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/atoms/Button';
import { find } from 'lodash';
import { TagManagerArgs } from 'analytics';
var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 30px;\n"], ["\n  padding: 30px;\n"])));
var ModalTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var ModalBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n  }\n"], ["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n  }\n"])));
var ModalButtons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin: 0 10px;\n  }\n"])));
export var IdleModal = function (_a) {
    var modalOpen = _a.modalOpen, setModalOpen = _a.setModalOpen, onLogout = _a.onLogout, refresh = _a.refresh, content = _a.content;
    var logoutBtn = find(content.modalButtons, function (obj) {
        return obj.buttonId === 'logoutBtn';
    });
    var continueBtn = find(content.modalButtons, function (obj) {
        return obj.buttonId === 'continueBtn';
    });
    return (React.createElement(Dialog, { open: modalOpen },
        React.createElement(ModalWrapper, null,
            React.createElement(ModalTitle, null, content.title),
            React.createElement(ModalBody, null,
                React.createElement(ReactMarkdown, { linkTarget: "_blank", source: content.body })),
            React.createElement(ModalButtons, null,
                React.createElement(Button, { buttonStyle: logoutBtn.style, onClick: function () {
                        setModalOpen(false);
                        onLogout();
                        TagManagerArgs('Button', window.location.pathname + " - Idle modal", logoutBtn.text);
                    } }, logoutBtn.text),
                React.createElement(Button, { buttonStyle: continueBtn.style, onClick: function () {
                        setModalOpen(false);
                        refresh();
                        TagManagerArgs('Button', window.location.pathname + " - Idle modal", continueBtn.text);
                    } }, continueBtn.text)))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
