var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import filter from 'lodash/filter';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory, useLocation } from 'react-router-dom';
import { SessionStorageKey, AddDriverWatchedFieldIds, ContentPageURL, } from 'types';
import { getPageContent, addNewDriver, mtaTokenRefresh, multiMtas } from 'services';
import { getDriverValues } from './getDriverValues';
import MtaModal from 'components/molecules/MtaModal';
import MtaPageButtons from 'components/molecules/MtaPageButtons';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import MtaDateAndTime from 'components/organisms/MtaDateAndTime';
import ShareComponent from 'pages/ShareComponent';
import { AddDriverConvictionsAndClaims, AddDriverDetails, AddDriverNewDriver, AddDriverConvictions, AddDriverClaims, } from 'components/organisms/MtaAddDriver';
// styled components
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var MtaAddDriverPage = function (_a) {
    var currentPolicy = _a.currentPolicy, setQuoteDetails = _a.setQuoteDetails, setMtaDate = _a.setMtaDate, mta = _a.mta, setMta = _a.setMta, setMultiMtaResponse = _a.setMultiMtaResponse, mtaEffectiveDate = _a.mtaEffectiveDate, multiMtaResponse = _a.multiMtaResponse, setMultiDrivers = _a.setMultiDrivers, setDriverFormDetails = _a.setDriverFormDetails, setConvictionsAndClaimsFormDetails = _a.setConvictionsAndClaimsFormDetails, setMtaFormDetails = _a.setMtaFormDetails, mtaFormDetails = _a.mtaFormDetails, multiMtaDrivers = _a.multiMtaDrivers;
    // CMS
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), formButtons = _d[0], setFormButtons = _d[1];
    var _e = useState(undefined), modalContent = _e[0], setModalContent = _e[1];
    // Modal
    var _f = useState(false), modalOpen = _f[0], setModalOpen = _f[1];
    // Form / MTA
    var _g = useState(''), effectiveDate = _g[0], setEffectiveDate = _g[1];
    var _h = useState(undefined), gwFormData = _h[0], setGwFormData = _h[1];
    // which submit button is pressed
    var _j = useState(''), submitType = _j[0], setSubmitType = _j[1];
    var _k = useState({
        display: [],
        data: [],
    }), convictions = _k[0], setConvictions = _k[1];
    var _l = useState({
        display: [],
        data: [],
    }), claims = _l[0], setClaims = _l[1];
    var _m = useState(undefined), amendConvictionClaims = _m[0], setAmendConvictionClaims = _m[1];
    var driverAmendDetails = undefined;
    if (mtaFormDetails && mtaFormDetails.amend === true) {
        driverAmendDetails = mtaFormDetails.drivers[mtaFormDetails.index];
    }
    var history = useHistory();
    var location = useLocation();
    var _o = useForm({
        defaultValues: driverAmendDetails,
    }), register = _o.register, handleSubmit = _o.handleSubmit, control = _o.control, watch = _o.watch, errors = _o.errors;
    // Which fields trigger hidden fields to show / hide
    var watchedFields = watch([
        AddDriverWatchedFieldIds.UK_RESIDENT_FIVE_YEARS,
        AddDriverWatchedFieldIds.LICENSE_TYPE,
        AddDriverWatchedFieldIds.TEST_PASSED_TEN_YEARS,
        AddDriverWatchedFieldIds.HAS_MOTOR_CONVICTIONS,
        AddDriverWatchedFieldIds.HAS_MOTOR_CLAIMS,
    ]);
    // Form submission
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var obj, policyNumber, token, otherNewDrivers, driver, driverToAmend_1, removeDriverFlag, otherChanges, mtaFlag, oldFormData, amendedFormData, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    obj = getDriverValues(data);
                    obj.newlyAdded = true;
                    // Adds convictions and claims, if there are none the array will be empty in the request, GW is ok with this
                    obj.pmClaims = claims.data;
                    obj.pmMotorConvictions = convictions.data;
                    if (submitType === 'continue') {
                        // set the returned object to state. If confirmed by the user in the modal, this is the object that will be sent to GW
                        setGwFormData(obj);
                        // For amending the the driver from the Quote page
                        setDriverFormDetails(data);
                        // open the modal for confirmation
                        setModalOpen(true);
                        // set conviction and claims details for later use in amend
                        setConvictionsAndClaimsFormDetails({
                            claims: claims,
                            convictions: convictions,
                        });
                    }
                    if (!(submitType === 'otherChanges')) return [3 /*break*/, 3];
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    otherNewDrivers = undefined;
                    if (multiMtaResponse)
                        otherNewDrivers = filter(multiMtaResponse.drivers, { newlyAdded: true }).map(function (driver) {
                            var driverData = driver;
                            driverData.newlyAdded = false;
                            driverData.newlyAdded = false;
                            return driverData;
                        });
                    driver = undefined;
                    if (multiMtaResponse === undefined) {
                        driver = { drivers: [obj] };
                    }
                    else
                        driver = {
                            status: multiMtaResponse.status,
                            jobId: multiMtaResponse.jobId,
                            drivers: otherNewDrivers ? __spreadArrays(otherNewDrivers, [obj]) : [obj],
                        };
                    if (mtaFormDetails && mtaFormDetails.amend === true) {
                        driverToAmend_1 = mtaFormDetails.drivers[mtaFormDetails.index];
                        removeDriverFlag = multiMtaResponse.drivers.map(function (item) {
                            if (item.firstName === driverToAmend_1.firstName && item.lastName === driverToAmend_1.lastName) {
                                var amend = __assign(__assign(__assign({}, item), obj), { updateOrRemove: 'update', newlyAdded: false });
                                return amend;
                            }
                            else
                                return item;
                        });
                        driver = {
                            status: multiMtaResponse.status,
                            jobId: multiMtaResponse.jobId,
                            drivers: removeDriverFlag,
                            dayTimeParking: multiMtaResponse.vehicles[0].dayTimeParking,
                            nightTimeParking: multiMtaResponse.vehicles[0].nightTimeParking,
                        };
                    }
                    return [4 /*yield*/, multiMtas(policyNumber, driver, effectiveDate, token)];
                case 1:
                    otherChanges = _a.sent();
                    if (otherChanges.status === 200) {
                        mtaFormDetails && mtaFormDetails.amend === true
                            ? setMultiDrivers(driver.drivers)
                            : setMultiDrivers(__spreadArrays(multiMtaDrivers, [__assign(__assign({}, obj), { newlyAdded: true })]));
                        mtaFlag = __assign(__assign({}, mta), { addDriver: true });
                        setMta(mtaFlag);
                        setMultiMtaResponse(otherChanges.data);
                        setMtaDate(effectiveDate);
                        if (mtaFormDetails && mtaFormDetails.amend === true) {
                            oldFormData = mtaFormDetails.drivers;
                            amendedFormData = oldFormData.map(function (item, index) {
                                if (index !== mtaFormDetails.index)
                                    return item;
                                return data;
                            });
                            setMtaFormDetails({
                                amend: false,
                                index: 0,
                                drivers: amendedFormData,
                            });
                        }
                        else
                            setMtaFormDetails(__assign(__assign({}, mtaFormDetails), { drivers: __spreadArrays(mtaFormDetails.drivers, [data]) }));
                        history.push('/other-changes');
                    }
                    if ((otherChanges.status !== undefined && otherChanges.status === 500) ||
                        (otherChanges.data.status !== undefined && otherChanges.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(otherChanges.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        onSubmit(data);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var modalContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, token, quote, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, addNewDriver(policyNumber, gwFormData, effectiveDate, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        setQuoteDetails(quote.data);
                        mtaFlag = __assign(__assign({}, mta), { addDriver: true });
                        setMta(mtaFlag);
                        setMtaDate(effectiveDate);
                        history.push('/your-quote');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        modalContinue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onCancel = function () {
        if (mtaEffectiveDate)
            history.push('/other-changes');
        else
            history.push('/mypolicy');
    };
    useEffect(function () {
        if (mtaEffectiveDate !== undefined)
            setEffectiveDate(mtaEffectiveDate);
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.ADD_DRIVER_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent !== undefined)
            setFormButtons(cmsContent.contentSections[0].buttons);
        if (cmsContent !== undefined)
            setModalContent(cmsContent.modals[0]);
        if (location.state !== undefined) {
            setAmendConvictionClaims(location.state.amendConvictionsAndClaims);
        }
    }, [cmsContent, currentPolicy, driverAmendDetails]);
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) }, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(MtaDateAndTime, { disabled: mtaEffectiveDate !== undefined, getEffectiveDate: function (date) { return setEffectiveDate(date); }, mtaEffectiveDate: mtaEffectiveDate }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                formGroups && effectiveDate !== '' && (React.createElement(React.Fragment, null,
                    React.createElement(AddDriverNewDriver, { formGroups: formGroups, register: register, control: control, watchedFields: watchedFields, errors: errors }),
                    React.createElement(AddDriverDetails, { formGroups: formGroups, control: control, watchedFields: watchedFields, errors: errors }),
                    React.createElement(AddDriverConvictionsAndClaims, { formGroups: formGroups, control: control, errors: errors }),
                    currentPolicy && watchedFields.hasMotorConvictions === 'Yes' && (React.createElement(AddDriverConvictions, { onChange: function (data) { return setConvictions(data); }, formGroups: formGroups, currentPolicy: currentPolicy, amendDetails: amendConvictionClaims !== undefined && amendConvictionClaims.convictions !== undefined
                            ? amendConvictionClaims.convictions
                            : undefined })),
                    watchedFields.hasMotorClaims === 'Yes' && (React.createElement(AddDriverClaims, { onChange: function (data) { return setClaims(data); }, formGroups: formGroups, amendDetails: amendConvictionClaims !== undefined && amendConvictionClaims.claims !== undefined
                            ? amendConvictionClaims.claims
                            : undefined })))),
                formButtons && (React.createElement(MtaPageButtons, { formButtons: formButtons, submitType: setSubmitType, showMtaButtons: effectiveDate !== '', onCancel: onCancel, showContinue: mtaEffectiveDate === undefined })))),
        modalContent && (React.createElement(MtaModal, { modalOpen: modalOpen, setModalOpen: setModalOpen, modalContent: modalContent, modalContinue: modalContinue }))))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    mta: state.mta,
    mtaEffectiveDate: state.mtaEffectiveDate,
    multiMtaResponse: state.multiMtaResponse,
    mtaFormDetails: state.mtaFormDetails,
    multiMtaDrivers: state.multiMtaDrivers,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setQuoteDetails: function (quote) { return dispatch(actions.setQuote(quote)); },
    setMtaDate: function (date) { return dispatch(actions.setMtaEffectiveDate(date)); },
    setMta: function (mta) { return dispatch(actions.setMta(mta)); },
    setMtaFormDetails: function (data) { return dispatch(actions.setMtaFormDetails(data)); },
    setMultiMtaResponse: function (response) { return dispatch(actions.setMultiMtaResponse(response)); },
    setMultiDrivers: function (driver) { return dispatch(actions.setMultiDrivers(driver)); },
    setDriverFormDetails: function (driver) { return dispatch(actions.setDriverFormDetails(driver)); },
    setConvictionsAndClaimsFormDetails: function (data) { return dispatch(actions.setConvictionsAndClaimsFormDetails(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MtaAddDriverPage);
var templateObject_1;
