var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { fontSize, fontWeight, media } from 'styles';
import HideOn from 'components/atoms/HideOn';
import MilesBar from './MilesBar';
import MilesBox from './MilesBox';
import OverlayBar from './OverlayBar';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 30px 15px;\n\n  ", "\n"], ["\n  padding: 30px 15px;\n\n  ",
    "\n"])), media.large(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 1024px;\n    margin: 0 auto;\n    padding: 40px 80px 30px 80px;\n  "], ["\n    max-width: 1024px;\n    margin: 0 auto;\n    padding: 40px 80px 30px 80px;\n  "]))));
var MilesBoxes = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 30px;\n  text-align: justify;\n"], ["\n  display: flex;\n  margin-bottom: 30px;\n  text-align: justify;\n"])));
var MilesBars = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var Scale = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin: 8px 0px 18px 0px;\n\n  ", "\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin: 8px 0px 18px 0px;\n\n  ",
    "\n"])), media.large(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 8px 72px 18px 140px;\n  "], ["\n    margin: 8px 72px 18px 140px;\n  "]))));
var ScaleLabel = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var LabelWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  text-align: center;\n"])), fontSize.fs12, fontWeight.medium);
var MilesWidgetSection = function (_a) {
    var milesData = _a.milesData, milesWidgetContent = _a.milesWidgetContent;
    var _b = useState(true), expanded = _b[0], setExpanded = _b[1];
    var maxMiles = milesData.reduce(function (max, miles) { return (miles.value > max.value ? miles : max); });
    var milesScale = 10000;
    if (maxMiles.value > 10000 && maxMiles.value < 20000) {
        milesScale = 20000;
    }
    if (maxMiles.value > 20000 && maxMiles.value < 50000) {
        milesScale = 50000;
    }
    if (maxMiles.value > 50000 && maxMiles.value < 100000) {
        milesScale = 100000;
    }
    var milesWithBreakdown = milesData.filter(function (miles) { return miles.breakdown !== undefined; })[0].breakdown;
    var contentWithBreakdown = milesWidgetContent.milesBars.filter(function (content) { return content.breakdown !== undefined; })[0]
        .breakdown;
    var milesContent = milesWidgetContent.milesBars;
    return (React.createElement(Root, null,
        React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
            React.createElement(MilesBoxes, null, milesData.map(function (miles) {
                var content = milesContent.filter(function (content) { return content.key === miles.key; })[0];
                return React.createElement(MilesBox, { key: miles.key, color: content.color, label: content.label, value: miles.value });
            }))),
        React.createElement(MilesBars, null,
            milesData.map(function (miles, index) {
                var content = milesContent.filter(function (content) { return content.key === miles.key; })[0];
                var milesBreakdown = miles.breakdown;
                var contentBreakdown = content.breakdown;
                var overlayBars = undefined;
                if (milesBreakdown && contentBreakdown) {
                    overlayBars = milesBreakdown
                        .sort(function (a, b) { return b.value - a.value; })
                        .map(function (item) {
                        var itemContent = contentBreakdown.filter(function (content) { return content.key === item.key; })[0];
                        return (React.createElement(OverlayBar, { key: item.key, color: itemContent.color, value: (item.value / milesScale) * 100 + "%", info: itemContent.breakdownInfo }));
                    });
                }
                return (React.createElement(MilesBar, { key: miles.key, info: content.info, milesScale: milesScale, expanded: expanded, firstBar: index === 0, color: content.color, label: content.label, value: miles.value, overlayBars: overlayBars, onArrowClick: function () { return setExpanded(true); } }));
            }),
            expanded &&
                milesWithBreakdown &&
                contentWithBreakdown &&
                milesWithBreakdown.map(function (item) {
                    var content = contentWithBreakdown.filter(function (content) { return content.key === item.key; })[0];
                    return (React.createElement(MilesBar, { key: item.key, milesScale: milesScale, info: content.info, color: content.color, label: content.label, value: item.value }));
                })),
        React.createElement(Scale, null,
            React.createElement(ScaleLabel, null, "0"),
            React.createElement(ScaleLabel, null, "" + milesScale / 1000 / 2,
                "k"),
            React.createElement(ScaleLabel, null, "" + milesScale / 1000,
                "k")),
        React.createElement(LabelWrapper, null, milesWidgetContent.widgetLabel)));
};
export default MilesWidgetSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
