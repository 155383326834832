import moment from 'moment';
import { AddDriverCmsValue } from 'types';
// Converts the values from the form object to the correct GW formats
export var getDriverValues = function (data) {
    var keys = Object.keys(data);
    var obj = Object.fromEntries(keys.map(function (key) {
        // Occupation autosearch returns an object as the form data value, GW only need the value
        if (key === AddDriverCmsValue.OCCUPATION)
            return [key, data[key].value];
        if (data[key] === 'Yes' && key !== AddDriverCmsValue.DRIVING_QUALIFICATIONS)
            return [key, true];
        if (data[key] === 'No' &&
            key !== AddDriverCmsValue.DRIVING_QUALIFICATIONS &&
            key !== AddDriverCmsValue.MEDICAL_CONDITIONS)
            return [key, false];
        // converts from datestamp to a date object
        if (key === AddDriverCmsValue.DATE_OF_BIRTH) {
            var date = new Date(data[key]);
            return [
                key,
                {
                    day: date.getDate(),
                    month: date.getMonth(),
                    year: date.getFullYear(),
                },
            ];
        }
        return [key, data[key]];
    }));
    if (obj.ukResidencyDate) {
        obj.ukResidencyMonth = moment(obj.ukResidencyDate).format('MMMM');
        obj.ukResidencyYear = parseInt(moment(obj.ukResidencyDate).format('YYYY'));
        delete obj.ukResidencyDate;
    }
    if (obj.passDate) {
        obj.passedTestMonth = moment(obj.passDate).format('MMMM');
        obj.passedTestYear = parseInt(moment(obj.passDate).format('YYYY'));
        delete obj.passDate;
    }
    if (obj.firstDroveProvisionalDate) {
        obj.provisionalLicenseMonth = moment(obj.firstDroveProvisionalDate).format('MMMM');
        obj.provisionalLicenseYear = parseInt(moment(obj.firstDroveProvisionalDate).format('YYYY'));
        delete obj.firstDroveProvisionalDate;
    }
    // These are default values required for the MTA to work
    obj.newlyAdded = true;
    obj.accountHolder = false;
    obj.tempID = '1';
    return obj;
};
