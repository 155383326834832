var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { color, fontFamily, space, fontSize } from 'styles';
export var Root = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: ", ";\n  height: 50px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  background: ", ";\n  border: solid 1.5px ", ";\n  border-radius: 42px;\n  padding: ", " ", ";\n  color: ", ";\n  cursor: ", ";;\n  font-family: ", ";\n  font-size: ", ";\n  text-align: center;\n\n  opacity: ", ";\n\n  a {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  svg {\n    bottom: 3px;\n    position: relative;\n    fill: ", ";\n    margin-left: 5px;\n  }\n\n  :focus {\n    outline: 0;\n  }\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  width: ", ";\n  height: 50px;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  background: ", ";\n  border: solid 1.5px ", ";\n  border-radius: 42px;\n  padding: ", " ", ";\n  color: ", ";\n  cursor: ", ";;\n  font-family: ", ";\n  font-size: ", ";\n  text-align: center;\n\n  opacity: ", ";\n\n  a {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  svg {\n    bottom: 3px;\n    position: relative;\n    fill: ", ";\n    margin-left: 5px;\n  }\n\n  :focus {\n    outline: 0;\n  }\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n"])), function (props) { return (props.fullWidth ? '100%' : 'max-content'); }, color.green, color.green, space.s12, space.s24, color.white, function (props) { return (props.disabled ? 'inherit' : 'pointer'); }, fontFamily.condensed, fontSize.fs16, function (props) { return (props.disabled ? 0.5 : 1); }, color.white, color.white, function (props) {
    return props.buttonStyle === 'secondary' && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background: white;\n      color: ", ";\n\n      a {\n        color: ", ";\n      }\n\n      svg {\n        fill: ", ";\n      }\n    "], ["\n      background: white;\n      color: ", ";\n\n      a {\n        color: ", ";\n      }\n\n      svg {\n        fill: ", ";\n      }\n    "])), color.green, color.green, color.green);
}, function (props) {
    return props.buttonStyle === 'warning secondary' && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: transparent;\n      border: solid 1.5px rgba(217, 48, 37, 0.4);\n      color: #d93025;\n\n      a {\n        color: #d93025;\n      }\n\n      svg {\n        fill: #d93025;\n      }\n    "], ["\n      background-color: transparent;\n      border: solid 1.5px rgba(217, 48, 37, 0.4);\n      color: #d93025;\n\n      a {\n        color: #d93025;\n      }\n\n      svg {\n        fill: #d93025;\n      }\n    "])));
}, function (props) {
    return props.buttonStyle === 'clear' && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: transparent;\n      border: solid 0;\n      color: #898989;\n\n      a {\n        color: #898989;\n      }\n\n      svg {\n        fill: #898989;\n      }\n    "], ["\n      background-color: transparent;\n      border: solid 0;\n      color: #898989;\n\n      a {\n        color: #898989;\n      }\n\n      svg {\n        fill: #898989;\n      }\n    "])));
}, function (props) {
    return props.buttonStyle === 'warning-clear' && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background-color: white;\n      border: solid 0;\n      color: #d93025;\n      border: solid 1px #d93025;\n\n      a {\n        color: #d93025;\n      }\n\n      svg {\n        fill: #d93025;\n      }\n    "], ["\n      background-color: white;\n      border: solid 0;\n      color: #d93025;\n      border: solid 1px #d93025;\n\n      a {\n        color: #d93025;\n      }\n\n      svg {\n        fill: #d93025;\n      }\n    "])));
});
var IconWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 16px;\n"], ["\n  height: 16px;\n"])));
var LeftIconWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 8px;\n  margin-right: 6px;\n"], ["\n  margin-top: 8px;\n  margin-right: 6px;\n"])));
var Button = function (_a) {
    var buttonStyle = _a.buttonStyle, children = _a.children, onClick = _a.onClick, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.type, type = _d === void 0 ? 'button' : _d;
    return (React.createElement(Root, { onClick: onClick, buttonStyle: buttonStyle, fullWidth: fullWidth, disabled: disabled, type: type },
        leftIcon && React.createElement(LeftIconWrapper, null, leftIcon),
        children,
        rightIcon && React.createElement(IconWrapper, null, rightIcon)));
};
export default Button;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
