var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getSingleButtonContent } from 'components/organisms/Form';
import Title from 'components/atoms/Title';
import Button from 'components/atoms/Button';
import HideOn from 'components/atoms/HideOn';
import ListRow from 'components/molecules/ListRow';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 40px;\n"], ["\n  margin-bottom: 40px;\n"])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #202020;\n"], ["\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #202020;\n"])));
var FullWidthRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  box-sizing: border-box;\n\n  border-bottom: ", ";\n  padding-bottom: ", ";\n  background-color: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  box-sizing: border-box;\n\n  border-bottom: ", ";\n  padding-bottom: ", ";\n  background-color: ", ";\n"])), function (props) { return (props.tableHeader ? 'solid 1px #898989' : 'solid 1px rgba(137, 137, 137, 0.2)'); }, function (props) { return (props.tableHeader ? '20px' : '0'); }, function (props) { return (props.newCar ? 'rgba(246, 246, 246, 0.5)' : 'white'); });
var LeftWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 70%;\n"], ["\n  width: 70%;\n"])));
var RightWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 30%;\n  margin: auto 0;\n  display: flex;\n  justify-content: space-between;\n  padding-right: 10px;\n  align-items: center;\n\n  button {\n    margin-left: auto;\n  }\n"], ["\n  width: 30%;\n  margin: auto 0;\n  display: flex;\n  justify-content: space-between;\n  padding-right: 10px;\n  align-items: center;\n\n  button {\n    margin-left: auto;\n  }\n"])));
var TableRow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: repeat(4, minmax(0, 1fr));\n"], ["\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: repeat(4, minmax(0, 1fr));\n"])));
var TableHeader = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n"], ["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n"])));
var Cell = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 80px;\n  display: flex;\n  align-items: center;\n  font-weight: ", ";\n  font-stretch: normal;\n  font-style: ", ";\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n  overflow-wrap: anywhere;\n\n  color: ", ";\n"], ["\n  height: 80px;\n  display: flex;\n  align-items: center;\n  font-weight: ", ";\n  font-stretch: normal;\n  font-style: ", ";\n  line-height: normal;\n  letter-spacing: 0.5px;\n  padding-left: 10px;\n  overflow-wrap: anywhere;\n\n  color: ",
    ";\n"])), function (props) { return (props.status ? '400' : '300'); }, function (props) { return (props.status ? 'italic' : 'normal'); }, function (props) {
    if (props.status === 'currentCar')
        return '#025c8d';
    if (props.status === 'newCar')
        return '#007f49';
    else
        return '#202020';
});
var MobileSpace = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
var YourCar = function (_a) {
    var yourCarGroup = _a.yourCarGroup, currentVehicleData = _a.currentVehicleData, newVehicleData = _a.newVehicleData;
    var history = useHistory();
    var headers = yourCarGroup.list[0].listItems;
    var amendCarBtn = getSingleButtonContent('amendCarBtn', yourCarGroup.buttons);
    var make = currentVehicleData.make, model = currentVehicleData.model, marketValue = currentVehicleData.marketValue, displayName = currentVehicleData.displayName;
    var currentVehicle = {
        make: make,
        model: model,
        marketValue: "\u00A3" + marketValue.amount,
        displayName: displayName.substring(displayName.lastIndexOf('(') + 1, displayName.lastIndexOf(')')),
    };
    var newVehicle = undefined;
    if (newVehicleData)
        newVehicle = {
            make: newVehicleData.make,
            model: newVehicleData.model,
            marketValue: "\u00A3" + newVehicleData.marketValue.amount,
            displayName: newVehicleData.registrationNumber,
        };
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null,
            React.createElement(Title, null, yourCarGroup.title)),
        React.createElement(HideOn, { smallOnly: true },
            React.createElement(FullWidthRow, { tableHeader: true },
                React.createElement(LeftWrapper, null,
                    React.createElement(TableRow, null, headers.map(function (item) { return (React.createElement(TableHeader, { key: item.label }, item.label)); }))),
                React.createElement(RightWrapper, null,
                    React.createElement(Button, { onClick: function () {
                            history.push('/change-vehicle');
                            TagManagerArgs('Button', "" + window.location.pathname, amendCarBtn.text);
                        }, buttonStyle: amendCarBtn.style }, amendCarBtn.text))),
            currentVehicle && (React.createElement(FullWidthRow, null,
                React.createElement(LeftWrapper, null,
                    React.createElement(TableRow, null, headers.map(function (cell) { return (React.createElement(Cell, { key: cell.value }, currentVehicle[cell.value])); }))),
                React.createElement(RightWrapper, null,
                    React.createElement(Cell, { status: "currentCar" }, "Current car")))),
            newVehicle && (React.createElement(FullWidthRow, { newCar: true },
                React.createElement(LeftWrapper, null,
                    React.createElement(TableRow, null, headers.map(function (cell) { return (React.createElement(Cell, { key: cell.value }, newVehicle[cell.value])); }))),
                React.createElement(RightWrapper, null,
                    React.createElement(Cell, { status: "newCar" }, "New details"))))),
        React.createElement(HideOn, { medium: true },
            React.createElement(MobileSpace, null, headers.map(function (item, index) { return (React.createElement(ListRow, { key: item.label + " " + index, label: item.label, value: currentVehicle[item.value], style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' } })); })),
            !newVehicleData && (React.createElement(Button, { fullWidth: true, buttonStyle: amendCarBtn.style }, amendCarBtn.text)),
            newVehicle && (React.createElement(React.Fragment, null,
                React.createElement(TitleWrapper, null,
                    React.createElement(Title, null, "New car details")),
                React.createElement(MobileSpace, null, headers.map(function (item, index) { return (React.createElement(ListRow, { key: item.label + " " + index, label: item.label, value: newVehicle[item.value], style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' } })); })),
                React.createElement(Button, { fullWidth: true, buttonStyle: amendCarBtn.style }, amendCarBtn.text))))));
};
export default YourCar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
