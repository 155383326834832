var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Drawer from '@material-ui/core/Drawer';
import React, { useState } from 'react';
import styled from 'styled-components';
import HideOn from 'components/atoms/HideOn';
import Layout from 'components/organisms/Layout';
import Topbar from './Topbar';
import MenuView from './MenuView';
import NavigationBar from './NavigationBar';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PageHeader = function (_a) {
    var toHome = _a.toHome, onAccountClick = _a.onAccountClick, onLogout = _a.onLogout, onTabClick = _a.onTabClick, uri = _a.uri, content = _a.content, toAccountSettings = _a.toAccountSettings, isLapsed = _a.isLapsed;
    var _b = useState(false), openMenu = _b[0], setMenuOpen = _b[1];
    var accountText = content.accountText, logoutText = content.logoutText, tabs = content.tabs;
    return (React.createElement(Layout, null,
        React.createElement(Root, null,
            React.createElement(Topbar, { accountText: accountText, logoutText: logoutText, toHome: toHome, onAccountClick: onAccountClick, onLogout: onLogout, onMenuClick: function () { return setMenuOpen(!openMenu); }, isLapsed: isLapsed }),
            React.createElement(HideOn, { smallOnly: true },
                React.createElement(NavigationBar, { uri: uri, tabs: tabs, onTabClick: onTabClick, isLapsed: isLapsed })),
            React.createElement(HideOn, { medium: true },
                React.createElement(Drawer, { anchor: "top", open: openMenu, onBackdropClick: function () { return setMenuOpen(false); } },
                    React.createElement(MenuView, { logoutText: logoutText, uri: uri, tabs: tabs, onClose: function () { return setMenuOpen(false); }, toHome: toHome, onLogout: onLogout, onTabClick: onTabClick, toAccountSettings: toAccountSettings, accountText: accountText, isLapsed: isLapsed }))))));
};
export default PageHeader;
var templateObject_1;
