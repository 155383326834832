var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { fontWeight, media } from 'styles';
export var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: rgba(0, 127, 73, 0.05);\n"], ["\n  background: rgba(0, 127, 73, 0.05);\n"])));
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n\n  ", "\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n\n  ",
    "\n"])), fontWeight.medium, media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 25px;\n  "], ["\n    font-size: 25px;\n  "]))));
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  line-height: 1.5;\n  margin-top: 16px;\n"], ["\n  line-height: 1.5;\n  margin-top: 16px;\n"])));
var ContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
var Status = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #007f49;\n  margin-left: 50px;\n\n  strong {\n    font-weight: 400;\n    color: #202020;\n  }\n"], ["\n  color: #007f49;\n  margin-left: 50px;\n\n  strong {\n    font-weight: 400;\n    color: #202020;\n  }\n"])));
var RenewalBanner = function (_a) {
    var title = _a.title, text = _a.text, statusText = _a.statusText, totalPrice = _a.totalPrice, policyMiles = _a.policyMiles;
    var replacePlacholderText = function (value) {
        var placeholders = {
            POLICY_MILES: policyMiles,
            TOTAL_COST: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.toFixed(2),
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/POLICY_MILES|TOTAL_COST/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    return (React.createElement(Layout, null,
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(ContentWrapper, null,
                        React.createElement("div", null,
                            React.createElement(TitleWrapper, null, replacePlacholderText(title)),
                            React.createElement(TextWrapper, null,
                                React.createElement(ReactMarkdown, { source: text }))),
                        React.createElement(Status, null,
                            React.createElement("strong", null, "Status:"),
                            " ",
                            statusText)))))));
};
export default RenewalBanner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
