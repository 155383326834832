import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResetPassword, LoginPageLayout } from 'components/organisms/Login';
import HelpSection from 'components/organisms/HelpSection';
import { getPageContent } from 'services';
import { ContentPageURL } from 'types';
import { changePassword } from 'services/api';
var ResetPasswordPage = function () {
    var _a = useState(undefined), contentSections = _a[0], setContentSections = _a[1];
    var history = useHistory();
    var params = new URLSearchParams(window.location.search);
    useEffect(function () {
        getPageContent(ContentPageURL.LOGIN_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200) {
                setContentSections(payload === null || payload === void 0 ? void 0 : payload.contentSections);
            }
            else {
                console.log('Error fetching login page content | status code ', status);
            }
        });
    }, []);
    var resetPassword = function (password) {
        var token = params.get('token') || '';
        changePassword(token, password);
    };
    var renderSection = function (section) {
        if ('signInText' in section) {
            return (React.createElement(ResetPassword, { key: "resetpassword", content: section, backToSignIn: function () { return history.push('/login'); }, onResetPassword: resetPassword }));
        }
        else {
            return React.createElement(HelpSection, { key: "helpSectionContent", content: section });
        }
    };
    return contentSections ? (React.createElement(LoginPageLayout, null, contentSections.map(function (section) { return renderSection(section); }))) : null;
};
export default ResetPasswordPage;
