var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px 0;\n  display: flex;\n  justify-content: center;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  padding: 40px 0;\n  display: flex;\n  justify-content: center;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var ButtonGroup = function (_a) {
    var printBtnText = _a.printBtnText, saveBtnText = _a.saveBtnText, continueBtnText = _a.continueBtnText, onPrint = _a.onPrint, onSave = _a.onSave, onContinue = _a.onContinue, onGoBack = _a.onGoBack;
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(Root, null,
                    React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                            onGoBack();
                            TagManagerArgs('Button', "" + window.location.pathname, 'Go Back');
                        } }, "Go back"),
                    React.createElement(Button, { onClick: function () {
                            onSave();
                            TagManagerArgs('Button', "" + window.location.pathname, saveBtnText);
                        }, buttonStyle: "secondary" }, saveBtnText),
                    React.createElement(Button, { onClick: function () {
                            onContinue();
                            TagManagerArgs('Button', "" + window.location.pathname, continueBtnText);
                        } }, continueBtnText))))));
};
export default ButtonGroup;
var templateObject_1;
