import moment from 'moment';
import { formatDateOfBirth } from 'utils';
import { getClassOfUseDisplay, getLicenseDisplay, getMaritalDisplay, getOccupationDisplay, getSecurityDeviceDisplay, } from 'guidewirePolicyUtils';
import { numberOfDrivers } from 'pages/RenewalOne/utils';
export var getCoverDetails = function (policy) {
    var addons = [
        {
            value: ' ',
            key: 'PMKeyProtect',
        },
        {
            value: ' ',
            key: 'rescue',
        },
        {
            value: ' ',
            key: 'coverPlus',
        },
    ];
    return {
        details: [
            {
                value: policy.status,
                key: 'status',
            },
            {
                value: moment(new Date(policy.effective)).format('DD / MM / yyyy'),
                key: 'start',
            },
            {
                value: moment(new Date(policy.expiration)).format('DD / MM / yyyy'),
                key: 'end',
            },
            {
                value: policy.coverType,
                key: 'cover',
            },
            {
                value: policy.ncdYears + ' years',
                key: 'discount',
            },
            {
                value: getClassOfUseDisplay(policy.vehicleInfo.classOfUse),
                key: 'carUsage',
            },
        ],
        addons: addons,
    };
};
export var getRenewalCoverDetails = function (policy) { return ({
    details: [
        {
            value: policy.policyNumber,
            key: 'policyNumber',
        },
        {
            value: policy.coverType,
            key: 'cover',
        },
        {
            value: policy.productMiles + ' miles',
            key: 'product',
        },
        {
            value: policy.ncdYears + ' years',
            key: 'discount',
        },
        {
            value: '£24.50',
            key: '£24.50',
        },
        {
            value: '£31.50',
            key: '£31.50',
        },
        {
            value: '£42.50',
            key: '£42.50',
        },
        {
            value: numberOfDrivers('textNumberDisplay', policy.drivers.length - 1, 0),
            key: 'otherDrivers',
        },
        {
            value: moment(new Date(policy.effective)).format('DD / MM / yyyy'),
            key: 'start',
        },
        {
            value: moment(new Date(policy.expiration)).format('DD / MM / yyyy'),
            key: 'end',
        },
        {
            value: '£' + policy.myExcessPayment.damageExcesses[0].totalExcess,
            key: 'excess',
        },
        {
            value: policy.myExcessPayment.glassReplacementExcess,
            key: 'windscreenReplacement',
        },
        {
            value: policy.myExcessPayment.glassRepairExcess,
            key: 'windscreenRepair',
        },
        {
            value: getClassOfUseDisplay(policy.vehicleInfo.classOfUse),
            key: 'carUsage',
        },
    ],
}); };
export var getVehicleDetails = function (policy, isRenewal) {
    var vehicleInfo = policy.vehicleInfo;
    var displayName = vehicleInfo.displayName, fuelType = vehicleInfo.fuelType, firstRegisteredYear = vehicleInfo.firstRegisteredYear, marketValue = vehicleInfo.marketValue, annualMileage = vehicleInfo.annualMileage, securityDevices = vehicleInfo.securityDevices, securityTracker = vehicleInfo.securityTracker, hasBeenModified = vehicleInfo.hasBeenModified, rightHandDrive = vehicleInfo.rightHandDrive, lessThanEightSeats = vehicleInfo.lessThanEightSeats, imported = vehicleInfo.imported, registeredKeeper = vehicleInfo.registeredKeeper;
    return {
        details: [
            {
                value: displayName || '',
                key: 'registration',
            },
            {
                value: fuelType,
                key: 'fuel',
            },
            {
                value: policy.vehicleInfo.purchasedDate,
                key: 'purchase',
            },
            {
                value: firstRegisteredYear,
                key: 'yearOfRegistration',
            },
            {
                value: isRenewal ? '' : "\u00A3" + marketValue.amount.toFixed(2),
                key: isRenewal ? '' : 'marketValue',
            },
            {
                value: isRenewal ? '' : annualMileage || '',
                key: isRenewal ? '' : 'annualMileage',
            },
            {
                value: getSecurityDeviceDisplay(securityDevices),
                key: 'devices',
            },
            {
                value: securityTracker ? 'Yes' : 'No',
                key: 'tracker',
            },
            {
                value: hasBeenModified ? 'Yes' : 'No',
                key: 'modified',
            },
            {
                value: rightHandDrive ? 'Yes' : 'No',
                key: 'rightHandDrive',
            },
            {
                value: lessThanEightSeats ? 'Yes' : 'No',
                key: 'lessThan8Seats',
            },
            {
                value: imported ? 'Yes' : 'No',
                key: 'carImported',
            },
            {
                value: registeredKeeper ? 'Yes' : 'No',
                key: 'keeperOwner',
            },
        ],
    };
};
export var getExcessData = function (policy) {
    var myExcessPayment = policy.myExcessPayment;
    var damageExcesses = myExcessPayment.damageExcesses, glassRepairExcess = myExcessPayment.glassRepairExcess, glassReplacementExcess = myExcessPayment.glassReplacementExcess;
    return {
        excesses: damageExcesses,
        otherExcesses: [
            {
                name: 'Windscreen replacement',
                amount: glassReplacementExcess,
            },
            {
                name: 'Windscreen repair',
                amount: glassRepairExcess,
            },
        ],
    };
};
export var getDriversData = function (policy) {
    var drivers = policy.drivers;
    var driverProfiles = drivers.map(function (driver) { return ({
        mainData: {
            firstName: driver.firstName || '',
            surname: driver.lastName,
            dateOfBirth: formatDateOfBirth(driver.dateOfBirth, 'DD / MM / yyyy'),
        },
        detailsData: {
            occupation: getOccupationDisplay(driver.occupation).label,
            maritalStatus: getMaritalDisplay(driver.maritalStatus),
            ukResidency: driver.ukResidency,
            licenceType: getLicenseDisplay(driver.licenseType),
            accessOtherVehicles: driver.accessToOtherVehicles ? 'Yes' : 'No',
            additionalQualifications: driver.drivingQualifications,
            medicalConditions: driver.medicalConditions,
            unspentOffences: driver.nonMotoringConvictions ? 'Yes' : 'No',
            cancelledPolicy: driver.cancelledPreviousInsurance ? 'Yes' : 'No',
            convictions: driver.hasMotorConvictions ? 'Yes' : 'No',
            claims: driver.hasMotorClaims ? 'Yes' : 'No',
            mainDriver: driver.mainDriver,
        },
    }); });
    return {
        mainDriverData: driverProfiles.find(function (profile) { return profile.detailsData.mainDriver; }) || driverProfiles[0],
        otherDriversData: driverProfiles,
    };
};
export var getOvernightParkingData = function (policy) {
    var overnightParkingAddress = policy.overnightParkingAddress;
    var addressLine1 = overnightParkingAddress.addressLine1, city = overnightParkingAddress.city, postalCode = overnightParkingAddress.postalCode;
    var data = [
        {
            value: addressLine1,
            key: 'address',
        },
        {
            value: city,
            key: 'city',
        },
        {
            value: postalCode,
            key: 'postcode',
        },
    ];
    return data;
};
