var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { filter, find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { ContentPageURL } from 'types';
import { getPageContent } from 'services';
import { getPaymentSectionContent, getInformationUsageSectionContent, getMyCoverSectionContent, } from '../QuoteSummaryPage/contentMapper';
import { TotalPriceBanner, PaymentSection, InformationUsageSection, } from 'components/organisms/QuoteSummary';
import ButtonGroup from 'components/organisms/QuoteSummary/ButtonGroup';
import MyCoverSection from 'components/organisms/QuoteSummary/MyCoverSection';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
var QuoteSummaryDirectPage = function (_a) {
    var quoteDetails = _a.quoteDetails, renewalPolicy = _a.renewalPolicy, setRenewalState = _a.setRenewalState, renewalState = _a.renewalState;
    var history = useHistory();
    var _b = useState(undefined), content = _b[0], setContent = _b[1];
    var _c = useState(''), priceBannerAnnual = _c[0], setPriceBannerAnnual = _c[1];
    var _d = useState(''), priceBannerMonthly = _d[0], setPriceBannerMonthly = _d[1];
    var _e = useState(undefined), excessesForYourCover = _e[0], setExcessesForYourCover = _e[1];
    var _f = useState(undefined), billingID = _f[0], setBillingID = _f[1];
    // annual and monthly plans grouped by mileage (6000, 8000, 10000)
    var _g = useState(undefined), mileagePlans = _g[0], setMileagePlans = _g[1];
    // annual/monthly plans based on the chosen mileage
    var _h = useState(undefined), selectedPlansByMileage = _h[0], setSelectedPlansByMileage = _h[1];
    // The actual annual or yearly plan the user has chosen (ie - 6000 miles, annual payment / 10000 miles, monthly payment etc)
    var _j = useState(undefined), selectedPlan = _j[0], setSelectedPlan = _j[1];
    // Addons the use has on this policy, if they want to edit they will then go through the amend journey
    var _k = useState([]), selectedAddons = _k[0], setSelectedAddons = _k[1];
    var _l = useState([]), selectedRoadRescue = _l[0], setSelectedRoadRescue = _l[1];
    var getExcessCostsForYourCover = function () {
        var displayName = quoteDetails.accountHolder.displayName;
        var _a = quoteDetails.myExcessPayment, glassRepairExcess = _a.glassRepairExcess, glassReplacementExcess = _a.glassReplacementExcess, damageExcesses = _a.damageExcesses;
        var excess = '';
        damageExcesses.map(function (item) {
            if (item.driver === displayName)
                excess = item.compulsoryExcess.toString();
        });
        return setExcessesForYourCover({
            glassRepairExcess: glassRepairExcess,
            glassReplacementExcess: glassReplacementExcess,
            excess: excess,
        });
    };
    var getNumberOfOtherDrivers = function () {
        // minus 1 because this is the total drivers not including the policy holder
        var total = renewalPolicy.drivers.length - 1;
        if (total === 0)
            return 'None';
        return total.toString();
    };
    var groupPlansByMileage = function () {
        var mileageIds = quoteDetails.quoteData.offeredQuotes.map(function (plan) {
            return {
                miles: plan.branchCode,
                publicID: plan.publicID,
            };
        });
        var plans = quoteDetails.bindData.paymentPlans;
        var groupPlansByMiles = mileageIds.map(function (item) {
            var offers = filter(plans, function (obj) {
                return obj.periodID === item.publicID;
            });
            return {
                id: item.publicID,
                miles: item.miles,
                plans: offers,
            };
        });
        setMileagePlans(groupPlansByMiles);
    };
    var onPaymentSelect = function (paymentOption) {
        var plan = find(selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.plans, function (obj) {
            return obj.billingId === paymentOption;
        });
        setSelectedPlan(plan);
        setBillingID(paymentOption);
    };
    var getAddOnPrices = function () {
        var addOns = quoteDetails.includedAddOns.filter(function (item) { return item.codeIdentifier !== 'PMRoadRescueCov'; });
        var costs = addOns.map(function (item) {
            var codeIdentifier = item.codeIdentifier, description = item.description, amount = item.amount;
            var monthlyBreakdown = content === null || content === void 0 ? void 0 : content.chargeText.replace('${chargeMonthly}', amount !== undefined ? (amount.amount / 12).toFixed(2).toString() : '0');
            var price = "\u00A3" + (amount === null || amount === void 0 ? void 0 : amount.amount.toFixed(2)) || '£0';
            return {
                codeIdentifier: codeIdentifier,
                description: description,
                price: price,
                monthlyBreakdown: monthlyBreakdown,
            };
        });
        return costs;
    };
    var getRoadRescuePrices = function () {
        var addOns = quoteDetails.includedAddOns.filter(function (item) { return item.codeIdentifier === 'PMRoadRescueCov'; });
        if (addOns.length > 0) {
            var costs = addOns[0].terms[0].options;
            var levelOne_1 = find(costs, function (obj) {
                return obj.name === 'Level 1';
            });
            var levelTwo_1 = find(costs, function (obj) {
                return obj.name === 'Level 2';
            });
            var levelThree_1 = find(costs, function (obj) {
                return obj.name === 'Level 3';
            });
            var replacePlacholderText_1 = function (value) {
                var placeholders = {
                    LEVEL_ONE: levelOne_1 === null || levelOne_1 === void 0 ? void 0 : levelOne_1.amount.amount.toFixed(2),
                    LEVEL_TWO: levelTwo_1 === null || levelTwo_1 === void 0 ? void 0 : levelTwo_1.amount.amount.toFixed(2),
                    LEVEL_THREE: levelThree_1 === null || levelThree_1 === void 0 ? void 0 : levelThree_1.amount.amount.toFixed(2),
                };
                var changedValue = '';
                if (value !== undefined) {
                    changedValue = value.replace(/LEVEL_ONE|LEVEL_TWO|LEVEL_THREE/gi, function (matched) {
                        return placeholders[matched];
                    });
                }
                return changedValue;
            };
            var roadRescueCosts = content === null || content === void 0 ? void 0 : content.coverOptions.map(function (item) {
                var value = __assign(__assign({}, item), { priceText: replacePlacholderText_1(item.priceText) });
                return value;
            });
            return roadRescueCosts;
        }
        // If the object is missing from the array, return cms content but leave out price
        return content === null || content === void 0 ? void 0 : content.coverOptions.map(function (item) {
            var value = __assign(__assign({}, item), { priceText: '' });
            return value;
        });
    };
    var setAddOns = function () {
        var coverContent = content ? getMyCoverSectionContent(content) : undefined;
        var addons = coverContent === null || coverContent === void 0 ? void 0 : coverContent.addons;
        var includedAddOns = quoteDetails.includedAddOns;
        var roadRescue = find(includedAddOns, function (obj) {
            return obj.codeIdentifier === 'PMRoadRescueCov';
        });
        var ids = [];
        addons === null || addons === void 0 ? void 0 : addons.map(function (item) {
            if (item.title.includes('Key') || item.title.includes('Courtesy')) {
                includedAddOns.map(function (offer) {
                    if (offer.name === item.title && offer.selected) {
                        ids = __spreadArrays(ids, [item.title]);
                    }
                });
            }
        });
        var roadRescueLevel = undefined;
        if ((roadRescue === null || roadRescue === void 0 ? void 0 : roadRescue.selected) === true) {
            roadRescueLevel = roadRescue.terms[0].chosenTerm;
        }
        setSelectedAddons(ids);
        if (roadRescueLevel === 'Level1')
            setSelectedRoadRescue(['National Cover']);
        if (roadRescueLevel === 'Level2')
            setSelectedRoadRescue(['National Cover Plus Doorstep']);
        if (roadRescueLevel === 'Level3')
            setSelectedRoadRescue(['National Cover including Doorstep in Europe']);
    };
    var getTotalPriceBannerText = function () {
        if (billingID === 'bc:5')
            return priceBannerAnnual;
        if (billingID === 'bc:7')
            return priceBannerMonthly;
        return '';
    };
    var getTotalPriceBannerPrice = function () {
        if (billingID === 'bc:5')
            return selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.total.amount.toFixed(2);
        if (billingID === 'bc:7')
            return selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.installment.amount.toFixed(2);
        return 0;
    };
    useEffect(function () {
        getPageContent(ContentPageURL.QUOTESUMMARY_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200 && payload) {
                payload.contentSections.map(function (item) {
                    if (!item.sectionId)
                        setContent(item);
                    if (item.sectionId === 'priceBannerAnnual')
                        setPriceBannerAnnual(item.body);
                    if (item.sectionId === 'priceBannerMonthly')
                        setPriceBannerMonthly(item.body);
                });
            }
            // seperate the plan offerings, grouped by mileage
            if (mileagePlans === undefined) {
                groupPlansByMileage();
            }
        });
        if (selectedPlansByMileage === undefined && mileagePlans !== undefined) {
            var initialPlanBillingID = quoteDetails.bindData.selectedPaymentPlan.billingId;
            var initialPlanPeriodID_1 = quoteDetails.bindData.selectedPaymentPlan.periodID;
            var plans = find(mileagePlans, function (obj) {
                return obj.id === initialPlanPeriodID_1;
            });
            setSelectedPlansByMileage(plans);
            setBillingID(initialPlanBillingID);
        }
        if (selectedPlansByMileage && billingID && selectedPlan === undefined) {
            onPaymentSelect(billingID);
        }
        if (excessesForYourCover === undefined) {
            getExcessCostsForYourCover();
        }
        if (content) {
            setAddOns();
        }
    }, [mileagePlans, billingID, selectedPlansByMileage, excessesForYourCover]);
    return (React.createElement("div", null, content && (React.createElement(React.Fragment, null,
        React.createElement(RenewalJourneyProgress, { step: 3 }),
        selectedPlan && billingID && (React.createElement(TotalPriceBanner, { title: getTotalPriceBannerText(), price: getTotalPriceBannerPrice() })),
        billingID && selectedPlansByMileage && (React.createElement(PaymentSection, { content: getPaymentSectionContent(content), onPaymentSelected: function (paymentOption) { return onPaymentSelect(paymentOption); }, billingID: billingID, selectedPlans: selectedPlansByMileage })),
        excessesForYourCover && renewalPolicy && quoteDetails && (React.createElement(MyCoverSection, { content: content, policyNumber: renewalPolicy.policyNumber, coverType: renewalPolicy.coverType, product: renewalPolicy.productMiles + " miles", ncd: renewalPolicy.ncdYears + " years", keyCover: 'Included as standard', otherDrivers: getNumberOfOtherDrivers(), excess: "\u00A3" + excessesForYourCover.excess, windscreenReplacement: excessesForYourCover.glassRepairExcess, windscreenRepair: excessesForYourCover.glassReplacementExcess, selectedAddons: selectedAddons, selectedCoverOptions: selectedRoadRescue, ncdYears: renewalPolicy.ncdYears, addOnPrices: getAddOnPrices(), roadRescuePrices: getRoadRescuePrices(), periodStartDate: quoteDetails.baseData.periodStartDate, showBreakdown: quoteDetails.includedAddOns.find(function (item) { return item.codeIdentifier === 'PMRoadRescueCov'; }) === undefined
                ? false
                : true })),
        React.createElement(InformationUsageSection, { content: getInformationUsageSectionContent(content) }),
        React.createElement(ButtonGroup, { printBtnText: content.printQuoteButtonText, saveBtnText: content.saveQuoteButtonText, continueBtnText: content.continueButtonText, onPrint: function () { return console.log('onPrint'); }, onSave: function () {
                history.push('/dashboard');
                location.reload();
            }, onGoBack: function () { return history.goBack(); }, onContinue: function () {
                setRenewalState(__assign(__assign({}, renewalState), { paymentPlan: {
                        id: selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id,
                        billingID: billingID,
                        total: selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.total.amount,
                        miles: selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.miles,
                        selectedPlan: selectedPlan,
                    } }));
                history.push('/renewal-payment-summary');
            } })))));
};
var mapStateToProps = function (state) { return ({
    quoteDetails: state.renewalAmendedQuote,
    renewalPolicy: state.renewalPolicy,
    renewalState: state.renewalState,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRenewalState: function (formState) { return dispatch(actions.setAmendRenewalState(formState)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(QuoteSummaryDirectPage);
