var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getUserInfo, mtaTokenRefresh, updateContactNumbers, updateCorrespondenceAddress, updateDocsPreference, updateEmailAddress, updatePassword, } from 'services';
import { SessionStorageKey } from 'types';
export var handleUpdateContactDetails = function (values, accountInfoDisplay, history) { return __awaiter(void 0, void 0, void 0, function () {
    var email, contactNumbers, token, updateNumbers, refresh, data, updateEmail, refresh;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                email = values.emailAddress;
                contactNumbers = {
                    homePhone: values.contacts.homePhone !== '' ? values.contacts.homePhone : accountInfoDisplay === null || accountInfoDisplay === void 0 ? void 0 : accountInfoDisplay.homePhone,
                    mobilePhone: values.contacts.mobilePhone !== '' ? values.contacts.mobilePhone : accountInfoDisplay === null || accountInfoDisplay === void 0 ? void 0 : accountInfoDisplay.mobilePhone,
                };
                token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                if (!(Object.keys(contactNumbers).length > 0)) return [3 /*break*/, 3];
                return [4 /*yield*/, updateContactNumbers(token, contactNumbers)];
            case 1:
                updateNumbers = _a.sent();
                if (updateNumbers.status === 200) {
                    if (email === '')
                        history.go(0);
                }
                if ((updateNumbers.status !== undefined && updateNumbers.status === 500) ||
                    (updateNumbers.data.status !== undefined && updateNumbers.data.status === 500)) {
                    history.push('/error');
                }
                if (!(updateNumbers.data.status === 401)) return [3 /*break*/, 3];
                return [4 /*yield*/, mtaTokenRefresh()];
            case 2:
                refresh = _a.sent();
                if (refresh === 200)
                    handleUpdateContactDetails(values, accountInfoDisplay, history);
                else {
                    history.push('/login');
                }
                _a.label = 3;
            case 3:
                if (!(email !== '')) return [3 /*break*/, 6];
                data = {
                    brand: 'itb',
                    email: email,
                };
                return [4 /*yield*/, updateEmailAddress(token, data)];
            case 4:
                updateEmail = _a.sent();
                if (updateEmail.status === 200) {
                    return [2 /*return*/, 200];
                }
                if ((updateEmail.status !== undefined && updateEmail.status === 500) ||
                    (updateEmail.data.status !== undefined && updateEmail.data.status === 500)) {
                    return [2 /*return*/, history.push('/error')];
                }
                if (!(updateEmail.data.status === 401)) return [3 /*break*/, 6];
                return [4 /*yield*/, mtaTokenRefresh()];
            case 5:
                refresh = _a.sent();
                if (refresh === 200)
                    handleUpdateContactDetails(values, accountInfoDisplay, history);
                else {
                    history.push('/login');
                }
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); };
export var handleUpdateCorrespondenceAddress = function (address, history) { return __awaiter(void 0, void 0, void 0, function () {
    var token, addressChange, refresh;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                return [4 /*yield*/, updateCorrespondenceAddress(token, address)];
            case 1:
                addressChange = _a.sent();
                if (addressChange.status === 200) {
                    history.go(0);
                }
                if ((addressChange.status !== undefined && addressChange.status === 500) ||
                    (addressChange.data.status !== undefined && addressChange.data.status === 500)) {
                    history.push('/error');
                }
                if (!(addressChange.data.status === 401)) return [3 /*break*/, 3];
                return [4 /*yield*/, mtaTokenRefresh()];
            case 2:
                refresh = _a.sent();
                if (refresh === 200)
                    handleUpdateCorrespondenceAddress(address, history);
                else {
                    history.push('/login');
                }
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var handleCommunicationPrefs = function (watchedFields, history) { return __awaiter(void 0, void 0, void 0, function () {
    var docPref, data, token, docs, refresh;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                docPref = '';
                if (watchedFields.documentationPreference === 'Email')
                    docPref = 'Electronic';
                if (watchedFields.documentationPreference === 'Post')
                    docPref = 'Paper';
                data = { documentationPreference: docPref };
                token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                return [4 /*yield*/, updateDocsPreference(token, data)];
            case 1:
                docs = _a.sent();
                if (docs.status === 200) {
                    history.go(0);
                }
                if ((docs.status !== undefined && docs.status === 500) ||
                    (docs.data.status !== undefined && docs.data.status === 500)) {
                    history.push('/error');
                }
                if (!(docs.data.status === 401)) return [3 /*break*/, 3];
                return [4 /*yield*/, mtaTokenRefresh()];
            case 2:
                refresh = _a.sent();
                if (refresh === 200)
                    handleCommunicationPrefs(watchedFields, history);
                else {
                    history.push('/login');
                }
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var handleUpdatePassword = function (values, history) { return __awaiter(void 0, void 0, void 0, function () {
    var token, userInfo, userId, data, reset, refresh, refresh;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                return [4 /*yield*/, getUserInfo(token)];
            case 1:
                userInfo = _a.sent();
                if (!(userInfo.status === 200)) return [3 /*break*/, 4];
                userId = userInfo.data.user_id;
                data = __assign(__assign({}, values), { userId: userId });
                return [4 /*yield*/, updatePassword(token, data)];
            case 2:
                reset = _a.sent();
                if (reset.status === 200) {
                    return [2 /*return*/, 200];
                }
                if ((reset.status !== undefined && reset.status === 500) ||
                    (reset.data.status !== undefined && reset.data.status === 500)) {
                    return [2 /*return*/, history.push('/error')];
                }
                if (!(reset.data.status === 401)) return [3 /*break*/, 4];
                return [4 /*yield*/, mtaTokenRefresh()];
            case 3:
                refresh = _a.sent();
                if (refresh === 200)
                    handleUpdatePassword(values, history);
                else {
                    history.push('/login');
                }
                _a.label = 4;
            case 4:
                if ((userInfo.status !== undefined && userInfo.status === 500) ||
                    (userInfo.data.status !== undefined && userInfo.data.status === 500)) {
                    history.push('/error');
                }
                if (!(userInfo.data.status === 401)) return [3 /*break*/, 6];
                return [4 /*yield*/, mtaTokenRefresh()];
            case 5:
                refresh = _a.sent();
                if (refresh === 200)
                    handleUpdatePassword(values, history);
                else {
                    history.push('/login');
                }
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); };
