var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup as MaterialRadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styled from 'styled-components';
import { color, fontFamily, fontSize, space } from 'styles';
var useStyles = makeStyles(function (theme) { return ({
    formControl: {
        height: '100%',
        margin: theme.spacing(0),
    },
}); });
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .MuiFormLabel-root {\n    margin-bottom: ", ";\n    color: ", ";\n    font-family: ", ";\n    font-size: ", ";\n  }\n\n  .MuiFormLabel-root.Mui-focused {\n    color: ", ";\n  }\n\n  .MuiFormControlLabel-root {\n    margin-left: 0;\n    margin-right: ", ";\n    padding: 5px ", " 5px ", ";\n    border: 1px solid rgba(137, 137, 137, 0.15);\n  }\n\n  .MuiRadio-root {\n    color: ", ";\n\n    :hover {\n      background-color: ", "33;\n    }\n  }\n\n  .MuiRadio-colorSecondary.Mui-checked {\n    color: ", ";\n\n    :hover {\n      background-color: ", "33;\n    }\n  }\n\n  .MuiTypography-body1 {\n    color: ", ";\n    font-size: ", ";\n  }\n\n  .MuiFormGroup-root {\n    flex-wrap: nowrap;\n  }\n"], ["\n  .MuiFormLabel-root {\n    margin-bottom: ", ";\n    color: ", ";\n    font-family: ", ";\n    font-size: ", ";\n  }\n\n  .MuiFormLabel-root.Mui-focused {\n    color: ", ";\n  }\n\n  .MuiFormControlLabel-root {\n    margin-left: 0;\n    margin-right: ", ";\n    padding: 5px ", " 5px ", ";\n    border: 1px solid rgba(137, 137, 137, 0.15);\n  }\n\n  .MuiRadio-root {\n    color: ", ";\n\n    :hover {\n      background-color: ", "33;\n    }\n  }\n\n  .MuiRadio-colorSecondary.Mui-checked {\n    color: ", ";\n\n    :hover {\n      background-color: ", "33;\n    }\n  }\n\n  .MuiTypography-body1 {\n    color: ", ";\n    font-size: ", ";\n  }\n\n  .MuiFormGroup-root {\n    flex-wrap: nowrap;\n  }\n"])), space.s10, color.black, fontFamily.base, fontSize.fs14, color.black, space.s6, space.s20, space.s10, color.greyMedium, color.green, color.green, color.green, color.greyMedium, fontSize.fs16);
var RadioGroup = function (_a) {
    var legend = _a.legend, name = _a.name, onSelect = _a.onSelect, defaultValue = _a.defaultValue, options = _a.options;
    var classes = useStyles();
    var handleChange = function (event) {
        onSelect(event.target.value);
    };
    return (React.createElement(Root, null,
        React.createElement(FormControl, { component: "fieldset", className: classes.formControl },
            React.createElement(FormLabel, { component: "legend" }, legend),
            React.createElement(MaterialRadioGroup, { name: name, onChange: function (event) { return handleChange(event); }, row: true }, options.map(function (option) { return (React.createElement(FormControlLabel, { checked: defaultValue === option, key: option, value: option, control: React.createElement(Radio, { size: "small" }), label: option })); })))));
};
export default RadioGroup;
var templateObject_1;
