import React from 'react';
var getHorizontalPath = function (x, y, width, height, radius) {
    return "M" + x + "," + y + "\n          h" + (width - radius) + "\n          a" + radius + ", " + radius + " 0 0 1 " + radius + ", " + radius + "\n          v" + (height - 2 * radius) + "\n          a" + radius + ", " + radius + " 0 0 1 -" + radius + ", " + radius + "\n          h" + (radius - width) + "\n          v" + -height + "\n          z";
};
var getVerticalPath = function (x, y, width, height, radius) {
    return "M" + x + "," + y + "\n          a" + radius + ", " + radius + " 0 0 1 " + radius + ", -" + radius + "\n          h" + (width - 2 * radius) + "\n          a" + radius + ", " + radius + " 0 0 1 " + radius + ", " + radius + "\n          v" + height + "\n          h" + -width + "\n          v" + -height + "\n          z";
};
var getRectanglePath = function (x, y, width, height) {
    return "M" + x + "," + y + "\n          h" + width + "\n          v" + height + "\n          h" + -width + "\n          v" + -height + "\n          z";
};
var getPath = function (x, y, width, height, radius, horizontal, rectangle) {
    if (rectangle) {
        return getRectanglePath(x, y, width, height);
    }
    if (horizontal) {
        return getHorizontalPath(x, y, width, height, radius);
    }
    else {
        return getVerticalPath(x, y, width, height, radius);
    }
};
var BarShape = function (_a) {
    var fill = _a.fill, x = _a.x, y = _a.y, width = _a.width, height = _a.height, radius = _a.radius, _b = _a.horizontal, horizontal = _b === void 0 ? false : _b, _c = _a.rectangle, rectangle = _c === void 0 ? false : _c;
    return (React.createElement("path", { d: getPath(x, y, width, height, radius, horizontal, rectangle), stroke: "none", fill: fill }));
};
export default BarShape;
