var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Layout from 'components/organisms/Layout';
import { fontWeight, media } from 'styles';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: 400px;\n  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(", ");\n  background-position: center;\n  background-repeat: none;\n  background-size: cover;\n  padding: 50px 10px;\n\n  ", "\n"], ["\n  display: flex;\n  height: 400px;\n  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(", ");\n  background-position: center;\n  background-repeat: none;\n  background-size: cover;\n  padding: 50px 10px;\n\n  ",
    "\n"])), function (props) { return props.imageUrl; }, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 392px;\n    padding: 50px 135px;\n  "], ["\n    height: 392px;\n    padding: 50px 135px;\n  "]))));
var ContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  align-self: flex-end;\n  background-color: rgba(255, 2555, 255, 0.8);\n  border-radius: 6px;\n  box-sizing: border-box;\n  padding: 30px 20px;\n\n  ", "\n"], ["\n  width: 100%;\n  align-self: flex-end;\n  background-color: rgba(255, 2555, 255, 0.8);\n  border-radius: 6px;\n  box-sizing: border-box;\n  padding: 30px 20px;\n\n  ",
    "\n"])), media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    align-self: center;\n    max-width: 400px;\n  "], ["\n    align-self: center;\n    max-width: 400px;\n  "]))));
var TitleWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 25px;\n  font-weight: ", ";\n"], ["\n  font-size: 25px;\n  font-weight: ", ";\n"])), fontWeight.medium);
var TextWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  line-height: 24px;\n  margin-top: 20px;\n"], ["\n  line-height: 24px;\n  margin-top: 20px;\n"])));
var BannerImageSection = function (_a) {
    var content = _a.content;
    return (React.createElement(Layout, null,
        React.createElement(Root, { imageUrl: content.imageUrl },
            React.createElement(ContentWrapper, null,
                React.createElement(TitleWrapper, null, content.title),
                React.createElement(TextWrapper, null, content.text)))));
};
export default BannerImageSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
