var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Icon from 'components/atoms/Icon';
import ArrowDownIcon from 'assets/arrow_down.svg';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  justify-content: space-between;\n  padding: 0 16px;\n\n  color: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  justify-content: space-between;\n  padding: 0 16px;\n\n  color: ",
    ";\n"])), function (props) {
    if (props.status === 'remove')
        return 'red';
    if (props.status === 'update')
        return 'green';
    return 'inherit';
});
var AnimateIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  transition: 0.3s;\n  transform: ", ";\n"], ["\n  color: ", ";\n  transition: 0.3s;\n  transform: ", ";\n"])), function (props) { return (props.spinArrow ? '#30A369' : '#898989'); }, function (props) { return (props.spinArrow ? 'rotate(180deg)' : 'rotate(0deg)'); });
var SubSectionHeader = function (_a) {
    var title = _a.title, _b = _a.open, open = _b === void 0 ? false : _b, onClick = _a.onClick, status = _a.status;
    return (React.createElement(Root, { status: status, onClick: function () { return onClick && onClick(); } },
        title,
        React.createElement(AnimateIcon, { spinArrow: open },
            React.createElement(Icon, { localIcon: ArrowDownIcon }))));
};
export default SubSectionHeader;
var templateObject_1, templateObject_2;
