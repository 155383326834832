var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import PagerButton, { Root as PagerButtonRoot } from './PagerButton';
export var PageType;
(function (PageType) {
    PageType["FIRST"] = "FIRST";
    PageType["LAST"] = "LAST";
    PageType["PREV"] = "PREV";
    PageType["NEXT"] = "NEXT";
})(PageType || (PageType = {}));
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 480px;\n  align-items: center;\n  margin: 0 auto;\n\n  ", " {\n    margin-right: 10px;\n    user-select: none;\n\n    :last-child {\n      margin-right: auto;\n    }\n\n    :first-child {\n      margin-left: auto;\n    }\n  }\n"], ["\n  display: flex;\n  width: 480px;\n  align-items: center;\n  margin: 0 auto;\n\n  ", " {\n    margin-right: 10px;\n    user-select: none;\n\n    :last-child {\n      margin-right: auto;\n    }\n\n    :first-child {\n      margin-left: auto;\n    }\n  }\n"])), PagerButtonRoot);
var PageNumberWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: rgba(137, 137, 137, 0.6);\n  margin-right: 10px;\n\n  span {\n    color: rgba(137, 137, 137, 1);\n  }\n"], ["\n  color: rgba(137, 137, 137, 0.6);\n  margin-right: 10px;\n\n  span {\n    color: rgba(137, 137, 137, 1);\n  }\n"])));
var Pager = function (_a) {
    var currentPage = _a.currentPage, onPageSelect = _a.onPageSelect, totalPages = _a.totalPages;
    var onPageButtonClick = function (id) {
        switch (id) {
            case 'first':
                onPageSelect(PageType.FIRST);
                break;
            case 'previous':
                if (currentPage > 1) {
                    onPageSelect(PageType.PREV);
                }
                break;
            case 'next':
                if (currentPage < totalPages) {
                    onPageSelect(PageType.NEXT);
                }
                break;
            case 'last':
                onPageSelect(PageType.LAST);
                break;
        }
    };
    return (React.createElement(Root, null,
        React.createElement(PagerButton, { onClick: function () { return onPageButtonClick('first'); }, disabled: currentPage === 1 }, "First"),
        React.createElement(PagerButton, { previous: true, disabled: currentPage === 1, onClick: function () { return onPageButtonClick('previous'); } }),
        React.createElement(PageNumberWrapper, null,
            React.createElement("span", null, currentPage),
            "\u00A0of\u00A0",
            totalPages),
        React.createElement(PagerButton, { next: true, disabled: currentPage === totalPages, onClick: function () { return onPageButtonClick('next'); } }),
        React.createElement(PagerButton, { onClick: function () { return onPageButtonClick('last'); }, disabled: currentPage === totalPages }, "Last")));
};
export default Pager;
var templateObject_1, templateObject_2;
