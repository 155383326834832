var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { get } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import EmailIcon from 'assets/email2.svg';
import EmailGreenIcon from 'assets/email_green.svg';
import SMSIcon from 'assets/sms.svg';
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import Pager, { getPage } from 'components/molecules/Pager';
import { fontWeight } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    margin: 20px 0;\n  }\n\n  > * {\n    :last-child {\n      margin-top: 24px;\n    }\n  }\n"], ["\n  ", " {\n    margin: 20px 0;\n  }\n\n  > * {\n    :last-child {\n      margin-top: 24px;\n    }\n  }\n"])), ButtonRoot);
var MessageWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: 1px solid #898989;\n  border-bottom: 1px solid #898989;\n  font-weight: ", ";\n  padding-top: 12px;\n"], ["\n  border-top: 1px solid #898989;\n  border-bottom: 1px solid #898989;\n  font-weight: ", ";\n  padding-top: 12px;\n"])), function (props) { return (props.read ? fontWeight.regular : fontWeight.medium); });
var Row = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  justify-content: space-between;\n  padding: 8px 0;\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  justify-content: space-between;\n  padding: 8px 0;\n"])));
var Cell = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  max-width: 50%;\n  font-weight: ", ";\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  svg {\n    margin-right: 10px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  max-width: 50%;\n  font-weight: ", ";\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  svg {\n    margin-right: 10px;\n  }\n"])), function (props) { return (props.bold ? fontWeight.medium : fontWeight.light); });
var MessageListMobile = function (_a) {
    var header = _a.header, messages = _a.messages, viewMessageButtonText = _a.viewMessageButtonText, page = _a.page, selectPage = _a.selectPage, showMessageDetail = _a.showMessageDetail;
    var _b = useState(messages.slice(0, 5)), visibleMessages = _b[0], setVisibleMessages = _b[1];
    var onPageSelect = function (pageType) {
        var newPage = getPage(page, Math.ceil(messages.length / 5.0), pageType);
        selectPage(newPage);
        setVisibleMessages(messages.slice((newPage - 1) * 5, newPage * 5));
    };
    return (React.createElement(Root, null,
        visibleMessages.map(function (message) { return (React.createElement(MessageWrapper, { key: message.messageId, read: message.notification === null },
            header.map(function (item) { return (React.createElement(Row, { key: item.value },
                React.createElement(Cell, { bold: true }, item.label),
                React.createElement(Cell, { bold: message.notification !== null },
                    (item.value === 'from' || item.value === 'subject') && get(message, item.value),
                    item.value === 'messageType' && (React.createElement(React.Fragment, null,
                        message.messageType === 'SMS' ? (React.createElement(SMSIcon, null)) : message.notification ? (React.createElement(EmailGreenIcon, null)) : (React.createElement(EmailIcon, null)),
                        get(message, item.value))),
                    item.value === 'sentOn' && moment(get(message, item.value)).format('DD / MM / yyyy')))); }),
            React.createElement(Button, { buttonStyle: "secondary", fullWidth: true, onClick: function () {
                    showMessageDetail(message);
                    TagManagerArgs('Button', window.location.pathname + " - Message Detail", viewMessageButtonText);
                } }, viewMessageButtonText))); }),
        React.createElement(Pager, { currentPage: page, totalPages: Math.ceil(messages.length / 5.0), onPageSelect: onPageSelect })));
};
export default MessageListMobile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
