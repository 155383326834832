import { getClassOfUseDisplay, getVehicleDisplayValuesForList } from './typeKeyDisplay';
import { isoDateFormatTo, numberFormatTo, numberFormatToText, textBetweenBrackets } from './formattingUtils';
import { driverUtils } from './driversUtils';
// Policy, renewal and amended Policy types
export var policyUtil = function (policy) {
    var getPolicyNumber = function () { return policy.policyNumber; };
    /**
     * @returns policy number without "ITB/..." prefix
     */
    var getPolicyNumberNoPrefix = function () { return getPolicyNumber().replace('ITB/', ''); };
    var getPolicyStatus = function () { return policy.status; };
    var getPolicyEffectiveDate = function () { return policy.effective; };
    var getPolicyEffectiveDateDisplay = function () { return isoDateFormatTo(policy.effective).dayMonthYear(); };
    var getPolicyExpirationDate = function () { return policy.expiration; };
    var getPolicyExpirationDateDisplay = function () { return isoDateFormatTo(policy.expiration).dayMonthYear(); };
    var getPolicyCoverType = function () { return policy.coverType; };
    var getPolicyNcdYears = function () { return policy.ncdYears; };
    var getPolicyNcdYearsDisplay = function () { return numberFormatTo(policy.ncdYears).yearsAfterNumber(); };
    var getPremium = function () { return policy.premium.amount; };
    var getProductMiles = function () { return policy.productMiles; };
    var getProductMilesDisplay = function () { return numberFormatTo(policy.productMiles).milesAfterNumber(); };
    var getTotalExcess = function () { return policy.myExcessPayment.damageExcesses[0].totalExcess; };
    var getTotalExcessDisplay = function () { return numberFormatTo(getTotalExcess()).priceFormat('noDecimal'); };
    var getWindscreenReplacement = function () { return policy.myExcessPayment.glassReplacementExcess; };
    var getWindscreenRepair = function () { return policy.myExcessPayment.glassRepairExcess; };
    // VehicleInfo
    var getVehicleInfoClassOfUse = function () { return policy.vehicleInfo.classOfUse; };
    var getVehicleInfoClassOfUseDisplay = function () { return getClassOfUseDisplay(policy.vehicleInfo.classOfUse); };
    var getRegistrationFromDisplayName = function () { return textBetweenBrackets(policy.vehicleInfo.displayName); };
    var getVehicleInfo = function () { return policy.vehicleInfo; };
    var getVehicleInfoDisplay = function () { return getVehicleDisplayValuesForList(getVehicleInfo()); };
    // Drivers
    var getTotalDriversNumber = function () { return driverUtils(policy.drivers).getTotalDrivers(); };
    var getTotalOtherDriversNumber = function () { return getTotalDriversNumber() - 1; };
    var getTotalDriversAsText = function (withPh) {
        if (withPh === 'includePh')
            return numberFormatToText(getTotalDriversNumber());
        if (withPh === 'withoutPh')
            return numberFormatToText(getTotalOtherDriversNumber());
        else
            return null;
    };
    var getPolicyHolderDriverDetails = driverUtils(policy.drivers).getPolicyHolderDriver();
    var getAdditionalDriversDetails = driverUtils(policy.drivers).getNonPolicyHolderDrivers();
    var getDisplayValue = function (value) {
        var _a;
        // CMS - Values
        var mapping = (_a = {},
            _a['policyStatus'] = getPolicyStatus(),
            _a['policyEffectiveDateDisplay'] = getPolicyEffectiveDateDisplay(),
            _a['policyExpirationDateDisplay'] = getPolicyExpirationDateDisplay(),
            _a['policyCoverType'] = getPolicyCoverType(),
            _a['policyNcdYearsDisplay'] = getPolicyNcdYearsDisplay(),
            _a['policyNumberDisplay'] = getPolicyNumber(),
            _a['policyProductMilesDisplay'] = getProductMilesDisplay(),
            _a['policyOtherDriversNumberDisplay'] = "" + getTotalOtherDriversNumber(),
            _a['policyTotalExcessDisplay'] = getTotalExcessDisplay(),
            _a['policyWindscreenReplacementDisplay'] = getWindscreenReplacement(),
            _a['policyWindscreenRepairDisplay'] = getWindscreenRepair(),
            _a['vehicleInfoClassOfUseDisplay'] = getVehicleInfoClassOfUseDisplay(),
            _a);
        if (mapping[value])
            return mapping[value];
        else
            return value;
    };
    return {
        getPolicyStatus: getPolicyStatus,
        getPolicyEffectiveDate: getPolicyEffectiveDate,
        getPolicyEffectiveDateDisplay: getPolicyEffectiveDateDisplay,
        getPolicyExpirationDate: getPolicyExpirationDate,
        getPolicyExpirationDateDisplay: getPolicyExpirationDateDisplay,
        getPolicyCoverType: getPolicyCoverType,
        getPolicyNcdYears: getPolicyNcdYears,
        getPolicyNcdYearsDisplay: getPolicyNcdYearsDisplay,
        getVehicleInfoClassOfUse: getVehicleInfoClassOfUse,
        getVehicleInfoClassOfUseDisplay: getVehicleInfoClassOfUseDisplay,
        getDisplayValue: getDisplayValue,
        getPremium: getPremium,
        getProductMiles: getProductMiles,
        getPolicyNumber: getPolicyNumber,
        getPolicyNumberNoPrefix: getPolicyNumberNoPrefix,
        getProductMilesDisplay: getProductMilesDisplay,
        getTotalDriversNumber: getTotalDriversNumber,
        getTotalOtherDriversNumber: getTotalOtherDriversNumber,
        getTotalExcess: getTotalExcess,
        getTotalExcessDisplay: getTotalExcessDisplay,
        getWindscreenReplacement: getWindscreenReplacement,
        getWindscreenRepair: getWindscreenRepair,
        getTotalDriversAsText: getTotalDriversAsText,
        getPolicyHolderDriverDetails: getPolicyHolderDriverDetails,
        getAdditionalDriversDetails: getAdditionalDriversDetails,
        getVehicleInfo: getVehicleInfo,
        getVehicleInfoDisplay: getVehicleInfoDisplay,
        getRegistrationFromDisplayName: getRegistrationFromDisplayName,
    };
};
