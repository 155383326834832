var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { media } from 'styles';
var SectionButtonWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 30px;\n\n  a {\n    text-decoration: none;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  margin-top: 30px;\n\n  a {\n    text-decoration: none;\n  }\n\n  ",
    "\n\n  ",
    "\n"])), media.smallOnly(templateObject_1 || (templateObject_1 = __makeTemplateObject(["  \n    > * {\n      width: 100%;\n    }\n  "], ["  \n    > * {\n      width: 100%;\n    }\n  "]))), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-end;\n\n    > * {\n      margin-left: 16px;\n    }\n  "], ["\n    display: flex;\n    justify-content: flex-end;\n\n    > * {\n      margin-left: 16px;\n    }\n  "]))));
export default SectionButtonWrapper;
var templateObject_1, templateObject_2, templateObject_3;
