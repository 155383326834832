var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ReactDropdown from 'react-dropdown';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { color } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .Dropdown-root {\n    position: relative;\n    align-items: center;\n    border: 1px solid ", ";\n    border-radius: 30px;\n  }\n\n  .Dropdown-control {\n    display: flex;\n    justify-content: space-between;\n    padding: 12px 20px 6px 20px;\n  }\n\n  .Dropdown-menu {\n    position: absolute;\n    width: 100%;\n    top: 70px;\n    background-color: rgba(255, 255, 255, 1);\n    box-sizing: border-box;\n    border: 1px solid ", ";\n    border-radius: 6px;\n    padding: 16px 20px;\n    z-index: 100;\n  }\n\n  .Dropdown-arrow-wrapper {\n    svg {\n      fill: ", ";\n    }\n  }\n\n  .Dropdown-option {\n    border-radius: 4px;\n    padding: 5px 0;\n\n    :hover {\n      background-color: ", "3A;\n    }\n  }\n"], ["\n  .Dropdown-root {\n    position: relative;\n    align-items: center;\n    border: 1px solid ", ";\n    border-radius: 30px;\n  }\n\n  .Dropdown-control {\n    display: flex;\n    justify-content: space-between;\n    padding: 12px 20px 6px 20px;\n  }\n\n  .Dropdown-menu {\n    position: absolute;\n    width: 100%;\n    top: 70px;\n    background-color: rgba(255, 255, 255, 1);\n    box-sizing: border-box;\n    border: 1px solid ", ";\n    border-radius: 6px;\n    padding: 16px 20px;\n    z-index: 100;\n  }\n\n  .Dropdown-arrow-wrapper {\n    svg {\n      fill: ", ";\n    }\n  }\n\n  .Dropdown-option {\n    border-radius: 4px;\n    padding: 5px 0;\n\n    :hover {\n      background-color: ", "3A;\n    }\n  }\n"])), color.green, color.green, color.green, color.green);
var Dropdown = function (_a) {
    var defaultOption = _a.defaultOption, onSelect = _a.onSelect, options = _a.options, placeholder = _a.placeholder;
    return (React.createElement(Root, null,
        React.createElement(ReactDropdown, { options: options, onChange: function (option) { return onSelect(option.value); }, value: defaultOption, placeholder: placeholder, arrowClosed: React.createElement(ArrowDownIcon, null), arrowOpen: React.createElement(ArrowUpIcon, null) })));
};
export default Dropdown;
var templateObject_1;
