import React, { useState } from 'react';
import CheckEmail from './CheckEmail';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import { Root, Logo, ContentContainer, PAGE_STATUS } from './shareComponents';
import HideOn from 'components/atoms/HideOn';
var Login = function (_a) {
    var content = _a.content, signIn = _a.signIn, sendResetPasswordToEmail = _a.sendResetPasswordToEmail, loginError = _a.loginError, loginErrorMessage = _a.loginErrorMessage;
    var logo = content.logo, emailLabel = content.emailLabel, emailPlaceholder = content.emailPlaceholder, passwordLabel = content.passwordLabel, passwordPlaceholder = content.passwordPlaceholder, forgotPasswordText = content.forgotPasswordText, signInText = content.signInText, forgotPasswordTitle = content.forgotPasswordTitle, forgotPasswordHint = content.forgotPasswordHint, backToSignInText = content.backToSignInText, resetButtonText = content.resetButtonText, newPasswordTitle = content.newPasswordTitle, newPasswordHint = content.newPasswordHint, newPasswordLabel = content.newPasswordLabel, newPasswordPlaceholder = content.newPasswordPlaceholder, confirmPasswordLabel = content.confirmPasswordLabel, confirmPasswordPlaceholder = content.confirmPasswordPlaceholder, checkEmailTitle = content.checkEmailTitle, checkEmailHint = content.checkEmailHint;
    var loginFormContent = {
        emailLabel: emailLabel,
        emailPlaceholder: emailPlaceholder,
        passwordLabel: passwordLabel,
        passwordPlaceholder: passwordPlaceholder,
        forgotPasswordText: forgotPasswordText,
        signInText: signInText,
    };
    var forgotPasswordContent = {
        forgotPasswordTitle: forgotPasswordTitle,
        forgotPasswordHint: forgotPasswordHint,
        emailLabel: emailLabel,
        emailPlaceholder: emailPlaceholder,
        backToSignInText: backToSignInText,
        resetButtonText: resetButtonText,
        newPasswordTitle: newPasswordTitle,
        newPasswordHint: newPasswordHint,
        newPasswordLabel: newPasswordLabel,
        newPasswordPlaceholder: newPasswordPlaceholder,
        confirmPasswordLabel: confirmPasswordLabel,
        confirmPasswordPlaceholder: confirmPasswordPlaceholder,
    };
    var checkEmailContent = {
        checkEmailTitle: checkEmailTitle,
        checkEmailHint: checkEmailHint,
        backToSignInText: backToSignInText,
    };
    var _b = useState(PAGE_STATUS.LOGIN), pageStatus = _b[0], setPageStatus = _b[1];
    var onBackToSignIn = function () { return setPageStatus(PAGE_STATUS.LOGIN); };
    return (React.createElement(Root, null,
        React.createElement(HideOn, { large: true },
            React.createElement(HideOn, { medium: true },
                React.createElement(Logo, null,
                    React.createElement("img", { src: logo, alt: "" })))),
        React.createElement(ContentContainer, null,
            pageStatus === PAGE_STATUS.LOGIN && (React.createElement(LoginForm, { content: loginFormContent, onPasswordForgottenClick: function () { return setPageStatus(PAGE_STATUS.RESET_PASSWORD); }, signIn: function (email, password) { return signIn(email, password); }, loginError: loginError, loginErrorMessage: loginErrorMessage })),
            pageStatus === PAGE_STATUS.RESET_PASSWORD && (React.createElement(ForgotPassword, { shouldSetNewPassword: false, content: forgotPasswordContent, onBackToSignIn: onBackToSignIn, onReset: function (email) {
                    setPageStatus(PAGE_STATUS.CHECK_EMAIL);
                    sendResetPasswordToEmail(email);
                } })),
            pageStatus === PAGE_STATUS.CHECK_EMAIL && (React.createElement(CheckEmail, { content: checkEmailContent, onBackToSignIn: onBackToSignIn })))));
};
export default Login;
