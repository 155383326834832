var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import DriverDetails from './DriverDetails';
import Icon from 'components/atoms/Icon';
import ListRow from 'components/molecules/ListRow';
import { FormInput, Group } from 'components/organisms/Form';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { fontWeight, fontSize, media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n\n  ", "\n"], ["\n  font-weight: ", ";\n\n  ",
    "\n"])), fontWeight.medium, media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: ", ";\n  "], ["\n    font-size: ", ";\n  "])), fontSize.fs20));
var MainDriver = function (_a) {
    var title = _a.title, detailsTitle = _a.detailsTitle, driverProfileContent = _a.driverProfileContent, driverProfileData = _a.driverProfileData, setMainDriver = _a.setMainDriver, setMainDriverButtonText = _a.setMainDriverButtonText, addDriverDetail = _a.addDriverDetail, addDriverDetailButtonText = _a.addDriverDetailButtonText, policyIsLapsed = _a.policyIsLapsed;
    var _b = useState(false), detailsOpen = _b[0], setDetailsOpen = _b[1];
    var mainContent = driverProfileContent.mainContent, detailsContent = driverProfileContent.detailsContent;
    var mainData = driverProfileData.mainData, detailsData = driverProfileData.detailsData;
    var keys = Object.keys(mainData);
    var driverMainData = keys.map(function (key) { return ({
        label: mainContent[key],
        value: mainData[key],
    }); });
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null, title),
        React.createElement(Group, { noBorder: true }, driverMainData.map(function (item) { return (React.createElement(FormInput, { key: item.label, disabled: true, label: item.label, value: item.value, name: item.value })); })),
        React.createElement(ListRow, { style: { borderTop: '1px solid rgba(137, 137, 137, 0.5)', borderBottom: '1px solid rgba(137, 137, 137, 0.5)' }, label: detailsTitle, icon: React.createElement(Icon, { localIcon: detailsOpen ? ArrowUpIcon : ArrowDownIcon }), onClick: function () { return setDetailsOpen(!detailsOpen); } }),
        detailsOpen && (React.createElement(DriverDetails, { detailsContent: detailsContent, detailsData: detailsData, setMainDriver: setMainDriver, setMainDriverButtonText: setMainDriverButtonText, addDriverDetail: addDriverDetail, addDriverDetailButtonText: addDriverDetailButtonText, policyIsLapsed: policyIsLapsed }))));
};
export default MainDriver;
var templateObject_1, templateObject_2, templateObject_3;
