import find from 'lodash/find';
import moment from 'moment';
import fallbackKeys from 'fallbacks/typekeys.json';
import { SessionStorageKey } from 'types';
import { TypeKeyEnums } from './enum';
import { booleanToText, numberFormatTo } from './formattingUtils';
var keys = JSON.parse(sessionStorage.getItem(SessionStorageKey.TYPEKEYS)) !== null
    ? JSON.parse(sessionStorage.getItem(SessionStorageKey.TYPEKEYS))
    : fallbackKeys;
export var getGwDropdownValues = function (typeKeyGroup) {
    var group = find(keys, [TypeKeyEnums.TYPE_NAME, typeKeyGroup]);
    var options = group.keyValuePairs.map(function (item) {
        return {
            label: item.value,
            value: item.key.substring(item.key.lastIndexOf('.') + 1),
        };
    });
    // Returning a blank object stops the FE breaking if something was done wrong in the CMS
    if (options === undefined)
        return [{ label: '', value: '' }];
    else
        return options;
};
export var getClassOfUseDisplay = function (value) {
    var status = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_VEHICLE_CLASS_OF_USE]);
    var data = find(status.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_VEHICLE_CLASS_OF_USE + "." + value });
    return data.value;
};
export var getSecurityDeviceDisplay = function (value) {
    var status = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_VEHICLE_SECURITY_DEVICE]);
    var data = find(status.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_VEHICLE_SECURITY_DEVICE + "." + value });
    return data.value;
};
export var getTitleDisplay = function (value, titleEdit) {
    if (titleEdit === void 0) { titleEdit = false; }
    var titles = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_NAME_PREFIX]);
    var data = find(titles.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_NAME_PREFIX + "." + value });
    if (!titleEdit)
        return data.value;
    if (titleEdit)
        return data.key.substring(data.key.lastIndexOf('.') + 1);
};
export var getMaritalDisplay = function (value) {
    var status = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_MARITAL_STATUS]);
    var data = find(status.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_MARITAL_STATUS + "." + value });
    return data.value;
};
export var getLicenseDisplay = function (value) {
    var license = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_DRIVING_LICENSE]);
    var data = find(license.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_DRIVING_LICENSE + "." + value });
    return data.value;
};
export var getAdditionalQualificationDisplay = function (value) {
    var qualification = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_DRIVING_QUALIFICATIONS]);
    var data = find(qualification.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_DRIVING_QUALIFICATIONS + "." + value });
    return data.value;
};
export var getMedicalConditionDisplay = function (value) {
    var condition = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_MEDICAL_CONDITIONS]);
    var data = find(condition.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_MEDICAL_CONDITIONS + "." + value });
    return data.value;
};
export var getNightTimeParkingDisplay = function (value) {
    var status = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_NIGHTTIME_PARKING]);
    var data = find(status.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_NIGHTTIME_PARKING + "." + value });
    return data.value;
};
export var getDayTimeParkingDisplay = function (value) {
    var status = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_DAYTIME_PARKING]);
    var data = find(status.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_DAYTIME_PARKING + "." + value });
    return data.value;
};
export var getOccupationDisplay = function (value) {
    var occupations = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_OCCUPATION]);
    var data = find(occupations.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_OCCUPATION + "." + value });
    return { label: data.value, value: value };
};
export var getDocumentType = function (value) {
    var documentType = find(keys, [TypeKeyEnums.TYPE_NAME, TypeKeyEnums.TYPE_KEY_DOCUMENT_TYPE]);
    var data = find(documentType.keyValuePairs, { key: TypeKeyEnums.TYPE_KEY_DOCUMENT_TYPE + "." + value + "_itb" });
    if (data !== undefined) {
        return { label: data.value, value: value };
    }
    return { label: value, value: value };
};
// converts date object to ISO
export var getDateObjectDisplay = function (date) {
    return moment(date).format();
};
// For driver form displays
export var getDriverDisplayValues = function (data, titleEditValue) {
    if (titleEditValue === void 0) { titleEditValue = false; }
    return Object.fromEntries(Object.keys(data).map(function (key) {
        if (key === 'title') {
            if (!titleEditValue)
                return [key, getTitleDisplay(data[key])];
            if (titleEditValue)
                return [key, getTitleDisplay(data[key], true)];
        }
        if (key === 'dateOfBirth')
            return [key, getDateObjectDisplay(data[key])];
        if (key === 'occupation')
            return [key, getOccupationDisplay(data[key])];
        if (key === 'documentationPreference' && data[key] === 'Electronic')
            return [key, 'Email'];
        if (key === 'documentationPreference' && data[key] === 'Paper')
            return [key, 'Post'];
        if (key === 'passedTestMonth' || key === 'provisionalLicenseMonth')
            return [key, moment().month(data[key])];
        if (data[key] === true)
            return [key, 'Yes'];
        if (data[key] === false)
            return [key, 'No'];
        return [key, data[key]];
    }));
};
// For driver details lists
export var getDriverDisplayValuesForList = function (data) {
    return Object.fromEntries(Object.keys(data).map(function (key) {
        if (key === 'title')
            return [key, getTitleDisplay(data[key])];
        if (key === 'dateOfBirth')
            return [key, moment(getDateObjectDisplay(data[key])).format('DD / MM / YYYY')];
        if (key === 'occupation')
            return [key, getOccupationDisplay(data[key]).label];
        if (key === 'maritalStatus')
            return [key, getMaritalDisplay(data[key])];
        if (key === 'licenseType')
            return [key, getLicenseDisplay(data[key])];
        if (key === 'drivingQualifications')
            return [key, getAdditionalQualificationDisplay(data[key])];
        if (key === 'medicalConditions')
            return [key, getMedicalConditionDisplay(data[key])];
        if (data[key] === true)
            return [key, 'Yes'];
        if (data[key] === false)
            return [key, 'No'];
        return [key, data[key]];
    }));
};
// For vehicle displays
export var getVehicleDisplayValuesForList = function (data) {
    return Object.fromEntries(Object.keys(data).map(function (key) {
        if (key === 'classOfUse')
            return [key, getClassOfUseDisplay(data[key])];
        if (key === 'marketValue')
            return [key, numberFormatTo(data[key].amount).priceFormat('noDecimal')];
        if (key === 'ncdYears')
            return [key, numberFormatTo(data[key]).milesAfterNumber()];
        if (key === 'nightTimeParking')
            return [key, getNightTimeParkingDisplay(data[key])];
        if (key === 'dayTimeParking')
            return [key, getDayTimeParkingDisplay(data[key])];
        if (key === 'securityDevices')
            return [key, getSecurityDeviceDisplay(data[key])];
        if (typeof data[key] === 'boolean')
            return [key, booleanToText(data[key])];
        return [key, data[key]];
    }));
};
