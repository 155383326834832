var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color, media } from 'styles';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0 15px;\n\n  ", "\n\n  ", "\n"], ["\n  padding: 0 15px;\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    background-color: ", ";\n  "], ["\n    display: flex;\n    background-color: ", ";\n  "])), color.greyBackground), media.large(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0 135px;\n  "], ["\n    padding: 0 135px;\n  "]))));
var TabWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  text-align: center;\n\n  ", "\n"], ["\n  cursor: pointer;\n  text-align: center;\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 1;\n  "], ["\n    flex: 1;\n  "]))));
var TabTextWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 20px 0 15px 0;\n"], ["\n  padding: 20px 0 15px 0;\n"])));
var TabIndicator = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 6px;\n  width: 80px;\n  background-color: ", ";\n  border-radius: 3px;\n  margin: 0 auto;\n"], ["\n  height: 6px;\n  width: 80px;\n  background-color: ", ";\n  border-radius: 3px;\n  margin: 0 auto;\n"])), function (props) { return (props.selected ? '#007F49' : 'transparent'); });
var NavigationBar = function (_a) {
    var uri = _a.uri, tabs = _a.tabs, onTabClick = _a.onTabClick, onClose = _a.onClose, isLapsed = _a.isLapsed;
    return (React.createElement(Root, null, tabs.map(function (tab) {
        if ((isLapsed === true && tab.label === 'Dashboard') || tab.label === 'Messages') {
            return (React.createElement(TabWrapper, { key: tab.label, onClick: function () {
                    onTabClick(tab.value);
                    onClose && onClose();
                } },
                React.createElement(TabTextWrapper, null, tab.label),
                React.createElement(TabIndicator, { selected: uri === tab.value })));
        }
        if (isLapsed === false) {
            return (React.createElement(TabWrapper, { key: tab.label, onClick: function () {
                    onTabClick(tab.value);
                    onClose && onClose();
                } },
                React.createElement(TabTextWrapper, null, tab.label),
                React.createElement(TabIndicator, { selected: uri === tab.value })));
        }
        return null;
    })));
};
export default NavigationBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
