var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import EditDriverForm from 'components/organisms/EditDriverForm';
import { useForm } from 'react-hook-form';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { getDriverDisplayValues } from 'guidewirePolicyUtils';
import Button from 'components/atoms/Button';
import { getDriverValues } from 'pages/MtaAddDriverPage/getDriverValues';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { TagManagerArgs } from 'analytics';
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var RenewalEditDriverPage = function (_a) {
    var setRenewalState = _a.setRenewalState, renewalState = _a.renewalState, renewalPolicy = _a.renewalPolicy;
    var _b = useState({
        display: [],
        data: [],
    }), convictions = _b[0], setConvictions = _b[1];
    var _c = useState({
        display: [],
        data: [],
    }), claims = _c[0], setClaims = _c[1];
    var history = useHistory();
    var _d = history.location.state, personalContent = _d.personalContent, driverContent = _d.driverContent, formGroups = _d.formGroups, driver = _d.driver, drivers = _d.drivers, driverIndex = _d.driverIndex;
    var personalFixed = {
        dateOfBirth: driver.data.dateOfBirth,
        firstName: driver.data.firstName,
        lastName: driver.data.lastName,
        title: driver.data.title,
        ukResident5Years: driver.data.ukResident5Years,
    };
    var driverFixed = {
        licenseType: driver.data.licenseType,
        testPassed10Years: driver.data.testPassed10Years,
    };
    var personalFixedDisplay = getDriverDisplayValues(personalFixed);
    var driverFixedDisplay = getDriverDisplayValues(driverFixed);
    var _e = useForm({
        defaultValues: driver.data,
    }), control = _e.control, register = _e.register, watch = _e.watch, handleSubmit = _e.handleSubmit;
    var watchedFields = watch();
    var onSubmit = function (data) {
        var formValues = {
            maritalStatus: data.maritalStatus,
            gender: data.gender,
            occupation: data.occupation,
            accessToOtherVehicles: data.accessToOtherVehicles,
            drivingQualifications: data.drivingQualifications,
            medicalConditions: data.medicalConditions,
            nonMotoringConvictions: data.nonMotoringConvictions,
            cancelledPreviousInsurance: data.cancelledPreviousInsurance,
            pmClaims: claims.data,
            pmMotorConvictions: convictions.data,
        };
        var currentValues = {
            maritalStatus: driver.data.maritalStatus,
            gender: driver.data.gender,
            occupation: driver.data.occupation,
            accessToOtherVehicles: driver.data.accessToOtherVehicles,
            drivingQualifications: driver.data.drivingQualifications,
            medicalConditions: driver.data.medicalConditions,
            nonMotoringConvictions: driver.data.nonMotoringConvictions,
            cancelledPreviousInsurance: driver.data.cancelledPreviousInsurance,
            pmClaims: driver.data.pmClaims,
            pmMotorConvictions: driver.data.pmMotorConvictions,
        };
        if (!isEqual(formValues, currentValues)) {
            var obj = getDriverValues(__assign(__assign(__assign({}, data), driverFixed), personalFixed));
            delete obj.newlyAdded;
            delete obj.tempID;
            obj.newlyAdded = false;
            obj.updateOrRemove = 'update';
            obj.pmClaims = claims.data;
            obj.pmMotorConvictions = convictions.data;
            var edited = drivers;
            edited[driverIndex] = __assign(__assign({}, drivers[driverIndex]), obj);
            setRenewalState(__assign(__assign({}, renewalState), { nonAccountHolderDrivers: edited }));
            history.push('/renewal-about-you');
        }
        else {
            history.push('/renewal-about-you');
        }
    };
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(EditDriverForm, { personalContent: personalContent, personalFixedValues: personalFixedDisplay, driverContent: driverContent, driverFixedValues: driverFixedDisplay, control: control, register: register, policy: renewalPolicy, formGroups: formGroups, watchedFields: watchedFields, onConvictionsChange: function (data) { return setConvictions(data); }, onClaimsChange: function (data) { return setClaims(data); }, hideEmailField: true, noPersonalTitle: true }),
                React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { type: "button", buttonStyle: "warning-clear", onClick: function () {
                            history.push('/renewal-about-you');
                            TagManagerArgs('Button', "" + window.location.pathname, 'Go back');
                        } }, "Go back"),
                    React.createElement(Button, { type: "submit" }, "Confirm changes"))))));
};
var mapStateToProps = function (state) { return ({
    renewalState: state.renewalState,
    renewalPolicy: state.renewalPolicy,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRenewalState: function (formState) { return dispatch(actions.setAmendRenewalState(formState)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(RenewalEditDriverPage);
var templateObject_1, templateObject_2;
