var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import ReactDropdown from 'react-dropdown';
import styled from 'styled-components';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import HideOn from 'components/atoms/HideOn';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { media } from 'styles';
import ExcessTable from './ExcessTable';
import ExcessTableMobile from './ExcessTableMobile';
import OtherExcess from './OtherExcess';
import Title from 'components/atoms/Title';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 28px;\n  font-size: 14px;\n\n  ", "\n"], ["\n  margin-bottom: 28px;\n  font-size: 14px;\n\n  ",
    "\n"])), media.smallOnly(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0 15px;\n  "], ["\n    padding: 0 15px;\n  "]))));
var DropdownWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  font-size: 14px;\n  margin-bottom: 20px;\n\n  ", "\n\n  .Dropdown-root {\n    position: relative;\n    border: 1px solid rgba(137, 137, 137, 0.3);\n    border-radius: 2px;\n    margin-top: 8px;\n  }\n\n  .Dropdown-control {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px 20px;\n  }\n\n  .Dropdown-menu {\n    position: absolute;\n    width: 100%;\n    top: 70px;\n    background-color: rgba(255, 255, 255, 1);\n    box-sizing: border-box;\n    border: 1px solid rgba(137, 137, 137, 0.3);\n    border-radius: 2px;\n    padding: 12px 0;\n    z-index: 100;\n  }\n\n  .Dropdown-option {\n    padding: 5px 18px;\n\n    :hover {\n      background-color: rgba(137, 137, 137, 0.1);\n    }\n  }\n"], ["\n  width: 100%;\n  font-size: 14px;\n  margin-bottom: 20px;\n\n  ",
    "\n\n  .Dropdown-root {\n    position: relative;\n    border: 1px solid rgba(137, 137, 137, 0.3);\n    border-radius: 2px;\n    margin-top: 8px;\n  }\n\n  .Dropdown-control {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px 20px;\n  }\n\n  .Dropdown-menu {\n    position: absolute;\n    width: 100%;\n    top: 70px;\n    background-color: rgba(255, 255, 255, 1);\n    box-sizing: border-box;\n    border: 1px solid rgba(137, 137, 137, 0.3);\n    border-radius: 2px;\n    padding: 12px 0;\n    z-index: 100;\n  }\n\n  .Dropdown-option {\n    padding: 5px 18px;\n\n    :hover {\n      background-color: rgba(137, 137, 137, 0.1);\n    }\n  }\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 320px;\n    margin-bottom: 30px;\n  "], ["\n    width: 320px;\n    margin-bottom: 30px;\n  "]))));
var DropdownLabelWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var ExcessSection = function (_a) {
    var content = _a.content, data = _a.data, _b = _a.expandable, expandable = _b === void 0 ? true : _b, selectOptions = _a.selectOptions, terms = _a.terms, onSelectExcess = _a.onSelectExcess;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(selectOptions ? selectOptions[0] : undefined), option = _d[0], setOption = _d[1];
    var title = content.title, intro = content.intro, tableHeaders = content.tableHeaders, otherExcessTitle = content.otherExcessTitle;
    var excesses = data.excesses, otherExcesses = data.otherExcesses;
    return (React.createElement(React.Fragment, null,
        expandable && (React.createElement(Layout, { shadow: true, withSeperator: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(Root, null,
                    React.createElement(SectionHeader, { title: title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
                    open && (React.createElement(PaddingVertical, null,
                        selectOptions && onSelectExcess && (React.createElement(DropdownWrapper, null,
                            React.createElement(DropdownLabelWrapper, null, "Voluntary excesses"),
                            React.createElement(ReactDropdown, { options: selectOptions, onChange: function (option) {
                                    onSelectExcess(parseInt(option.value, 10));
                                    setOption(option);
                                }, value: option, arrowClosed: React.createElement(ArrowDownIcon, null), arrowOpen: React.createElement(ArrowUpIcon, null) }))),
                        React.createElement(TextWrapper, null, intro),
                        React.createElement(HideOn, { smallOnly: true },
                            React.createElement(ExcessTable, { headers: tableHeaders, excesses: excesses })),
                        React.createElement(HideOn, { medium: true },
                            React.createElement(ExcessTableMobile, { headers: tableHeaders, excesses: excesses })),
                        React.createElement(OtherExcess, { title: otherExcessTitle, otherExcesses: otherExcesses }),
                        terms)))))),
        !expandable && (React.createElement(Root, null,
            React.createElement(Title, null, title),
            React.createElement(PaddingVertical, null,
                React.createElement(TextWrapper, null, intro),
                React.createElement(HideOn, { smallOnly: true },
                    React.createElement(ExcessTable, { headers: tableHeaders, excesses: excesses })),
                React.createElement(HideOn, { medium: true },
                    React.createElement(ExcessTableMobile, { headers: tableHeaders, excesses: excesses })),
                React.createElement(OtherExcess, { title: otherExcessTitle, otherExcesses: otherExcesses }))))));
};
export default ExcessSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
