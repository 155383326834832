var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { getPageContent } from 'services';
import { connect } from 'react-redux';
import { ContentPageURL } from 'types';
import HeaderSection from 'components/organisms/HeaderSection';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import Button from 'components/atoms/Button';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    margin-bottom: 20px;\n  }\n"], ["\n  p {\n    margin-bottom: 20px;\n  }\n"])));
var FormButtons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  a {\n    text-decoration: none;\n  }\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  a {\n    text-decoration: none;\n  }\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var PaymentCompletePage = function (_a) {
    var effectiveFrom = _a.effectiveFrom, currentpolicy = _a.currentpolicy;
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), text = _c[0], setText = _c[1];
    var history = useHistory();
    // Replaces placeholder text from the cms.
    // Works for strings with multiple and mixed placeholders
    var replacePlacholderText = function (value) {
        var placeholders = {
            EMAIL_ADDRESS: currentpolicy.policyHolder.emailAddress1,
            EFFECTIVE_DATE: effectiveFrom,
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/EMAIL_ADDRESS|EFFECTIVE_DATE/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    var handleFinished = function () {
        history.push('/dashboard');
    };
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.ACCEPTED_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setText(cmsContent.contentSections[0]);
    }, [cmsContent, text]);
    return (React.createElement(Root, null, cmsContent && currentpolicy && (React.createElement(React.Fragment, null,
        React.createElement(HeaderSection, { title: cmsContent.header.title, body: cmsContent.header.body }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    text && React.createElement(ReactMarkdown, { source: replacePlacholderText(text.body) }),
                    React.createElement(FormButtons, null,
                        React.createElement(Button, { onClick: function () {
                                handleFinished();
                                TagManagerArgs('Button', "" + window.location.pathname, 'Go back to My Dashboard');
                            }, type: "button" }, "Go back to My Dashboard")))))))));
};
var mapStateToProps = function (state) { return ({
    currentpolicy: state.currentPolicy,
    effectiveFrom: moment(state.mtaEffectiveDate).format('DD/MM/YYYY'),
}); };
export default connect(mapStateToProps)(PaymentCompletePage);
var templateObject_1, templateObject_2;
