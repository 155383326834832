export var ContentPageURL;
(function (ContentPageURL) {
    ContentPageURL["LOGIN_PAGE"] = "policypages/login-page";
    ContentPageURL["DASHBOARD_PAGE"] = "policypages/dashboard";
    ContentPageURL["MY_POLICY_PAGE"] = "policypages/policy";
    ContentPageURL["TOPUP_MILES_PAGE"] = "policypages/topup-miles";
    ContentPageURL["DRIVING_DATA_PAGE"] = "drivingdatapages/driving-data";
    ContentPageURL["PAGE_HEADER"] = "policypages/page-header";
    ContentPageURL["ADD_DRIVER_PAGE"] = "drivingdatapages/add-driver";
    ContentPageURL["CHANGE_VEHICLE_PAGE"] = "drivingdatapages/change-vehicle";
    ContentPageURL["OVERNIGHT_PARKING_PAGE"] = "drivingdatapages/overnight-parking";
    ContentPageURL["QUOTESUMMARY_PAGE"] = "drivingdatapages/quote-summary";
    ContentPageURL["MESSAGES_PAGE"] = "policypages/messages";
    ContentPageURL["YOUR_QUOTE_PAGE"] = "drivingdatapages/your-quote";
    ContentPageURL["IMPORTANT_INFORMATION_PAGE"] = "drivingdatapages/important-information";
    ContentPageURL["PAYMENT_WIDGET"] = "drivingdatapages/payment-widget";
    ContentPageURL["AMEND_DRIVER_PAGE"] = "drivingdatapages/amend-driver";
    ContentPageURL["OTHER_CHANGES_PAGE"] = "drivingdatapages/other-changes";
    ContentPageURL["CARD_DETAILS_PAGE"] = "drivingdatapages/card-details";
    ContentPageURL["ACCEPTED_PAGE"] = "drivingdatapages/accepted";
    ContentPageURL["ERROR_PAGE"] = "drivingdatapages/error";
    ContentPageURL["ERROR_PAGE_PAYMENTS"] = "drivingdatapages/error-payments";
    ContentPageURL["RENEWAL_ABOUT_YOU"] = "drivingdatapages/renewal-about-you";
    ContentPageURL["RENEWAL_YOUR_CAR"] = "drivingdatapages/renewal-your-car";
    ContentPageURL["RENEWAL_PAYMENT_SUMMARY_ANNUAL"] = "drivingdatapages/renewal-annual-payment";
    ContentPageURL["RENEWAL_PAYMENT_SUMMARY_MONTHLY"] = "drivingdatapages/renewal-monthly-payment";
    ContentPageURL["RENEWAL_PAYMENT_DIRECT_DEBIT_DETAILS"] = "drivingdatapages/renewal-monthly-details";
    ContentPageURL["RENEWAL_PAYMENT_DIRECT_DEBIT_MANDATE"] = "drivingdatapages/renewal-monthly-mandate";
    ContentPageURL["RENEWAL_PAYMENT_COMPLETE"] = "drivingdatapages/payment-complete";
    ContentPageURL["MY_ACCOUNT_PAGE"] = "drivingdatapages/my-account";
    ContentPageURL["IDLE_MODAL"] = "drivingdatapages/timeout";
    ContentPageURL["POPUP_GUIDE"] = "drivingdatapages/popup-guide";
    ContentPageURL["MTA_MODAL"] = "drivingdatapages/mta-modal";
    ContentPageURL["TWENTY_THOUSAND_BANNER"] = "drivingdatapages/twenty-thousand-miles";
    ContentPageURL["PAGE_FOOTER"] = "drivingdatapages/footer";
    ContentPageURL["TYPKEYS_URL"] = "typekeys/all";
})(ContentPageURL || (ContentPageURL = {}));
export var MockPolicyNumbers;
(function (MockPolicyNumbers) {
    MockPolicyNumbers["ITB65"] = "ITB%252F15008565";
    MockPolicyNumbers["ITB66"] = "ITB%252F15008566";
    MockPolicyNumbers["ITB67"] = "ITB%252F15008567";
    MockPolicyNumbers["ITB68"] = "ITB%252F15008568";
})(MockPolicyNumbers || (MockPolicyNumbers = {}));
export var SessionStorageKey;
(function (SessionStorageKey) {
    SessionStorageKey["ACCESS_TOKEN"] = "access_token";
    SessionStorageKey["REFRESH_TOKEN"] = "refresh_token";
    SessionStorageKey["POLICY_OPTION_INDEX"] = "policy_option_index";
    SessionStorageKey["TYPEKEYS"] = "typeKeys";
})(SessionStorageKey || (SessionStorageKey = {}));
export var AddDriverWatchedFieldIds;
(function (AddDriverWatchedFieldIds) {
    AddDriverWatchedFieldIds["UK_RESIDENT_FIVE_YEARS"] = "ukResident5Years";
    AddDriverWatchedFieldIds["LICENSE_TYPE"] = "licenseType";
    AddDriverWatchedFieldIds["TEST_PASSED_TEN_YEARS"] = "testPassed10Years";
    AddDriverWatchedFieldIds["HAS_MOTOR_CONVICTIONS"] = "hasMotorConvictions";
    AddDriverWatchedFieldIds["HAS_MOTOR_CLAIMS"] = "hasMotorClaims";
})(AddDriverWatchedFieldIds || (AddDriverWatchedFieldIds = {}));
export var AddDriverWatchedValues;
(function (AddDriverWatchedValues) {
    AddDriverWatchedValues["VALUE_YES"] = "Yes";
    AddDriverWatchedValues["VALUE_NO"] = "No";
    AddDriverWatchedValues["VALUE_FULL_UK"] = "FullUK";
    AddDriverWatchedValues["VALUE_FULL_EU"] = "FullEU";
    AddDriverWatchedValues["VALUE_PROVISIONAL_UK"] = "ProvisionalUK";
    AddDriverWatchedValues["VALUE_PROVISIONAL_EU"] = "ProvisionalEU";
})(AddDriverWatchedValues || (AddDriverWatchedValues = {}));
// These are associated with the 'value' field in the CMS
// Generally they match up to the values GW uses for keys in Requests, however in some cases such as dates
// the form will use one key (passedDate) and GW will require 2 keys (passedMonth, passedYear)
export var AddDriverCmsValue;
(function (AddDriverCmsValue) {
    AddDriverCmsValue["OCCUPATION"] = "occupation";
    AddDriverCmsValue["DRIVING_QUALIFICATIONS"] = "drivingQualifications";
    AddDriverCmsValue["MEDICAL_CONDITIONS"] = "medicalConditions";
    AddDriverCmsValue["DATE_OF_BIRTH"] = "dateOfBirth";
})(AddDriverCmsValue || (AddDriverCmsValue = {}));
export var ChangeVehicleWatchedFieldIds;
(function (ChangeVehicleWatchedFieldIds) {
    ChangeVehicleWatchedFieldIds["HAS_BEEN_MODIFIED"] = "hasBeenModified";
})(ChangeVehicleWatchedFieldIds || (ChangeVehicleWatchedFieldIds = {}));
export var ChangeVehicleSectionIds;
(function (ChangeVehicleSectionIds) {
    ChangeVehicleSectionIds["YOUR_CAR_SECTION"] = "yourCarSection";
    ChangeVehicleSectionIds["REGISTRATION_LOOKUP_SECTION"] = "registrationLookupSection";
    ChangeVehicleSectionIds["CAR_INFORMATION_ONE_SECTION"] = "carInformationOneSection";
    ChangeVehicleSectionIds["CAR_INFORMATION_TWO_SECTION"] = "carInformationTwoSection";
})(ChangeVehicleSectionIds || (ChangeVehicleSectionIds = {}));
export var VehicleLookupContentIds;
(function (VehicleLookupContentIds) {
    VehicleLookupContentIds["CAR_REGISTRATION"] = "carRegistration";
    VehicleLookupContentIds["FIND_MY_CAR"] = "findMyCar";
})(VehicleLookupContentIds || (VehicleLookupContentIds = {}));
export var OvernightParkingContentIds;
(function (OvernightParkingContentIds) {
    OvernightParkingContentIds["CURRENT_ADDRESS_SECTION"] = "currentAddressSection";
    OvernightParkingContentIds["ADDRESS_LOOKUP_SECTION"] = "addressLookupSection";
    OvernightParkingContentIds["SELECTED_ADDRESS_SECTION"] = "selectedAddressSection";
})(OvernightParkingContentIds || (OvernightParkingContentIds = {}));
// General that apply to all three mtas
export var RadioSelectValues;
(function (RadioSelectValues) {
    RadioSelectValues["VALUE_YES"] = "Yes";
    RadioSelectValues["VALUE_NO"] = "No";
})(RadioSelectValues || (RadioSelectValues = {}));
export var AddressContentIds;
(function (AddressContentIds) {
    AddressContentIds["POSTCODE"] = "postcode";
    AddressContentIds["FIND_ADDRESS"] = "findAddress";
    AddressContentIds["ADDRESS_SELECT"] = "addressSelect";
    AddressContentIds["ADDRESS_LINE_ONE"] = "addressLine1";
    AddressContentIds["CITY"] = "city";
    AddressContentIds["POSTAL_CODE"] = "postalCode";
    AddressContentIds["HOME_OWNER"] = "homeOwner";
    AddressContentIds["DAY_TIME_PARKING"] = "dayTimeParking";
    AddressContentIds["NIGHT_TIME_PARKING"] = "nightTimeParking";
})(AddressContentIds || (AddressContentIds = {}));
export var CmsPlaceholder;
(function (CmsPlaceholder) {
    CmsPlaceholder["POLICY_HOLDER_NAME"] = "POLICY_HOLDER_NAME";
    CmsPlaceholder["VEHICLE_MAKE_MODEL"] = "VEHICLE_MAKE_MODEL";
})(CmsPlaceholder || (CmsPlaceholder = {}));
