var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { getPageContent, changeVehicle, mtaTokenRefresh, multiMtas } from 'services';
import { getVehicleValues } from './getVehicleValues';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { SessionStorageKey, ContentPageURL, ChangeVehicleWatchedFieldIds, ChangeVehicleSectionIds, } from 'types';
import { changeVehicleFormController } from './changeVehicleFormController';
import MtaDateAndTime from 'components/organisms/MtaDateAndTime';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { getSingleFormGroupContent, GroupsControlled } from 'components/organisms/Form';
import { VehicleCurrent, VehicleLookup } from 'components/organisms/Vehicle';
import MtaPageButtons from 'components/molecules/MtaPageButtons';
import MtaModal from 'components/molecules/MtaModal';
import ShareComponent from 'pages/ShareComponent';
import { policyUtil } from 'guidewirePolicyUtils';
import { registrationLookup } from 'guidewire/registrationLookup';
var showHideFields = {
    modification: true,
};
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var MtaChangeVehiclePage = function (_a) {
    var mta = _a.mta, setQuoteDetails = _a.setQuoteDetails, setMta = _a.setMta, setMtaDate = _a.setMtaDate, currentPolicy = _a.currentPolicy, setMultiMtaResponse = _a.setMultiMtaResponse, mtaEffectiveDate = _a.mtaEffectiveDate, multiMtaResponse = _a.multiMtaResponse;
    // CMS
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), formButtons = _d[0], setFormButtons = _d[1];
    // Modal
    var _e = useState(undefined), modalContent = _e[0], setModalContent = _e[1];
    var _f = useState(false), modalOpen = _f[0], setModalOpen = _f[1];
    // Mta effective date
    var _g = useState(''), effectiveDate = _g[0], setEffectiveDate = _g[1];
    // Car / registration info
    var _h = useState(undefined), carDetails = _h[0], setCarDetails = _h[1];
    var _j = useState(''), registration = _j[0], setRegistration = _j[1];
    // Hidden fields
    var _k = useState(showHideFields), hiddenField = _k[0], setHiddenField = _k[1];
    // data combining searched vehicle and converted formdata. This is to be used in the MTA call
    var _l = useState(undefined), carData = _l[0], setCarData = _l[1];
    // which submit button is pressed
    var _m = useState(''), submitType = _m[0], setSubmitType = _m[1];
    // Form functions and controllers
    var _o = useForm(), register = _o.register, handleSubmit = _o.handleSubmit, control = _o.control, watch = _o.watch, errors = _o.errors;
    var history = useHistory();
    // Which fields trigger hidden fields to show / hide
    var watchedFields = watch([ChangeVehicleWatchedFieldIds.HAS_BEEN_MODIFIED]);
    var policy = currentPolicy && policyUtil(currentPolicy);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var obj, policyNumber, token, details, vehicle, otherChanges, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    obj = getVehicleValues(data, registration);
                    if (submitType === 'continue') {
                        setCarData(__assign(__assign({}, obj), carDetails));
                        setModalOpen(true);
                    }
                    if (!(submitType === 'otherChanges')) return [3 /*break*/, 3];
                    policyNumber = policy.getPolicyNumberNoPrefix();
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    details = __assign(__assign({}, obj), carDetails);
                    vehicle = undefined;
                    if (multiMtaResponse === undefined) {
                        vehicle = { vehicles: [details] };
                    }
                    else
                        vehicle = {
                            status: multiMtaResponse.status,
                            jobId: multiMtaResponse.jobId,
                            vehicles: [details],
                        };
                    return [4 /*yield*/, multiMtas(policyNumber, vehicle, effectiveDate, token)];
                case 1:
                    otherChanges = _a.sent();
                    if (otherChanges.status === 200) {
                        mtaFlag = __assign(__assign({}, mta), { newCar: true });
                        setMta(mtaFlag);
                        setMultiMtaResponse(otherChanges.data);
                        setMtaDate(effectiveDate);
                        history.push('/other-changes');
                    }
                    if ((otherChanges.status !== undefined && otherChanges.status === 500) ||
                        (otherChanges.data.status !== undefined && otherChanges.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(otherChanges.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        onSubmit(data);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var modalContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, token, quote, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, changeVehicle(policyNumber, carData, effectiveDate, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        // set quote data to redux
                        setQuoteDetails(quote.data);
                        mtaFlag = __assign(__assign({}, mta), { newCar: true });
                        setMta(mtaFlag);
                        // set dates in redux
                        setMtaDate(effectiveDate);
                        // redirect
                        history.push('/your-quote');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        modalContinue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onCancel = function () {
        if (mtaEffectiveDate)
            history.push('/other-changes');
        else
            history.push('/mypolicy');
    };
    useEffect(function () {
        if (mtaEffectiveDate !== undefined)
            setEffectiveDate(mtaEffectiveDate);
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.CHANGE_VEHICLE_PAGE).then(function (content) {
                var payload = content.payload, status = content.status;
                if (status === 200) {
                    setCmsContent(payload);
                }
            });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent !== undefined)
            setFormButtons(cmsContent.contentSections[0].buttons);
        if (cmsContent !== undefined)
            setModalContent(cmsContent.modals[0]);
        changeVehicleFormController(watchedFields, hiddenField, setHiddenField);
    }, [cmsContent, carDetails, watchedFields.hasBeenModified]);
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) }, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(MtaDateAndTime, { disabled: mtaEffectiveDate !== undefined, getEffectiveDate: function (date) { return setEffectiveDate(date); } }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null, formGroups && currentPolicy && (React.createElement(React.Fragment, null,
                    React.createElement(VehicleCurrent, { content: getSingleFormGroupContent(ChangeVehicleSectionIds.YOUR_CAR_SECTION, formGroups), vehicle: policy.getVehicleInfoDisplay() }),
                    effectiveDate !== '' && (React.createElement(React.Fragment, null,
                        React.createElement(VehicleLookup, { content: getSingleFormGroupContent(ChangeVehicleSectionIds.REGISTRATION_LOOKUP_SECTION, formGroups), carToParent: setCarDetails, registrationToParent: setRegistration, currentRegistration: policy.getRegistrationFromDisplayName(), registrationLookup: registrationLookup }),
                        carDetails && (React.createElement(React.Fragment, null,
                            React.createElement(GroupsControlled, { ids: [
                                    ChangeVehicleSectionIds.CAR_INFORMATION_ONE_SECTION,
                                    ChangeVehicleSectionIds.CAR_INFORMATION_TWO_SECTION,
                                ], formGroups: formGroups, register: register, control: control, hiddenField: hiddenField, errors: errors }))))),
                    formButtons && (React.createElement(MtaPageButtons, { formButtons: formButtons, submitType: setSubmitType, showMtaButtons: effectiveDate !== '' && carDetails !== undefined, onCancel: onCancel, showContinue: mtaEffectiveDate === undefined }))))))),
        modalContent && (React.createElement(MtaModal, { modalOpen: modalOpen, setModalOpen: setModalOpen, modalContent: modalContent, modalContinue: modalContinue }))))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    mta: state.mta,
    mtaEffectiveDate: state.mtaEffectiveDate,
    multiMtaResponse: state.multiMtaResponse,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setQuoteDetails: function (quote) { return dispatch(actions.setQuote(quote)); },
    setMtaDate: function (date) { return dispatch(actions.setMtaEffectiveDate(date)); },
    setMta: function (mta) { return dispatch(actions.setMta(mta)); },
    setMultiMtaResponse: function (response) { return dispatch(actions.setMultiMtaResponse(response)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MtaChangeVehiclePage);
var templateObject_1;
