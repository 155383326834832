var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MtaDatePicker from './MtaDatePicker';
import MtaTimePicker from './MtaTimePicker';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import moment from 'moment';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var DateAndTime = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  background-color: #ffffff;\n\n  div:first-child {\n    margin-right: 30px;\n  }\n"], ["\n  display: flex;\n  background-color: #ffffff;\n\n  div:first-child {\n    margin-right: 30px;\n  }\n"])));
var MtaDateAndTime = function (_a) {
    var getEffectiveDate = _a.getEffectiveDate, disabled = _a.disabled, mtaEffectiveDate = _a.mtaEffectiveDate;
    var _b = useState(''), time = _b[0], setTime = _b[1];
    var _c = useState(''), date = _c[0], setDate = _c[1];
    var _d = useState(''), effective = _d[0], setEffective = _d[1];
    var _e = useState(''), timeErrorMessage = _e[0], setTimeErrorMessage = _e[1];
    var mtaDate = function (date, time) {
        var dateIso = date.substring(0, 11);
        var timeIso = new Date(time).toLocaleTimeString();
        setEffective("" + dateIso + timeIso + ".000Z");
    };
    useEffect(function () {
        var dateToday = new Date().toLocaleDateString();
        var selectedDate = new Date(date).toLocaleDateString();
        // If everything is selected and valid, activate getEffectiveDate to create the final date
        if (time !== '' && date !== '') {
            if (moment(time).isValid() && time !== null && moment(date).isValid && date !== null)
                mtaDate(date, time);
            else {
                setEffective('');
                getEffectiveDate('');
            }
        }
        // The is a logic check for a specific usecase, makes sure the time error message shows correctly
        // Example:
        // step 1: User selects todays date and a time in the past = timeErrorMessage shown
        // step 2: User changes to a future date, time is now correct = timeErrorMessage not shown
        if (date !== '') {
            if (dateToday !== selectedDate) {
                setTimeErrorMessage('');
            }
        }
        // Logic check for the time select. Prevents the user selecting a time that is not at least 30 min in the future
        if (time !== '') {
            if (dateToday === selectedDate) {
                if (moment(new Date(time)).isAfter(moment().add(30, 'minutes')) === false) {
                    setEffective('');
                    getEffectiveDate('');
                    setTimeErrorMessage('Please choose a time that is 30 minutes or more in the future');
                }
                else
                    setTimeErrorMessage('');
            }
        }
        if (effective !== '') {
            getEffectiveDate(effective);
        }
    }, [time, date, effective]);
    return (React.createElement(Root, null,
        React.createElement(Layout, { shadow: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(DateAndTime, null,
                        React.createElement(MtaDatePicker, { mtaEffectiveDate: mtaEffectiveDate, disabled: disabled, onSelect: function (event) { return setDate(event); }, disablePast: true }),
                        React.createElement(MtaTimePicker, { mtaEffectiveDate: mtaEffectiveDate, disabled: disabled, timeErrorMessage: timeErrorMessage, onSelect: function (event) { return setTime(event); } })))))));
};
export default MtaDateAndTime;
var templateObject_1, templateObject_2;
