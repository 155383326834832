import React from 'react';
import { getSingleFormGroupContent, getSingleFieldContent, Group, FormSelect, FormRadio, FormDateYearMonthOnly, } from 'components/organisms/Form';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
var AddDriverDetails = function (_a) {
    var formGroups = _a.formGroups, control = _a.control, watchedFields = _a.watchedFields, errors = _a.errors;
    var driverDetailsContent = getSingleFormGroupContent('driverDetailsSection', formGroups);
    var licenseType = getSingleFieldContent('licenseType', driverDetailsContent.fields);
    var licenseTypeOptions = getGwDropdownValues(licenseType.gwTypelist);
    var passed10Years = getSingleFieldContent('passed10Years', driverDetailsContent.fields);
    var passDate = getSingleFieldContent('passDate', driverDetailsContent.fields);
    var droveOnProvisional = getSingleFieldContent('droveOnProvisional', driverDetailsContent.fields);
    var accessToOtherVehicles = getSingleFieldContent('accessToOtherVehicles', driverDetailsContent.fields);
    var otherQualifications = getSingleFieldContent('otherQualifications', driverDetailsContent.fields);
    var otherQualificationsOptions = getGwDropdownValues(otherQualifications.gwTypelist);
    var medical = getSingleFieldContent('medical', driverDetailsContent.fields);
    var medicalOptions = getGwDropdownValues(medical.gwTypelist);
    var nonMotoringConvictions = getSingleFieldContent('nonMotoringConvictions', driverDetailsContent.fields);
    var policyCancelled = getSingleFieldContent('policyCancelled', driverDetailsContent.fields);
    return (React.createElement(Group, { title: driverDetailsContent.title },
        React.createElement(FormSelect, { label: licenseType.label, options: licenseTypeOptions, toolTip: licenseType.toolTip, placeholder: licenseType.placeholder, name: licenseType.value, control: control, spanTwoCols: licenseType.spanTwoColumns, additionalToTooltip: licenseType.additionalToTooltip, isRequired: licenseType.required, error: errors && errors[licenseType.value] ? errors[licenseType.value] : undefined }),
        (watchedFields.licenseType === 'FullUK' || watchedFields.licenseType === 'FullEU') &&
            watchedFields.licenseType !== undefined && (React.createElement(FormRadio, { label: passed10Years.label, toolTip: passed10Years.toolTip, options: passed10Years.options, name: passed10Years.value, control: control, additionalToTooltip: passed10Years.additionalToTooltip, isRequired: passed10Years.required, error: errors && errors[passed10Years.value] ? errors[passed10Years.value] : undefined })),
        (watchedFields.testPassed10Years === 'No' || watchedFields.licenseType === 'Other') && (React.createElement(FormDateYearMonthOnly, { label: passDate.label, toolTip: passDate.toolTip, name: passDate.value, control: control, additionalToTooltip: passDate.additionalToTooltip, isRequired: passDate.required, error: errors && errors[passDate.value] ? errors[passDate.value] : undefined })),
        (watchedFields.licenseType === 'ProvisionalUK' || watchedFields.licenseType === 'ProvisionalEU') &&
            watchedFields.licenseType !== undefined && (React.createElement(FormDateYearMonthOnly, { label: droveOnProvisional.label, toolTip: droveOnProvisional.toolTip, name: droveOnProvisional.value, control: control, additionalToTooltip: droveOnProvisional.additionalToTooltip, isRequired: droveOnProvisional.required, error: errors && errors[droveOnProvisional.value] ? errors[droveOnProvisional.value] : undefined })),
        React.createElement(FormRadio, { label: accessToOtherVehicles.label, toolTip: accessToOtherVehicles.toolTip, options: accessToOtherVehicles.options, name: accessToOtherVehicles.value, control: control, additionalToTooltip: accessToOtherVehicles.additionalToTooltip, isRequired: accessToOtherVehicles.required, error: errors && errors[accessToOtherVehicles.value] ? errors[accessToOtherVehicles.value] : undefined }),
        React.createElement(FormSelect, { label: otherQualifications.label, options: otherQualificationsOptions, toolTip: otherQualifications.toolTip, placeholder: otherQualifications.placeholder, name: otherQualifications.value, control: control, spanTwoCols: otherQualifications.spanTwoColumns, additionalToTooltip: otherQualifications.additionalToTooltip, isRequired: otherQualifications.required, error: errors && errors[otherQualifications.value] ? errors[otherQualifications.value] : undefined }),
        React.createElement(FormSelect, { label: medical.label, options: medicalOptions, toolTip: medical.toolTip, placeholder: medical.placeholder, name: medical.value, control: control, spanTwoCols: medical.spanTwoColumns, additionalToTooltip: medical.additionalToTooltip, isRequired: medical.required, error: errors && errors[medical.value] ? errors[medical.value] : undefined }),
        React.createElement(FormRadio, { label: nonMotoringConvictions.label, toolTip: nonMotoringConvictions.toolTip, options: nonMotoringConvictions.options, name: nonMotoringConvictions.value, control: control, additionalToTooltip: nonMotoringConvictions.additionalToTooltip, isRequired: nonMotoringConvictions.required, error: errors && errors[nonMotoringConvictions.value] ? errors[nonMotoringConvictions.value] : undefined }),
        React.createElement(FormRadio, { label: policyCancelled.label, toolTip: policyCancelled.toolTip, options: policyCancelled.options, name: policyCancelled.value, control: control, additionalToTooltip: policyCancelled.additionalToTooltip, isRequired: policyCancelled.required, error: errors && errors[policyCancelled.value] ? errors[policyCancelled.value] : undefined })));
};
export default AddDriverDetails;
