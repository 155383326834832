var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Tooltip from 'components/molecules/Tooltip';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 44px;\n  margin-bottom: 36px;\n"], ["\n  margin-top: 44px;\n  margin-bottom: 36px;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 14px;\n  padding: 0 30px;\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 14px;\n  padding: 0 30px;\n"])));
var HeaderCell = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  padding-bottom: 18px;\n"], ["\n  display: flex;\n  flex: 1;\n  padding-bottom: 18px;\n"])));
var LabelWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-right: 15px;\n"], ["\n  font-weight: ", ";\n  margin-right: 15px;\n"])), fontWeight.medium);
var Rows = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var Row = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  cursor: pointer;\n  padding: 14px 30px;\n\n  :last-child {\n    border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  }\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  cursor: pointer;\n  padding: 14px 30px;\n\n  :last-child {\n    border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  }\n"])));
var CellWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  font-weight: ", ";\n"], ["\n  flex: 1;\n  font-weight: ", ";\n"])), function (props) { return (props.isTotal ? fontWeight.regular : fontWeight.light); });
var ExcessTable = function (_a) {
    var headers = _a.headers, excesses = _a.excesses;
    return (React.createElement(Root, null,
        React.createElement(Header, null, headers.map(function (header) { return (React.createElement(HeaderCell, { key: header.value },
            React.createElement(LabelWrapper, { key: header.value }, header.label),
            header.info && React.createElement(Tooltip, { text: header.info }))); })),
        React.createElement(Rows, null, excesses.map(function (excess) { return (React.createElement(Row, { key: excess.driver }, headers.map(function (header) {
            var value = excess[header.value];
            var type = typeof value;
            return (React.createElement(CellWrapper, { key: excess.driver + header.value, isTotal: header.value === 'totalExcess' }, type === 'number' ? "\u00A3" + value : value));
        }))); }))));
};
export default ExcessTable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
