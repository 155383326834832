var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import SectionButtonWrapper from 'components/molecules/SectionButtonWrapper';
import { TagManagerArgs } from 'analytics';
import ListRow from 'components/molecules/ListRow';
import { fontWeight, media } from 'styles';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 40px;\n\n  ", " {\n    margin-bottom: 15px;\n\n    ", "\n  }\n"], ["\n  margin-bottom: 40px;\n\n  ", " {\n    margin-bottom: 15px;\n\n    ",
    "\n  }\n"])), ButtonRoot, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin-right: 10px;\n\n      :last-child {\n        margin-right: 0;\n      }\n    "], ["\n      margin-right: 10px;\n\n      :last-child {\n        margin-right: 0;\n      }\n    "]))));
var Details = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: rgba(137, 137, 137, 0.04);\n\n  > * {\n    border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n\n    :last-child {\n      border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n    }\n  }\n"], ["\n  background-color: rgba(137, 137, 137, 0.04);\n\n  > * {\n    border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n\n    :last-child {\n      border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n    }\n  }\n"])));
var DriverDetails = function (_a) {
    var detailsContent = _a.detailsContent, detailsData = _a.detailsData, setMainDriver = _a.setMainDriver, setMainDriverButtonText = _a.setMainDriverButtonText, addDriverDetail = _a.addDriverDetail, addDriverDetailButtonText = _a.addDriverDetailButtonText, removeDriver = _a.removeDriver, removeDriverButtonText = _a.removeDriverButtonText, driverIndex = _a.driverIndex, policyIsLapsed = _a.policyIsLapsed;
    var keys = Object.keys(detailsData);
    var details = keys.map(function (key) { return ({
        label: detailsContent[key],
        value: detailsData[key],
        info: detailsContent[key + "Info"],
    }); });
    return (React.createElement(Root, null,
        React.createElement(Details, null, details.map(function (detail) { return (React.createElement(ListRow, { key: detail.label + " " + driverIndex, label: detail.label, labelStyle: { fontWeight: fontWeight.light }, value: detail.value, info: detail.info })); })),
        "-",
        ' ',
        React.createElement(SectionButtonWrapper, null, removeDriverButtonText !== undefined && removeDriver && (React.createElement(Button, { disabled: policyIsLapsed, buttonStyle: "warning secondary", onClick: function () {
                removeDriver(driverIndex);
                TagManagerArgs('Button', window.location.pathname + " - Driver Details", removeDriverButtonText);
            } }, removeDriverButtonText)))));
};
export default DriverDetails;
var templateObject_1, templateObject_2, templateObject_3;
