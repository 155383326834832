import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MilesWidgetSection from 'components/organisms/MilesWidgetSection';
import MyJourneysSection from 'components/organisms/MyJourneysSection';
import BonusMilesSection from 'components/organisms/BonusMilesSection';
import DrivingScoresSection from 'components/organisms/DrivingScoresSection';
import { getPageContent, drivingDataClient } from 'services';
import { ContentPageURL, SessionStorageKey, } from 'types';
import { getMonthAndYearFromString, getFriendlyDisplayDate } from 'utils/date';
import { mapToDailyMiles, mapToJourneySummaries, mapToMonthlyMiles, createBonusMilesData, mapToJourneys, fetchDrivingData, fetchTripsByPage, mapPolicyTripsToJourneys, } from 'pages/ShareComponent/shareFunctions';
import ShareComponent from 'pages/ShareComponent';
import { DISPLAY_TRIPS_NAME, getTokens, MAP_PERMISSION_NAME, MAP_PERMISSION_VALUE_ALLOWED } from 'utils';
import { fetchMapConsentPermission, fetchPolicyTrips, sendMapConsentAgreement } from 'services/drivingDataClient';
import { actions } from 'reduxStore';
var DrivingDataPage = function (_a) {
    var currentPolicyIndex = _a.currentPolicyIndex, currentPolicy = _a.currentPolicy, policyIds = _a.policyIds, policies = _a.policies, refresh = _a.refresh, setRefreshPolicy = _a.setRefreshPolicy;
    var accessToken = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
    var refreshToken = sessionStorage.getItem(SessionStorageKey.REFRESH_TOKEN);
    var _b = useState(0), totalPages = _b[0], setTotalPages = _b[1];
    var _c = useState(moment().format('yyyy-MM')), monthForJourney = _c[0], setMonthForJourney = _c[1];
    var _d = useState(undefined), contentSections = _d[0], setContentSections = _d[1];
    var _e = useState(undefined), headerContent = _e[0], setHeaderContent = _e[1];
    var _f = useState(undefined), milesWidgetData = _f[0], setMilesWidgetData = _f[1];
    var _g = useState(undefined), journeys = _g[0], setJourneys = _g[1];
    var _h = useState(undefined), latestJourney = _h[0], setLatestJourney = _h[1];
    var _j = useState(undefined), bonusMilesData = _j[0], setBonusMilesData = _j[1];
    var _k = useState(undefined), drivingSummaries = _k[0], setDrivingSummaries = _k[1];
    var _l = useState(undefined), monthlyMiles = _l[0], setMonthlyMiles = _l[1];
    var _m = useState(undefined), dailyMiles = _m[0], setDailyMiles = _m[1];
    var _o = useState(''), currentMonth = _o[0], setCurrentMonth = _o[1];
    var _p = useState(undefined), scores = _p[0], setScores = _p[1];
    var _q = useState(['', '']), scoresDatePeriod = _q[0], setScoresDatePeriod = _q[1];
    var _r = useState(false), mapDisplayAllowed = _r[0], setMapDisplayAllowed = _r[1];
    var _s = useState(false), tripsDisplayAllowed = _s[0], setTripsDisplayAllowed = _s[1];
    var mapToDrivingScores = function (driverScores) {
        var keys = Object.keys(driverScores).filter(function (key) { return key !== 'bonusMilesId'; });
        return keys.map(function (key) { return ({
            category: key,
            score: key === 'timeOfDay' ? parseInt(driverScores[key], 10) : driverScores[key],
        }); });
    };
    var fetchDrivingScoresCallback = function (drivingScoresResult) {
        if (drivingScoresResult && drivingScoresResult.driverScores) {
            var driverScores = drivingScoresResult.driverScores;
            setScores(mapToDrivingScores(driverScores[0]));
        }
    };
    var fetchBonusMilesCallback = function (bonusMilesResponse) {
        var _a;
        var emptyBonusMilesObject = [
            {
                year: '',
                data: [],
                yearlyMiles: 0,
                effective: '',
                expiration: '',
            },
        ];
        if (bonusMilesResponse) {
            var availableMiles = bonusMilesResponse.availableMiles, consumedMiles = bonusMilesResponse.consumedMiles, bonusMiles = bonusMilesResponse.bonusMiles;
            if (bonusMiles && bonusMiles.yearlyAddedMiles && ((_a = bonusMiles.yearlyAddedMiles) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                var bonusMilesData_1 = createBonusMilesData(bonusMiles, policies[currentPolicyIndex].periods);
                setBonusMilesData(bonusMilesData_1);
                var milesDataForWidget = [
                    {
                        key: 'rollover',
                        value: consumedMiles + availableMiles - currentPolicy.productMiles - bonusMilesData_1[0].yearlyMiles,
                    },
                    {
                        key: 'policy',
                        value: currentPolicy.productMiles,
                    },
                    {
                        key: 'driven',
                        value: consumedMiles,
                    },
                    {
                        key: 'remaining',
                        value: availableMiles,
                        breakdown: [
                            {
                                key: 'bonus',
                                value: bonusMilesData_1[0].yearlyMiles,
                            },
                        ],
                    },
                ];
                setMilesWidgetData(milesDataForWidget);
                var yearlyAddedMiles = bonusMiles.yearlyAddedMiles;
                var bonusMilesForLatestYear = yearlyAddedMiles[0];
                var monthlyAddedMiles = bonusMilesForLatestYear.monthlyAddedMiles;
                var bonusMilesForLatestMonth = monthlyAddedMiles[0];
                setScoresDatePeriod([
                    getFriendlyDisplayDate(bonusMilesForLatestMonth.from),
                    getFriendlyDisplayDate(bonusMilesForLatestMonth.to),
                ]);
                if (accessToken && refreshToken) {
                    drivingDataClient.fetchDrivingScores(policyIds[currentPolicyIndex], bonusMilesForLatestMonth.id.toString(), accessToken, refreshToken, fetchDrivingScoresCallback);
                }
            }
            else {
                setBonusMilesData(emptyBonusMilesObject);
                var milesDataForWidget = [
                    {
                        key: 'rollover',
                        value: consumedMiles + availableMiles - currentPolicy.productMiles,
                    },
                    {
                        key: 'policy',
                        value: currentPolicy.productMiles,
                    },
                    {
                        key: 'driven',
                        value: consumedMiles,
                    },
                    {
                        key: 'remaining',
                        value: availableMiles,
                        breakdown: [
                            {
                                key: 'bonus',
                                value: 0,
                            },
                        ],
                    },
                ];
                setMilesWidgetData(milesDataForWidget);
                setScoresDatePeriod(['', '']);
                setScores(undefined);
            }
        }
    };
    var fetchDailyMilesCallback = function (year, month, monthlySummaryPerDay) {
        if (monthlySummaryPerDay) {
            setDailyMiles(mapToDailyMiles(monthlySummaryPerDay));
            setCurrentMonth(moment(new Date(year, month - 1)).format('MMMM, YYYY'));
        }
    };
    var fetchMilesSummariesCallback = function (milesSummaries) {
        if (milesSummaries && Object.keys(milesSummaries).length !== 0) {
            setDrivingSummaries(mapToJourneySummaries(milesSummaries));
            var monthlyMiles_1 = mapToMonthlyMiles(milesSummaries);
            var _a = getMonthAndYearFromString(monthlyMiles_1[monthlyMiles_1.length - 1].month), year = _a.year, month = _a.month;
            setMonthlyMiles(monthlyMiles_1);
            if (accessToken && refreshToken) {
                drivingDataClient.fetchDailyMilesForMonth(policyIds[currentPolicyIndex], year, month, accessToken, refreshToken, fetchDailyMilesCallback);
            }
        }
    };
    var fetchTripsCallback = function (isLatest, data) {
        var pagination = data.pagination, trips = data.trips;
        var journeys = mapToJourneys(trips);
        if (isLatest) {
            setLatestJourney(journeys[0]);
        }
        else {
            setJourneys(journeys);
            var pages = pagination.pages;
            setTotalPages(pages);
        }
    };
    var fetchPolicyTripsCallback = function (data) {
        setJourneys(mapPolicyTripsToJourneys(data));
        setTotalPages(Math.ceil(data.totalElements / 6));
    };
    var fetchMapPermissionCallback = function (behaviourValue) {
        setMapDisplayAllowed(behaviourValue === MAP_PERMISSION_VALUE_ALLOWED ? true : false);
    };
    var fetchTripsPermissionCallback = function (behaviourValue) {
        var shouldShowTrips = behaviourValue.toUpperCase() === 'TRUE' ? true : false;
        setTripsDisplayAllowed(shouldShowTrips);
        var tokens = getTokens();
        if (!shouldShowTrips && tokens) {
            var accessToken_1 = tokens.accessToken, refreshToken_1 = tokens.refreshToken;
            var date = new Date();
            fetchPolicyTrips(policyIds[currentPolicyIndex], 0, date.getFullYear(), date.getMonth() + 1, accessToken_1, refreshToken_1, fetchPolicyTripsCallback);
        }
        if (shouldShowTrips && tokens) {
            var accessToken_2 = tokens.accessToken, refreshToken_2 = tokens.refreshToken;
            drivingDataClient.fetchTrips(policyIds[currentPolicyIndex], 1, 6, moment()
                .startOf('month')
                .format('YYYY-MM-DDT00:00:00') + "Z", moment()
                .endOf('month')
                .format('YYYY-MM-DDT23:59:59') + "Z", accessToken_2, refreshToken_2, fetchTripsCallback);
            drivingDataClient.fetchTrips(policyIds[currentPolicyIndex], 1, 1, moment()
                .subtract(1, 'year')
                .format('YYYY-MM-DDT00:00:00') + "Z", moment().format('YYYY-MM-DDT00:00:00') + "Z", accessToken_2, refreshToken_2, fetchTripsCallback);
            fetchMapConsentPermission(policyIds[currentPolicyIndex], MAP_PERMISSION_NAME, tokens.accessToken, tokens.refreshToken, fetchMapPermissionCallback);
        }
    };
    var onMonthlySummarySelected = function (monthString) {
        var _a = getMonthAndYearFromString(monthString), month = _a.month, year = _a.year;
        if (accessToken && refreshToken) {
            drivingDataClient.fetchDailyMilesForMonth(policyIds[currentPolicyIndex], year, month, accessToken, refreshToken, fetchDailyMilesCallback);
        }
    };
    var onMonthlyMilesSelectedById = function (from, to, bonusMilesId) {
        if (accessToken && refreshToken) {
            setScoresDatePeriod([getFriendlyDisplayDate(from), getFriendlyDisplayDate(to)]);
            drivingDataClient.fetchDrivingScores(policyIds[currentPolicyIndex], bonusMilesId.toString(), accessToken, refreshToken, fetchDrivingScoresCallback);
        }
    };
    useEffect(function () {
        getPageContent(ContentPageURL.DRIVING_DATA_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200) {
                setContentSections(payload === null || payload === void 0 ? void 0 : payload.contentSections);
                setHeaderContent(payload === null || payload === void 0 ? void 0 : payload.header);
            }
            else {
                console.log('Error fetching driving data page content | status code ', status);
            }
        });
        if (accessToken && refreshToken && policyIds && currentPolicy) {
            fetchDrivingData(policyIds[currentPolicyIndex], accessToken, refreshToken, fetchBonusMilesCallback, fetchMilesSummariesCallback);
            fetchMapConsentPermission(policyIds[currentPolicyIndex], DISPLAY_TRIPS_NAME, accessToken, refreshToken, fetchTripsPermissionCallback);
        }
        if (refresh === true) {
            setRefreshPolicy();
            history.go(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPolicyIndex, currentPolicy, policyIds, policies]);
    var renderSection = function (section) {
        var tokens = getTokens();
        if ('widgetLabel' in section && milesWidgetData) {
            return (React.createElement(MilesWidgetSection, { key: "mileswidget", milesData: milesWidgetData, milesWidgetContent: section }));
        }
        if ('journeyHeaders' in section && currentPolicy) {
            return (React.createElement(MyJourneysSection, { key: "myjourney", mapDisplayAllowed: mapDisplayAllowed, tripsDisplayAllowed: tripsDisplayAllowed, singleDriver: currentPolicy.drivers.length === 1, cmsContent: section, latestJourney: latestJourney, previousJourneys: journeys, summaries: drivingSummaries, dailyMiles: dailyMiles, monthlyMiles: monthlyMiles, monthName: currentMonth, totalPages: totalPages, onAgreeMapConsent: function () {
                    if (tokens) {
                        sendMapConsentAgreement(policyIds[currentPolicyIndex], MAP_PERMISSION_NAME, MAP_PERMISSION_VALUE_ALLOWED, tokens.accessToken, tokens.refreshToken, function () { return setMapDisplayAllowed(true); });
                    }
                }, onMonthlySummarySelected: onMonthlySummarySelected, onMonthlyJourneySelected: function (month) {
                    if (tokens) {
                        setMonthForJourney(month);
                        var accessToken_3 = tokens.accessToken, refreshToken_3 = tokens.refreshToken;
                        if (tripsDisplayAllowed) {
                            fetchTripsByPage(policyIds[currentPolicyIndex], accessToken_3, refreshToken_3, month, 1, fetchTripsCallback);
                        }
                        else {
                            fetchPolicyTrips(policyIds[currentPolicyIndex], 0, new Date(month).getFullYear(), new Date(month).getMonth() + 1, accessToken_3, refreshToken_3, fetchPolicyTripsCallback);
                        }
                    }
                }, onPageSelect: function (page) {
                    if (tokens) {
                        var accessToken_4 = tokens.accessToken, refreshToken_4 = tokens.refreshToken;
                        if (tripsDisplayAllowed) {
                            fetchTripsByPage(policyIds[currentPolicyIndex], accessToken_4, refreshToken_4, monthForJourney, page, fetchTripsCallback);
                        }
                        else {
                            fetchPolicyTrips(policyIds[currentPolicyIndex], page - 1, new Date(monthForJourney).getFullYear(), new Date(monthForJourney).getMonth() + 1, accessToken_4, refreshToken_4, fetchPolicyTripsCallback);
                        }
                    }
                } }));
        }
        if ('currentMonthMilesText' in section && bonusMilesData && policies) {
            return (React.createElement(BonusMilesSection, { key: "bonusmiles", hideButton: true, cmsContent: section, milesData: bonusMilesData, onMonthlyMilesSelectedById: onMonthlyMilesSelectedById }));
        }
        if ('evaluations' in section && scores) {
            return (React.createElement(DrivingScoresSection, { key: "drivingscores", cmsContent: section, dateStart: scoresDatePeriod[0], dateEnd: scoresDatePeriod[1], scores: scores }));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: headerContent }),
        contentSections && contentSections.map(function (content) { return renderSection(content); })));
};
var mapStateToProps = function (state) { return ({
    currentPolicyIndex: state.currentPolicyIndex,
    currentPolicy: state.currentPolicy,
    policies: state.policySummaries,
    policyIds: state.policyIds,
    refresh: state.refresh,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRefreshPolicy: function () { return dispatch(actions.setRefreshPolicy(false)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(DrivingDataPage);
