var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
import { FormDateYearMonthOnly, FormInput, FormRadio, FormSelect, getSingleFieldContent, Group } from '../Form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var VehicleFormDetails = function (_a) {
    var sectionOne = _a.sectionOne, sectionTwo = _a.sectionTwo, control = _a.control, register = _a.register, watchedFields = _a.watchedFields, errors = _a.errors;
    var purchasedDate = getSingleFieldContent('purchasedDate', sectionOne.fields);
    var marketValue = getSingleFieldContent('marketValue', sectionOne.fields);
    var hasBeenModified = getSingleFieldContent('hasBeenModified', sectionOne.fields);
    var modification = getSingleFieldContent('modification', sectionOne.fields);
    var modificationOptions = getGwDropdownValues(modification.gwTypelist);
    var securityDevices = getSingleFieldContent('securityDevices', sectionOne.fields);
    var securityDevicesOptions = getGwDropdownValues(securityDevices.gwTypelist);
    var nightTimeParking = getSingleFieldContent('nightTimeParking', sectionOne.fields);
    var nightTimeParkingOptions = getGwDropdownValues(nightTimeParking.gwTypelist);
    var dayTimeParking = getSingleFieldContent('dayTimeParking', sectionOne.fields);
    var dayTimeParkingOptions = getGwDropdownValues(dayTimeParking.gwTypelist);
    var annualMileage = getSingleFieldContent('annualMileage', sectionOne.fields);
    var classOfUse = getSingleFieldContent('classOfUse', sectionOne.fields);
    var classOfUseOptions = getGwDropdownValues(classOfUse.gwTypelist);
    var imported = getSingleFieldContent('imported', sectionOne.fields);
    var registeredKeeper = getSingleFieldContent('registeredKeeper', sectionTwo.fields);
    var securityTracker = getSingleFieldContent('securityTracker', sectionTwo.fields);
    var rightHandDrive = getSingleFieldContent('rightHandDrive', sectionTwo.fields);
    var lessThanEightSeats = getSingleFieldContent('lessThanEightSeats', sectionTwo.fields);
    return (React.createElement(Root, null,
        React.createElement(Group, null,
            React.createElement(FormDateYearMonthOnly, { label: purchasedDate.label, name: purchasedDate.value, toolTip: purchasedDate.toolTip, control: control, additionalToTooltip: purchasedDate.additionalToTooltip, isRequired: purchasedDate.required, error: errors[purchasedDate.value] }),
            React.createElement(FormInput, { label: marketValue.label, name: marketValue.value, toolTip: marketValue.toolTip, control: register, additionalToTooltip: marketValue.additionalToTooltip, type: "number", isRequired: marketValue.required, error: errors[marketValue.value] }),
            React.createElement(FormRadio, { label: hasBeenModified.label, name: hasBeenModified.value, toolTip: hasBeenModified.toolTip, options: hasBeenModified.options, control: control, additionalToTooltip: hasBeenModified.additionalToTooltip, isRequired: hasBeenModified.required, error: errors[hasBeenModified.value] }),
            watchedFields.hasBeenModified === 'Yes' && (React.createElement(FormSelect, { label: modification.label, options: modificationOptions, toolTip: modification.toolTip, name: modification.value, control: control, additionalToTooltip: modification.additionalToTooltip, isRequired: modification.required, error: errors[modification.value] })),
            React.createElement(FormSelect, { label: securityDevices.label, options: securityDevicesOptions, toolTip: securityDevices.toolTip, name: securityDevices.value, control: control, additionalToTooltip: securityDevices.additionalToTooltip, isRequired: securityDevices.required, error: errors[securityDevices.value] }),
            React.createElement(FormSelect, { label: nightTimeParking.label, options: nightTimeParkingOptions, toolTip: nightTimeParking.toolTip, name: nightTimeParking.value, control: control, additionalToTooltip: nightTimeParking.additionalToTooltip, isRequired: nightTimeParking.required, error: errors[nightTimeParking.value] }),
            React.createElement(FormSelect, { label: dayTimeParking.label, options: dayTimeParkingOptions, toolTip: dayTimeParking.toolTip, name: dayTimeParking.value, control: control, additionalToTooltip: dayTimeParking.additionalToTooltip, isRequired: dayTimeParking.required, error: errors[dayTimeParking.value] }),
            React.createElement(FormInput, { label: annualMileage.label, name: annualMileage.value, toolTip: annualMileage.toolTip, control: register, additionalToTooltip: annualMileage.additionalToTooltip, isRequired: annualMileage.required, error: errors[annualMileage.value], type: "number" }),
            React.createElement(FormSelect, { label: classOfUse.label, options: classOfUseOptions, toolTip: classOfUse.toolTip, name: classOfUse.value, control: control, additionalToTooltip: classOfUse.additionalToTooltip, spanTwoCols: classOfUse.spanTwoColumns, isRequired: classOfUse.required, error: errors[classOfUse.value] }),
            React.createElement(FormRadio, { label: imported.label, name: imported.value, toolTip: imported.toolTip, options: imported.options, control: control, additionalToTooltip: imported.additionalToTooltip, isRequired: imported.required, error: errors[imported.value] })),
        React.createElement(Group, null,
            React.createElement(FormRadio, { label: registeredKeeper.label, name: registeredKeeper.value, toolTip: registeredKeeper.toolTip, options: registeredKeeper.options, control: control, additionalToTooltip: registeredKeeper.additionalToTooltip, isRequired: registeredKeeper.required, error: errors[registeredKeeper.value] }),
            React.createElement(FormRadio, { label: securityTracker.label, name: securityTracker.value, toolTip: securityTracker.toolTip, options: securityTracker.options, control: control, additionalToTooltip: securityTracker.additionalToTooltip, isRequired: securityTracker.required, error: errors[securityTracker.value] }),
            React.createElement(FormRadio, { label: rightHandDrive.label, name: rightHandDrive.value, toolTip: rightHandDrive.toolTip, options: rightHandDrive.options, control: control, additionalToTooltip: rightHandDrive.additionalToTooltip, isRequired: rightHandDrive.required, error: errors[rightHandDrive.value] }),
            React.createElement(FormRadio, { label: lessThanEightSeats.label, name: lessThanEightSeats.value, toolTip: lessThanEightSeats.toolTip, options: lessThanEightSeats.options, control: control, additionalToTooltip: lessThanEightSeats.additionalToTooltip, isRequired: lessThanEightSeats.required, error: errors[lessThanEightSeats.value] }))));
};
export default VehicleFormDetails;
var templateObject_1;
