var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import OverviewSection from 'components/organisms/OverviewSection';
import SagePayIframe from 'components/organisms/SagePayIframe';
import TopupMiles from 'components/organisms/TopupMiles';
import { drivingDataClient, getHelpfulInformationContent, getPageContent, fetchSagePay, fetchSavedQuotes, fetchQuotePortalDomain, bindPayment, } from 'services';
import { ContentPageURL, } from 'types';
import { getTokens } from 'utils';
import HelpfulInformation from 'components/molecules/HelpfulInformation';
import ShareComponent from 'pages/ShareComponent';
var TopupMilesPage = function (_a) {
    var currentPolicy = _a.currentPolicy, currentPolicyIndex = _a.currentPolicyIndex, policyIds = _a.policyIds, topupId = _a.topupId, setTopupId = _a.setTopupId;
    var history = useHistory();
    var _b = useState(undefined), contentSections = _b[0], setContentSections = _b[1];
    var _c = useState(0), remainingMiles = _c[0], setRemaingMiles = _c[1];
    var _d = useState(0), consumedMiles = _d[0], setConsumedMiles = _d[1];
    var _e = useState(undefined), milesBundles = _e[0], setMilesBundles = _e[1];
    var _f = useState(undefined), priceResult = _f[0], setPriceResult = _f[1];
    var _g = useState(undefined), paymentId = _g[0], setPaymentId = _g[1];
    var _h = useState(''), sagePayLink = _h[0], setSagePayLink = _h[1];
    var _j = useState(undefined), savedQuotes = _j[0], setSavedQuotes = _j[1];
    var _k = useState(''), quotePortalUrl = _k[0], setQuotePortalUrl = _k[1];
    var _l = useState(undefined), helpfulInfoContent = _l[0], setHelpfulInfoContent = _l[1];
    var fetchMilesBundlesCallback = function (bundles) {
        if (bundles) {
            setMilesBundles(bundles.map(function (bundle) { return ({
                miles: bundle.milesRequested,
                cost: bundle.costOfMilesRequested.amount,
            }); }));
        }
    };
    var fetchMilesCostCallback = function (milesCost) {
        if (milesCost && milesCost.length > 0) {
            setPriceResult([milesCost[0].milesRequested, milesCost[0].costOfMilesRequested.amount]);
        }
    };
    var clearSliderPriceResult = function () { return setPriceResult(undefined); };
    var fetchMilesCost = function (milesToRequest) {
        var tokens = getTokens();
        if (tokens) {
            drivingDataClient.fetchMilesCost(policyIds[currentPolicyIndex], milesToRequest, tokens.accessToken, tokens.refreshToken, fetchMilesCostCallback);
        }
    };
    var addTopupMilesCallback = function (jobId) { return setPaymentId(jobId); };
    var fetchBonusMilesCallback = function (bonusMilesResponse) {
        if (bonusMilesResponse) {
            var availableMiles = bonusMilesResponse.availableMiles, consumedMiles_1 = bonusMilesResponse.consumedMiles;
            setRemaingMiles(availableMiles);
            setConsumedMiles(consumedMiles_1);
        }
    };
    var addTopupMiles = function (miles) {
        var tokens = getTokens();
        if (tokens && policyIds) {
            drivingDataClient.addPolicyMiles(policyIds[currentPolicyIndex], miles, tokens.accessToken, tokens.refreshToken, addTopupMilesCallback);
        }
    };
    var bindPaymentToPolicy = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var tokens, useExistingCard;
        return __generator(this, function (_a) {
            tokens = getTokens();
            if (tokens) {
                useExistingCard = { reuseExistingCard_itb: false };
                bindPayment(id, useExistingCard, tokens.accessToken, tokens.refreshToken, function () {
                    history.push('/accepted');
                    setTopupId(undefined);
                }, '/topupmiles');
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var tokens = getTokens();
        getPageContent(ContentPageURL.TOPUP_MILES_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200) {
                setContentSections(payload === null || payload === void 0 ? void 0 : payload.contentSections);
            }
            else {
                console.log('Error fetching my policy page content | status code ', status);
            }
        });
        if (tokens && policyIds) {
            var accessToken = tokens.accessToken, refreshToken = tokens.refreshToken;
            drivingDataClient.fetchMilesBundles(policyIds[currentPolicyIndex], accessToken, refreshToken, fetchMilesBundlesCallback);
            drivingDataClient.fetchBonusMiles(policyIds[currentPolicyIndex], accessToken, refreshToken, fetchBonusMilesCallback);
            if (!savedQuotes) {
                fetchSavedQuotes(accessToken, refreshToken, function (quotes) { return setSavedQuotes(quotes); });
            }
            if (quotePortalUrl === '') {
                fetchQuotePortalDomain(accessToken, refreshToken, function (url) { return setQuotePortalUrl(url); });
            }
        }
        if (helpfulInfoContent === undefined) {
            getHelpfulInformationContent().then(function (response) { return setHelpfulInfoContent(response.data); });
        }
        var handler = function (event) {
            try {
                var data = JSON.parse(event.data);
                if (data.message === 'success') {
                    if (topupId !== undefined)
                        bindPaymentToPolicy(topupId);
                }
                if (data.message === 'failure')
                    history.push('/error');
                if (data.message === 'cancelled')
                    history.go(0);
            }
            catch (error) {
                //
            }
        };
        if (location.pathname === '/topupmiles') {
            window.addEventListener('message', handler);
        }
    }, [currentPolicy, policyIds, currentPolicyIndex, topupId]);
    var renderOverviewSection = function (section) {
        var displayName = currentPolicy.vehicleInfo.displayName || '()';
        var carplate = displayName.substring(displayName.lastIndexOf('(') + 1, displayName.lastIndexOf(')'));
        return (React.createElement(OverviewSection, { key: "overview", carplate: carplate, content: section, remainingMiles: remainingMiles, consumedMiles: consumedMiles }));
    };
    var renderTopupMilesSection = function (section, milesBundles) {
        return (React.createElement(TopupMiles, { remainingMiles: remainingMiles, content: section, data: milesBundles, priceResult: priceResult, onConfirm: function (miles) { return addTopupMiles(miles); }, onChangeCommitted: function (miles) { return fetchMilesCost(miles); }, onBundleSelected: function (miles) { return addTopupMiles(miles); }, toPayment: function () {
                setTopupId(paymentId);
                var tokens = getTokens();
                if (tokens && paymentId) {
                    var obj = {
                        billingAddress: currentPolicy.policyHolder.primaryAddress,
                        paymentDetails: {
                            creditCardData: {
                                isCardRegisteredToYou: true,
                            },
                            reuseExistingCard_itb: false,
                        },
                        applicationType: 'SFE_ITB',
                    };
                    fetchSagePay(paymentId, obj, tokens.accessToken, tokens.refreshToken, function (link) { return setSagePayLink(link); }, '/topupmiles');
                }
            }, toMyPolicy: function () { return history.push('/mypolicy'); }, paymentButtonDisabled: paymentId === undefined, clearSliderPriceResult: clearSliderPriceResult, paymentId: paymentId, resetPaymentId: setPaymentId }));
    };
    return (React.createElement(React.Fragment, null,
        sagePayLink === '' && (React.createElement(React.Fragment, null,
            React.createElement(ShareComponent, { headerContent: {
                    title: 'Top Up Miles',
                    body: 'Top Up Miles can be bought by selecting the number of miles required or by specifying how much you would like to spend. You can also select one of the bundles at the indicated prices.',
                    hidePolicySelect: true,
                } }),
            contentSections &&
                currentPolicy &&
                renderOverviewSection(contentSections.find(function (section) { return 'remainingMilesContent' in section; })),
            milesBundles &&
                contentSections &&
                renderTopupMilesSection(contentSections.find(function (section) { return 'paymentButtonText' in section; }), milesBundles),
            helpfulInfoContent && React.createElement(HelpfulInformation, { content: helpfulInfoContent[0] }))),
        sagePayLink !== '' && React.createElement(SagePayIframe, { sagePayLink: sagePayLink })));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    renewalPolicy: state.renewalPolicy,
    currentPolicyIndex: state.currentPolicyIndex,
    policyIds: state.policyIds,
    covered: state.currentlyCovered,
    accountNumber: state.accountNumber,
    topupId: state.topupId,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setTopupId: function (id) { return dispatch(actions.setTopupId(id)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TopupMilesPage);
