var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import CloseMenuIcon from 'assets/backMenuIcon.svg';
import HomeIcon from 'assets/home.svg';
import LogoutIcon from 'assets/logout.svg';
import AccountIcon from 'assets/account.svg';
import NavigationBar from './NavigationBar';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 15px 15px 25px 15px;\n"], ["\n  padding: 15px 15px 25px 15px;\n"])));
var Icons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var IconWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var LogoutWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  cursor: pointer;\n\n  svg {\n    fill: #ad1f1f;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  cursor: pointer;\n\n  svg {\n    fill: #ad1f1f;\n  }\n"])));
var AccountWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  cursor: pointer;\n\n  svg {\n    fill: rgb(0, 127, 73);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  cursor: pointer;\n\n  svg {\n    fill: rgb(0, 127, 73);\n  }\n"])));
var TextWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin-left: 16px;\n"], ["\n  display: flex;\n  margin-left: 16px;\n"])));
var AccountLogoutWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-evenly;\n"], ["\n  display: flex;\n  justify-content: space-evenly;\n"])));
var MenuView = function (_a) {
    var onClose = _a.onClose, toHome = _a.toHome, logoutText = _a.logoutText, onLogout = _a.onLogout, uri = _a.uri, tabs = _a.tabs, onTabClick = _a.onTabClick, toAccountSettings = _a.toAccountSettings, accountText = _a.accountText, isLapsed = _a.isLapsed;
    return (React.createElement(Root, null,
        React.createElement(Icons, null,
            React.createElement(IconWrapper, { onClick: function () {
                    onClose();
                    toHome();
                } },
                React.createElement(HomeIcon, null)),
            React.createElement(IconWrapper, { onClick: function () { return onClose(); } },
                React.createElement(CloseMenuIcon, null))),
        React.createElement(NavigationBar, { uri: uri, tabs: tabs, onTabClick: onTabClick, onClose: onClose, isLapsed: isLapsed }),
        React.createElement(AccountLogoutWrapper, null,
            isLapsed === false && (React.createElement(AccountWrapper, { onClick: function () {
                    onClose();
                    toAccountSettings();
                } },
                React.createElement(AccountIcon, null),
                React.createElement(TextWrapper, null, accountText))),
            React.createElement(LogoutWrapper, { onClick: function () { return onLogout(); } },
                React.createElement(LogoutIcon, null),
                React.createElement(TextWrapper, null, logoutText)))));
};
export default MenuView;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
