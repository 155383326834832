var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { fontWeight } from 'styles';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import MonthlyPayment from './MonthlyPayment';
import RadioButton from './RadioButton';
import find from 'lodash/find';
import ReactMarkdown from 'react-markdown';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  line-height: 1.63;\n"], ["\n  font-weight: ", ";\n  line-height: 1.63;\n"])), fontWeight.light);
var ParagraphWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ul {\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 8px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  strong {\n    font-weight: ", ";\n  }\n"], ["\n  ul {\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 8px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  strong {\n    font-weight: ", ";\n  }\n"])), fontWeight.medium);
var RadioButtons = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  > * {\n    margin-bottom: 10px;\n  }\n"], ["\n  margin: 30px 0;\n\n  > * {\n    margin-bottom: 10px;\n  }\n"])));
var PaymentSection = function (_a) {
    var content = _a.content, onPaymentSelected = _a.onPaymentSelected, billingID = _a.billingID, selectedPlans = _a.selectedPlans;
    var plans = selectedPlans.plans;
    var annual = find(plans, function (obj) {
        return obj.billingId === 'bc:5';
    });
    var monthly = find(plans, function (obj) {
        return obj.billingId === 'bc:7';
    });
    var paragraph1 = content.paragraph1, options = content.options, monthlyPaymentDetails = content.monthlyPaymentDetails, monthlyPaymentDetailsTitle = content.monthlyPaymentDetailsTitle;
    // local state
    var _b = useState(billingID), payment = _b[0], setPayment = _b[1];
    var onSelect = function (value) {
        onPaymentSelected(value);
        setPayment(value);
    };
    var getCost = function (plan) {
        if (plan === 'bc:5')
            return annual === null || annual === void 0 ? void 0 : annual.total.amount.toFixed(2);
        if (plan === 'bc:7')
            return monthly === null || monthly === void 0 ? void 0 : monthly.installment.amount.toFixed(2);
        return 0;
    };
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(Root, null,
                    React.createElement(ParagraphWrapper, null,
                        React.createElement(ReactMarkdown, { source: paragraph1 })),
                    React.createElement(RadioButtons, null, monthlyPaymentDetails.map(function (option) { return (React.createElement(RadioButton, { key: option.value, selected: payment === option.value, title: React.createElement("div", { style: { fontWeight: fontWeight.light } }, option.label), text: option.text ? (React.createElement("div", { style: { fontWeight: fontWeight.medium, marginLeft: '36px' } }, option.text)) : null, price: getCost(option.value), onSelect: function () { return onSelect(option.value); } })); })),
                    payment === 'bc:7' && monthly && (React.createElement(MonthlyPayment, { monthlyPaymentDetailsTitle: monthlyPaymentDetailsTitle, monthlyPaymentDetails: options, annualPrice: "\u00A3" + monthly.totalPremiumRPT.amount.toFixed(2), creditAmount: "\u00A3" + monthly.creditAmount_itb.amount.toFixed(2), fixedRate: "" + monthly.interestRate_itb.toFixed(2), fee: "\u00A3" + monthly.fees_itb.amount.toFixed(2), monthlyPayment: "\u00A3" + monthly.installment.amount.toFixed(2), totalPayableAmount: "\u00A3" + monthly.total.amount.toFixed(2), apr: monthly.aprPercentage_itb })),
                    monthly && (React.createElement(ParagraphWrapper, null,
                        React.createElement(ReactMarkdown, { source: "- The total cost to you for paying by monthly direct debits is the Transaction fee __\u00A3" + monthly.fees_itb.amount.toFixed(2) + "__ \n\n - Security over your policy is required when choosing the monthly payment option. \n\n - Whilst there is no interest on the loan, the Transaction fee is taken into account in calculating the representative APR. \n\n - This quote is valid for 30 days, unless you change any details including the policy start date, and includes Insurance Premium Tax. The quoted premium only applies for your first year of insurance. Subsequent renewal premiums may be higher. \n\n - We act as a credit broker, and are not a lender.\n              " }))))))));
};
export default PaymentSection;
var templateObject_1, templateObject_2, templateObject_3;
