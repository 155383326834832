var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { PaddingVertical } from 'components/organisms/Layout';
import { media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: ", ";\n"], ["\n  border-bottom: ", ";\n"])), function (props) { return (props.noBorder ? '0' : '1px solid rgba(137, 137, 137, 0.5)'); });
var GroupTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 40px;\n  font-size: 25px;\n  font-weight: 500;\n"], ["\n  margin-bottom: 40px;\n  font-size: 25px;\n  font-weight: 500;\n"])));
var FormGroupFields = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: repeat(1, minmax(0, 1fr));\n\n  ", "\n"], ["\n  display: grid;\n  grid-gap: 20px;\n  grid-template-columns: repeat(1, minmax(0, 1fr));\n\n  ",
    "\n"])), media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    grid-gap: 30px;\n    grid-template-columns: repeat(3, minmax(0, 1fr));\n  "], ["\n    grid-gap: 30px;\n    grid-template-columns: repeat(3, minmax(0, 1fr));\n  "]))));
var FullGroupColumn = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-column: 1/-1;\n\n  h3 {\n    margin-top: 0;\n    font-weight: 500;\n  }\n\n  p {\n    margin-bottom: 10px;\n    line-height: 1.5;\n    letter-spacing: 0.4px;\n    font-weight: 300;\n  }\n\n  strong {\n    font-weight: 500;\n  }\n\n  a {\n    color: #007f49;\n    text-decoration: none;\n  }\n"], ["\n  grid-column: 1/-1;\n\n  h3 {\n    margin-top: 0;\n    font-weight: 500;\n  }\n\n  p {\n    margin-bottom: 10px;\n    line-height: 1.5;\n    letter-spacing: 0.4px;\n    font-weight: 300;\n  }\n\n  strong {\n    font-weight: 500;\n  }\n\n  a {\n    color: #007f49;\n    text-decoration: none;\n  }\n"])));
var Group = function (_a) {
    var children = _a.children, title = _a.title, introText = _a.introText, _b = _a.noBorder, noBorder = _b === void 0 ? false : _b;
    return (React.createElement(Root, { noBorder: noBorder },
        React.createElement(PaddingVertical, null,
            title && React.createElement(GroupTitle, null, title),
            introText && (React.createElement(FullGroupColumn, null,
                React.createElement(ReactMarkdown, { linkTarget: "_blank", source: introText }))),
            children && React.createElement(FormGroupFields, null, children))));
};
export default Group;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
