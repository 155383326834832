var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/molecules/SectionHeader';
import { FormInput, Group } from 'components/organisms/Form';
// border bottom shows when dropdown closed. Open there is already a border on the Group component
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: ", ";\n\n  input {\n    color: #898989;\n  }\n"], ["\n  border-bottom: ", ";\n\n  input {\n    color: #898989;\n  }\n"])), function (props) { return (props.border ? '1px solid rgba(137,137,137,0.5)' : 'none'); });
export var AddressCurrent = function (_a) {
    var content = _a.content, address = _a.address;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(Root, { border: !open },
        React.createElement(SectionHeader, { expandable: true, open: open, title: content.title, onClick: function () { return setOpen(!open); } }),
        open && (React.createElement(Group, null, content.fields.map(function (field) { return (React.createElement(React.Fragment, { key: field.label },
            React.createElement(FormInput, { label: field.label, name: field.value, toolTip: field.toolTip, disabled: true, placeholder: field.placeholder, value: address[field.value] }))); })))));
};
var templateObject_1;
