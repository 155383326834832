var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Progress = styled.svg.attrs({ width: 180, height: 180, viewBox: '0 0 180 180' })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: rotate(-90deg);\n"], ["\n  transform: rotate(-90deg);\n"])));
var InnerCircleMeter = styled.circle.attrs({ cx: 90, cy: 90, r: 44, strokeWidth: 18 })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fill: none;\n  stroke: #f3f3f6;\n"], ["\n  fill: none;\n  stroke: #f3f3f6;\n"])));
var InnerCircleValue = styled.circle.attrs(function (_a) {
    var innerValue = _a.innerValue;
    return ({
        cx: 90,
        cy: 90,
        r: 44,
        strokeWidth: 12,
        strokeDasharray: 276.46,
        strokeDashoffset: 276.46 * (1 - innerValue),
    });
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fill: none;\n  stroke: #52be80;\n  stroke-linecap: round;\n"], ["\n  fill: none;\n  stroke: #52be80;\n  stroke-linecap: round;\n"])));
var OuterCircleMeter = styled.circle.attrs({ cx: 90, cy: 90, r: 70, strokeWidth: 18 })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  fill: none;\n  stroke: #f3f3f6;\n"], ["\n  fill: none;\n  stroke: #f3f3f6;\n"])));
var OuterCircleValue = styled.circle.attrs(function (_a) {
    var outerValue = _a.outerValue;
    return ({
        cx: 90,
        cy: 90,
        r: 70,
        strokeWidth: 12,
        strokeDasharray: 439.822,
        strokeDashoffset: 439.822 * (1 - outerValue),
    });
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fill: none;\n  stroke: #268657;\n  stroke-linecap: round;\n"], ["\n  fill: none;\n  stroke: #268657;\n  stroke-linecap: round;\n"])));
var CircularProgress = function (_a) {
    var innerValue = _a.innerValue, outerValue = _a.outerValue;
    return (React.createElement(Progress, null,
        React.createElement(InnerCircleMeter, null),
        React.createElement(InnerCircleValue, { innerValue: innerValue }),
        React.createElement(OuterCircleMeter, null),
        React.createElement(OuterCircleValue, { outerValue: outerValue })));
};
export default CircularProgress;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
