var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
import { FormRadio, FormSelect, getSingleFieldContent, Group } from 'components/organisms/Form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var DriverDetails = function (_a) {
    var content = _a.content, control = _a.control, fixedValues = _a.fixedValues, errors = _a.errors;
    var licenseType = getSingleFieldContent('licenseType', content.fields);
    var licenseTypeOptions = getGwDropdownValues(licenseType.gwTypelist);
    var testPassed10Years = getSingleFieldContent('testPassed10Years', content.fields);
    var accessToOtherVehicles = getSingleFieldContent('accessToOtherVehicles', content.fields);
    var drivingQualifications = getSingleFieldContent('drivingQualifications', content.fields);
    var drivingQualificationsOptions = getGwDropdownValues(drivingQualifications.gwTypelist);
    var medicalConditions = getSingleFieldContent('medicalConditions', content.fields);
    var medicalConditionsOptions = getGwDropdownValues(medicalConditions.gwTypelist);
    var nonMotoringConvictions = getSingleFieldContent('nonMotoringConvictions', content.fields);
    var cancelledPreviousInsurance = getSingleFieldContent('cancelledPreviousInsurance', content.fields);
    return (React.createElement(Root, null,
        React.createElement(Group, { title: content.title },
            React.createElement(FormSelect, { label: licenseType.label, options: licenseTypeOptions, toolTip: licenseType.toolTip, name: licenseType.value, value: fixedValues.licenseType, disabled: true, readOnly: true }),
            React.createElement(FormRadio, { label: testPassed10Years.label, options: testPassed10Years.options, toolTip: testPassed10Years.toolTip, name: testPassed10Years.value, value: fixedValues.testPassed10Years, disabled: true }),
            React.createElement(FormRadio, { label: accessToOtherVehicles.label, options: accessToOtherVehicles.options, toolTip: accessToOtherVehicles.toolTip, name: accessToOtherVehicles.value, control: control, isRequired: accessToOtherVehicles.required, error: errors[accessToOtherVehicles.value] }),
            React.createElement(FormSelect, { label: drivingQualifications.label, options: drivingQualificationsOptions, toolTip: drivingQualifications.toolTip, name: drivingQualifications.value, control: control, isRequired: drivingQualifications.required, error: errors[drivingQualifications.value] }),
            React.createElement(FormSelect, { label: medicalConditions.label, options: medicalConditionsOptions, toolTip: medicalConditions.toolTip, name: medicalConditions.value, control: control, isRequired: medicalConditions.required, error: errors[medicalConditions.value] }),
            React.createElement(FormRadio, { label: nonMotoringConvictions.label, options: nonMotoringConvictions.options, toolTip: nonMotoringConvictions.toolTip, name: nonMotoringConvictions.value, control: control, isRequired: nonMotoringConvictions.required, error: errors[nonMotoringConvictions.value] }),
            React.createElement(FormRadio, { label: cancelledPreviousInsurance.label, options: cancelledPreviousInsurance.options, toolTip: cancelledPreviousInsurance.toolTip, name: cancelledPreviousInsurance.value, control: control, isRequired: nonMotoringConvictions.required, error: errors[nonMotoringConvictions.value] }))));
};
var templateObject_1;
