var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import Button from 'components/atoms/Button';
import Title from 'components/atoms/Title';
import styled from 'styled-components';
import { FormInput, getSingleFieldContent, Group } from '../Form';
import { AddressLookup } from '../MtaAddress';
import SectionHeader from 'components/molecules/SectionHeader';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var GroupColumnOverride = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
var ButtonWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var RenewalOvernightParking = function (_a) {
    var content = _a.content, register = _a.register, addressLookupContent = _a.addressLookupContent, addressToParent = _a.addressToParent, _b = _a.noBorder, noBorder = _b === void 0 ? false : _b, _c = _a.asDropDown, asDropDown = _c === void 0 ? false : _c, resetAddressChange = _a.resetAddressChange, confirmCorrespondenceChange = _a.confirmCorrespondenceChange, modalContent = _a.modalContent;
    // open address lookup
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    // open content
    var _e = useState(false), openContent = _e[0], setOpenContent = _e[1];
    var _f = useState(undefined), selectedAddress = _f[0], setSelectedAddress = _f[1];
    // const [addressModalOpen, setAddressModalOpen] = useState(false)
    var addressLine1 = getSingleFieldContent('addressLine1', content.fields);
    var city = getSingleFieldContent('city', content.fields);
    var postalCode = getSingleFieldContent('postalCode', content.fields);
    return (React.createElement(Root, null,
        !asDropDown && (React.createElement(TitleWrapper, null,
            React.createElement(Title, null, content.title),
            React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                    setOpen(true);
                    TagManagerArgs('Button', window.location.pathname + " - Renewal Overnight Parking", 'change address');
                } }, "Change address"))),
        asDropDown && (React.createElement(SectionHeader, { title: content.title, expandable: true, open: openContent, onClick: function () { return setOpenContent(!openContent); } })),
        ((!openContent && !asDropDown) || openContent) && (React.createElement(Group, { noBorder: noBorder },
            React.createElement(FormInput, { label: addressLine1.label, name: addressLine1.value, toolTip: addressLine1.toolTip, control: register, readOnly: true }),
            React.createElement(FormInput, { label: city.label, name: city.value, toolTip: city.toolTip, control: register, readOnly: true }),
            React.createElement(FormInput, { label: postalCode.label, name: postalCode.value, toolTip: postalCode.toolTip, control: register, readOnly: true }),
            asDropDown && !open && (React.createElement(ButtonWrapper, null,
                React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                        // TODO: Ticket SFE-1208 will add a modal. Work is already done, wording needs to be agreed
                        // uncomment the bolow line and remove setOpen(true)
                        // setAddressModalOpen(true)
                        setOpen(true);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal Overnight Parking", 'change address');
                    } }, "Change address"))),
            open && (React.createElement(GroupColumnOverride, null,
                React.createElement(AddressLookup, { content: addressLookupContent, addressToParent: function (data) { return setSelectedAddress(data); }, noBorder: true }))),
            open && (React.createElement(ButtonWrapper, null,
                React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                        setSelectedAddress(undefined);
                        resetAddressChange();
                        setOpen(false);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal Overnight Parking", 'Cancel');
                    } }, "Cancel"),
                React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                        addressToParent(selectedAddress);
                        setOpen(false);
                        confirmCorrespondenceChange ? confirmCorrespondenceChange(selectedAddress) : null;
                        TagManagerArgs('Button', window.location.pathname + " - Renewal Overnight Parking", 'confirm address');
                    }, disabled: !selectedAddress }, "Confirm address")))))));
};
export default RenewalOvernightParking;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
