var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SectionHeader from '../SectionHeader';
import ReactMarkdown from 'react-markdown';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var HelpWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 0 15px;\n  box-sizing: border-box;\n\n  * > div {\n    font-size: 14px;\n    font-weight: 400;\n    letter-spacing: 0.5px;\n  }\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 0 15px;\n  box-sizing: border-box;\n\n  * > div {\n    font-size: 14px;\n    font-weight: 400;\n    letter-spacing: 0.5px;\n  }\n"])));
var BodyWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: 300;\n  padding: 20px 0;\n"], ["\n  font-size: 14px;\n  font-weight: 300;\n  padding: 20px 0;\n"])));
var HelpItem = function (_a) {
    var title = _a.title, text = _a.text;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var openHelp = function () { return setOpen(!open); };
    return (React.createElement(Root, null,
        React.createElement(HelpWrapper, null,
            React.createElement(SectionHeader, { open: open, onClick: openHelp, title: title, expandable: true }),
            open && (React.createElement(BodyWrapper, null,
                React.createElement(ReactMarkdown, { linkTarget: "_blank", source: text }))))));
};
export default HelpItem;
var templateObject_1, templateObject_2, templateObject_3;
