var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Motorway from 'assets/motorway.svg';
import NegativeMood from 'assets/mood_negative.svg';
import NeutralMood from 'assets/mood_neutral.svg';
import NoneMotorway from 'assets/none_motorway.svg';
import PositiveMood from 'assets/mood_positive.svg';
import Title from 'components/atoms/Title';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { fontSize, fontWeight } from 'styles';
import DrivingScoreHint from './DrivingScoreHint';
import DrivingScoreRow from './DrivingScoreRow';
import { ScoreIdentifier } from './types';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var IntroTextWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 20px;\n  margin: 20px 0;\n\n  strong {\n    font-weight: ", ";\n  }\n"], ["\n  line-height: 20px;\n  margin: 20px 0;\n\n  strong {\n    font-weight: ", ";\n  }\n"])), fontWeight.bold);
var HintTitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 12px;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 12px;\n"])), fontSize.fs20, fontWeight.medium);
var Hints = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var Rows = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 42px 0;\n"], ["\n  margin: 42px 0;\n"])));
var getIconByScoreText = function (scoreText) {
    switch (scoreText) {
        case ScoreIdentifier.GOOD:
            return React.createElement(PositiveMood, null);
        case ScoreIdentifier.BAD:
            return React.createElement(NegativeMood, null);
        case ScoreIdentifier.NEUTRAL:
            return React.createElement(NeutralMood, null);
        case ScoreIdentifier.MOTORWAY:
            return React.createElement(Motorway, null);
        case ScoreIdentifier.NONEMOTORWAY:
            return React.createElement(NoneMotorway, null);
        default:
            return undefined;
    }
};
var getIconByScoreNumber = function (score) {
    switch (score) {
        case 1:
            return React.createElement(PositiveMood, null);
        case 2:
            return React.createElement(NeutralMood, null);
        case 3:
            return React.createElement(NegativeMood, null);
        default:
            return undefined;
    }
};
var DrivingScoresSection = function (_a) {
    var cmsContent = _a.cmsContent, dateStart = _a.dateStart, dateEnd = _a.dateEnd, scores = _a.scores;
    var sectionTitle = cmsContent.sectionTitle, introText = cmsContent.introText, hintTitle = cmsContent.hintTitle, hints = cmsContent.hints, evaluations = cmsContent.evaluations, tipTitle = cmsContent.tipTitle;
    var categories = evaluations.map(function (item) { return item.category; }).filter(function (category) { return category !== 'noneMotorway'; });
    var getContentForRow = function (category, score) {
        var evaluation = evaluations.filter(function (evaluationContent) { return evaluationContent.category === category; })[0];
        var evaluationText = undefined;
        var tip = undefined;
        var categoryLabel = '';
        if (evaluation) {
            switch (score) {
                case 1:
                    evaluationText = evaluation.textForPositive;
                    tip = evaluation.tipForPositive;
                    break;
                case 3:
                    evaluationText = evaluation.textForNegative;
                    tip = evaluation.tipForNegative;
                    break;
                case 2:
                    evaluationText = evaluation.textForNeutral;
                    tip = evaluation.tipForNeutral;
                    break;
                default:
                    break;
            }
            categoryLabel = evaluation.categoryLabel;
        }
        return { tip: tip, evaluationText: evaluationText, categoryLabel: categoryLabel };
    };
    return (React.createElement(Layout, null,
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(Title, null, sectionTitle + " " + dateStart + " - " + dateEnd),
                    React.createElement(IntroTextWrapper, null,
                        React.createElement(ReactMarkdown, { source: introText })),
                    React.createElement(HintTitleWrapper, null, hintTitle),
                    React.createElement(Hints, null, hints.map(function (hint) { return (React.createElement(DrivingScoreHint, { key: hint.score, icon: getIconByScoreText(hint.score), hint: hint.hint })); })),
                    React.createElement(Rows, null, categories.map(function (category, index) {
                        var score = scores.find(function (item) { return item.category === category; });
                        if (score) {
                            var _a = getContentForRow(score.category, score.score), tip = _a.tip, evaluationText = _a.evaluationText, categoryLabel = _a.categoryLabel;
                            return (React.createElement(DrivingScoreRow, { key: score.category, categoryLabel: categoryLabel, isFirstRow: index === 0, evaluationText: evaluationText, icon: score.category === 'motorway' ? (score.score > 0 ? (React.createElement(Motorway, null)) : (React.createElement(NoneMotorway, null))) : (getIconByScoreNumber(score.score)), tip: tip, tipTitle: tipTitle }));
                        }
                        else {
                            return null;
                        }
                    })))))));
};
export default DrivingScoresSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
