var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { fontSize, fontWeight } from 'styles';
var useStylesBootstrap = makeStyles(function () { return ({
    arrow: {
        color: '#424242',
    },
    tooltip: {
        maxWidth: '100px',
        backgroundColor: '#424242',
        fontSize: fontSize.fs14,
        fontWeight: fontWeight.light,
        padding: '15px',
    },
}); });
var BootstrapTooltip = function (props) {
    var classes = useStylesBootstrap();
    return React.createElement(Tooltip, __assign({ arrow: true, classes: classes }, props));
};
export default BootstrapTooltip;
