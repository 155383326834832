var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import InfoIcon from 'assets/info.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import Row from './Row';
import { color, fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ChatInfoWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.3);\n  padding: 30px 0;\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.3);\n  padding: 30px 0;\n"])));
var ChatIconWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 40px;\n  width: 40px;\n  background-color: ", ";\n  border-radius: 20px;\n  text-align: center;\n\n  svg {\n    fill: white;\n    margin-top: 6px;\n  }\n"], ["\n  height: 40px;\n  width: 40px;\n  background-color: ", ";\n  border-radius: 20px;\n  text-align: center;\n\n  svg {\n    fill: white;\n    margin-top: 6px;\n  }\n"])), color.green);
var CallServiceInfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.3);\n  padding: 20px 0;\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.3);\n  padding: 20px 0;\n"])));
var HelpInfos = function (_a) {
    var chatText = _a.chatText, claimCallIntroText = _a.claimCallIntroText, claimCallText = _a.claimCallText, claimCallNumber = _a.claimCallNumber, repairCallText = _a.repairCallText, repairCallNumber = _a.repairCallNumber, callUsText = _a.callUsText;
    return (React.createElement(Root, null,
        React.createElement(ChatInfoWrapper, null,
            React.createElement(Row, { style: { paddingLeft: 0 }, icon: React.createElement(ChatIconWrapper, null,
                    React.createElement(ArrowUpIcon, null)), text: chatText, textStyle: { fontWeight: fontWeight.light } })),
        React.createElement(CallServiceInfoWrapper, null,
            React.createElement(Row, { icon: React.createElement(InfoIcon, { style: { marginBottom: '10px' } }), style: { marginBottom: '10px' }, text: callUsText, textStyle: { fontWeight: fontWeight.light } })),
        React.createElement(CallServiceInfoWrapper, null,
            React.createElement(Row, { style: { marginBottom: '20px' }, text: claimCallIntroText, textStyle: { fontWeight: fontWeight.light } }),
            React.createElement(Row, { icon: React.createElement(InfoIcon, null), style: { marginBottom: '10px' }, text: claimCallText, textStyle: { fontWeight: fontWeight.light } }),
            React.createElement(Row, { style: { marginBottom: '20px' }, text: claimCallNumber }),
            React.createElement(Row, { icon: React.createElement(InfoIcon, null), style: { marginBottom: '10px' }, text: repairCallText, textStyle: { fontWeight: fontWeight.light } }),
            React.createElement(Row, { style: { marginBottom: '20px' }, text: repairCallNumber }))));
};
export default HelpInfos;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
