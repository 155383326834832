export var QuoteStatus;
(function (QuoteStatus) {
    QuoteStatus["DRAFT"] = "Draft";
    QuoteStatus["QUOTED"] = "Quoted";
})(QuoteStatus || (QuoteStatus = {}));
export var QuoteActionName;
(function (QuoteActionName) {
    QuoteActionName["AMEND_DETAILS"] = "AMEND_DETAILS";
    QuoteActionName["BUY_QUOTE"] = "BUY_QUOTE";
    QuoteActionName["RESUME_QUOTE"] = "RESUME_QUOTE";
    QuoteActionName["NEW_QUOTE"] = "NEW_QUOTE";
})(QuoteActionName || (QuoteActionName = {}));
