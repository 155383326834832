var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SubSectionHeader from 'components/molecules/SubSectionHeader';
import ListRow from 'components/molecules/ListRow';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var DropDownWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  padding: 15px 0;\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  padding: 15px 0;\n"])));
var MileageText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 16px 30px;\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  padding: 16px 30px;\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"])));
export var DropDownSection = function (_a) {
    var label = _a.label, list = _a.list, mileageText = _a.mileageText, values = _a.values;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(Root, null,
        React.createElement(DropDownWrapper, { isOpen: open },
            React.createElement(SubSectionHeader, { title: label, open: open, onClick: function () { return setOpen(!open); } })),
        open && list && (React.createElement(React.Fragment, null, list.map(function (item, index) { return (React.createElement(ListRow, { key: item.label, label: item.label, value: values ? values[item.value] : item.value, style: {
                borderBottom: index < list.length - 1 ? '1px solid rgba(137, 137, 137, 0.2)' : '0',
                borderTop: index === 0 ? '1px solid rgba(137, 137, 137, 0.2)' : '0',
                paddingLeft: '30px',
                paddingRight: '30px',
            } })); }))),
        open && mileageText && React.createElement(MileageText, null, mileageText)));
};
var templateObject_1, templateObject_2, templateObject_3;
