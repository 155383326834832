var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border: ", ";\n  background: ", ";\n  cursor: pointer;\n  justify-content: space-between;\n  padding: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  border: ", ";\n  background: ", ";\n  cursor: pointer;\n  justify-content: space-between;\n  padding: 16px;\n"])), function (props) { return (props.selected ? '1px solid rgba(59, 156, 115, 0.5)' : '1px solid rgba(137, 137, 137, 0.2)'); }, function (props) { return (props.selected ? 'rgba(59, 156, 115, 0.07)' : 'white'); });
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var PriceWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var TitleContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 12px;\n"], ["\n  margin-top: 12px;\n"])));
var CircleWrapper = styled.svg.attrs({ width: 20, height: 20, viewBox: '0 0 20 20' })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-right: 16px;\n"], ["\n  margin-right: 16px;\n"])));
var InnerCircle = styled.circle(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  fill: ", ";\n"], ["\n  fill: ", ";\n"])), function (props) { return props.color; });
var OuterCircle = styled.circle(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", ";\n"], ["\n  fill: none;\n  stroke: ", ";\n"])), function (props) { return props.color; });
var RadioButton = function (_a) {
    var selected = _a.selected, title = _a.title, text = _a.text, price = _a.price, onSelect = _a.onSelect;
    return (React.createElement(Root, { selected: selected },
        React.createElement(ContentWrapper, null,
            React.createElement(TitleContainer, null,
                React.createElement(CircleWrapper, { onClick: function () { return onSelect(); } },
                    selected && React.createElement(InnerCircle, { color: "#007f49", r: 5, cx: 10, cy: 10 }),
                    React.createElement(OuterCircle, { color: selected ? '#007f49' : '#898989', r: 9, cx: 10, cy: 10 })),
                title),
            text && React.createElement(TextWrapper, null, text)),
        price && React.createElement(PriceWrapper, null,
            "\u00A3",
            price)));
};
export default RadioButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
