var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { color, fontFamily } from 'styles';
import FormLabel from './FormLabel';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .MuiInput-root {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px rgba(137, 137, 137, 0.3);\n    background-color: ", ";\n    padding: 0 17px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n  }\n\n  .MuiIconButton-edgeEnd {\n    margin-right: 0;\n  }\n\n  .MuiIconButton-root {\n    padding: 5px;\n    opacity: ", ";\n  }\n\n  .MuiInputBase-input {\n    margin: 0;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n"], ["\n  .MuiInput-root {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px rgba(137, 137, 137, 0.3);\n    background-color: ", ";\n    padding: 0 17px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n  }\n\n  .MuiIconButton-edgeEnd {\n    margin-right: 0;\n  }\n\n  .MuiIconButton-root {\n    padding: 5px;\n    opacity: ", ";\n  }\n\n  .MuiInputBase-input {\n    margin: 0;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n"])), function (props) { return (props.disabledStyle ? 'rgba(0,0,0,.05)' : 'white'); }, function (props) { return (props.disabledStyle ? '0.5' : '1'); }, color.black, fontFamily.base);
var FormPassword = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c, _d = _a.value, value = _d === void 0 ? '' : _d, label = _a.label, toolTip = _a.toolTip, name = _a.name, valueToParent = _a.valueToParent, placeHolder = _a.placeHolder;
    var _e = useState({
        password: value,
        showPassword: false,
    }), values = _e[0], setValues = _e[1];
    var handleChange = function (prop) { return function (event) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = event.currentTarget.value, _a)));
        valueToParent(event.currentTarget.value);
    }; };
    var handleClickShowPassword = function () {
        setValues(__assign(__assign({}, values), { showPassword: !values.showPassword }));
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    return (React.createElement(Root, { disabledStyle: disabled },
        React.createElement(FormLabel, { label: label, toolTip: toolTip }),
        React.createElement(Input, { name: name, type: values.showPassword ? 'text' : 'password', value: values.password, onChange: handleChange('password'), endAdornment: React.createElement(InputAdornment, { position: "end" },
                React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: !disabled ? handleClickShowPassword : undefined, onMouseDown: handleMouseDownPassword, edge: "end" }, values.showPassword ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))), fullWidth: true, disabled: disabled, readOnly: readOnly, placeholder: placeHolder })));
};
export default FormPassword;
var templateObject_1;
