var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Title from 'components/atoms/Title';
import ReactMarkdown from 'react-markdown';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import ListRow from 'components/molecules/ListRow';
import DirectDebitInstructions from 'components/organisms/DirectDebitInstructions';
import Button from 'components/atoms/Button';
import { getPageContent, mtaTokenRefresh, validateDDI } from 'services';
import { ContentPageURL, SessionStorageKey } from 'types';
import { TagManagerArgs } from 'analytics';
import moment from 'moment';
import { FormControl } from '@material-ui/core';
import { FormRadio, Group } from 'components/organisms/Form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  h3 {\n    font-weight: 500;\n  }\n\n  strong {\n    font-weight: 500;\n  }\n\n  p {\n    margin-bottom: 10px;\n    font-weight: 300;\n  }\n\n  ul {\n    line-height: 1.5;\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 10px;\n      font-weight: 300;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 8px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n"], ["\n  h3 {\n    font-weight: 500;\n  }\n\n  strong {\n    font-weight: 500;\n  }\n\n  p {\n    margin-bottom: 10px;\n    font-weight: 300;\n  }\n\n  ul {\n    line-height: 1.5;\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 10px;\n      font-weight: 300;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 8px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n"])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 30px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  margin-bottom: 30px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var Seperator = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 30px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  margin: 30px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var SubTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 20px;\n"], ["\n  margin-bottom: 20px;\n"])));
var ListBorder = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n  margin-bottom: 40px;\n  border: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  margin-top: 20px;\n  margin-bottom: 40px;\n  border: 1px solid rgba(137, 137, 137, 0.2);\n"])));
var CheckBoxWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  padding: 13px;\n  background-color: rgba(137, 137, 137, 0.06);\n  border: 1px solid rgba(137, 137, 137, 0.2);\n\n  p {\n    font-size: 14px;\n    font-weight: 300;\n  }\n\n  .MuiIconButton-root {\n    padding: 0;\n    margin-right: 20px;\n  }\n\n  .MuiCheckbox-colorSecondary.Mui-checked {\n    color: green;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  padding: 13px;\n  background-color: rgba(137, 137, 137, 0.06);\n  border: 1px solid rgba(137, 137, 137, 0.2);\n\n  p {\n    font-size: 14px;\n    font-weight: 300;\n  }\n\n  .MuiIconButton-root {\n    padding: 0;\n    margin-right: 20px;\n  }\n\n  .MuiCheckbox-colorSecondary.Mui-checked {\n    color: green;\n    padding: 0;\n  }\n"])));
var ButtonsWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var RenewalDebitDetails = function (_a) {
    var renewalState = _a.renewalState, renewalPolicy = _a.renewalPolicy, quoteDetails = _a.quoteDetails, setAmendedRenewalQuote = _a.setAmendedRenewalQuote, autoRenew = _a.autoRenew;
    var history = useHistory();
    var _b = useState(autoRenew === true ? 'Opt in' : 'Opt out'), radioValue = _b[0], setRadioValue = _b[1];
    var _c = useState(autoRenew), userAutoRenew = _c[0], setUserAutoRenew = _c[1];
    var _d = useState(undefined), cmsContent = _d[0], setCmsContent = _d[1];
    var _e = useState(undefined), legalTitle = _e[0], setLegalTitle = _e[1];
    var _f = useState(undefined), aprBreakdownContent = _f[0], setAprBreakdownContent = _f[1];
    var _g = useState(undefined), interestInformation = _g[0], setInterestInformation = _g[1];
    var _h = useState(undefined), aprCheckboxText = _h[0], setAprCheckboxText = _h[1];
    var _j = useState(undefined), debitDetailsText = _j[0], setDebitDetailsText = _j[1];
    var _k = useState(undefined), bankDetailsText = _k[0], setBankDetailsText = _k[1];
    var _l = useState(undefined), weNeedBankDetails = _l[0], setweNeedBankDetails = _l[1];
    var _m = useState(undefined), instructionsToBank = _m[0], setInstructionsToBank = _m[1];
    var _o = useState(false), checked = _o[0], setChecked = _o[1];
    var _p = useState(undefined), noAdditionalSigniture = _p[0], setNoAdditionalSigniture = _p[1];
    var _q = useForm({
        mode: 'onChange',
    }), register = _q.register, handleSubmit = _q.handleSubmit, errors = _q.errors;
    var getBreakDownListValue = function (value) {
        var apr = renewalState.paymentPlan.selectedPlan;
        if (value === 'interestRate_itb')
            return apr[value] + "%";
        if (value === 'aprPercentage_itb')
            return apr[value];
        return "\u00A3" + apr[value].amount.toFixed(2);
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var address, paymentData, policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    address = quoteDetails.accountHolder.primaryAddress;
                    delete address.changed;
                    paymentData = {
                        selectedQuote: renewalState.paymentPlan.id,
                        selectedPaymentPlan: 'bc:7',
                        billingAddress: address,
                        contactEmail: quoteDetails.bindData.contactEmail,
                        documentationPreference: 'Electronic',
                        continuousAuthorityGranted_itb: false,
                        paymentDetails: {
                            bankAccountData: {
                                bankAccountNumber: data.bankAccountNumber,
                                bankAccountType: 'individual',
                                accountHolderName: data.accountHolderName,
                                sortCode: data.sortCode,
                                isAuthorisedPayment: true,
                            },
                        },
                        applicationType: 'SFE_ITB',
                        autoRenew: userAutoRenew,
                    };
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, validateDDI(policyNumber, paymentData, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        setAmendedRenewalQuote(quote.data);
                        history.push('/renewal-monthly-mandate');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        console.log(500);
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        onSubmit(data);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var replacePlacholderText = function (value) {
        var placeholders = {
            DEBIT_AMOUNT: renewalState.paymentPlan.selectedPlan.installment.amount.toFixed(2),
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/DEBIT_AMOUNT/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    // Date dependant content changes
    // If policy start date is before 04/04/2022 it needs to show different legal text to after this date
    // only applies to DD payments
    var getDirectDebitText = function () {
        // Change to find method
        var before = cmsContent === null || cmsContent === void 0 ? void 0 : cmsContent.contentSections.find(function (item) { return item.sectionId === 'before_legalText'; });
        var after = cmsContent === null || cmsContent === void 0 ? void 0 : cmsContent.contentSections.find(function (item) { return item.sectionId === 'after_legalText'; });
        var original = cmsContent === null || cmsContent === void 0 ? void 0 : cmsContent.contentSections.find(function (item) { return item.sectionId === 'legalText'; });
        var isBefore = moment(renewalPolicy.effective).isBefore('04/04/2022');
        if (isBefore === true) {
            return (before === null || before === void 0 ? void 0 : before.body) || '';
        }
        else if (isBefore === false) {
            return (after === null || after === void 0 ? void 0 : after.body) || '';
        }
        return (original === null || original === void 0 ? void 0 : original.body) || '';
    };
    ////////////////////
    useEffect(function () {
        console.clear();
        if (cmsContent === undefined) {
            getPageContent(ContentPageURL.RENEWAL_PAYMENT_DIRECT_DEBIT_DETAILS).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        }
        if (cmsContent && legalTitle === undefined) {
            cmsContent.contentSections.map(function (item) {
                if (item.sectionId === 'legalTitle')
                    setLegalTitle(item.body);
                if (item.formGroups && item.formGroups[0].formGroupId === 'aprBreakdown') {
                    setAprBreakdownContent(item.formGroups[0]);
                }
                if (item.sectionId === 'interestInformation')
                    setInterestInformation(item.body);
                if (item.sectionId === 'aprCheckbox')
                    setAprCheckboxText(item.body);
                if (item.sectionId === 'debitDetails')
                    setDebitDetailsText(item.body);
                if (item.sectionId === 'bankDetailsAutomaticRenewal')
                    setBankDetailsText(item.body);
                if (item.sectionId === 'weNeedBankDetails')
                    setweNeedBankDetails(item.body);
                if (item.formGroups && item.formGroups[0].formGroupId === 'instructionsToBank') {
                    setInstructionsToBank(item.formGroups[0]);
                }
            });
        }
    }, [cmsContent, quoteDetails]);
    return (React.createElement(Root, null,
        React.createElement(React.Fragment, null,
            React.createElement(RenewalJourneyProgress, { step: 4 }),
            cmsContent && (React.createElement(React.Fragment, null,
                React.createElement(Layout, { withSeperator: true, shadow: true },
                    React.createElement(PaddingHorizontal, null,
                        React.createElement(PaddingVertical, null,
                            React.createElement(TitleWrapper, null,
                                React.createElement(Title, null, legalTitle)),
                            React.createElement(ReactMarkdown, { source: getDirectDebitText() }),
                            React.createElement(Seperator, null),
                            aprBreakdownContent && (React.createElement(React.Fragment, null,
                                React.createElement(SubTitle, null,
                                    React.createElement(Title, null, aprBreakdownContent.title)),
                                React.createElement(ReactMarkdown, { source: aprBreakdownContent.text }),
                                React.createElement(ListBorder, null, aprBreakdownContent.list &&
                                    aprBreakdownContent.list[0].listItems.map(function (item, index) { return (React.createElement(ListRow, { key: item.label, style: {
                                            backgroundColor: 'rgba(137, 137, 137, 0.06)',
                                            borderTop: index > 0 ? '1px solid rgba(137, 137, 137, 0.2)' : '0',
                                        }, label: item.label, value: getBreakDownListValue(item.value) })); })),
                                React.createElement(ReactMarkdown, { source: interestInformation }),
                                React.createElement("div", { style: { border: checked ? 'none' : 'solid 1px red' } },
                                    React.createElement(CheckBoxWrapper, null,
                                        React.createElement(Checkbox, { onChange: function (event) { return setChecked(event.target.checked); } }),
                                        React.createElement(ReactMarkdown, { source: aprCheckboxText })))))))),
                React.createElement(Layout, { withSeperator: true, shadow: true },
                    React.createElement(PaddingHorizontal, null,
                        React.createElement(PaddingVertical, null,
                            React.createElement(ReactMarkdown, { source: debitDetailsText }),
                            React.createElement(Seperator, null),
                            React.createElement(ReactMarkdown, { source: replacePlacholderText(bankDetailsText) }),
                            React.createElement(Group, { noBorder: true },
                                React.createElement(FormControl, null,
                                    React.createElement(FormRadio, { options: ['Opt in', 'Opt out'], value: radioValue, name: "", handleUncontrolled: function (event) {
                                            setRadioValue(event);
                                            setUserAutoRenew(event === 'Opt in' ? true : false);
                                        } }))),
                            React.createElement(ReactMarkdown, { source: replacePlacholderText(weNeedBankDetails) }),
                            React.createElement(Seperator, null),
                            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                                instructionsToBank && instructionsToBank.fields && (React.createElement(DirectDebitInstructions, { title: instructionsToBank.title, fields: instructionsToBank.fields, control: register, noAdditionalSigniture: function (value) {
                                        return setNoAdditionalSigniture(value === 'Yes' ? true : false);
                                    }, showWarning: noAdditionalSigniture, errors: errors })),
                                React.createElement(ButtonsWrapper, null,
                                    React.createElement(Button, { type: "button", buttonStyle: "secondary", onClick: function () {
                                            history.goBack();
                                            TagManagerArgs('Button', "" + window.location.pathname, 'Go back');
                                        } }, "Go back"),
                                    React.createElement(Button, { type: "submit", disabled: !checked || !noAdditionalSigniture, onClick: function () { return TagManagerArgs('Button', "" + window.location.pathname, 'Continue'); } }, "Continue")))))))))));
};
var mapStateToProps = function (state) { return ({
    renewalState: state.renewalState,
    renewalPolicy: state.renewalPolicy,
    quoteDetails: state.renewalAmendedQuote,
    autoRenew: state.autoRenew,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setAmendedRenewalQuote: function (quote) { return dispatch(actions.setAmendedRenewalQuote(quote)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(RenewalDebitDetails);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
