var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: '#f8f8f8';\n  border: 2px solid rgba(137, 137, 137, 0.2);\n  border-radius: 4px;\n  cursor: pointer;\n  margin-bottom: 20px;\n  padding: 20px 10px 30px;\n"], ["\n  background-color: '#f8f8f8';\n  border: 2px solid rgba(137, 137, 137, 0.2);\n  border-radius: 4px;\n  cursor: pointer;\n  margin-bottom: 20px;\n  padding: 20px 10px 30px;\n"])));
var CoverHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  justify-content: space-between;\n  padding-bottom: 20px;\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  justify-content: space-between;\n  padding-bottom: 20px;\n"])));
var CircleWrapper = styled.svg.attrs({ width: 20, height: 20, viewBox: '0 0 20 20' })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-width: 22px;\n  margin-right: 16px;\n"], ["\n  min-width: 22px;\n  margin-right: 16px;\n"])));
var InnerCircle = styled.circle(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  fill: ", ";\n"], ["\n  fill: ", ";\n"])), function (props) { return props.color; });
var OuterCircle = styled.circle(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", ";\n"], ["\n  fill: none;\n  stroke: ", ";\n"])), function (props) { return props.color; });
var TitleContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var SubtitleWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 14px;\n"], ["\n  font-weight: ", ";\n  font-size: 14px;\n"])), fontWeight.light);
var TextWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 12px;\n\n  span {\n    font-weight: ", ";\n  }\n"], ["\n  margin-top: 12px;\n\n  span {\n    font-weight: ", ";\n  }\n"])), fontWeight.medium);
var BenefitWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 20px;\n\n  span {\n    font-weight: ", ";\n  }\n"], ["\n  margin-top: 20px;\n\n  span {\n    font-weight: ", ";\n  }\n"])), fontWeight.medium);
var BreakdownCoverMobile = function (_a) {
    var coverOption = _a.coverOption, benefits = _a.benefits, _b = _a.selected, selected = _b === void 0 ? false : _b, onSelect = _a.onSelect, replacePlacholderText = _a.replacePlacholderText;
    return (React.createElement(Root, { onClick: function () { return onSelect(); } },
        React.createElement(CoverHeader, null,
            React.createElement(TitleContainer, null,
                React.createElement(TitleWrapper, null, coverOption.coverTitle),
                React.createElement(SubtitleWrapper, null, coverOption.coverSubtitle),
                React.createElement(TextWrapper, null,
                    React.createElement(ReactMarkdown, { source: replacePlacholderText(coverOption.priceText) }))),
            React.createElement(CircleWrapper, null,
                selected && React.createElement(InnerCircle, { color: "#007f49", r: 5, cx: 10, cy: 10 }),
                React.createElement(OuterCircle, { color: selected ? '#007f49' : '#898989', r: 9, cx: 10, cy: 10 }))),
        coverOption.coveredBenefits.map(function (benefit) {
            var _a;
            return (React.createElement(BenefitWrapper, { key: benefit },
                React.createElement(ReactMarkdown, { source: ((_a = benefits.find(function (item) { return item.value === benefit; })) === null || _a === void 0 ? void 0 : _a.text) || '' })));
        })));
};
export default BreakdownCoverMobile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
