var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n\n  ", "\n"], ["\n  width: 24px;\n  height: 24px;\n\n  ",
    "\n"])), function (props) {
    return props.onClick && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      cursor: pointer;\n    "], ["\n      cursor: pointer;\n    "])));
});
var Root = styled(function (_a) {
    var localIcon = _a.localIcon;
    return React.createElement(localIcon, {});
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 24px;\n  width: 24px;\n\n  svg {\n    fill: currentColor;\n  }\n"], ["\n  height: 24px;\n  width: 24px;\n\n  svg {\n    fill: currentColor;\n  }\n"])));
var Icon = function (_a) {
    var localIcon = _a.localIcon, onClick = _a.onClick;
    return (React.createElement(Wrapper, { onClick: onClick },
        React.createElement(Root, { localIcon: localIcon })));
};
export default Icon;
var templateObject_1, templateObject_2, templateObject_3;
