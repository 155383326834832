var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import StartIcon from './start_location.svg';
import EndIcon from './end_location.svg';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #ffffffe0;\n  border-radius: 4px;\n  padding: 12px;\n"], ["\n  background: #ffffffe0;\n  border-radius: 4px;\n  padding: 12px;\n"])));
var LegendItemWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-bottom: 16px;\n\n  > * {\n    :last-child {\n      margin-left: 12px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-bottom: 16px;\n\n  > * {\n    :last-child {\n      margin-left: 12px;\n    }\n  }\n"])));
var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var Legend = function () { return (React.createElement(Root, null,
    React.createElement(LegendItemWrapper, null,
        React.createElement(TextWrapper, null, "Start point:"),
        React.createElement(StartIcon, null)),
    React.createElement(LegendItemWrapper, null,
        React.createElement(TextWrapper, null, "End journey point:"),
        React.createElement(EndIcon, null)))); };
export default Legend;
var templateObject_1, templateObject_2, templateObject_3;
