var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import MenuIcon from 'assets/menuIcon.svg';
import PersonIcon from 'assets/add_person.svg';
import LogoutIcon from 'assets/logout.svg';
import HideOn from 'components/atoms/HideOn';
import { color, media } from 'styles';
import ItbLogo from 'assets/itb-logo.svg';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n  justify-content: space-between;\n  padding: 0 15px;\n\n  ", "\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n  justify-content: space-between;\n  padding: 0 15px;\n\n  ",
    "\n\n  ",
    "\n"])), color.black, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", "08;\n  "], ["\n    background-color: ", "08;\n  "])), color.grey), media.large(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0 135px;\n  "], ["\n    padding: 0 135px;\n  "]))));
var LogoWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  svg {\n    height: 45px;\n    width: 45px;\n  }\n\n  ", "\n"], ["\n  cursor: pointer;\n  svg {\n    height: 45px;\n    width: 45px;\n  }\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    svg {\n      height: 90px;\n      width: 90px;\n    }\n  "], ["\n    svg {\n      height: 90px;\n      width: 90px;\n    }\n  "]))));
var MenuWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n\n  svg {\n    fill: white;\n  }\n"], ["\n  cursor: pointer;\n\n  svg {\n    fill: white;\n  }\n"])));
var Items = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n\n  > * {\n    :first-child {\n      margin-right: 48px;\n    }\n  }\n"], ["\n  display: flex;\n\n  > * {\n    :first-child {\n      margin-right: 48px;\n    }\n  }\n"])));
var ItemWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"])));
var AccountWrapper = styled(ItemWrapper)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var LogoutWrapper = styled(ItemWrapper)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  svg {\n    fill: ", ";\n  }\n"], ["\n  svg {\n    fill: ", ";\n  }\n"])), color.red);
var TextWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-left: 12px;\n"], ["\n  margin-left: 12px;\n"])));
var Topbar = function (_a) {
    var toHome = _a.toHome, accountText = _a.accountText, onAccountClick = _a.onAccountClick, logoutText = _a.logoutText, onLogout = _a.onLogout, onMenuClick = _a.onMenuClick, isLapsed = _a.isLapsed;
    return (React.createElement(Root, null,
        React.createElement(LogoWrapper, { onClick: function () { return toHome(); } },
            React.createElement(ItbLogo, null)),
        React.createElement(HideOn, { medium: true },
            React.createElement(MenuWrapper, { onClick: function () { return onMenuClick(); } },
                React.createElement(MenuIcon, null))),
        React.createElement(HideOn, { smallOnly: true },
            React.createElement(Items, null,
                isLapsed === false && (React.createElement(AccountWrapper, { onClick: function () { return onAccountClick(); } },
                    React.createElement(PersonIcon, null),
                    React.createElement(TextWrapper, null, accountText))),
                React.createElement(LogoutWrapper, { onClick: function () { return onLogout(); } },
                    React.createElement(LogoutIcon, null),
                    React.createElement(TextWrapper, null, logoutText))))));
};
export default Topbar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
