var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import ListRow from 'components/molecules/ListRow';
import SubSectionHeader from 'components/molecules/SubSectionHeader';
import { getDriverDisplayValuesForList } from 'guidewirePolicyUtils';
import Button from 'components/atoms/Button';
import { TagManagerArgs } from 'analytics';
var DropdownWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 50px;\n  align-items: center;\n  display: flex;\n  box-sizing: border-box;\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  height: 50px;\n  align-items: center;\n  display: flex;\n  box-sizing: border-box;\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding: 20px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding: 20px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
export var RenewalDriversDetails = function (_a) {
    var data = _a.data, rows = _a.rows, type = _a.type, _b = _a.showRemoveButton, showRemoveButton = _b === void 0 ? false : _b, driverIndex = _a.driverIndex, onRemoveDriver = _a.onRemoveDriver, onEditAddedDriver = _a.onEditAddedDriver, status = _a.status, cancelRemoveDriver = _a.cancelRemoveDriver, setDriverToMain = _a.setDriverToMain, chosenAsMain = _a.chosenAsMain, cancelAdditionalDriverToMain = _a.cancelAdditionalDriverToMain;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var firstName = data.firstName, lastName = data.lastName, make = data.make, model = data.model;
    var display = getDriverDisplayValuesForList(data);
    var title = firstName + " " + lastName;
    if (chosenAsMain)
        title = title + " , (selected to be main driver)";
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownWrapper, null,
            type === 'driver' && (React.createElement(SubSectionHeader, { status: status, title: title, onClick: function () { return setOpen(!open); }, open: open })),
            type === 'car' && React.createElement(SubSectionHeader, { title: make + " " + model, onClick: function () { return setOpen(!open); }, open: open })),
        open && (React.createElement(React.Fragment, null,
            rows.map(function (row) { return (React.createElement(ListRow, { key: row.label, label: row.label, value: display[row.value] || '', style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' } })); }),
            showRemoveButton && (React.createElement(ButtonWrapper, null,
                status !== 'remove' && (React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                        onRemoveDriver(driverIndex);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal driver details", 'Remove');
                    } }, "Remove")),
                status === 'remove' && (React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                        cancelRemoveDriver(driverIndex);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal driver details", 'Cancel Removal');
                    } }, "Cancel removal")),
                setDriverToMain && !chosenAsMain && (React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                        setDriverToMain(driverIndex);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal driver details", 'Set as main driver');
                    } }, "Set as main driver")),
                chosenAsMain && (React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                        cancelAdditionalDriverToMain(driverIndex);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal driver details", 'Cancel main driver');
                    } }, "Cancel main driver")),
                React.createElement(Button, { onClick: function () {
                        onEditAddedDriver(driverIndex);
                        TagManagerArgs('Button', window.location.pathname + " - Renewal Driver", 'Edit');
                    } }, "Edit")))))));
};
var templateObject_1, templateObject_2;
