var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Slider from '@material-ui/core/Slider';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import SectionHeader, { Root as SectionHeaderRoot } from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { color, fontWeight, media } from 'styles';
import MilesBox from './MilesBox';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 22px;\n\n  ", " {\n    color: ", ";\n  }\n\n  ", " {\n    width: 100%;\n\n    ", "\n  }\n"], ["\n  line-height: 22px;\n\n  ", " {\n    color: ", ";\n  }\n\n  ", " {\n    width: 100%;\n\n    ",
    "\n  }\n"])), SectionHeaderRoot, function (props) { return (props.negativeMiles ? color.red : 'inherit'); }, ButtonRoot, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      width: auto;\n      margin: 0 auto;\n    "], ["\n      width: auto;\n      margin: 0 auto;\n    "]))));
var NegativeMilesTextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), color.red);
var AddMilesWidgetWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  justify-content: center;\n\n  ", "\n"], ["\n  justify-content: center;\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 690px;\n    margin: auto;\n  "], ["\n    width: 690px;\n    margin: auto;\n  "]))));
var HintWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 32px;\n  margin-bottom: 32px;\n\n  ", "\n"], ["\n  margin-top: 32px;\n  margin-bottom: 32px;\n\n  ",
    "\n"])), media.medium(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-top: 50px;\n    text-align: center;\n  "], ["\n    margin-top: 50px;\n    text-align: center;\n  "]))));
var CurrentMilesWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 210px;\n  background-color: rgba(137, 137, 137, 0.1);\n  border-radius: 25px;\n  border: solid 1px rgba(137, 137, 137, 0.15);\n  margin: 0 auto 50px auto;\n  padding: 18px 0;\n  text-align: center;\n\n  ", "\n"], ["\n  width: 210px;\n  background-color: rgba(137, 137, 137, 0.1);\n  border-radius: 25px;\n  border: solid 1px rgba(137, 137, 137, 0.15);\n  margin: 0 auto 50px auto;\n  padding: 18px 0;\n  text-align: center;\n\n  ",
    "\n"])), media.medium(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-bottom: 30px;\n  "], ["\n    margin-bottom: 30px;\n  "]))));
var SliderWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 0 32px;\n\n  ", "\n\n  .MuiSlider-rail {\n    height: 10px;\n    background-color: ", "44;\n    border-radius: 5px;\n  }\n\n  .MuiSlider-track {\n    height: 10px;\n    background-color: ", "66;\n    border-radius: 5px;\n  }\n\n  .MuiSlider-thumb {\n    width: 18px;\n    height: 18px;\n    background-color: ", "EE;\n\n    :hover {\n      box-shadow: 0px 0px 0px 8px ", "33;\n    }\n  }\n"], ["\n  padding: 0 32px;\n\n  ",
    "\n\n  .MuiSlider-rail {\n    height: 10px;\n    background-color: ", "44;\n    border-radius: 5px;\n  }\n\n  .MuiSlider-track {\n    height: 10px;\n    background-color: ", "66;\n    border-radius: 5px;\n  }\n\n  .MuiSlider-thumb {\n    width: 18px;\n    height: 18px;\n    background-color: ", "EE;\n\n    :hover {\n      box-shadow: 0px 0px 0px 8px ", "33;\n    }\n  }\n"])), media.medium(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    width: 100%;\n    padding: 0;\n  "], ["\n    width: 100%;\n    padding: 0;\n  "]))), color.grey, color.green, color.green, color.green);
var SliderHintWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 25px 32px 44px 32px;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 25px 32px 44px 32px;\n\n  ",
    "\n"])), media.medium(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    margin: 32px 0 50px 0;\n  "], ["\n    margin: 32px 0 50px 0;\n  "]))));
var SliderHintLabel = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n"], ["\n  color: ", ";\n  font-size: 12px;\n"])), color.greyMedium);
var SliderValueLabel = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var AlternativeMilesWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 20px;\n  padding: 40px 0;\n\n  ", "\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 20px;\n  padding: 40px 0;\n\n  ",
    "\n"])), media.medium(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    margin: 40px 0;\n  "], ["\n    margin: 40px 0;\n  "]))));
var AlternativeMilesTextWrapper = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
var AlternativeMilesBoxes = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  white-space: wrap;\n\n  > * {\n    width: 100%;\n    margin-bottom: 16px;\n\n    ", "\n\n    ", "\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  white-space: wrap;\n\n  > * {\n    width: 100%;\n    margin-bottom: 16px;\n\n    ",
    "\n\n    ",
    "\n  }\n"])), media.medium(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      width: 40%;\n    "], ["\n      width: 40%;\n    "]))), media.large(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      width: auto;\n      flex: 1;\n      margin-right: 12px;\n\n      :last-child {\n        margin-right: 0;\n      }\n    "], ["\n      width: auto;\n      flex: 1;\n      margin-right: 12px;\n\n      :last-child {\n        margin-right: 0;\n      }\n    "]))));
var PaymentButtonWrapper = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  margin: 50px 0 40px 0;\n\n  ", " {\n    :first-child {\n      margin-bottom: 12px;\n    }\n  }\n\n  ", "\n"], ["\n  margin: 50px 0 40px 0;\n\n  ", " {\n    :first-child {\n      margin-bottom: 12px;\n    }\n  }\n\n  ",
    "\n"])), ButtonRoot, media.medium(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    display: flex;\n    margin: 50px 0;\n  "], ["\n    display: flex;\n    margin: 50px 0;\n  "]))));
var TopupMiles = function (_a) {
    var content = _a.content, data = _a.data, remainingMiles = _a.remainingMiles, priceResult = _a.priceResult, onConfirm = _a.onConfirm, onChangeCommitted = _a.onChangeCommitted, onBundleSelected = _a.onBundleSelected, toPayment = _a.toPayment, toMyPolicy = _a.toMyPolicy, paymentButtonDisabled = _a.paymentButtonDisabled, clearSliderPriceResult = _a.clearSliderPriceResult, paymentId = _a.paymentId, resetPaymentId = _a.resetPaymentId;
    var title = content.title, negativeMilesTitle = content.negativeMilesTitle, negativeMilesText = content.negativeMilesText, hintText = content.hintText, sliderHint = content.sliderHint, minMiles = content.minMiles, maxMiles = content.maxMiles, confirmButtonText = content.confirmButtonText, summaryText = content.summaryText, alternativeText = content.alternativeText, note = content.note, paymentButtonText = content.paymentButtonText, backButtonText = content.backButtonText;
    var negativeMiles = remainingMiles < 0;
    var _b = useState(minMiles), currentMiles = _b[0], setCurrentMiles = _b[1];
    var _c = useState(0), selectedAlternative = _c[0], setSelectedAlternative = _c[1];
    return (React.createElement(Layout, { shadow: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(Root, { negativeMiles: negativeMiles },
                    React.createElement(SectionHeader, { title: negativeMiles
                            ? negativeMilesTitle.replace('{miles}', Math.abs(remainingMiles).toString())
                            : title.replace('{miles}', remainingMiles.toString()) }),
                    negativeMiles && React.createElement(NegativeMilesTextWrapper, null, negativeMilesText),
                    React.createElement(AddMilesWidgetWrapper, null,
                        React.createElement(HintWrapper, null, hintText),
                        React.createElement(CurrentMilesWrapper, null,
                            currentMiles,
                            " miles"),
                        React.createElement(SliderWrapper, null,
                            React.createElement(Slider, { value: currentMiles, step: 50, min: minMiles, max: maxMiles, onChange: function (event, value) {
                                    setCurrentMiles(value);
                                    setSelectedAlternative(0);
                                    resetPaymentId(undefined);
                                }, onChangeCommitted: function (event, value) { return onChangeCommitted(value); } })),
                        React.createElement(SliderHintWrapper, null,
                            React.createElement(SliderValueLabel, null, minMiles),
                            React.createElement(SliderHintLabel, null, sliderHint),
                            React.createElement(SliderValueLabel, null, maxMiles)),
                        React.createElement(Button, { onClick: function () {
                                onConfirm(currentMiles);
                                TagManagerArgs('Button', window.location.pathname + " - Top up miles", confirmButtonText);
                            }, buttonStyle: "secondary" }, confirmButtonText),
                        priceResult && (React.createElement(HintWrapper, null, summaryText
                            .replace('{miles}', priceResult[0].toString())
                            .replace('{cost}', priceResult[1].toString())))),
                    React.createElement(AlternativeMilesWrapper, null,
                        React.createElement(AlternativeMilesTextWrapper, null, alternativeText),
                        React.createElement(AlternativeMilesBoxes, null, data.map(function (item) { return (React.createElement(MilesBox, { key: item.miles.toString(), selected: selectedAlternative === item.miles, cost: item.cost, miles: item.miles, onClick: function (miles) {
                                if (paymentId !== undefined)
                                    resetPaymentId(undefined);
                                setSelectedAlternative(miles);
                                onBundleSelected(miles);
                                setCurrentMiles(minMiles);
                                clearSliderPriceResult();
                            } })); }))),
                    React.createElement(Markdown, { source: note }),
                    React.createElement(PaymentButtonWrapper, null,
                        React.createElement(Button, { onClick: function () {
                                toMyPolicy();
                                TagManagerArgs('Button', window.location.pathname + " - Top up miles", backButtonText);
                            }, buttonStyle: "secondary" }, backButtonText),
                        React.createElement(Button, { onClick: function () {
                                toPayment();
                                TagManagerArgs('Button', window.location.pathname + " - Top up miles", paymentButtonText);
                            }, disabled: paymentButtonDisabled, buttonStyle: "primary" }, paymentButtonText)))))));
};
export default TopupMiles;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
