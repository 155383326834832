var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Layout, { PaddingHorizontal, PaddingVertical } from '../Layout';
import TabBar from '../MyJourneysSection/TabBar';
import RenewalBanner from '../PolicyDetailsSection/RenewalBanner';
import Dropdown from 'components/molecules/Dropdown';
import Button from 'components/atoms/Button';
import HideOn from 'components/atoms/HideOn';
import ListRow from 'components/molecules/ListRow';
import { media } from 'styles';
import { policyUtil, getVehicleDisplayValuesForList } from 'guidewirePolicyUtils';
import { getSingleFormGroupContent } from '../Form';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TwoColumns = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(1fr);\n\n  ", "\n"], ["\n  display: grid;\n  grid-template-columns: repeat(1fr);\n\n  ",
    "\n"])), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    grid-template-columns: repeat(2, 1fr);\n    column-gap: 50px;\n  "], ["\n    grid-template-columns: repeat(2, 1fr);\n    column-gap: 50px;\n  "]))));
var DetailsTitleBar = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var DetailsListTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: 500;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  padding-left: 30px;\n"], ["\n  font-size: 20px;\n  font-weight: 500;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  padding-left: 30px;\n"])));
var ButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 20px;\n  border-top: 1px solid rgba(0, 127, 73, 0.5);\n  padding-top: 30px;\n  padding-bottom: 40px;\n\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  margin-top: 20px;\n  border-top: 1px solid rgba(0, 127, 73, 0.5);\n  padding-top: 30px;\n  padding-bottom: 40px;\n\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var ListWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0 10px;\n  background-color: ", ";\n"], ["\n  margin: 0 10px;\n  background-color: ", ";\n"])), function (props) { return (props.greyBg ? 'rgba(137,137,137,0.06)' : 'transparent'); });
var PleaseNoteText = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 20px;\n  font-size: 12px;\n\n  strong {\n    font-weight: 500;\n  }\n"], ["\n  padding: 20px;\n  font-size: 12px;\n\n  strong {\n    font-weight: 500;\n  }\n"])));
var AddOnsTitle = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 20px;\n  padding: 20px 30px;\n  font-weight: 500;\n"], ["\n  font-size: 20px;\n  padding: 20px 30px;\n  font-weight: 500;\n"])));
var PolicyDetailsTabs = function (_a) {
    var currentPolicy = _a.currentPolicy, renewalPolicy = _a.renewalPolicy, alternateRenewalPolicy = _a.alternateRenewalPolicy, content = _a.content, onAmendDetails = _a.onAmendDetails, onRenewPolicy = _a.onRenewPolicy, onChangeCar = _a.onChangeCar;
    var currentPolicyDisplay = policyUtil(currentPolicy);
    var renewalPolicyDisplay = renewalPolicy ? policyUtil(renewalPolicy) : undefined;
    var alternateRenewalPolicyDisplay = alternateRenewalPolicy ? policyUtil(alternateRenewalPolicy) : undefined;
    // Tabs Content
    var tabs = getSingleFormGroupContent('policyDetailsTabs', content.formGroups).list[0].listItems;
    var visableTabs = tabs.filter(function (tab) {
        if (tab.value === 'amendedRenewal' && alternateRenewalPolicy !== undefined)
            return tab;
        if (tab.value === 'renewal' && renewalPolicy !== undefined)
            return tab;
        if (tab.value === 'current' && currentPolicy !== undefined)
            return tab;
    });
    // Content
    var myCoverCurrentPolicy = getSingleFormGroupContent('myCoverCurrentPolicy', content.formGroups);
    var pleaseNoteText = myCoverCurrentPolicy.text;
    var myCoverCurrentPolicyTitle = myCoverCurrentPolicy.title;
    var myCoverListItems = myCoverCurrentPolicy.list[0].listItems;
    var myCarDetailsAllPolicys = getSingleFormGroupContent('myCarDetailsCurrentPolicy', content.formGroups);
    var myCarDetailsTitle = myCarDetailsAllPolicys.title;
    var myCarListItems = myCarDetailsAllPolicys.list[0].listItems;
    var addOnsCurrentPolicy = getSingleFormGroupContent('addOnsCurrentPolicy', content.formGroups);
    var addOnsCurrentPolicyTitle = addOnsCurrentPolicy.title;
    var addOnsListItems = addOnsCurrentPolicy.list[0].listItems;
    var roadRescueCurrentPolicy = getSingleFormGroupContent('roadRescueCurrentPolicy', content.formGroups);
    var roadRescueListItems = roadRescueCurrentPolicy.list[0].listItems;
    var renewalPriceAndStatus = getSingleFormGroupContent('renewalPriceAndStatus', content.formGroups);
    var myCoverRenewalPolicy = getSingleFormGroupContent('renewalMyCover', content.formGroups);
    var pleaseNoteTextRenewal = myCoverRenewalPolicy.text;
    var myCoverRenewalTitle = myCoverRenewalPolicy.title;
    var myCoverRenewalListItems = myCoverRenewalPolicy.list[0].listItems;
    var getCarDetailsForDisplay = function (policy) {
        var carDetails = policy.vehicleInfo;
        return getVehicleDisplayValuesForList(carDetails);
    };
    var getPolicySelectedAddons = function (policy) {
        var selected = {
            PMRoadRescueCov: false,
            PMKeyProtect: false,
            PMCourtesyCarPlusCov: false,
            PMLegalExpensesCov: false,
            TMEnhancedCourtesyCarCov: false,
        };
        policy.includedAddOns.map(function (item) {
            if (item.codeIdentifier === 'PMRoadRescueCov')
                selected[item.codeIdentifier] = true;
            if (item.codeIdentifier === 'PMKeyProtect')
                selected[item.codeIdentifier] = true;
            if (item.codeIdentifier === 'PMCourtesyCarPlusCov')
                selected[item.codeIdentifier] = true;
            if (item.codeIdentifier === 'PMLegalExpensesCov')
                selected[item.codeIdentifier] = true;
            if (item.codeIdentifier === 'TMEnhancedCourtesyCarCov')
                selected[item.codeIdentifier] = true;
        });
        return selected;
    };
    var currentPolicyAddOns = getPolicySelectedAddons(currentPolicy);
    var carDetailsDisplay = getCarDetailsForDisplay(currentPolicy);
    var renewalPolicyAddOns = undefined;
    var carDetailsRenewalDisplay = undefined;
    if (renewalPolicy) {
        renewalPolicyAddOns = getPolicySelectedAddons(renewalPolicy);
        carDetailsRenewalDisplay = getCarDetailsForDisplay(renewalPolicy);
    }
    var alternateRenewalPolicyAddOns = undefined;
    var carDetailsAlternateRenewalDisplay = undefined;
    if (alternateRenewalPolicy) {
        alternateRenewalPolicyAddOns = getPolicySelectedAddons(alternateRenewalPolicy);
        carDetailsAlternateRenewalDisplay = getCarDetailsForDisplay(alternateRenewalPolicy);
    }
    // Setting state for the Tabs
    // Default tab will always be the first out of:
    // current
    // renewal, current
    // alternateRenewal, renewal, current
    var _b = useState(visableTabs[0].value), selectedTab = _b[0], setSelectedTab = _b[1];
    useEffect(function () {
        setSelectedTab(visableTabs[0].value);
    }, [renewalPolicy, alternateRenewalPolicy]);
    var getRoadRescue = function (selected, policy, listContent, listBorderBottom) {
        var addonLabelStyle = { width: '50%' };
        var addonRowStyle = {
            paddingLeft: '20px',
            paddingRight: '20px',
            borderBottom: listBorderBottom ? '1px solid rgba(137, 137, 137, 0.2)' : '0',
        };
        var roadRescueNotSelected = {
            label: 'Road rescue',
            value: 'No',
        };
        if (selected.PMRoadRescueCov === true) {
            var roadRescue = policy.includedAddOns.filter(function (item) { return item.codeIdentifier === 'PMRoadRescueCov'; });
            var level_1 = roadRescue[0].terms[0].chosenTermValue;
            var roadRescueContent = listContent.filter(function (item) { return item.value === level_1; });
            return (React.createElement(ListRow, { key: roadRescueContent[0].label, label: roadRescueContent[0].label, value: roadRescueContent[0].text, labelStyle: addonLabelStyle, style: addonRowStyle }));
        }
        return (React.createElement(ListRow, { key: "noCover", label: roadRescueNotSelected.label, value: roadRescueNotSelected.value, labelStyle: addonLabelStyle, style: addonRowStyle }));
    };
    return (React.createElement(Root, null,
        selectedTab && (React.createElement(Layout, null, visableTabs.length > 1 && (React.createElement("div", { style: { padding: '50px 0', backgroundColor: '#f9f9f9' } },
            React.createElement(PaddingHorizontal, null,
                React.createElement(HideOn, { smallOnly: true },
                    React.createElement(TabBar, { defaultTab: selectedTab, tabBarContent: visableTabs, onSelect: function (key) { return setSelectedTab(key); } })),
                React.createElement(HideOn, { medium: true },
                    React.createElement(Dropdown, { options: visableTabs, onSelect: function (value) {
                            setSelectedTab(value);
                        }, defaultOption: selectedTab }))))))),
        selectedTab === 'amendedRenewal' && alternateRenewalPolicy && (React.createElement(Layout, { withSeperator: true },
            React.createElement(RenewalBanner, { title: renewalPriceAndStatus.title, text: renewalPriceAndStatus.body, statusText: alternateRenewalPolicyDisplay === null || alternateRenewalPolicyDisplay === void 0 ? void 0 : alternateRenewalPolicyDisplay.getPolicyStatus(), totalPrice: alternateRenewalPolicyDisplay === null || alternateRenewalPolicyDisplay === void 0 ? void 0 : alternateRenewalPolicyDisplay.getPremium(), policyMiles: alternateRenewalPolicyDisplay === null || alternateRenewalPolicyDisplay === void 0 ? void 0 : alternateRenewalPolicyDisplay.getProductMiles() }),
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(HideOn, { smallOnly: true },
                        React.createElement(DetailsTitleBar, null,
                            React.createElement(TwoColumns, null,
                                React.createElement(DetailsListTitle, null, myCoverRenewalTitle),
                                React.createElement(DetailsListTitle, null, myCarDetailsTitle)))),
                    React.createElement(TwoColumns, null,
                        React.createElement("div", null,
                            React.createElement(HideOn, { medium: true },
                                React.createElement(DetailsTitleBar, null,
                                    React.createElement(DetailsListTitle, null, myCoverRenewalTitle))),
                            React.createElement(ListWrapper, null,
                                myCoverRenewalListItems.map(function (item) {
                                    if (item.label === 'ROAD_RESCUE_LEVEL') {
                                        return getRoadRescue(alternateRenewalPolicyAddOns, alternateRenewalPolicy, roadRescueListItems, true);
                                    }
                                    if (item.value === 'PMKeyProtect' || item.value === 'PMCourtesyCarPlusCov') {
                                        return (React.createElement(ListRow, { key: item.label + 'alternateRenewal', label: item.label, value: alternateRenewalPolicyAddOns[item.value] === true ? 'Yes' : 'No', info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                                width: '50%',
                                                textAlign: 'right',
                                            }, style: {
                                                borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                            } }));
                                    }
                                    return (React.createElement(ListRow, { key: item.label + 'alternateRenewal', label: item.label, value: alternateRenewalPolicyDisplay === null || alternateRenewalPolicyDisplay === void 0 ? void 0 : alternateRenewalPolicyDisplay.getDisplayValue(item.value), info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                            width: '50%',
                                            textAlign: 'right',
                                        }, style: {
                                            borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                        } }));
                                }),
                                React.createElement(ListRow, { label: "Legal Expenses Cover", value: alternateRenewalPolicyAddOns.PMLegalExpensesCov === true ? 'Yes' : 'No', labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                        width: '50%',
                                        textAlign: 'right',
                                    }, style: {
                                        borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    } }),
                                React.createElement(ListRow, { label: "Enhanced Courtesy Car Cover", value: alternateRenewalPolicyAddOns.TMEnhancedCourtesyCarCov === true ? 'Yes' : 'No', labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                        borderBottom: '1px solid white',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    } })),
                            React.createElement(ListWrapper, null,
                                React.createElement(PleaseNoteText, null,
                                    React.createElement(ReactMarkdown, { source: pleaseNoteText })))),
                        React.createElement("div", null,
                            React.createElement(HideOn, { medium: true },
                                React.createElement(DetailsTitleBar, null,
                                    React.createElement(DetailsListTitle, null, myCarDetailsTitle))),
                            React.createElement(ListWrapper, null, myCarListItems.map(function (item) { return (React.createElement(ListRow, { key: item.label + 'alternateRenewal', label: item.label, value: carDetailsAlternateRenewalDisplay[item.value], info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                    borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                } })); })))),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                onAmendDetails(false);
                                TagManagerArgs('Button', '/mypolicy - Amend renewal policy (Amended)', 'Amend details');
                            } }, "Amend details"),
                        React.createElement(Button, { buttonStyle: "primary", onClick: function () {
                                onAmendDetails(true);
                                TagManagerArgs('Button', '/mypolicy - Renew policy (Amended)', 'Renew policy');
                            } }, "Renew policy")))))),
        selectedTab === 'renewal' && renewalPolicy && (React.createElement(Layout, { withSeperator: true },
            React.createElement(RenewalBanner, { title: renewalPriceAndStatus.title, text: renewalPriceAndStatus.body, statusText: renewalPolicyDisplay === null || renewalPolicyDisplay === void 0 ? void 0 : renewalPolicyDisplay.getPolicyStatus(), totalPrice: renewalPolicyDisplay === null || renewalPolicyDisplay === void 0 ? void 0 : renewalPolicyDisplay.getPremium(), policyMiles: renewalPolicyDisplay === null || renewalPolicyDisplay === void 0 ? void 0 : renewalPolicyDisplay.getProductMiles() }),
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(HideOn, { smallOnly: true },
                        React.createElement(DetailsTitleBar, null,
                            React.createElement(TwoColumns, null,
                                React.createElement(DetailsListTitle, null, myCoverRenewalTitle),
                                React.createElement(DetailsListTitle, null, myCarDetailsTitle)))),
                    React.createElement(TwoColumns, null,
                        React.createElement("div", null,
                            React.createElement(HideOn, { medium: true },
                                React.createElement(DetailsTitleBar, null,
                                    React.createElement(DetailsListTitle, null, myCoverRenewalTitle))),
                            React.createElement(ListWrapper, null,
                                myCoverRenewalListItems.map(function (item) {
                                    if (item.label === 'ROAD_RESCUE_LEVEL') {
                                        return getRoadRescue(renewalPolicyAddOns, renewalPolicy, roadRescueListItems, true);
                                    }
                                    if (item.value === 'PMKeyProtect' || item.value === 'PMCourtesyCarPlusCov') {
                                        return (React.createElement(ListRow, { key: item.label + 'renewal', label: item.label, value: renewalPolicyAddOns[item.value] === true ? 'Yes' : 'No', info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                                width: '50%',
                                                textAlign: 'right',
                                            }, style: {
                                                borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                            } }));
                                    }
                                    return (React.createElement(ListRow, { key: item.label + 'renewal', label: item.label, value: renewalPolicyDisplay === null || renewalPolicyDisplay === void 0 ? void 0 : renewalPolicyDisplay.getDisplayValue(item.value), info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                            width: '50%',
                                            textAlign: 'right',
                                        }, style: {
                                            borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                        } }));
                                }),
                                React.createElement(ListRow, { label: "Legal Expenses Cover", value: renewalPolicyAddOns.PMLegalExpensesCov === true ? 'Yes' : 'No', labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                        width: '50%',
                                        textAlign: 'right',
                                    }, style: {
                                        borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    } }),
                                React.createElement(ListRow, { label: "Enhanced Courtesy Car Cover", value: renewalPolicyAddOns.TMEnhancedCourtesyCarCov === true ? 'Yes' : 'No', labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                        borderBottom: '1px solid white',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                    } })),
                            React.createElement(ListWrapper, null,
                                React.createElement(PleaseNoteText, null,
                                    React.createElement(ReactMarkdown, { source: pleaseNoteTextRenewal })))),
                        React.createElement("div", null,
                            React.createElement(HideOn, { medium: true },
                                React.createElement(DetailsTitleBar, null,
                                    React.createElement(DetailsListTitle, null, myCarDetailsTitle))),
                            React.createElement(ListWrapper, null, myCarListItems.map(function (item) { return (React.createElement(ListRow, { key: item.label + 'renewal', label: item.label, value: carDetailsRenewalDisplay[item.value], info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                    borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                } })); })))),
                    React.createElement(ButtonWrapper, null, renewalPolicy.status !== 'Accepted' && (React.createElement(React.Fragment, null,
                        !alternateRenewalPolicy && (React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                onAmendDetails(false);
                                TagManagerArgs('Button', '/mypolicy - Amend details (Renewal)', 'Amend details');
                            } }, "Amend details")),
                        React.createElement(Button, { buttonStyle: "primary", onClick: function () {
                                onRenewPolicy();
                                TagManagerArgs('Button', '/mypolicy - Renew policy (Renewal)', 'Renew policy');
                            } }, "Renew policy")))))))),
        selectedTab === 'current' && (React.createElement(Layout, { withSeperator: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null),
                React.createElement(HideOn, { smallOnly: true },
                    React.createElement(DetailsTitleBar, null,
                        React.createElement(TwoColumns, null,
                            React.createElement(DetailsListTitle, null, myCoverCurrentPolicyTitle),
                            React.createElement(DetailsListTitle, null, myCarDetailsTitle)))),
                React.createElement(TwoColumns, null,
                    React.createElement("div", null,
                        React.createElement(HideOn, { medium: true },
                            React.createElement(DetailsTitleBar, null,
                                React.createElement(DetailsListTitle, null, myCoverCurrentPolicyTitle))),
                        React.createElement(ListWrapper, null, myCoverListItems.map(function (item) { return (React.createElement(ListRow, { key: item.label + 'current', label: item.label, value: currentPolicyDisplay.getDisplayValue(item.value), info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: {
                                width: '50%',
                                textAlign: 'right',
                                color: item.label === 'Status' && currentPolicyDisplay.getDisplayValue(item.value) === 'In Force'
                                    ? 'green'
                                    : 'inherit',
                            }, style: {
                                borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                            } })); })),
                        React.createElement(ListWrapper, null,
                            React.createElement(PleaseNoteText, null,
                                React.createElement(ReactMarkdown, { source: pleaseNoteText }))),
                        React.createElement(AddOnsTitle, null, addOnsCurrentPolicyTitle),
                        React.createElement(ListWrapper, { greyBg: true },
                            addOnsListItems.map(function (item) { return (React.createElement(ListRow, { key: item.label + 'current', label: item.label, value: currentPolicyAddOns[item.value] === true ? item.text : 'No', info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                    borderBottom: '1px solid white',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                } })); }),
                            getRoadRescue(currentPolicyAddOns, currentPolicy, roadRescueListItems, false),
                            React.createElement(ListRow, { label: "Legal Expenses Cover", value: currentPolicyAddOns.PMLegalExpensesCov === true ? 'Yes' : 'No', labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                    borderBottom: '1px solid white',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                } }),
                            React.createElement(ListRow, { label: "Enhanced Courtesy Car Cover", value: currentPolicyAddOns.TMEnhancedCourtesyCarCov === true ? 'Yes' : 'No', labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                    borderBottom: '1px solid white',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                } }))),
                    React.createElement("div", null,
                        React.createElement(HideOn, { medium: true },
                            React.createElement(DetailsTitleBar, null,
                                React.createElement(DetailsListTitle, null, myCarDetailsTitle))),
                        React.createElement(ListWrapper, null, myCarListItems.map(function (item) { return (React.createElement(ListRow, { key: item.label + 'current', label: item.label, value: carDetailsDisplay[item.value], info: item.info, labelStyle: { maxWidth: '50%', marginRight: '10px' }, valueStyle: { width: '50%', textAlign: 'right' }, style: {
                                borderBottom: '1px solid rgba(137, 137, 137, 0.2)',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                            } })); })))),
                React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { disabled: currentPolicy.status === 'Lapsed', buttonStyle: "secondary", onClick: function () {
                            onChangeCar('/change-vehicle');
                            TagManagerArgs('Button', '/mypolicy)', 'Change car');
                        } }, "Change car")))))));
};
export default PolicyDetailsTabs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
