import { renewToken } from './client';
import { createForAccount, getQuotePortalDomain, getSavedQuotes } from './quoteAPIs';
var fetchSavedQuotesAttempts = 0;
export var fetchSavedQuotes = function (accessToken, tokenForRefresh, callBack) {
    getSavedQuotes(accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            callBack(payload);
            fetchSavedQuotesAttempts = 0;
        }
        else if (status === 401 && fetchSavedQuotesAttempts < 5) {
            fetchSavedQuotesAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return fetchSavedQuotes(newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error fetching saved quotes | status code " + status);
        }
    });
};
var fetchQuotePortalDomainAttempts = 0;
export var fetchQuotePortalDomain = function (accessToken, tokenForRefresh, callBack) {
    getQuotePortalDomain(accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            fetchQuotePortalDomainAttempts = 0;
            callBack(payload);
        }
        else if (status === 401 && fetchQuotePortalDomainAttempts < 5) {
            fetchQuotePortalDomainAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return fetchQuotePortalDomain(newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error fetching quote portal domain | status code " + status);
        }
    });
};
var createNewQuoteAttempts = 0;
export var createNewQuote = function (accountNumber, accessToken, tokenForRefresh, callBack) {
    var date = new Date();
    var baseData = {
        baseData: {
            productCode: 'PersonalMotor',
            accountNumber: accountNumber,
            periodStartDate: {
                year: date.getFullYear(),
                month: date.getMonth(),
                day: date.getDate(),
            },
        },
    };
    createForAccount(baseData, accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            createNewQuoteAttempts = 0;
            callBack(payload);
        }
        else if (status === 401 && createNewQuoteAttempts < 5) {
            createNewQuoteAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return createNewQuote(accountNumber, newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error fetching quote portal domain | status code " + status);
        }
    });
};
