var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import AddDriverIcon from 'assets/add_person.svg';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { color, fontWeight, fontSize, media } from 'styles';
import DriverDetails from './DriverDetails';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
var TitileContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 30px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 30px;\n"])));
var TitleWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n\n  ", "\n"], ["\n  font-weight: ", ";\n\n  ",
    "\n"])), fontWeight.medium, media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: ", ";\n  "], ["\n    font-size: ", ";\n  "])), fontSize.fs20));
var Headers = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 10px 0px 20px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 10px 0px 20px;\n"])));
var Header = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 20%;\n"], ["\n  width: 20%;\n"])));
var Row = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  border-top: 1px solid ", ";\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 0px 10px 0px 20px;\n\n  :last-child {\n    border-bottom: 1px solid ", ";\n  }\n"], ["\n  display: flex;\n  border-top: 1px solid ", ";\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 0px 10px 0px 20px;\n\n  :last-child {\n    border-bottom: 1px solid ", ";\n  }\n"])), color.greyMedium, fontWeight.light, color.greyMedium);
var DriverProfiles = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 15px;\n"], ["\n  margin-top: 15px;\n"])));
var DataCell = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 20%;\n  display: flex;\n  justify-content: space-between;\n  padding: 17px 0;\n"], ["\n  width: 20%;\n  display: flex;\n  justify-content: space-between;\n  padding: 17px 0;\n"])));
var OtherDrivers = function (_a) {
    var title = _a.title, addDriverButtonText = _a.addDriverButtonText, driverProfileContent = _a.driverProfileContent, driverProfileDatas = _a.driverProfileDatas, setMainDriver = _a.setMainDriver, setMainDriverButtonText = _a.setMainDriverButtonText, addDriverDetail = _a.addDriverDetail, addDriverDetailButtonText = _a.addDriverDetailButtonText, removeDriver = _a.removeDriver, removeDriverButtonText = _a.removeDriverButtonText, policyIsLapsed = _a.policyIsLapsed, onAddDriver = _a.onAddDriver;
    var _b = useState(new Set()), indexes = _b[0], setIndexes = _b[1];
    var mainContent = driverProfileContent.mainContent, detailsContent = driverProfileContent.detailsContent;
    var headerKeys = Object.keys(mainContent);
    var headers = headerKeys.map(function (key) { return mainContent[key]; });
    var onRowClick = function (index) {
        if (indexes.has(index)) {
            indexes.delete(index);
        }
        else {
            indexes.add(index);
        }
        setIndexes(new Set(indexes));
    };
    var renderRow = function (profileData) {
        var mainData = profileData.mainData;
        var keys = Object.keys(mainData);
        var cellsData = keys.map(function (key) { return ({
            label: mainContent[key],
            value: mainData[key],
        }); });
        return cellsData.map(function (cellData, index) { return (React.createElement(DataCell, { key: cellData.label },
            cellData.value,
            index === cellsData.length - 1 && (indexes.has(index) ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null)))); });
    };
    return (React.createElement(Root, null,
        React.createElement(TitileContainer, null,
            React.createElement(TitleWrapper, null, title),
            React.createElement(Button, { disabled: policyIsLapsed, buttonStyle: "secondary", rightIcon: React.createElement(AddDriverIcon, null), onClick: function () {
                    onAddDriver('/add-driver');
                    TagManagerArgs('Button', '/mypolicy - Driver Section', addDriverButtonText);
                } }, addDriverButtonText)),
        React.createElement(Headers, null, headers.map(function (header) {
            return header !== 'Title' ? React.createElement(Header, { key: header }, header) : null;
        })),
        React.createElement(DriverProfiles, null, driverProfileDatas.map(function (profileData, index) { return (React.createElement(React.Fragment, { key: index },
            React.createElement(Row, { onClick: function () { return onRowClick(index); } }, renderRow(profileData)),
            indexes.has(index) && (React.createElement(DriverDetails, { detailsContent: detailsContent, detailsData: profileData.detailsData, setMainDriver: setMainDriver, setMainDriverButtonText: setMainDriverButtonText, addDriverDetail: addDriverDetail, addDriverDetailButtonText: addDriverDetailButtonText, removeDriver: !profileData.detailsData.mainDriver ? removeDriver : undefined, removeDriverButtonText: !profileData.detailsData.mainDriver ? removeDriverButtonText : undefined, driverIndex: index, policyIsLapsed: policyIsLapsed })))); }))));
};
export default OtherDrivers;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
