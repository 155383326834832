var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { fontWeight, media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var RowWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border-top: ", ";\n  border-bottom: ", ";\n  padding: 14px 0;\n"], ["\n  display: flex;\n  align-items: center;\n  border-top: ", ";\n  border-bottom: ", ";\n  padding: 14px 0;\n"])), function (props) { return (props.isFirstRow ? '1px solid rgba(137, 137, 137, 0.5)' : 'none'); }, function (props) { return (props.expanded ? 'none' : '1px solid rgba(137, 137, 137, 0.5)'); });
var CategoryWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-left: 15px;\n"], ["\n  font-weight: ", ";\n  margin-left: 15px;\n"])), fontWeight.medium);
var ArrowWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: auto;\n"], ["\n  margin-left: auto;\n"])));
var ScoreTextWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: ", ";\n  line-height: 20px;\n  margin: 0 80px;\n  padding: 20px 0px;\n\n  ", "\n"], ["\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: ", ";\n  line-height: 20px;\n  margin: 0 80px;\n  padding: 20px 0px;\n\n  ",
    "\n"])), fontWeight.light, media.large(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 0;\n    padding: 20px 80px;\n  "], ["\n    margin: 0;\n    padding: 20px 80px;\n  "]))));
var EvaluationTextWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-bottom: 20px;\n"], ["\n  padding-bottom: 20px;\n"])));
var TipTitleWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: ", ";\n  padding: 10px 0;\n"], ["\n  font-size: 18px;\n  font-weight: ", ";\n  padding: 10px 0;\n"])), fontWeight.medium);
var TipWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var DrivingScoreRow = function (_a) {
    var categoryLabel = _a.categoryLabel, evaluationText = _a.evaluationText, icon = _a.icon, _b = _a.isFirstRow, isFirstRow = _b === void 0 ? false : _b, tip = _a.tip, tipTitle = _a.tipTitle;
    var _c = useState(false), expanded = _c[0], setExpanded = _c[1];
    return (React.createElement(Root, null,
        React.createElement(RowWrapper, { isFirstRow: isFirstRow, expanded: expanded, onClick: function () { return setExpanded(!expanded); } },
            icon,
            React.createElement(CategoryWrapper, null, categoryLabel),
            React.createElement(ArrowWrapper, null, expanded ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null))),
        expanded && (React.createElement(ScoreTextWrapper, null,
            evaluationText && (React.createElement(EvaluationTextWrapper, null,
                React.createElement(ReactMarkdown, { source: evaluationText }))),
            tip && (React.createElement(React.Fragment, null,
                React.createElement(TipTitleWrapper, null, tipTitle),
                React.createElement(TipWrapper, null,
                    React.createElement(ReactMarkdown, { source: tip }))))))));
};
export default DrivingScoreRow;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
