import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Login, { LoginPageLayout } from 'components/organisms/Login';
import HelpSection from 'components/organisms/HelpSection';
import { actions } from 'reduxStore';
import { login, getPageContent, getPolicies, resetPassword } from 'services';
import { ContentPageURL, SessionStorageKey } from 'types';
var LoginPage = function (_a) {
    var setPolicyIds = _a.setPolicyIds;
    var _b = useState(undefined), contentSections = _b[0], setContentSections = _b[1];
    var _c = useState(false), loginError = _c[0], setLoginError = _c[1];
    var _d = useState(''), loginErrorMessage = _d[0], setLoginErrorMessage = _d[1];
    var history = useHistory();
    useEffect(function () {
        getPageContent(ContentPageURL.LOGIN_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200) {
                setContentSections(payload === null || payload === void 0 ? void 0 : payload.contentSections);
            }
        });
    }, []);
    var signIn = function (email, password) {
        login(email, password).then(function (result) {
            var loginStatus = result.status, authData = result.payload;
            if (loginStatus === 200 && authData) {
                var access_token = authData.access_token, refresh_token = authData.refresh_token, expires_in = authData.expires_in;
                var expiresSeconds = parseInt(expires_in) * 1000 - 3000;
                if (access_token && refresh_token) {
                    sessionStorage.setItem(SessionStorageKey.ACCESS_TOKEN, access_token);
                    sessionStorage.setItem(SessionStorageKey.REFRESH_TOKEN, refresh_token);
                    sessionStorage.setItem('refresh_after', JSON.stringify(Date.now() + expiresSeconds));
                    getPolicies(access_token).then(function (result) {
                        var status = result.status, policies = result.payload;
                        if (status === 200) {
                            if (policies && policies.length > 0) {
                                var ids = policies.map(function (policy) { return policy.periods[0].policyId; });
                                setPolicyIds(ids);
                                sessionStorage.setItem('showPopupGuide', 'true');
                                history.push('/dashboard');
                            }
                            else {
                                setLoginErrorMessage('No policies available');
                                setLoginError(true);
                            }
                        }
                        else {
                            setLoginErrorMessage('Network error while fetching policies');
                            setLoginError(true);
                        }
                    });
                }
            }
            if (loginStatus === 401) {
                setLoginErrorMessage('Please check your username or password is correct');
                setLoginError(true);
            }
            if (loginStatus === 500) {
                setLoginErrorMessage('There is currently a problem with our sever');
                setLoginError(true);
            }
        });
    };
    var sendResetPasswordToEmail = function (email) { return resetPassword(email); };
    var renderSection = function (section) {
        if ('signInText' in section) {
            return (React.createElement(Login, { key: "loginContent", content: section, signIn: signIn, sendResetPasswordToEmail: sendResetPasswordToEmail, loginError: loginError, loginErrorMessage: loginErrorMessage }));
        }
        else {
            return React.createElement(HelpSection, { key: "helpSectionContent", content: section });
        }
    };
    return contentSections ? (React.createElement(LoginPageLayout, null, contentSections.map(function (section) { return renderSection(section); }))) : null;
};
var mapDispatchToProps = function (dispatch) { return ({
    setPolicyIds: function (ids) { return dispatch(actions.setPolicyIds(ids)); },
}); };
export default connect(null, mapDispatchToProps)(LoginPage);
