var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import FormLabel from './FormLabel';
import { color, fontFamily } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .MuiFormControl-root {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px ", ";\n    background-color: ", ";\n    padding: 0 17px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n    transition: 0.3s;\n  }\n\n  .MuiInputBase-input {\n    margin: 0;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiIconButton-root {\n    padding: 5px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .MuiFormControl-root {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px ", ";\n    background-color: ", ";\n    padding: 0 17px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n    transition: 0.3s;\n  }\n\n  .MuiInputBase-input {\n    margin: 0;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiIconButton-root {\n    padding: 5px;\n  }\n"])), function (props) { return (props.error ? 'red' : 'rgba(137, 137, 137, 0.3)'); }, function (props) { return (props.disabledStyle ? 'rgba(0,0,0,.05)' : 'white'); }, color.black, fontFamily.base);
var FormDatePicker = function (_a) {
    var label = _a.label, toolTip = _a.toolTip, name = _a.name, control = _a.control, additionalToTooltip = _a.additionalToTooltip, onChange = _a.onChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, _d = _a.disableFuture, disableFuture = _d === void 0 ? true : _d, value = _a.value, _e = _a.error, error = _e === void 0 ? false : _e;
    var _f = useState(null), date = _f[0], setDate = _f[1];
    var handleDateChange = function (date) {
        setDate(date);
        onChange(date);
    };
    return (React.createElement(Root, { disabledStyle: disabled, error: error },
        control && (React.createElement(React.Fragment, null,
            React.createElement(FormLabel, { label: label, toolTip: toolTip, additionalToTooltip: additionalToTooltip, isRequired: isRequired }),
            React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                React.createElement(Controller, { render: function (props) { return (React.createElement(KeyboardDatePicker, { inputRef: props.ref, name: props.name, onChange: props.onChange, value: props.value, disableToolbar: true, disableFuture: disableFuture, format: "dd/MM/yyyy", KeyboardButtonProps: {
                            'aria-label': 'change date',
                        }, InputLabelProps: {
                            shrink: true,
                        }, placeholder: "dd / mm / yyyy", disabled: disabled })); }, rules: { required: isRequired }, name: name, control: control, defaultValue: null })))),
        !control && (React.createElement(React.Fragment, null,
            React.createElement(FormLabel, { label: label, toolTip: toolTip, additionalToTooltip: additionalToTooltip }),
            React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                React.createElement(KeyboardDatePicker, { name: name, onChange: function (date) { return handleDateChange(date); }, disableToolbar: true, disableFuture: disableFuture, format: "dd/MM/yyyy", KeyboardButtonProps: {
                        'aria-label': 'change date',
                    }, InputLabelProps: {
                        shrink: true,
                    }, placeholder: "dd / mm / yyyy", value: value ? value : date, disabled: disabled }))))));
};
export default FormDatePicker;
var templateObject_1;
