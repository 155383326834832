var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_STATE_INIT, SET_POLICY_IDS, SET_CURRENT_POLICY_INDEX, SET_CURRENT_POLICY, SET_RENEWAL_POLICY, SET_ALTERNATE_RENEWAL_POLICY, SET_PAGE_HEADER_CONTENT, SET_QUOTE, SET_MTA_EFFECTIVE_DATE, SET_MTA, SET_MULTI_MTA_RESPONSE, SET_MULTI_DRIVERS, SET_DRIVER_INDEX, REMOVE_MULTI_DRIVER, SET_DRIVER_FORM_DETAILS, SET_CONVICTION_AND_CLAIMS_FORM_DETAILS, SET_AMEND_RENEWAL_DETAILS, SET_AMEND_RENEWAL_STATE, SET_AMENDED_RENEWAL_QUOTE, SET_ACCOUNT_INFO, SET_CURRENTLT_COVERED, SET_ACCOUNT_NUMBER, SET_POLICY_SUMMARIES, SET_MTA_FORM_DETAILS, SET_TOPUP_ID, SET_AUTORENEW_CHOICE, SET_REFRESH_POLICY, } from './action-types';
import { initialState } from './initial-state';
export var reducer = function (state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case SET_STATE_INIT:
            return initialState;
        case SET_POLICY_IDS:
            return __assign(__assign({}, state), { policyIds: action.value });
        case SET_TOPUP_ID:
            return __assign(__assign({}, state), { topupId: action.value });
        case SET_CURRENT_POLICY:
            return __assign(__assign({}, state), { currentPolicy: action.value });
        case SET_RENEWAL_POLICY:
            return __assign(__assign({}, state), { renewalPolicy: action.value });
        case SET_ALTERNATE_RENEWAL_POLICY:
            return __assign(__assign({}, state), { alternateRenewalPolicy: action.value });
        case SET_CURRENT_POLICY_INDEX:
            return __assign(__assign({}, state), { currentPolicyIndex: action.value });
        case SET_PAGE_HEADER_CONTENT:
            return __assign(__assign({}, state), { pageHeaderContent: action.value });
        case SET_QUOTE:
            return __assign(__assign({}, state), { quote: action.value });
        case SET_MTA_EFFECTIVE_DATE:
            return __assign(__assign({}, state), { mtaEffectiveDate: action.value });
        case SET_MTA:
            return __assign(__assign({}, state), { mta: action.value });
        case SET_MTA_FORM_DETAILS:
            return __assign(__assign({}, state), { mtaFormDetails: action.value });
        case SET_MULTI_MTA_RESPONSE:
            return __assign(__assign({}, state), { multiMtaResponse: action.value });
        case SET_MULTI_DRIVERS:
            return __assign(__assign({}, state), { multiMtaDrivers: action.value });
        case REMOVE_MULTI_DRIVER:
            return __assign(__assign({}, state), { multiMtaDrivers: action.value });
        case SET_DRIVER_INDEX:
            return __assign(__assign({}, state), { driverIndex: action.value });
        case SET_DRIVER_FORM_DETAILS:
            return __assign(__assign({}, state), { driverFormDetails: action.value });
        case SET_CONVICTION_AND_CLAIMS_FORM_DETAILS:
            return __assign(__assign({}, state), { convictionsAndClaimsFormDetails: action.value });
        case SET_AMEND_RENEWAL_DETAILS:
            return __assign(__assign({}, state), { renewalAmendDetails: action.value });
        case SET_AMEND_RENEWAL_STATE:
            return __assign(__assign({}, state), { renewalState: action.value });
        case SET_AMENDED_RENEWAL_QUOTE:
            return __assign(__assign({}, state), { renewalAmendedQuote: action.value });
        case SET_ACCOUNT_INFO:
            return __assign(__assign({}, state), { accountInfo: action.value });
        case SET_CURRENTLT_COVERED:
            return __assign(__assign({}, state), { currentlyCovered: action.value });
        case SET_ACCOUNT_NUMBER:
            return __assign(__assign({}, state), { accountNumber: action.value });
        case SET_POLICY_SUMMARIES:
            return __assign(__assign({}, state), { policySummaries: action.value });
        case SET_AUTORENEW_CHOICE:
            return __assign(__assign({}, state), { autoRenew: action.value });
        case SET_REFRESH_POLICY:
            return __assign(__assign({}, state), { refresh: action.value });
        default:
            return state;
    }
};
export default reducer;
