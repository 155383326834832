var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from 'components/atoms/Button';
import OverviewSection from 'components/organisms/OverviewSection';
import ExcessSection from 'components/organisms/ExcessSection';
import { Reminder } from 'components/organisms/NotificationsSection';
import PolicyHolderSection from 'components/organisms/PolicyHolderSection';
import DriversSection from 'components/organisms/DriversSection';
import OvernightParkingSection from 'components/organisms/OvernightParkingSection';
import SavedQuoteSection from 'components/organisms/SavedQuoteSection';
import PolicyDetailsTabs from 'components/organisms/PolicyDetailsTabs';
import { drivingDataClient, getHelpfulInformationContent, getPageContent, getRenewalForAmend, mtaTokenRefresh, fetchSavedQuotes, fetchQuotePortalDomain, getRenewalDirectBuy, client, updateAutomaticRenewal, } from 'services';
import { ContentPageURL, NotificationLevel, SessionStorageKey, } from 'types';
import { getExcessData, getDriversData, getOvernightParkingData } from './mapPolicy';
import ShareComponent from 'pages/ShareComponent';
import { getFriendlyDisplayDate } from 'utils/date';
import { getTokens } from 'utils';
import HelpfulInformation from 'components/molecules/HelpfulInformation';
import { onQuoteAction, createNewQuoteForAccount } from 'pages/ShareComponent/shareFunctions';
import DocumentsSection from 'components/organisms/DocumentsSection';
import styled from 'styled-components';
import { getDriverDisplayValuesForList } from 'guidewirePolicyUtils';
import MtaModal from 'components/molecules/MtaModal';
import { color } from 'styles';
import ReactMarkdown from 'react-markdown';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { TagManagerArgs } from 'analytics';
import { TempRenewalModal } from './TempRenewalModal';
import MyRenewalPreferencesSection from 'components/organisms/MyRenewalPreferencesSection';
import { authorizationCheck } from 'services/refreshToken';
var TwentyThousandBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #204054;\n  color: ", ";\n  padding: 30px;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  margin-bottom: 20px;\n\n  p {\n    font-weight: 300;\n  }\n\n  h2 {\n    margin-bottom: 20px;\n    font-weight: 500;\n  }\n"], ["\n  background-color: #204054;\n  color: ", ";\n  padding: 30px;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  margin-bottom: 20px;\n\n  p {\n    font-weight: 300;\n  }\n\n  h2 {\n    margin-bottom: 20px;\n    font-weight: 500;\n  }\n"])), color.white);
var MyPolicyPage = function (_a) {
    var currentPolicy = _a.currentPolicy, renewalPolicy = _a.renewalPolicy, currentPolicyIndex = _a.currentPolicyIndex, policyIds = _a.policyIds, covered = _a.covered, accountNumber = _a.accountNumber, policySummaries = _a.policySummaries, setDriverIndex = _a.setDriverIndex, setAmendRenewalDetails = _a.setAmendRenewalDetails, setAmendedRenewalQuote = _a.setAmendedRenewalQuote, alternateRenewalPolicy = _a.alternateRenewalPolicy, autoRenew = _a.autoRenew, refresh = _a.refresh, setRefreshPolicy = _a.setRefreshPolicy;
    var history = useHistory();
    var _b = useState(undefined), contentSections = _b[0], setContentSections = _b[1];
    var _c = useState(undefined), headerContent = _c[0], setHeaderContent = _c[1];
    var _d = useState(0), remainingMiles = _d[0], setRemaingMiles = _d[1];
    var _e = useState(0), consumedMiles = _e[0], setConsumedMiles = _e[1];
    var _f = useState(30), daysToExpiration = _f[0], setDaysToExpiration = _f[1];
    var _g = useState(undefined), savedQuotes = _g[0], setSavedQuotes = _g[1];
    var _h = useState(''), quotePortalUrl = _h[0], setQuotePortalUrl = _h[1];
    var _j = useState(undefined), helpfulInfoContent = _j[0], setHelpfulInfoContent = _j[1];
    var _k = useState(undefined), mtaModal = _k[0], setMtaModal = _k[1];
    var _l = useState(false), mtaModalOpen = _l[0], setMtaModalOpen = _l[1];
    var _m = useState(''), mtaModalRedirect = _m[0], setMtaModalRedirect = _m[1];
    var _o = useState(undefined), twentyThousandBannerText = _o[0], setTwentyThousandBannerText = _o[1];
    var _p = useState(undefined), removeDriverModal = _p[0], setRemoveDriverModal = _p[1];
    var _q = useState(false), removeDriverModalOpen = _q[0], setRemoveDriverModalOpen = _q[1];
    var _r = useState(''), removeDriverName = _r[0], setRemoveDriverName = _r[1];
    var _s = useState(false), showTempRenewalPopup = _s[0], setshowTempRenewalPopup = _s[1];
    var _t = useState(true), showNewPolicyButton = _t[0], setNewPolicyButtonDisplay = _t[1];
    var fetchBonusMilesCallback = function (bonusMilesResponse) {
        if (bonusMilesResponse) {
            var availableMiles = bonusMilesResponse.availableMiles, consumedMiles_1 = bonusMilesResponse.consumedMiles;
            setRemaingMiles(availableMiles);
            setConsumedMiles(consumedMiles_1);
        }
    };
    useEffect(function () {
        var tokens = getTokens();
        getPageContent(ContentPageURL.MY_POLICY_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200) {
                setContentSections(payload === null || payload === void 0 ? void 0 : payload.contentSections);
                setHeaderContent(payload === null || payload === void 0 ? void 0 : payload.header);
            }
        });
        if (mtaModal === undefined) {
            getPageContent(ContentPageURL.MTA_MODAL).then(function (result) {
                var status = result.status, payload = result.payload;
                if (status === 200) {
                    setMtaModal((payload === null || payload === void 0 ? void 0 : payload.modals) ? payload.modals[0] : undefined);
                    setRemoveDriverModal((payload === null || payload === void 0 ? void 0 : payload.modals) ? payload.modals[1] : undefined);
                }
            });
        }
        if (twentyThousandBannerText === undefined && renewalPolicy) {
            if (renewalPolicy.productMiles >= 20000)
                getPageContent(ContentPageURL.TWENTY_THOUSAND_BANNER).then(function (result) {
                    var status = result.status, payload = result.payload;
                    if (status === 200) {
                        setTwentyThousandBannerText(payload === null || payload === void 0 ? void 0 : payload.contentSections[0]);
                    }
                });
        }
        if (tokens && policyIds) {
            var accessToken = tokens.accessToken, refreshToken = tokens.refreshToken;
            drivingDataClient.fetchBonusMiles(policyIds[currentPolicyIndex], accessToken, refreshToken, fetchBonusMilesCallback);
            if (!savedQuotes) {
                fetchSavedQuotes(accessToken, refreshToken, function (quotes) { return setSavedQuotes(quotes); });
            }
            if (quotePortalUrl === '') {
                fetchQuotePortalDomain(accessToken, refreshToken, function (url) { return setQuotePortalUrl(url); });
            }
        }
        if (currentPolicy) {
            setDaysToExpiration(moment(new Date(currentPolicy.expiration)).diff(moment(new Date()), 'days'));
        }
        var specificDate = moment('2024-08-01', 'YYYY-MM-DD');
        var isEffectiveDateBol = moment().isBefore(specificDate);
        if (isEffectiveDateBol === true && currentPolicy) {
            setNewPolicyButtonDisplay(true);
        }
        else if (currentPolicy) {
            setNewPolicyButtonDisplay(false);
        }
        if (helpfulInfoContent === undefined) {
            getHelpfulInformationContent().then(function (response) { return setHelpfulInfoContent(response.data); });
        }
        if (refresh === true) {
            setRefreshPolicy();
            history.go(0);
        }
        window.addEventListener('message', function (event) {
            if (event.data === '{"error":false,"message":"success"}') {
                history.push('/accepted');
            }
            if (event.data === '{"error":false,"message":"failure"}') {
                history.push('/error');
            }
        });
    }, [currentPolicy, policyIds, currentPolicyIndex, renewalPolicy, autoRenew]);
    // const tempRenewalModal = () => {
    //   setshowTempRenewalPopup(true)
    // }
    var onAmendDetails = function (calculateQuote) { return __awaiter(void 0, void 0, void 0, function () {
        var tokens, policyNumber, renewalDetails, refresh_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tokens = getTokens();
                    if (!tokens) return [3 /*break*/, 3];
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    return [4 /*yield*/, getRenewalForAmend(policyNumber, calculateQuote, tokens.accessToken)];
                case 1:
                    renewalDetails = _a.sent();
                    if (renewalDetails.status === 200) {
                        if (calculateQuote === false) {
                            setAmendRenewalDetails(renewalDetails.data);
                            history.push('/renewal-about-you');
                        }
                        if (calculateQuote === true) {
                            setAmendedRenewalQuote(renewalDetails.data);
                            history.push('/quote-summary-direct');
                        }
                    }
                    // TODO: avoid error handling in the page level. In the page level should only deal with
                    // besiness logic. For example refresh token is a general handling. This should be wrapped
                    // in an API client. Consider using axios interceptor.
                    if (renewalDetails.status === 500 || renewalDetails.data.status === 500) {
                        history.push('/error');
                    }
                    if (!(renewalDetails.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh_1 = _a.sent();
                    if (refresh_1 === 200) {
                        onAmendDetails(calculateQuote);
                    }
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onRenewPolicy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var tokens, policyNumber, renewalDetails, refresh_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tokens = getTokens();
                    if (!tokens) return [3 /*break*/, 3];
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    return [4 /*yield*/, getRenewalDirectBuy(policyNumber, tokens.accessToken)];
                case 1:
                    renewalDetails = _a.sent();
                    if (renewalDetails.status === 200) {
                        setAmendedRenewalQuote(renewalDetails.data);
                        history.push('/quote-summary-direct');
                    }
                    if (renewalDetails.status === 500 || renewalDetails.data.status === 500) {
                        history.push('/error');
                    }
                    if (!(renewalDetails.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh_2 = _a.sent();
                    if (refresh_2 === 200) {
                        onRenewPolicy();
                    }
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleMtaClickMyPolicy = function (pushTo) {
        setMtaModalRedirect(pushTo);
        setMtaModalOpen(true);
    };
    var handleMtaModalContinue = function () { return history.push(mtaModalRedirect); };
    var removeDriver = function (index) {
        setDriverIndex(index);
        setRemoveDriverModalOpen(true);
        setRemoveDriverName(currentPolicy.drivers[index].displayName);
    };
    var onAutoRenewal = function (value) {
        var data = value.toUpperCase() === 'OPT IN' ? true : false;
        var token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
        updateAutomaticRenewal(token, data, currentPolicy.policyNumber.slice(4)).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            var refresh_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (response.status === 200) {
                            history.go(0);
                        }
                        if (!(response.status === 401)) return [3 /*break*/, 2];
                        return [4 /*yield*/, mtaTokenRefresh()];
                    case 1:
                        refresh_3 = _a.sent();
                        if (refresh_3 === 200)
                            onAutoRenewal(value);
                        else {
                            history.push('/login');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    var renderSection = function (section) {
        var _a, _b;
        if ('remainingMilesContent' in section) {
            var displayName = currentPolicy.vehicleInfo.displayName || '()';
            var carplate = displayName.substring(displayName.lastIndexOf('(') + 1, displayName.lastIndexOf(')'));
            return (React.createElement(React.Fragment, { key: "overview" },
                React.createElement(OverviewSection, { carplate: carplate, content: section, remainingMiles: remainingMiles, consumedMiles: consumedMiles, topupMilesButton: React.createElement(Button, { disabled: currentPolicy.status === 'Lapsed', buttonStyle: "secondary", onClick: function () {
                            history.push('/topupmiles');
                            TagManagerArgs('Button', '/mypolicy', section.buttonText);
                        } }, section.buttonText) }),
                renewalPolicy && renewalPolicy.productMiles >= 20000 && (React.createElement(Layout, null,
                    React.createElement(PaddingHorizontal, null,
                        React.createElement(TwentyThousandBanner, null, twentyThousandBannerText && React.createElement(ReactMarkdown, { source: twentyThousandBannerText.body })))))));
        }
        if ('formGroups' in section) {
            return (React.createElement(PolicyDetailsTabs, { key: "tabs", currentPolicy: currentPolicy, renewalPolicy: renewalPolicy, alternateRenewalPolicy: alternateRenewalPolicy, content: section, onAmendDetails: onAmendDetails, onRenewPolicy: onRenewPolicy, onChangeCar: handleMtaClickMyPolicy }));
        }
        if ('otherExcessTitle' in section) {
            return (React.createElement(ExcessSection, { key: "MyExcess", data: getExcessData(currentPolicy), content: section }));
        }
        if ('formGroupId' in section && section.formGroupId === 'policyHolderInfoDisplay') {
            var policyHolder = currentPolicy.policyHolder;
            return (React.createElement(PolicyHolderSection, { key: "PolicyHolder", content: section, data: getDriverDisplayValuesForList(policyHolder) }));
        }
        if ('mainDriverTitle' in section) {
            return (React.createElement(DriversSection, { key: "DriversSection", data: getDriversData(currentPolicy), content: section, setDriverIndex: setDriverIndex, policyIsLapsed: currentPolicy.status === 'Lapsed' || currentPolicy.drivers.length === 5, onAddDriver: handleMtaClickMyPolicy, onRemoveDriver: removeDriver }));
        }
        if ('labels' in section && 'buttonText' in section) {
            return (React.createElement(React.Fragment, null,
                React.createElement(OvernightParkingSection, { key: "OvernightParking", content: section, data: getOvernightParkingData(currentPolicy), onChangeAddress: handleMtaClickMyPolicy, policyIsLapsed: currentPolicy.status === 'Lapsed' }),
                autoRenew !== undefined && (React.createElement(MyRenewalPreferencesSection, { confirmRenewalChange: function (value) { return onAutoRenewal(value); }, selected: autoRenew === true ? 'Opt in' : 'Opt out' }))));
        }
        if ('documentName' in section && policySummaries) {
            var currentPolicySummary = policySummaries[currentPolicyIndex];
            var documentsCurrentPolicy = (_a = currentPolicySummary.periods.find(function (period) { return period.status === 'In Force'; })) === null || _a === void 0 ? void 0 : _a.documents;
            var documentsRenewal = (_b = currentPolicySummary.periods.find(function (period) { return period.status === 'Renewing'; })) === null || _b === void 0 ? void 0 : _b.documents;
            return documentsCurrentPolicy ? (React.createElement(DocumentsSection, { key: "documents", content: section, documents: documentsCurrentPolicy, renewalDocuments: documentsRenewal, downloadDocument: function (doc) { return __awaiter(void 0, void 0, void 0, function () {
                    var accessToken, refreshToken;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, authorizationCheck()];
                            case 1:
                                _a.sent();
                                accessToken = sessionStorage.getItem('access_token');
                                refreshToken = sessionStorage.getItem('refresh_token');
                                client.openDocumentForPolicy(doc.docUID.replace('==', ''), doc.sessionID, doc.name, doc.mimeType, doc.policyNumber, accessToken, refreshToken);
                                return [2 /*return*/];
                        }
                    });
                }); } })) : null;
        }
        if ('noQuoteText' in section && savedQuotes) {
            return (React.createElement(SavedQuoteSection, { key: "savedquote", content: section, savedQuotes: savedQuotes, onQuoteAction: function (action, postalCode, dateOfBirth, quoteNumber) {
                    return onQuoteAction(quotePortalUrl, action, postalCode, dateOfBirth, covered, quoteNumber);
                }, onGetNewQuote: function () { return createNewQuoteForAccount(accountNumber, quotePortalUrl, covered); }, newButtonDisplay: showNewPolicyButton }));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TempRenewalModal, { open: showTempRenewalPopup, close: setshowTempRenewalPopup }),
        currentPolicy && (daysToExpiration === 30 || daysToExpiration === 22) && (React.createElement(Reminder, { level: NotificationLevel.WARNING, title: "Policy renewal", message: "This policy is due for renewal on " + getFriendlyDisplayDate(currentPolicy.expiration) })),
        React.createElement(ShareComponent, { headerContent: headerContent }),
        contentSections && currentPolicy && contentSections.map(function (content) { return renderSection(content); }),
        helpfulInfoContent && React.createElement(HelpfulInformation, { content: helpfulInfoContent[0] }),
        mtaModal && (React.createElement(MtaModal, { modalOpen: mtaModalOpen, setModalOpen: setMtaModalOpen, modalContent: mtaModal, modalContinue: handleMtaModalContinue })),
        removeDriverModal && (React.createElement(MtaModal, { modalOpen: removeDriverModalOpen, setModalOpen: setRemoveDriverModalOpen, modalContent: removeDriverModal, modalContinue: function () {
                history.push('/remove-driver');
            }, removeDriverName: removeDriverName }))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    renewalPolicy: state.renewalPolicy,
    alternateRenewalPolicy: state.alternateRenewalPolicy,
    currentPolicyIndex: state.currentPolicyIndex,
    policyIds: state.policyIds,
    covered: state.currentlyCovered,
    accountNumber: state.accountNumber,
    policySummaries: state.policySummaries,
    autoRenew: state.autoRenew,
    refresh: state.refresh,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setDriverIndex: function (index) { return dispatch(actions.setDriverIndex(index)); },
    setAmendRenewalDetails: function (data) { return dispatch(actions.setAmendRenewalDetails(data)); },
    setAmendedRenewalQuote: function (quote) { return dispatch(actions.setAmendedRenewalQuote(quote)); },
    setRefreshPolicy: function () { return dispatch(actions.setRefreshPolicy(false)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MyPolicyPage);
var templateObject_1;
