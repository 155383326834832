var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormRadio, getSingleFieldContent, Group } from 'components/organisms/Form';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import SectionHeader from 'components/molecules/SectionHeader';
import Button from 'components/atoms/Button';
import { TagManagerArgs } from 'analytics';
var ButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
export var MyCommunicationPrefs = function (_a) {
    var communicationsContent = _a.communicationsContent, control = _a.control, disableConfirm = _a.disableConfirm, confirmCommunicationChange = _a.confirmCommunicationChange;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var documentationPreference = getSingleFieldContent('documentationPreference', communicationsContent.fields);
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(SectionHeader, { title: communicationsContent.title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
            open && (React.createElement(React.Fragment, null,
                React.createElement(Group, { noBorder: true, introText: communicationsContent.text },
                    React.createElement(FormRadio, { options: documentationPreference.options, name: documentationPreference.value, label: documentationPreference.label, control: control }),
                    disableConfirm && (React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                confirmCommunicationChange();
                                TagManagerArgs('Button', window.location.pathname + " - communication Preferences", 'Confirm change');
                            } }, "Confirm change")))))))));
};
var templateObject_1;
