var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
import { FormAutoComplete, FormDatePicker, FormInput, FormSelect, getSingleFieldContent, Group, } from 'components/organisms/Form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var DriverPersonalDetails = function (_a) {
    var content = _a.content, control = _a.control, register = _a.register, fixedValues = _a.fixedValues, _b = _a.hideEmailField, hideEmailField = _b === void 0 ? false : _b, _c = _a.noPersonalTitle, noPersonalTitle = _c === void 0 ? false : _c, errors = _a.errors;
    var title = getSingleFieldContent('title', content.fields);
    var firstName = getSingleFieldContent('firstName', content.fields);
    var lastName = getSingleFieldContent('lastName', content.fields);
    var dateOfBirth = getSingleFieldContent('dateOfBirth', content.fields);
    var maritalStatus = getSingleFieldContent('maritalStatus', content.fields);
    var maritalStatusOptions = getGwDropdownValues(maritalStatus.gwTypelist);
    var gender = getSingleFieldContent('gender', content.fields);
    var genderOptions = getGwDropdownValues(gender.gwTypelist);
    var occupation = getSingleFieldContent('occupation', content.fields);
    var occupationOptions = getGwDropdownValues(occupation.gwTypelist);
    var ukResident5Years = getSingleFieldContent('ukResident5Years', content.fields);
    var emailAddress1 = getSingleFieldContent('emailAddress1', content.fields);
    return (React.createElement(Root, null,
        React.createElement(Group, { title: noPersonalTitle === false ? content.title : undefined },
            React.createElement(FormInput, { label: title.label, toolTip: title.toolTip, name: title.value, value: fixedValues.title, readOnly: true }),
            React.createElement(FormInput, { label: firstName.label, name: firstName.value, toolTip: firstName.toolTip, value: fixedValues.firstName, readOnly: true }),
            React.createElement(FormInput, { label: lastName.label, name: lastName.value, toolTip: lastName.toolTip, value: fixedValues.lastName, readOnly: true }),
            React.createElement(FormDatePicker, { label: dateOfBirth.label, name: dateOfBirth.value, toolTip: dateOfBirth.toolTip, value: fixedValues.dateOfBirth, disabled: true }),
            React.createElement(FormSelect, { label: maritalStatus.label, options: maritalStatusOptions, toolTip: maritalStatus.toolTip, name: maritalStatus.value, control: control, isRequired: maritalStatus.required, error: errors[maritalStatus.value] }),
            React.createElement(FormSelect, { label: gender.label, options: genderOptions, toolTip: gender.toolTip, name: gender.value, control: control, isRequired: gender.required, error: errors[gender.value] }),
            React.createElement(FormAutoComplete, { label: occupation.label, options: occupationOptions, toolTip: occupation.toolTip, name: occupation.value, control: control, isRequired: occupation.required, error: errors[occupation.value] }),
            React.createElement(FormInput, { label: ukResident5Years.label, name: ukResident5Years.value, toolTip: ukResident5Years.toolTip, value: fixedValues.ukResident5Years, readOnly: true }),
            hideEmailField === false && (React.createElement(FormInput, { label: emailAddress1.label, name: emailAddress1.value, toolTip: emailAddress1.toolTip, control: register, isRequired: emailAddress1.required, error: errors[emailAddress1.value] })))));
};
var templateObject_1;
