var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size/throttled';
import { Bar, BarChart as ReactBarChart, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { fontSize, fontWeight, screenLargeMin } from 'styles';
import TooltipContent from './TooltipContent';
import BarShape from './BarShape';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .axis-label {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  .recharts-yAxis {\n    margin-left: 60px;\n  }\n"], ["\n  .axis-label {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  .recharts-yAxis {\n    margin-left: 60px;\n  }\n"])), fontSize.fs12, fontWeight.medium);
var BarChart = function (_a) {
    var data = _a.data, xAxisLabel = _a.xAxisLabel, yAxisLabel = _a.yAxisLabel, onClick = _a.onClick;
    var _b = useState(false), showTooltip = _b[0], setTooltipShow = _b[1];
    var _c = useState(''), barHovered = _c[0], setHoveredBar = _c[1];
    var width = useWindowSize()[0];
    var getWidth = function () {
        if (width <= screenLargeMin) {
            return width - 30;
        }
        if (width > screenLargeMin && width < 1440) {
            return width - 270;
        }
        return 1170;
    };
    return (React.createElement(Root, null,
        React.createElement(ReactBarChart, { layout: "vertical", barSize: 30, width: getWidth(), height: 600, data: data, margin: { top: 25, right: 30, left: 20, bottom: 25 } },
            React.createElement(XAxis, { dataKey: "miles", type: "number", tick: { fontSize: '14px', fill: 'rgba(32, 32, 32, 1)' }, domain: [0, 100], ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100], axisLine: { stroke: 'rgba(137, 137, 137)' }, padding: { left: 0, right: 10 }, label: {
                    value: xAxisLabel,
                    position: 'insideBottom',
                    offset: -20,
                    className: 'axis-label',
                } }),
            React.createElement(YAxis, { dataKey: "month", type: "category", tick: { fontSize: '14px', fill: 'rgba(32, 32, 32, 1)' }, tickLine: false, axisLine: { stroke: 'rgba(137, 137, 137)' }, padding: { top: 20, bottom: 10 }, label: { value: yAxisLabel, position: 'insideTop', offset: -20, className: 'axis-label' } }),
            showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) { return React.createElement(TooltipContent, __assign({}, props)); }, isAnimationActive: false })),
            React.createElement(Bar, { dataKey: "miles", onMouseOver: function (target) {
                    setTooltipShow(true);
                    setHoveredBar(target.month);
                }, onMouseLeave: function () {
                    setTooltipShow(false);
                    setHoveredBar('');
                }, onMouseEnter: function (target) {
                    setTooltipShow(true);
                    setHoveredBar(target.month);
                }, onClick: function (target) { return onClick(target); }, shape: function (props) { return (React.createElement(BarShape, { rectangle: true, fill: props.month === barHovered ? '#52be80' : '#268657', x: props.x || 0, y: props.y || 0, width: props.width || 0, height: props.height || 0, radius: 15 })); } }))));
};
export default BarChart;
var templateObject_1;
