export var SET_STATE_INIT = 'itb-policy/SET_STATE_INIT';
export var SET_POLICY_IDS = 'itb-policy/SET_POLICY_IDS';
export var SET_CURRENT_POLICY_INDEX = 'itb-policy/SET_CURRENT_POLICY_INDEX';
export var SET_CURRENT_POLICY = 'itb-policy/SET_CURRENT_POLICY';
export var SET_RENEWAL_POLICY = 'itb-policy/SET_RENEWAL_POLICY';
export var SET_ALTERNATE_RENEWAL_POLICY = 'itb-policy/SET_ALTERNATE_RENEWAL_POLICY ';
export var SET_PAGE_HEADER_CONTENT = 'itb-policy/SET_PAGE_HEADER_CONTENT';
export var SET_QUOTE = 'itb-policy/SET_QUOTE';
export var SET_MTA_EFFECTIVE_DATE = 'itb-policy/SET_MTA_EFFECTIVE_DATE';
export var SET_MTA = 'itb-policy/SET_MTA';
export var SET_MTA_FORM_DETAILS = 'itb-policy/SET_MTA_FORM_DETAILS';
export var SET_MULTI_MTA_RESPONSE = 'itb-policy/SET_MULTI_MTA_RESPONSE';
export var SET_MULTI_DRIVERS = 'itb-policy/SET_MULTI_DRIVERS';
export var REMOVE_MULTI_DRIVER = 'itb-policy/REMOVE_MULTI_DRIVER';
export var SET_DRIVER_INDEX = 'itb-policy/SET_DRIVER_INDEX';
export var SET_DRIVER_FORM_DETAILS = 'itb-policy/SET_DRIVER_FORM_DETAILS';
export var SET_CONVICTION_AND_CLAIMS_FORM_DETAILS = 'itb-policy/SET_CONVICTION_AND_CLAIMS_FORM_DETAILS';
export var SET_AMEND_RENEWAL_DETAILS = 'itb-policy/SET_AMEND_RENEWAL_DETAILS';
export var SET_AMEND_RENEWAL_STATE = 'itb-policy/SET_AMEND_RENEWAL_STATE';
export var SET_AMENDED_RENEWAL_QUOTE = 'itb-policy/SET_AMENDED_RENEWAL_QUOTE';
export var SET_ACCOUNT_INFO = 'itb-policy/SET_ACCOUNT_INFO';
export var SET_CURRENTLT_COVERED = 'itb-policy/SET_CURRENTLT_COVERED';
export var SET_ACCOUNT_NUMBER = 'itb-policy/SET_ACCOUNT_NUMBER';
export var SET_POLICY_SUMMARIES = 'itb-policy/SET_POLICY_SUMMARIES';
export var SET_TOPUP_ID = 'itb-policy/SET_TOPUP_ID';
export var SET_AUTORENEW_CHOICE = 'itb-policy/SET_AUTORENEW_CHOICE';
export var SET_REFRESH_POLICY = 'itb-policy/SET_REFRESH_POLICY';
