var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { color, fontWeight, fontFamily, media } from 'styles';
import { NotificationLevel } from 'types';
import WarningFilledIcon from 'assets/warning_filled.svg';
import WarningIcon from 'assets/warning.svg';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n  padding: 20px 0;\n\n  > * {\n    ", "\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n  padding: 20px 0;\n\n  > * {\n    ",
    "\n  }\n"])), function (props) { return (props.red ? 'white' : color.black); }, function (props) { return (props.red ? 'rgba(173,31,31,1.0)' : 'rgba(255,195,0,0.8)'); }, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n    "], ["\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n    "]))));
var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 20px;\n\n  ", "\n"], ["\n  margin-bottom: 20px;\n\n  ",
    "\n"])), media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    margin-bottom: 0px;\n  "], ["\n    flex: 1;\n    margin-bottom: 0px;\n  "]))));
var Header = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border-bottom: ", ";\n  font-weight: ", ";\n  padding: 0 14px;\n"], ["\n  display: flex;\n  align-items: center;\n  border-bottom: ", ";\n  font-weight: ", ";\n  padding: 0 14px;\n"])), function (props) { return (props.red ? '1px solid white' : "1px solid " + color.black); }, fontWeight.medium);
var TitleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: 16px;\n"], ["\n  margin-left: 16px;\n"])));
var MessageWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  padding: 8px 18px 0 18px;\n"], ["\n  font-size: 14px;\n  padding: 8px 18px 0 18px;\n"])));
var ActionButton = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 24px;\n  color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  padding: 12px 24px;\n  text-align: center;\n\n  ", "\n"], ["\n  background: ", ";\n  border-radius: 24px;\n  color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  padding: 12px 24px;\n  text-align: center;\n\n  ",
    "\n"])), function (props) { return (props.red ? 'white' : color.black); }, function (props) { return (props.red ? color.black : 'white'); }, fontFamily.condensed, media.medium(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: inline-block;\n    margin-top: 8px;\n    margin-left: 8px;\n  "], ["\n    display: inline-block;\n    margin-top: 8px;\n    margin-left: 8px;\n  "]))));
var Reminder = function (_a) {
    var title = _a.title, message = _a.message, level = _a.level, buttonText = _a.buttonText, onButtonClick = _a.onButtonClick;
    return (React.createElement(Layout, null,
        React.createElement(Root, { red: level === NotificationLevel.ERROR },
            React.createElement(PaddingHorizontal, null,
                React.createElement(ContentContainer, null,
                    React.createElement(Header, { red: level === NotificationLevel.ERROR },
                        level === NotificationLevel.ERROR ? React.createElement(WarningIcon, null) : React.createElement(WarningFilledIcon, null),
                        React.createElement(TitleWrapper, null, title)),
                    React.createElement(MessageWrapper, null, message)),
                onButtonClick && (React.createElement(ActionButton, { red: level === NotificationLevel.ERROR, onClick: function () { return onButtonClick(); } }, buttonText))))));
};
export default Reminder;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
