var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PageHeader from 'components/organisms/PageHeader';
import DashboardPage from 'pages/DashboardPage';
import DrivingDataPage from 'pages/DrivingDataPage';
import ImportantInformationPage from 'pages/ImportantInformationPage';
import { LoginPage, ResetPasswordPage } from 'pages/LoginPage';
import PaymentCompletePage from 'pages/PaymentCompletePage';
import MtaOvernightParkingPage from 'pages/MtaOvernightParkingPage';
import MtaChangeVehiclePage from 'pages/MtaChangeVehiclePage';
import MyPolicyPage from 'pages/MyPolicyPage';
import QuotePage from 'pages/QuotePage';
import { actions } from 'reduxStore';
import { getAccountInfo, getPageContent, getTypeKeys, mtaTokenRefresh } from 'services';
import { gaSessionIdToBehaviourService } from 'services/drivingdataAPIs';
import { ContentPageURL, SessionStorageKey } from 'types';
import GlobalStyle from 'styles';
import IdleComponent from './pages/IdleComponent';
import MessagesPage from './pages/MessagesPage';
import MtaOtherChanges from './pages/MtaOtherChanges';
import MtaRemoveDriverPage from 'pages/MtaRemoveDriver';
import MtaAmendDriverPage from 'pages/MtaAmendDriverPage';
import ErrorPage from './pages/ErrorPage';
import QuoteSummaryPage from 'pages/QuoteSummaryPage';
import SagePaySuccess from './pages/SagePaySuccess';
import SagePayFailure from 'pages/SagePayFailure/SagePayFailure';
import MtaAddDriverPage from 'pages/MtaAddDriverPage';
import RenewalAddDriverPage from 'pages/RenewalAddDriverPage';
import MyAccountPage from 'pages/MyAccountPage';
import ErrorPagePayments from 'pages/ErrorPagePayments';
import RenewalOne from 'pages/RenewalOne';
import RenewalTwo from 'pages/RenewalTwo';
import RenewalEditDriver from 'pages/RenewalEditDriverPage';
import QuoteSummaryDirectPage from 'pages/QuoteSummaryDirectPage';
import RenewalPaymentSummary from 'pages/RenewalPaymentSummary';
import RenewalDebitDetails from 'pages/RenewalDebitDetails';
import RenewalDebitMandate from 'pages/RenewalDebitMandate';
import RenewalPaymentComplete from 'pages/RenewalPaymentComplete';
import TagManager from 'react-gtm-module';
import TopupMilesPage from 'pages/TopupMilesPage';
import Footer from 'components/molecules/Footer';
import { TagManagerArgs } from 'analytics';
// GTM / GA implementation
// This takes the client ID from the GA cookie.
// sendClientId() forwards this id and policy number to the behaviour service
var cookies = document.cookie.split(';');
var sessionId = undefined;
cookies.map(function (item) {
    if (item.includes('_ga=')) {
        sessionId = item.slice(11);
    }
});
var tagManagerArgs = {
    // gtmId: GtmId.DEV,
    gtmId: 'GTM-M9HCPR',
};
TagManager.initialize(tagManagerArgs);
var App = function (_a) {
    var currentPolicy = _a.currentPolicy, pageHeaderContent = _a.pageHeaderContent, setPageHeaderContent = _a.setPageHeaderContent, resetReduxStore = _a.resetReduxStore, setAccountInfo = _a.setAccountInfo, accountInfo = _a.accountInfo;
    var _b = useState(false), showHeader = _b[0], setHeaderShown = _b[1];
    var _c = useState('/dashboard'), uri = _c[0], setUri = _c[1];
    var _d = useState(undefined), lapsedPlusThirtyDays = _d[0], setLapsedPlusThirtyDays = _d[1];
    // number of milliseconds the user has to be in an "idle" state
    var _e = useState(undefined), idleTimeout = _e[0], setIdleTimeout = _e[1];
    var _f = useState(undefined), footerContent = _f[0], setFooterContent = _f[1];
    var _g = useState(''), scrollPercentage = _g[0], setScrollPercentage = _g[1];
    var location = useLocation();
    var history = useHistory();
    history.listen(function (location) { return setUri(location.pathname); });
    var currentScrollPercentage = function () {
        return (((document.documentElement.scrollTop + document.body.scrollTop) /
            (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
            100);
    };
    if (sessionId !== undefined && currentPolicy !== undefined) {
        var token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
        var data = {
            sessionId: sessionId,
            policyNumber: currentPolicy.policyNumber,
        };
        if (sessionStorage.getItem('CLIENT_ID') !== 'true') {
            gaSessionIdToBehaviourService(token, data);
            // Prevent the session call from being fired multiple times
            sessionStorage.setItem('CLIENT_ID', 'true');
            sessionId = undefined;
        }
    }
    useEffect(function () {
        if (!JSON.parse(sessionStorage.getItem(SessionStorageKey.TYPEKEYS))) {
            getTypeKeys();
        }
        var currentUri = location.pathname;
        setUri(currentUri);
        if (currentUri === '/') {
            var token = sessionStorage.getItem('access_token');
            token ? history.push('/dashboard') : history.push('/login');
        }
        if (currentUri !== '/login' && currentUri !== '/' && currentUri !== '/auth/resetpassword') {
            setHeaderShown(true);
            if (!pageHeaderContent) {
                getPageContent(ContentPageURL.PAGE_HEADER).then(function (result) {
                    var status = result.status, payload = result.payload;
                    if (status === 200 && payload) {
                        var contentSections = payload.contentSections;
                        setPageHeaderContent(contentSections[0]);
                    }
                });
            }
            if (!footerContent) {
                getPageContent(ContentPageURL.PAGE_FOOTER).then(function (result) {
                    var status = result.status, payload = result.payload;
                    if (status === 200 && payload) {
                        var contentSections = payload.contentSections;
                        setFooterContent(contentSections[0]);
                    }
                });
            }
            var getInfo_1 = function () { return __awaiter(void 0, void 0, void 0, function () {
                var token, accountInfo, refresh;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                            return [4 /*yield*/, getAccountInfo(token)];
                        case 1:
                            accountInfo = _a.sent();
                            if (accountInfo.status === 200) {
                                setAccountInfo(accountInfo.data);
                            }
                            if ((accountInfo.status !== undefined && accountInfo.status === 500) ||
                                (accountInfo.data.status !== undefined && accountInfo.data.status === 500)) {
                                history.push('/error');
                            }
                            if (!(accountInfo.data.status === 401)) return [3 /*break*/, 3];
                            return [4 /*yield*/, mtaTokenRefresh()];
                        case 2:
                            refresh = _a.sent();
                            if (refresh === 200)
                                getInfo_1();
                            else {
                                history.push('/login');
                            }
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            if (accountInfo === undefined)
                getInfo_1();
        }
        if (currentUri === '/dashboard' ||
            currentUri === '/mypolicy' ||
            currentUri === '/driving-data' ||
            currentUri === '/messages' ||
            currentUri === '/my-account') {
            // 29 min in ms - the modal is available for the remaining minute
            setIdleTimeout(29 * 60 * 1000);
            // 4 min in ms - the modal is available for the remaining minute
        }
        else
            setIdleTimeout(4 * 60 * 1000);
        if (currentPolicy && currentPolicy.status === 'Lapsed' && lapsedPlusThirtyDays === undefined) {
            setLapsedPlusThirtyDays(moment(currentPolicy.expiration)
                .add(30, 'days')
                .toISOString());
        }
        if (moment().isAfter(lapsedPlusThirtyDays)) {
            if (currentUri !== ('/dashboard' || '/messages')) {
                history.push('/dashboard');
            }
        }
        var onScroll = function () {
            var current = currentScrollPercentage().toFixed();
            if (parseInt(current) > 0 && parseInt(current) < 25 && scrollPercentage !== '25%') {
                setScrollPercentage('25%');
                TagManagerArgs('Page Scroll', window.location.pathname, '25%');
            }
            if (parseInt(current) >= 25 && parseInt(current) < 50 && scrollPercentage !== '50%') {
                setScrollPercentage('50%');
                TagManagerArgs('Page Scroll', window.location.pathname, '50%');
            }
            if (parseInt(current) >= 50 && parseInt(current) < 75 && scrollPercentage !== '75%') {
                setScrollPercentage('75%');
                TagManagerArgs('Page Scroll', window.location.pathname, '75%');
            }
            if (parseInt(current) >= 75 && parseInt(current) <= 100 && scrollPercentage !== '100%') {
                setScrollPercentage('100%');
                TagManagerArgs('Page Scroll', window.location.pathname, '100%');
            }
        };
        window.addEventListener('scroll', onScroll);
        return function () { return window.removeEventListener('scroll', onScroll); };
    }, [location, pageHeaderContent, uri, accountInfo, sessionId, currentPolicy, idleTimeout, scrollPercentage]);
    var onAccountClick = function () { return history.push('/my-account'); };
    var onLogout = function () {
        sessionStorage.clear();
        resetReduxStore();
        setHeaderShown(false);
        history.push('/login');
    };
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', minHeight: '100vh', height: '100%' } },
        React.createElement("div", null,
            React.createElement(GlobalStyle, null),
            location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/auth/resetpassword' && (React.createElement(React.Fragment, null, idleTimeout !== undefined && (React.createElement(IdleComponent, { timeOut: idleTimeout, onLogout: onLogout, refresh: mtaTokenRefresh })))),
            showHeader &&
                pageHeaderContent &&
                location.pathname !== '/payment-success' &&
                location.pathname !== '/' &&
                location.pathname !== '/login' && (React.createElement(PageHeader, { toHome: function () { return history.push('/dashboard'); }, onAccountClick: onAccountClick, onLogout: onLogout, onTabClick: function (uri) { return history.push(uri); }, uri: uri, content: pageHeaderContent, toAccountSettings: function () { return history.push('/my-account'); }, isLapsed: moment().isAfter(lapsedPlusThirtyDays) })),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/auth/resetpassword", component: ResetPasswordPage }),
                React.createElement(Route, { exact: true, path: "/login", component: LoginPage }),
                React.createElement(Route, { exact: true, path: "/dashboard", render: function () { return React.createElement(DashboardPage, { disableDueToLapse: moment().isAfter(lapsedPlusThirtyDays) }); } }),
                React.createElement(Route, { exact: true, path: "/mypolicy", component: MyPolicyPage }),
                React.createElement(Route, { exact: true, path: "/topupmiles", component: TopupMilesPage }),
                React.createElement(Route, { exact: true, path: "/driving-data", component: DrivingDataPage }),
                React.createElement(Route, { exact: true, path: "/messages", component: MessagesPage }),
                React.createElement(Route, { exact: true, path: "/my-account", component: MyAccountPage }),
                React.createElement(Route, { exact: true, path: "/change-vehicle", component: MtaChangeVehiclePage }),
                React.createElement(Route, { exact: true, path: "/overnight-parking", component: MtaOvernightParkingPage }),
                React.createElement(Route, { exact: true, path: "/add-driver", component: MtaAddDriverPage }),
                React.createElement(Route, { exact: true, path: "/other-changes", component: MtaOtherChanges }),
                React.createElement(Route, { exact: true, path: "/remove-driver", component: MtaRemoveDriverPage }),
                React.createElement(Route, { exact: true, path: "/amend-driver", component: MtaAmendDriverPage }),
                React.createElement(Route, { exact: true, path: "/your-quote", component: QuotePage }),
                React.createElement(Route, { exact: true, path: "/important-information", component: ImportantInformationPage }),
                React.createElement(Route, { exact: true, path: "/accepted", component: PaymentCompletePage }),
                React.createElement(Route, { exact: true, path: "/error-payments", component: ErrorPagePayments }),
                React.createElement(Route, { exact: true, path: "/payment-success", component: SagePaySuccess }),
                React.createElement(Route, { exact: true, path: "/payment-failure", component: SagePayFailure }),
                React.createElement(Route, { exact: true, path: "/error", component: ErrorPage }),
                React.createElement(Route, { exact: true, path: "/renewal-about-you", component: RenewalOne }),
                React.createElement(Route, { exact: true, path: "/renewal-add-driver", component: RenewalAddDriverPage }),
                React.createElement(Route, { exact: true, path: "/renewal-edit-driver", component: RenewalEditDriver }),
                React.createElement(Route, { exact: true, path: "/renewal-your-car", component: RenewalTwo }),
                React.createElement(Route, { exact: true, path: "/quote-summary", component: QuoteSummaryPage }),
                React.createElement(Route, { exact: true, path: "/quote-summary-direct", component: QuoteSummaryDirectPage }),
                React.createElement(Route, { exact: true, path: "/renewal-payment-summary", component: RenewalPaymentSummary }),
                React.createElement(Route, { exact: true, path: "/renewal-monthly-details", component: RenewalDebitDetails }),
                React.createElement(Route, { exact: true, path: "/renewal-monthly-mandate", component: RenewalDebitMandate }),
                React.createElement(Route, { exact: true, path: "/payment-complete", component: RenewalPaymentComplete }))),
        location.pathname !== '/login' && location.pathname !== '/' && footerContent && (React.createElement(Footer, { footerContent: footerContent }))));
};
var mapStateToProps = function (state) { return ({
    pageHeaderContent: state.pageHeaderContent,
    accountInfo: state.accountInfo,
    currentPolicy: state.currentPolicy,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setPageHeaderContent: function (content) { return dispatch(actions.setPageHeaderContent(content)); },
    resetReduxStore: function () { return dispatch(actions.resetStore()); },
    setAccountInfo: function (content) { return dispatch(actions.setAccountInfo(content)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(App);
