var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Tooltip from 'components/molecules/Tooltip';
import { fontSize, fontWeight, media } from 'styles';
export var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  cursor: ", ";\n  font-size: ", ";\n  justify-content: space-between;\n  padding: 16px 0;\n\n  ", "\n"], ["\n  display: flex;\n  cursor: ", ";\n  font-size: ", ";\n  justify-content: space-between;\n  padding: 16px 0;\n\n  ",
    "\n"])), function (props) { return (props.expandable ? 'pointer' : 'inherit'); }, fontSize.fs14, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: ", ";\n    padding: 16px;\n  "], ["\n    font-size: ", ";\n    padding: 16px;\n  "])), fontSize.fs16));
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var TooltipWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 10px;\n\n  ", "\n"], ["\n  margin-left: 10px;\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-left: 13px;\n  "], ["\n    margin-left: 13px;\n  "]))));
var IconWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var ValueWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.light);
var ListRow = function (_a) {
    var label = _a.label, labelStyle = _a.labelStyle, value = _a.value, valueStyle = _a.valueStyle, info = _a.info, icon = _a.icon, style = _a.style, onClick = _a.onClick, onIconClick = _a.onIconClick;
    return (React.createElement(Root, { style: style, expandable: onClick !== undefined && !onIconClick, onClick: function () { return !onIconClick && onClick && onClick(); } },
        React.createElement(LabelWrapper, { style: labelStyle },
            label,
            info && (React.createElement(TooltipWrapper, null,
                React.createElement(Tooltip, { text: info })))),
        icon ? (React.createElement(IconWrapper, { onClick: function () { return onIconClick && onIconClick(); } }, icon)) : (React.createElement(ValueWrapper, { style: valueStyle }, value))));
};
export default ListRow;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
