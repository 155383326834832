var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import MainDriver from './MainDriver';
import OtherDrivers from './OtherDrivers';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var DriversSection = function (_a) {
    var data = _a.data, content = _a.content, setDriverIndex = _a.setDriverIndex, _b = _a.policyIsLapsed, policyIsLapsed = _b === void 0 ? false : _b, onAddDriver = _a.onAddDriver, onRemoveDriver = _a.onRemoveDriver;
    var history = useHistory();
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var sectionTitle = content.sectionTitle, mainDriverTitle = content.mainDriverTitle, mainDriverDetailsTitle = content.mainDriverDetailsTitle, driverProfileContent = content.driverProfileContent, setMainDriverButtonText = content.setMainDriverButtonText, addDriverDetailButtonText = content.addDriverDetailButtonText, removeDriverButtonText = content.removeDriverButtonText, otherDriversTitle = content.otherDriversTitle, addDriverButtonText = content.addDriverButtonText;
    var mainDriverData = data.mainDriverData, otherDriversData = data.otherDriversData;
    var setMainDriver = function () { return console.log('set main driver'); };
    var addDriverDetail = function (index) {
        setDriverIndex(index);
        history.push('/amend-driver');
    };
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(Root, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(SectionHeader, { expandable: true, title: sectionTitle, open: open, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(PaddingVertical, null,
                    React.createElement(MainDriver, { title: mainDriverTitle, detailsTitle: mainDriverDetailsTitle, driverProfileContent: driverProfileContent, driverProfileData: mainDriverData, setMainDriver: setMainDriver, setMainDriverButtonText: setMainDriverButtonText, addDriverDetail: addDriverDetail, addDriverDetailButtonText: addDriverDetailButtonText, policyIsLapsed: policyIsLapsed }),
                    React.createElement(OtherDrivers, { title: otherDriversTitle, driverProfileContent: driverProfileContent, driverProfileDatas: otherDriversData, addDriverButtonText: addDriverButtonText, setMainDriver: setMainDriver, setMainDriverButtonText: setMainDriverButtonText, addDriverDetail: addDriverDetail, addDriverDetailButtonText: addDriverDetailButtonText, removeDriver: onRemoveDriver, removeDriverButtonText: removeDriverButtonText, policyIsLapsed: policyIsLapsed, onAddDriver: onAddDriver })))))));
};
export default DriversSection;
var templateObject_1;
