var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import SectionHeader from 'components/molecules/SectionHeader';
import RadioGroup from 'components/molecules/RadioGroup';
var RadioGroupWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: 32px;\n"], ["\n  padding-bottom: 32px;\n"])));
export var MyMapPermissionPref = function (_a) {
    var permission = _a.permission, onSelect = _a.onSelect;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(permission), radioValue = _c[0], setRadioValue = _c[1];
    useEffect(function () {
        setRadioValue(permission);
    }, [permission]);
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(SectionHeader, { title: "My map permission setting", expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
            open && (React.createElement(RadioGroupWrapper, null,
                React.createElement(RadioGroup, { options: ['Allowed', 'Deny'], defaultValue: radioValue, onSelect: function (value) {
                        onSelect(value);
                        setRadioValue(value);
                    } }))))));
};
var templateObject_1;
