var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout, { PaddingHorizontal } from '../Layout';
import { FormInput, FormRadio, Group } from '../Form';
import ReactMarkdown from 'react-markdown';
import find from 'lodash/find';
import { FormControl } from '@material-ui/core';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 1.63;\n  letter-spacing: 0.5px;\n  font-size: 16px;\n\n  p {\n    font-weight: 300;\n    margin-bottom: 10px;\n  }\n\n  h2 {\n    font-size: 25px;\n    font-weight: 500;\n    letter-spacing: 0.25px;\n  }\n\n  strong {\n    font-size: 16px;\n    font-weight: 500;\n  }\n"], ["\n  line-height: 1.63;\n  letter-spacing: 0.5px;\n  font-size: 16px;\n\n  p {\n    font-weight: 300;\n    margin-bottom: 10px;\n  }\n\n  h2 {\n    font-size: 25px;\n    font-weight: 500;\n    letter-spacing: 0.25px;\n  }\n\n  strong {\n    font-size: 16px;\n    font-weight: 500;\n  }\n"])));
var SpanFullGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
var RenewalAnnualPaymentForm = function (_a) {
    var renewalInformation = _a.renewalInformation, annualBankDetails = _a.annualBankDetails, register = _a.register, setRegisteredHasBeenSelected = _a.setRegisteredHasBeenSelected, errors = _a.errors, autoRenew = _a.autoRenew, onUserAutoRenew = _a.onUserAutoRenew;
    var _b = useState(undefined), cardRegistraionToYou = _b[0], setCardRegistraionToYou = _b[1];
    var _c = useState(autoRenew === true ? 'Opt in' : 'Opt out'), radioValue = _c[0], setRadioValue = _c[1];
    var cardRegistration = find(annualBankDetails, function (obj) {
        return obj.formGroupId === 'cardRegistration';
    });
    var cardRegistrationRadio = (cardRegistration === null || cardRegistration === void 0 ? void 0 : cardRegistration.fields) ? cardRegistration.fields[0] : undefined;
    var billingDetails = find(annualBankDetails, function (obj) {
        return obj.formGroupId === 'billingDetails';
    });
    // If card registered to another person these fields are required
    var firstName = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'firstName';
    });
    var lastName = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'lastName';
    });
    var emailAddress = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'emailAddress1';
    });
    var phoneNumber = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'phoneNumber';
    });
    var addressLine1 = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'addressLine1';
    });
    var addressLine2 = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'addressLine2';
    });
    var city = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'city';
    });
    var county = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'county';
    });
    var postalCode = find(billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.fields, function (obj) {
        return obj.fieldId === 'postalCode';
    });
    return (React.createElement(Root, null,
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(ReactMarkdown, { source: renewalInformation.body }),
                React.createElement(Group, { noBorder: true },
                    React.createElement(FormControl, null,
                        React.createElement(FormRadio, { options: ['Opt in', 'Opt out'], value: radioValue, name: "", handleUncontrolled: function (event) {
                                setRadioValue(event);
                                onUserAutoRenew(event === 'Opt in' ? true : false);
                            } }))),
                cardRegistration && cardRegistrationRadio && (React.createElement(React.Fragment, null,
                    React.createElement(Group, { title: cardRegistration.title },
                        React.createElement(FormRadio, { options: cardRegistrationRadio.options, label: cardRegistrationRadio.label, name: cardRegistrationRadio.value, handleUncontrolled: function (value) {
                                setCardRegistraionToYou(value === 'Yes' ? true : false);
                                setRegisteredHasBeenSelected(true);
                            }, error: cardRegistraionToYou == undefined }),
                        React.createElement(SpanFullGroup, null,
                            React.createElement(ReactMarkdown, { source: cardRegistration.text }))))),
                cardRegistraionToYou === false && (React.createElement(Group, { title: !cardRegistraionToYou ? billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.title : '', noBorder: true },
                    firstName && (React.createElement(FormInput, { name: firstName.value, label: firstName.label, control: register, error: errors[firstName.value], isRequired: true })),
                    lastName && (React.createElement(FormInput, { name: lastName.value, label: lastName.label, control: register, error: errors[lastName.value], isRequired: true })),
                    emailAddress && (React.createElement(FormInput, { type: "email", name: emailAddress.value, label: emailAddress.label, control: register, error: errors[emailAddress.value], isRequired: true })),
                    phoneNumber && (React.createElement(FormInput, { name: phoneNumber.value, label: phoneNumber.label, control: register, error: errors[phoneNumber.value], isRequired: true, type: "number" })))),
                React.createElement(Group, { title: cardRegistraionToYou === true || cardRegistraionToYou === undefined ? billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.title : '' },
                    addressLine1 && (React.createElement(FormInput, { name: addressLine1.value, label: addressLine1.label, control: register, error: errors[addressLine1.value], isRequired: true })),
                    addressLine2 && (React.createElement(FormInput, { name: addressLine2.value, label: addressLine2.label, control: register, error: errors[addressLine2.value], isRequired: true })),
                    city && (React.createElement(FormInput, { name: city.value, label: city.label, control: register, error: errors[city.value], isRequired: true })),
                    county && (React.createElement(FormInput, { name: county.value, label: county.label, control: register, error: errors[county.value], isRequired: true })),
                    postalCode && (React.createElement(FormInput, { name: postalCode.value, label: postalCode.label, control: register, error: errors[postalCode.value], isRequired: true })),
                    React.createElement(SpanFullGroup, null,
                        React.createElement(ReactMarkdown, { source: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.text })))))));
};
export default RenewalAnnualPaymentForm;
var templateObject_1, templateObject_2;
