var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tab from 'components/molecules/Tab';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(", ", 1fr);\n  grid-column-gap: 10px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(", ", 1fr);\n  grid-column-gap: 10px;\n"])), function (props) { return props.numberOfTabs; });
var TabBar = function (_a) {
    var defaultTab = _a.defaultTab, onSelect = _a.onSelect, tabBarContent = _a.tabBarContent;
    var _b = useState(defaultTab), selectedTab = _b[0], setSelectedTab = _b[1];
    useEffect(function () {
        setSelectedTab(defaultTab);
    }, [defaultTab]);
    return (React.createElement(Root, { numberOfTabs: tabBarContent.length }, tabBarContent.map(function (tab) { return (React.createElement(Tab, { key: tab.value, tabKey: tab.value, selected: tab.value === selectedTab, text: tab.label, onClick: function (key) {
            setSelectedTab(key);
            onSelect(key);
        } })); })));
};
export default TabBar;
var templateObject_1;
