var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import HideOn from 'components/atoms/HideOn';
import Point from 'components/atoms/Point';
import { fontFamily, fontWeight, media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var MilesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 14px;\n\n  ", "\n"], ["\n  display: flex;\n  margin-bottom: 14px;\n\n  ",
    "\n"])), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-bottom: 16px;\n  "], ["\n    margin-bottom: 16px;\n  "]))));
var MilesWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 30px;\n  font-weight: ", ";\n  margin-left: 10px;\n  margin-right: 4px;\n\n  ", "\n"], ["\n  font-size: 30px;\n  font-weight: ", ";\n  margin-left: 10px;\n  margin-right: 4px;\n\n  ",
    "\n"])), fontWeight.bold, media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 35px;\n    margin-right: 10px;\n  "], ["\n    font-size: 35px;\n    margin-right: 10px;\n  "]))));
var UnitWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-self: flex-end;\n  font-size: 12px;\n  margin-bottom: 6px;\n\n  ", "\n"], ["\n  display: flex;\n  align-self: flex-end;\n  font-size: 12px;\n  margin-bottom: 6px;\n\n  ",
    "\n"])), media.medium(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 18px;\n    margin-bottom: 4px;\n  "], ["\n    font-size: 18px;\n    margin-bottom: 4px;\n  "]))));
var LabelWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 18px;\n  letter-spacing: 0.5px;\n  margin-bottom: 10px;\n\n  ", "\n"], ["\n  font-family: ", ";\n  font-size: 18px;\n  letter-spacing: 0.5px;\n  margin-bottom: 10px;\n\n  ",
    "\n"])), fontFamily.condensed, media.medium(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: 19px;\n  "], ["\n    font-size: 19px;\n  "]))));
var InfoWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 11px;\n  line-height: 15px;\n  font-style: italic;\n  font-weight: ", ";\n\n  ", "\n\n  ", "\n"], ["\n  font-size: 11px;\n  line-height: 15px;\n  font-style: italic;\n  font-weight: ", ";\n\n  ",
    "\n\n  ",
    "\n"])), fontWeight.light, media.medium(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-size: 12px;\n    line-height: 18px;\n  "], ["\n    font-size: 12px;\n    line-height: 18px;\n  "]))), media.large(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    line-height: 20px;\n  "], ["\n    line-height: 20px;\n  "]))));
var MilesText = function (_a) {
    var content = _a.content, miles = _a.miles;
    return (React.createElement(Root, null,
        React.createElement(MilesContainer, null,
            React.createElement(HideOn, { medium: true },
                React.createElement(Point, { color: content.color, innerRadius: 6, outerRadius: 12 })),
            React.createElement(HideOn, { smallOnly: true, large: true },
                React.createElement(Point, { color: content.color, innerRadius: 7, outerRadius: 14 })),
            React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
                React.createElement(Point, { color: content.color, innerRadius: 8, outerRadius: 16 })),
            React.createElement(MilesWrapper, null, new Intl.NumberFormat().format(miles)),
            React.createElement(UnitWrapper, null, content.unit)),
        React.createElement(LabelWrapper, null, content.label),
        React.createElement(InfoWrapper, null, content.info)));
};
export default MilesText;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
