var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import L from 'leaflet';
import { max, min } from 'lodash';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import { media } from 'styles';
import { getDistance } from 'geolib';
import Routing from './RoutingMachine';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .map {\n    height: calc(100vh - 80px);\n    ", "\n    ", "\n  }\n\n  .leaflet-routing-alternatives-container {\n    display: none;\n  }\n"], ["\n  .map {\n    height: calc(100vh - 80px);\n    ",
    "\n    ",
    "\n  }\n\n  .leaflet-routing-alternatives-container {\n    display: none;\n  }\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      height: calc(100vh - 100px);\n    "], ["\n      height: calc(100vh - 100px);\n    "]))), media.large(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      height: calc(100vh - 120px);\n    "], ["\n      height: calc(100vh - 120px);\n    "]))));
var MapRenderer = function (_a) {
    var origin = _a.origin, destination = _a.destination, acceleratingPoints = _a.acceleratingPoints, brakingPoints = _a.brakingPoints, waypoints = _a.waypoints;
    var ref = useRef();
    var _b = useState(false), show = _b[0], setShow = _b[1];
    useEffect(function () {
        setShow(true);
    }, []);
    var getCenter = function () {
        var lats = waypoints.map(function (point) { return point.lat; });
        var lngs = waypoints.map(function (point) { return point.lng; });
        var maxLat = max(lats);
        var minLat = min(lats);
        var maxLng = max(lngs);
        var minLng = min(lngs);
        if (maxLat && minLat && maxLng && minLng) {
            return [parseFloat(((maxLat + minLat) / 2).toFixed(5)), parseFloat(((maxLng + minLng) / 2).toFixed(5))];
        }
        return [origin.lat, origin.lng];
    };
    var getZoom = function () {
        var distance = getDistance({ latitude: origin.lat, longitude: origin.lng }, { latitude: destination.lat, longitude: destination.lng });
        if (distance > 1800) {
            return 13;
        }
        return 15;
    };
    return (React.createElement(Root, null,
        React.createElement(LeafletMap, { ref: ref, className: "map", center: getCenter(), zoom: getZoom(), createLeafletElement: function (props) { return L.Routing.control({}).addTo(props.map.leafletElement); } },
            React.createElement(TileLayer, { attribution: '\u00A9 <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png", crossOrigin: false }),
            show && (React.createElement(Routing, { map: ref.current, origin: origin, destination: destination, acceleratingPoints: acceleratingPoints, brakingPoints: brakingPoints, waypoints: waypoints })))));
};
export default MapRenderer;
var templateObject_1, templateObject_2, templateObject_3;
