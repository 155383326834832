var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getPageContent, mtaTokenRefresh, bindRenewalPayment } from 'services';
import { ContentPageURL, SessionStorageKey } from 'types';
import { useHistory } from 'react-router-dom';
import Button from 'components/atoms/Button';
import Title from 'components/atoms/Title';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import { Dialog } from '@material-ui/core';
import Close from 'assets/close.svg';
import { media } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    display: flex;\n    margin-bottom: 10px;\n    font-weight: 300;\n  }\n\n  strong {\n    font-size: 16px;\n    font-weight: 500;\n    margin-top: 10px;\n  }\n"], ["\n  p {\n    display: flex;\n    margin-bottom: 10px;\n    font-weight: 300;\n  }\n\n  strong {\n    font-size: 16px;\n    font-weight: 500;\n    margin-top: 10px;\n  }\n"])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 30px;\n"], ["\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  margin-bottom: 30px;\n"])));
var ViewMandateBtn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 40px 0;\n"], ["\n  margin: 40px 0;\n"])));
var ButtonsWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 40px 0;\n  display: flex;\n  justify-content: center;\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  margin-top: 40px;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  padding: 40px 0;\n  display: flex;\n  justify-content: center;\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  margin-top: 40px;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var MandateDetails = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 40px 0;\n  padding: 20px;\n  border-radius: 2px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  background-color: rgba(137, 137, 137, 0.06);\n\n  ul {\n    padding-left: 0;\n    list-style: none;\n    box-sizing: border-box;\n    padding: 17px;\n    background: white;\n    width: fit-content;\n    min-width: 106px;\n    height: 58px;\n    border-radius: 2px;\n    border: solid 1px rgba(137, 137, 137, 0.3);\n    font-size: 14px;\n    color: #898989;\n  }\n"], ["\n  margin: 40px 0;\n  padding: 20px;\n  border-radius: 2px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  background-color: rgba(137, 137, 137, 0.06);\n\n  ul {\n    padding-left: 0;\n    list-style: none;\n    box-sizing: border-box;\n    padding: 17px;\n    background: white;\n    width: fit-content;\n    min-width: 106px;\n    height: 58px;\n    border-radius: 2px;\n    border: solid 1px rgba(137, 137, 137, 0.3);\n    font-size: 14px;\n    color: #898989;\n  }\n"])));
var AddressWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  p {\n    margin-bottom: 0;\n  }\n"], ["\n  p {\n    margin-bottom: 0;\n  }\n"])));
var ModalWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 40px;\n"], ["\n  padding: 40px;\n"])));
var ModalDetailsWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(1, 1fr);\n  grid-column-gap: 20px;\n  border: 1px solid black;\n  padding: 10px;\n\n  ", "\n\n  h3 {\n    margin: 0;\n  }\n\n  strong,\n  h3 {\n    font-weight: 400;\n    margin-bottom: 10px;\n  }\n\n  ul {\n    padding-left: 0;\n    list-style: none;\n    box-sizing: border-box;\n    padding: 17px;\n    background: white;\n    width: fit-content;\n    min-width: 106px;\n    height: 58px;\n    border-radius: 2px;\n    border: solid 1px rgba(137, 137, 137, 0.3);\n    font-size: 14px;\n    color: #898989;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(1, 1fr);\n  grid-column-gap: 20px;\n  border: 1px solid black;\n  padding: 10px;\n\n  ",
    "\n\n  h3 {\n    margin: 0;\n  }\n\n  strong,\n  h3 {\n    font-weight: 400;\n    margin-bottom: 10px;\n  }\n\n  ul {\n    padding-left: 0;\n    list-style: none;\n    box-sizing: border-box;\n    padding: 17px;\n    background: white;\n    width: fit-content;\n    min-width: 106px;\n    height: 58px;\n    border-radius: 2px;\n    border: solid 1px rgba(137, 137, 137, 0.3);\n    font-size: 14px;\n    color: #898989;\n  }\n"])), media.medium(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    grid-template-columns: repeat(2, 1fr);\n  "], ["\n    grid-template-columns: repeat(2, 1fr);\n  "]))));
var Column = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject([""], [""])));
var CloseWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n\n  svg {\n    fill: #007f49;\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n\n  svg {\n    fill: #007f49;\n    cursor: pointer;\n  }\n"])));
var ModalGap = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin: 10px 0;\n"], ["\n  margin: 10px 0;\n"])));
var BorderedText = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  border: solid 1px black;\n  width: ", ";\n  display: flex;\n  flex-direction: ", ";\n  padding: ", ";\n  box-sizing: border-box;\n  min-height: 35px;\n"], ["\n  border: solid 1px black;\n  width: ", ";\n  display: flex;\n  flex-direction: ", ";\n  padding: ", ";\n  box-sizing: border-box;\n  min-height: 35px;\n"])), function (props) { return (props.fullWidth ? '100%' : 'fit-content'); }, function (props) { return (props.column ? 'column' : 'row'); }, function (props) { return (props.withPadding ? '5px 10px' : 'none'); });
var LogoWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  font-weight: 500;\n\n  img {\n    width: 150px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  font-weight: 500;\n\n  img {\n    width: 150px;\n  }\n"])));
var RenewalDebitMandate = function (_a) {
    var renewalState = _a.renewalState, renewalPolicy = _a.renewalPolicy, quoteDetails = _a.quoteDetails, autoRenew = _a.autoRenew;
    var history = useHistory();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(undefined), cmsContent = _c[0], setCmsContent = _c[1];
    var _d = useState(undefined), pageTitle = _d[0], setPageTitle = _d[1];
    var _e = useState(undefined), pleaseCheckText = _e[0], setPleaseCheckText = _e[1];
    var _f = useState(undefined), viewMandateText = _f[0], setViewMandateText = _f[1];
    var _g = useState(undefined), pclAddressText = _g[0], setPclAddressText = _g[1];
    var _h = useState(undefined), accountHolderText = _h[0], setAccountHolderText = _h[1];
    var _j = useState(undefined), accountNumberText = _j[0], setAccountNumberText = _j[1];
    var _k = useState(undefined), sortCodeText = _k[0], setSortCodeText = _k[1];
    var _l = useState(undefined), yourBankAddressText = _l[0], setYourBankAddressText = _l[1];
    var _m = useState(undefined), referenceText = _m[0], setReferenceText = _m[1];
    var _o = useState(undefined), guaranteeText = _o[0], setGuaranteeText = _o[1];
    var _p = useState(undefined), detailsText = _p[0], setDetailsText = _p[1];
    var _q = useState(undefined), lastText = _q[0], setLastText = _q[1];
    // Modal
    var _r = useState(undefined), modalPremiumCreditAddress = _r[0], setModalPremiumCreditAddress = _r[1];
    var _s = useState(undefined), modalAccountHolderLabel = _s[0], setModalAccountHolderLabel = _s[1];
    var _t = useState(undefined), modalAccountNumberLabel = _t[0], setModalAccountNumberLabel = _t[1];
    var _u = useState(undefined), modalSortCodeLabel = _u[0], setModalSortCodeLabel = _u[1];
    var _v = useState(undefined), modalBankAdressLabel = _v[0], setModalBankAdressLabel = _v[1];
    var _w = useState(undefined), modalInstructionToBankLabel = _w[0], setModalInstructionToBankLabel = _w[1];
    var _x = useState(undefined), modalServiceUserNumberLabel = _x[0], setModalServiceUserNumberLabel = _x[1];
    var _y = useState(undefined), modalServeiceUserNumberText = _y[0], setModalServeiceUserNumberText = _y[1];
    var _z = useState(undefined), modalReferenceLabel = _z[0], setModalReferenceLabel = _z[1];
    var _0 = useState(undefined), modalInstructionToBankText = _0[0], setModalInstructionToBankText = _0[1];
    var _1 = useState(undefined), modalDateBox = _1[0], setModalDateBox = _1[1];
    var _2 = useState(undefined), modalBottomText = _2[0], setModalBottomText = _2[1];
    var bindPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        selectedQuote: renewalState.paymentPlan.id,
                        selectedPaymentPlan: 'bc:7',
                        autoRenew: autoRenew,
                    };
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, bindRenewalPayment(policyNumber, data, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        history.push('/payment-complete');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        console.log(500);
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        bindPayment();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var replacePlacholderText = function (value) {
        var _a, _b, _c, _d, _e, _f, _g;
        var placeholders = {
            ACCOUNT_HOLDER_NAME: (_a = quoteDetails.bindData.paymentDetails) === null || _a === void 0 ? void 0 : _a.bankAccountData.accountHolderName,
            BANK_ACCOUNT_NUMBER: (_b = quoteDetails.bindData.paymentDetails) === null || _b === void 0 ? void 0 : _b.bankAccountData.bankAccountNumber,
            SORT_CODE: (_c = quoteDetails.bindData.paymentDetails) === null || _c === void 0 ? void 0 : _c.bankAccountData.sortCode,
            BANK_NAME: (_d = quoteDetails.bindData.paymentDetails) === null || _d === void 0 ? void 0 : _d.bankAccountData.validationResponse_itb.bank,
            CONTACT_ADDRESS_LINE_ONE: (_e = quoteDetails.bindData.paymentDetails) === null || _e === void 0 ? void 0 : _e.bankAccountData.validationResponse_itb.contactAddressLine1,
            BRANCH_NAME: (_f = quoteDetails.bindData.paymentDetails) === null || _f === void 0 ? void 0 : _f.bankAccountData.validationResponse_itb.branch,
            CONTACT_POSTCODE: (_g = quoteDetails.bindData.paymentDetails) === null || _g === void 0 ? void 0 : _g.bankAccountData.validationResponse_itb.contactPostcode,
            TODAYS_DATE: moment(new Date()).format('DD / MM / YYYY'),
            DEBIT_AMOUNT: quoteDetails.bindData.selectedPaymentPlan.installment.amount.toFixed(2),
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/ACCOUNT_HOLDER_NAME|BANK_ACCOUNT_NUMBER|SORT_CODE|BANK_NAME|CONTACT_ADDRESS_LINE_ONE|BRANCH_NAME|CONTACT_POSTCODE|TODAYS_DATE|DEBIT_AMOUNT/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    var numberBlocks = function (value) {
        return value.split('').map(function (item, index) { return (React.createElement("div", { style: {
                borderLeft: index !== 0 ? 'solid 1px black' : 'none',
                padding: '5px 10px',
                width: '35px',
                boxSizing: 'border-box',
                textAlign: 'center',
            }, key: item + " " + index + " " + Date.now() }, item)); });
    };
    useEffect(function () {
        console.clear();
        if (cmsContent === undefined) {
            getPageContent(ContentPageURL.RENEWAL_PAYMENT_DIRECT_DEBIT_MANDATE).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        }
        if (cmsContent && pageTitle === undefined) {
            cmsContent.contentSections.map(function (item) {
                if (item.sectionId === 'title')
                    setPageTitle(item.body);
                if (item.sectionId === 'pleaseCheck')
                    setPleaseCheckText(item.body);
                if (item.sectionId === 'mandateLink')
                    setViewMandateText(item.body);
                if (item.sectionId === 'pclAddress')
                    setPclAddressText(item.body);
                if (item.sectionId === 'accountHolder')
                    setAccountHolderText(item.body);
                if (item.sectionId === 'accountNumber')
                    setAccountNumberText(item.body);
                if (item.sectionId === 'sortCode')
                    setSortCodeText(item.body);
                if (item.sectionId === 'yourBankAddress')
                    setYourBankAddressText(item.body);
                if (item.sectionId === 'instructionReferences')
                    setReferenceText(item.body);
                if (item.sectionId === 'guaranteeText')
                    setGuaranteeText(item.body);
                if (item.sectionId === 'guaranteeText')
                    setGuaranteeText(item.body);
                if (item.sectionId === 'details')
                    setDetailsText(item.body);
                if (item.sectionId === 'lastText')
                    setLastText(item.body);
                // Modal
                if (item.sectionId === 'modalPremiumCreditAddress')
                    setModalPremiumCreditAddress(item.body);
                if (item.sectionId === 'modalAccountHolderLabel')
                    setModalAccountHolderLabel(item.body);
                if (item.sectionId === 'modalAccountNumberLabel')
                    setModalAccountNumberLabel(item.body);
                if (item.sectionId === 'modalSortCodeLabel')
                    setModalSortCodeLabel(item.body);
                if (item.sectionId === 'modalBankAdressLabel')
                    setModalBankAdressLabel(item.body);
                if (item.sectionId === 'modalInstructionToBankLabel')
                    setModalInstructionToBankLabel(item.body);
                if (item.sectionId === 'modalServiceUserNumberLabel')
                    setModalServiceUserNumberLabel(item.body);
                if (item.sectionId === 'modalServeiceUserNumberText')
                    setModalServeiceUserNumberText(item.body);
                if (item.sectionId === 'modalReferenceLabel')
                    setModalReferenceLabel(item.body);
                if (item.sectionId === 'modalInstructionToBankText')
                    setModalInstructionToBankText(item.body);
                if (item.sectionId === 'modalDateBox')
                    setModalDateBox(item.body);
                if (item.sectionId === 'modalBottomText')
                    setModalBottomText(item.body);
            });
        }
    }, [cmsContent]);
    return (React.createElement(Root, null,
        React.createElement(Layout, null,
            React.createElement(RenewalJourneyProgress, { step: 4 })),
        cmsContent && (React.createElement(Layout, { shadow: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(TitleWrapper, null,
                        React.createElement(Title, null, pageTitle)),
                    React.createElement(ReactMarkdown, { source: pleaseCheckText }),
                    React.createElement(ViewMandateBtn, null,
                        React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                setOpen(true);
                                TagManagerArgs('Button', "" + window.location.pathname, viewMandateText);
                            } }, viewMandateText)),
                    React.createElement(MandateDetails, null,
                        React.createElement(AddressWrapper, null,
                            React.createElement(ReactMarkdown, { source: pclAddressText })),
                        React.createElement(ReactMarkdown, { source: replacePlacholderText(accountHolderText) }),
                        React.createElement(ReactMarkdown, { source: replacePlacholderText(accountNumberText) }),
                        React.createElement(ReactMarkdown, { source: replacePlacholderText(sortCodeText) }),
                        React.createElement(AddressWrapper, null,
                            React.createElement(ReactMarkdown, { source: replacePlacholderText(yourBankAddressText) })),
                        React.createElement(ReactMarkdown, { source: referenceText }),
                        React.createElement(ReactMarkdown, { source: replacePlacholderText(guaranteeText) })),
                    React.createElement(ReactMarkdown, { source: replacePlacholderText(detailsText) }),
                    React.createElement(ReactMarkdown, { source: lastText }),
                    React.createElement(ButtonsWrapper, null,
                        React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                history.goBack();
                                TagManagerArgs('Button', "" + window.location.pathname, 'Go back');
                            } }, "Go back"),
                        React.createElement(Button, { onClick: function () {
                                bindPayment();
                                TagManagerArgs('Button', "" + window.location.pathname, 'Purchase');
                            } }, "Purchase")))),
            React.createElement(Dialog, { maxWidth: "md", open: open },
                React.createElement(ModalWrapper, null,
                    React.createElement(CloseWrapper, null,
                        React.createElement(Close, { onClick: function () { return setOpen(false); } })),
                    React.createElement(ModalDetailsWrapper, null,
                        React.createElement(Column, null,
                            React.createElement(ReactMarkdown, { source: modalPremiumCreditAddress }),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalAccountHolderLabel }),
                                React.createElement(BorderedText, { withPadding: true }, replacePlacholderText('ACCOUNT_HOLDER_NAME'))),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalAccountNumberLabel }),
                                React.createElement(BorderedText, null, numberBlocks(replacePlacholderText('BANK_ACCOUNT_NUMBER')))),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalSortCodeLabel }),
                                React.createElement(BorderedText, null, numberBlocks(replacePlacholderText('SORT_CODE')))),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalBankAdressLabel }),
                                React.createElement(BorderedText, { withPadding: true, fullWidth: true }, replacePlacholderText('BANK_NAME')),
                                React.createElement(BorderedText, { withPadding: true, fullWidth: true }, replacePlacholderText('CONTACT_ADDRESS_LINE_ONE')),
                                React.createElement(BorderedText, { withPadding: true, fullWidth: true }, replacePlacholderText('BRANCH_NAME')),
                                React.createElement(BorderedText, { withPadding: true, fullWidth: true }, replacePlacholderText('CONTACT_POSTCODE')))),
                        React.createElement(Column, null,
                            React.createElement(LogoWrapper, null,
                                React.createElement(ReactMarkdown, { source: modalInstructionToBankLabel })),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalServiceUserNumberLabel }),
                                modalServeiceUserNumberText && (React.createElement(BorderedText, null, numberBlocks(modalServeiceUserNumberText)))),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalReferenceLabel }),
                                React.createElement(BorderedText, { fullWidth: true, withPadding: true },
                                    React.createElement("div", null, '  '))),
                            React.createElement(ModalGap, null,
                                React.createElement(ReactMarkdown, { source: modalInstructionToBankText })),
                            React.createElement(ModalGap, null,
                                React.createElement(BorderedText, { fullWidth: true, withPadding: true, column: true },
                                    React.createElement(ReactMarkdown, { source: replacePlacholderText(modalDateBox) }))))),
                    React.createElement(ReactMarkdown, { source: modalBottomText })))))));
};
var mapStateToProps = function (state) { return ({
    quoteDetails: state.renewalAmendedQuote,
    renewalState: state.renewalState,
    renewalPolicy: state.renewalPolicy,
    autoRenew: state.autoRenew,
}); };
export default connect(mapStateToProps)(RenewalDebitMandate);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
