var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color, fontSize } from 'styles';
import ArrowUpIcon from 'assets/arrow_up.svg';
export var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 50px;\n  height: 50px;\n  border-radius: 4px;\n  border: solid 1px rgba(137, 137, 137, 0.5);\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  justify-content: center;\n  text-align: center;\n\n  svg {\n    fill: currentColor;\n    margin: 0 auto;\n    transform: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 50px;\n  height: 50px;\n  border-radius: 4px;\n  border: solid 1px rgba(137, 137, 137, 0.5);\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  justify-content: center;\n  text-align: center;\n\n  svg {\n    fill: currentColor;\n    margin: 0 auto;\n    transform: ", ";\n  }\n"])), function (props) { return (props.disabled ? 'rgba(137, 137, 137, 0.7)' : color.black); }, fontSize.fs14, function (props) { return (props.next ? 'rotate(90deg)' : props.previous ? 'rotate(-90deg)' : 'rotate(0deg)'); });
var PagerButton = function (_a) {
    var children = _a.children, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.next, next = _c === void 0 ? false : _c, _d = _a.previous, previous = _d === void 0 ? false : _d, onClick = _a.onClick;
    return (React.createElement(Root, { disabled: disabled, next: next, previous: previous, onClick: function () {
            !disabled && onClick();
        } },
        !children && React.createElement(ArrowUpIcon, null),
        children));
};
export default PagerButton;
var templateObject_1;
