var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import moment from 'moment';
import { secondsToHms, getMonthFromDate, getTokens } from 'utils';
import { createNewQuote, drivingDataClient } from 'services';
import { QuoteActionName } from 'components/organisms/SavedQuoteSection';
export var mapToJourneySummaries = function (milesSumaries) {
    var drivingSummaries = milesSumaries.drivingSummaries, drivingTotals = milesSumaries.drivingTotals;
    var monthlySumaries = drivingSummaries === null || drivingSummaries === void 0 ? void 0 : drivingSummaries.map(function (summary) { return ({
        id: (new Date(summary.startTimeStamp).getTime() / 1000).toString(),
        month: moment(summary.startTimeStamp).format('MMM YY'),
        distance: new Intl.NumberFormat('en-En', { minimumFractionDigits: 1 }).format(summary.totalMiles),
        totalTime: secondsToHms(summary.totalSeconds),
        motorwayMiles: summary.motorwayPercentage + '%',
    }); });
    var totalSumaries = drivingTotals
        ? {
            id: (new Date(drivingTotals.startTimeStamp).getTime() / 1000).toString(),
            month: 'Total',
            distance: new Intl.NumberFormat('en-En', { minimumFractionDigits: 1 }).format(drivingTotals.totalMiles),
            totalTime: secondsToHms(drivingTotals.totalSeconds),
            motorwayMiles: drivingTotals.motorwayPercentage + '%',
        }
        : undefined;
    return totalSumaries ? __spreadArrays(monthlySumaries, [totalSumaries]) : monthlySumaries;
};
export var mapToMonthlyMiles = function (milesSumaries) {
    var _a;
    return (_a = milesSumaries.drivingSummaries) === null || _a === void 0 ? void 0 : _a.map(function (summary) { return ({
        month: moment(summary.startTimeStamp).format('MMM YY'),
        miles: summary.totalMiles,
    }); });
};
export var mapToDailyMiles = function (monthlySummaryPerDay) {
    var _a;
    var cumulativeMiles = 0;
    return (_a = monthlySummaryPerDay.monthlySummaryPerDay) === null || _a === void 0 ? void 0 : _a.map(function (summaryPerDay) {
        cumulativeMiles = cumulativeMiles + summaryPerDay.totalMiles;
        return {
            day: summaryPerDay.day.toString(),
            miles: summaryPerDay.totalMiles,
            cumulative: cumulativeMiles,
        };
    });
};
var mapMilesDataByPolicyYear = function (milesData, periods) {
    return periods.map(function (period) {
        var effectiveYear = new Date(period.effective).getFullYear().toString();
        var allMonthMiles = milesData.map(function (item) { return item.data; }).flat();
        var monthMilesForPeriod = allMonthMiles.filter(function (monthMiles) {
            return moment(monthMiles.from).isBetween(moment(period.effective).subtract(1, 'd'), moment(period.expiration).add(1, 'd'));
        });
        var yearlyMiles = 0;
        monthMilesForPeriod.forEach(function (monthMiles) { return (yearlyMiles = yearlyMiles + monthMiles.miles); });
        return {
            year: effectiveYear,
            data: monthMilesForPeriod,
            yearlyMiles: yearlyMiles,
            effective: period.effective,
            expiration: period.expiration,
        };
    });
};
export var createBonusMilesData = function (bonusMiles, periods) {
    var yearlyAddedMiles = bonusMiles.yearlyAddedMiles;
    var yearlyMilesByCalenderYear = yearlyAddedMiles.map(function (yearlyMiles) { return ({
        year: yearlyMiles.year.toString(),
        data: yearlyMiles.monthlyAddedMiles.map(function (monthlyMiles) { return ({
            bonusMilesId: monthlyMiles.id,
            month: getMonthFromDate(monthlyMiles.from, true),
            miles: monthlyMiles.miles,
            from: monthlyMiles.from,
            to: monthlyMiles.to,
        }); }),
        yearlyMiles: 0,
        effective: '',
        expiration: '',
    }); });
    return mapMilesDataByPolicyYear(yearlyMilesByCalenderYear, periods).reverse();
};
var getMiles = function (meters) {
    var miles = meters * 0.000621371192;
    return Number.parseFloat(miles.toFixed(2));
};
export var mapToJourneys = function (trips) {
    var getAddressString = function (address) {
        return address.streetName + ", " + address.city + ", " + address.postcode + " " + address.country;
    };
    return trips.map(function (trip) {
        var _a, _b, _c, _d, _e, _f, _g;
        var journeyOutline = trip.visualisations.features.find(function (feature) { return feature.id === 'journeyOutline'; });
        var acceleratingPoints = (_c = (_b = (_a = trip.visualisations.features.find(function (feature) { return feature.id === 'accelerating1'; })) === null || _a === void 0 ? void 0 : _a.geometry) === null || _b === void 0 ? void 0 : _b.coordinates) === null || _c === void 0 ? void 0 : _c.flat().map(function (point) { return ({
            lat: point[1],
            lng: point[0],
        }); });
        var brakingPoints = (_f = (_e = (_d = trip.visualisations.features.find(function (feature) { return feature.id === 'braking1'; })) === null || _d === void 0 ? void 0 : _d.geometry) === null || _e === void 0 ? void 0 : _e.coordinates) === null || _f === void 0 ? void 0 : _f.flat().map(function (point) { return ({
            lat: point[1],
            lng: point[0],
        }); });
        var waypoints = ((_g = journeyOutline === null || journeyOutline === void 0 ? void 0 : journeyOutline.geometry) === null || _g === void 0 ? void 0 : _g.coordinates).flat().map(function (coordinate) { return ({
            lat: coordinate[1],
            lng: coordinate[0],
        }); });
        return {
            id: trip.id,
            date: moment(new Date(trip.startTimestamp)).format('DD / MM / yyyy'),
            duration: secondsToHms(trip.duration),
            start: moment(trip.startTimestamp).format('HH:mm'),
            end: moment(trip.endTimestamp).format('HH:mm'),
            miles: new Intl.NumberFormat().format(getMiles(trip.distance)),
            originName: getAddressString(trip.startAddress),
            origin: trip.startLocation,
            destinationName: getAddressString(trip.endAddress),
            destination: trip.endLocation,
            acceleratingPoints: acceleratingPoints,
            brakingPoints: brakingPoints,
            waypoints: waypoints,
        };
    });
};
export var mapPolicyTripsToJourneys = function (data) {
    var trips = data.trips;
    return trips.map(function (trip) { return ({
        id: trip.id.toString(),
        date: moment(new Date(trip.startTimestamp)).format('DD / MM / yyyy'),
        duration: secondsToHms(trip.totalSeconds),
        start: moment(trip.startTimestamp).format('HH:mm'),
        end: moment(trip.endTimestamp).format('HH:mm'),
        miles: new Intl.NumberFormat().format(trip.totalMiles),
    }); });
};
export var fetchDrivingData = function (policyId, accessToken, refreshToken, fetchBonusMilesCallback, fetchMilesSummariesCallback) {
    drivingDataClient.fetchBonusMiles(policyId, accessToken, refreshToken, fetchBonusMilesCallback);
    drivingDataClient.fetchMilesSummaries(policyId, accessToken, refreshToken, fetchMilesSummariesCallback);
};
export var fetchTripsByPage = function (policyId, accessToken, refreshToken, monthString, page, fetchTripsCallback) {
    drivingDataClient.fetchTrips(policyId, page, 6, moment(monthString)
        .startOf('month')
        .format('YYYY-MM-DDThh:mm:ss') + "Z", moment(monthString)
        .endOf('month')
        .format('YYYY-MM-DDThh:mm:ss') + "Z", accessToken, refreshToken, fetchTripsCallback);
};
export var onQuoteAction = function (quotePortalUrl, action, postalCode, dateOfBirth, covered, quoteNumber) {
    var link = document.createElement('a');
    // Resume - amend = false
    // Amend - amend = true
    // Buy now - amend = false
    var amend = action === QuoteActionName.AMEND_DETAILS;
    link.href = quotePortalUrl + "#/ampquoteentry?quoteID=" + quoteNumber + "&postalCode=" + postalCode + "&dateOfBirth=" + dateOfBirth.day + "-" + dateOfBirth.month + "-" + dateOfBirth.year + "&c=" + covered + "&amend=" + amend;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
};
export var createNewQuoteForAccount = function (accountNumber, quotePortalUrl, covered) {
    var tokens = getTokens();
    if (tokens) {
        createNewQuote(accountNumber, tokens.accessToken, tokens.refreshToken, function (quoteForAccount) {
            return onQuoteAction(quotePortalUrl, QuoteActionName.NEW_QUOTE, quoteForAccount.baseData.policyAddress.postalCode, quoteForAccount.accountHolder.dateOfBirth, covered, quoteForAccount.quoteID);
        });
    }
};
