var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { color, fontWeight, media } from 'styles';
import HideOn from 'components/atoms/HideOn';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import SectionHeader from 'components/molecules/SectionHeader';
import Tooltip from 'components/molecules/Tooltip';
import AddonForMyCover from './AddonForMyCover';
import ReactMarkdown from 'react-markdown/with-html';
import PNCDAppendix from '../PNCDAppendix/PNCDAppendix';
import find from 'lodash/find';
import moment from 'moment';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.light);
var IntroWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 20px 0;\n"], ["\n  padding: 20px 0;\n"])));
var Rows = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: 14px;\n  margin-bottom: 20px;\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: 14px;\n  margin-bottom: 20px;\n"])));
var Row = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  min-height: 52px;\n  align-items: center;\n  display: flex;\n  padding: 12px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  justify-content: space-between;\n  box-sizing: border-box;\n\n  :last-child {\n    border-bottom: none;\n  }\n\n  ", "\n"], ["\n  min-height: 52px;\n  align-items: center;\n  display: flex;\n  padding: 12px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  justify-content: space-between;\n  box-sizing: border-box;\n\n  :last-child {\n    border-bottom: none;\n  }\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    justify-content: inherit;\n    padding: 12px 24px;\n  "], ["\n    justify-content: inherit;\n    padding: 12px 24px;\n  "]))));
var LabelWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: ", ";\n\n  ", "\n\n  ", "\n"], ["\n  font-weight: ", ";\n\n  ",
    "\n\n  ",
    "\n"])), fontWeight.regular, media.medium(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    min-width: 280px;\n  "], ["\n    min-width: 280px;\n  "]))), media.large(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    min-width: 300px;\n  "], ["\n    min-width: 300px;\n  "]))));
var LabelContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  div {\n    margin-left: 6px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  div {\n    margin-left: 6px;\n  }\n"])));
var ValueWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  white-space: nowrap;\n"], ["\n  display: flex;\n  white-space: nowrap;\n"])));
var PncdWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 10px;\n  width: 75%;\n  font-style: italic;\n\n  strong {\n    color: ", ";\n    font-weight: 500;\n    cursor: pointer;\n    border-bottom: 1px solid;\n  }\n\n  ", "\n"], ["\n  margin-top: 10px;\n  width: 75%;\n  font-style: italic;\n\n  strong {\n    color: ", ";\n    font-weight: 500;\n    cursor: pointer;\n    border-bottom: 1px solid;\n  }\n\n  ",
    "\n"])), color.green, media.medium(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    margin-top: 0px;\n    margin-left: 12px;\n    white-space: normal;\n    width: 100%;\n  "], ["\n    margin-top: 0px;\n    margin-left: 12px;\n    white-space: normal;\n    width: 100%;\n  "]))));
var MyCoverSection = function (_a) {
    var content = _a.content, policyNumber = _a.policyNumber, coverType = _a.coverType, product = _a.product, ncd = _a.ncd, keyCover = _a.keyCover, otherDrivers = _a.otherDrivers, excess = _a.excess, windscreenReplacement = _a.windscreenReplacement, windscreenRepair = _a.windscreenRepair, selectedAddons = _a.selectedAddons, selectedCoverOptions = _a.selectedCoverOptions, ncdYears = _a.ncdYears, addOnPrices = _a.addOnPrices, roadRescuePrices = _a.roadRescuePrices, periodStartDate = _a.periodStartDate, _b = _a.showBreakdown, showBreakdown = _b === void 0 ? true : _b;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var coverTitle = content.coverTitle, coverIntro = content.coverIntro, coverDetails = content.coverDetails, pncdInfo = content.pncdInfo, addonTitle = content.addonTitle, addons = content.addons, benefits = content.benefits;
    var effectiveDate = moment(periodStartDate);
    var defactoDate = moment('20230601', 'YYYYMMDD');
    var showAddonsSection = (effectiveDate.isSameOrAfter(defactoDate));
    var getCoverDetailValue = function (detail) {
        switch (detail) {
            case 'policyNumber':
                return policyNumber;
            case 'coverType':
                return coverType;
            case 'product':
                return product;
            case 'ncd':
                return ncd;
            case 'keyCover':
                return keyCover;
            case 'policyOtherDriversNumberDisplay':
                return otherDrivers;
            case 'policyTotalExcessDisplay':
                return excess;
            case 'policyWindscreenReplacementDisplay':
                return windscreenReplacement;
            case 'policyWindscreenRepairDisplay':
                return windscreenRepair;
            default:
                return '';
        }
    };
    var getIncludesForCover = function (benefitValues) {
        var includesTexts = benefitValues.map(function (value) { var _a; return ((_a = benefits.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.text) || ''; });
        return includesTexts;
    };
    var getPNCDAppendix = function (value) {
        return React.createElement("div", { style: { fontWeight: fontWeight.light }, dangerouslySetInnerHTML: { __html: value } });
    };
    useEffect(function () {
        var _a;
        if (content)
            (_a = document.getElementById('openPNCD')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
                setOpen(true);
            });
    }, []);
    var addOnsWithPrice = function () {
        return addons.map(function (item) {
            var cost = find(addOnPrices, function (obj) {
                return obj.description === item.title;
            });
            return __assign(__assign({}, item), { monthlyBreakdown: cost
                    ? cost.monthlyBreakdown
                    : '(equivilant to £2.00 per month excluding the transaction fee)', price: cost ? cost.price : '£24.00' });
        });
    };
    var policyAddOns = addOnsWithPrice();
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(Root, null,
                    React.createElement(SectionHeader, { title: coverTitle, expandable: false }),
                    React.createElement(IntroWrapper, null, coverIntro),
                    React.createElement(Rows, null, coverDetails.map(function (detail) { return (React.createElement(Row, { key: detail.value },
                        React.createElement(LabelWrapper, null,
                            React.createElement(LabelContainer, null,
                                detail.label,
                                detail.text && React.createElement(Tooltip, { text: detail.text })),
                            detail.value === 'ncd' && (React.createElement(HideOn, { medium: true },
                                React.createElement(PncdWrapper, null,
                                    React.createElement(ReactMarkdown, { source: pncdInfo }))))),
                        React.createElement(ValueWrapper, null,
                            getCoverDetailValue(detail.value),
                            detail.value === 'ncd' && (React.createElement(HideOn, { smallOnly: true },
                                React.createElement(PncdWrapper, null, getPNCDAppendix(pncdInfo))))))); })),
                    React.createElement(SectionHeader, { title: addonTitle, expandable: false }),
                    policyAddOns.map(function (addon) { return (React.createElement(AddonForMyCover, { key: addon.title, selected: selectedAddons.includes(addon.title), title: addon.title, addonsList: addon.includesText, price: "__" + addon.price + "__ ", monthlyBreakdown: addon.monthlyBreakdown, showAddonsSection: showAddonsSection })); }),
                    showBreakdown &&
                        roadRescuePrices &&
                        roadRescuePrices.map(function (option) { return (React.createElement(AddonForMyCover, { key: option.coverTitle, selected: selectedCoverOptions.includes(option.coverTitle), title: option.coverTitle, includes: getIncludesForCover(option.coveredBenefits), price: option.priceText, showAddonsSection: showAddonsSection })); })),
                React.createElement(PNCDAppendix, { ncdYears: ncdYears, open: open, setOpen: setOpen, startDate: periodStartDate })))));
};
export default MyCoverSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
