var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Space from 'components/atoms/Space';
import Title from 'components/atoms/Title';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import HelpItem from './HelpItem';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var LayoutBackground = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: rgba(103, 213, 151, 0.05);\n"], ["\n  background-color: rgba(103, 213, 151, 0.05);\n"])));
var HelpfulInformation = function (_a) {
    var content = _a.content;
    var categories = content.informationItem[0].formGroups;
    return (React.createElement(Root, null,
        React.createElement(Layout, { shadow: true },
            React.createElement(LayoutBackground, null,
                React.createElement(PaddingHorizontal, null,
                    React.createElement(PaddingVertical, null,
                        React.createElement(Title, null, content.title),
                        React.createElement(Space, null),
                        categories.map(function (item) { return (React.createElement(HelpItem, { key: item.title, title: item.title, text: item.text })); })))))));
};
export default HelpfulInformation;
var templateObject_1, templateObject_2;
