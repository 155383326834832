var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color } from 'styles';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import InfoIcon from 'assets/info.svg';
import BootstrapTooltip from './BootstrapTooltip';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  height: 26px;\n  width: 80px;\n  align-items: center;\n  justify-content: right;\n"], ["\n  display: flex;\n  position: relative;\n  height: 26px;\n  width: 80px;\n  align-items: center;\n  justify-content: right;\n"])));
var LineIndicator = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 12px;\n  z-index: 100;\n  width: 1px;\n  height: 115px;\n  background-color: rgba(137, 137, 137, 0.5);\n"], ["\n  position: absolute;\n  top: 0;\n  left: 12px;\n  z-index: 100;\n  width: 1px;\n  height: 115px;\n  background-color: rgba(137, 137, 137, 0.5);\n"])));
var ArrowWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 14px 0 16px;\n\n  svg {\n    fill: ", ";\n  }\n"], ["\n  margin: 0 14px 0 16px;\n\n  svg {\n    fill: ", ";\n  }\n"])), color.green);
var InfoIconWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: auto;\n"], ["\n  margin-left: auto;\n"])));
var InfoWidget = function (_a) {
    var _b = _a.expandable, expandable = _b === void 0 ? false : _b, _c = _a.expanded, expanded = _c === void 0 ? false : _c, info = _a.info, onArrowClick = _a.onArrowClick;
    return (React.createElement(Root, null,
        expandable && (React.createElement(React.Fragment, null,
            expanded && React.createElement(LineIndicator, null),
            React.createElement(ArrowWrapper, { onClick: function () { return onArrowClick && onArrowClick(); } }, expanded ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null)))),
        React.createElement(BootstrapTooltip, { title: info },
            React.createElement(InfoIconWrapper, null,
                React.createElement(InfoIcon, null)))));
};
export default InfoWidget;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
