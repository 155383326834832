var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { AddDriverClaims, AddDriverConvictions, AddDriverConvictionsAndClaims } from '../MtaAddDriver';
import { DriverDetails } from './DriverDetails';
import { DriverPersonalDetails } from './DriverPersonalDetails';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var EditDriverForm = function (_a) {
    var personalContent = _a.personalContent, personalFixedValues = _a.personalFixedValues, driverContent = _a.driverContent, driverFixedValues = _a.driverFixedValues, control = _a.control, register = _a.register, policy = _a.policy, formGroups = _a.formGroups, watchedFields = _a.watchedFields, onConvictionsChange = _a.onConvictionsChange, onClaimsChange = _a.onClaimsChange, addedConvictions = _a.addedConvictions, addedClaims = _a.addedClaims, hideEmailField = _a.hideEmailField, noPersonalTitle = _a.noPersonalTitle, errors = _a.errors;
    return (React.createElement(Root, null,
        React.createElement(DriverPersonalDetails, { content: personalContent, control: control, register: register, fixedValues: personalFixedValues, hideEmailField: hideEmailField, noPersonalTitle: noPersonalTitle, errors: errors }),
        React.createElement(DriverDetails, { content: driverContent, control: control, fixedValues: driverFixedValues, errors: errors }),
        React.createElement(AddDriverConvictionsAndClaims, { formGroups: formGroups, control: control, errors: errors }),
        policy && watchedFields.hasMotorConvictions === 'Yes' && (React.createElement(AddDriverConvictions, { onChange: function (data) { return onConvictionsChange(data); }, formGroups: formGroups, currentPolicy: policy, amendDetails: addedConvictions })),
        watchedFields.hasMotorClaims === 'Yes' && (React.createElement(AddDriverClaims, { onChange: function (data) { return onClaimsChange(data); }, formGroups: formGroups, amendDetails: addedClaims }))));
};
export default EditDriverForm;
var templateObject_1;
