var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ListRow from 'components/molecules/ListRow';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.1);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.1);\n  margin-bottom: 30px;\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.1);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.1);\n  margin-bottom: 30px;\n"])));
var createRowStyle = function (backgrounded) { return ({
    backgroundColor: backgrounded ? 'rgba(137, 137, 137, 0.1)' : 'transparent',
    borderBottom: backgrounded ? 'none' : '1px solid rgba(137, 137, 137, 0.1)',
    margin: backgrounded ? '0' : '0 15px',
    padding: backgrounded ? '15px' : '15px 0',
}); };
var ExcessTableMobile = function (_a) {
    var headers = _a.headers, excesses = _a.excesses;
    return (React.createElement(Root, null, excesses.map(function (excess) {
        return headers.map(function (header) {
            var value = excess[header.value];
            var type = typeof value;
            return (React.createElement(ListRow, { key: excess.driver + header.value, info: header.info, style: createRowStyle(header.value === 'totalExcess'), label: header.label, value: type === 'number' ? "\u00A3" + value : value, valueStyle: { fontWeight: header.value === 'totalExcess' ? fontWeight.regular : fontWeight.light } }));
        });
    })));
};
export default ExcessTableMobile;
var templateObject_1;
