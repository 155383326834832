var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable react/no-children-prop */
import React from 'react';
import styled from 'styled-components';
import find from 'lodash/find';
import Layout, { PaddingHorizontal, PaddingVertical } from '../Layout';
import ReactMarkdown from 'react-markdown';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.5;\n    letter-spacing: 0.5px;\n    padding-bottom: 10px;\n  }\n"], ["\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.5;\n    letter-spacing: 0.5px;\n    padding-bottom: 10px;\n  }\n"])));
var IntroText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  h2 {\n    margin-bottom: 40px;\n    margin-top: 0;\n    font-family: Ubuntu;\n    font-size: 30px;\n    font-weight: 500;\n  }\n"], ["\n  h2 {\n    margin-bottom: 40px;\n    margin-top: 0;\n    font-family: Ubuntu;\n    font-size: 30px;\n    font-weight: 500;\n  }\n"])));
var DocumentLinks = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ul {\n    li {\n      position: relative;\n      list-style: none;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 15px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  a {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 2.5;\n    letter-spacing: 0.5px;\n    color: #007f49;\n  }\n"], ["\n  ul {\n    li {\n      position: relative;\n      list-style: none;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -40px;\n        top: 15px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n\n  a {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 2.5;\n    letter-spacing: 0.5px;\n    color: #007f49;\n  }\n"])));
var DocumentPreferences = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  a {\n    color: #007f49;\n  }\n\n  h2 {\n    margin-bottom: 20px;\n    margin-top: 50px;\n    font-size: 25px;\n    font-weight: 500;\n  }\n"], ["\n  a {\n    color: #007f49;\n  }\n\n  h2 {\n    margin-bottom: 20px;\n    margin-top: 50px;\n    font-size: 25px;\n    font-weight: 500;\n  }\n"])));
var RenewalPolicyDocuments = function (_a) {
    var content = _a.content, _b = _a.standardDocs, standardDocs = _b === void 0 ? '' : _b, _c = _a.roadRescueDoc, roadRescueDoc = _c === void 0 ? '' : _c, _d = _a.keyProtectDoc, keyProtectDoc = _d === void 0 ? '' : _d;
    var policyDocumentsText = find(content, function (obj) {
        return obj.sectionId === 'policyDocumentsText';
    });
    var documentsPreference = find(content, function (obj) {
        return obj.sectionId === 'documentsPreference';
    });
    // get the link for whichever environment being used (local, dev, qa)
    var myAccountLink = "[My account](" + window.location.origin + "/my-account)";
    var replacePlacholderText = function (value) {
        var placeholders = {
            MY_ACCOUNT: myAccountLink,
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/MY_ACCOUNT/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    return (React.createElement(Root, null,
        React.createElement(Layout, { shadow: true, withSeperator: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(IntroText, null,
                        React.createElement(ReactMarkdown, { source: policyDocumentsText === null || policyDocumentsText === void 0 ? void 0 : policyDocumentsText.body })),
                    React.createElement(DocumentLinks, null,
                        React.createElement(ReactMarkdown, { linkTarget: "_blank", source: "" + standardDocs + roadRescueDoc + keyProtectDoc })),
                    React.createElement(DocumentPreferences, null,
                        React.createElement(ReactMarkdown, { source: documentsPreference === null || documentsPreference === void 0 ? void 0 : documentsPreference.body })))))));
};
export default RenewalPolicyDocuments;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
