var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { color, media } from 'styles';
export var PAGE_STATUS;
(function (PAGE_STATUS) {
    PAGE_STATUS["LOGIN"] = "login";
    PAGE_STATUS["CHECK_EMAIL"] = "check_email";
    PAGE_STATUS["RESET_PASSWORD"] = "reset_password";
    PAGE_STATUS["CONFIRM_PASSWORD"] = "confirm_password";
    PAGE_STATUS["PASSWORD_CHANGED"] = "password_changed";
})(PAGE_STATUS || (PAGE_STATUS = {}));
export var LoginPageLayout = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column-reverse;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column-reverse;\n\n  ",
    "\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: 100vh;\n    flex-direction: row;\n    > * {\n      width: 50%;\n    }\n  "], ["\n    min-height: 100vh;\n    flex-direction: row;\n    > * {\n      width: 50%;\n    }\n  "]))));
export var Root = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: white;\n  padding: 20px;\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  background-color: white;\n  padding: 20px;\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n"])), media.smallOnly(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: 600px;\n    box-shadow: 0 4px 10px 4px rgba(32, 32, 32, 0.2);\n  "], ["\n    height: 600px;\n    box-shadow: 0 4px 10px 4px rgba(32, 32, 32, 0.2);\n  "]))), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background-color: ", ";\n    padding: 0 40px;\n  "], ["\n    background-color: ", ";\n    padding: 0 40px;\n  "])), color.green), media.large(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 0 75px;\n  "], ["\n    padding: 0 75px;\n  "]))));
export var Logo = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  img {\n    width: 150px;\n    margin: auto;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  img {\n    width: 150px;\n    margin: auto;\n  }\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-bottom: 20px;\n  "], ["\n    margin-bottom: 20px;\n  "]))), media.large(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-bottom: 50px;\n  "], ["\n    margin-bottom: 50px;\n  "]))));
export var ContentContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: white;\n\n  ", "\n\n  ", "\n"], ["\n  background-color: white;\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin: 0;\n    position: absolute;\n    top: 45%;\n    left: calc(50% + 35px);\n    right: 35px;\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n    border-radius: 8px;\n    box-shadow: 0 4px 10px 4px rgba(32, 32, 32, 0.2);\n    padding: 50px;\n  "], ["\n    margin: 0;\n    position: absolute;\n    top: 45%;\n    left: calc(50% + 35px);\n    right: 35px;\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n    border-radius: 8px;\n    box-shadow: 0 4px 10px 4px rgba(32, 32, 32, 0.2);\n    padding: 50px;\n  "]))), media.large(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    margin: 0;\n    position: absolute;\n    top: 50%;\n    left: calc(50% + 40px);\n    right: 40px;\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n    padding: 90px;\n    max-width: 500px;\n    margin-right: auto;\n  "], ["\n    margin: 0;\n    position: absolute;\n    top: 50%;\n    left: calc(50% + 40px);\n    right: 40px;\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n    padding: 90px;\n    max-width: 500px;\n    margin-right: auto;\n  "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
