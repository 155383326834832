import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import PopupGuide from 'components/organisms/PopupGuide';
import { getSectionContent } from 'components/organisms/Form/utils';
var DashboardPopUpGuide = function (_a) {
    var popupGuideVisible = _a.popupGuideVisible, setPopupGuideVisible = _a.setPopupGuideVisible, popupGuideCMS = _a.popupGuideCMS, name = _a.name;
    var questionText = getSectionContent('popupQuestion', popupGuideCMS.contentSections).body;
    var linksGroup = popupGuideCMS.contentSections.filter(function (item) { return item.formGroups; });
    var navigationTexts = linksGroup[0].formGroups[0].list[0].listItems;
    var morningGreetingText = '';
    var afternoonGreetingText = '';
    var eveningGreetingText = '';
    var nightGreetingText = '';
    popupGuideCMS.contentSections.map(function (item) {
        if (item.sectionId === 'morningGreetingText')
            morningGreetingText = item.body;
        if (item.sectionId === 'afternoonGreetingText')
            afternoonGreetingText = item.body;
        if (item.sectionId === 'eveningGreetingText')
            eveningGreetingText = item.body;
        if (item.sectionId === 'nightGreetingText')
            nightGreetingText = item.body;
    });
    var content = {
        morningGreetingText: morningGreetingText,
        afternoonGreetingText: afternoonGreetingText,
        eveningGreetingText: eveningGreetingText,
        nightGreetingText: nightGreetingText,
        questionText: questionText,
        navigationTexts: navigationTexts,
    };
    var history = useHistory();
    return (React.createElement(Dialog, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }, open: popupGuideVisible, onClose: function () { return setPopupGuideVisible(false); }, maxWidth: "xl" },
        React.createElement(PopupGuide, { content: content, name: name, onClose: function () {
                setPopupGuideVisible(false);
                sessionStorage.setItem('showPopupGuide', 'false');
            }, navigateTo: function (url) {
                sessionStorage.setItem('showPopupGuide', 'false');
                setPopupGuideVisible(false);
                history.push(url);
            } })));
};
export default DashboardPopUpGuide;
