var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { fontWeight } from 'styles';
import Addon from './Addon';
import BreakdownCoverSection from './BreakdownCoverSection';
import moment from 'moment';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  line-height: 1.63;\n"], ["\n  font-weight: ", ";\n  line-height: 1.63;\n"])), fontWeight.light);
var IntroWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.light);
var Addons = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  padding: 30px 0;\n\n  > * {\n    margin-top: 15px;\n  }\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  padding: 30px 0;\n\n  > * {\n    margin-top: 15px;\n  }\n"])));
var AddonsSection = function (_a) {
    var addonsContent = _a.addonsContent, breakdownContent = _a.breakdownContent, breakdownCosts = _a.breakdownCosts, addonSelectOrRemove = _a.addonSelectOrRemove, onBreakDownSelection = _a.onBreakDownSelection, roadRescueSelected = _a.roadRescueSelected, initialRoadRescueIndex = _a.initialRoadRescueIndex, removeRoadRescue = _a.removeRoadRescue, _b = _a.showBreakDownAddon, showBreakDownAddon = _b === void 0 ? true : _b, startDate = _a.startDate;
    var title = addonsContent.title, intro = addonsContent.intro, addCoverText = addonsContent.addCoverText, removeCoverText = addonsContent.removeCoverText, chargeTitle = addonsContent.chargeTitle, chargeText = addonsContent.chargeText, addons = addonsContent.addons;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var renewalStartDate = moment(startDate);
    //show new content after 19.12.22 (months start with 0)
    var showNewDate = moment({ day: 20, month: 11, year: 2022 });
    var showNew = renewalStartDate.isSameOrAfter(showNewDate);
    //TODO: this should be added to CMS
    var newKeyProtect = "With Key Protect you can make an unlimited number of claims in a policy year for any insured keys that are lost, stolen or accidentally damaged, up to the cover limit. You can not claim within the first 14 days of your policy.\n\nRead the [Insurance Product Information Document](https://assets.ctfassets.net/fxn1re9he7cd/6MgfREqkfkgPtHTvYWJ5T7/70d289786c7445810ab2be473ee9d73e/ITB_Keys_IPID_v4.0.pdf) to understand the main benefits and restrictions.\n\nIf you have any other key protection there may be an element of overlap with the policy. Key Protect is distributed by Supercover Insurance, a trading name of Insurance Factory Limited, and underwritten by West Bay Insurance PLC. Please review your Privat Car Insurance Policy document for full terms and conditions.";
    var getDescription = function (title, description) {
        switch (title) {
            case 'Key Protect':
                return showNew ? newKeyProtect : description;
            default:
                return description;
        }
    };
    var defactoDate = moment('20230601');
    if (renewalStartDate.isSameOrAfter(defactoDate)) {
        var itemToRemoveIndex = addons.findIndex(function (addon) {
            return addon.title === 'Key Protect';
        });
        // proceed to remove an item only if it exists.
        if (itemToRemoveIndex !== -1) {
            addons.splice(itemToRemoveIndex, 1);
        }
    }
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(SectionHeader, { title: title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(PaddingVertical, null,
                    React.createElement(IntroWrapper, null, intro),
                    React.createElement(Addons, null, addons.map(function (addon) { return (React.createElement(Addon, { key: addon.title, title: addon.title, charge: addon.amount, chargeTitle: chargeTitle, chargeText: chargeText, addCoverText: addCoverText, removeCoverText: removeCoverText, includesText: addon.includesText, description: getDescription(addon.title, addon.description), onSelect: function () { return addonSelectOrRemove(addon.title, true); }, onRemove: function () { return addonSelectOrRemove(addon.title, false); }, initialSelection: addon.selected })); })),
                    showBreakDownAddon && (React.createElement(BreakdownCoverSection, { onBreakDownSelection: onBreakDownSelection, content: breakdownContent, breakdownCosts: breakdownCosts, roadRescueSelected: roadRescueSelected, initialRoadRescueIndex: initialRoadRescueIndex, removeRoadRescue: removeRoadRescue }))))))));
};
export default AddonsSection;
var templateObject_1, templateObject_2, templateObject_3;
