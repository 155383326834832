var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import find from 'lodash/find';
import { FormInput, FormRadio, Group } from '../Form';
import ErrorMessage from 'components/atoms/ErrorMessage';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var SpanFullGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
var DirectDebitInstructions = function (_a) {
    var title = _a.title, fields = _a.fields, control = _a.control, noAdditionalSigniture = _a.noAdditionalSigniture, showWarning = _a.showWarning, errors = _a.errors;
    var accountHolderName = find(fields, function (obj) {
        return obj.fieldId === 'accountHolderName';
    });
    var bankAccountNumber = find(fields, function (obj) {
        return obj.fieldId === 'bankAccountNumber';
    });
    var sortCode = find(fields, function (obj) {
        return obj.fieldId === 'sortCode';
    });
    var accountInYourName = find(fields, function (obj) {
        return obj.fieldId === 'isAuthorisedPayment';
    });
    return (React.createElement(Root, null,
        React.createElement("strong", null, title),
        React.createElement(Group, null,
            accountHolderName && (React.createElement(FormInput, { name: accountHolderName.value, label: accountHolderName.label, control: control, isRequired: accountHolderName.required, error: errors[accountHolderName.value] })),
            bankAccountNumber && (React.createElement(FormInput, { name: bankAccountNumber.value, label: bankAccountNumber.label, control: control, isRequired: bankAccountNumber.required, error: errors[bankAccountNumber.value], type: "number" })),
            sortCode && (React.createElement(FormInput, { name: sortCode.value, label: sortCode.label, control: control, isRequired: sortCode.required, error: errors[sortCode.value] })),
            React.createElement(SpanFullGroup, null,
                accountInYourName && (React.createElement(FormRadio, { label: accountInYourName === null || accountInYourName === void 0 ? void 0 : accountInYourName.label, name: accountInYourName === null || accountInYourName === void 0 ? void 0 : accountInYourName.value, options: accountInYourName === null || accountInYourName === void 0 ? void 0 : accountInYourName.options, handleUncontrolled: noAdditionalSigniture, isRequired: accountInYourName.required, error: errors[accountInYourName.value] })),
                !showWarning && showWarning !== undefined && accountInYourName && (React.createElement(ErrorMessage, { errorMessage: accountInYourName.importantInformation }))))));
};
export default DirectDebitInstructions;
var templateObject_1, templateObject_2;
