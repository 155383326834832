import find from 'lodash/find';
import React from 'react';
import FormAutocomplete from './FormAutocomplete';
import FormDatePicker from './FormDatePicker';
import FormInput from './FormInput';
import FormRadio from './FormRadio';
import FormSelect from './FormSelect';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
// If the form fields can be rendered using map, without further dev input
export var getFieldType = function (field, register, control, error) {
    if (error === void 0) { error = false; }
    if (field.fieldType === 'input') {
        return (React.createElement(FormInput, { key: field.label, type: "text", label: field.label, name: field.value, toolTip: field.toolTip, control: register, placeholder: field.placeholder, additionalToTooltip: field.additionalToTooltip, isRequired: field.required, error: error }));
    }
    if (field.fieldType === 'input-number') {
        return (React.createElement(FormInput, { key: field.label, type: "number", label: field.label, name: field.value, toolTip: field.toolTip, control: register, placeholder: field.placeholder, additionalToTooltip: field.additionalToTooltip, isRequired: field.required, error: error }));
    }
    if (field.fieldType === 'dropdown') {
        var options = getGwDropdownValues(field.gwTypelist);
        return (React.createElement(FormSelect, { key: field.label, label: field.label, options: options, toolTip: field.toolTip, placeholder: field.placeholder, name: field.value, control: control, spanTwoCols: field.spanTwoColumns, additionalToTooltip: field.additionalToTooltip, isRequired: field.required, error: error }));
    }
    if (field.fieldType === 'datepicker') {
        return (React.createElement(FormDatePicker, { key: field.label, label: field.label, toolTip: field.toolTip, name: field.value, control: control, additionalToTooltip: field.additionalToTooltip, isRequired: field.required, error: error }));
    }
    if (field.fieldType === 'radio')
        return (React.createElement(FormRadio, { key: field.label, label: field.label, toolTip: field.toolTip, options: field.options, name: field.value, control: control, additionalToTooltip: field.additionalToTooltip, isRequired: field.required, error: error }));
    if (field.fieldType === 'autosearch') {
        var options = getGwDropdownValues(field.gwTypelist);
        return (React.createElement(FormAutocomplete, { key: field.label, label: field.label, control: control, name: field.value, options: options, error: error }));
    }
    return React.createElement(React.Fragment, null);
};
// Return a single form group content
export var getSingleFormGroupContent = function (id, formGroups) {
    var content = find(formGroups, function (obj) {
        return obj.formGroupId === id || obj.headerId === id;
    });
    return content;
};
// Return a single field content
export var getSingleFieldContent = function (id, fields) {
    var content = find(fields, function (obj) {
        return obj.fieldId === id;
    });
    return content;
};
// Return a single button
// Return a single form group content
export var getSingleButtonContent = function (id, buttons) {
    var content = find(buttons, function (obj) {
        return obj.buttonId === id;
    });
    return content;
};
export var getSectionContent = function (id, contentSections) {
    var content = find(contentSections, function (obj) {
        return obj.sectionId === id;
    });
    return content;
};
export var getModalContent = function (id, modalContent) {
    var content = find(modalContent, function (obj) {
        return obj.modalId === id;
    });
    return content;
};
