var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ArrowDownIcon from 'assets/arrow_down.svg';
import Icon from 'components/atoms/Icon';
import Title from 'components/atoms/Title';
import { media } from 'styles';
export var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: ", ";\n  justify-content: space-between;\n  padding: 15px;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: ", ";\n  justify-content: space-between;\n  padding: 15px;\n\n  ",
    "\n"])), function (props) { return (props.expandable ? 'pointer' : 'inherit'); }, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 20px 0;\n  "], ["\n    padding: 20px 0;\n  "]))));
var AnimateIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  transition: 0.3s;\n  transform: ", ";\n"], ["\n  color: ", ";\n  transition: 0.3s;\n  transform: ", ";\n"])), function (props) { return (props.spinArrow ? '#30A369' : '#898989'); }, function (props) { return (props.spinArrow ? 'rotate(180deg)' : 'rotate(0deg)'); });
var SectionHeader = function (_a) {
    var title = _a.title, _b = _a.expandable, expandable = _b === void 0 ? false : _b, _c = _a.open, open = _c === void 0 ? false : _c, onClick = _a.onClick;
    return (React.createElement(Root, { expandable: expandable, onClick: function () { return expandable && onClick && onClick(); } },
        React.createElement(Title, null, title),
        expandable && (React.createElement(AnimateIcon, { spinArrow: open },
            React.createElement(Icon, { localIcon: ArrowDownIcon })))));
};
export default SectionHeader;
var templateObject_1, templateObject_2, templateObject_3;
