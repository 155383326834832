import find from 'lodash/find';
import React from 'react';
import HeaderSection from 'components/organisms/HeaderSection';
export var QuoteHeader = function (_a) {
    var quote = _a.quote, cmsContent = _a.cmsContent, _b = _a.blue, blue = _b === void 0 ? false : _b;
    var header = undefined;
    // Green header at top of page
    if (!blue) {
        if (quote.changeInCost.amount > 0 && quote.paymentPlanId === 'bc:5') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'gh-ai';
            });
        }
        if (quote.changeInCost.amount < 0 && quote.paymentPlanId === 'bc:5') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'gh-ar';
            });
        }
        if (quote.changeInCost.amount === 0 && quote.paymentPlanId === 'bc:5') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'gh-anc';
            });
        }
        if (quote.changeInCost.amount > 0 && quote.paymentPlanId === 'bc:6') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'gh-mi';
            });
        }
        if (quote.changeInCost.amount < 0 && quote.paymentPlanId === 'bc:6') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'gh-mr';
            });
        }
        if (quote.changeInCost.amount === 0 && quote.paymentPlanId === 'bc:6') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'gh-mnc';
            });
        }
    }
    // Blue "Header" in the middle of quote page
    // No blue header is used for Anuual payments (bc:5) where money is to be paid
    if (blue) {
        if (quote.changeInCost.amount < 0 && quote.paymentPlanId === 'bc:5') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'bh-ar';
            });
        }
        if (quote.changeInCost.amount === 0 && quote.paymentPlanId === 'bc:5') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'bh-anc';
            });
        }
        if (quote.changeInCost.amount > 0 && quote.paymentPlanId === 'bc:6') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'bh-mi';
            });
        }
        if (quote.changeInCost.amount < 0 && quote.paymentPlanId === 'bc:6') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'bh-mr';
            });
        }
        if (quote.changeInCost.amount === 0 && quote.paymentPlanId === 'bc:6') {
            header = find(cmsContent, function (obj) {
                return obj.headerId === 'bh-mnc';
            });
        }
    }
    // Replaces placeholder text from the cms.
    // Works for strings with multiple and mixed placeholders
    var replacePlacholderText = function (value) {
        var placeholders = {
            QUOTE_TOTAL_COST: Math.abs(quote.totalCost.amount).toFixed(2),
            TRANSACTION_FEE: Math.abs(quote.itbFees.amount).toFixed(2),
            PCL_FEE: Math.abs(quote.pclFee).toFixed(1),
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/QUOTE_TOTAL_COST|TRANSACTION_FEE|PCL_FEE/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    if (header !== undefined)
        return (React.createElement(HeaderSection, { title: replacePlacholderText(header.title), body: replacePlacholderText(header.body), blueSection: blue }));
    else
        return React.createElement(React.Fragment, null);
};
