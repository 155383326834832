var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { getSingleButtonContent } from 'components/organisms/Form';
import { getTokens } from 'utils';
import { bindPayment, fetchSagePay, validateCardOrInstallments } from 'services';
import ErrorMessage from 'components/atoms/ErrorMessage';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 30px;\n"], ["\n  padding: 30px;\n"])));
var ModalTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var ModalBody = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n    margin-bottom: 10px;\n  }\n"], ["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n    margin-bottom: 10px;\n  }\n"])));
var ModalButtons = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var PaymentWidget = function (_a) {
    var content = _a.content, jobId = _a.jobId, modalOpen = _a.modalOpen, onClose = _a.onClose, onSagePayConnected = _a.onSagePayConnected, validationEndpoint = _a.validationEndpoint, isRefund = _a.isRefund, refundAmount = _a.refundAmount, billingAddress = _a.billingAddress, topupMilesPath = _a.topupMilesPath, showAddCard = _a.showAddCard, showSavedCard = _a.showSavedCard;
    var _b = useState(true), showCardButton = _b[0], setShowCardButton = _b[1];
    var _c = useState(''), expiredCardMessage = _c[0], setExpiredCardMessage = _c[1];
    var history = useHistory();
    var btnCancel = getSingleButtonContent('btnCancel', content.modalButtons);
    var btnContinue = getSingleButtonContent('btnContinue', content.modalButtons);
    var btnNewCard = getSingleButtonContent('btnNewCard', content.modalButtons);
    var btnSavedCard = getSingleButtonContent('btnSavedCard', content.modalButtons);
    var replacePlacholderText = function (value) {
        var placeholders = {
            REFUND_TOTAL: "\u00A3" + refundAmount,
        };
        return (value === null || value === void 0 ? void 0 : value.replace(/REFUND_TOTAL/gi, function (matched) { return placeholders[matched]; })) || '';
    };
    var addNewCard = function () {
        var tokens = getTokens();
        if (tokens) {
            var obj = {
                billingAddress: billingAddress,
                paymentDetails: {
                    creditCardData: {
                        isCardRegisteredToYou: true,
                    },
                    reuseExistingCard_itb: false,
                },
                applicationType: 'SFE_ITB',
            };
            fetchSagePay(jobId, obj, tokens.accessToken, tokens.refreshToken, function (link) { return onSagePayConnected(link); }, topupMilesPath);
        }
    };
    var bindPaymentToPolicy = function (jobId, reuseCard) {
        var tokens = getTokens();
        if (tokens) {
            var useExistingCard = { reuseExistingCard_itb: reuseCard };
            bindPayment(jobId, useExistingCard, tokens.accessToken, tokens.refreshToken, function () { return history.push('/accepted'); });
        }
    };
    var makePayment = function (validation) { return __awaiter(void 0, void 0, void 0, function () {
        var tokens;
        return __generator(this, function (_a) {
            tokens = getTokens();
            if (tokens) {
                validateCardOrInstallments(jobId, validation, tokens.accessToken, tokens.refreshToken, function (validationResult) {
                    if ((typeof validationResult === 'boolean' && validationResult === true) ||
                        (typeof validationResult === 'number' && validationResult > 0)) {
                        bindPaymentToPolicy(jobId, true);
                    }
                    if (typeof validationResult === 'boolean' && validationResult === false) {
                        setShowCardButton(true);
                        setExpiredCardMessage('The card we have on file seems to have expired, You can add a new one by clicking "Add new card" below.');
                    }
                });
            }
            return [2 /*return*/];
        });
    }); };
    //TODO: add btnNewCard content to buttons on installemt payment
    return (React.createElement(Root, null,
        React.createElement(Dialog, { open: modalOpen },
            React.createElement(ModalWrapper, null,
                React.createElement(ModalTitle, null, content.title),
                React.createElement(ModalBody, null,
                    React.createElement(ReactMarkdown, { escapeHtml: false, source: replacePlacholderText(content.body) }),
                    expiredCardMessage !== '' && React.createElement(ErrorMessage, { errorMessage: expiredCardMessage })),
                React.createElement(ModalButtons, null,
                    React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () { return onClose(); } }, btnCancel.text),
                    showCardButton && expiredCardMessage === '' && showAddCard ? (React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                            setExpiredCardMessage('');
                            onClose();
                            addNewCard();
                            TagManagerArgs('Button', window.location.pathname + " - Payment Widget", 'Add a new card');
                        } }, 'Add a new card')) : (React.createElement(React.Fragment, null)),
                    showCardButton && expiredCardMessage === '' && showSavedCard ? (React.createElement(Button, { buttonStyle: "primary", onClick: function () {
                            makePayment(validationEndpoint);
                            TagManagerArgs('Button', window.location.pathname + " - Payment Widget", isRefund ? 'Continue' : 'Use saved card');
                        } }, isRefund ? 'Continue' : 'Use saved card')) : (React.createElement(React.Fragment, null)))))));
};
export default PaymentWidget;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
