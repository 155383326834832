var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import FormLabel from './FormLabel';
import { color, fontFamily } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .MuiFormControl-root {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px ", ";\n    background-color: #ffffff;\n    padding: 0 17px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n    transition: 0.3s;\n  }\n\n  .MuiInputBase-input {\n    margin: 0;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiIconButton-root {\n    padding: 5px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .MuiFormControl-root {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px ", ";\n    background-color: #ffffff;\n    padding: 0 17px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n    transition: 0.3s;\n  }\n\n  .MuiInputBase-input {\n    margin: 0;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-size: 14px;\n    font-family: ", ";\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiIconButton-root {\n    padding: 5px;\n  }\n"])), function (props) { return (props.error ? 'red' : 'rgba(137, 137, 137, 0.3)'); }, color.black, fontFamily.base);
var FormDateYearMonthOnly = function (_a) {
    var label = _a.label, toolTip = _a.toolTip, name = _a.name, control = _a.control, additionalToTooltip = _a.additionalToTooltip, error = _a.error, _b = _a.isRequired, isRequired = _b === void 0 ? false : _b;
    return (React.createElement(Root, { error: error },
        React.createElement(FormLabel, { label: label, toolTip: toolTip, additionalToTooltip: additionalToTooltip, isRequired: isRequired }),
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(Controller, { render: function (props) { return (React.createElement(KeyboardDatePicker, { inputRef: props.ref, name: props.name, onChange: props.onChange, value: props.value, disableToolbar: true, disableFuture: true, format: "MM/yyyy", KeyboardButtonProps: {
                        'aria-label': 'change date',
                    }, InputLabelProps: {
                        shrink: true,
                    }, placeholder: "mm / yyyy", views: ['year', 'month'] })); }, rules: { required: isRequired }, name: name, control: control, defaultValue: null }))));
};
export default FormDateYearMonthOnly;
var templateObject_1;
