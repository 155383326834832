var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import Reactmarkdown from 'react-markdown';
import styled from 'styled-components';
import CheckIcon from 'assets/check.svg';
import Button from 'components/atoms/Button';
import { fontWeight, color, media } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: white;\n  border-radius: 4px;\n  margin: 0 16px;\n  text-align: center;\n  padding: 36px 20px;\n\n  ", "\n\n  ", "\n"], ["\n  background-color: white;\n  border-radius: 4px;\n  margin: 0 16px;\n  text-align: center;\n  padding: 36px 20px;\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 0 48px;\n  "], ["\n    margin: 0 48px;\n  "]))), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 0 256px;\n  "], ["\n    margin: 0 256px;\n  "]))));
var TitleWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 25px;\n  font-weight: ", ";\n  margin: 30px 0;\n"], ["\n  font-size: 25px;\n  font-weight: ", ";\n  margin: 30px 0;\n"])), fontWeight.medium);
var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var Checkbox = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 18px;\n  height: 18px;\n  border-radius: 2px;\n  border: 1px solid #202020;\n  color: ", ";\n  margin: 16px auto;\n\n  svg {\n    margin-top: -2px;\n    margin-left: -2px;\n  }\n"], ["\n  width: 18px;\n  height: 18px;\n  border-radius: 2px;\n  border: 1px solid #202020;\n  color: ", ";\n  margin: 16px auto;\n\n  svg {\n    margin-top: -2px;\n    margin-left: -2px;\n  }\n"])), color.green);
var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n\n  > * {\n    margin: 30px 20px;\n\n    :first-child {\n      margin-left: auto;\n    }\n    :last-child {\n      margin-right: auto;\n    }\n  }\n"], ["\n  display: flex;\n\n  > * {\n    margin: 30px 20px;\n\n    :first-child {\n      margin-left: auto;\n    }\n    :last-child {\n      margin-right: auto;\n    }\n  }\n"])));
var MapConsent = function (_a) {
    var title = _a.title, text = _a.text, _b = _a.checkable, checkable = _b === void 0 ? false : _b, disAgreeButtonText = _a.disAgreeButtonText, agreeButtonText = _a.agreeButtonText, onAgree = _a.onAgree, onClose = _a.onClose;
    var _c = useState(false), checked = _c[0], setChecked = _c[1];
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null, title),
        React.createElement(TextWrapper, null,
            React.createElement(Reactmarkdown, { source: text })),
        checkable && React.createElement(Checkbox, { onClick: function () { return setChecked(!checked); } }, checked && React.createElement(CheckIcon, null)),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                    onClose();
                    TagManagerArgs('Button', window.location.pathname + " - Map Consent", disAgreeButtonText);
                } }, disAgreeButtonText),
            React.createElement(Button, { disabled: !checked, onClick: function () {
                    onAgree();
                    TagManagerArgs('Button', window.location.pathname + " - Map Consent", agreeButtonText);
                } }, agreeButtonText))));
};
export default MapConsent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
