var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ListRow from 'components/molecules/ListRow';
import { fontWeight, media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 20px 15px;\n\n  ", "\n"], ["\n  margin: 20px 15px;\n\n  ",
    "\n"])), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 20px 30px;\n  "], ["\n    margin: 20px 30px;\n  "]))));
var ListWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: #f9f9f9;\n  border-top: 1px solid rgba(137, 137, 137, 0.1);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.1);\n  padding: 0 15px;\n\n  ", "\n"], ["\n  background-color: #f9f9f9;\n  border-top: 1px solid rgba(137, 137, 137, 0.1);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.1);\n  padding: 0 15px;\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background-color: transparent;\n    border-top: 1px solid rgba(137, 137, 137, 0.5);\n    border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  "], ["\n    background-color: transparent;\n    border-top: 1px solid rgba(137, 137, 137, 0.5);\n    border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  "]))));
var OtherExcess = function (_a) {
    var title = _a.title, otherExcesses = _a.otherExcesses;
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null, title),
        React.createElement(ListWrapper, null, otherExcesses.map(function (item, index) { return (React.createElement(ListRow, { key: item.name, label: item.name, labelStyle: { fontWeight: fontWeight.light }, value: "" + item.amount, style: { borderBottom: index === otherExcesses.length - 1 ? 'none' : '1px solid rgba(137, 137, 137, 0.1)' } })); }))));
};
export default OtherExcess;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
