var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import find from 'lodash/find';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
import Button from 'components/atoms/Button';
import { FormDatePicker, FormSelect, getSingleFieldContent, getSingleFormGroupContent, Group, } from 'components/organisms/Form';
import RemoveIcon from 'assets/close.svg';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  grid-column: 1/-1;\n  justify-content: center;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  grid-column: 1/-1;\n  justify-content: center;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var ClaimsTable = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
var ClaimsRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  display: grid;\n  padding: 10px 0;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: 300;\n"], ["\n  align-items: center;\n  display: grid;\n  padding: 10px 0;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: 300;\n"])));
var ClaimsHeaders = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  padding: 15px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-weight: 400;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  padding: 15px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-weight: 400;\n"])));
var IconCell = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  cursor: pointer;\n\n  svg {\n    fill: red;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  cursor: pointer;\n\n  svg {\n    fill: red;\n  }\n"])));
var initialClaimState = {
    claimDate: undefined,
    claimType: undefined,
};
var AddDriverClaims = function (_a) {
    var formGroups = _a.formGroups, onChange = _a.onChange, amendDetails = _a.amendDetails;
    var _b = useState(initialClaimState), claim = _b[0], setClaim = _b[1];
    var _c = useState(amendDetails ? amendDetails.data : []), claimsList = _c[0], setClaimsList = _c[1];
    var _d = useState(amendDetails ? amendDetails.display : []), claimsListDisplay = _d[0], setClaimsListDisplay = _d[1];
    var claimsContent = getSingleFormGroupContent('claimsSection', formGroups);
    var claimDate = getSingleFieldContent('claimDate', claimsContent.fields);
    var claimType = getSingleFieldContent('claimType', claimsContent.fields);
    var claimTypeOptions = getGwDropdownValues(claimType.gwTypelist);
    var addClaim = function () {
        // GW data
        var gwClaim = __assign(__assign({}, claim), { claimDate: {
                day: moment(claim.claimDate).date(),
                month: moment(claim.claimDate).month(),
                year: moment(claim.claimDate).year(),
            } });
        // Format for display on the FE
        var displayClaimReason = find(claimTypeOptions, function (obj) {
            return obj.value === claim.claimType;
        });
        var displayClaim = {
            convictionDate: moment(claim.claimDate).format('DD / MM / yyyy'),
            disqualificationMonths: displayClaimReason.label,
        };
        setClaimsList(__spreadArrays(claimsList, [gwClaim]));
        setClaimsListDisplay(__spreadArrays(claimsListDisplay, [displayClaim]));
    };
    var removeClaim = function (index) {
        var removedDisplay = claimsListDisplay.filter(function (value, listIndex) {
            if (listIndex !== index)
                return value;
        });
        var removed = claimsList.filter(function (value, listIndex) {
            if (listIndex !== index)
                return value;
        });
        setClaimsListDisplay(removedDisplay);
        setClaimsList(removed);
    };
    useEffect(function () {
        onChange({
            display: claimsListDisplay,
            data: claimsList,
        });
    }, [claimsListDisplay, claimsList]);
    return (React.createElement(Root, null,
        React.createElement(Group, { title: claimsContent.title },
            React.createElement(FormDatePicker, { label: claimDate.label, toolTip: claimDate.toolTip, name: claimDate.value, additionalToTooltip: claimDate.additionalToTooltip, onChange: function (value) { return setClaim(__assign(__assign({}, claim), { claimDate: value.toString() })); } }),
            React.createElement(FormSelect, { label: claimType.label, options: claimTypeOptions, toolTip: claimType.toolTip, placeholder: claimType.placeholder, name: claimType.value, spanTwoCols: claimType.spanTwoColumns, additionalToTooltip: claimType.additionalToTooltip, onChange: function (value) { return setClaim(__assign(__assign({}, claim), { claimType: value })); } }),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { disabled: Object.values(claim).includes(undefined), onClick: function () {
                        addClaim();
                        TagManagerArgs('Button', window.location.pathname + " - Add a claim", 'Add');
                    } }, "Add")),
            claimsListDisplay.length > 0 && (React.createElement(ClaimsTable, null,
                React.createElement(ClaimsHeaders, null,
                    React.createElement("div", null, "When did it happen"),
                    React.createElement("div", null, "What happened?")),
                claimsListDisplay.map(function (item, index) { return (React.createElement(ClaimsRow, { key: item + " " + index },
                    Object.values(item).map(function (cell, cellIndex) { return (React.createElement("div", { key: cell + " " + cellIndex }, cell)); }),
                    React.createElement(IconCell, { onClick: function () {
                            removeClaim(index);
                            TagManagerArgs('Button', window.location.pathname + " - Remove a claim", 'Add');
                        } },
                        React.createElement(RemoveIcon, null)))); }))))));
};
export default AddDriverClaims;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
