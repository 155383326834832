import moment from 'moment';
import { DateFormat, StringValue } from './enum';
export var isoDateFormatTo = function (date) {
    var dayMonthYear = function () { return moment(date).format(DateFormat.DD_MM_YYYY); };
    var monthYear = function () { return moment(date).format(DateFormat.MM_YYYY); };
    return {
        dayMonthYear: dayMonthYear,
        monthYear: monthYear,
    };
};
export var numberFormatTo = function (value) {
    var yearsAfterNumber = function () { return value + " " + (value === 1 ? StringValue.YEAR : StringValue.YEARS); };
    var milesAfterNumber = function () { return value + " " + StringValue.MILES; };
    var priceFormat = function (type) {
        if (type === 'withDecimal')
            return "\u00A3" + value.toFixed(2);
        if (type === 'noDecimal')
            return "\u00A3" + value;
        return '';
    };
    return {
        yearsAfterNumber: yearsAfterNumber,
        milesAfterNumber: milesAfterNumber,
        priceFormat: priceFormat,
    };
};
export var numberFormatToText = function (value) {
    var text = ['None', 'One', 'Two', 'Three', 'Four', 'Five'];
    return text[value];
};
export var textBetweenBrackets = function (value) {
    var regExp = /\(([^)]+)\)/;
    var match = regExp.exec(value);
    if (match !== null)
        return match[1];
    return value;
};
export var booleanToText = function (value) { return (value === true ? 'Yes' : 'No'); };
