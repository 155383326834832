var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { color } from 'styles';
import { FormInput, FormPassword } from '../Form';
import ErrorMessage from '../../atoms/ErrorMessage';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var FieldWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 30px;\n\n  ::placeholder {\n    font-size: 14px;\n  }\n"], ["\n  margin-bottom: 30px;\n\n  ::placeholder {\n    font-size: 14px;\n  }\n"])));
var ForgotPasswordLink = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  text-decoration: underline;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  text-decoration: underline;\n"])), color.green);
var LoginForm = function (_a) {
    var signIn = _a.signIn, onPasswordForgottenClick = _a.onPasswordForgottenClick, content = _a.content, loginError = _a.loginError, loginErrorMessage = _a.loginErrorMessage;
    var emailLabel = content.emailLabel, emailPlaceholder = content.emailPlaceholder, passwordLabel = content.passwordLabel, passwordPlaceholder = content.passwordPlaceholder, forgotPasswordText = content.forgotPasswordText, signInText = content.signInText;
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), password = _c[0], setPassword = _c[1];
    var onFormSubmit = function (e) {
        e.preventDefault();
        if (email !== '' && password !== '') {
            signIn(email, password);
        }
    };
    return (React.createElement(Root, null,
        React.createElement("form", { onSubmit: onFormSubmit, noValidate: true },
            React.createElement(FieldWrapper, null,
                React.createElement(FormInput, { type: "email", name: "email", label: emailLabel, placeholder: emailPlaceholder, onChange: function (value) { return setEmail(value); } })),
            React.createElement(FieldWrapper, null,
                React.createElement(FormPassword, { label: passwordLabel, valueToParent: function (value) { return setPassword(value); }, placeHolder: passwordPlaceholder })),
            loginError && (React.createElement(FieldWrapper, null,
                React.createElement(ErrorMessage, { errorMessage: loginErrorMessage }))),
            React.createElement(FieldWrapper, null,
                React.createElement(ForgotPasswordLink, { onClick: function () { return onPasswordForgottenClick(); } }, forgotPasswordText)),
            React.createElement(Button, { type: "submit", fullWidth: true, disabled: email === '' || password === '', onClick: function () { return TagManagerArgs('Button', window.location.pathname + " - Login Button", signInText); } }, signInText))));
};
export default LoginForm;
var templateObject_1, templateObject_2, templateObject_3;
