export var getPage = function (currentPage, totalPages, pageType) {
    var page = currentPage;
    switch (pageType) {
        case 'FIRST':
            page = 1;
            break;
        case 'PREV':
            if (currentPage > 1) {
                page = currentPage - 1;
            }
            break;
        case 'NEXT':
            if (currentPage < totalPages) {
                page = currentPage + 1;
            }
            break;
        case 'LAST':
            page = totalPages;
            break;
    }
    return page;
};
