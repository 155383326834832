var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import ToolTip from '../../molecules/Tooltip';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 16px;\n  align-items: flex-end;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  font-size: 14px;\n"], ["\n  min-height: 16px;\n  align-items: flex-end;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  font-size: 14px;\n"])));
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: ", ";\n  line-height: 1.43;\n\n  ", "\n"], ["\n  margin-right: ", ";\n  line-height: 1.43;\n\n  ",
    "\n"])), function (props) { return (props.toolTipSpace ? '10px' : '0'); }, function (props) {
    return props.isRequired && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ::after {\n        content: '*';\n        color: red;\n        margin-right: 3px;\n      }\n    "], ["\n      ::after {\n        content: '*';\n        color: red;\n        margin-right: 3px;\n      }\n    "])));
});
var AdditionalInfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 5px;\n  font-size: 14px;\n  font-weight: 300;\n  font-style: italic;\n  color: #898989;\n  padding-right: ", ";\n  width: 100%;\n  line-height: 1.43;\n"], ["\n  margin-top: 5px;\n  font-size: 14px;\n  font-weight: 300;\n  font-style: italic;\n  color: #898989;\n  padding-right: ", ";\n  width: 100%;\n  line-height: 1.43;\n"])), function (props) { return (props.toolTipSpace ? '10px' : '0'); });
var Label = function (_a) {
    var label = _a.label, toolTip = _a.toolTip, additionalToTooltip = _a.additionalToTooltip, isRequired = _a.isRequired;
    return (React.createElement(Root, null,
        React.createElement("div", null,
            React.createElement(LabelWrapper, { toolTipSpace: toolTip ? true : false, isRequired: isRequired }, label),
            additionalToTooltip && (React.createElement(AdditionalInfoWrapper, { toolTipSpace: additionalToTooltip ? true : false },
                React.createElement(ReactMarkdown, { skipHtml: true, source: additionalToTooltip })))),
        toolTip && React.createElement(ToolTip, { text: toolTip })));
};
export default Label;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
