var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from 'assets/check.svg';
import RemoveIcon from 'assets/remove.svg';
import HideOn from 'components/atoms/HideOn';
import SectionHeader from 'components/molecules/SectionHeader';
import { TableCellType } from '../types';
import { fontWeight } from 'styles';
import CoverRadioButton from './CoverRadioButton';
import TableCell from './TableCell';
import BreakdownCoverMobile from './BreakdownCoverMobile';
import find from 'lodash/find';
import RadioButton from '../RadioButton';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var IntroWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-bottom: 40px;\n"], ["\n  font-weight: ", ";\n  margin-bottom: 40px;\n"])), fontWeight.light);
var Table = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid #202020;\n"], ["\n  border-bottom: 1px solid #202020;\n"])));
var BenefitWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 2;\n  align-self: flex-end;\n"], ["\n  flex: 2;\n  align-self: flex-end;\n"])));
var Covers = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 3;\n\n  > * {\n    flex: 1;\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  flex: 3;\n\n  > * {\n    flex: 1;\n    margin-left: 10px;\n  }\n"])));
var RowWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var OptOutWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 40px;\n"], ["\n  margin-top: 40px;\n"])));
var BreakdownCoverSection = function (_a) {
    var content = _a.content, breakdownCosts = _a.breakdownCosts, onBreakDownSelection = _a.onBreakDownSelection, roadRescueSelected = _a.roadRescueSelected, initialRoadRescueIndex = _a.initialRoadRescueIndex, removeRoadRescue = _a.removeRoadRescue;
    var _b = useState(initialRoadRescueIndex !== undefined ? initialRoadRescueIndex : -1), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var _c = useState(roadRescueSelected), optOut = _c[0], setOptOut = _c[1];
    var title = content.title, intro = content.intro, chooseCoverText = content.chooseCoverText, coverOptions = content.coverOptions, benefits = content.benefits;
    var levelOne = find(breakdownCosts, function (obj) {
        return obj.code === 'Level1';
    });
    var levelTwo = find(breakdownCosts, function (obj) {
        return obj.code === 'Level2';
    });
    var levelThree = find(breakdownCosts, function (obj) {
        return obj.code === 'Level3';
    });
    var replacePlacholderText = function (value) {
        var placeholders = {
            LEVEL_ONE: levelOne === null || levelOne === void 0 ? void 0 : levelOne.amount.amount.toFixed(2).toString(),
            LEVEL_TWO: levelTwo === null || levelTwo === void 0 ? void 0 : levelTwo.amount.amount.toFixed(2).toString(),
            LEVEL_THREE: levelThree === null || levelThree === void 0 ? void 0 : levelThree.amount.amount.toFixed(2).toString(),
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/LEVEL_ONE|LEVEL_TWO|LEVEL_THREE/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    var selectedCover = function (index) {
        setSelectedIndex(index);
        onBreakDownSelection(index);
    };
    return (React.createElement(Root, null,
        React.createElement(SectionHeader, { title: title, expandable: false }),
        React.createElement(IntroWrapper, null, intro),
        React.createElement(HideOn, { smallOnly: true, mediumOnly: true },
            React.createElement(Table, null,
                React.createElement(RowWrapper, null,
                    React.createElement(BenefitWrapper, null),
                    React.createElement(Covers, null, coverOptions.map(function (option, index) { return (React.createElement(CoverRadioButton, { key: option.coverTitle, selected: index === selectedIndex, title: option.coverTitle, subtitle: option.coverSubtitle, onSelect: function () { return selectedCover(index); } })); }))),
                React.createElement(RowWrapper, null,
                    React.createElement(BenefitWrapper, null,
                        React.createElement(TableCell, { cellType: TableCellType.TEXT, text: chooseCoverText, style: { borderBottom: '1px solid #202020' } })),
                    React.createElement(Covers, null, coverOptions.map(function (option, index) { return (React.createElement(TableCell, { key: option.priceText, selected: index === selectedIndex, cellType: TableCellType.TEXT, text: breakdownCosts ? replacePlacholderText(option.priceText) : '', style: { padding: '0 16px' }, borderStyle: {
                            borderBottom: index === selectedIndex ? '1px solid #FFFFFF66' : '1px solid rgba(137, 137, 137, 0.25)',
                        } })); }))),
                benefits.map(function (benefit, index) {
                    var isLastRow = index === benefits.length - 1;
                    return (React.createElement(RowWrapper, { key: benefit.value },
                        React.createElement(BenefitWrapper, null,
                            React.createElement(TableCell, { style: {
                                    textAlign: 'left',
                                    borderBottom: isLastRow ? 'none' : '1px solid rgba(137, 137, 137, 0.25)',
                                }, cellType: TableCellType.TEXT, text: benefit.text })),
                        React.createElement(Covers, null, coverOptions.map(function (option, index) { return (React.createElement(TableCell, { key: option.coverTitle, style: { padding: '16px' }, borderStyle: {
                                borderBottom: isLastRow
                                    ? 'none'
                                    : index === selectedIndex
                                        ? '1px solid #FFFFFF66'
                                        : '1px solid rgba(137, 137, 137, 0.25)',
                                paddingBottom: '16px',
                            }, selected: index === selectedIndex, cellType: TableCellType.ICON, icon: option.coveredBenefits.includes(benefit.value) ? React.createElement(CheckIcon, null) : React.createElement(RemoveIcon, null) })); }))));
                }))),
        React.createElement(HideOn, { large: true }, coverOptions.map(function (option, index) { return (React.createElement(BreakdownCoverMobile, { key: option.coverTitle, selected: index === selectedIndex, onSelect: function () { return selectedCover(index); }, coverOption: option, benefits: benefits, replacePlacholderText: replacePlacholderText })); })),
        React.createElement(OptOutWrapper, null,
            React.createElement(RadioButton, { selected: !optOut, title: React.createElement("div", { style: { fontWeight: fontWeight.light } }, 'I do not require breakdown cover'), price: undefined, onSelect: function () {
                    setOptOut(!optOut);
                    removeRoadRescue();
                } }))));
};
export default BreakdownCoverSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
