var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { media, fontWeight } from 'styles';
import WarningFilledIcon from 'assets/warning_filled.svg';
import WarningIcon from 'assets/warning.svg';
import ArrowForwardIcon from 'assets/arrow_forward.svg';
import ArrowBackIcon from 'assets/arrow_back.svg';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  user-select: none;\n"], ["\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  user-select: none;\n"])));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 10px 14px;\n\n  ", "\n"], ["\n  display: flex;\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 10px 14px;\n\n  ",
    "\n"])), function (props) { return (props.red ? 'rgba(173,31,31,1.0)' : 'rgba(255,195,0,0.8)'); }, function (props) { return (props.red ? 'white' : '#202020'); }, fontWeight.medium, media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    align-items: center;\n  "], ["\n    align-items: center;\n  "]))));
var TitleWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n  "], ["\n    display: flex;\n    align-items: center;\n  "]))));
var Title = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var PagerWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  font-size: 14px;\n  padding: 6px 18px 0 18px;\n"], ["\n  display: flex;\n  font-size: 14px;\n  padding: 6px 18px 0 18px;\n"])));
var PageNumbers = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  span {\n    font-weight: ", ";\n  }\n"], ["\n  span {\n    font-weight: ", ";\n  }\n"])), fontWeight.light);
var IconWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  margin-top: 2px;\n"], ["\n  color: ",
    ";\n  cursor: pointer;\n  margin-top: 2px;\n"])), function (props) {
    return props.fillWhite ? (props.disabled ? '#eeeeee60' : 'white') : props.disabled ? '#606060' : '#202020';
});
var ArrowBackIconWrapper = styled(IconWrapper)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-left: 16px;\n"], ["\n  margin-left: 16px;\n"])));
var ArrowForwardIconWrapper = styled(IconWrapper)(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
var MessageWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 14px;\n  font-style: italic;\n  padding: 10px 14px;\n"], ["\n  font-size: 14px;\n  font-style: italic;\n  padding: 10px 14px;\n"])));
var Notifications = function (_a) {
    var notifications = _a.notifications;
    var _b = useState(notifications[0]), notification = _b[0], setNotification = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var red = notifications[0].red;
    var length = notifications.length;
    var onPageSelect = function (type) {
        if (type === 'prev') {
            if (page > 1) {
                setPage(page - 1);
                setNotification(notifications[page - 2]);
            }
        }
        if (type === 'next') {
            if (page < length) {
                setPage(page + 1);
                setNotification(notifications[page]);
            }
        }
    };
    return (React.createElement(Root, null,
        React.createElement(Header, { red: red },
            React.createElement(TitleWrapper, null,
                red ? React.createElement(WarningIcon, null) : React.createElement(WarningFilledIcon, null),
                React.createElement(Title, null, notification.heading)),
            length > 1 && (React.createElement(PagerWrapper, null,
                React.createElement(PageNumbers, { dangerouslySetInnerHTML: { __html: page + "<span>/" + length + "</span>" } }),
                React.createElement(ArrowBackIconWrapper, { disabled: page === 1, fillWhite: red, onClick: function () { return onPageSelect('prev'); } },
                    React.createElement(ArrowBackIcon, null)),
                React.createElement(ArrowForwardIconWrapper, { disabled: page === length, fillWhite: red, onClick: function () { return onPageSelect('next'); } },
                    React.createElement(ArrowForwardIcon, null))))),
        React.createElement(MessageWrapper, null, notification.description)));
};
export default Notifications;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
