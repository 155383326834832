var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { lighten, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { color, media, fontSize } from 'styles';
var BorderLinearProgress = withStyles({
    root: {
        height: '15px',
        backgroundColor: lighten(color.green, 0.8),
        borderRadius: '7.5px',
    },
    bar: {
        backgroundColor: color.orange,
        borderRadius: 20,
    },
})(LinearProgress);
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ScaleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 4px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 4px;\n"])));
var BigTick = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 1px;\n  height: 10px;\n  background-color: ", ";\n  border-radius: 0.5px;\n  margin: auto;\n"], ["\n  width: 1px;\n  height: 10px;\n  background-color: ", ";\n  border-radius: 0.5px;\n  margin: auto;\n"])), color.greyMiles);
var SmallTick = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 1px;\n  height: 6px;\n  background-color: ", ";\n  border-radius: 0.5px;\n  margin: auto;\n"], ["\n  width: 1px;\n  height: 6px;\n  background-color: ", ";\n  border-radius: 0.5px;\n  margin: auto;\n"])), color.greyMiles);
var CountWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 3px;\n  font-size: ", ";\n"], ["\n  margin-top: 3px;\n  font-size: ", ";\n"])), fontSize.fs14);
var EvenWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var FormatWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 10px;\n  text-align: center;\n\n  ", "\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 10px;\n  text-align: center;\n\n  ",
    "\n"])), color.greyMiles, fontSize.fs12, media.medium(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 16px;\n  "], ["\n    margin-top: 16px;\n  "]))));
var ProgressBar = function (_a) {
    var remainingMiles = _a.remainingMiles, label = _a.label, counts = _a.counts;
    return (React.createElement(Root, null,
        React.createElement(BorderLinearProgress, { variant: "determinate", color: "secondary", value: remainingMiles / (counts.length * 10 - 1 * 10) }),
        React.createElement(ScaleWrapper, null, counts.map(function (count, index) { return (React.createElement(EvenWrapper, { key: index }, count & 1 ? (React.createElement(SmallTick, null)) : (React.createElement(React.Fragment, null,
            React.createElement(BigTick, null),
            React.createElement(CountWrapper, null,
                count,
                "k"))))); })),
        React.createElement(FormatWrapper, null, label)));
};
export default ProgressBar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
