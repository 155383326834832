var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import base64 from 'base-64';
import { ContentPageURL, MockPolicyNumbers, SessionStorageKey, } from 'types';
import mockPoliciesResponse from './mock/policies.json';
import mockTokenResponse from './mock/token.json';
import mockLoginPageContent from './mock/loginPage.json';
import mockDashboardPageContent from './mock/dashboardPage.json';
import mockMyPolicyPageContent from './mock/myPolicyPage.json';
import mockTopupMilesPageContent from './mock/topupMilesContent.json';
import mockDrivingDataPageContent from './mock/drivingdataPage.json';
import mockPageHeaderContent from './mock/pageHeader.json';
import mockPaymentWidgetContent from './mock/paymentWidgetContent.json';
import mockItbPolicy65 from './mock/ITB%252F15008565.json';
import mockItbPolicy66 from './mock/ITB%252F15008566.json';
import mockItbPolicy67 from './mock/ITB%252F15008567.json';
import mockItbPolicy68 from './mock/ITB%252F15008568.json';
import mockQuoteSummaryContent from './mock/quoteSummaryPage.json';
import mockPopupGuideContent from './mock/popupGuide.json';
import mockMessagesContent from './mock/messagesPageContent.json';
import userInfo from './mock/userInfo.json';
import pdfObject from './mock/pdfBase64.json';
import mockAccountInfo from './mock/accountInfo.json';
import { getRandomNumberBetween } from 'utils/math';
export var FACADE_SERVICE_BASE_URL = '/api/facade-service';
var CONTENT_BASE_URL = '/api/contentful-service';
var AUTHORIZATION_BASE_URL = '/api/login-service';
export var POLICY_BASE_URL = '/api/policy-service';
export var BASE_URL = "" + process.env.PORTAL_BASE_URL;
export var handlePromise = function (promise) {
    return promise
        .then(function (response) { return ({
        status: 200,
        payload: response.data,
    }); })
        .catch(function (error) {
        var _a;
        return ({
            status: (_a = error.response) === null || _a === void 0 ? void 0 : _a.status,
            payload: undefined,
        });
    });
};
export var login = function (email, password) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: mockTokenResponse,
            });
        });
    }
    return handlePromise(axios.get(AUTHORIZATION_BASE_URL + "/token?brand=itb", {
        headers: { 'x-authorization': 'Basic ' + base64.encode(email + ':' + password) },
    }));
};
export var refreshToken = function (refreshToken) {
    return handlePromise(axios.get(AUTHORIZATION_BASE_URL + "/refresh-token?brand=itb", {
        headers: { 'x-authorization': refreshToken },
    }));
};
export var getPageContent = function (pageUri) {
    if (process.env.mock) {
        var pageContent_1 = '';
        if (pageUri === ContentPageURL.LOGIN_PAGE) {
            pageContent_1 = mockLoginPageContent;
        }
        if (pageUri === ContentPageURL.DASHBOARD_PAGE) {
            pageContent_1 = mockDashboardPageContent;
        }
        if (pageUri === ContentPageURL.MY_POLICY_PAGE) {
            pageContent_1 = mockMyPolicyPageContent;
        }
        if (pageUri === ContentPageURL.TOPUP_MILES_PAGE) {
            pageContent_1 = mockTopupMilesPageContent;
        }
        if (pageUri === ContentPageURL.PAGE_HEADER) {
            pageContent_1 = mockPageHeaderContent;
        }
        if (pageUri === ContentPageURL.DRIVING_DATA_PAGE) {
            pageContent_1 = mockDrivingDataPageContent;
        }
        if (pageUri === ContentPageURL.QUOTESUMMARY_PAGE) {
            pageContent_1 = mockQuoteSummaryContent;
        }
        if (pageUri === ContentPageURL.MESSAGES_PAGE) {
            pageContent_1 = mockMessagesContent;
        }
        if (pageUri === ContentPageURL.PAYMENT_WIDGET) {
            pageContent_1 = mockPaymentWidgetContent;
        }
        if (pageUri === ContentPageURL.POPUP_GUIDE) {
            pageContent_1 = mockPopupGuideContent;
        }
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: {
                    pageUrl: pageContent_1.pageUrl,
                    header: pageContent_1.header,
                    contentSections: pageContent_1.contentSections,
                },
            });
        });
    }
    return handlePromise(axios.get(CONTENT_BASE_URL + "/" + pageUri));
};
export var getPolicies = function (token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: mockPoliciesResponse,
            });
        });
    }
    return handlePromise(axios.get(POLICY_BASE_URL + "/policies", { headers: { 'x-authorization': token } }));
};
export var getPolicy = function (policyId, token) {
    if (process.env.mock) {
        var policyData_1 = mockItbPolicy65;
        if (policyId === MockPolicyNumbers.ITB66) {
            policyData_1 = mockItbPolicy66;
        }
        if (policyId === MockPolicyNumbers.ITB67) {
            policyData_1 = mockItbPolicy67;
        }
        if (policyId === MockPolicyNumbers.ITB68) {
            policyData_1 = mockItbPolicy68;
        }
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: policyData_1,
            });
        });
    }
    return handlePromise(axios.get(POLICY_BASE_URL + "/policies/" + policyId, { headers: { 'x-authorization': token } }));
};
export var getUser = function (policyId, firstName, lastName, dateOfBirth, token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: userInfo,
            });
        });
    }
    return handlePromise(axios.get(POLICY_BASE_URL + "/policies/" + policyId + "/fetchdriver?firstName=" + firstName + "&lastName=" + lastName + "&dateOfBirth=" + dateOfBirth, {
        headers: { 'x-authorization': token, 'Content-Type': 'application/json' },
    }));
};
export var getDocumentById = function (documentId, token, responseType) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: pdfObject.pdfMultiPages,
                contentType: 'application/pdf',
            });
        });
    }
    var requestId = getRandomNumberBetween(10000, 99999);
    return axios
        .get(FACADE_SERVICE_BASE_URL + "/documents/" + documentId + "?requestId=" + requestId, {
        headers: { authorization: "Bearer " + token },
        responseType: responseType,
    })
        .then(function (response) {
        var contentType = response.headers['content-type'];
        return {
            status: 200,
            payload: responseType === 'arraybuffer'
                ? contentType === 'text/html'
                    ? new TextDecoder('utf-8').decode(response.data)
                    : Buffer.from(response.data, 'binary').toString('base64')
                : response.data,
            contentType: contentType,
        };
    })
        .catch(function (error) {
        var _a;
        return ({
            status: (_a = error.response) === null || _a === void 0 ? void 0 : _a.status,
            payload: undefined,
        });
    });
};
export var getDocumentByIdFromPolicy = function (id, sessionId, name, mimeType, policyNumber, token) {
    return axios
        .get(POLICY_BASE_URL + "/docs/" + id + "?token=" + sessionId + "&name=" + name + "&mimeType=" + mimeType + "&policyNumber=" + policyNumber + "&jobId=undefined", {
        headers: { 'x-authorization': token },
        responseType: 'arraybuffer',
    })
        .then(function (response) {
        return {
            status: 200,
            payload: response.data,
        };
    })
        .catch(function (error) {
        var _a;
        return ({
            status: (_a = error.response) === null || _a === void 0 ? void 0 : _a.status,
            payload: undefined,
        });
    });
};
export var mtaTokenRefresh = function () { return __awaiter(void 0, void 0, void 0, function () {
    var sessionToken, tokenResult, payload, access_token, refresh_token;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                sessionToken = sessionStorage.getItem(SessionStorageKey.REFRESH_TOKEN);
                return [4 /*yield*/, refreshToken(sessionToken)];
            case 1:
                tokenResult = _a.sent();
                if (tokenResult.status === 200) {
                    payload = tokenResult.payload;
                    access_token = payload.access_token, refresh_token = payload.refresh_token;
                    sessionStorage.setItem(SessionStorageKey.ACCESS_TOKEN, access_token);
                    sessionStorage.setItem(SessionStorageKey.REFRESH_TOKEN, refresh_token);
                    return [2 /*return*/, 200];
                }
                else
                    return [2 /*return*/, 401];
                return [2 /*return*/];
        }
    });
}); };
// ///////////////////////////////////
// Use MTA add driver
export var addNewDriver = function (policyId, data, date, token) {
    if (data === void 0) { data = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, mta;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {
                        'x-authorization': token,
                        'Content-Type': 'application/json',
                    };
                    return [4 /*yield*/, axios({
                            url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/drivers?effectiveDateTime=" + date,
                            method: 'POST',
                            data: JSON.stringify(data),
                            headers: headers,
                        })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 401) {
                                    return [2 /*return*/, {
                                            status: 401,
                                            data: 'Token refresh failed',
                                        }];
                                }
                                return [2 /*return*/, {
                                        status: response.status,
                                        data: response.data,
                                    }];
                            });
                        }); })
                            .catch(function (error) {
                            return {
                                status: error,
                                data: error.response,
                            };
                        })];
                case 1:
                    mta = _a.sent();
                    return [2 /*return*/, mta];
            }
        });
    });
};
////////////////
// Use MTA overnightAddress
// Important!! This is a PUT request, not POST
export var changeOvernightAddress = function (policyId, data, date, params, token) {
    if (data === void 0) { data = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, mta;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {
                        'x-authorization': token,
                        'Content-Type': 'application/json',
                    };
                    return [4 /*yield*/, axios({
                            url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/address?effectiveDateTime=" + date + params,
                            method: 'PUT',
                            data: JSON.stringify(data),
                            headers: headers,
                        })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 401) {
                                    return [2 /*return*/, {
                                            status: 401,
                                            data: 'Token refresh failed',
                                        }];
                                }
                                return [2 /*return*/, {
                                        status: response.status,
                                        data: response.data,
                                    }];
                            });
                        }); })
                            .catch(function (error) {
                            return {
                                status: error,
                                data: error.response,
                            };
                        })];
                case 1:
                    mta = _a.sent();
                    return [2 /*return*/, mta];
            }
        });
    });
};
//////////////////////////////
// Use MTA add driver
export var changeVehicle = function (policyId, data, date, token) {
    if (data === void 0) { data = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, mta;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {
                        'x-authorization': token,
                        'Content-Type': 'application/json',
                    };
                    return [4 /*yield*/, axios({
                            url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/vehicle?effectiveDateTime=" + date,
                            method: 'PUT',
                            data: JSON.stringify(data),
                            headers: headers,
                        })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 401) {
                                    return [2 /*return*/, {
                                            status: 401,
                                            data: 'Token refresh failed',
                                        }];
                                }
                                return [2 /*return*/, {
                                        status: response.status,
                                        data: response.data,
                                    }];
                            });
                        }); })
                            .catch(function (error) {
                            return {
                                status: error,
                                data: error.response,
                            };
                        })];
                case 1:
                    mta = _a.sent();
                    return [2 /*return*/, mta];
            }
        });
    });
};
// Address lookup api's
export var getAddressesFromPostCode = function (postcode, token) {
    return axios
        .get(POLICY_BASE_URL + "/addresses?postalCode=" + postcode, {
        headers: { 'x-authorization': token },
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (response.status === 401) {
                return [2 /*return*/, {
                        status: 401,
                        data: 'Token refresh failed',
                    }];
            }
            return [2 /*return*/, {
                    status: response.status,
                    data: response.data,
                }];
        });
    }); })
        .catch(function (error) {
        return {
            status: error.response.status,
            data: error.response,
        };
    });
};
export var getDetailedAddressFromId = function (id, token) {
    return axios
        .get(POLICY_BASE_URL + "/addresses/" + id, {
        headers: { 'x-authorization': token },
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (response.status === 401) {
                return [2 /*return*/, {
                        status: 401,
                        data: 'Token refresh failed',
                    }];
            }
            return [2 /*return*/, {
                    status: response.status,
                    data: response.data,
                }];
        });
    }); })
        .catch(function (error) {
        return {
            status: error.response.status,
            data: error.response,
        };
    });
};
// ///////////////////////
// Vehilce lookup by registration
export var getVehicleFromRegistration = function (registration, token) {
    return axios
        .get(POLICY_BASE_URL + "/vehicles/" + registration, {
        headers: { 'x-authorization': token },
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (response.status === 401) {
                return [2 /*return*/, {
                        status: 401,
                        data: 'Token refresh failed',
                    }];
            }
            return [2 /*return*/, {
                    status: response.status,
                    data: response.data,
                }];
        });
    }); })
        .catch(function (error) {
        return {
            status: error.response.status,
            data: error.response,
        };
    });
};
// //////////
// Multi mtas
export var multiMtas = function (policyId, data, date, token) {
    if (data === void 0) { data = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, mtaChange;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {
                        'x-authorization': token,
                        'Content-Type': 'application/json',
                    };
                    return [4 /*yield*/, axios({
                            url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/policyChange?effectiveDateTime=" + date,
                            method: 'PUT',
                            data: JSON.stringify(data),
                            headers: headers,
                        })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 401) {
                                    return [2 /*return*/, {
                                            status: 401,
                                            data: 'Token refresh failed',
                                        }];
                                }
                                return [2 /*return*/, {
                                        status: response.status,
                                        data: response.data,
                                    }];
                            });
                        }); })
                            .catch(function (error) {
                            return {
                                status: error,
                                data: error.response,
                            };
                        })];
                case 1:
                    mtaChange = _a.sent();
                    return [2 /*return*/, mtaChange];
            }
        });
    });
};
export var quoteMultiMtas = function (policyId, jobNumber, data, date, token) {
    if (data === void 0) { data = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, quote;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {
                        'x-authorization': token,
                        'Content-Type': 'application/json',
                    };
                    return [4 /*yield*/, axios({
                            url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/quote/" + jobNumber + "?effectiveDateTime=" + date,
                            method: 'POST',
                            data: JSON.stringify(data),
                            headers: headers,
                        })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 401) {
                                    return [2 /*return*/, {
                                            status: 401,
                                            data: 'Token refresh failed',
                                        }];
                                }
                                return [2 /*return*/, {
                                        status: response.status,
                                        data: response.data,
                                    }];
                            });
                        }); })
                            .catch(function (error) {
                            return {
                                status: error,
                                data: error.response,
                            };
                        })];
                case 1:
                    quote = _a.sent();
                    return [2 /*return*/, quote];
            }
        });
    });
};
// //////////
// Get remove a driver
export var removeDriver = function (policyId, publicId, date, token) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, quote;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/drivers/pc%253A" + publicId + "?effectiveDateTime=" + date,
                        method: 'DELETE',
                        headers: headers,
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error,
                            data: error.response,
                        };
                    })];
            case 1:
                quote = _a.sent();
                return [2 /*return*/, quote];
        }
    });
}); };
// //////////
// Get more policy info
export var getDetailedPolicy = function (policyId, date, token) {
    return axios
        .get(POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/load?effectiveDateTime=" + date, {
        headers: { 'x-authorization': token },
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (response.status === 401) {
                return [2 /*return*/, {
                        status: 401,
                        data: 'Token refresh failed',
                    }];
            }
            return [2 /*return*/, {
                    status: response.status,
                    data: response.data,
                }];
        });
    }); })
        .catch(function (error) {
        return {
            status: error.response.status,
            data: error.response,
        };
    });
};
// //////////
// Amend a driver
export var amendDriver = function (policyId, publicId, data, date, token) {
    if (data === void 0) { data = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var headers, mta;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    headers = {
                        'x-authorization': token,
                        'Content-Type': 'application/json',
                    };
                    return [4 /*yield*/, axios({
                            url: POLICY_BASE_URL + "/policies/ITB%252F" + policyId + "/drivers/pc%253A" + publicId + "?effectiveDateTime=" + date,
                            method: 'PUT',
                            data: JSON.stringify(data),
                            headers: headers,
                        })
                            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (response.status === 401) {
                                    return [2 /*return*/, {
                                            status: 401,
                                            data: 'Token refresh failed',
                                        }];
                                }
                                return [2 /*return*/, {
                                        status: response.status,
                                        data: response.data,
                                    }];
                            });
                        }); })
                            .catch(function (error) {
                            return {
                                status: error,
                                data: error.response,
                            };
                        })];
                case 1:
                    mta = _a.sent();
                    return [2 /*return*/, mta];
            }
        });
    });
};
// //////////
// Get typekeys
export var getTypeKeys = function () {
    return axios
        .get(POLICY_BASE_URL + "/" + ContentPageURL.TYPKEYS_URL)
        .then(function (response) { return (window.sessionStorage.typeKeys = JSON.stringify(response.data)); });
};
// ///////////
// Helpful info content
export var getHelpfulInformationContent = function () {
    return axios.get(CONTENT_BASE_URL + "/helpfulinformation/all").then(function (response) {
        return {
            status: response.status,
            data: response.data,
        };
    });
};
// get policy renewal for amend journey
export var getRenewalDirectBuy = function (policyId, token) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, renewal;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/renewals/ITB%252F" + policyId,
                        method: 'GET',
                        headers: headers,
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                renewal = _a.sent();
                return [2 /*return*/, renewal];
        }
    });
}); };
export var getRenewalForAmend = function (policyId, calculate, token) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, renewal;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/renewals/ITB%252F" + policyId + "/amend?calculateQuote=" + calculate,
                        method: 'GET',
                        headers: headers,
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                renewal = _a.sent();
                return [2 /*return*/, renewal];
        }
    });
}); };
export var getRenewalAmendQuote = function (policyId, data, token) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, renewal;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/renewals/ITB%252F" + policyId + "/quote",
                        method: 'POST',
                        data: JSON.stringify(data),
                        headers: headers,
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                renewal = _a.sent();
                return [2 /*return*/, renewal];
        }
    });
}); };
export var getAccountInfo = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, accountInfo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (process.env.mock) {
                    return [2 /*return*/, new Promise(function (resolve) { return resolve(mockAccountInfo); })];
                }
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/profilesettings/accountInfo",
                        method: 'GET',
                        headers: headers,
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                accountInfo = _a.sent();
                return [2 /*return*/, accountInfo];
        }
    });
}); };
export var updateContactNumbers = function (token, data) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, contactNumbers;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/profilesettings/updatePhoneNumbers",
                        method: 'PUT',
                        headers: headers,
                        data: JSON.stringify(data),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                contactNumbers = _a.sent();
                return [2 /*return*/, contactNumbers];
        }
    });
}); };
export var updateEmailAddress = function (token, data) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, email;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/profilesettings/updateEmailAddress",
                        method: 'PUT',
                        headers: headers,
                        data: JSON.stringify(data),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                email = _a.sent();
                return [2 /*return*/, email];
        }
    });
}); };
export var updateCorrespondenceAddress = function (token, data) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, address;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/profilesettings/updateCorrespondenceAddress",
                        method: 'POST',
                        headers: headers,
                        data: JSON.stringify(data),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                address = _a.sent();
                return [2 /*return*/, address];
        }
    });
}); };
export var updateDocsPreference = function (token, data) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, email;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/profilesettings/updateDocsPreference",
                        method: 'PUT',
                        headers: headers,
                        data: JSON.stringify(data),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                email = _a.sent();
                return [2 /*return*/, email];
        }
    });
}); };
// IMPORTANT - This is for accounts setting page only
// This has nothing to do with with any password functionality from the login screen
export var updatePassword = function (token, data) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, email;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: AUTHORIZATION_BASE_URL + "/reset-password?brand=itb",
                        method: 'PUT',
                        headers: headers,
                        data: JSON.stringify(data),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                email = _a.sent();
                return [2 /*return*/, email];
        }
    });
}); };
export var resetPassword = function (email) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, reset;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': '',
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/users/resetPassword",
                        method: 'POST',
                        headers: headers,
                        data: JSON.stringify({ email: email, newPasswordEntryUrl: BASE_URL + "/auth/resetpassword", brand_itb: 'itb' }),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                reset = _a.sent();
                return [2 /*return*/, reset];
        }
    });
}); };
export var changePassword = function (token, newPassword) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, reset;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': '',
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/users/createNewPassword",
                        method: 'POST',
                        headers: headers,
                        data: JSON.stringify({ code: token, new_password: newPassword, brand_itb: 'itb' }),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                reset = _a.sent();
                return [2 /*return*/, reset];
        }
    });
}); };
export var getUserInfo = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, reset;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: AUTHORIZATION_BASE_URL + "/userinfo?brand=itb",
                        method: 'GET',
                        headers: headers,
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                reset = _a.sent();
                return [2 /*return*/, reset];
        }
    });
}); };
export var updateCoverages = function (token, policyId, data) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, reset;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'x-authorization': token,
                    'Content-Type': 'application/json',
                };
                return [4 /*yield*/, axios({
                        url: POLICY_BASE_URL + "/renewals/ITB%252F" + policyId + "/updatecoverages",
                        method: 'PUT',
                        headers: headers,
                        data: JSON.stringify(data),
                    })
                        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (response.status === 401) {
                                return [2 /*return*/, {
                                        status: 401,
                                        data: 'Token refresh failed',
                                    }];
                            }
                            return [2 /*return*/, {
                                    status: response.status,
                                    data: response.data,
                                }];
                        });
                    }); })
                        .catch(function (error) {
                        return {
                            status: error.response.status,
                            data: error.response,
                        };
                    })];
            case 1:
                reset = _a.sent();
                return [2 /*return*/, reset];
        }
    });
}); };
export var updateAutomaticRenewal = function (token, data, policyNumber) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, payload;
    return __generator(this, function (_a) {
        headers = {
            'x-authorization': token,
            'Content-Type': 'application/json',
        };
        payload = [
            {
                operation: 'SET_AUTO_RENEW_CHOICE',
                path: 'autoRenew',
                value: data,
            },
        ];
        return [2 /*return*/, axios({
                url: POLICY_BASE_URL + "/policies/ITB%252F" + policyNumber,
                method: 'PATCH',
                headers: headers,
                data: JSON.stringify(payload),
            })
                .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (response.status === 401) {
                        return [2 /*return*/, {
                                status: 401,
                                data: 'Token refresh failed',
                            }];
                    }
                    return [2 /*return*/, {
                            status: response.status,
                            data: response.data,
                        }];
                });
            }); })
                .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response,
                };
            })];
    });
}); };
