import React from 'react';
import ExcessSection from 'components/organisms/ExcessSection';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { tableHeaders, otherExcessTitle } from 'components/organisms/ExcessSection/ExcessSection.story';
export var QuoteExcesses = function (_a) {
    var quote = _a.quote;
    // Uses get componet from another space, currently no API
    var excesses = quote.myExcessPayment.damageExcesses;
    var otherExcesses = [
        {
            name: 'Windscreen replacement',
            amount: quote.myExcessPayment.glassReplacementExcess,
        },
        {
            name: 'Windscreen repair',
            amount: quote.myExcessPayment.glassRepairExcess,
        },
    ];
    var data = { excesses: excesses, otherExcesses: otherExcesses };
    var content = {
        title: 'My Excess',
        intro: 'Excess is the amount you will need to pay in the event of a claim for damage to your car.',
        tableHeaders: tableHeaders,
        otherExcessTitle: otherExcessTitle,
    };
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(ExcessSection, { data: data, content: content, expandable: false })))));
};
