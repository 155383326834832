var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import ReactMarkdown from 'react-markdown';
import roadSafetyImg from 'assets/Roadsafetylogo.png';
import pactImg from 'assets/PACTlogo.png';
import { media } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: auto;\n"], ["\n  margin-top: auto;\n"])));
var ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  color: white;\n  font-size: 14px;\n  flex-direction: column;\n\n  strong {\n    display: block;\n  }\n\n  ", "\n"], ["\n  display: flex;\n  color: white;\n  font-size: 14px;\n  flex-direction: column;\n\n  strong {\n    display: block;\n  }\n\n  ",
    "\n"])), media.medium(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex-direction: row;\n  "], ["\n    flex-direction: row;\n  "]))));
var LogosWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-top: 20px;\n  justify-content: space-between;\n\n  img {\n    height: 100px;\n  }\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  margin-top: 20px;\n  justify-content: space-between;\n\n  img {\n    height: 100px;\n  }\n\n  ",
    "\n"])), media.medium(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 0;\n    justify-content: center;\n\n    img {\n      margin-left: 20px;\n    }\n  "], ["\n    margin-top: 0;\n    justify-content: center;\n\n    img {\n      margin-left: 20px;\n    }\n  "]))));
var Footer = function (_a) {
    var footerContent = _a.footerContent;
    return (React.createElement(React.Fragment, null,
        React.createElement(PaddingVertical, null),
        React.createElement(Root, null,
            React.createElement(Layout, null,
                React.createElement("div", { style: { backgroundColor: '#373737' } },
                    React.createElement(PaddingHorizontal, null,
                        React.createElement(PaddingVertical, null,
                            React.createElement(ContentWrapper, null,
                                React.createElement(ReactMarkdown, { source: footerContent.body }),
                                React.createElement(LogosWrapper, null,
                                    React.createElement("img", { src: roadSafetyImg, alt: "" }),
                                    React.createElement("img", { src: pactImg, alt: "" }))))))))));
};
export default Footer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
