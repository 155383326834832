var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/atoms/Button';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n"], ["\n  padding: 20px;\n"])));
var CloseButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 20px 0;\n  display: flex;\n  justify-content: center;\n"], ["\n  padding: 20px 0;\n  display: flex;\n  justify-content: center;\n"])));
export var TempRenewalModal = function (_a) {
    var open = _a.open, close = _a.close;
    return (React.createElement(Dialog, { open: open },
        React.createElement(Root, null,
            React.createElement("p", null,
                "We\u2019re sorry, you\u2019re currently unable to edit your renewal quote online. If you want to make a change to your policy from your renewal start date, please contact us on Live Chat or call us on",
                ' ',
                React.createElement("strong", null, "0333 103 0000"),
                ". Our opening hours are",
                ' ',
                React.createElement("strong", null, "Mon-Fri: 8am-7:45pm (Live Chat: 8am-7pm), Sat: 9am-4pm, Sun: Closed.")),
            React.createElement("br", null),
            React.createElement("em", null, "Any calls to numbers starting 03 cost the same as calls to numbers starting 01 or 02 and may be included in any inclusive calls package you may have. insurethebox does not earn any revenue from the use of the 03 number."),
            React.createElement(CloseButtonWrapper, null,
                React.createElement(Button, { onClick: function () { return close(false); } }, "Close")))));
};
export default TempRenewalModal;
var templateObject_1, templateObject_2;
