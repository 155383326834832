var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { color, fontWeight, fontFamily } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 30px;\n  text-align: center;\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n  margin-bottom: 30px;\n  text-align: center;\n"])), fontWeight.medium);
var HintWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 50px;\n"], ["\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 50px;\n"])));
var BackButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 160px;\n  color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  margin: 0 auto;\n  padding: 16px;\n"], ["\n  width: 160px;\n  color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  margin: 0 auto;\n  padding: 16px;\n"])), color.greyMedium, fontFamily.condensed);
var CheckEmail = function (_a) {
    var onBackToSignIn = _a.onBackToSignIn, content = _a.content;
    var checkEmailTitle = content.checkEmailTitle, checkEmailHint = content.checkEmailHint, backToSignInText = content.backToSignInText;
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null, checkEmailTitle),
        React.createElement(HintWrapper, null,
            React.createElement(ReactMarkdown, { source: checkEmailHint, escapeHtml: false })),
        React.createElement(BackButton, { onClick: function () { return onBackToSignIn(); } }, backToSignInText)));
};
export default CheckEmail;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
