var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import PDFIcon from 'assets/pdf.svg';
// import PrinterIcon from 'assets/printer.svg'
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import { fontWeight } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    margin: 32px 0;\n  }\n"], ["\n  ", " {\n    margin: 32px 0;\n  }\n"])), ButtonRoot);
var MessageWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  justify-content: space-between;\n  padding: 12px 0;\n"], ["\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  justify-content: space-between;\n  padding: 12px 0;\n"])));
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #898989;\n"], ["\n  color: #898989;\n"])));
var ValueWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var ActionsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-left: auto;\n  padding: 12px 0;\n\n  svg {\n    fill: #30a369;\n  }\n"], ["\n  width: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-left: auto;\n  padding: 12px 0;\n\n  svg {\n    fill: #30a369;\n  }\n"])));
var ContentWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 16px 0;\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 16px 0;\n"])));
var MessageDetailMobile = function (_a) {
    var _b, _c, _d, _e;
    var 
    // showPDF,
    // printMessage,
    header = _a.header, message = _a.message, messageContent = _a.messageContent, closeButtontext = _a.closeButtontext, onMessageClose = _a.onMessageClose;
    return (React.createElement(Root, null,
        React.createElement(MessageWrapper, null,
            React.createElement(LabelWrapper, null, (_b = header.find(function (item) { return item.value === 'subject'; })) === null || _b === void 0 ? void 0 :
                _b.label,
                ":"),
            React.createElement(ValueWrapper, null, message.subject)),
        React.createElement(MessageWrapper, null,
            React.createElement(LabelWrapper, null, (_c = header.find(function (item) { return item.value === 'from'; })) === null || _c === void 0 ? void 0 :
                _c.label,
                ":"),
            React.createElement(ValueWrapper, null, message.from)),
        React.createElement(MessageWrapper, null,
            React.createElement(LabelWrapper, null, (_d = header.find(function (item) { return item.value === 'sentOn'; })) === null || _d === void 0 ? void 0 :
                _d.label,
                ":"),
            React.createElement(ValueWrapper, null, moment(message.sentOn).format('DD / MM / yy'))),
        React.createElement(MessageWrapper, null,
            React.createElement(LabelWrapper, null, (_e = header.find(function (item) { return item.value === 'messageType'; })) === null || _e === void 0 ? void 0 :
                _e.label,
                ":"),
            React.createElement(ValueWrapper, null, message.messageType)),
        React.createElement(ActionsWrapper, null,
            React.createElement(PDFIcon, null)),
        React.createElement(ContentWrapper, null, messageContent),
        React.createElement(Button, { fullWidth: true, onClick: function () {
                onMessageClose();
                TagManagerArgs('Button', window.location.pathname + " - Message Detail", closeButtontext);
            }, buttonStyle: "secondary" }, closeButtontext)));
};
export default MessageDetailMobile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
