import React, { useState } from 'react';
import ForgotPassword from './ForgotPassword';
import PasswordChanged from './PasswordChanged';
import { Root, Logo, ContentContainer, PAGE_STATUS } from './shareComponents';
var ResetPassword = function (_a) {
    var content = _a.content, backToSignIn = _a.backToSignIn, onResetPassword = _a.onResetPassword;
    var logo = content.logo, emailLabel = content.emailLabel, emailPlaceholder = content.emailPlaceholder, signInText = content.signInText, forgotPasswordTitle = content.forgotPasswordTitle, forgotPasswordHint = content.forgotPasswordHint, backToSignInText = content.backToSignInText, resetButtonText = content.resetButtonText, newPasswordTitle = content.newPasswordTitle, newPasswordHint = content.newPasswordHint, newPasswordLabel = content.newPasswordLabel, newPasswordPlaceholder = content.newPasswordPlaceholder, confirmPasswordLabel = content.confirmPasswordLabel, confirmPasswordPlaceholder = content.confirmPasswordPlaceholder, passwordChangedTitle = content.passwordChangedTitle, passwordChangedHint = content.passwordChangedHint;
    var forgotPasswordContent = {
        forgotPasswordTitle: forgotPasswordTitle,
        forgotPasswordHint: forgotPasswordHint,
        emailLabel: emailLabel,
        emailPlaceholder: emailPlaceholder,
        backToSignInText: backToSignInText,
        resetButtonText: resetButtonText,
        newPasswordTitle: newPasswordTitle,
        newPasswordHint: newPasswordHint,
        newPasswordLabel: newPasswordLabel,
        newPasswordPlaceholder: newPasswordPlaceholder,
        confirmPasswordLabel: confirmPasswordLabel,
        confirmPasswordPlaceholder: confirmPasswordPlaceholder,
    };
    var passwordChangedContent = {
        passwordChangedTitle: passwordChangedTitle,
        passwordChangedHint: passwordChangedHint,
        signInText: signInText,
    };
    var _b = useState(PAGE_STATUS.CONFIRM_PASSWORD), pageStatus = _b[0], setPageStatus = _b[1];
    return (React.createElement(Root, null,
        React.createElement(Logo, null,
            React.createElement("img", { src: logo, alt: "" })),
        React.createElement(ContentContainer, null,
            pageStatus === PAGE_STATUS.CONFIRM_PASSWORD && (React.createElement(ForgotPassword, { shouldSetNewPassword: true, content: forgotPasswordContent, onBackToSignIn: backToSignIn, onReset: function (password) {
                    setPageStatus(PAGE_STATUS.PASSWORD_CHANGED);
                    onResetPassword(password);
                } })),
            pageStatus === PAGE_STATUS.PASSWORD_CHANGED && (React.createElement(PasswordChanged, { content: passwordChangedContent, onSignIn: backToSignIn })))));
};
export default ResetPassword;
