var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var YearThree = function (_a) {
    var content = _a.content;
    return (React.createElement(Root, null,
        React.createElement("div", null,
            React.createElement("table", { width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { colSpan: 3 },
                            React.createElement(ReactMarkdown, { escapeHtml: false, source: content['and.quoteandbind.pm.view.quote.pncdAppendix.noclaimswithout'] }))),
                    React.createElement("tr", null,
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.1claimin12']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2claimin12']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.3claimin12']))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2Year']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.1Year']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.nilYears']))))),
        React.createElement("div", null,
            React.createElement("table", { width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { colSpan: 4 },
                            React.createElement(ReactMarkdown, { escapeHtml: false, source: content['and.quoteandbind.pm.view.quote.pncdAppendix.2'] }))),
                    React.createElement("tr", null,
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.1claimin1year']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2claimin1year']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.3claimin1year']))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.unaffected']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.unaffected']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.nilYears']))))),
        React.createElement("div", null,
            React.createElement("h3", { style: { fontWeight: 400, fontSize: 16 } }, content['and.quoteandbind.pm.view.quote.pncdAppendix.1claimin5yearcontinious.itb'])),
        React.createElement("div", null,
            React.createElement("table", { width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { colSpan: 3 },
                            React.createElement(ReactMarkdown, { escapeHtml: false, source: content['and.quoteandbind.pm.view.quote.pncdAppendix.noclaimswithout'] }))),
                    React.createElement("tr", null,
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.1claimin12']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2claimin12']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.3claimin12']))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2Year']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.1Year']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.nilYears']))))),
        React.createElement("div", null,
            React.createElement("table", { width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { colSpan: 3 },
                            React.createElement(ReactMarkdown, { escapeHtml: false, source: content['and.quoteandbind.pm.view.quote.pncdAppendix.2'] }))),
                    React.createElement("tr", null,
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.1claimin1year']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2claimin1year']),
                        React.createElement("th", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.3claimin1year']))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.unaffected']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.2Year']),
                        React.createElement("td", null, content['and.quoteandbind.pm.view.quote.pncdAppendix.nilYears'])))))));
};
export default YearThree;
var templateObject_1;
