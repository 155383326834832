import axios from 'axios';
import mockNotifications from './mock/notifications.json';
import mockMessages from './mock/messages.json';
import { handlePromise } from './api';
var MESSAGE_URL = "/api/facade-service/message";
var NOTIFICATION_URL = "/api/facade-service/notification";
export var getNotifications = function (policyId, token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: mockNotifications,
            });
        });
    }
    return handlePromise(axios.get(NOTIFICATION_URL + "?policyNumber=" + policyId, { headers: { Authorization: "Bearer " + token } }));
};
export var getMessages = function (policyId, token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: mockMessages,
            });
        });
    }
    return handlePromise(axios.get(MESSAGE_URL + "?policyNumber=" + policyId, {
        headers: { Authorization: "Bearer " + token },
    }));
};
