var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { FormDatePicker, getSingleFieldContent, Group } from 'components/organisms/Form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var RenewalCoverStartDate = function (_a) {
    var content = _a.content, startDate = _a.startDate, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var periodStartDate = getSingleFieldContent('periodStartDate', content.fields);
    return (React.createElement(Root, null,
        React.createElement(Group, { title: content.title },
            React.createElement(FormDatePicker, { label: periodStartDate.label, name: periodStartDate.value, toolTip: periodStartDate.toolTip, disabled: disabled, value: startDate, disableFuture: false }))));
};
export default RenewalCoverStartDate;
var templateObject_1;
