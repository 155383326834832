var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EmailIcon from 'assets/email2.svg';
import EmailGreenIcon from 'assets/email_green.svg';
import SMSIcon from 'assets/sms.svg';
import FilterIcon from 'assets/filter.svg';
import SortIcon from 'assets/sort.svg';
import Pager, { getPage } from 'components/molecules/Pager';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Rows = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid #898989;\n  border-top: 1px solid #898989;\n  margin-bottom: 30px;\n"], ["\n  border-bottom: 1px solid #898989;\n  border-top: 1px solid #898989;\n  margin-bottom: 30px;\n"])));
var Row = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  cursor: pointer;\n  font-weight: ", ";\n  padding: 12px 24px;\n\n  :last-child {\n    border-bottom: none;\n  }\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  cursor: pointer;\n  font-weight: ", ";\n  padding: 12px 24px;\n\n  :last-child {\n    border-bottom: none;\n  }\n"])), function (props) { return (props.read ? fontWeight.regular : fontWeight.medium); });
var Cell = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: ", ";\n  align-items: center;\n  overflow: hidden;\n  padding-right: 16px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"], ["\n  display: flex;\n  flex: ", ";\n  align-items: center;\n  overflow: hidden;\n  padding-right: 16px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"])), function (props) { return (props.wide ? 3 : 1); });
var MarginRight = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
var MessageTypeWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: 10px;\n"], ["\n  margin-left: 10px;\n"])));
var MessageList = function (_a) {
    var header = _a.header, messages = _a.messages, page = _a.page, selectPage = _a.selectPage, showMessageDetail = _a.showMessageDetail;
    var keys = header.map(function (item) { return item.value; });
    var _b = useState(messages), allMessages = _b[0], setAllMessages = _b[1];
    var _c = useState(messages.slice(0, 5)), visibleMessages = _c[0], setVisibleMessages = _c[1];
    useEffect(function () {
        setAllMessages(messages);
        setVisibleMessages(messages.slice(0, 5));
    }, [messages]);
    var sortItems = function (sortBy) {
        allMessages.sort(function (msg1, msg2) {
            var a = get(msg1, sortBy);
            var b = get(msg2, sortBy);
            return sortBy === 'sentOn' ? (a > b ? -1 : a < b ? 1 : 0) : a > b ? 1 : a < b ? -1 : 0;
        });
        setAllMessages(allMessages);
        setVisibleMessages(allMessages.slice(0, 5));
        selectPage(1);
    };
    var onPageSelect = function (pageType) {
        var newPage = getPage(page, Math.ceil(messages.length / 5.0), pageType);
        selectPage(newPage);
        setVisibleMessages(allMessages.slice((newPage - 1) * 5, newPage * 5));
    };
    return (React.createElement(Root, null,
        React.createElement(Row, { read: false }, header.map(function (item) { return (React.createElement(Cell, { key: item.value, wide: item.value === 'subject' },
            React.createElement(MarginRight, null, item.label),
            (item.value === 'from' || item.value === 'messageType') && (React.createElement(FilterIcon, { onClick: function () { return sortItems(item.value); } })),
            item.value === 'sentOn' && React.createElement(SortIcon, { onClick: function () { return sortItems('sentOn'); } }))); })),
        React.createElement(Rows, null, visibleMessages.map(function (message) { return (React.createElement(Row, { key: message.messageId, read: message.notification === null, onClick: function () { return showMessageDetail(message); } }, keys.map(function (key) { return (React.createElement(Cell, { key: key, wide: key === 'subject' },
            (key === 'from' || key === 'subject') && get(message, key),
            key === 'messageType' && (React.createElement(React.Fragment, null,
                message.messageType === 'SMS' ? (React.createElement(SMSIcon, null)) : message.notification ? (React.createElement(EmailGreenIcon, null)) : (React.createElement(EmailIcon, null)),
                React.createElement(MessageTypeWrapper, null, get(message, key)))),
            key === 'sentOn' && moment(get(message, key)).format('DD / MM / yyyy'))); }))); })),
        React.createElement(Pager, { currentPage: page, totalPages: Math.ceil(messages.length / 5.0), onPageSelect: onPageSelect })));
};
export default MessageList;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
