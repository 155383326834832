var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getPageContent, login } from 'services';
import { FormInput, FormPassword, getSingleFormGroupContent } from 'components/organisms/Form';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import RenewalOvernightParking from 'components/organisms/RenewalOvernightParking/RenewalOvernightParking';
import ShareComponent from 'pages/ShareComponent';
import { MyContactDetails } from './MyContactDetails';
import { MyDetails } from './MyDetails';
import { MyPassword } from './MyPassword';
import { MyCommunicationPrefs } from './MyCommunicationPrefs';
import { getDriverDisplayValues } from 'guidewirePolicyUtils';
import { ContentPageURL, SessionStorageKey } from 'types';
import { handleUpdatePassword, handleCommunicationPrefs, handleUpdateContactDetails, handleUpdateCorrespondenceAddress, } from './handlers';
import { MyMapPermissionPref } from './MyMapPermissionPref';
import { DISPLAY_TRIPS_NAME, getTokens, MAP_PERMISSION_NAME } from 'utils';
import { fetchMapConsentPermission, sendMapConsentAgreement } from 'services/drivingDataClient';
import { Dialog } from '@material-ui/core';
import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 30px;\n"], ["\n  padding: 30px;\n"])));
var ModalTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"], ["\n  font-size: 25px;\n  padding-bottom: 30px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n"])));
var ModalBody = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n  }\n"], ["\n  margin: 30px 0;\n\n  p {\n    font-size: 16px;\n    font-weight: 300;\n    line-height: 1.56;\n    letter-spacing: normal;\n  }\n"])));
var ModalButtons = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
var MyAccountPage = function (_a) {
    var accountInfo = _a.accountInfo, currentPolicyIndex = _a.currentPolicyIndex, policyIds = _a.policyIds, refresh = _a.refresh, setRefreshPolicy = _a.setRefreshPolicy;
    var history = useHistory();
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), myDetailsContent = _d[0], setMyDetailsContent = _d[1];
    var _e = useState(undefined), myContactDetailsContent = _e[0], setMyContactDetailsContent = _e[1];
    var _f = useState(undefined), myPasswordContent = _f[0], setMyPasswordContent = _f[1];
    var _g = useState(undefined), correspondenceAddressContent = _g[0], setCorrespondenceAddressContent = _g[1];
    var _h = useState(undefined), addressLookupContent = _h[0], setAddressLookupContent = _h[1];
    var _j = useState(undefined), communicationsContent = _j[0], setCommunicationsContent = _j[1];
    var _k = useState(undefined), accountInfoDisplay = _k[0], setAccountInfoDisplay = _k[1];
    // const [address, setAddress] = useState<CorrespondenceAddress | undefined>(undefined)
    var _l = useState(false), tripsDisplayAllowed = _l[0], setTripsDisplayAllowed = _l[1];
    var _m = useState('Deny'), mapPermission = _m[0], setMapPermission = _m[1];
    var _o = useState(false), openLoginModal = _o[0], setOpenLoginModal = _o[1];
    var _p = useState(''), email = _p[0], setEmail = _p[1];
    var _q = useState(''), password = _q[0], setPassword = _q[1];
    var _r = useState(''), loginErrorMessage = _r[0], setLoginErrorMessage = _r[1];
    var _s = useForm(), register = _s.register, control = _s.control, watch = _s.watch, reset = _s.reset;
    var watchedFields = watch();
    // Sends request to GW for numbers/email updates
    var updateContactDetails = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var status;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleUpdateContactDetails(values, accountInfoDisplay, history)
                    // Only apllies to email being changed
                ];
                case 1:
                    status = _a.sent();
                    // Only apllies to email being changed
                    if (status === 200) {
                        setOpenLoginModal(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    // Sets for the Frontend to use/display the values
    var setChangedAddress = function (correspondenceAddress) {
        var data = correspondenceAddress;
        data = __assign(__assign({}, data), { changed: true, addressType: 'Correspondence' });
        // setAddress(data)
        reset({
            addressLine1: data.addressLine1,
            city: data.city,
            postalCode: data.postalCode,
        });
    };
    // Resets values for FE / display
    var resetAddressChange = function () {
        var _a, _b, _c;
        // setAddress(undefined)
        reset({
            addressLine1: (_a = accountInfoDisplay === null || accountInfoDisplay === void 0 ? void 0 : accountInfoDisplay.correspondenceAddress) === null || _a === void 0 ? void 0 : _a.addressLine1,
            city: (_b = accountInfoDisplay === null || accountInfoDisplay === void 0 ? void 0 : accountInfoDisplay.correspondenceAddress) === null || _b === void 0 ? void 0 : _b.city,
            postalCode: (_c = accountInfoDisplay === null || accountInfoDisplay === void 0 ? void 0 : accountInfoDisplay.correspondenceAddress) === null || _c === void 0 ? void 0 : _c.postalCode,
        });
    };
    // request update of address with GW
    var updateCorrespondenceAddress = function (selected) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleUpdateCorrespondenceAddress(selected, history);
            return [2 /*return*/];
        });
    }); };
    // request update of post/email prefs with GW
    var updateCommunicationPrefs = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleCommunicationPrefs(watchedFields, history);
            return [2 /*return*/];
        });
    }); };
    var updatePassword = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var status;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleUpdatePassword(values, history)];
                case 1:
                    status = _a.sent();
                    if (status === 200) {
                        setOpenLoginModal(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleLogin = function () {
        login(email, password).then(function (result) {
            var loginStatus = result.status, authData = result.payload;
            if (loginStatus === 200 && authData) {
                var access_token = authData.access_token, refresh_token = authData.refresh_token;
                if (access_token && refresh_token) {
                    sessionStorage.setItem(SessionStorageKey.ACCESS_TOKEN, access_token);
                    sessionStorage.setItem(SessionStorageKey.REFRESH_TOKEN, refresh_token);
                    setOpenLoginModal(false);
                    history.go(0);
                }
            }
            if (loginStatus === 401) {
                setLoginErrorMessage('Please check your username or password is correct');
            }
            if (loginStatus === 500) {
                setLoginErrorMessage('There is currently a problem with our sever');
            }
        });
    };
    var fetchMapPermissionCallback = function (behaviourValue) {
        setMapPermission(behaviourValue);
    };
    var fetchTripsPermissionCallback = function (behaviourValue) {
        var shouldShowTrips = behaviourValue === 'true' ? true : false;
        setTripsDisplayAllowed(shouldShowTrips);
        var tokens = getTokens();
        if (shouldShowTrips && tokens) {
            fetchMapConsentPermission(policyIds[currentPolicyIndex], MAP_PERMISSION_NAME, tokens.accessToken, tokens.refreshToken, fetchMapPermissionCallback);
        }
    };
    useEffect(function () {
        var tokens = getTokens();
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.MY_ACCOUNT_PAGE).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        if (cmsContent !== undefined) {
            var groups = cmsContent.contentSections[0].formGroups;
            setFormGroups(groups);
        }
        if (formGroups !== undefined) {
            setMyDetailsContent(getSingleFormGroupContent('myDetailsSection', formGroups));
            setMyContactDetailsContent(getSingleFormGroupContent('myContactDetailsSection', formGroups));
            setMyPasswordContent(getSingleFormGroupContent('myPasswordSection', formGroups));
            setCorrespondenceAddressContent(getSingleFormGroupContent('correspondenceAddressSection', formGroups));
            setAddressLookupContent(getSingleFormGroupContent('addressLookupSection', formGroups));
            setCommunicationsContent(getSingleFormGroupContent('communicationPrefsSection', formGroups));
        }
        if (accountInfoDisplay === undefined && accountInfo !== undefined) {
            setAccountInfoDisplay(getDriverDisplayValues(accountInfo));
        }
        if (accountInfoDisplay) {
            reset({
                addressLine1: accountInfoDisplay.correspondenceAddress
                    ? accountInfoDisplay.correspondenceAddress.addressLine1
                    : undefined,
                city: accountInfoDisplay.correspondenceAddress ? accountInfoDisplay.correspondenceAddress.city : undefined,
                postalCode: accountInfoDisplay.correspondenceAddress
                    ? accountInfoDisplay.correspondenceAddress.postalCode
                    : undefined,
                documentationPreference: accountInfoDisplay.documentationPreference,
            });
        }
        if (tokens && policyIds) {
            fetchMapConsentPermission(policyIds[currentPolicyIndex], DISPLAY_TRIPS_NAME, tokens.accessToken, tokens.refreshToken, fetchTripsPermissionCallback);
        }
        if (refresh === true) {
            setRefreshPolicy();
            history.go(0);
        }
    }, [cmsContent, formGroups, accountInfo, accountInfoDisplay, policyIds, currentPolicyIndex]);
    return (React.createElement(Root, null, accountInfoDisplay && (React.createElement(React.Fragment, null, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        myDetailsContent && accountInfoDisplay && (React.createElement(MyDetails, { myDetailsContent: myDetailsContent, titleDisplay: accountInfoDisplay.title, firstNameDisplay: accountInfoDisplay.firstName, lastNameDisplay: accountInfoDisplay.lastName, dateOfBirthDisplay: accountInfoDisplay.dateOfBirth })),
        myContactDetailsContent && (React.createElement(MyContactDetails, { myContactDetailsContent: myContactDetailsContent, emailDisplay: accountInfoDisplay.emailAddress, mobileDisplay: accountInfoDisplay.mobilePhone, homePhoneDisplay: accountInfoDisplay.homePhone, getValues: function (values) { return updateContactDetails(values); } })),
        myPasswordContent && (React.createElement(MyPassword, { myPasswordContent: myPasswordContent, changePassword: function (data) { return updatePassword(data); } })),
        correspondenceAddressContent && addressLookupContent && (React.createElement(Layout, { shadow: true, withSeperator: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(RenewalOvernightParking, { content: correspondenceAddressContent, modalContent: cmsContent.modals, register: register, addressLookupContent: addressLookupContent, addressToParent: function (data) { return setChangedAddress(data); }, noBorder: true, asDropDown: true, resetAddressChange: resetAddressChange, confirmCorrespondenceChange: updateCorrespondenceAddress })))),
        communicationsContent && (React.createElement(MyCommunicationPrefs, { communicationsContent: communicationsContent, control: control, disableConfirm: watchedFields.documentationPreference !== accountInfoDisplay.documentationPreference, confirmCommunicationChange: updateCommunicationPrefs })),
        mapPermission && tripsDisplayAllowed && (React.createElement(MyMapPermissionPref, { permission: mapPermission, onSelect: function (value) {
                var tokens = getTokens();
                if (tokens) {
                    sendMapConsentAgreement(policyIds[currentPolicyIndex], MAP_PERMISSION_NAME, value, tokens.accessToken, tokens.refreshToken);
                }
            } })),
        React.createElement(Dialog, { open: openLoginModal },
            React.createElement(ModalWrapper, null,
                React.createElement(ModalTitle, null, "Login with your new Email or Password"),
                React.createElement(ModalBody, null,
                    React.createElement(FormInput, { name: "email", label: "Email address", onChange: function (value) { return setEmail(value); } }),
                    React.createElement("div", { style: { marginBottom: '10px' } }),
                    React.createElement(FormPassword, { label: "Enter new password", valueToParent: function (value) { return setPassword(value); } }),
                    React.createElement("div", { style: { marginBottom: '10px' } }),
                    loginErrorMessage !== '' && React.createElement(ErrorMessage, { errorMessage: loginErrorMessage })),
                React.createElement(ModalButtons, null,
                    React.createElement(Button, { disabled: email === '' && password === '', type: "button", onClick: function () {
                            handleLogin();
                            TagManagerArgs('Button', window.location.pathname + " - Account settings", 'Login');
                        } }, "Login"))))))))));
};
var mapStateToProps = function (state) { return ({
    accountInfo: state.accountInfo,
    currentPolicyIndex: state.currentPolicyIndex,
    policyIds: state.policyIds,
    refresh: state.refresh,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setAccountInfo: function (content) { return dispatch(actions.setAccountInfo(content)); },
    setRefreshPolicy: function () { return dispatch(actions.setRefreshPolicy(false)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
