var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import CloseIcon from 'assets/close.svg';
import { color } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  background-color: white;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  padding: 40px 120px;\n\n  > * {\n    :first-child {\n      position: absolute;\n      top: 20px;\n      right: 20px;\n      cursor: pointer;\n    }\n  }\n"], ["\n  position: relative;\n  background-color: white;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  padding: 40px 120px;\n\n  > * {\n    :first-child {\n      position: absolute;\n      top: 20px;\n      right: 20px;\n      cursor: pointer;\n    }\n  }\n"])));
var GreetingWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  font-family: 'Nunito Sans';\n  font-size: 25px;\n  font-weight: 800;\n"], ["\n  display: flex;\n  font-family: 'Nunito Sans';\n  font-size: 25px;\n  font-weight: 800;\n"])));
var GreetingTextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #204054;\n  margin-left: auto;\n"], ["\n  color: #204054;\n  margin-left: auto;\n"])));
var NameWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  margin-left: 12px;\n  margin-right: auto;\n"], ["\n  color: ", ";\n  margin-left: 12px;\n  margin-right: auto;\n"])), color.green);
var QuestionTextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #343534;\n  margin: 20px 0;\n  font-size: 20px;\n  font-weight: 400;\n  text-align: center;\n"], ["\n  color: #343534;\n  margin: 20px 0;\n  font-size: 20px;\n  font-weight: 400;\n  text-align: center;\n"])));
var Button = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 320px;\n  background-color: #f8fbfb;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  cursor: pointer;\n  font-weight: 350;\n  margin: 0 auto 10px;\n  padding: 14px 20px;\n"], ["\n  width: 320px;\n  background-color: #f8fbfb;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n  cursor: pointer;\n  font-weight: 350;\n  margin: 0 auto 10px;\n  padding: 14px 20px;\n"])));
var PopupGuide = function (_a) {
    var name = _a.name, content = _a.content, onClose = _a.onClose, navigateTo = _a.navigateTo;
    var morningGreetingText = content.morningGreetingText, afternoonGreetingText = content.afternoonGreetingText, eveningGreetingText = content.eveningGreetingText, nightGreetingText = content.nightGreetingText, questionText = content.questionText, navigationTexts = content.navigationTexts;
    var getGreeting = function () {
        var currentHour = parseInt(moment().format('HH'), 10);
        if (currentHour >= 3 && currentHour < 12) {
            return morningGreetingText;
        }
        else if (currentHour >= 12 && currentHour < 18) {
            return afternoonGreetingText;
        }
        else if (currentHour >= 18 && currentHour < 22) {
            return eveningGreetingText;
        }
        else if ((currentHour >= 0 && currentHour < 3) || (currentHour >= 22 && currentHour < 24)) {
            return nightGreetingText;
        }
        return 'Hello';
    };
    return (React.createElement(Root, null,
        React.createElement(CloseIcon, { onClick: function () { return onClose(); } }),
        React.createElement(GreetingWrapper, null,
            React.createElement(GreetingTextWrapper, null, getGreeting()),
            React.createElement(NameWrapper, null, name)),
        React.createElement(QuestionTextWrapper, null, questionText),
        navigationTexts.map(function (text) { return (React.createElement(Button, { key: text.value, onClick: function () {
                navigateTo(text.value);
                TagManagerArgs('Button', window.location.pathname + " - Popup Guide", text.label);
            } }, text.label)); })));
};
export default PopupGuide;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
