var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { fontWeight, media } from 'styles';
export var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n  letter-spacing: 0.25px;\n\n  ", "\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n  letter-spacing: 0.25px;\n\n  ",
    "\n"])), fontWeight.medium, media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: 25px;\n  "], ["\n    font-size: 25px;\n  "]))));
var Title = function (_a) {
    var children = _a.children;
    return React.createElement(Root, null, children);
};
export default Title;
var templateObject_1, templateObject_2;
