var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { fontWeight, media } from 'styles';
import SectionButtonWrapper from 'components/molecules/SectionButtonWrapper';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import MilesBox from './MilesBox';
import ProgressBar from './ProgressBar';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: center;\n    display: flex;\n  "], ["\n    align-items: center;\n    display: flex;\n  "]))));
var Column = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n\n    :first-child {\n      margin-right: 24px;\n    }\n  "], ["\n    flex: 1;\n\n    :first-child {\n      margin-right: 24px;\n    }\n  "]))), media.large(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    :first-child {\n      margin-right: 40px;\n    }\n  "], ["\n    :first-child {\n      margin-right: 40px;\n    }\n  "]))), media.extraLarge(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    :first-child {\n      margin-right: 80px;\n    }\n  "], ["\n    :first-child {\n      margin-right: 80px;\n    }\n  "]))));
var CarPlateWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 250px;\n  background: #f2f2f2;\n  border-radius: 30px;\n  font-size: 35px;\n  font-weight: ", ";\n  margin: 15px auto;\n  padding: 4px 30px;\n  text-align: center;\n"], ["\n  width: 250px;\n  background: #f2f2f2;\n  border-radius: 30px;\n  font-size: 35px;\n  font-weight: ", ";\n  margin: 15px auto;\n  padding: 4px 30px;\n  text-align: center;\n"])), fontWeight.bold);
var MilesWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 20px;\n  margin-bottom: 40px;\n\n  > * {\n    flex: 1;\n\n    :first-child {\n      margin-right: 20px;\n    }\n  }\n\n  ", "\n"], ["\n  display: flex;\n  margin-top: 20px;\n  margin-bottom: 40px;\n\n  > * {\n    flex: 1;\n\n    :first-child {\n      margin-right: 20px;\n    }\n  }\n\n  ",
    "\n"])), media.medium(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-top: 0;\n  "], ["\n    margin-top: 0;\n  "]))));
var OverviewSection = function (_a) {
    var content = _a.content, carplate = _a.carplate, remainingMiles = _a.remainingMiles, consumedMiles = _a.consumedMiles, topupMilesButton = _a.topupMilesButton;
    var imageUrl = content.imageUrl, remainingMilesContent = content.remainingMilesContent, consumedMilesContent = content.consumedMilesContent, progressBarLabel = content.progressBarLabel;
    var totalMiles = remainingMiles < 0 ? consumedMiles : consumedMiles + remainingMiles;
    var counts = [];
    for (var index = 0; index <= totalMiles / 1000 + 1; index++) {
        counts.push(index);
    }
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(Root, null,
                    React.createElement(Column, null,
                        React.createElement("img", { src: imageUrl, alt: "" }),
                        React.createElement(CarPlateWrapper, null, carplate)),
                    React.createElement(Column, null,
                        React.createElement(MilesWrapper, null,
                            React.createElement(MilesBox, { content: remainingMilesContent, miles: remainingMiles }),
                            React.createElement(MilesBox, { content: consumedMilesContent, miles: consumedMiles })),
                        React.createElement(ProgressBar, { remainingMiles: remainingMiles < 0 ? 0 : remainingMiles, counts: counts, label: progressBarLabel }),
                        topupMilesButton && React.createElement(SectionButtonWrapper, null, topupMilesButton)))))));
};
export default OverviewSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
