var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { fontSize, fontWeight } from 'styles';
import JourneyDetailView from './JourneyDetailView';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 15px;\n"], ["\n  margin: 0 15px;\n"])));
var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  border-bottom: ", ";\n  cursor: pointer;\n  justify-content: space-between;\n  padding: 16px;\n\n  :first-child {\n    border-top: 1px solid rgba(137, 137, 137, 0.5);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  border-bottom: ", ";\n  cursor: pointer;\n  justify-content: space-between;\n  padding: 16px;\n\n  :first-child {\n    border-top: 1px solid rgba(137, 137, 137, 0.5);\n  }\n"])), function (props) { return (props.expanded ? 'none' : '1px solid rgba(137, 137, 137, 0.5)'); });
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n\n  span {\n    font-size: ", ";\n    opacity: 0.5;\n  }\n"], ["\n  font-weight: ", ";\n\n  span {\n    font-size: ", ";\n    opacity: 0.5;\n  }\n"])), fontWeight.medium, fontSize.fs12);
var LabelInfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  opacity: 0.5;\n"], ["\n  font-size: ", ";\n  opacity: 0.5;\n"])), fontSize.fs12);
var ValueWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var Rows = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n\n  > * {\n    :first-child {\n      :first-child {\n        border-top: none;\n      }\n    }\n  }\n"], ["\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n\n  > * {\n    :first-child {\n      :first-child {\n        border-top: none;\n      }\n    }\n  }\n"])));
var TableMobile = function (_a) {
    var headers = _a.headers, journeys = _a.journeys, latestJourney = _a.latestJourney, onShowMap = _a.onShowMap, showMapButtonText = _a.showMapButtonText, tab = _a.tab;
    var _b = useState(''), expandedId = _b[0], setExpandedId = _b[1];
    var onRowClick = function (id) {
        if (id === expandedId) {
            setExpandedId('');
        }
        else {
            setExpandedId(id);
        }
    };
    var renderLatestJourney = function (headers, journey, showMapButtonText, onShowMap) {
        var originName = journey.originName, destinationName = journey.destinationName;
        return (React.createElement(React.Fragment, null,
            headers.map(function (header) { return (React.createElement(Row, { key: header.value },
                React.createElement(LabelWrapper, null,
                    header.label,
                    header.info && React.createElement(LabelInfoWrapper, null,
                        "\u00A0",
                        header.info)),
                React.createElement(ValueWrapper, null, journey[header.value]))); }),
            originName && destinationName && (React.createElement(JourneyDetailView, { buttonText: showMapButtonText, start: originName, destination: destinationName, onShowMap: function () { return onShowMap(journey.id); } }))));
    };
    var renderJourney = function (headers, journey, showMapButtonText, onShowMap, onRowClick, expanded) {
        var originName = journey.originName, destinationName = journey.destinationName;
        return (React.createElement(React.Fragment, null,
            React.createElement(Row, { key: journey.date, expanded: expanded, onClick: function () { return onRowClick(journey.id); } },
                React.createElement(LabelWrapper, null, journey.date),
                expanded ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null)),
            expanded && (React.createElement(Rows, null,
                headers.map(function (header) { return (React.createElement(Row, { key: header.value },
                    React.createElement(LabelWrapper, null,
                        header.label,
                        header.info && React.createElement(LabelInfoWrapper, null,
                            "\u00A0",
                            header.info)),
                    React.createElement(ValueWrapper, null, journey[header.value]))); }),
                originName && destinationName && (React.createElement(JourneyDetailView, { buttonText: showMapButtonText, start: originName, destination: destinationName, onShowMap: function () { return onShowMap(journey.id); } }))))));
    };
    return (React.createElement(Root, null,
        tab === 'latest' && latestJourney && renderLatestJourney(headers, latestJourney, showMapButtonText, onShowMap),
        tab === 'previous' &&
            journeys &&
            journeys.map(function (journey) {
                return renderJourney(headers.filter(function (header) { return header.value !== 'date'; }), journey, showMapButtonText, onShowMap, onRowClick, expandedId === journey.id);
            })));
};
export default TableMobile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
