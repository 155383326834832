var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { filter, find } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { TotalPriceBanner, PaymentSection, MileageSection, NCDSection, AddonsSection, InformationUsageSection, } from 'components/organisms/QuoteSummary';
import ExcessSection from 'components/organisms/ExcessSection';
import ButtonGroup from 'components/organisms/QuoteSummary/ButtonGroup';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import { content as excessContent } from 'components/organisms/ExcessSection/ExcessSection.story';
import { getPageContent, mtaTokenRefresh, updateCoverages } from 'services';
import { ContentPageURL, SessionStorageKey } from 'types';
import { getPaymentSectionContent, getMileageSectionContent, getNCDSectionContent, getAddonsSectionContent, getBreakdownCoverSectionContent, getInformationUsageSectionContent, } from './contentMapper';
import PNCDAppendix from 'components/organisms/PNCDAppendix/PNCDAppendix';
var QuoteSummaryPage = function (_a) {
    var quoteDetails = _a.quoteDetails, renewalPolicy = _a.renewalPolicy, setRecalculatedQuote = _a.setRecalculatedQuote, renewalState = _a.renewalState, setRenewalState = _a.setRenewalState;
    var history = useHistory();
    var ncdYears = renewalPolicy.ncdYears;
    // Config for FREE NCD OFFER
    var policyEffectiveDate = quoteDetails.baseData.periodStartDate;
    var freeNCD = moment(policyEffectiveDate).isBetween(moment('04-08-2021').format('DD-MM-YYYY'), moment('06-11-2021').format('DD-MM-YYYY'));
    var ncdCost = quoteDetails.vehicles[0].ncdCosts.amount;
    var _b = useState(false), openPncd = _b[0], setOpenPncd = _b[1];
    // CMS Content
    var _c = useState(undefined), content = _c[0], setContent = _c[1];
    var _d = useState(''), priceBannerAnnual = _d[0], setPriceBannerAnnual = _d[1];
    var _e = useState(''), priceBannerMonthly = _e[0], setPriceBannerMonthly = _e[1];
    // The billing id (bc:5 = Annual / bc:7 = Monthly)
    var _f = useState(undefined), billingID = _f[0], setBillingID = _f[1];
    // All annual and monthly plans grouped by mileage (6000, 8000, 10000)
    var _g = useState(undefined), mileagePlans = _g[0], setMileagePlans = _g[1];
    // annual/monthly plans based on the chosen mileage - For displaying the correct cost and showing both
    // prices in the Annual / Monthly selector
    var _h = useState(undefined), selectedPlansByMileage = _h[0], setSelectedPlansByMileage = _h[1];
    // The actual annual or yearly plan the user has chosen (ie - 6000 miles, annual payment / 10000 miles, monthly payment etc)
    var _j = useState(undefined), selectedPlan = _j[0], setSelectedPlan = _j[1];
    // Specific price to use in the your cover section
    var _k = useState(undefined), excessesForYourCover = _k[0], setExcessesForYourCover = _k[1];
    // For use in the NCD selector
    var _l = useState(undefined), ncdProtection = _l[0], setNcdProtection = _l[1];
    var _m = useState(undefined), addonContent = _m[0], setAddonContent = _m[1];
    var _o = useState(undefined), roadRescueCosts = _o[0], setRoadRescueCosts = _o[1];
    var _p = useState(false), roadRescueSelected = _p[0], setRoadRescueSelected = _p[1];
    var _q = useState(undefined), roadRescueIndex = _q[0], setRoadRescueIndex = _q[1];
    var getExcessCostsForYourCover = function () {
        var displayName = quoteDetails.accountHolder.displayName;
        var _a = quoteDetails.myExcessPayment, glassRepairExcess = _a.glassRepairExcess, glassReplacementExcess = _a.glassReplacementExcess, damageExcesses = _a.damageExcesses;
        var excess = '';
        damageExcesses.map(function (item) {
            if (item.driver === displayName)
                excess = item.compulsoryExcess.toString();
        });
        return setExcessesForYourCover({
            glassRepairExcess: glassRepairExcess,
            glassReplacementExcess: glassReplacementExcess,
            excess: excess,
        });
    };
    var groupPlansByMileage = function () {
        var mileageIds = quoteDetails.quoteData.offeredQuotes.map(function (plan) {
            return {
                miles: plan.branchCode,
                publicID: plan.publicID,
            };
        });
        var plans = quoteDetails.bindData.paymentPlans;
        var groupPlansByMiles = mileageIds.map(function (item) {
            var offers = filter(plans, function (obj) {
                return obj.periodID === item.publicID;
            });
            return {
                id: item.publicID,
                miles: item.miles,
                plans: offers,
            };
        });
        setMileagePlans(groupPlansByMiles);
    };
    var getTopUpMilesCosts = function () {
        var costs = quoteDetails.quoteData.offeredQuotes;
        var milesCosts = costs.map(function (item) {
            return {
                miles: item.branchCode,
                topUp: item.topUpMilesCost_itb.amount,
            };
        });
        return milesCosts;
    };
    var getMileagePlansByMiles = function (miles) {
        var plans = find(mileagePlans, function (obj) {
            return obj.miles === miles;
        });
        setSelectedPlansByMileage(plans);
        // Makes sure the prices / correct billingId match when different miles are selected
        plans === null || plans === void 0 ? void 0 : plans.plans.map(function (item) {
            if (item.billingId === billingID)
                setSelectedPlan(item);
        });
    };
    var onPaymentSelect = function (paymentOption) {
        var plan = find(selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.plans, function (obj) {
            return obj.billingId === paymentOption;
        });
        setSelectedPlan(plan);
        setBillingID(paymentOption);
    };
    var getExcesses = function () {
        return {
            excesses: quoteDetails.myExcessPayment.damageExcesses,
            otherExcesses: [
                {
                    name: 'Windscreen replacement',
                    amount: quoteDetails.myExcessPayment.glassReplacementExcess,
                },
                {
                    name: 'Windscreen repair',
                    amount: quoteDetails.myExcessPayment.glassRepairExcess,
                },
            ],
        };
    };
    var getExcessSelectOptions = function () {
        var options = quoteDetails.vehicles[0].voluntaryExcessOptions;
        return options.map(function (item) {
            return {
                label: item.toString(),
                value: item,
            };
        });
    };
    // Recalculations - Done after selecting an addon. Changing miles or payment type require no recalculation
    var reset = function () {
        setMileagePlans(undefined);
        setSelectedPlansByMileage(undefined);
        setSelectedPlan(undefined);
        setNcdProtection(undefined);
        setExcessesForYourCover(undefined);
        setAddonContent(undefined);
        setRoadRescueCosts(undefined);
        setRoadRescueSelected(false);
        setRoadRescueIndex(undefined);
    };
    var handleRecalculation = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, updateCoverages(token, policyNumber, data)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        setRecalculatedQuote(quote.data);
                        reset();
                    }
                    if (quote.status === 500) {
                        console.log('Server error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        handleRecalculation(data);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getOfferingForCalculation = function (id) {
        return find(quoteDetails.quoteData.offeredQuotes, function (obj) {
            return obj.publicID === id;
        });
    };
    var onVoluntaryExcess = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var offerID, offering, data;
        return __generator(this, function (_a) {
            offerID = selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id;
            offering = getOfferingForCalculation(offerID);
            data = {
                sessionUUID: quoteDetails.sessionUUID,
                quoteID: quoteDetails.quoteID,
                quote: offering,
                coverages: quoteDetails.includedAddOns,
                voluntaryExcess: value,
                ncdProtection: quoteDetails.vehicles[0].ncdProtection,
                periodStartDate: quoteDetails.baseData.periodStartDate,
                periodEndDate: quoteDetails.baseData.periodEndDate,
            };
            handleRecalculation(data);
            return [2 /*return*/];
        });
    }); };
    var onNcdProtection = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var ncdIsSelected, offerID, offering, data;
        return __generator(this, function (_a) {
            ncdIsSelected = undefined;
            if (value === 'yes')
                ncdIsSelected = true;
            if (value === 'no')
                ncdIsSelected = false;
            offerID = selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id;
            offering = getOfferingForCalculation(offerID);
            data = {
                sessionUUID: quoteDetails.sessionUUID,
                quoteID: quoteDetails.quoteID,
                quote: offering,
                coverages: quoteDetails.includedAddOns,
                voluntaryExcess: quoteDetails.myExcessPayment.damageExcesses[0].voluntaryExcess,
                ncdProtection: ncdIsSelected,
                periodStartDate: quoteDetails.baseData.periodStartDate,
                periodEndDate: quoteDetails.baseData.periodEndDate,
            };
            handleRecalculation(data);
            return [2 /*return*/];
        });
    }); };
    var onAddonSelectOrRemove = function (value, selected) { return __awaiter(void 0, void 0, void 0, function () {
        var offerID, offering, amendedAddons, data;
        return __generator(this, function (_a) {
            offerID = selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id;
            offering = getOfferingForCalculation(offerID);
            amendedAddons = quoteDetails.includedAddOns.map(function (item) {
                if (value.includes(item.name)) {
                    return __assign(__assign({}, item), { selected: selected });
                }
                return item;
            });
            data = {
                sessionUUID: quoteDetails.sessionUUID,
                quoteID: quoteDetails.quoteID,
                quote: offering,
                coverages: amendedAddons,
                voluntaryExcess: quoteDetails.myExcessPayment.damageExcesses[0].voluntaryExcess,
                ncdProtection: quoteDetails.vehicles[0].ncdProtection,
                periodStartDate: quoteDetails.baseData.periodStartDate,
                periodEndDate: quoteDetails.baseData.periodEndDate,
            };
            handleRecalculation(data);
            return [2 /*return*/];
        });
    }); };
    var onBreakDownSelection = function (index) {
        var offerID = selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id;
        var offering = getOfferingForCalculation(offerID);
        var addOns = quoteDetails.includedAddOns;
        var level = ['Level1', 'Level2', 'Level3'];
        var selectedLevel = level[index];
        var selectedCover = addOns.map(function (item) {
            if (item.name === 'Road Rescue') {
                var roadRescue = item;
                var terms = roadRescue.terms[0];
                terms.chosenTermValue = selectedLevel;
                terms.chosenTerm = selectedLevel;
                roadRescue.selected = true;
                roadRescue.terms[0] = terms;
                return roadRescue;
            }
            return item;
        });
        var data = {
            sessionUUID: quoteDetails.sessionUUID,
            quoteID: quoteDetails.quoteID,
            quote: offering,
            coverages: selectedCover,
            voluntaryExcess: quoteDetails.myExcessPayment.damageExcesses[0].voluntaryExcess,
            ncdProtection: quoteDetails.vehicles[0].ncdProtection,
            periodStartDate: quoteDetails.baseData.periodStartDate,
            periodEndDate: quoteDetails.baseData.periodEndDate,
        };
        handleRecalculation(data);
    };
    var removeRoadRescue = function () {
        var offerID = selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id;
        var offering = getOfferingForCalculation(offerID);
        var addOns = quoteDetails.includedAddOns;
        var selectedCover = addOns.map(function (item) {
            if (item.name === 'Road Rescue') {
                var roadRescue = item;
                var terms = roadRescue.terms[0];
                terms.chosenTermValue = null;
                roadRescue.selected = false;
                return roadRescue;
            }
            return item;
        });
        var data = {
            sessionUUID: quoteDetails.sessionUUID,
            quoteID: quoteDetails.quoteID,
            quote: offering,
            coverages: selectedCover,
            voluntaryExcess: quoteDetails.myExcessPayment.damageExcesses[0].voluntaryExcess,
            ncdProtection: quoteDetails.vehicles[0].ncdProtection,
            periodStartDate: quoteDetails.baseData.periodStartDate,
            periodEndDate: quoteDetails.baseData.periodEndDate,
        };
        handleRecalculation(data);
    };
    var getTotalPriceBannerText = function () {
        if (billingID === 'bc:5')
            return priceBannerAnnual;
        if (billingID === 'bc:7')
            return priceBannerMonthly;
        return '';
    };
    var getTotalPriceBannerPrice = function () {
        if (billingID === 'bc:5')
            return selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.total.amount.toFixed(2);
        if (billingID === 'bc:7')
            return selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.installment.amount.toFixed(2);
        return 0;
    };
    useEffect(function () {
        if (content === undefined) {
            getPageContent(ContentPageURL.QUOTESUMMARY_PAGE).then(function (result) {
                var status = result.status, payload = result.payload;
                if (status === 200 && payload) {
                    payload.contentSections.map(function (item) {
                        if (!item.sectionId)
                            setContent(item);
                        if (item.sectionId === 'priceBannerAnnual')
                            setPriceBannerAnnual(item.body);
                        if (item.sectionId === 'priceBannerMonthly')
                            setPriceBannerMonthly(item.body);
                    });
                }
            });
        }
        if (quoteDetails !== undefined) {
            // group the plans by mileage
            if (mileagePlans === undefined) {
                groupPlansByMileage();
            }
            // set the initial plans
            if (selectedPlansByMileage === undefined && mileagePlans !== undefined) {
                var initialPlanBillingID = quoteDetails.bindData.selectedPaymentPlan.billingId;
                var initialPlanPeriodID_1 = quoteDetails.bindData.selectedPaymentPlan.periodID;
                var plans = find(mileagePlans, function (obj) {
                    return obj.id === initialPlanPeriodID_1;
                });
                setSelectedPlansByMileage(plans);
                // Sets the initial billingID.
                // If the user has selected a different payment option, then triggered a recalulation this is ignored
                if (billingID === undefined)
                    setBillingID(initialPlanBillingID);
            }
            // Sets the initial payment plan for the policy
            if (selectedPlansByMileage && billingID !== undefined && selectedPlan === undefined) {
                onPaymentSelect(billingID);
            }
            // Set to local state the status of the NCD protection for display of the FE
            if (ncdProtection === undefined) {
                setNcdProtection({
                    protectionSelected: quoteDetails.vehicles[0].ncdProtection ? 'yes' : 'no',
                    ncdYears: ncdYears,
                });
            }
            if (excessesForYourCover === undefined) {
                getExcessCostsForYourCover();
            }
            if (content !== undefined && addonContent === undefined) {
                var coverContent = getAddonsSectionContent(content);
                var addons = coverContent === null || coverContent === void 0 ? void 0 : coverContent.addons;
                var includedAddOns_1 = quoteDetails.includedAddOns;
                // Adds cost information for display on the breakdown options
                var addonsWithCostAndSelected_1 = [];
                addons === null || addons === void 0 ? void 0 : addons.map(function (item) {
                    if (item.title.includes('Key') ||
                        item.title.includes('Enhanced Courtesy') ||
                        item.title.includes('Legal Expenses')) {
                        includedAddOns_1.map(function (offer) {
                            if (item.title.includes(offer.name)) {
                                var withDetails = __assign(__assign({}, item), { selected: offer.selected, amount: offer.amount.amount.toFixed(2) });
                                addonsWithCostAndSelected_1 = __spreadArrays(addonsWithCostAndSelected_1, [withDetails]);
                            }
                        });
                    }
                });
                coverContent.addons = addonsWithCostAndSelected_1;
                setAddonContent(coverContent);
            }
            // Get the costs / see if a breakdown plan is chosen
            if (roadRescueIndex == undefined) {
                var includedAddOns = quoteDetails.includedAddOns;
                var roadRescue = find(includedAddOns, function (obj) {
                    return obj.codeIdentifier === 'PMRoadRescueCov';
                });
                if (roadRescue) {
                    var costs = roadRescue.terms[0].options.sort(function (a, b) { return a.amount.amount - b.amount.amount; });
                    setRoadRescueCosts(costs);
                }
                if (roadRescue)
                    setRoadRescueSelected(roadRescue.selected);
                if (roadRescue && roadRescue.selected === true) {
                    var levels = ['Level1', 'Level2', 'Level3'];
                    var chosenTerm = roadRescue.terms[0].chosenTerm;
                    var index = levels.indexOf(chosenTerm);
                    setRoadRescueIndex(index);
                }
                else
                    setRoadRescueIndex(undefined);
            }
        }
    }, [
        mileagePlans,
        billingID,
        selectedPlansByMileage,
        ncdProtection,
        excessesForYourCover,
        quoteDetails,
        content,
        roadRescueIndex,
    ]);
    return (React.createElement(React.Fragment, null, content && (React.createElement(React.Fragment, null,
        React.createElement(RenewalJourneyProgress, { step: 3 }),
        selectedPlan && billingID && (React.createElement(TotalPriceBanner, { title: getTotalPriceBannerText(), price: getTotalPriceBannerPrice() })),
        billingID && selectedPlansByMileage && (React.createElement(PaymentSection, { content: getPaymentSectionContent(content), onPaymentSelected: function (paymentOption) { return onPaymentSelect(paymentOption); }, billingID: billingID, selectedPlans: selectedPlansByMileage })),
        selectedPlansByMileage && (React.createElement(MileageSection, { content: getMileageSectionContent(content), onMileageSelected: function (miles) { return getMileagePlansByMiles(miles); }, planMiles: selectedPlansByMileage.miles, topUpMilesCost: getTopUpMilesCosts() })),
        quoteDetails && (React.createElement(ExcessSection, { data: getExcesses(), content: excessContent, selectOptions: getExcessSelectOptions(), onSelectExcess: function (value) { return onVoluntaryExcess(value); }, terms: React.createElement("div", { style: { fontWeight: 300, marginTop: '30px' } },
                React.createElement(ReactMarkdown, { source: content.terms })) })),
        ncdProtection && (React.createElement(NCDSection, { freeNCD: freeNCD, ncdCost: ncdCost, ncdYears: ncdProtection.ncdYears, optionSelected: ncdProtection.protectionSelected, content: getNCDSectionContent(content), onNCDSelected: function (value) {
                setNcdProtection({
                    protectionSelected: value,
                    ncdYears: ncdYears,
                });
                onNcdProtection(value);
            }, setOpenPncd: setOpenPncd })),
        addonContent && (React.createElement(AddonsSection, { addonsContent: addonContent, breakdownContent: getBreakdownCoverSectionContent(content), breakdownCosts: roadRescueCosts, addonSelectOrRemove: function (value, selected) { return onAddonSelectOrRemove(value, selected); }, onBreakDownSelection: function (index) { return onBreakDownSelection(index); }, roadRescueSelected: roadRescueSelected, initialRoadRescueIndex: roadRescueIndex, removeRoadRescue: function () { return removeRoadRescue(); }, 
            // In some circumstances breakdowb is not offered (if vehicle is over 15 years old for example)
            showBreakDownAddon: quoteDetails.includedAddOns.find(function (item) { return item.codeIdentifier === 'PMRoadRescueCov'; }) === undefined
                ? false
                : true, startDate: quoteDetails.baseData.periodStartDate })),
        React.createElement(InformationUsageSection, { content: getInformationUsageSectionContent(content) }),
        React.createElement(ButtonGroup, { printBtnText: content.printQuoteButtonText, saveBtnText: content.saveQuoteButtonText, continueBtnText: content.continueButtonText, onPrint: function () { return console.log('onPrint'); }, onSave: function () {
                history.push('/dashboard');
                location.reload();
            }, onGoBack: function () { return history.goBack(); }, onContinue: function () {
                setRenewalState(__assign(__assign({}, renewalState), { paymentPlan: {
                        id: selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.id,
                        billingID: billingID,
                        total: selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.total.amount,
                        miles: selectedPlansByMileage === null || selectedPlansByMileage === void 0 ? void 0 : selectedPlansByMileage.miles,
                        selectedPlan: selectedPlan,
                    } }));
                history.push('/renewal-payment-summary');
            } }),
        React.createElement(PNCDAppendix, { ncdYears: ncdYears, open: openPncd, setOpen: setOpenPncd, startDate: quoteDetails.baseData.periodStartDate })))));
};
var mapStateToProps = function (state) { return ({
    quoteDetails: state.renewalAmendedQuote,
    renewalPolicy: state.renewalPolicy,
    renewalState: state.renewalState,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRecalculatedQuote: function (quote) { return dispatch(actions.setAmendedRenewalQuote(quote)); },
    setRenewalState: function (formState) { return dispatch(actions.setAmendRenewalState(formState)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(QuoteSummaryPage);
