var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 30px;\n  border: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  margin-bottom: 30px;\n  border: 1px solid rgba(137, 137, 137, 0.2);\n"])));
var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 16px 16px 16px 52px;\n\n  :last-child {\n    border-bottom: none;\n  }\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 16px 16px 16px 52px;\n\n  :last-child {\n    border-bottom: none;\n  }\n"])), function (props) { return (props.bold ? fontWeight.medium : fontWeight.light); });
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var ValueWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var MonthlyPayment = function (_a) {
    var monthlyPaymentDetailsTitle = _a.monthlyPaymentDetailsTitle, monthlyPaymentDetails = _a.monthlyPaymentDetails, annualPrice = _a.annualPrice, creditAmount = _a.creditAmount, fixedRate = _a.fixedRate, fee = _a.fee, monthlyPayment = _a.monthlyPayment, totalPayableAmount = _a.totalPayableAmount, apr = _a.apr;
    var getValueForLabel = function (label) {
        switch (label) {
            case 'annual':
                return annualPrice;
            case 'credit':
                return creditAmount;
            case 'fixedRate':
                return fixedRate;
            case 'fee':
                return fee;
            case 'monthlyPayment':
                return monthlyPayment;
            case 'totalPayableAmount':
                return totalPayableAmount;
            case 'apr':
                return apr;
            default:
                return '';
        }
    };
    return (React.createElement(Root, null,
        React.createElement(Row, { bold: true }, monthlyPaymentDetailsTitle),
        monthlyPaymentDetails.map(function (detail) { return (React.createElement(Row, { key: detail.value, bold: false },
            React.createElement(LabelWrapper, null, detail.label),
            React.createElement(ValueWrapper, null, getValueForLabel(detail.value)))); })));
};
export default MonthlyPayment;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
