import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderSection from 'components/organisms/HeaderSection';
import { actions } from 'reduxStore';
import { getPolicies, getPolicy, client } from 'services';
import { SessionStorageKey, CmsPlaceholder } from 'types';
var ShareComponent = function (_a) {
    var policyIds = _a.policyIds, policySummaries = _a.policySummaries, currentPolicyIndex = _a.currentPolicyIndex, currentPolicy = _a.currentPolicy, setPolicyIds = _a.setPolicyIds, setCurrentPolicyIndex = _a.setCurrentPolicyIndex, setCurrentPolicy = _a.setCurrentPolicy, setRenewalPolicy = _a.setRenewalPolicy, headerContent = _a.headerContent, setMta = _a.setMta, setQuote = _a.setQuote, setMtaEffectiveDate = _a.setMtaEffectiveDate, setMultiMtaResponse = _a.setMultiMtaResponse, removeMultiDrivers = _a.removeMultiDrivers, setDriverFormDetails = _a.setDriverFormDetails, setCurrentlyCovered = _a.setCurrentlyCovered, setAccountNumber = _a.setAccountNumber, setAlternateRenewalPolicy = _a.setAlternateRenewalPolicy, setPolicySummaries = _a.setPolicySummaries, setMtaFormDetails = _a.setMtaFormDetails, setAutorenewChoice = _a.setAutorenewChoice;
    var attemptsFetchPolicy = 0;
    var history = useHistory();
    var fetchPolicy = function (policyId, token, tokenForRefresh) {
        getPolicy(policyId.replace('/', '%252F'), token).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200 && payload) {
                var currentPolicy_1 = payload.currentPeriod;
                var renewalPolicy = payload.renewedPeriod;
                var alternateRenewalPolicy = payload.alternateRenewedPeriod;
                setCurrentPolicy(currentPolicy_1);
                setRenewalPolicy(renewalPolicy);
                setAlternateRenewalPolicy(alternateRenewalPolicy);
                setAutorenewChoice(payload.autoRenew);
            }
            else if (status === 401) {
                client.renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                    return fetchPolicy(policyId, newToken, newRefreshToken);
                });
            }
            else {
                console.log("Error fetching policy for " + policyId + " | status code " + status);
            }
        });
    };
    var onPolicySelect = function (policyId, index) {
        var token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
        var refreshToken = sessionStorage.getItem(SessionStorageKey.REFRESH_TOKEN);
        if (token && refreshToken) {
            fetchPolicy(policyId, token, refreshToken);
            setCurrentPolicyIndex(index);
            sessionStorage.setItem('policyIndex', index.toString());
        }
        else {
            history.push('/login');
        }
    };
    var fetchPolicies = function (accessToken, tokenForRefresh) {
        attemptsFetchPolicy++;
        if (attemptsFetchPolicy < 3) {
            getPolicies(accessToken).then(function (result) {
                var status = result.status, policies = result.payload;
                if (status === 200) {
                    if (policies) {
                        var ids = policies.map(function (policy) {
                            setAccountNumber(policy.accountNumber);
                            policy.periods.forEach(function (period) {
                                if (period.inForce || period.status === 'Scheduled' || period.status === 'In Force') {
                                    setCurrentlyCovered(true);
                                }
                            });
                            return policy.periods[0].policyId;
                        });
                        setPolicyIds(ids);
                        var policyIndex = sessionStorage.getItem('policyIndex')
                            ? parseInt(sessionStorage.getItem('policyIndex'))
                            : 0;
                        setCurrentPolicyIndex(policyIndex);
                        fetchPolicy(ids[policyIndex], accessToken, tokenForRefresh);
                        setPolicySummaries(policies);
                    }
                    else {
                        console.log('no policies available');
                    }
                }
                else if (status === 401) {
                    console.log('Unauthorized: token expired. Refreshing token');
                    client.renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                        return fetchPolicies(newToken, newRefreshToken);
                    });
                }
                else {
                    console.log('Error fetching policies | status code', status);
                }
            });
        }
    };
    // Replace CMS placeholder text with policy information
    var replacePlacholderText = function (value) {
        var _a;
        var _b;
        var placeholders = (_a = {},
            _a[CmsPlaceholder.POLICY_HOLDER_NAME] = (_b = currentPolicy.policyHolder.displayName) === null || _b === void 0 ? void 0 : _b.replace(currentPolicy.policyHolder.lastName, ''),
            _a[CmsPlaceholder.VEHICLE_MAKE_MODEL] = currentPolicy.vehicleInfo.displayName,
            _a);
        var regex = RegExp(CmsPlaceholder.POLICY_HOLDER_NAME + "|" + CmsPlaceholder.VEHICLE_MAKE_MODEL, 'gi');
        var changedValue = '';
        changedValue = value.replace(regex, function (matched) {
            return placeholders[matched];
        });
        return changedValue;
    };
    useEffect(function () {
        var accessToken = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
        var refreshToken = sessionStorage.getItem(SessionStorageKey.REFRESH_TOKEN);
        if (accessToken && refreshToken) {
            if (!policyIds || !policySummaries) {
                fetchPolicies(accessToken, refreshToken);
            }
            else {
                if (!currentPolicy) {
                    fetchPolicy(policyIds[currentPolicyIndex], accessToken, refreshToken);
                }
            }
        }
        else {
            history.push('/login');
        }
        if (history.location.pathname === '/dashboard' || history.location.pathname === '/mypolicy') {
            setQuote({});
            setMta({
                addDriver: false,
                newCar: false,
                overnightParking: false,
                removeDriver: false,
            });
            setMtaEffectiveDate(undefined);
            setMultiMtaResponse(undefined);
            setDriverFormDetails(undefined);
            removeMultiDrivers([]);
            setMtaFormDetails({
                amend: false,
                index: 0,
                drivers: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyIds, currentPolicy, policySummaries]);
    return (React.createElement(HeaderSection, { title: headerContent && headerContent.title && currentPolicy ? replacePlacholderText(headerContent.title) : '', body: headerContent && headerContent.body && currentPolicy ? replacePlacholderText(headerContent.body) : '', options: policyIds, onSelect: (headerContent === null || headerContent === void 0 ? void 0 : headerContent.hidePolicySelect) ? undefined : onPolicySelect, optionsIndex: currentPolicyIndex }));
};
var mapStateToProps = function (state) { return ({
    policyIds: state.policyIds,
    currentPolicyIndex: state.currentPolicyIndex,
    currentPolicy: state.currentPolicy,
    policySummaries: state.policySummaries,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setPolicyIds: function (ids) { return dispatch(actions.setPolicyIds(ids)); },
    setCurrentPolicyIndex: function (index) { return dispatch(actions.setCurrentPolicyIndex(index)); },
    setCurrentPolicy: function (policy) { return dispatch(actions.setCurrentPolicy(policy)); },
    setRenewalPolicy: function (policy) { return dispatch(actions.setRenewalPolicy(policy)); },
    setAlternateRenewalPolicy: function (policy) { return dispatch(actions.setAlternateRenewalPolicy(policy)); },
    setMta: function (mtas) { return dispatch(actions.setMta(mtas)); },
    setMtaFormDetails: function (details) { return dispatch(actions.setMtaFormDetails(details)); },
    setQuote: function (quote) { return dispatch(actions.setQuote(quote)); },
    setMtaEffectiveDate: function (date) { return dispatch(actions.setMtaEffectiveDate(date)); },
    setMultiMtaResponse: function (response) { return dispatch(actions.setMultiMtaResponse(response)); },
    removeMultiDrivers: function (drivers) { return dispatch(actions.removeMultiDrivers(drivers)); },
    setDriverFormDetails: function (details) { return dispatch(actions.setDriverFormDetails(details)); },
    setCurrentlyCovered: function (covered) { return dispatch(actions.setCurrentlyCovered(covered)); },
    setAccountNumber: function (accountNumber) { return dispatch(actions.setAccountNumber(accountNumber)); },
    setPolicySummaries: function (policySummaries) { return dispatch(actions.setPolicySummaries(policySummaries)); },
    setAutorenewChoice: function (autoRenew) { return dispatch(actions.setAutorenewChoice(autoRenew)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ShareComponent);
