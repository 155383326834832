import moment from 'moment';
// Converts the values from the form object to the correct GW formats
export var getVehicleValues = function (data, registration) {
    var keys = Object.keys(data);
    var obj = Object.fromEntries(keys.map(function (key) {
        // For radios only. There are no dropdown values with "No" in this MTA
        if (data[key] === 'Yes')
            return [key, true];
        if (data[key] === 'No')
            return [key, false];
        if (data[key] === 'annualMileage')
            return [key, parseInt(data[key])];
        if (key === 'marketValue') {
            return [
                key,
                {
                    amount: parseInt(data[key]),
                },
            ];
        }
        if (key === 'modification') {
            if (data[key] === 'No')
                return [key, null];
            else
                return [key, data[key]];
        }
        return [key, data[key]];
    }));
    if (obj.purchasedDate) {
        obj.purchasedMonth = moment(obj.purchasedDate).format('MMMM');
        obj.purchasedYear = parseInt(moment(obj.purchasedDate).format('YYYY'));
    }
    if (obj.hasBeenModified === false) {
        obj.modification = null;
    }
    // Standard keys needed for the mta to work
    obj.registrationNumber = registration;
    obj.voluntaryExcessOptions = [0, 0];
    obj.voluntaryExcess = 0;
    obj.tempID = '1';
    obj.costNew = {};
    return obj;
};
