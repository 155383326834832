import React from 'react';
import { FormDatePicker, FormInput, getSingleFieldContent, Group } from 'components/organisms/Form';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
export var MyDetails = function (_a) {
    var myDetailsContent = _a.myDetailsContent, titleDisplay = _a.titleDisplay, firstNameDisplay = _a.firstNameDisplay, lastNameDisplay = _a.lastNameDisplay, dateOfBirthDisplay = _a.dateOfBirthDisplay;
    var title = getSingleFieldContent('title', myDetailsContent.fields);
    var firstName = getSingleFieldContent('firstName', myDetailsContent.fields);
    var lastName = getSingleFieldContent('lastName', myDetailsContent.fields);
    var dateOfBirth = getSingleFieldContent('dateOfBirth', myDetailsContent.fields);
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Group, { title: myDetailsContent.title, noBorder: true },
                React.createElement(FormInput, { name: title.value, label: title.label, value: titleDisplay, readOnly: true }),
                React.createElement(FormInput, { name: firstName.value, label: firstName.label, value: firstNameDisplay, readOnly: true }),
                React.createElement(FormInput, { name: lastName.value, label: lastName.label, value: lastNameDisplay, readOnly: true }),
                React.createElement(FormDatePicker, { name: dateOfBirth.value, label: dateOfBirth.label, value: dateOfBirthDisplay, disabled: true })))));
};
