import { SET_STATE_INIT, SET_POLICY_IDS, SET_CURRENT_POLICY_INDEX, SET_CURRENT_POLICY, SET_RENEWAL_POLICY, SET_ALTERNATE_RENEWAL_POLICY, SET_PAGE_HEADER_CONTENT, SET_QUOTE, SET_MTA_EFFECTIVE_DATE, SET_MTA, SET_MULTI_MTA_RESPONSE, SET_MULTI_DRIVERS, SET_DRIVER_INDEX, REMOVE_MULTI_DRIVER, SET_DRIVER_FORM_DETAILS, SET_CONVICTION_AND_CLAIMS_FORM_DETAILS, SET_AMEND_RENEWAL_DETAILS, SET_AMEND_RENEWAL_STATE, SET_AMENDED_RENEWAL_QUOTE, SET_ACCOUNT_INFO, SET_CURRENTLT_COVERED, SET_ACCOUNT_NUMBER, SET_POLICY_SUMMARIES, SET_MTA_FORM_DETAILS, SET_TOPUP_ID, SET_AUTORENEW_CHOICE, SET_REFRESH_POLICY, } from './action-types';
export var resetStore = function () { return ({
    type: SET_STATE_INIT,
    value: undefined,
}); };
export var setPolicyIds = function (ids) { return ({
    type: SET_POLICY_IDS,
    value: ids,
}); };
export var setTopupId = function (id) { return ({
    type: SET_TOPUP_ID,
    value: id,
}); };
export var setAutorenewChoice = function (autoRenew) {
    return {
        type: SET_AUTORENEW_CHOICE,
        value: autoRenew,
    };
};
export var setCurrentPolicy = function (policy) { return ({
    type: SET_CURRENT_POLICY,
    value: policy,
}); };
export var setRenewalPolicy = function (policy) { return ({
    type: SET_RENEWAL_POLICY,
    value: policy,
}); };
export var setAlternateRenewalPolicy = function (policy) { return ({
    type: SET_ALTERNATE_RENEWAL_POLICY,
    value: policy,
}); };
export var setCurrentPolicyIndex = function (index) { return ({
    type: SET_CURRENT_POLICY_INDEX,
    value: index,
}); };
export var setPageHeaderContent = function (pageHeaderContent) { return ({
    type: SET_PAGE_HEADER_CONTENT,
    value: pageHeaderContent,
}); };
export var setQuote = function (quote) { return ({
    type: SET_QUOTE,
    value: quote,
}); };
export var setMtaEffectiveDate = function (date) { return ({
    type: SET_MTA_EFFECTIVE_DATE,
    value: date,
}); };
export var setMta = function (mta) { return ({
    type: SET_MTA,
    value: mta,
}); };
export var setMtaFormDetails = function (mtaFormDetails) { return ({
    type: SET_MTA_FORM_DETAILS,
    value: mtaFormDetails,
}); };
export var setMultiMtaResponse = function (response) { return ({
    type: SET_MULTI_MTA_RESPONSE,
    value: response,
}); };
export var setMultiDrivers = function (response) { return ({
    type: SET_MULTI_DRIVERS,
    value: response,
}); };
export var removeMultiDrivers = function (response) { return ({
    type: REMOVE_MULTI_DRIVER,
    value: response,
}); };
export var setDriverIndex = function (index) { return ({
    type: SET_DRIVER_INDEX,
    value: index,
}); };
export var setDriverFormDetails = function (index) { return ({
    type: SET_DRIVER_FORM_DETAILS,
    value: index,
}); };
export var setConvictionsAndClaimsFormDetails = function (data) { return ({
    type: SET_CONVICTION_AND_CLAIMS_FORM_DETAILS,
    value: data,
}); };
export var setAmendRenewalDetails = function (data) { return ({
    type: SET_AMEND_RENEWAL_DETAILS,
    value: data,
}); };
export var setAmendRenewalState = function (data) { return ({
    type: SET_AMEND_RENEWAL_STATE,
    value: data,
}); };
export var setAmendedRenewalQuote = function (data) { return ({
    type: SET_AMENDED_RENEWAL_QUOTE,
    value: data,
}); };
export var setAccountInfo = function (data) { return ({
    type: SET_ACCOUNT_INFO,
    value: data,
}); };
export var setCurrentlyCovered = function (covered) { return ({
    type: SET_CURRENTLT_COVERED,
    value: covered,
}); };
export var setAccountNumber = function (accountNumber) { return ({
    type: SET_ACCOUNT_NUMBER,
    value: accountNumber,
}); };
export var setPolicySummaries = function (policySummaries) { return ({
    type: SET_POLICY_SUMMARIES,
    value: policySummaries,
}); };
export var setRefreshPolicy = function (refresh) { return ({
    type: SET_REFRESH_POLICY,
    value: refresh,
}); };
