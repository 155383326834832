var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styles';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 1440px;\n  margin: 0 auto;\n\n  ", "\n\n  ", "\n"], ["\n  max-width: 1440px;\n  margin: 0 auto;\n\n  ",
    "\n\n  ",
    "\n"])), function (props) {
    return props.shadow && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin-bottom: 10px;\n      box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n    "], ["\n      margin-bottom: 10px;\n      box-shadow: 0 1px 2px 1px rgba(209, 209, 209, 0.28);\n    "])));
}, function (props) {
    return props.withSeperator && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-bottom: 0;\n    "], ["\n      margin-bottom: 0;\n    "])));
});
export var PaddingHorizontal = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 15px;\n\n  ", "\n"], ["\n  padding: 0 15px;\n\n  ",
    "\n"])), media.large(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0 135px;\n  "], ["\n    padding: 0 135px;\n  "]))));
export var PaddingVertical = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 20px 0;\n\n  ", "\n"], ["\n  padding: 20px 0;\n\n  ",
    "\n"])), media.large(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding: 30px 0;\n  "], ["\n    padding: 30px 0;\n  "]))));
var GreySeperator = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 10px;\n  background-color: #f9f9f9;\n  position: relative;\n  z-index: -1;\n  max-width: 1440px;\n  margin: 0 auto;\n"], ["\n  height: 10px;\n  background-color: #f9f9f9;\n  position: relative;\n  z-index: -1;\n  max-width: 1440px;\n  margin: 0 auto;\n"])));
var Layout = function (_a) {
    var children = _a.children, _b = _a.shadow, shadow = _b === void 0 ? false : _b, _c = _a.withSeperator, withSeperator = _c === void 0 ? false : _c;
    return (React.createElement(React.Fragment, null,
        React.createElement(Root, { shadow: shadow, withSeperator: withSeperator }, children),
        withSeperator && React.createElement(GreySeperator, null)));
};
export default Layout;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
