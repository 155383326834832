var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { get } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import SectionButtonWrapper from 'components/molecules/SectionButtonWrapper';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { fontWeight } from 'styles';
import { QuoteActionName, QuoteStatus } from './types';
import { getChargeString } from 'utils/general';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: 14px;\n  user-select: none;\n\n  ", " {\n    margin-bottom: 16px;\n  }\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n  font-size: 14px;\n  user-select: none;\n\n  ", " {\n    margin-bottom: 16px;\n  }\n"])), ButtonRoot);
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  padding: 0 24px;\n"], ["\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  padding: 0 24px;\n"])));
var Row = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 14px 0;\n\n  svg {\n    fill: #202020aa;\n  }\n"], ["\n  display: flex;\n  font-weight: ", ";\n  justify-content: space-between;\n  padding: 14px 0;\n\n  svg {\n    fill: #202020aa;\n  }\n"])), fontWeight.light);
var TopBorderRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"])));
var HeaderLabelWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var LabelWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var QuoteDetailsWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: rgba(137, 137, 137, 0.04);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 0 24px 24px 24px;\n"], ["\n  background-color: rgba(137, 137, 137, 0.04);\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  padding: 0 24px 24px 24px;\n"])));
var QuoteDetailRow = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  justify-content: space-between;\n  padding: 12px 0;\n"], ["\n  display: flex;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  justify-content: space-between;\n  padding: 12px 0;\n"])));
var QuoteDetailCell = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var TableMobile = function (_a) {
    var headers = _a.headers, quotes = _a.quotes, resumeButtonText = _a.resumeButtonText, buyButtonText = _a.buyButtonText, amendDetailsButtonText = _a.amendDetailsButtonText, moreInfoText = _a.moreInfoText, onQuoteAction = _a.onQuoteAction;
    var tableHeaders = headers.filter(function (header) { return header.value === 'quoteNumber' || header.value === 'premium' || header.value === 'registrationNumber'; });
    var quoteDetails = headers.filter(function (header) { return header.value !== 'quoteNumber' && header.value !== 'premium' && header.value !== 'registrationNumber'; });
    var _b = useState([]), expandedQuotes = _b[0], setExpandedQuotes = _b[1];
    var onRowClick = function (quoteNumber) {
        if (expandedQuotes.includes(quoteNumber)) {
            setExpandedQuotes(__spreadArrays(expandedQuotes.filter(function (number) { return number !== quoteNumber; })));
        }
        else {
            expandedQuotes.push(quoteNumber);
            setExpandedQuotes(__spreadArrays(expandedQuotes));
        }
    };
    var formatDate = function (date) { return moment(date).format('DD / MM / YYYY'); };
    return (React.createElement(Root, null, quotes.map(function (quote) { return (React.createElement("div", { key: quote.quoteNumber },
        React.createElement(Header, null,
            tableHeaders.map(function (header) { return (React.createElement(Row, { key: header.value },
                React.createElement(HeaderLabelWrapper, null, header.label),
                React.createElement(LabelWrapper, null, header.value === 'premium'
                    ? getChargeString(get(quote, header.value))
                    : get(quote, header.value) || 'Incomplete'))); }),
            React.createElement(TopBorderRow, { onClick: function () { return onRowClick(quote.quoteNumber); } },
                React.createElement(LabelWrapper, null, moreInfoText),
                expandedQuotes.includes(quote.quoteNumber) ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null))),
        expandedQuotes.includes(quote.quoteNumber) && (React.createElement(QuoteDetailsWrapper, null,
            quoteDetails.map(function (detail) { return (React.createElement(QuoteDetailRow, { key: detail.value },
                React.createElement(QuoteDetailCell, null, detail.label),
                React.createElement(QuoteDetailCell, null, detail.value === 'coverStartDate' || detail.value === 'coverEndDate'
                    ? formatDate(quote[detail.value])
                    : get(quote, detail.value) || 'Incomplete'))); }),
            React.createElement(SectionButtonWrapper, null,
                quote.quoteStatus === QuoteStatus.DRAFT && (React.createElement(Button, { buttonStyle: "secondary", fullWidth: true, onClick: function () {
                        onQuoteAction(QuoteActionName.RESUME_QUOTE, quote.postalCode, quote.dateOfBirth, quote.quoteNumber);
                        TagManagerArgs('Button', "" + window.location.pathname, resumeButtonText);
                    } }, resumeButtonText)),
                quote.quoteStatus === QuoteStatus.QUOTED && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { buttonStyle: "secondary", fullWidth: true, onClick: function () {
                            onQuoteAction(QuoteActionName.AMEND_DETAILS, quote.postalCode, quote.dateOfBirth, quote.quoteNumber);
                            TagManagerArgs('Button', "" + window.location.pathname, amendDetailsButtonText);
                        } }, amendDetailsButtonText),
                    React.createElement(Button, { fullWidth: true, onClick: function () {
                            onQuoteAction(QuoteActionName.BUY_QUOTE, quote.postalCode, quote.dateOfBirth, quote.quoteNumber);
                            TagManagerArgs('Button', "" + window.location.pathname, buyButtonText);
                        } }, buyButtonText)))))))); })));
};
export default TableMobile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
