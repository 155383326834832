var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { getPageContent, mtaTokenRefresh, multiMtas, quoteMultiMtas } from 'services';
import Button from 'components/atoms/Button';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import ShareComponent from 'pages/ShareComponent';
import { ContentPageURL, SessionStorageKey } from 'types';
import { getSingleFormGroupContent } from 'components/organisms/Form';
import YourCar from './YourCar';
import YourAddress from './YourAddress';
import YourDrivers from './YourDrivers';
import MtaModal from 'components/molecules/MtaModal';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PageButtons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var MtaOtherChanges = function (_a) {
    var currentPolicy = _a.currentPolicy, mta = _a.mta, multiMtaResponse = _a.multiMtaResponse, setQuoteDetails = _a.setQuoteDetails, effectiveDate = _a.effectiveDate, multiMtaDrivers = _a.multiMtaDrivers, removeMultiDriver = _a.removeMultiDriver, setMta = _a.setMta, setMultiMtaResponse = _a.setMultiMtaResponse, mtaFormDetails = _a.mtaFormDetails, setMtaFormDetails = _a.setMtaFormDetails;
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), currentVehicleData = _d[0], setCurrentVehicleData = _d[1];
    var _e = useState(undefined), currentOvernightData = _e[0], setCurrentOvernightData = _e[1];
    var _f = useState(undefined), currentDriversData = _f[0], setCurrentDriversData = _f[1];
    var _g = useState(undefined), newVehicleData = _g[0], setNewVehicleData = _g[1];
    var _h = useState(undefined), newOvernightData = _h[0], setNewOvernightData = _h[1];
    var _j = useState(undefined), newDriversData = _j[0], setNewDriversData = _j[1];
    // Modal
    var _k = useState(undefined), modalContent = _k[0], setModalContent = _k[1];
    var _l = useState(false), modalOpen = _l[0], setModalOpen = _l[1];
    var history = useHistory();
    useEffect(function () {
        // redirect if no mta has been done
        if (multiMtaResponse === undefined)
            history.push('/mypolicy');
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.OTHER_CHANGES_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent !== undefined)
            setModalContent(cmsContent.modals[0]);
        // Set current info here
        if (currentPolicy !== undefined) {
            setCurrentVehicleData(currentPolicy.vehicleInfo);
            setCurrentOvernightData(currentPolicy.overnightParkingAddress);
            setCurrentDriversData(currentPolicy.drivers);
        }
        // Needed here to update the list correctly
        setNewDriversData(multiMtaDrivers);
        // set new info here
        if (multiMtaResponse && mta) {
            if (mta.newCar === true)
                setNewVehicleData(multiMtaResponse.vehicles[0]);
            if (mta.overnightParking === true)
                setNewOvernightData(multiMtaResponse.overnightParkingAddress);
            if (mta.addDriver && multiMtaDrivers === true) {
                setNewDriversData(multiMtaDrivers);
            }
        }
    }, [cmsContent, currentPolicy, multiMtaResponse, mta, multiMtaDrivers]);
    var onContinue = function () {
        setModalOpen(true);
    };
    var modalContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, jobNumber, data, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = multiMtaResponse.policyNumber.replace('ITB/', '');
                    jobNumber = multiMtaResponse.jobId;
                    data = { jobId: jobNumber, status: multiMtaResponse.status };
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, quoteMultiMtas(policyNumber, jobNumber, data, effectiveDate, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        // set quote data to redux
                        setQuoteDetails(quote.data);
                        // redirect
                        history.push('/your-quote');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        modalContinue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var clearChanges = function () { return history.push('/mypolicy'); };
    // Delete one of the added drivers
    var removeNewDriver = function (driver, index) { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, token, removeDriverFlag, data, otherChanges, removeFromDrivers, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    removeDriverFlag = multiMtaResponse.drivers.map(function (item) {
                        if (item.firstName === driver.firstName && item.lastName === driver.lastName) {
                            var removeDriver = __assign(__assign({}, item), { updateOrRemove: 'remove', newlyAdded: false });
                            return removeDriver;
                        }
                        else
                            return __assign(__assign({}, item), { newlyAdded: false });
                    });
                    data = {
                        status: multiMtaResponse.status,
                        jobId: multiMtaResponse.jobId,
                        drivers: removeDriverFlag,
                    };
                    return [4 /*yield*/, multiMtas(policyNumber, data, effectiveDate, token)];
                case 1:
                    otherChanges = _a.sent();
                    if (otherChanges.status === 200) {
                        // Update the quote response in redux
                        setMultiMtaResponse(otherChanges.data);
                        removeFromDrivers = multiMtaDrivers.filter(function (item) { return item !== multiMtaDrivers[index]; });
                        // sets the flag to false if no more drivers left
                        if (removeFromDrivers.length === 0) {
                            mtaFlag = __assign(__assign({}, mta), { addDriver: false });
                            setMta(mtaFlag);
                        }
                        removeMultiDriver(removeFromDrivers);
                    }
                    if (!(otherChanges.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        removeNewDriver(data, index);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Root, null, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null, formGroups && currentPolicy && (React.createElement(PaddingVertical, null,
                formGroups && currentDriversData && (React.createElement(YourDrivers, { yourDriversGroup: getSingleFormGroupContent('yourDriversGroup', formGroups), currentDriversData: currentDriversData, newDriversData: newDriversData, removeNewDriver: removeNewDriver })),
                formGroups && currentVehicleData && (React.createElement(YourCar, { yourCarGroup: getSingleFormGroupContent('yourCarGroup', formGroups), currentVehicleData: currentVehicleData, newVehicleData: newVehicleData })),
                formGroups && currentOvernightData && (React.createElement(YourAddress, { yourAddressGroup: getSingleFormGroupContent('yourAddressGroup', formGroups), currentOvernightData: currentOvernightData, newOvernightData: newOvernightData })),
                React.createElement(PageButtons, null,
                    React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                            clearChanges();
                            TagManagerArgs('Button', window.location.pathname + " - Mta other changes", 'Cancel');
                        } }, "Cancel"),
                    React.createElement(Button, { disabled: Object.values(mta).every(function (item) { return item === false; }), onClick: function () {
                            onContinue();
                            TagManagerArgs('Button', window.location.pathname + " - Mta other changes", 'Continue');
                        } }, "Continue")))))),
        modalContent && (React.createElement(MtaModal, { modalOpen: modalOpen, setModalOpen: setModalOpen, modalContent: modalContent, modalContinue: modalContinue }))))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    mta: state.mta,
    multiMtaResponse: state.multiMtaResponse,
    effectiveDate: state.mtaEffectiveDate,
    multiMtaDrivers: state.multiMtaDrivers,
    mtaFormDetails: state.mtaFormDetails,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setQuoteDetails: function (quote) { return dispatch(actions.setQuote(quote)); },
    removeMultiDriver: function (driver) { return dispatch(actions.removeMultiDrivers(driver)); },
    setMta: function (mta) { return dispatch(actions.setMta(mta)); },
    setMultiMtaResponse: function (response) { return dispatch(actions.setMultiMtaResponse(response)); },
    setMtaFormDetails: function (data) { return dispatch(actions.setMtaFormDetails(data)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MtaOtherChanges);
var templateObject_1, templateObject_2;
