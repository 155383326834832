var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { fontWeight } from 'styles';
import RadioButton from './RadioButton';
import find from 'lodash/find';
import ReactMarkdown from 'react-markdown';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  line-height: 1.63;\n\n  p {\n    margin-bottom: 10px;\n  }\n"], ["\n  font-weight: ", ";\n  line-height: 1.63;\n\n  p {\n    margin-bottom: 10px;\n  }\n"])), fontWeight.light);
var RadioLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var RadioText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-left: 36px;\n"], ["\n  font-weight: ", ";\n  margin-left: 36px;\n"])), fontWeight.light);
var RadioButtons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  > * {\n    margin-bottom: 10px;\n  }\n"], ["\n  margin: 30px 0;\n\n  > * {\n    margin-bottom: 10px;\n  }\n"])));
var MileageSection = function (_a) {
    var content = _a.content, onMileageSelected = _a.onMileageSelected, planMiles = _a.planMiles, topUpMilesCost = _a.topUpMilesCost;
    var topUpSixThousand = find(topUpMilesCost, function (obj) {
        return obj.miles === '6000';
    });
    var topUpEightThousand = find(topUpMilesCost, function (obj) {
        return obj.miles === '8000';
    });
    var topUpTenThousand = find(topUpMilesCost, function (obj) {
        return obj.miles === '10000';
    });
    var topUpTwentyThousand = find(topUpMilesCost, function (obj) {
        return obj.miles === '20000';
    });
    var title = content.title, paragraph1 = content.paragraph1, paragraph2 = content.paragraph2, options = content.options;
    var _b = useState(planMiles), mileage = _b[0], setMileage = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var replacePlacholderText = function (value) {
        var placeholders = {
            SIX_THOUSAND_TOPUP_COST: topUpSixThousand ? topUpSixThousand.topUp.toFixed(2) : '',
            EIGHT_THOUSAND_TOPUP_COST: topUpEightThousand ? topUpEightThousand.topUp.toFixed(2) : '',
            TEN_THOUSAND_TOPUP_COST: topUpTenThousand ? topUpTenThousand.topUp.toFixed(2) : '',
            TWENTY_THOUSAND_TOPUP_COST: topUpTwentyThousand ? topUpTwentyThousand.topUp.toFixed(2) : '',
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/SIX_THOUSAND_TOPUP_COST|EIGHT_THOUSAND_TOPUP_COST|TEN_THOUSAND_TOPUP_COST|TWENTY_THOUSAND_TOPUP_COST/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    var onSelect = function (value) {
        onMileageSelected(value);
        setMileage(value);
    };
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(SectionHeader, { title: title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(PaddingVertical, null,
                    React.createElement(ReactMarkdown, { source: paragraph1 }),
                    React.createElement(RadioButtons, null, options.map(function (option) {
                        if (topUpMilesCost.length === 1 && option.value === '20000') {
                            return (React.createElement(RadioButton, { key: option.value, selected: mileage === option.value, title: React.createElement(RadioLabel, null, option.label), text: React.createElement(RadioText, null,
                                    React.createElement(ReactMarkdown, { source: replacePlacholderText(option.text) })), onSelect: function () { return onSelect(option.value); } }));
                        }
                        if (topUpMilesCost.length > 1 && option.value !== '20000') {
                            return (React.createElement(RadioButton, { key: option.value, selected: mileage === option.value, title: React.createElement(RadioLabel, null, option.label), text: React.createElement(RadioText, null,
                                    React.createElement(ReactMarkdown, { source: replacePlacholderText(option.text) })), onSelect: function () { return onSelect(option.value); } }));
                        }
                        return null;
                    })),
                    React.createElement(ReactMarkdown, { source: paragraph2 })))))));
};
export default MileageSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
