var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import MtaPageButtons from 'components/molecules/MtaPageButtons';
import { AddressCurrent, AddressLookup, AddressSelected } from 'components/organisms/MtaAddress';
import MtaDateAndTime from 'components/organisms/MtaDateAndTime';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { SessionStorageKey, ContentPageURL, OvernightParkingContentIds, } from 'types';
import { getPageContent, changeOvernightAddress, mtaTokenRefresh, multiMtas } from 'services';
import { getSingleFormGroupContent } from 'components/organisms/Form';
import MtaModal from 'components/molecules/MtaModal';
import ShareComponent from 'pages/ShareComponent';
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var MtaOvernightParkingPage = function (_a) {
    var currentPolicy = _a.currentPolicy, mta = _a.mta, setQuoteDetails = _a.setQuoteDetails, setMta = _a.setMta, setMtaDate = _a.setMtaDate, setMultiMtaResponse = _a.setMultiMtaResponse, mtaEffectiveDate = _a.mtaEffectiveDate, multiMtaResponse = _a.multiMtaResponse;
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), formButtons = _d[0], setFormButtons = _d[1];
    var _e = useState(''), effectiveDate = _e[0], setEffectiveDate = _e[1];
    var _f = useState(undefined), address = _f[0], setAddress = _f[1];
    var _g = useState(undefined), params = _g[0], setParams = _g[1];
    var _h = useState(undefined), modalContent = _h[0], setModalContent = _h[1];
    var _j = useState(false), modalOpen = _j[0], setModalOpen = _j[1];
    // which submit button is pressed
    var _k = useState(''), submitType = _k[0], setSubmitType = _k[1];
    var _l = useForm(), register = _l.register, handleSubmit = _l.handleSubmit, control = _l.control, errors = _l.errors;
    var history = useHistory();
    // Form submission
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var obj, policyNumber, token, changedAddress, overnightAddress, otherChanges, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    obj = {
                        homeOwner: data.homeOwner === 'Yes' ? true : false,
                        dayTimeParking: data.dayTimeParking,
                        nightTimeParking: data.nightTimeParking,
                    };
                    // The user can change Address line 1 only. If this is the case, it is replaced here
                    if (address && data.addressLine1 !== address.addressLine1)
                        setAddress(__assign(__assign({}, address), { addressLine1: data.addressLine1, changed: true }));
                    if (submitType === 'continue') {
                        setParams("&homeOwner=" + obj.homeOwner + "&dayTimeParking=" + obj.dayTimeParking + "&nightTimeParking=" + obj.nightTimeParking);
                        setModalOpen(true);
                    }
                    if (!(submitType === 'otherChanges')) return [3 /*break*/, 3];
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    changedAddress = __assign(__assign({}, address), { changed: true });
                    overnightAddress = undefined;
                    if (multiMtaResponse === undefined) {
                        overnightAddress = __assign(__assign({}, obj), { overnightParkingAddress: changedAddress });
                    }
                    else
                        overnightAddress = __assign(__assign({ status: multiMtaResponse.status, jobId: multiMtaResponse.jobId }, obj), { overnightParkingAddress: changedAddress });
                    return [4 /*yield*/, multiMtas(policyNumber, overnightAddress, effectiveDate, token)];
                case 1:
                    otherChanges = _a.sent();
                    if (otherChanges.status === 200) {
                        mtaFlag = __assign(__assign({}, mta), { overnightParking: true });
                        setMta(mtaFlag);
                        setMultiMtaResponse(otherChanges.data);
                        setMtaDate(effectiveDate);
                        history.push('/other-changes');
                    }
                    if ((otherChanges.status !== undefined && otherChanges.status === 500) ||
                        (otherChanges.data.status !== undefined && otherChanges.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(otherChanges.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        onSubmit(data);
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var modalContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var policyNumber, token, quote, mtaFlag, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, changeOvernightAddress(policyNumber, address, effectiveDate, params, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        // set quote data to redux
                        setQuoteDetails(quote.data);
                        mtaFlag = __assign(__assign({}, mta), { overnightParking: true });
                        setMta(mtaFlag);
                        // set dates in redux
                        setMtaDate(effectiveDate);
                        // redirect
                        history.push('/your-quote');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        modalContinue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onCancel = function () {
        if (mtaEffectiveDate)
            history.push('/other-changes');
        else
            history.push('/mypolicy');
    };
    useEffect(function () {
        if (mtaEffectiveDate !== undefined)
            setEffectiveDate(mtaEffectiveDate);
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.OVERNIGHT_PARKING_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent !== undefined)
            setFormButtons(cmsContent.contentSections[0].buttons);
        if (cmsContent !== undefined)
            setModalContent(cmsContent.modals[0]);
    }, [cmsContent]);
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) }, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(MtaDateAndTime, { disabled: mtaEffectiveDate !== undefined, getEffectiveDate: function (date) { return setEffectiveDate(date); } }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    formGroups && currentPolicy && (React.createElement(React.Fragment, null,
                        React.createElement(AddressCurrent, { content: getSingleFormGroupContent(OvernightParkingContentIds.CURRENT_ADDRESS_SECTION, formGroups), address: currentPolicy.overnightParkingAddress }),
                        effectiveDate !== '' && (React.createElement(React.Fragment, null,
                            React.createElement(AddressLookup, { content: getSingleFormGroupContent(OvernightParkingContentIds.ADDRESS_LOOKUP_SECTION, formGroups), addressToParent: setAddress }),
                            address && (React.createElement(AddressSelected, { content: getSingleFormGroupContent(OvernightParkingContentIds.SELECTED_ADDRESS_SECTION, formGroups), address: address, register: register, control: control, errors: errors })))))),
                    formButtons && (React.createElement(MtaPageButtons, { formButtons: formButtons, submitType: setSubmitType, showMtaButtons: effectiveDate !== '' && address !== undefined, onCancel: onCancel, showContinue: mtaEffectiveDate === undefined }))))),
        modalContent && (React.createElement(MtaModal, { modalOpen: modalOpen, setModalOpen: setModalOpen, modalContent: modalContent, modalContinue: modalContinue }))))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    mta: state.mta,
    mtaEffectiveDate: state.mtaEffectiveDate,
    multiMtaResponse: state.multiMtaResponse,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setQuoteDetails: function (quote) { return dispatch(actions.setQuote(quote)); },
    setMtaDate: function (date) { return dispatch(actions.setMtaEffectiveDate(date)); },
    setMta: function (mta) { return dispatch(actions.setMta(mta)); },
    setMultiMtaResponse: function (response) { return dispatch(actions.setMultiMtaResponse(response)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MtaOvernightParkingPage);
var templateObject_1;
