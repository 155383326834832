var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import ArrowBackIcon from 'assets/arrow_back_prev.svg';
import ArrowForwardIcon from 'assets/arrow_forward_next.svg';
import { fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PdfWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var IconWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  cursor: ", ";\n"], ["\n  color: ", ";\n  cursor: ", ";\n"])), function (props) { return (props.disabled ? '#20202060' : '#202020'); }, function (props) { return (props.disabled ? 'not-allowed' : 'pointer'); });
var PageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  span {\n    font-weight: ", ";\n  }\n"], ["\n  span {\n    font-weight: ", ";\n  }\n"])), fontWeight.light);
var PdfPager = function (_a) {
    var data = _a.data;
    var _b = useState(0), pages = _b[0], setPages = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var onPageSelect = function (type) {
        if (type === 'prev' && page > 1) {
            setPage(page - 1);
        }
        if (type === 'next' && page < pages) {
            setPage(page + 1);
        }
    };
    return (React.createElement(Root, null,
        React.createElement(PdfWrapper, null,
            React.createElement(IconWrapper, { disabled: page === 1, onClick: function () { return onPageSelect('prev'); } },
                React.createElement(ArrowBackIcon, null)),
            React.createElement(Document, { file: "data:application/pdf;base64," + data, onLoadSuccess: function (_a) {
                    var numPages = _a.numPages;
                    return setPages(numPages);
                } },
                React.createElement(Page, { pageNumber: page })),
            React.createElement(IconWrapper, { disabled: page === pages, onClick: function () { return onPageSelect('next'); } },
                React.createElement(ArrowForwardIcon, null))),
        React.createElement(PageWrapper, { dangerouslySetInnerHTML: { __html: page + "<span> / " + pages + "</span>" } })));
};
export default PdfPager;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
