var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color } from 'styles';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import ReactMarkdown from 'react-markdown';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var IntroWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  a {\n    color: ", ";\n  }\n\n  p {\n    margin-bottom: 10px;\n  }\n"], ["\n  a {\n    color: ", ";\n  }\n\n  p {\n    margin-bottom: 10px;\n  }\n"])), color.green);
var InformationUsageSection = function (_a) {
    var content = _a.content;
    return (React.createElement(Layout, null,
        React.createElement(PaddingHorizontal, null,
            React.createElement(PaddingVertical, null,
                React.createElement(Root, null,
                    React.createElement(SectionHeader, { title: content.title, expandable: false }),
                    React.createElement(IntroWrapper, null,
                        React.createElement(ReactMarkdown, { linkTarget: '__blank', source: content.intro })))))));
};
export default InformationUsageSection;
var templateObject_1, templateObject_2;
