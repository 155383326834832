var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import SubSectionHeader from 'components/molecules/SubSectionHeader';
import Title from 'components/atoms/Title';
import Tooltip from 'components/molecules/Tooltip';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var DropdownHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 20px;\n  padding: 16px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n\n  > div {\n    padding-left: 0px;\n  }\n"], ["\n  margin-top: 20px;\n  padding: 16px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  border-top: 1px solid rgba(137, 137, 137, 0.5);\n\n  > div {\n    padding-left: 0px;\n  }\n"])));
var Info = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 16px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  padding: 16px 0;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n"])));
var InfoQuestion = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-weight: 500;\n"], ["\n  margin-bottom: 10px;\n  font-weight: 500;\n"])));
var InfoAnswer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 300;\n"], ["\n  font-weight: 300;\n"])));
var InfoAsterix = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 300;\n  font-style: italic;\n  font-size: 14px;\n"], ["\n  font-weight: 300;\n  font-style: italic;\n  font-size: 14px;\n"])));
var QuestionWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Question = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
export var VehicleCurrent = function (_a) {
    var content = _a.content, vehicle = _a.vehicle;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var displayName = vehicle.displayName;
    var title = content.title, list = content.list;
    var listRows = list[0].listItems;
    return (React.createElement(Root, null,
        React.createElement(Title, null, title),
        React.createElement(DropdownHeader, null,
            React.createElement(SubSectionHeader, { open: open, title: displayName, onClick: function () { return setOpen(!open); } })),
        open && (React.createElement(React.Fragment, null, listRows.map(function (item) { return (React.createElement(Info, { key: item.value },
            React.createElement(InfoQuestion, null,
                React.createElement(QuestionWrapper, null,
                    React.createElement(Question, null, item.label),
                    " ",
                    item.info && React.createElement(Tooltip, { text: item.info })),
                item.text && React.createElement(InfoAsterix, null, item.text)),
            React.createElement(InfoAnswer, null, vehicle[item.value]))); })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
