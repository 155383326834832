export var PaymentOption;
(function (PaymentOption) {
    PaymentOption["ANNUAL"] = "ANNUAL";
    PaymentOption["MONTHLY"] = "MONTHLY";
})(PaymentOption || (PaymentOption = {}));
export var TableCellType;
(function (TableCellType) {
    TableCellType["TEXT"] = "TEXT";
    TableCellType["ICON"] = "ICON";
})(TableCellType || (TableCellType = {}));
