var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import find from 'lodash/find';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
import { useHistory } from 'react-router-dom';
import { amendDriver, getDetailedPolicy, getPageContent, mtaTokenRefresh } from 'services';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
import { ContentPageURL, SessionStorageKey } from 'types';
import ShareComponent from 'pages/ShareComponent';
import MtaDateAndTime from 'components/organisms/MtaDateAndTime';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { FormInput, FormSelect, Group } from 'components/organisms/Form';
import FormAutocomplete from 'components/organisms/Form/FormAutocomplete';
import Button from 'components/atoms/Button';
import MtaModal from 'components/molecules/MtaModal';
var Root = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var MtaAmendDriverPage = function (_a) {
    var currentPolicy = _a.currentPolicy, driverIndex = _a.driverIndex, setQuoteDetails = _a.setQuoteDetails, setMtaDate = _a.setMtaDate;
    // CMS
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), modalContent = _d[0], setModalContent = _d[1];
    // Modal
    var _e = useState(false), modalOpen = _e[0], setModalOpen = _e[1];
    // effective date
    var _f = useState(''), effectiveDate = _f[0], setEffectiveDate = _f[1];
    // form data
    var _g = useState(undefined), details = _g[0], setDetails = _g[1];
    var _h = useState(undefined), detailedPolicy = _h[0], setDetailedPolicy = _h[1];
    var _j = useState(undefined), formValues = _j[0], setFormValues = _j[1];
    var history = useHistory();
    var _k = useForm(), register = _k.register, handleSubmit = _k.handleSubmit, control = _k.control, watch = _k.watch, reset = _k.reset;
    var watchForm = watch(['title', 'lastName', 'martalStatus', 'occupation', 'dayTimeParking', 'nightTimeParking']);
    useEffect(function () {
        if (driverIndex === undefined)
            history.push('/dashboard');
        if (currentPolicy !== undefined) {
            var getDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
                var policyNumber, date, token, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                            date = new Date();
                            token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                            return [4 /*yield*/, getDetailedPolicy(policyNumber, date.toISOString(), token)];
                        case 1:
                            result = _a.sent();
                            if (result.status === 200) {
                                setDetailedPolicy(result.data);
                            }
                            else
                                console.log(result);
                            return [2 /*return*/];
                    }
                });
            }); };
            getDetails();
        }
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.AMEND_DRIVER_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent !== undefined)
            setModalContent(cmsContent.modals[0]);
        if (currentPolicy !== undefined)
            setDetails(currentPolicy.drivers[driverIndex]);
        if (details !== undefined) {
            var keys = JSON.parse(sessionStorage.getItem(SessionStorageKey.TYPEKEYS));
            var group = find(keys, ['typeName', 'typekey.OccupationType_itb']);
            var occ = find(group.keyValuePairs, ['key', "typekey.OccupationType_itb." + details.occupation]);
            var occupationData = {
                label: occ.value,
                value: occ.key.substring(occ.key.lastIndexOf('.') + 1),
            };
            reset(__assign(__assign({}, details), { occupation: occupationData }));
        }
    }, [cmsContent, currentPolicy, details, reset]);
    var onSubmit = function (data) {
        if (data.lastName === details.lastName &&
            data.maritalStatus === details.maritalStatus &&
            data.title === details.title &&
            data.occupation.value === details.occupation) {
            console.log('No Change');
        }
        else {
            setFormValues(data);
            setModalOpen(true);
        }
    };
    var modalContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, publicId, policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = __assign(__assign({}, formValues), { occupation: formValues.occupation.value, dayTimeParking: formValues.dayTimeParking === undefined ? detailedPolicy.vehicles[0].dayTimeParking : formValues.dayTimeParking, nightTimeParking: formValues.nightTimeParking === undefined
                            ? detailedPolicy.vehicles[0].nightTimeParking
                            : formValues.nightTimeParking });
                    publicId = detailedPolicy.drivers[driverIndex].publicID.replace('pc:', '');
                    policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, amendDriver(policyNumber, publicId, data, effectiveDate, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        setQuoteDetails(quote.data);
                        setMtaDate(effectiveDate);
                        history.push('/your-quote');
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        history.push('/error');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        modalContinue();
                    else {
                        history.push('/login');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var renderAmendDriver = function () {
        var amendGroup = formGroups[0];
        var title = amendGroup.title, fields = amendGroup.fields;
        var titleField = find(fields, function (obj) {
            return obj.fieldId === 'title';
        });
        var titleOptions = getGwDropdownValues(titleField.gwTypelist);
        var lastNameField = find(fields, function (obj) {
            return obj.fieldId === 'lastname';
        });
        var maritalStatusField = find(fields, function (obj) {
            return obj.fieldId === 'maritalStatus';
        });
        var maritalStatusOptions = getGwDropdownValues(maritalStatusField.gwTypelist);
        var occupationField = find(fields, function (obj) {
            return obj.fieldId === 'occupation';
        });
        var occupationOptions = getGwDropdownValues(occupationField.gwTypelist);
        return (React.createElement(Group, { title: title },
            React.createElement(FormSelect, { label: titleField.label, options: titleOptions, toolTip: titleField.toolTip, placeholder: titleField.placeholder, name: titleField.value, control: control }),
            React.createElement(FormInput, { type: "text", label: lastNameField.label, name: lastNameField.value, toolTip: lastNameField.toolTip, control: register, placeholder: lastNameField.placeholder }),
            React.createElement(FormSelect, { label: maritalStatusField.label, options: maritalStatusOptions, toolTip: maritalStatusField.toolTip, placeholder: maritalStatusField.placeholder, name: maritalStatusField.value, control: control }),
            React.createElement(FormAutocomplete, { label: occupationField.label, control: control, name: occupationField.value, options: occupationOptions })));
    };
    var renderParkingOptions = function () {
        var amendGroup = formGroups[0];
        var fields = amendGroup.fields;
        var dayTimeField = find(fields, function (obj) {
            return obj.fieldId === 'dayTimeParking';
        });
        var dayTimeOptions = getGwDropdownValues(dayTimeField.gwTypelist);
        var nightTimeField = find(fields, function (obj) {
            return obj.fieldId === 'nightTimeParking';
        });
        var nightTimeOptions = getGwDropdownValues(nightTimeField.gwTypelist);
        return (React.createElement(Group, { title: "Parking options" },
            React.createElement(FormSelect, { label: dayTimeField.label, options: dayTimeOptions, toolTip: dayTimeField.toolTip, placeholder: dayTimeField.placeholder, name: dayTimeField.value, control: control, spanTwoCols: true }),
            React.createElement(FormSelect, { label: nightTimeField.label, options: nightTimeOptions, toolTip: nightTimeField.toolTip, placeholder: nightTimeField.placeholder, name: nightTimeField.value, control: control, spanTwoCols: true })));
    };
    return (React.createElement(Root, { onSubmit: handleSubmit(onSubmit) }, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(MtaDateAndTime, { getEffectiveDate: function (date) { return setEffectiveDate(date); } }),
        details && watchForm.occupation && effectiveDate !== '' && (React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                formGroups && renderAmendDriver(),
                watchForm.occupation.value !== details.occupation && renderParkingOptions(),
                React.createElement(ButtonsWrapper, null,
                    React.createElement(Button, { buttonStyle: "warning-clear" }, "Cancel"),
                    React.createElement(Button, { type: "submit" }, "Continue"))))),
        modalContent && (React.createElement(MtaModal, { modalOpen: modalOpen, setModalOpen: setModalOpen, modalContent: modalContent, modalContinue: modalContinue }))))));
};
var mapStateToProps = function (state) { return ({
    currentPolicy: state.currentPolicy,
    driverIndex: state.driverIndex,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setQuoteDetails: function (quote) { return dispatch(actions.setQuote(quote)); },
    setMtaDate: function (date) { return dispatch(actions.setMtaEffectiveDate(date)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MtaAmendDriverPage);
var templateObject_1, templateObject_2;
