import { renewToken } from './client';
import { getMilesSummaries, getDailyMilesForMonth, getBonusMiles, getDrivingScores, getMilesBundles, getMilesCost, addMiles, getTrips, postMapConsentAgreement, getMapConsentPermission, getPolicyTrips, } from './drivingdataAPIs';
var fetchBonusMilesAttempts = 0;
export var fetchBonusMiles = function (policyId, accessToken, tokenForRefresh, callBack) {
    getBonusMiles(policyId.replace('/', '%252F'), accessToken).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            fetchBonusMilesAttempts = 0;
            callBack(payload);
        }
        else if (status === 401 && fetchBonusMilesAttempts < 5) {
            fetchBonusMilesAttempts++;
            renewToken(tokenForRefresh, function (newToken, newRefreshToken) {
                return fetchBonusMiles(policyId, newToken, newRefreshToken, callBack);
            });
        }
        else {
            console.log("Error fetching bonus miles for " + policyId + " | status code " + status);
        }
    });
};
var fetchMilesSummariesAttempts = 0;
export var fetchMilesSummaries = function (policyId, token, refreshToken, callback) {
    getMilesSummaries(policyId.replace('/', '%252F'), token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200) {
            fetchMilesSummariesAttempts = 0;
            callback(payload);
        }
        else if (status === 401 && fetchMilesSummariesAttempts < 5) {
            fetchMilesSummariesAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchMilesSummaries(policyId, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching miles summaries for " + policyId + " | status code " + status);
        }
    });
};
var fetchDailyMilesForMonthAttempts = 0;
export var fetchDailyMilesForMonth = function (policyId, year, month, token, refreshToken, callback) {
    getDailyMilesForMonth(policyId.replace('/', '%252F'), year, month, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200) {
            fetchDailyMilesForMonthAttempts = 0;
            callback(year, month, payload);
        }
        else if (status === 401 && fetchDailyMilesForMonthAttempts < 5) {
            fetchDailyMilesForMonthAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchDailyMilesForMonth(policyId, year, month, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching bonus miles for " + policyId + " | status code " + status);
        }
    });
};
var fetchDrivingScoresAttempts = 0;
export var fetchDrivingScores = function (policyId, bonusMilesId, token, refreshToken, callback) {
    getDrivingScores(policyId.replace('/', '%252F'), bonusMilesId, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200) {
            fetchDrivingScoresAttempts = 0;
            callback(payload);
        }
        else if (status === 401 && fetchDrivingScoresAttempts < 5) {
            fetchDrivingScoresAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchDrivingScores(policyId, bonusMilesId, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error driving scores for " + policyId + " | status code " + status);
        }
    });
};
var fetchMilesBundlesAttempts = 0;
export var fetchMilesBundles = function (policyId, token, refreshToken, callback) {
    getMilesBundles(policyId.replace('/', '%252F'), token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200) {
            fetchMilesBundlesAttempts = 0;
            callback(payload);
        }
        else if (status === 401 && fetchMilesBundlesAttempts < 5) {
            fetchMilesBundlesAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchMilesBundles(policyId, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching Miles Bundles for " + policyId + " | status code " + status);
        }
    });
};
var fetchMilesCostAttempts = 0;
export var fetchMilesCost = function (policyId, miles, token, refreshToken, callback) {
    getMilesCost(policyId.replace('/', '%252F'), miles, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            fetchMilesCostAttempts = 0;
            callback(payload);
        }
        else if (status === 401 && fetchMilesCostAttempts < 5) {
            fetchMilesCostAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchMilesCost(policyId, miles, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching Miles Cost for " + policyId + " | status code " + status);
        }
    });
};
var fetchTripsAttempts = 0;
export var fetchTrips = function (policyId, page, size, from, to, token, refreshToken, callback) {
    getTrips(policyId, page, size, from, to, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            callback(size === 1, payload);
            fetchTripsAttempts = 0;
        }
        else if (status === 401 && fetchTripsAttempts < 5) {
            fetchTripsAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchTrips(policyId, page, size, from, to, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching trips data for " + policyId + " | status code " + status);
        }
    });
};
var fetchPolicyTripsAttempts = 0;
export var fetchPolicyTrips = function (policyId, page, year, month, token, refreshToken, callback) {
    getPolicyTrips(policyId.replace('/', '%252F'), page, year, month, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            callback(payload);
            fetchPolicyTripsAttempts = 0;
        }
        else if (status === 401 && fetchPolicyTripsAttempts < 5) {
            fetchPolicyTripsAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchPolicyTrips(policyId, page, year, month, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching policy trips data for " + policyId + " | status code " + status);
        }
    });
};
var addPolicyMilesAttempts = 0;
export var addPolicyMiles = function (policyId, miles, token, refreshToken, callback) {
    addMiles(policyId.replace('/', '%252F'), miles, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200) {
            addPolicyMilesAttempts = 0;
            callback && callback(payload);
        }
        else if (status === 401 && addPolicyMilesAttempts < 5) {
            addPolicyMilesAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return addPolicyMiles(policyId, miles, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error add miles for " + policyId + " | status code " + status);
        }
    });
};
var sendMapConsentAgreementAttempts = 0;
export var sendMapConsentAgreement = function (policyNumber, behaviourName, behaviourValue, token, refreshToken, callback) {
    postMapConsentAgreement(policyNumber, behaviourName, behaviourValue, token).then(function (result) {
        var status = result.status;
        if (status === 200) {
            sendMapConsentAgreementAttempts = 0;
            callback && callback();
        }
        else if (status === 401 && sendMapConsentAgreementAttempts < 5) {
            sendMapConsentAgreementAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return sendMapConsentAgreement(policyNumber, behaviourName, behaviourValue, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error sending map consent permission for " + policyNumber + " | status code " + status);
        }
    });
};
var fetchMapConsentPermissionAttempts = 0;
export var fetchMapConsentPermission = function (policyNumber, behaviourName, token, refreshToken, callback) {
    getMapConsentPermission(policyNumber, behaviourName, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 || status === 204) {
            fetchMapConsentPermissionAttempts = 0;
            callback(payload ? payload.behaviourValue : 'Deny');
        }
        else if (status === 401 && fetchMapConsentPermissionAttempts < 5) {
            fetchMapConsentPermissionAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchMapConsentPermission(policyNumber, behaviourName, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching map consent permission for " + policyNumber + " | status code " + status);
        }
    });
};
