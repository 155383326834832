var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Phone from 'assets/phone.svg';
import Button from 'components/atoms/Button';
import Title from 'components/atoms/Title';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { getPageContent } from 'services';
import { ContentPageURL } from 'types';
import { TagManagerArgs } from 'analytics';
import { connect } from 'react-redux';
import { actions } from 'reduxStore';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  * {\n    box-sizing: border-box;\n  }\n\n  h2 {\n    font-weight: 500;\n    font-size: 30px;\n  }\n\n  p {\n    margin-bottom: 10px;\n    font-weight: 300;\n  }\n"], ["\n  * {\n    box-sizing: border-box;\n  }\n\n  h2 {\n    font-weight: 500;\n    font-size: 30px;\n  }\n\n  p {\n    margin-bottom: 10px;\n    font-weight: 300;\n  }\n"])));
var CompletedConfirmation = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 30px;\n  border-radius: 2px;\n  border: solid 1px rgba(59, 156, 115, 0.5);\n  background-color: rgba(59, 156, 115, 0.06);\n\n  h2 {\n    margin-top: 0;\n  }\n"], ["\n  padding: 30px;\n  border-radius: 2px;\n  border: solid 1px rgba(59, 156, 115, 0.5);\n  background-color: rgba(59, 156, 115, 0.06);\n\n  h2 {\n    margin-top: 0;\n  }\n"])));
var SelfManageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  div {\n    margin-bottom: 20px;\n  }\n\n  strong {\n    font-weight: 500;\n    line-height: 1.5;\n  }\n"], ["\n  div {\n    margin-bottom: 20px;\n  }\n\n  strong {\n    font-weight: 500;\n    line-height: 1.5;\n  }\n"])));
var BlockWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: 10px;\n  padding: 20px;\n  border-radius: 1px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  background-color: rgba(137, 137, 137, 0.04);\n"], ["\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: 10px;\n  padding: 20px;\n  border-radius: 1px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  background-color: rgba(137, 137, 137, 0.04);\n"])));
var ClaimsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  padding: 20px;\n  border-radius: 1px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  background-color: rgba(137, 137, 137, 0.04);\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  padding: 20px;\n  border-radius: 1px;\n  border: solid 1px rgba(137, 137, 137, 0.2);\n  background-color: rgba(137, 137, 137, 0.04);\n"])));
var Letter = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 50px;\n  min-width: 50px;\n  height: 50px;\n  color: white;\n  background-color: #e6454e;\n  margin-right: 20px;\n  border-radius: 4px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 25px;\n"], ["\n  width: 50px;\n  min-width: 50px;\n  height: 50px;\n  color: white;\n  background-color: #e6454e;\n  margin-right: 20px;\n  border-radius: 4px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 25px;\n"])));
var StopWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 40px;\n"], ["\n  margin-top: 40px;\n"])));
var Contact = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n\n  p {\n    margin: 0;\n  }\n\n  svg {\n    fill: #30a369;\n    margin-right: 20px;\n  }\n"], ["\n  display: flex;\n\n  p {\n    margin: 0;\n  }\n\n  svg {\n    fill: #30a369;\n    margin-right: 20px;\n  }\n"])));
var Seperator = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 1px;\n  background-color: rgba(137, 137, 137, 0.5);\n  margin: 40px 0;\n"], ["\n  height: 1px;\n  background-color: rgba(137, 137, 137, 0.5);\n  margin: 40px 0;\n"])));
var ButtonWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var MakeClaimWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  div {\n    margin-bottom: 20px;\n  }\n\n  em {\n    font-size: 12px;\n    font-weight: 300;\n    line-height: 1.83;\n  }\n"], ["\n  div {\n    margin-bottom: 20px;\n  }\n\n  em {\n    font-size: 12px;\n    font-weight: 300;\n    line-height: 1.83;\n  }\n"])));
var RenewalPaymentComplete = function (_a) {
    var setRenewalState = _a.setRenewalState;
    var history = useHistory();
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), renewalCompleteText = _c[0], setRenewalCompleteText = _c[1];
    var _d = useState(undefined), accidentTitleText = _d[0], setAccidentTitleText = _d[1];
    var _e = useState(undefined), sBlock = _e[0], setsBlock = _e[1];
    var _f = useState(undefined), tBlock = _f[0], settBlock = _f[1];
    var _g = useState(undefined), oBlock = _g[0], setoBlock = _g[1];
    var _h = useState(undefined), pBlock = _h[0], setpBlock = _h[1];
    var _j = useState(undefined), makeClaimTitle = _j[0], setMakeClaimTitle = _j[1];
    var _k = useState(undefined), makeClaimTopText = _k[0], setMakeClaimTopText = _k[1];
    var _l = useState(undefined), claimsNumber = _l[0], setClaimsNumber = _l[1];
    var _m = useState(undefined), repairsContact = _m[0], setRepairsContact = _m[1];
    var _o = useState(undefined), queryText = _o[0], setQueryText = _o[1];
    var _p = useState(undefined), customerServices = _p[0], setCustomerServices = _p[1];
    var _q = useState(undefined), selfManageTitle = _q[0], setSelfManageTitle = _q[1];
    var _r = useState(undefined), selfManageText = _r[0], setSelfManageText = _r[1];
    useEffect(function () {
        console.clear();
        if (cmsContent === undefined) {
            getPageContent(ContentPageURL.RENEWAL_PAYMENT_COMPLETE).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        }
        if (cmsContent && renewalCompleteText === undefined) {
            cmsContent.contentSections.map(function (item) {
                if (item.sectionId === 'renewalCompleteText')
                    setRenewalCompleteText(item.body);
                if (item.sectionId === 'accidentTitle')
                    setAccidentTitleText(item.body);
                if (item.sectionId === 'sBlock')
                    setsBlock(item.body);
                if (item.sectionId === 'tBlock')
                    settBlock(item.body);
                if (item.sectionId === 'oBlock')
                    setoBlock(item.body);
                if (item.sectionId === 'pBlock')
                    setpBlock(item.body);
                if (item.sectionId === 'makeClaimTitle')
                    setMakeClaimTitle(item.body);
                if (item.sectionId === 'makeClaimTopText')
                    setMakeClaimTopText(item.body);
                if (item.sectionId === 'claimsNumber')
                    setClaimsNumber(item.body);
                if (item.sectionId === 'repairsContact')
                    setRepairsContact(item.body);
                if (item.sectionId === 'queryText')
                    setQueryText(item.body);
                if (item.sectionId === 'customerServices')
                    setCustomerServices(item.body);
                if (item.sectionId === 'selfManageTitle')
                    setSelfManageTitle(item.body);
                if (item.sectionId === 'selfManageText')
                    setSelfManageText(item.body);
            });
            setRenewalState();
        }
    }, [cmsContent]);
    return (React.createElement(Root, null, cmsContent && (React.createElement("div", null,
        React.createElement(RenewalJourneyProgress, { step: 5 }),
        React.createElement(Layout, { withSeperator: true, shadow: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(CompletedConfirmation, null,
                        React.createElement(ReactMarkdown, { source: renewalCompleteText }))))),
        React.createElement(Layout, { withSeperator: true, shadow: true },
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(SelfManageWrapper, null,
                        React.createElement(Title, null, selfManageTitle),
                        React.createElement(ReactMarkdown, { source: selfManageText })),
                    React.createElement(Seperator, null),
                    React.createElement(Title, null, accidentTitleText),
                    React.createElement(StopWrapper, null,
                        sBlock && (React.createElement(BlockWrapper, null,
                            React.createElement(Letter, null, "S"),
                            React.createElement(ReactMarkdown, { source: sBlock }))),
                        tBlock && (React.createElement(BlockWrapper, null,
                            React.createElement(Letter, null, "T"),
                            React.createElement(ReactMarkdown, { source: tBlock }))),
                        oBlock && (React.createElement(BlockWrapper, null,
                            React.createElement(Letter, null, "O"),
                            React.createElement(ReactMarkdown, { source: oBlock }))),
                        pBlock && (React.createElement(BlockWrapper, null,
                            React.createElement(Letter, null, "P"),
                            React.createElement(ReactMarkdown, { source: pBlock }))))))),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(MakeClaimWrapper, null,
                        React.createElement(Title, null, makeClaimTitle),
                        React.createElement(ReactMarkdown, { source: makeClaimTopText })),
                    React.createElement(ClaimsWrapper, null,
                        React.createElement(Contact, null,
                            React.createElement(Phone, null),
                            React.createElement("div", null,
                                React.createElement(ReactMarkdown, { source: claimsNumber }))),
                        React.createElement(Contact, null,
                            React.createElement(Phone, null),
                            React.createElement("div", null,
                                React.createElement(ReactMarkdown, { source: repairsContact })))),
                    React.createElement(ReactMarkdown, { source: queryText }),
                    React.createElement(ClaimsWrapper, null,
                        React.createElement(Contact, null,
                            React.createElement(Phone, null),
                            React.createElement("div", null,
                                React.createElement(ReactMarkdown, { source: customerServices })))),
                    React.createElement(Seperator, null),
                    React.createElement(ButtonWrapper, null,
                        React.createElement(Button, { onClick: function () {
                                history.push('/dashboard');
                                TagManagerArgs('Button', "" + window.location.pathname, 'Back to my dashboard');
                            } }, "Back to my dashboard")))))))));
};
var mapDispatchToProps = function (dispatch) { return ({
    setRenewalState: function () { return dispatch(actions.setRefreshPolicy(true)); },
}); };
export default connect(null, mapDispatchToProps)(RenewalPaymentComplete);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
