import find from 'lodash/find';
import React from 'react';
import { Group, FormInput, FormSelect, FormRadio } from 'components/organisms/Form';
import { AddressContentIds } from 'types';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
export var AddressSelected = function (_a) {
    var content = _a.content, address = _a.address, register = _a.register, control = _a.control, errors = _a.errors;
    var addressLineOne = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.ADDRESS_LINE_ONE;
    });
    var city = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.CITY;
    });
    var postalCode = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.POSTAL_CODE;
    });
    var homeOwner = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.HOME_OWNER;
    });
    var dayTime = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.DAY_TIME_PARKING;
    });
    var overNight = find(content.fields, function (obj) {
        return obj.fieldId === AddressContentIds.NIGHT_TIME_PARKING;
    });
    return (React.createElement(Group, { title: content.title },
        addressLineOne && (React.createElement(FormInput, { name: addressLineOne.value, defaultValue: address[addressLineOne.value], control: register, label: addressLineOne.label, error: errors[addressLineOne.value], isRequired: addressLineOne.required })),
        city && (React.createElement(FormInput, { name: city.value, defaultValue: address[city.value], control: register, label: city.label, readOnly: true })),
        postalCode && (React.createElement(FormInput, { name: postalCode.value, defaultValue: address[postalCode.value], control: register, label: postalCode.label, readOnly: true })),
        homeOwner && (React.createElement(FormRadio, { options: homeOwner.options, label: homeOwner.label, name: homeOwner.value, control: control, error: errors[homeOwner.value], isRequired: homeOwner.required })),
        dayTime && (React.createElement(FormSelect, { name: dayTime.value, options: getGwDropdownValues(dayTime.gwTypelist), label: dayTime.label, placeholder: "Please select", control: control, error: errors[dayTime.value], isRequired: dayTime.required })),
        overNight && (React.createElement(FormSelect, { name: overNight.value, options: getGwDropdownValues(overNight.gwTypelist), label: overNight.label, placeholder: "Please select", control: control, error: errors[overNight.value], isRequired: overNight.required }))));
};
