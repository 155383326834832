var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { getPageContent } from 'services';
import ShareComponent from 'pages/ShareComponent';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import PhoneIcon from 'assets/phone.svg';
import ChatIcon from 'assets/liveChatIcon.svg';
import { ContentPageURL } from 'types';
export var ErrorContentIds;
(function (ErrorContentIds) {
    ErrorContentIds["UNABLE_TO_PROCESS"] = "unableToProcess";
    ErrorContentIds["CONTACT_US"] = "contactUs";
    ErrorContentIds["EMAIL_US"] = "emailUs";
})(ErrorContentIds || (ErrorContentIds = {}));
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  svg {\n    fill: #007f49;\n    min-width: 24px;\n  }\n"], ["\n  svg {\n    fill: #007f49;\n    min-width: 24px;\n  }\n"])));
var UnableToProcessText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n\n  p {\n    font-size: 16px;\n    letter-spacing: 0.5px;\n    line-height: 1.25;\n    margin-bottom: 10px;\n  }\n"], ["\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n\n  p {\n    font-size: 16px;\n    letter-spacing: 0.5px;\n    line-height: 1.25;\n    margin-bottom: 10px;\n  }\n"])));
var ContactWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var ContactTextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 10px;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n\n  p {\n    font-size: 14px;\n    line-height: 1.07;\n    letter-spacing: 0.5px;\n    margin-bottom: 16px;\n  }\n"], ["\n  padding-left: 10px;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n\n  p {\n    font-size: 14px;\n    line-height: 1.07;\n    letter-spacing: 0.5px;\n    margin-bottom: 16px;\n  }\n"])));
var ErrorPage = function () {
    var _a = useState(undefined), cmsContent = _a[0], setCmsContent = _a[1];
    var _b = useState(undefined), information = _b[0], setInformation = _b[1];
    var _c = useState(''), unableToProcess = _c[0], setUnableToProcess = _c[1];
    var _d = useState(''), contactTimesTelephone = _d[0], setContactTimesTelephone = _d[1];
    var _e = useState(''), contactTimesLiveChat = _e[0], setContactTimesLiveChat = _e[1];
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.ERROR_PAGE).then(function (content) { return setCmsContent(content.payload); });
        if (cmsContent !== undefined)
            setInformation(cmsContent.contentSections);
        if (information !== undefined) {
            information.map(function (item) {
                if (item.sectionId === 'unableToProcess')
                    setUnableToProcess(item.body);
                if (item.sectionId === 'contactTimesTelephone')
                    setContactTimesTelephone(item.body);
                if (item.sectionId === 'contactTimesLiveChat')
                    setContactTimesLiveChat(item.body);
                return undefined;
            });
        }
    }, [cmsContent, information]);
    return (React.createElement(Root, null, cmsContent && (React.createElement(React.Fragment, null,
        React.createElement(ShareComponent, { headerContent: cmsContent.header }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    React.createElement(UnableToProcessText, null, unableToProcess && React.createElement(ReactMarkdown, { linkTarget: "_blank", source: unableToProcess })),
                    contactTimesLiveChat && (React.createElement(ContactWrapper, null,
                        React.createElement(ChatIcon, null),
                        React.createElement(ContactTextWrapper, null,
                            React.createElement(ReactMarkdown, { linkTarget: "_blank", source: contactTimesLiveChat })))),
                    contactTimesTelephone && (React.createElement(ContactWrapper, null,
                        React.createElement(PhoneIcon, null),
                        React.createElement(ContactTextWrapper, null,
                            React.createElement(ReactMarkdown, { linkTarget: "_blank", source: contactTimesTelephone })))))))))));
};
export default ErrorPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
