var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormInput, getSingleFieldContent, Group } from '../Form';
import CloseIcon from 'assets/close.svg';
import { VehicleLookupContentIds } from 'types';
import Button from 'components/atoms/Button';
import ReactMarkdown from 'react-markdown';
import { isRegistrationSameAsCurrent } from 'guidewirePolicyUtils';
import ErrorMessage from 'components/atoms/ErrorMessage';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var CarInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n\n  grid-column: span 3;\n\n  padding: 20px 17px;\n  box-sizing: border-box;\n  border: solid 1px rgba(59, 156, 115, 0.5);\n  border-radius: 4px;\n  background-color: rgba(59, 156, 115, 0.06);\n  font-size: 18px;\n  font-weight: 500;\n  letter-spacing: 0.5px;\n\n  svg {\n    fill: #007f49;\n  }\n\n  svg:hover {\n    cursor: pointer;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n\n  grid-column: span 3;\n\n  padding: 20px 17px;\n  box-sizing: border-box;\n  border: solid 1px rgba(59, 156, 115, 0.5);\n  border-radius: 4px;\n  background-color: rgba(59, 156, 115, 0.06);\n  font-size: 18px;\n  font-weight: 500;\n  letter-spacing: 0.5px;\n\n  svg {\n    fill: #007f49;\n  }\n\n  svg:hover {\n    cursor: pointer;\n  }\n"])));
var PleaseNoteText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 1/-1;\n  font-size: 15px;\n  line-height: 1.33;\n  letter-spacing: 0.5px;\n\n  h3 {\n    font-weight: 500;\n  }\n\n  p {\n    font-weight: 300;\n  }\n"], ["\n  grid-column: 1/-1;\n  font-size: 15px;\n  line-height: 1.33;\n  letter-spacing: 0.5px;\n\n  h3 {\n    font-weight: 500;\n  }\n\n  p {\n    font-weight: 300;\n  }\n"])));
var ErrorWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
export var VehicleLookup = function (_a) {
    var content = _a.content, carToParent = _a.carToParent, registrationToParent = _a.registrationToParent, initialValues = _a.initialValues, _b = _a.initialRegistration, initialRegistration = _b === void 0 ? '' : _b, currentRegistration = _a.currentRegistration, registrationLookup = _a.registrationLookup;
    var _c = useState(initialRegistration), carRegistrationValue = _c[0], setcarRegistrationValue = _c[1];
    var _d = useState(initialValues !== undefined ? initialValues : undefined), car = _d[0], setCar = _d[1];
    var _e = useState(''), errorMessage = _e[0], setErrorMessage = _e[1];
    var carRegistration = getSingleFieldContent(VehicleLookupContentIds.CAR_REGISTRATION, content.fields);
    var findMyCar = getSingleFieldContent(VehicleLookupContentIds.FIND_MY_CAR, content.fields);
    var pleaseNote = undefined || getSingleFieldContent('pleaseNote', content.fields);
    var lookupRegistration = function () { return __awaiter(void 0, void 0, void 0, function () {
        var results;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, registrationLookup(carRegistrationValue)];
                case 1:
                    results = _a.sent();
                    if (Object.keys(results.vehicle).length > 0 && results.errorMessage === '') {
                        setCar(results.vehicle);
                        carToParent(results.vehicle);
                        registrationToParent(carRegistrationValue);
                        setErrorMessage('');
                    }
                    else {
                        setCar(undefined);
                        carToParent(undefined);
                        registrationToParent('');
                        setErrorMessage(results.errorMessage);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var clearCar = function () {
        setCar(undefined);
        carToParent(undefined);
        registrationToParent(initialRegistration);
    };
    useEffect(function () {
        if (isRegistrationSameAsCurrent(currentRegistration, carRegistrationValue) &&
            initialValues === undefined) {
            setErrorMessage('Can not use existing registration');
            setCar(undefined);
            carToParent(undefined);
            registrationToParent('');
        }
        else
            setErrorMessage('');
    }, [carRegistrationValue]);
    return (React.createElement(Root, null,
        React.createElement(Group, { title: content.title },
            carRegistration && (React.createElement(FormInput, { name: carRegistration.value, placeholder: carRegistration.placeholder, onChange: function (value) { return setcarRegistrationValue(value.toUpperCase()); }, value: carRegistrationValue })),
            findMyCar && (React.createElement(Button, { disabled: carRegistrationValue === '' ||
                    isRegistrationSameAsCurrent(currentRegistration, carRegistrationValue), type: "button", onClick: function () {
                    lookupRegistration();
                    TagManagerArgs('Button', window.location.pathname + " - Vehicle lookup", findMyCar.label);
                } }, findMyCar.label)),
            errorMessage !== '' && initialRegistration === '' && (React.createElement(ErrorWrapper, null,
                React.createElement(ErrorMessage, { errorMessage: errorMessage }))),
            car && (React.createElement(CarInfo, null, car.make + " " + car.model + " " + car.firstRegisteredYear + ", " + car.engineCapacity + "CC, " + car.numberOfDoors + " Doors, " + car.fuelType + " " + car.transmission,
                initialRegistration === undefined && React.createElement(CloseIcon, { onClick: function () { return clearCar(); } }))),
            pleaseNote !== undefined && (React.createElement(PleaseNoteText, null,
                React.createElement(ReactMarkdown, { source: pleaseNote.additionalToTooltip }))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
