var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import SectionHeader from 'components/molecules/SectionHeader';
import { FormPassword, getSingleFieldContent, Group } from 'components/organisms/Form';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { TagManagerArgs } from 'analytics';
var ButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n"])));
var ConfirnationWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  button {\n    margin-left: 10px;\n  }\n"])));
export var MyPassword = function (_a) {
    var myPasswordContent = _a.myPasswordContent, changePassword = _a.changePassword;
    var _b = useState(false), editMode = _b[0], setEditmode = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(''), currentPassword = _d[0], setCurrentPassword = _d[1];
    var _e = useState(''), passwordValue = _e[0], setPasswordValue = _e[1];
    var _f = useState(''), confirmPasswordValue = _f[0], setConfirmPasswordValue = _f[1];
    var _g = useState(false), passwordChange = _g[0], setPasswordChange = _g[1];
    var _h = useState(false), saveButton = _h[0], setSaveButton = _h[1];
    var password = getSingleFieldContent('password', myPasswordContent.fields);
    var newPassword = getSingleFieldContent('newPassword', myPasswordContent.fields);
    var confirmNewPassword = getSingleFieldContent('confirmNewPassword', myPasswordContent.fields);
    var setNewPasswordControl = function (value) {
        setPasswordValue(value);
        if (value === '') {
            setPasswordChange(false);
            setConfirmPasswordValue('');
        }
        else
            setPasswordChange(true);
        if (value !== confirmPasswordValue)
            setSaveButton(false);
        if (value === confirmPasswordValue)
            setSaveButton(true);
    };
    var setConfirmPasswordControl = function (value) {
        if (value === '' || confirmPasswordValue === '')
            setSaveButton(false);
        if (value !== passwordValue)
            setSaveButton(false);
        if (value === passwordValue)
            setSaveButton(true);
        setConfirmPasswordValue(value);
    };
    var handleCancel = function () {
        setEditmode(false);
        setCurrentPassword('');
        setPasswordValue('');
        setConfirmPasswordValue('');
    };
    var handleSave = function () {
        var data = {
            oldPassword: currentPassword,
            password: confirmPasswordValue,
        };
        changePassword(data);
    };
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(SectionHeader, { title: myPasswordContent.title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
            open && (React.createElement(Group, { noBorder: true },
                !editMode && (React.createElement(FormPassword, { name: password.value, label: password.label, value: password.value, readOnly: true, disabled: true })),
                editMode && (React.createElement(FormPassword, { name: "oldPassword", label: "Confirm current password", value: passwordValue, valueToParent: function (value) { return setCurrentPassword(value); } })),
                editMode ? (React.createElement(FormPassword, { name: newPassword.value, label: newPassword.label, value: passwordValue, valueToParent: function (value) { return setNewPasswordControl(value); } })) : (React.createElement("div", null)),
                editMode ? (React.createElement(FormPassword, { name: confirmNewPassword.value, label: confirmNewPassword.label, value: confirmPasswordValue, readOnly: passwordChange === false && passwordValue === '', disabled: passwordChange === false && passwordValue === '', valueToParent: function (value) { return setConfirmPasswordControl(value); } })) : (React.createElement("div", null)),
                !editMode && (React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                            setEditmode(true);
                            TagManagerArgs('Button', window.location.pathname + " - My Password details", 'Edit');
                        } }, myPasswordContent.buttons && myPasswordContent.buttons[0].text))),
                editMode && (React.createElement(ButtonWrapper, null,
                    React.createElement(ConfirnationWrapper, null,
                        React.createElement(Button, { buttonStyle: "warning-clear", onClick: function () {
                                handleCancel();
                                TagManagerArgs('Button', window.location.pathname + " - My Password details", 'Cancel');
                            } }, "Cancel"),
                        React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                handleSave();
                                TagManagerArgs('Button', window.location.pathname + " - My Password details", 'Save Password');
                            }, disabled: saveButton === false || currentPassword === '' }, "Save password")))))))));
};
var templateObject_1, templateObject_2;
