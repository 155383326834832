var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Button, { Root as ButtonRoot } from 'components/atoms/Button';
import LocationIcon from 'assets/location.svg';
import { color, fontSize, media } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 36px 16px 36px 16px;\n\n  ", "\n"], ["\n  padding: 36px 16px 36px 16px;\n\n  ",
    "\n"])), media.large(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    background-color: rgba(137, 137, 137, 0.04);\n    border-top: 1px solid rgba(137, 137, 137, 0.5);\n    border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n    padding: 44px;\n  "], ["\n    display: flex;\n    background-color: rgba(137, 137, 137, 0.04);\n    border-top: 1px solid rgba(137, 137, 137, 0.5);\n    border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n    padding: 44px;\n  "]))));
var RouteWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var CircleWrapper = styled.svg.attrs({ width: 20, height: 20, viewBox: '0 0 20 20' })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var StartCircle = styled.circle.attrs({
    cx: 10,
    cy: 10,
    r: 8,
    strokeWidth: 4,
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", "FF;\n"], ["\n  fill: none;\n  stroke: ", "FF;\n"])), color.black);
var LocationWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var LocationIconWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  svg {\n    height: 20px;\n    width: 20px;\n    fill: ", ";\n  }\n"], ["\n  svg {\n    height: 20px;\n    width: 20px;\n    fill: ", ";\n  }\n"])), color.green);
var AddressWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-left: 20px;\n"], ["\n  font-size: ", ";\n  margin-left: 20px;\n"])), fontSize.fs14);
var LineWrapper = styled.svg.attrs({ width: 20, height: 42, viewBox: '0 0 20 32' })(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var Line = styled.line.attrs({
    x1: 10,
    y1: 4,
    x2: 10,
    y2: 38,
    strokeWidth: 2,
    strokeDasharray: 4,
    strokeDashoffset: -1,
})(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  stroke: ", "6A;\n"], ["\n  stroke: ", "6A;\n"])), color.black);
var ButtonWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: 50px;\n  width: 100%;\n  margin-top: 36px;\n\n  ", " {\n    border: solid 1.5px ", "5A;\n  }\n\n  ", "\n"], ["\n  height: 50px;\n  width: 100%;\n  margin-top: 36px;\n\n  ", " {\n    border: solid 1.5px ", "5A;\n  }\n\n  ",
    "\n"])), ButtonRoot, color.green, media.large(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    width: 190px;\n    margin-top: auto;\n    margin-left: auto;\n  "], ["\n    width: 190px;\n    margin-top: auto;\n    margin-left: auto;\n  "]))));
var JourneyDetailView = function (_a) {
    var buttonText = _a.buttonText, onShowMap = _a.onShowMap, destination = _a.destination, start = _a.start;
    return (React.createElement(Root, null,
        React.createElement(RouteWrapper, null,
            React.createElement(LocationWrapper, null,
                React.createElement(CircleWrapper, null,
                    React.createElement(StartCircle, null)),
                React.createElement(AddressWrapper, null, start)),
            React.createElement(LineWrapper, null,
                React.createElement(Line, null)),
            React.createElement(LocationWrapper, null,
                React.createElement(LocationIconWrapper, null,
                    React.createElement(LocationIcon, null)),
                React.createElement(AddressWrapper, null, destination))),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { onClick: function () {
                    onShowMap();
                    TagManagerArgs('Button', window.location.pathname + " - Journey Detail View", buttonText);
                }, buttonStyle: "secondary", fullWidth: true }, buttonText))));
};
export default JourneyDetailView;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
