import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { initializeStore, initialState, reducer } from 'reduxStore';
import App from './App';
var target = document.querySelector('#root');
var store = initializeStore(initialState, reducer);
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(Router, null,
        React.createElement(App, null))), target);
