var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContentPageURL, PaymentValidationEndpoint, } from 'types';
import { InformationText } from './InformationText';
import { getSingleButtonContent } from 'components/organisms/Form';
import { getModalContent, getSectionContent } from 'components/organisms/Form/utils';
import Button from 'components/atoms/Button';
import HeaderSection from 'components/organisms/HeaderSection';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { bindPayment, getPageContent } from 'services';
import { InfoIds } from './types';
import SagePayIframe from 'components/organisms/SagePayIframe';
import { getTokens } from 'utils';
import { PaymentWidget } from 'pages/ShareComponent';
import { TagManagerArgs } from 'analytics';
import moment from 'moment';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    margin-bottom: 10px;\n    line-height: 1.25;\n  }\n\n  h3 {\n    font-weight: 400;\n  }\n"], ["\n  p {\n    margin-bottom: 10px;\n    line-height: 1.25;\n  }\n\n  h3 {\n    font-weight: 400;\n  }\n"])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var getDateXdaysBefore = function (days, date) {
    return moment(date)
        .utc(false)
        .subtract(days, 'days')
        .toISOString();
};
var ImportantInformationPage = function (_a) {
    var _b, _c, _d, _e, _f;
    var accountInfo = _a.accountInfo, mta = _a.mta, quote = _a.quote, primaryAddress = _a.primaryAddress, jobId = _a.jobId, current = _a.current, _g = _a.autoRenew, autoRenew = _g === void 0 ? false : _g;
    var _h = useState(undefined), cmsContent = _h[0], setCmsContent = _h[1];
    var _j = useState(undefined), information = _j[0], setInformation = _j[1];
    var _k = useState(undefined), pageButtons = _k[0], setPageButtons = _k[1];
    var _l = useState(undefined), goBackBtn = _l[0], setGoBackBtn = _l[1];
    var _m = useState(undefined), acknowledgmentBtn = _m[0], setAcknowledgmentBtn = _m[1];
    var _o = useState(undefined), validationEndpoint = _o[0], setValidationEndpoint = _o[1];
    var _p = useState(false), modalOpen = _p[0], setModalOpen = _p[1];
    var _q = useState(undefined), modalContent = _q[0], setModalContent = _q[1];
    var _r = useState(''), sagePayLink = _r[0], setSagePayLink = _r[1];
    var title = getSectionContent('mainTitle', information);
    var history = useHistory();
    var bindPaymentToPolicy = function (jobId, reuseCard) { return __awaiter(void 0, void 0, void 0, function () {
        var tokens, useExistingCard;
        return __generator(this, function (_a) {
            tokens = getTokens();
            if (tokens) {
                useExistingCard = { reuseExistingCard_itb: reuseCard };
                bindPayment(jobId, useExistingCard, tokens.accessToken, tokens.refreshToken, function () { return history.push('/accepted'); });
            }
            return [2 /*return*/];
        });
    }); };
    //is installemnt
    var isInstallment = (quote === null || quote === void 0 ? void 0 : quote.paymentPlanId) !== 'bc:5';
    //is less then 2 installemnts left
    var installmentsLeft = ((_b = current === null || current === void 0 ? void 0 : current.installmentDetails) === null || _b === void 0 ? void 0 : _b.remainingInstallments) || -1;
    //is renewing
    var todaysDate = moment(new Date())
        .utc(false)
        .toISOString();
    var renewDate = getDateXdaysBefore(21, quote === null || quote === void 0 ? void 0 : quote.expiration);
    var isRenewing = moment(todaysDate).isAfter(renewDate) && moment(todaysDate).isBefore(quote === null || quote === void 0 ? void 0 : quote.expiration);
    //6 day payment cycle before my 2nd from last payment
    var is6DaysBefore2ndFromLastPayment = ((_c = current === null || current === void 0 ? void 0 : current.installmentDetails) === null || _c === void 0 ? void 0 : _c.remainingInstallments) && ((_d = current === null || current === void 0 ? void 0 : current.installmentDetails) === null || _d === void 0 ? void 0 : _d.nextPaymentDueDate) &&
        ((_e = current === null || current === void 0 ? void 0 : current.installmentDetails) === null || _e === void 0 ? void 0 : _e.remainingInstallments) === 2 &&
        moment((_f = current === null || current === void 0 ? void 0 : current.installmentDetails) === null || _f === void 0 ? void 0 : _f.nextPaymentDueDate)
            .subtract(6, 'd')
            .isAfter(moment());
    console.log('current?.installmentDetails', current === null || current === void 0 ? void 0 : current.installmentDetails);
    console.log('isInstallment', isInstallment);
    console.log('installmentsLeft', installmentsLeft);
    console.log('isRenewing', isRenewing);
    console.log('is6DaysBefore2ndFromLastPayment', is6DaysBefore2ndFromLastPayment);
    console.log('autoRenew', autoRenew);
    useEffect(function () {
        if (!modalContent) {
            getPageContent(ContentPageURL.PAYMENT_WIDGET).then(function (result) {
                var status = result.status, payload = result.payload;
                if (status === 200 && payload) {
                    var modals = payload.modals;
                    if (quote.paymentPlanId === 'bc:5' && quote.totalCost.amount > 0) {
                        setModalContent(getModalContent('bc5Payment', modals));
                    }
                    if (quote.paymentPlanId === 'bc:5' && quote.totalCost.amount < 0) {
                        setModalContent(getModalContent('bc5Refund', modals));
                    }
                    if (quote.paymentPlanId === 'bc:6' && quote.totalCost.amount > 0) {
                        setModalContent(getModalContent('bc6Payment', modals));
                    }
                    if (quote.paymentPlanId === 'bc:6' && quote.totalCost.amount < 0) {
                        setModalContent(getModalContent('bc6Refund', modals));
                    }
                }
                else {
                    console.log('Error fetching payment widget content | status code ', status);
                }
            });
        }
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.IMPORTANT_INFORMATION_PAGE).then(function (content) {
                return setCmsContent(content.payload);
            });
        if (cmsContent !== undefined)
            setInformation(cmsContent.contentSections);
        if (information !== undefined) {
            information.map(function (item) {
                if (item.buttons)
                    setPageButtons(item);
            });
        }
        if (pageButtons !== undefined) {
            setGoBackBtn(getSingleButtonContent('btnGoBack', pageButtons.buttons));
            setAcknowledgmentBtn(getSingleButtonContent('btnAcknowledgment', pageButtons.buttons));
        }
        if (quote !== undefined && cmsContent) {
            if (quote.paymentPlanId === 'bc:5') {
                setValidationEndpoint(PaymentValidationEndpoint.VALIDATE_CARD);
            }
            if (quote.paymentPlanId === 'bc:6') {
                setValidationEndpoint(PaymentValidationEndpoint.MONTHLY_REMAINING_INSTALLMENTS);
            }
        }
        var handler = function (event) {
            try {
                var data = JSON.parse(event.data);
                if (data.message === 'success') {
                    var usePreviousCard = false;
                    bindPaymentToPolicy(jobId, usePreviousCard);
                }
                if (data.message === 'cancelled')
                    history.go(0);
                if (data.message === 'failure')
                    history.push('/error');
            }
            catch (error) {
                //
            }
        };
        if (location.pathname === '/important-information') {
            window.addEventListener('message', handler);
        }
    }, [cmsContent, information, pageButtons, quote]);
    return (React.createElement(Root, null,
        title && React.createElement(HeaderSection, { title: title.body }),
        cmsContent && (React.createElement(React.Fragment, null,
            React.createElement(Layout, null,
                React.createElement(PaddingHorizontal, null,
                    React.createElement(PaddingVertical, null,
                        information && sagePayLink === '' && (React.createElement(React.Fragment, null,
                            mta.addDriver && React.createElement(InformationText, { titleType: InfoIds.NEW_DRIVER, information: information }),
                            mta.newCar && React.createElement(InformationText, { titleType: InfoIds.NEW_CAR, information: information }),
                            mta.overnightParking &&
                                (accountInfo.correspondenceAddress ? (React.createElement(InformationText, { titleType: InfoIds.OVERNIGHT_PARKING, information: information })) : (React.createElement(InformationText, { titleType: InfoIds.OVERNIGHT_PARKING_WITHOUT_CA, information: information }))),
                            mta.removeDriver && (React.createElement(InformationText, { titleType: InfoIds.REMOVED_DRIVER, information: information })),
                            goBackBtn && acknowledgmentBtn && (React.createElement(ButtonWrapper, null,
                                React.createElement(Button, { buttonStyle: goBackBtn.style, onClick: function () {
                                        history.goBack();
                                        TagManagerArgs('Button', "" + window.location.pathname, goBackBtn.text);
                                    } }, goBackBtn.text),
                                React.createElement(Button, { buttonStyle: acknowledgmentBtn.style, onClick: function () {
                                        setModalOpen(true);
                                        TagManagerArgs('Button', "" + window.location.pathname, acknowledgmentBtn.text);
                                    } }, acknowledgmentBtn.text))))),
                        sagePayLink !== '' && React.createElement(SagePayIframe, { sagePayLink: sagePayLink })))))),
        modalContent && primaryAddress && validationEndpoint && (React.createElement(PaymentWidget, { content: modalContent, jobId: jobId, isRefund: quote.totalCost.amount <= 0, showAddCard: !isInstallment ||
                installmentsLeft < 2 ||
                (isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && !autoRenew), showSavedCard: !isInstallment ||
                installmentsLeft > 2 ||
                (isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && autoRenew), modalOpen: modalOpen, onClose: function () { return setModalOpen(false); }, onSagePayConnected: function (link) { return setSagePayLink(link); }, validationEndpoint: validationEndpoint, billingAddress: primaryAddress, refundAmount: Math.abs(quote.totalCost.amount).toFixed(2) }))));
};
var mapStateToProps = function (state) { return ({
    accountInfo: state.accountInfo,
    mta: state.mta,
    quote: state.quote,
    primaryAddress: state.currentPolicy.policyHolder.primaryAddress,
    jobId: state.quote.jobId,
    current: state.currentPolicy,
    autoRenew: state.autoRenew,
}); };
export default connect(mapStateToProps)(ImportantInformationPage);
var templateObject_1, templateObject_2;
