// colors from new login screens - need to check all designs for color changes and adjust this file
// if needed. For now the login has colors in its css
//
// #f9f9f9 - light grey bg used for Mobile Content
// #268657 - green bg used on the right side of login page, desktop sized
// #268657 - green font color on the "forgot your password" link
// #30a369 - svg bg/fill color in the chat/contact section
// Spacing
export var space = {
    s4: '4px',
    s6: '6px',
    s8: '8px',
    s10: '10px',
    s12: '12px',
    s14: '14px',
    s16: '16px',
    s18: '18px',
    s20: '20px',
    s24: '24px',
    s28: '28px',
    s30: '30px',
    s32: '32px',
    s40: '40px',
    s50: '50px',
    s48: '48px',
    s60: '60px',
    s64: '64px',
    s75: '75px',
    s80: '80px',
    s90: '90px',
    s110: '110px',
};
// Font Sizes
export var fontSize = {
    fs72: '72px',
    fs48: '48px',
    fs40: '40px',
    fs32: '32px',
    fs24: '24px',
    fs20: '20px',
    fs18: '18px',
    fs16: '16px',
    fs14: '14px',
    fs12: '12px',
    fs11: '11px',
    fs10: '10px',
};
// Font Families
export var fontFamily = {
    base: 'Ubuntu, sans-serif',
    condensed: 'Ubuntu Condensed, sans-serif',
};
// Font Weights
export var fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
};
// Colors
export var color = {
    white: '#FFFFFF',
    green: '#30A369',
    greenDark: '#007F49',
    greenDots: '#48AF75',
    black: '#202020',
    blue: '#3399FF',
    blueDisabled: '#99CCFF',
    blueHover: '#1F5C99',
    blueActive: '#297ACC',
    red: '#ad1f1f',
    orange: '#f5a623',
    grey: '#444444',
    greyMedium: '#898989',
    greyLight: '#6E7076',
    greyDark: '#F8F8F8',
    greyNeutral: '#EEEEEE',
    greyDisabled: '#E4E4E4',
    greyBorder: '#CACACA',
    greyMiles: '#9ea2a3',
    greyParagraphLight: '#1D1D26',
    greyParagraph: '#2E2E38',
    greyParagraphDark: '#33333A',
    greyBackground: '#F9F9F9',
    greyNavigation: '#F4F4F3',
};
// Breakpoints & Media Queries
export var screenExtraSmallMin = 320;
export var screenSmallMin = 360;
export var screenSmallMax = 767;
export var screenMediumMin = 768;
export var screenMediumMax = 1023;
export var screenLargeMin = 1024;
export var screenLargeMax = 1919;
export var screenExtraLargeMin = 1920;
export var screenExtraLargeMax = 2559;
export var screenUltraHdMin = 2560;
export var breakpoints = {
    extraSmall: [screenExtraSmallMin],
    small: [screenSmallMin],
    smallOnly: [0, screenSmallMax],
    medium: [screenMediumMin],
    mediumOnly: [screenMediumMin, screenMediumMax],
    large: [screenLargeMin],
    largeOnly: [screenLargeMin, screenLargeMax],
    extraLarge: [screenExtraLargeMin],
    extraLargeOnly: [screenExtraLargeMin, screenExtraLargeMax],
    ultraHd: [screenUltraHdMin],
};
