var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Point from 'components/atoms/Point';
import { fontSize, fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100px;\n  border-right: 2px solid rgba(137, 137, 137, 0.15);\n  justify-content: center;\n  letter-spacing: 0.5px;\n\n  :last-child {\n    border-right: 0px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100px;\n  border-right: 2px solid rgba(137, 137, 137, 0.15);\n  justify-content: center;\n  letter-spacing: 0.5px;\n\n  :last-child {\n    border-right: 0px;\n  }\n"])));
var Head = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 27px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 27px;\n"])));
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n"])), fontSize.fs14, fontWeight.medium);
var ValueWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 25px;\n  font-weight: ", ";\n  text-align: center;\n"], ["\n  font-size: 25px;\n  font-weight: ", ";\n  text-align: center;\n"])), fontWeight.bold);
var MilesBox = function (_a) {
    var color = _a.color, label = _a.label, value = _a.value;
    return (React.createElement(Root, null,
        React.createElement(Head, null,
            React.createElement(Point, { color: color, innerRadius: 8, outerRadius: 16 }),
            React.createElement(LabelWrapper, null, label)),
        React.createElement(ValueWrapper, null, new Intl.NumberFormat().format(value))));
};
export default MilesBox;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
