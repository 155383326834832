import React from 'react';
import { getSingleFormGroupContent, getSingleFieldContent, Group, FormRadio } from 'components/organisms/Form';
var AddDriverConvictionsAndClaims = function (_a) {
    var formGroups = _a.formGroups, control = _a.control, errors = _a.errors;
    var convictionsAndClaimsContent = getSingleFormGroupContent('convictionsAndClaimsSection', formGroups);
    var hasMotorConvictions = getSingleFieldContent('hasMotorConvictions', convictionsAndClaimsContent.fields);
    var hasMotorClaims = getSingleFieldContent('hasMotorClaims', convictionsAndClaimsContent.fields);
    return (React.createElement(Group, { title: convictionsAndClaimsContent.title },
        hasMotorConvictions && (React.createElement(FormRadio, { label: hasMotorConvictions.label, toolTip: hasMotorConvictions.toolTip, options: hasMotorConvictions.options, name: hasMotorConvictions.value, control: control, additionalToTooltip: hasMotorConvictions.additionalToTooltip, isRequired: hasMotorConvictions.required, error: errors && errors[hasMotorConvictions.value] ? errors[hasMotorConvictions.value] : undefined })),
        hasMotorClaims && (React.createElement(FormRadio, { label: hasMotorClaims.label, toolTip: hasMotorClaims.toolTip, options: hasMotorClaims.options, name: hasMotorClaims.value, control: control, additionalToTooltip: hasMotorClaims.additionalToTooltip, isRequired: hasMotorClaims.required, error: errors && errors[hasMotorClaims.value] ? errors[hasMotorClaims.value] : undefined }))));
};
export default AddDriverConvictionsAndClaims;
