export var initialState = {
    topupId: undefined,
    currentPolicyIndex: 0,
    mtaFormDetails: {
        amend: false,
        index: 0,
        drivers: [],
    },
    mta: {
        addDriver: false,
        newCar: false,
        overnightParking: false,
        removeDriver: false,
    },
    quote: {},
    mtaEffectiveDate: undefined,
    multiMtaResponse: undefined,
    multiMtaDrivers: [],
    driverIndex: undefined,
    driverFormDetails: undefined,
    convictionsAndClaimsFormDetails: undefined,
    renewalState: {
        pageOne: undefined,
        pageTwo: undefined,
        addedDrivers: [],
        address: undefined,
        convictions: undefined,
        claims: undefined,
        vehicle: undefined,
        vehicleRegistration: undefined,
    },
    renewalAmendedQuote: undefined,
    accountInfo: undefined,
    currentlyCovered: false,
    accountNumber: '',
    autoRenew: undefined,
    refresh: false,
};
