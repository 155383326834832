var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import find from 'lodash/find';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { connect } from 'react-redux';
import { ContentPageURL } from 'types';
import { getPageContent } from 'services';
import { QuoteHeader } from './QuoteHeader';
import { QuoteExcesses } from './QuoteExcesses';
import { QuotePrice } from './QuotePrice';
import { QuoteDetailsList } from './QuoteDetailsList';
import { QuoteAddress } from './QuoteAddress';
import Button from 'components/atoms/Button';
import Title from 'components/atoms/Title';
import ListRow from 'components/molecules/ListRow';
import { getSingleFormGroupContent } from 'components/organisms/Form';
import { getDriverDisplayValuesForList, getVehicleDisplayValuesForList } from 'guidewirePolicyUtils';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var SummaryTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 20px;\n  border-bottom: solid 1px #898989;\n  margin-bottom: 40px;\n  margin-top: 40px;\n"], ["\n  padding-bottom: 20px;\n  border-bottom: solid 1px #898989;\n  margin-bottom: 40px;\n  margin-top: 40px;\n"])));
var SummarySubTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-top: 30px;\n  padding-bottom: 20px;\n"], ["\n  padding-top: 30px;\n  padding-bottom: 20px;\n"])));
var FormButtons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  a {\n    text-decoration: none;\n  }\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  a {\n    text-decoration: none;\n  }\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var QuotePage = function (_a) {
    var quote = _a.quote, mta = _a.mta, mtaEffectiveDate = _a.mtaEffectiveDate, multiMtaResponse = _a.multiMtaResponse, driverFormDetails = _a.driverFormDetails, convictionsAndClaimsFormDetails = _a.convictionsAndClaimsFormDetails, currentPolicy = _a.currentPolicy;
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(undefined), formGroups = _c[0], setFormGroups = _c[1];
    var _d = useState(undefined), formButton = _d[0], setFormButton = _d[1];
    var _e = useState(undefined), summaryOfChangesContent = _e[0], setSummaryOfChangesContent = _e[1];
    var _f = useState(undefined), aboutYouContent = _f[0], setAboutYouContent = _f[1];
    var _g = useState(undefined), newDriverContent = _g[0], setNewDriverContent = _g[1];
    var _h = useState(undefined), newCarContent = _h[0], setNewCarContent = _h[1];
    var _j = useState(undefined), newOvernightAddressContent = _j[0], setNewOvernightAddressContent = _j[1];
    var drivers = quote.drivers;
    var vehicles = quote.vehicles;
    var history = useHistory();
    var renderSummaryDate = function () {
        var row = summaryOfChangesContent === null || summaryOfChangesContent === void 0 ? void 0 : summaryOfChangesContent.list[0].listItems[0];
        return (React.createElement(React.Fragment, null,
            React.createElement(SummaryTitle, null,
                React.createElement(Title, null, summaryOfChangesContent === null || summaryOfChangesContent === void 0 ? void 0 : summaryOfChangesContent.title)),
            React.createElement(ListRow, { label: row === null || row === void 0 ? void 0 : row.label, value: moment(mtaEffectiveDate).format('LL'), style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' } })));
    };
    var renderAboutYou = function () {
        var mainDriver = find(quote.drivers, function (obj) {
            return obj.policyHolder === true;
        });
        var driverDisplay = getDriverDisplayValuesForList(mainDriver);
        var rows = aboutYouContent.list[0].listItems;
        return (React.createElement(React.Fragment, null,
            React.createElement(SummarySubTitle, null,
                React.createElement(Title, null, aboutYouContent.title)),
            React.createElement(QuoteDetailsList, { title: (mainDriver === null || mainDriver === void 0 ? void 0 : mainDriver.firstName) + " " + (mainDriver === null || mainDriver === void 0 ? void 0 : mainDriver.lastName), rows: rows, display: driverDisplay })));
    };
    var renderNewDriver = function (drivers) {
        var rows = newDriverContent.list[0].listItems;
        return (React.createElement(React.Fragment, null,
            React.createElement(SummarySubTitle, null,
                React.createElement(Title, null, newDriverContent.title)),
            drivers.map(function (item) {
                return (item.newlyAdded && (React.createElement(QuoteDetailsList, { title: item.firstName + " " + item.lastName, display: getDriverDisplayValuesForList(item), rows: rows })));
            })));
    };
    var renderNewCar = function (vehicles) {
        var rows = newCarContent.list[0].listItems;
        var button = newCarContent.buttons[0];
        var vehicleDisplay = getVehicleDisplayValuesForList(vehicles[0]);
        vehicleDisplay.purchasedDate = vehicleDisplay.purchasedMonth[0].toUpperCase() +
            vehicleDisplay.purchasedMonth.slice(1) + " " + vehicleDisplay.purchasedYear;
        vehicleDisplay.mileage = currentPolicy.productMiles;
        return (React.createElement(React.Fragment, null,
            React.createElement(SummarySubTitle, null,
                React.createElement(Title, null, newCarContent.title)),
            vehicles.map(function (item, index) { return (React.createElement(QuoteDetailsList, { key: "newCar", data: item, rows: rows, button: button, buttonBorderBottom: index === 0, type: "car", display: vehicleDisplay, title: vehicleDisplay.make + " " + vehicleDisplay.model + ", " + vehicleDisplay.registrationNumber })); })));
    };
    var renderNewOvernightAddress = function () {
        var address = quote.overnightParkingAddress;
        var title = newOvernightAddressContent.title;
        var fields = newOvernightAddressContent.fields;
        return (React.createElement(React.Fragment, null,
            React.createElement(SummarySubTitle, null,
                React.createElement(Title, null, title)),
            React.createElement(QuoteAddress, { fields: fields, address: address })));
    };
    var amendDetailsRedirect = function () {
        if (mta.addDriver && multiMtaResponse === undefined) {
            history.push({
                pathname: '/add-driver',
                state: { amendFormDetails: driverFormDetails, amendConvictionsAndClaims: convictionsAndClaimsFormDetails },
            });
        }
        if (mta.newCar && multiMtaResponse === undefined)
            history.push('/change-vehicle');
        if (mta.overnightParking && multiMtaResponse === undefined)
            history.push('/overnight-parking');
        if (multiMtaResponse !== undefined) {
            history.push('/other-changes');
        }
    };
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.YOUR_QUOTE_PAGE).then(function (content) {
                return setCmsContent(content.payload);
            });
        if (cmsContent !== undefined)
            setFormGroups(cmsContent.contentSections[0].formGroups);
        if (cmsContent !== undefined)
            setFormButton(cmsContent.contentSections[0].buttons);
        if (formGroups !== undefined) {
            setSummaryOfChangesContent(getSingleFormGroupContent('summaryOfChanges', formGroups));
            setAboutYouContent(getSingleFormGroupContent('aboutYouList', formGroups));
            setNewDriverContent(getSingleFormGroupContent('newDriver', formGroups));
            setNewCarContent(getSingleFormGroupContent('newCar', formGroups));
            setNewOvernightAddressContent(getSingleFormGroupContent('newOvernightAddress', formGroups));
        }
    }, [cmsContent, formGroups, quote]);
    return (React.createElement(Root, null, cmsContent && quote && (React.createElement(React.Fragment, null,
        React.createElement(QuoteHeader, { quote: quote, cmsContent: cmsContent.quoteHeaders }),
        formGroups && (React.createElement(React.Fragment, null,
            React.createElement(QuotePrice, { quote: quote, cmsContent: formGroups }),
            React.createElement(QuoteExcesses, { quote: quote }))),
        React.createElement(QuoteHeader, { quote: quote, cmsContent: formGroups, blue: true }),
        React.createElement(Layout, null,
            React.createElement(PaddingHorizontal, null,
                React.createElement(PaddingVertical, null,
                    summaryOfChangesContent && renderSummaryDate(),
                    aboutYouContent && renderAboutYou(),
                    mta.addDriver && multiMtaResponse === undefined && newDriverContent && renderNewDriver(drivers),
                    mta.newCar && newCarContent && renderNewCar(vehicles),
                    mta.overnightParking && newOvernightAddressContent && renderNewOvernightAddress(),
                    React.createElement(FormButtons, null, formButton && (React.createElement(React.Fragment, null,
                        React.createElement(Button, { onClick: function () {
                                history.push('/mypolicy');
                                TagManagerArgs('Button', "" + window.location.pathname, 'Cancel');
                            }, buttonStyle: "warning-clear" }, "Cancel"),
                        React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                amendDetailsRedirect();
                                TagManagerArgs('Button', "" + window.location.pathname, 'Amend changes');
                            } }, "Amend changes"),
                        React.createElement(Button, { onClick: function () {
                                history.push('/important-information');
                                TagManagerArgs('Button', "" + window.location.pathname, formButton[0].text);
                            }, buttonStyle: formButton[0].style }, formButton[0].text)))))))))));
};
var mapStateToProps = function (state) { return ({
    quote: state.quote,
    mta: state.mta,
    mtaEffectiveDate: state.mtaEffectiveDate,
    multiMtaResponse: state.multiMtaResponse,
    driverFormDetails: state.driverFormDetails,
    convictionsAndClaimsFormDetails: state.convictionsAndClaimsFormDetails,
    currentPolicy: state.currentPolicy,
}); };
export default connect(mapStateToProps)(QuotePage);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
