var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal, PaddingVertical } from 'components/organisms/Layout';
import { color, fontWeight } from 'styles';
import RadioButton from './RadioButton';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  line-height: 1.63;\n\n  ul {\n    margin-top: 30px;\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -35px;\n        top: 7px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n"], ["\n  font-weight: ", ";\n  line-height: 1.63;\n\n  ul {\n    margin-top: 30px;\n    li {\n      position: relative;\n      list-style: none;\n      margin-bottom: 12px;\n\n      &::before {\n        content: '';\n        position: absolute;\n        left: -35px;\n        top: 7px;\n        width: 10px;\n        height: 10px;\n        background-color: #007f49;\n        border-radius: 50%;\n      }\n    }\n  }\n"])), fontWeight.light);
var IntroTextWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  font-weight: ", ";\n"], ["\n  font-size: 20px;\n  font-weight: ", ";\n"])), fontWeight.medium);
var RadioButtons = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 30px 0;\n\n  > * {\n    margin-bottom: 10px;\n  }\n"], ["\n  margin: 30px 0;\n\n  > * {\n    margin-bottom: 10px;\n  }\n"])));
var DetailsWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n  span {\n    color: ", ";\n    font-weight: 500;\n    cursor: pointer;\n    border-bottom: 1px solid;\n  }\n"], ["\n  font-weight: ", ";\n  span {\n    color: ", ";\n    font-weight: 500;\n    cursor: pointer;\n    border-bottom: 1px solid;\n  }\n"])), fontWeight.light, color.green);
var NCDSection = function (_a) {
    var _b;
    var ncdYears = _a.ncdYears, content = _a.content, onNCDSelected = _a.onNCDSelected, optionSelected = _a.optionSelected, setOpenPncd = _a.setOpenPncd, freeNCD = _a.freeNCD, ncdCost = _a.ncdCost;
    var title = content.title, intro = content.intro, options = content.options;
    var _c = useState(optionSelected), ncd = _c[0], setNCD = _c[1];
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var replacePlacholderText = function (value) {
        var placeholders = {
            NCD_YEARS: ncdYears.toString(),
            FOR_FREE: freeNCD ? 'for no extra cost' : '',
        };
        var changedValue = '';
        if (value !== undefined) {
            changedValue = value.replace(/NCD_YEARS|FOR_FREE/gi, function (matched) {
                return placeholders[matched];
            });
        }
        return changedValue;
    };
    useEffect(function () {
        var _a;
        if (document.getElementById('openPNCD')) {
            (_a = document.getElementById('openPNCD')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
                setOpenPncd(true);
            });
        }
    }, [open]);
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(SectionHeader, { title: title, expandable: true, open: open, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(PaddingVertical, null,
                    React.createElement(IntroTextWrapper, null, ((_b = replacePlacholderText(intro)) === null || _b === void 0 ? void 0 : _b.trim()) + "?"),
                    React.createElement("br", null),
                    React.createElement(IntroTextWrapper, null, "\u00A3" + ncdCost.toFixed(2)),
                    React.createElement(RadioButtons, null, options.map(function (option) { return (React.createElement(RadioButton, { key: option.value, selected: ncd === option.value, title: React.createElement("div", { style: { fontWeight: fontWeight.medium } }, option.label), text: option.text && React.createElement(DetailsWrapper, { dangerouslySetInnerHTML: { __html: option.text } }), onSelect: function () {
                            setNCD(option.value);
                            onNCDSelected(option.value);
                        } })); }))))))));
};
export default NCDSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
