var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { color, fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: 2px solid ", ";\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  color: ", ";\n  cursor: pointer;\n  padding: 12px 16px;\n"], ["\n  display: flex;\n  border: 2px solid ", ";\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  color: ", ";\n  cursor: pointer;\n  padding: 12px 16px;\n"])), color.green, color.green);
var CircleWrapper = styled.svg.attrs({ width: 20, height: 20, viewBox: '0 0 20 20' })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-width: 22px;\n  margin-right: 16px;\n"], ["\n  min-width: 22px;\n  margin-right: 16px;\n"])));
var InnerCircle = styled.circle(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fill: ", ";\n"], ["\n  fill: ", ";\n"])), function (props) { return props.color; });
var OuterCircle = styled.circle(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", ";\n"], ["\n  fill: none;\n  stroke: ", ";\n"])), function (props) { return props.color; });
var TitleContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var TitleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.medium);
var SubtitleWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 14px;\n"], ["\n  font-weight: ", ";\n  font-size: 14px;\n"])), fontWeight.light);
var CoverRadioButton = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, _b = _a.selected, selected = _b === void 0 ? false : _b, onSelect = _a.onSelect;
    return (React.createElement(Root, { onClick: function () { return onSelect(); } },
        React.createElement(CircleWrapper, null,
            selected && React.createElement(InnerCircle, { color: "#007f49", r: 5, cx: 10, cy: 10 }),
            React.createElement(OuterCircle, { color: selected ? '#007f49' : '#898989', r: 9, cx: 10, cy: 10 })),
        React.createElement(TitleContainer, null,
            React.createElement(TitleWrapper, null, title),
            React.createElement(SubtitleWrapper, null, subtitle))));
};
export default CoverRadioButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
