import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { getPageContent } from 'services';
import { ContentPageURL } from 'types';
import { IdleModal } from './IdleModal';
var IdleComponent = function (_a) {
    var timeOut = _a.timeOut, onLogout = _a.onLogout, refresh = _a.refresh;
    // determined by the page the user is on. Time in ms comes from App.tsx
    var timeout = timeOut;
    var _b = useState(undefined), cmsContent = _b[0], setCmsContent = _b[1];
    var _c = useState(false), isIdle = _c[0], setIsIdle = _c[1];
    var _d = useState(false), showModal = _d[0], setShowmodal = _d[1];
    // Once modal is shown user has 60 seconds to make a choice. Auto logged out if no choice is made
    var _e = useState(60), modalTimeLeft = _e[0], setModalTimeLeft = _e[1];
    var handleOnActive = function () { return setIsIdle(false); };
    // if the user is idle for long enought the modal pops up. During that time the user can be active while making a choice, this overrides normal logic until the modal is gone
    var handleOnIdle = function () {
        setIsIdle(!isIdle);
        setShowmodal(true);
    };
    useIdleTimer({
        timeout: timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
    });
    useEffect(function () {
        if (cmsContent === undefined)
            getPageContent(ContentPageURL.IDLE_MODAL).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload.modals[0]);
            });
        if (showModal) {
            var modalCountDown_1 = setInterval(function () {
                setModalTimeLeft(modalTimeLeft - 1);
            }, 1000);
            // if the modalTimeOut reaches 0, the modal is hidden again. This is to prevent memory leaks
            // the logut logic is at the end of this useEffect
            if (modalTimeLeft === 0) {
                setShowmodal(false);
            }
            return function () { return clearInterval(modalCountDown_1); };
        }
        if (modalTimeLeft > 0 && showModal === false)
            setModalTimeLeft(60);
        // Auto logout if modal choice not made
        if (modalTimeLeft === 0 && showModal === false) {
            onLogout();
        }
        return undefined;
    }, [modalTimeLeft, showModal]);
    return (React.createElement("div", null, showModal && cmsContent && (React.createElement(IdleModal, { modalOpen: showModal, setModalOpen: setShowmodal, onLogout: onLogout, refresh: refresh, content: cmsContent }))));
};
export default IdleComponent;
/*
  Useful debug code.
  Place inside the return div to see values on the Frontend

  <div>
    <h1>Timeout: {timeout}ms</h1>
    <h1>Idle: {isIdle.toString()}</h1>
    <h1>Timeleft: {modalTimeLeft}</h1>
  </div>
*/
