var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import OverviewSection from 'components/organisms/OverviewSection';
import BannerImageSection from 'components/organisms/BannerImageSection';
import MyJourneysSection from 'components/organisms/MyJourneysSection';
import BonusMilesSection from 'components/organisms/BonusMilesSection';
import NotificationsSection, { Reminder } from 'components/organisms/NotificationsSection';
import SavedQuoteSection from 'components/organisms/SavedQuoteSection';
import { getPageContent, drivingDataClient, fetchSavedQuotes, fetchQuotePortalDomain, client, } from 'services';
import { authorizationCheck } from 'services/refreshToken';
import { ContentPageURL, SessionStorageKey, NotificationLevel, } from 'types';
import { getFriendlyDisplayDate, getMonthAndYearFromString } from 'utils/date';
import { createBonusMilesData, fetchDrivingData, fetchTripsByPage, mapToDailyMiles, mapToJourneys, mapToJourneySummaries, mapToMonthlyMiles, onQuoteAction, createNewQuoteForAccount, mapPolicyTripsToJourneys, } from 'pages/ShareComponent/shareFunctions';
import ShareComponent from 'pages/ShareComponent';
import { getTokens, MAP_PERMISSION_NAME, MAP_PERMISSION_VALUE_ALLOWED, DISPLAY_TRIPS_NAME } from 'utils';
import { fetchMapConsentPermission, sendMapConsentAgreement, fetchPolicyTrips } from 'services/drivingDataClient';
import DocumentsSection from 'components/organisms/DocumentsSection';
import DashboardPopUpGuide from './DashboardPopUpGuide';
import Button from 'components/atoms/Button/Button';
import { TagManagerArgs } from 'analytics';
import { actions } from 'reduxStore';
var DashboardPage = function (_a) {
    var _b;
    var currentPolicyIndex = _a.currentPolicyIndex, policy = _a.policy, policyIds = _a.policyIds, covered = _a.covered, accountNumber = _a.accountNumber, policySummaries = _a.policySummaries, disableDueToLapse = _a.disableDueToLapse, renewalPolicy = _a.renewalPolicy, refresh = _a.refresh, setRefreshPolicy = _a.setRefreshPolicy;
    var accessToken = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
    var refreshToken = sessionStorage.getItem(SessionStorageKey.REFRESH_TOKEN);
    var history = useHistory();
    var _c = useState(0), totalPages = _c[0], setTotalPages = _c[1];
    var _d = useState(undefined), notifications = _d[0], setNotifications = _d[1];
    var _e = useState(moment().format('yyyy-MM')), monthForJourney = _e[0], setMonthForJourney = _e[1];
    var _f = useState(undefined), contentSections = _f[0], setContentSections = _f[1];
    var _g = useState(undefined), headerContent = _g[0], setHeaderContent = _g[1];
    var _h = useState(undefined), bonusMilesData = _h[0], setBonusMilesData = _h[1];
    var _j = useState(undefined), journeys = _j[0], setJourneys = _j[1];
    var _k = useState(undefined), latestJourney = _k[0], setLatestJourney = _k[1];
    var _l = useState(undefined), drivingSummaries = _l[0], setDrivingSummaries = _l[1];
    var _m = useState(0), remainingMiles = _m[0], setRemaingMiles = _m[1];
    var _o = useState(0), consumedMiles = _o[0], setConsumedMiles = _o[1];
    var _p = useState(undefined), monthlyMiles = _p[0], setMonthlyMiles = _p[1];
    var _q = useState(undefined), dailyMiles = _q[0], setDailyMiles = _q[1];
    var _r = useState(''), currentMonth = _r[0], setCurrentMonth = _r[1];
    var _s = useState(10000), daysToExpiration = _s[0], setDaysToExpiration = _s[1];
    var _t = useState(false), mapDisplayAllowed = _t[0], setMapDisplayAllowed = _t[1];
    var _u = useState(false), tripsDisplayAllowed = _u[0], setTripsDisplayAllowed = _u[1];
    var _v = useState(undefined), savedQuotes = _v[0], setSavedQuotes = _v[1];
    var _w = useState(''), quotePortalUrl = _w[0], setQuotePortalUrl = _w[1];
    var _x = useState(sessionStorage.getItem('showPopupGuide') === 'true'), popupGuideVisible = _x[0], setPopupGuideVisible = _x[1];
    var _y = useState(undefined), popupGuideCMS = _y[0], setPopupGuideCMS = _y[1];
    var _z = useState(true), showNewPolicyButton = _z[0], setNewPolicyButtonDisplay = _z[1];
    var fetchBonusMilesCallback = function (bonusMilesResponse) {
        var _a;
        var emptyBonusMilesObject = [
            {
                year: '',
                data: [],
                yearlyMiles: 0,
                effective: '',
                expiration: '',
            },
        ];
        if (bonusMilesResponse) {
            var bonusMiles = bonusMilesResponse.bonusMiles, availableMiles = bonusMilesResponse.availableMiles, consumedMiles_1 = bonusMilesResponse.consumedMiles;
            setRemaingMiles(availableMiles);
            setConsumedMiles(consumedMiles_1);
            if (bonusMiles && bonusMiles.yearlyAddedMiles && ((_a = bonusMiles.yearlyAddedMiles) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                var bonusMilesData_1 = createBonusMilesData(bonusMiles, policySummaries[currentPolicyIndex].periods);
                setBonusMilesData(bonusMilesData_1);
            }
            else {
                setBonusMilesData(emptyBonusMilesObject);
            }
        }
    };
    var fetchDailyMilesCallback = function (year, month, monthlySummaryPerDay) {
        if (monthlySummaryPerDay) {
            setDailyMiles(mapToDailyMiles(monthlySummaryPerDay));
            setCurrentMonth(moment(new Date(year, month - 1)).format('MMMM, YYYY'));
        }
    };
    var fetchMilesSummariesCallback = function (milesSummaries) {
        if (milesSummaries) {
            setDrivingSummaries(mapToJourneySummaries(milesSummaries));
            var monthlyMiles_1 = mapToMonthlyMiles(milesSummaries);
            if (monthlyMiles_1 !== undefined) {
                var _a = getMonthAndYearFromString(monthlyMiles_1[monthlyMiles_1.length - 1].month), year = _a.year, month = _a.month;
                setMonthlyMiles(monthlyMiles_1);
                if (accessToken && refreshToken) {
                    drivingDataClient.fetchDailyMilesForMonth(policyIds[currentPolicyIndex], year, month, accessToken, refreshToken, fetchDailyMilesCallback);
                }
            }
        }
    };
    var fetchTripsCallback = function (isLatest, data) {
        var pagination = data.pagination, trips = data.trips;
        var journeys = mapToJourneys(trips);
        if (isLatest) {
            setLatestJourney(journeys[0]);
        }
        else {
            setJourneys(journeys);
            var pages = pagination.pages;
            setTotalPages(pages);
        }
    };
    var fetchPolicyTripsCallback = function (data) {
        setJourneys(mapPolicyTripsToJourneys(data));
        setTotalPages(Math.ceil(data.totalElements / 6));
    };
    var fetchMapPermissionCallback = function (behaviourValue) {
        setMapDisplayAllowed(behaviourValue === MAP_PERMISSION_VALUE_ALLOWED ? true : false);
    };
    var fetchTripsPermissionCallback = function (behaviourValue) {
        var shouldShowTrips = behaviourValue.toUpperCase() === 'TRUE' ? true : false;
        setTripsDisplayAllowed(shouldShowTrips);
        var tokens = getTokens();
        if (!shouldShowTrips && tokens) {
            var accessToken_1 = tokens.accessToken, refreshToken_1 = tokens.refreshToken;
            var date = new Date();
            fetchPolicyTrips(policyIds[currentPolicyIndex], 0, date.getFullYear(), date.getMonth() + 1, accessToken_1, refreshToken_1, fetchPolicyTripsCallback);
        }
        if (shouldShowTrips && tokens) {
            var accessToken_2 = tokens.accessToken, refreshToken_2 = tokens.refreshToken;
            drivingDataClient.fetchTrips(policyIds[currentPolicyIndex], 1, 6, moment()
                .startOf('month')
                .format('YYYY-MM-DDT00:00:00') + "Z", moment()
                .endOf('month')
                .format('YYYY-MM-DDT23:59:59') + "Z", accessToken_2, refreshToken_2, fetchTripsCallback);
            drivingDataClient.fetchTrips(policyIds[currentPolicyIndex], 1, 1, moment()
                .subtract(1, 'year')
                .format('YYYY-MM-DDT00:00:00') + "Z", moment().format('YYYY-MM-DDT00:00:00') + "Z", accessToken_2, refreshToken_2, fetchTripsCallback);
            fetchMapConsentPermission(policyIds[currentPolicyIndex], MAP_PERMISSION_NAME, tokens.accessToken, tokens.refreshToken, fetchMapPermissionCallback);
        }
    };
    var onMonthlySummarySelected = function (monthString) {
        var _a = getMonthAndYearFromString(monthString), month = _a.month, year = _a.year;
        if (accessToken && refreshToken) {
            drivingDataClient.fetchDailyMilesForMonth(policyIds[currentPolicyIndex], year, month, accessToken, refreshToken, fetchDailyMilesCallback);
        }
    };
    useEffect(function () {
        getPageContent(ContentPageURL.DASHBOARD_PAGE).then(function (result) {
            var status = result.status, payload = result.payload;
            if (status === 200) {
                setContentSections(payload === null || payload === void 0 ? void 0 : payload.contentSections);
                setHeaderContent(payload === null || payload === void 0 ? void 0 : payload.header);
            }
            else {
                console.log('Error fetching dashboard page content | status code ', status);
            }
        });
        if (popupGuideCMS === undefined) {
            var show = sessionStorage.getItem('showPopupGuide');
            if (show === 'true' || show === null)
                getPageContent(ContentPageURL.POPUP_GUIDE).then(function (content) { return setPopupGuideCMS(content.payload); });
        }
        if (accessToken && refreshToken && policyIds && policySummaries) {
            if (!journeys || !latestJourney) {
                fetchDrivingData(policyIds[currentPolicyIndex], accessToken, refreshToken, fetchBonusMilesCallback, fetchMilesSummariesCallback);
            }
            fetchMapConsentPermission(policyIds[currentPolicyIndex], DISPLAY_TRIPS_NAME, accessToken, refreshToken, fetchTripsPermissionCallback);
            /* messageClient.fetchNotifications(policyIds[currentPolicyIndex], accessToken, refreshToken, notifications =>
               setNotifications(notifications),
             )*/
            if (!savedQuotes) {
                fetchSavedQuotes(accessToken, refreshToken, function (quotes) { return setSavedQuotes(quotes); });
            }
            if (quotePortalUrl === '') {
                fetchQuotePortalDomain(accessToken, refreshToken, function (url) { return setQuotePortalUrl(url); });
            }
        }
        if (policy) {
            setDaysToExpiration(moment(new Date(policy.expiration)).diff(moment(new Date()), 'days'));
        }
        var specificDate = moment('2024-08-01', 'YYYY-MM-DD');
        var isEffectiveDateBol = moment().isBefore(specificDate);
        if (isEffectiveDateBol === true && policy) {
            setNewPolicyButtonDisplay(true);
        }
        else if (policy) {
            setNewPolicyButtonDisplay(false);
        }
        if (refresh === true) {
            setRefreshPolicy();
            history.go(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPolicyIndex, policyIds, policy, renewalPolicy, policySummaries]);
    var renderSection = function (section) {
        var _a, _b;
        var tokens = getTokens();
        if ('remainingMilesContent' in section && policy) {
            var displayName = policy.vehicleInfo.displayName || '()';
            var carplate = displayName.substring(displayName.lastIndexOf('(') + 1, displayName.lastIndexOf(')'));
            if (!disableDueToLapse) {
                return (React.createElement(OverviewSection, { key: "overview", carplate: carplate, content: section, remainingMiles: remainingMiles, consumedMiles: consumedMiles, topupMilesButton: React.createElement(Button, { disabled: policy.status === 'Lapsed', buttonStyle: "secondary", onClick: function () {
                            history.push('/topupmiles');
                            TagManagerArgs('Button', '/dashboard', section.buttonText);
                        } }, section.buttonText) }));
            }
            else
                return React.createElement(React.Fragment, null);
        }
        if ('title' in section && 'text' in section && 'imageUrl' in section) {
            if (!disableDueToLapse) {
                return React.createElement(BannerImageSection, { key: "banner", content: section });
            }
            else
                return React.createElement(React.Fragment, null);
        }
        if ('summaryHeaders' in section && policy) {
            if (!disableDueToLapse) {
                return (React.createElement(MyJourneysSection, { key: "myjourney", hideCharts: true, mapDisplayAllowed: mapDisplayAllowed, tripsDisplayAllowed: tripsDisplayAllowed, singleDriver: policy.drivers.length === 1, cmsContent: section, latestJourney: latestJourney, previousJourneys: journeys, summaries: drivingSummaries, dailyMiles: dailyMiles, monthlyMiles: monthlyMiles, monthName: currentMonth, totalPages: totalPages, onAgreeMapConsent: function () {
                        if (tokens) {
                            sendMapConsentAgreement(policyIds[currentPolicyIndex], MAP_PERMISSION_NAME, MAP_PERMISSION_VALUE_ALLOWED, tokens.accessToken, tokens.refreshToken, function () { return setMapDisplayAllowed(true); });
                        }
                    }, onMonthlySummarySelected: onMonthlySummarySelected, onMonthlyJourneySelected: function (month) {
                        if (tokens) {
                            setMonthForJourney(month);
                            var accessToken_3 = tokens.accessToken, refreshToken_3 = tokens.refreshToken;
                            if (tripsDisplayAllowed) {
                                fetchTripsByPage(policyIds[currentPolicyIndex], accessToken_3, refreshToken_3, month, 1, fetchTripsCallback);
                            }
                            else {
                                fetchPolicyTrips(policyIds[currentPolicyIndex], 0, new Date(month).getFullYear(), new Date(month).getMonth() + 1, accessToken_3, refreshToken_3, fetchPolicyTripsCallback);
                            }
                        }
                    }, onPageSelect: function (page) {
                        if (tokens) {
                            var accessToken_4 = tokens.accessToken, refreshToken_4 = tokens.refreshToken;
                            if (tripsDisplayAllowed) {
                                fetchTripsByPage(policyIds[currentPolicyIndex], accessToken_4, refreshToken_4, monthForJourney, page, fetchTripsCallback);
                            }
                            else {
                                fetchPolicyTrips(policyIds[currentPolicyIndex], page - 1, new Date(monthForJourney).getFullYear(), new Date(monthForJourney).getMonth() + 1, accessToken_4, refreshToken_4, fetchPolicyTripsCallback);
                            }
                        }
                    } }));
            }
            else
                return React.createElement(React.Fragment, null);
        }
        if ('currentMonthMilesText' in section && bonusMilesData) {
            if (!disableDueToLapse) {
                return (React.createElement(BonusMilesSection, { key: "bonusmiles", hideChart: true, cmsContent: section, milesData: bonusMilesData, showDrivingData: function () { return history.push('/driving-data'); } }));
            }
            else
                return React.createElement(React.Fragment, null);
        }
        if ('documentName' in section && policySummaries) {
            var currentPolicySummary = policySummaries[currentPolicyIndex];
            var documentsCurrentPolicy = (_a = currentPolicySummary.periods.find(function (period) { return period.status === 'In Force' || period.status === 'Expired' || period.status === 'Lapsed'; })) === null || _a === void 0 ? void 0 : _a.documents;
            var documentsRenewal = (_b = currentPolicySummary.periods.find(function (period) {
                return period.status === 'Renewing' || period.status === 'Scheduled' || period.status === 'Accepted';
            })) === null || _b === void 0 ? void 0 : _b.documents;
            return documentsCurrentPolicy ? (React.createElement(DocumentsSection, { key: "documents", content: section, documents: documentsCurrentPolicy, renewalDocuments: documentsRenewal, downloadDocument: function (doc) { return __awaiter(void 0, void 0, void 0, function () {
                    var accessToken, refreshToken;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, authorizationCheck()];
                            case 1:
                                _a.sent();
                                accessToken = sessionStorage.getItem('access_token');
                                refreshToken = sessionStorage.getItem('refresh_token');
                                client.openDocumentForPolicy(doc.docUID.replace('==', ''), doc.sessionID, doc.name, doc.mimeType, doc.policyNumber, accessToken, refreshToken);
                                return [2 /*return*/];
                        }
                    });
                }); } })) : null;
        }
        if ('noQuoteText' in section && savedQuotes) {
            return (React.createElement(SavedQuoteSection, { key: "savedquote", content: section, savedQuotes: savedQuotes, onQuoteAction: function (action, postalCode, dateOfBirth, quoteNumber) {
                    return onQuoteAction(quotePortalUrl, action, postalCode, dateOfBirth, covered, quoteNumber);
                }, onGetNewQuote: function () { return createNewQuoteForAccount(accountNumber, quotePortalUrl, covered); }, newButtonDisplay: showNewPolicyButton }));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        notifications && notifications.length > 0 && React.createElement(NotificationsSection, { notifications: notifications }),
        renewalPolicy && renewalPolicy.status !== 'Accepted' && (daysToExpiration === 30 || daysToExpiration === 22) && (React.createElement(Reminder, { title: "Policy renewal", message: 'This policy is due for renewal on ' +
                getFriendlyDisplayDate(policy.expiration) +
                '. Your renewal details will appear here ' +
                (daysToExpiration - 21 === 1 ? "tomorrow" : "in " + (daysToExpiration - 21) + " days."), buttonText: "Complete renewal", level: NotificationLevel.WARNING, onButtonClick: function () { return history.push('mypolicy'); } })),
        renewalPolicy && renewalPolicy.status !== 'Accepted' && daysToExpiration < 21 && (React.createElement(Reminder, { title: "Policy renewal", message: 'Your policy is due for renewal on ' + getFriendlyDisplayDate(policy.expiration), buttonText: "Complete renewal", level: NotificationLevel.WARNING, onButtonClick: function () { return history.push('mypolicy'); } })),
        React.createElement(ShareComponent, { headerContent: headerContent }),
        popupGuideCMS && policy && (React.createElement(DashboardPopUpGuide, { popupGuideVisible: popupGuideVisible, setPopupGuideVisible: setPopupGuideVisible, popupGuideCMS: popupGuideCMS, name: (_b = policy.policyHolder.displayName) === null || _b === void 0 ? void 0 : _b.replace(policy.policyHolder.lastName, '') })),
        contentSections && contentSections.map(function (content) { return renderSection(content); })));
};
var mapStateToProps = function (state) { return ({
    currentPolicyIndex: state.currentPolicyIndex,
    policy: state.currentPolicy,
    renewalPolicy: state.renewalPolicy,
    policyIds: state.policyIds,
    covered: state.currentlyCovered,
    accountNumber: state.accountNumber,
    policySummaries: state.policySummaries,
    refresh: state.refresh,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setRefreshPolicy: function () { return dispatch(actions.setRefreshPolicy(false)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
