var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 20px;\n\n  ", "\n\n  ", "\n"], ["\n  height: 20px;\n\n  ",
    "\n\n  ",
    "\n"])), media.medium(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 30px;\n  "], ["\n    height: 30px;\n  "]))), media.large(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: 40px;\n  "], ["\n    height: 40px;\n  "]))));
var Space = function () { return React.createElement(Root, null); };
export default Space;
var templateObject_1, templateObject_2, templateObject_3;
