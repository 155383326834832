import { refreshToken, getDocumentById, getPolicies, getDocumentByIdFromPolicy } from './api';
import { SessionStorageKey } from 'types';
export var renewToken = function (tokenForRefresh, callback) {
    refreshToken(tokenForRefresh).then(function (tokenResult) {
        var status = tokenResult.status, payload = tokenResult.payload;
        if (status === 200 && payload) {
            var access_token = payload.access_token, refresh_token = payload.refresh_token;
            sessionStorage.setItem(SessionStorageKey.ACCESS_TOKEN, access_token);
            sessionStorage.setItem(SessionStorageKey.REFRESH_TOKEN, refresh_token);
            callback(access_token, refresh_token);
        }
        else {
            console.log('Error refreshing token | status code', status);
        }
    });
};
export var fetchPolicies = function (token, refreshToken, callback) {
    getPolicies(token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            callback(payload);
        }
        else if (status === 401) {
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchPolicies(newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching policies | status code " + status);
        }
    });
};
var openDocumentAttempts = 0;
export var openDocument = function (documentId, token, refreshToken) {
    getDocumentById(documentId, token, 'blob').then(function (result) {
        var status = result.status, payload = result.payload, contentType = result.contentType;
        if (status === 200 && payload) {
            openDocumentAttempts = 0;
            var url = window.URL.createObjectURL(new Blob([payload], { type: contentType }));
            var link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
        }
        else if (status === 401 && openDocumentAttempts < 5) {
            openDocumentAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return openDocument(documentId, newToken, newRefreshToken);
            });
        }
        else {
            console.log("Error fetching document of " + documentId + " | status code " + status);
        }
    });
};
var openDocumentForPolicyAttempts = 0;
export var openDocumentForPolicy = function (id, sessionId, name, mimeType, policyNumber, token, refreshToken) {
    getDocumentByIdFromPolicy(id, sessionId, name, mimeType, policyNumber, token).then(function (result) {
        var status = result.status, payload = result.payload;
        if (status === 200 && payload) {
            openDocumentForPolicyAttempts = 0;
            var url = window.URL.createObjectURL(new Blob([payload], { type: mimeType }));
            var link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
        }
        else if (status === 401 && openDocumentForPolicyAttempts < 5) {
            openDocumentForPolicyAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return openDocumentForPolicy(id, sessionId, name, mimeType, policyNumber, token, newRefreshToken);
            });
        }
        else {
            console.log("Error fetching document of " + id + " | status code " + status);
        }
    });
};
var fetchDocumentByIdAttempts = 0;
export var fetchDocumentById = function (documentId, token, refreshToken, callback) {
    getDocumentById(documentId, token, 'arraybuffer').then(function (result) {
        var status = result.status, payload = result.payload, contentType = result.contentType;
        if (status === 200 && payload) {
            fetchDocumentByIdAttempts = 0;
            if (contentType === 'application/pdf') {
                callback(payload, 'PDF');
            }
            if (contentType === 'text/html') {
                callback(payload, 'HTML');
            }
        }
        else if (status === 401 && fetchDocumentByIdAttempts < 5) {
            fetchDocumentByIdAttempts++;
            renewToken(refreshToken, function (newToken, newRefreshToken) {
                return fetchDocumentById(documentId, newToken, newRefreshToken, callback);
            });
        }
        else {
            console.log("Error fetching document of " + documentId + " | status code " + status);
        }
    });
};
