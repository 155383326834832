var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import ListRow from 'components/molecules/ListRow';
import SubSectionHeader from 'components/molecules/SubSectionHeader';
var DropdownWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 50px;\n  align-items: center;\n  display: flex;\n  box-sizing: border-box;\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"], ["\n  height: 50px;\n  align-items: center;\n  display: flex;\n  box-sizing: border-box;\n  background-color: rgba(137, 137, 137, 0.04);\n  border-top: 1px solid rgba(137, 137, 137, 0.2);\n"])));
export var QuoteDetailsList = function (_a) {
    var rows = _a.rows, title = _a.title, display = _a.display;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownWrapper, null,
            React.createElement(SubSectionHeader, { title: title, onClick: function () { return setOpen(!open); }, open: open })),
        open && (React.createElement(React.Fragment, null, rows.map(function (row) { return (React.createElement(ListRow, { key: row.label, label: row.label, value: display[row.value], style: { borderBottom: '1px solid rgba(137, 137, 137, 0.2)' } })); })))));
};
var templateObject_1;
