import find from 'lodash/find';
import React from 'react';
import { PaddingVertical } from 'components/organisms/Layout';
import { Group, getFieldType } from 'components/organisms/Form';
var GroupsControlled = function (_a) {
    var ids = _a.ids, formGroups = _a.formGroups, _b = _a.hiddenField, hiddenField = _b === void 0 ? {} : _b, _c = _a.showHideSections, showHideSections = _c === void 0 ? [] : _c, register = _a.register, control = _a.control, errors = _a.errors;
    var groups = [];
    if (ids) {
        groups = ids.map(function (id) {
            return find(formGroups, function (obj) {
                return obj.formGroupId === id;
            });
        });
    }
    else
        groups = formGroups;
    return (React.createElement(PaddingVertical, null, groups.map(function (group) { return (React.createElement(React.Fragment, { key: group.formGroupId }, !showHideSections[group.formGroupId] && (React.createElement(Group, { title: group.title }, group.fields.map(function (field) {
        return (React.createElement(React.Fragment, { key: field.label }, !hiddenField[field.value] &&
            getFieldType(field, register, control, errors[field.value] ? true : false)));
    }))))); })));
};
export default GroupsControlled;
