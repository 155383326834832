var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import RemoveIcon from 'assets/close.svg';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
import { SessionStorageKey } from 'types';
import { getDetailedPolicy, mtaTokenRefresh } from 'services';
import fallBackCodes from 'fallbacks/convictionCodes.json';
import { getSingleFormGroupContent, getSingleFieldContent, Group, FormSelect, FormDatePicker, } from 'components/organisms/Form';
import Button from 'components/atoms/Button';
import { TagManagerArgs } from 'analytics';
var ButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var ConvictionsTable = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: 1/-1;\n"], ["\n  grid-column: 1/-1;\n"])));
var ConvictionsRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: grid;\n  padding: 10px 0;\n  grid-template-columns: repeat(5, minmax(0, 1fr));\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: 300;\n"], ["\n  align-items: center;\n  display: grid;\n  padding: 10px 0;\n  grid-template-columns: repeat(5, minmax(0, 1fr));\n  border-bottom: 1px solid rgba(137, 137, 137, 0.2);\n  font-weight: 300;\n"])));
var ConvictionsHeaders = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  padding: 15px 0;\n  grid-template-columns: repeat(5, minmax(0, 1fr));\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-weight: 400;\n"], ["\n  display: grid;\n  padding: 15px 0;\n  grid-template-columns: repeat(5, minmax(0, 1fr));\n  border-bottom: 1px solid rgba(137, 137, 137, 0.5);\n  font-weight: 400;\n"])));
var IconCell = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  cursor: pointer;\n\n  svg {\n    fill: red;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  cursor: pointer;\n\n  svg {\n    fill: red;\n  }\n"])));
var initialConvictionState = {
    codeID: undefined,
    convictionDate: undefined,
    convictionPoints: undefined,
    disqualificationMonths: undefined,
};
var AddDriverConvictions = function (_a) {
    var formGroups = _a.formGroups, currentPolicy = _a.currentPolicy, onChange = _a.onChange, amendDetails = _a.amendDetails;
    var history = useHistory();
    var _b = useState(undefined), convictionCodesList = _b[0], setConvictionCodesList = _b[1];
    var _c = useState(initialConvictionState), conviction = _c[0], setConviction = _c[1];
    var _d = useState(amendDetails ? amendDetails.data : []), convictionsList = _d[0], setConvictionsList = _d[1];
    var _e = useState(amendDetails ? amendDetails.display : []), convictionsListDisplay = _e[0], setConvictionsListDisplay = _e[1];
    var convictionsContent = getSingleFormGroupContent('convictionsSection', formGroups);
    var convictionCodes = getSingleFieldContent('convictionCodes', convictionsContent.fields);
    var convictionCodesOptions = convictionCodesList !== undefined
        ? convictionCodesList.map(function (item) {
            return { label: item, value: item };
        })
        : [];
    var convictionDate = getSingleFieldContent('convictionDate', convictionsContent.fields);
    var convictionPoints = getSingleFieldContent('convictionPoints', convictionsContent.fields);
    var convictionPointsOptions = Array.from(Array(12), function (_, x) {
        return { label: x, value: x };
    });
    var disqualificationMonths = getSingleFieldContent('disqualificationMonths', convictionsContent.fields);
    var disqualificationMonthsOptions = getGwDropdownValues(disqualificationMonths.gwTypelist);
    var addConviction = function () {
        var gwConviction = __assign(__assign({}, conviction), { convictionDate: {
                day: moment(conviction.convictionDate).date(),
                month: moment(conviction.convictionDate).month(),
                year: moment(conviction.convictionDate).year(),
            } });
        // get months label for the display
        var displayMonths = find(disqualificationMonthsOptions, function (obj) {
            return obj.value === conviction.disqualificationMonths;
        });
        // for displaying on the FE
        var displayConviction = __assign(__assign({}, conviction), { convictionDate: moment(gwConviction.convictionDate).format('DD / MM / yyyy'), disqualificationMonths: displayMonths.label });
        setConvictionsList(__spreadArrays(convictionsList, [gwConviction]));
        setConvictionsListDisplay(__spreadArrays(convictionsListDisplay, [displayConviction]));
    };
    var removeConviction = function (index) {
        var removedDisplay = convictionsListDisplay.filter(function (value, listIndex) {
            if (listIndex !== index)
                return value;
        });
        var removed = convictionsList.filter(function (value, listIndex) {
            if (listIndex !== index)
                return value;
        });
        setConvictionsListDisplay(removedDisplay);
        setConvictionsList(removed);
    };
    useEffect(function () {
        if (currentPolicy !== undefined) {
            var getDetails_1 = function () { return __awaiter(void 0, void 0, void 0, function () {
                var policyNumber, date, isodate, token, result, refresh;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            policyNumber = currentPolicy.policyNumber.replace('ITB/', '');
                            date = new Date();
                            date.setHours(date.getHours() + 1);
                            isodate = date.toISOString();
                            token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                            return [4 /*yield*/, getDetailedPolicy(policyNumber, isodate, token)];
                        case 1:
                            result = _a.sent();
                            if (result.status === 200) {
                                setConvictionCodesList(result.data.convictionCodes);
                            }
                            else
                                setConvictionCodesList(fallBackCodes);
                            if ((result.status !== undefined && result.status === 500) ||
                                (result.data.status !== undefined && result.data.status === 500)) {
                                history.push('/error');
                            }
                            if (!(result.data.status === 401)) return [3 /*break*/, 3];
                            return [4 /*yield*/, mtaTokenRefresh()];
                        case 2:
                            refresh = _a.sent();
                            if (refresh === 200)
                                getDetails_1();
                            else {
                                setConvictionCodesList(fallBackCodes);
                                // history.push('/login')
                            }
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            getDetails_1();
        }
        onChange({
            display: convictionsListDisplay,
            data: convictionsList,
        });
    }, [convictionsListDisplay, convictionsList]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Group, { title: convictionsContent.title },
            React.createElement(FormSelect, { label: convictionCodes.label, options: convictionCodesOptions, toolTip: convictionCodes.toolTip, placeholder: convictionCodes.placeholder, name: convictionCodes.value, spanTwoCols: convictionCodes.spanTwoColumns, additionalToTooltip: convictionCodes.additionalToTooltip, onChange: function (value) { return setConviction(__assign(__assign({}, conviction), { codeID: value })); } }),
            React.createElement(FormDatePicker, { label: convictionDate.label, toolTip: convictionDate.toolTip, name: convictionDate.value, additionalToTooltip: convictionDate.additionalToTooltip, onChange: function (value) { return setConviction(__assign(__assign({}, conviction), { convictionDate: value.toString() })); } }),
            React.createElement(FormSelect, { label: convictionPoints.label, options: convictionPointsOptions, toolTip: convictionPoints.toolTip, placeholder: convictionPoints.placeholder, name: convictionPoints.value, spanTwoCols: convictionPoints.spanTwoColumns, additionalToTooltip: convictionPoints.additionalToTooltip, onChange: function (value) { return setConviction(__assign(__assign({}, conviction), { convictionPoints: value })); } }),
            React.createElement(FormSelect, { label: disqualificationMonths.label, options: disqualificationMonthsOptions, toolTip: disqualificationMonths.toolTip, placeholder: disqualificationMonths.placeholder, name: disqualificationMonths.value, spanTwoCols: disqualificationMonths.spanTwoColumns, additionalToTooltip: disqualificationMonths.additionalToTooltip, onChange: function (value) { return setConviction(__assign(__assign({}, conviction), { disqualificationMonths: value })); } }),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { disabled: Object.values(conviction).includes(undefined), onClick: function () {
                        addConviction();
                        TagManagerArgs('Button', window.location.pathname + " - Add a conviction", 'Add');
                    } }, "Add")),
            convictionsListDisplay.length > 0 && (React.createElement(ConvictionsTable, null,
                React.createElement(ConvictionsHeaders, null,
                    React.createElement("div", null, "Conviction code"),
                    React.createElement("div", null, "Conviction date"),
                    React.createElement("div", null, "Number of points"),
                    React.createElement("div", null, "Months disqualified")),
                convictionsListDisplay.map(function (item, index) { return (React.createElement(ConvictionsRow, { key: item + " " + index },
                    Object.values(item).map(function (cell, cellIndex) { return (React.createElement("div", { key: cell + " " + cellIndex }, cell)); }),
                    React.createElement(IconCell, { onClick: function () {
                            removeConviction(index);
                            TagManagerArgs('Button', window.location.pathname + " - Remove a conviction", 'Remove');
                        } },
                        React.createElement(RemoveIcon, null)))); }))))));
};
export default AddDriverConvictions;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
