var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { get } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowDownIcon from 'assets/arrow_down.svg';
import ArrowUpIcon from 'assets/arrow_up.svg';
import { color, fontSize, fontWeight } from 'styles';
import JourneyDetailView from './JourneyDetailView';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var HeaderWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex: 1;\n  border-bottom: 2px solid ", ";\n  justify-content: center;\n  padding-bottom: 18px;\n  text-align: center;\n"], ["\n  display: flex;\n  align-items: center;\n  flex: 1;\n  border-bottom: 2px solid ", ";\n  justify-content: center;\n  padding-bottom: 18px;\n  text-align: center;\n"])), color.black);
var LabelInfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  opacity: 0.5;\n"], ["\n  font-size: ", ";\n  opacity: 0.5;\n"])), fontSize.fs12);
var Rows = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var JourneyWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var Row = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: ", ";\n"])), function (props) { return (props.expandable ? 'pointer' : 'inherit'); });
var IconWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-bottom: ", ";\n  height: 100%;\n  padding: 12.5px 0;\n"], ["\n  border-bottom: ", ";\n  height: 100%;\n  padding: 12.5px 0;\n"])), function (props) { return (props.expanded ? 'none' : '1px solid rgba(137, 137, 137, 0.5)'); });
var CellWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  flex: 1;\n  background-color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  border-bottom: ", ";\n  padding: 17px 0;\n  text-align: center;\n"], ["\n  flex: 1;\n  background-color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  border-bottom: ", ";\n  padding: 17px 0;\n  text-align: center;\n"])), function (props) { return (props.isTotal ? 'rgba(137, 137, 137, 0.04)' : 'transparent'); }, fontSize.fs14, function (props) { return (props.isTotal ? fontWeight.bold : fontWeight.regular); }, function (props) { return (props.expanded ? 'none' : '1px solid rgba(137, 137, 137, 0.5)'); });
var Table = function (_a) {
    var headers = _a.headers, journeys = _a.journeys, latestJourney = _a.latestJourney, onShowMap = _a.onShowMap, showMapButtonText = _a.showMapButtonText, summaries = _a.summaries, tab = _a.tab;
    var keys = headers.map(function (header) { return header.value; });
    var _b = useState(new Set()), expandedRows = _b[0], setExpandedRows = _b[1];
    var emptyJourney = {
        date: '-',
        duration: 0,
        start: '00:00',
        end: '00:00',
        miles: 0,
    };
    var emptySummary = {
        month: '-',
        distance: '0.0',
        totalTime: '00:00',
        motorwayMiles: '0.0',
    };
    var onRowClick = function (id) {
        if (expandedRows.has(id)) {
            expandedRows.delete(id);
        }
        else {
            expandedRows.add(id);
        }
        setExpandedRows(new Set(expandedRows));
    };
    var renderJourneys = function (journeys) {
        return journeys.map(function (journey) {
            var originName = journey.originName, destinationName = journey.destinationName;
            var expanded = expandedRows.has(journey.id);
            if (!originName || !destinationName) {
                expanded = false;
            }
            return (React.createElement(JourneyWrapper, { key: journey.id },
                React.createElement(Row, { expandable: !!originName, onClick: function () { return onRowClick(journey.id); } },
                    keys.map(function (key) { return (React.createElement(CellWrapper, { expanded: expanded, key: key }, get(journey, key, ''))); }),
                    originName && destinationName && (React.createElement(IconWrapper, { expanded: expanded }, expanded ? React.createElement(ArrowUpIcon, null) : React.createElement(ArrowDownIcon, null)))),
                expanded && journey.originName && journey.destinationName && (React.createElement(JourneyDetailView, { buttonText: showMapButtonText || '', start: journey.originName, destination: journey.destinationName, onShowMap: function () { return onShowMap && onShowMap(journey.id); } }))));
        });
    };
    var renderLatestJourney = function (journey) { return (React.createElement(JourneyWrapper, { key: journey.id },
        React.createElement(Row, { expandable: false }, keys.map(function (key) { return (React.createElement(CellWrapper, { expanded: true, key: key }, get(journey, key, ''))); })),
        journey.originName && journey.destinationName && (React.createElement(JourneyDetailView, { buttonText: showMapButtonText || '', start: journey.originName, destination: journey.destinationName, onShowMap: function () { return onShowMap && onShowMap(journey.id); } })))); };
    var renderEmptyMiles = function (data) { return (React.createElement(React.Fragment, null,
        React.createElement(Row, { expandable: false }, keys.map(function (key) { return (React.createElement(CellWrapper, { expanded: true, key: key }, get(data, key, ''))); })))); };
    var renderSummaries = function (summaries) {
        return summaries.map(function (summary) { return (React.createElement(Row, { expandable: false, key: summary.id }, keys.map(function (key) { return (React.createElement(CellWrapper, { isTotal: summary.month === 'Total', key: key }, get(summary, key, ''))); }))); });
    };
    var renderTable = function (tab) {
        if (tab === 'latest') {
            return latestJourney ? renderLatestJourney(latestJourney) : renderEmptyMiles(emptyJourney);
        }
        if (tab === 'previous') {
            return journeys && journeys.length > 0 ? renderJourneys(journeys) : renderEmptyMiles(emptyJourney);
        }
        if (tab === 'summary') {
            return summaries ? renderSummaries(summaries) : renderEmptyMiles(emptySummary);
        }
        return null;
    };
    return (React.createElement(Root, null,
        React.createElement(Header, null, headers.map(function (header) { return (React.createElement(HeaderWrapper, { key: header.value },
            header.label,
            header.info && React.createElement(LabelInfoWrapper, null,
                "\u00A0",
                header.info))); })),
        React.createElement(Rows, null, renderTable(tab))));
};
export default Table;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
