var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import React from 'react';
import styled from 'styled-components';
import TimeIcon from '../../../assets/time.svg';
import { color, fontFamily } from '../../../styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label,\n  .MuiInputBase-input {\n    color: ", ";\n    font-family: ", ";\n    margin-left: 10px;\n  }\n\n  .MuiIconButton-label {\n    svg {\n      fill: #30a369;\n    }\n  }\n\n  .MuiInputBase-root.Mui-disabled {\n    opacity: 0.38;\n  }\n"], ["\n  label,\n  .MuiInputBase-input {\n    color: ", ";\n    font-family: ", ";\n    margin-left: 10px;\n  }\n\n  .MuiIconButton-label {\n    svg {\n      fill: #30a369;\n    }\n  }\n\n  .MuiInputBase-root.Mui-disabled {\n    opacity: 0.38;\n  }\n"])), color.black, fontFamily.base);
var MtaTimePicker = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, initialTime = _a.initialTime, _c = _a.label, label = _c === void 0 ? 'Please select' : _c, onSelect = _a.onSelect, mtaEffectiveDate = _a.mtaEffectiveDate, timeErrorMessage = _a.timeErrorMessage;
    var _d = React.useState(initialTime || null), selectedDate = _d[0], setSelectedDate = _d[1];
    var handleTimeChange = function (date) {
        setSelectedDate(date);
        onSelect(date);
    };
    return (React.createElement(Root, null,
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(KeyboardTimePicker, { style: { width: '240px' }, ampm: false, error: timeErrorMessage !== '', helperText: timeErrorMessage, minutesStep: 15, disabled: disabled, placeholder: "hh:mm", variant: "inline", label: label, value: mtaEffectiveDate ? mtaEffectiveDate : selectedDate, onChange: handleTimeChange, InputLabelProps: {
                    shrink: true,
                }, keyboardIcon: React.createElement(TimeIcon, null) }))));
};
export default MtaTimePicker;
var templateObject_1;
