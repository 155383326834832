var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from 'lodash/isEqual';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from './FormLabel';
import { color, fontFamily } from 'styles';
import { Controller } from 'react-hook-form';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .MuiFormControl-root {\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    padding: 0 17px;\n    border: solid 1px ", ";\n    border-radius: 2px;\n    box-sizing: border-box;\n    background-color: #ffffff;\n    transition: 0.3s;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-family: ", ";\n    font-size: 14px;\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiInput-input {\n    padding: 0;\n    color: ", ";\n    font-family: ", ";\n\n    :focus {\n      background-color: white;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  .MuiFormControl-root {\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    padding: 0 17px;\n    border: solid 1px ", ";\n    border-radius: 2px;\n    box-sizing: border-box;\n    background-color: #ffffff;\n    transition: 0.3s;\n  }\n\n  .MuiInput-root {\n    color: ", ";\n    font-family: ", ";\n    font-size: 14px;\n  }\n\n  .MuiInput-underline:before {\n    display: none;\n  }\n\n  .MuiInput-underline:after {\n    display: none;\n  }\n\n  .MuiInput-input {\n    padding: 0;\n    color: ", ";\n    font-family: ", ";\n\n    :focus {\n      background-color: white;\n    }\n  }\n"])), function (props) { return (props.error ? 'red' : 'rgba(137, 137, 137, 0.3)'); }, color.black, fontFamily.base, color.black, fontFamily.base);
var FormAutocomplete = function (_a) {
    var label = _a.label, toolTip = _a.toolTip, control = _a.control, name = _a.name, options = _a.options, error = _a.error, _b = _a.isRequired, isRequired = _b === void 0 ? false : _b;
    return (React.createElement(Root, { error: error },
        React.createElement(FormLabel, { label: label, toolTip: toolTip, isRequired: isRequired }),
        React.createElement(Controller, { render: function (props) { return (React.createElement(Autocomplete, __assign({}, props, { options: options, getOptionLabel: function (option) { return option.label; }, getOptionSelected: function (option, value) { return isEqual(option, value); }, renderInput: function (params) { return React.createElement(TextField, __assign({}, params)); }, onChange: function (_, data) { return props.onChange(data); } }))); }, name: name, control: control, defaultValue: null, rules: { required: isRequired } })));
};
export default FormAutocomplete;
var templateObject_1;
