var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import SectionButtonWrapper from 'components/molecules/SectionButtonWrapper';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { FormInput, Group } from 'components/organisms//Form';
import { media } from 'styles';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 20px;\n\n  ", "\n"], ["\n  padding-bottom: 20px;\n\n  ",
    "\n"])), media.large(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding-bottom: 30px;\n  "], ["\n    padding-bottom: 30px;\n  "]))));
var OvernightParkingSection = function (_a) {
    var content = _a.content, data = _a.data, onChangeAddress = _a.onChangeAddress, _b = _a.policyIsLapsed, policyIsLapsed = _b === void 0 ? false : _b;
    var _c = useState(true), open = _c[0], setOpen = _c[1];
    var title = content.title, labels = content.labels, buttonText = content.buttonText;
    var textFieldValues = labels.map(function (label) { return ({
        label: label.label,
        value: data.filter(function (item) { return item.key === label.value; })[0].value,
    }); });
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(SectionHeader, { expandable: true, open: open, title: title, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(ContentWrapper, null,
                    React.createElement(Group, { noBorder: true }, textFieldValues.map(function (item) { return (React.createElement(FormInput, { key: item.label, disabled: true, label: item.label, value: item.value, name: item.value })); })),
                    React.createElement(SectionButtonWrapper, null,
                        React.createElement(Button, { disabled: policyIsLapsed, buttonStyle: "secondary", onClick: function () {
                                onChangeAddress('/overnight-parking');
                                TagManagerArgs('Button', '/mypolicy - My overnight parking address', buttonText);
                            } }, buttonText))))))));
};
export default OvernightParkingSection;
var templateObject_1, templateObject_2, templateObject_3;
