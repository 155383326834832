import axios from 'axios';
import mockSavedQuotes from './mock/savedQuotes.json';
import mockAccountResponse from './mock/createForAccount.json';
import { handlePromise, POLICY_BASE_URL } from './api';
var SAVED_QUOTE_URL = POLICY_BASE_URL + "/profilesettings/savedQuotes";
var GET_QUOTE_DOMAIN_URL = POLICY_BASE_URL + "/policies/getQNBDomain";
var CREATE_FOR_ACCOUNT_URL = POLICY_BASE_URL + "/quotes/createForAccount";
export var getSavedQuotes = function (token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: mockSavedQuotes,
            });
        });
    }
    return handlePromise(axios.get("" + SAVED_QUOTE_URL, { headers: { 'x-authorization': token } }));
};
export var getQuotePortalDomain = function (token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: 'https://sfedevquote.insurethebox.com',
            });
        });
    }
    return handlePromise(axios.get(GET_QUOTE_DOMAIN_URL + "?brand=ITB", { headers: { 'x-authorization': token } }));
};
export var createForAccount = function (data, token) {
    if (process.env.mock) {
        return new Promise(function (resolve) {
            return resolve({
                status: 200,
                payload: mockAccountResponse,
            });
        });
    }
    return handlePromise(axios({
        url: "" + CREATE_FOR_ACCOUNT_URL,
        method: 'POST',
        data: JSON.stringify(data),
        headers: { 'x-authorization': token, 'Content-Type': 'application/json' },
    }));
};
