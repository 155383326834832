var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import Title from 'components/atoms/Title';
import Switch from 'components/molecules/Switch';
import { color, fontFamily, fontSize, fontWeight } from 'styles';
import TooltipContent from 'components/organisms/BonusMilesSection/TooltipContent';
import BarShape from 'components/organisms/BonusMilesSection/BarShape';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 1024px;\n\n  .axis-label {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n"], ["\n  max-width: 1024px;\n\n  .axis-label {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n"])), fontSize.fs12, fontWeight.medium);
var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 30px;\n  padding: 0 80px;\n"], ["\n  margin-bottom: 30px;\n  padding: 0 80px;\n"])));
var SwitchWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var LegendTextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  color: ", ";\n  padding: 0 20px;\n"], ["\n  font-family: ", ";\n  color: ", ";\n  padding: 0 20px;\n"])), fontFamily.condensed, function (props) { return (props.selected ? color.black : color.greyMedium); });
var gridsPositions = [58, 180, 302, 424];
var DailyMiles = function (_a) {
    var data = _a.data, cmsContent = _a.cmsContent, monthName = _a.monthName;
    var title = cmsContent.title, xAxisLabel = cmsContent.xAxisLabel, yAxisLabel = cmsContent.yAxisLabel, legendCumulative = cmsContent.legendCumulative, legendMiles = cmsContent.legendMiles;
    var _b = useState(false), showTooltip = _b[0], setTooltipShow = _b[1];
    var _c = useState(false), showTotalMiles = _c[0], setTotalMilesShow = _c[1];
    var _d = useState(''), barHovered = _d[0], setHoveredBar = _d[1];
    var getDailyMilesTicks = function () {
        var maxMiles = data.reduce(function (max, dailyMiles) { return (dailyMiles.miles > max.miles ? dailyMiles : max); });
        var ticks = [25, 50, 75, 100];
        if (maxMiles.miles > 100 && maxMiles.miles <= 200) {
            ticks = [50, 100, 150, 200];
        }
        if (maxMiles.miles > 200 && maxMiles.miles <= 400) {
            ticks = [100, 200, 300, 400];
        }
        if (maxMiles.miles > 400 && maxMiles.miles <= 600) {
            ticks = [150, 300, 450, 600];
        }
        if (maxMiles.miles > 600 && maxMiles.miles <= 1000) {
            ticks = [250, 500, 750, 1000];
        }
        if (maxMiles.miles > 1000 && maxMiles.miles <= 1600) {
            ticks = [400, 800, 1200, 1600];
        }
        if (maxMiles.miles > 1600 && maxMiles.miles <= 2000) {
            ticks = [500, 1000, 1500, 2000];
        }
        if (maxMiles.miles > 2000 && maxMiles.miles <= 4000) {
            ticks = [1000, 2000, 3000, 4000];
        }
        if (maxMiles.miles > 4000 && maxMiles.miles <= 6000) {
            ticks = [1500, 3000, 4500, 6000];
        }
        if (maxMiles.miles > 6000 && maxMiles.miles <= 8000) {
            ticks = [2000, 4000, 6000, 8000];
        }
        return ticks;
    };
    var getCumulativeMilesMilesTicks = function () {
        var maxMiles = data[data.length - 1];
        var ticks = [250, 500, 750, 1000];
        if (maxMiles.cumulative <= 100) {
            ticks = [25, 50, 75, 100];
        }
        if (maxMiles.cumulative > 100 && maxMiles.cumulative <= 200) {
            ticks = [50, 100, 150, 200];
        }
        if (maxMiles.cumulative > 200 && maxMiles.cumulative <= 400) {
            ticks = [100, 200, 300, 400];
        }
        if (maxMiles.cumulative > 400 && maxMiles.cumulative <= 600) {
            ticks = [150, 300, 450, 600];
        }
        if (maxMiles.cumulative > 600 && maxMiles.cumulative <= 800) {
            ticks = [200, 400, 600, 800];
        }
        if (maxMiles.cumulative > 800 && maxMiles.cumulative <= 1000) {
            ticks = [250, 500, 750, 1000];
        }
        if (maxMiles.cumulative > 1000 && maxMiles.cumulative <= 2000) {
            ticks = [500, 1000, 1500, 2000];
        }
        if (maxMiles.cumulative > 2000 && maxMiles.cumulative <= 4000) {
            ticks = [1000, 2000, 3000, 4000];
        }
        if (maxMiles.cumulative > 4000 && maxMiles.cumulative <= 6000) {
            ticks = [1500, 3000, 4500, 6000];
        }
        if (maxMiles.cumulative > 6000 && maxMiles.cumulative <= 8000) {
            ticks = [2000, 4000, 6000, 8000];
        }
        if (maxMiles.cumulative > 8000 && maxMiles.cumulative <= 10000) {
            ticks = [2500, 5000, 7500, 10000];
        }
        return ticks;
    };
    var onSwitchChange = function () {
        setTotalMilesShow(!showTotalMiles);
    };
    return (React.createElement(Root, null,
        React.createElement(TitleWrapper, null,
            React.createElement(Title, null, title.replace('${month}', monthName))),
        React.createElement(ComposedChart, { barSize: 60, width: 980, height: 600, data: data, margin: { top: 25, right: 30, left: 20, bottom: 25 } },
            React.createElement(Legend, { verticalAlign: "top", content: function () { return (React.createElement(SwitchWrapper, null,
                    React.createElement(LegendTextWrapper, { selected: showTotalMiles }, legendCumulative),
                    React.createElement(Switch, { defaultValue: true, onChange: onSwitchChange }),
                    React.createElement(LegendTextWrapper, { selected: !showTotalMiles }, legendMiles))); } }),
            React.createElement(CartesianGrid, { vertical: false, horizontal: true, stroke: "rgba(137, 137, 137, 0.1)", horizontalPoints: gridsPositions }),
            React.createElement(YAxis, { dataKey: "miles", type: "number", tick: { fontSize: '14px', fill: 'rgba(32, 32, 32, 1)' }, tickLine: false, tickCount: 4, domain: [0, 100], ticks: showTotalMiles ? getCumulativeMilesMilesTicks() : getDailyMilesTicks(), axisLine: { stroke: 'rgba(137, 137, 137)' }, padding: { top: 10, bottom: 0 }, label: {
                    value: yAxisLabel,
                    position: 'insideTop',
                    offset: -20,
                    className: 'axis-label',
                } }),
            React.createElement(XAxis, { dataKey: "day", type: "category", tick: { fontSize: '14px', fill: 'rgba(32, 32, 32, 1)' }, tickLine: false, axisLine: { stroke: 'rgba(137, 137, 137)' }, padding: { left: 10, right: 10 }, label: { value: xAxisLabel, position: 'insideBottom', offset: -20, className: 'axis-label' } }),
            showTooltip && (React.createElement(Tooltip, { cursor: false, content: function (props) { return React.createElement(TooltipContent, __assign({}, props)); }, isAnimationActive: false })),
            showTotalMiles ? (React.createElement(Line, { name: legendCumulative, dot: false, strokeWidth: 4, type: "monotone", dataKey: "cumulative", stroke: "#e56d6d" })) : (React.createElement(Bar, { name: legendMiles, dataKey: "miles", onMouseOver: function (target) {
                    setTooltipShow(true);
                    setHoveredBar(target.day);
                }, onMouseLeave: function () {
                    setTooltipShow(false);
                    setHoveredBar('');
                }, onMouseEnter: function (target) {
                    setTooltipShow(true);
                    setHoveredBar(target.day);
                }, shape: function (props) { return (React.createElement(BarShape, { rectangle: true, fill: props.day === barHovered ? '#52be80' : '#268657', x: props.x || 0, y: props.y || 0, width: props.width || 0, height: props.height || 0, radius: 15 })); } })))));
};
export default DailyMiles;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
