var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { getSingleButtonContent } from 'components/organisms/Form';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin: 0 10px;\n  }\n"])));
var MtaPageButtons = function (_a) {
    var formButtons = _a.formButtons, submitType = _a.submitType, showMtaButtons = _a.showMtaButtons, onCancel = _a.onCancel, showContinue = _a.showContinue;
    var btnFurtherChanges = getSingleButtonContent('btnFurtherChanges', formButtons);
    var btnContinue = getSingleButtonContent('btnContinue', formButtons);
    var btnCancel = getSingleButtonContent('btnCancel', formButtons);
    return (React.createElement(Root, null,
        React.createElement(Button, { type: "button", buttonStyle: btnCancel.style, onClick: function () {
                onCancel();
                TagManagerArgs('Button', window.location.pathname + " - Mta Page Buttons", btnCancel.text);
            } }, btnCancel.text),
        showMtaButtons && (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "submit", onClick: function () {
                    submitType('otherChanges');
                    TagManagerArgs('Button', window.location.pathname + " - Mta Page Buttons", btnFurtherChanges.text);
                }, buttonStyle: btnFurtherChanges.style }, btnFurtherChanges.text),
            showContinue && (React.createElement(Button, { buttonStyle: btnContinue.style, type: "submit", onClick: function () {
                    submitType('continue');
                    TagManagerArgs('Button', window.location.pathname + " - Mta Page Buttons", btnContinue.text);
                } }, btnContinue.text))))));
};
export default MtaPageButtons;
var templateObject_1;
