var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { fontSize, fontWeight } from 'styles';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #424242;\n  border-radius: 15px;\n  color: #ffffff;\n  margin: 0;\n  padding: 10px;\n  white-space: nowrap;\n"], ["\n  background-color: #424242;\n  border-radius: 15px;\n  color: #ffffff;\n  margin: 0;\n  padding: 10px;\n  white-space: nowrap;\n"])));
var PayloadWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n"], ["\n  margin: 0;\n  padding: 0;\n"])));
var LabelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  font-size: ", ";\n  letter-spacing: 0.5px;\n"], ["\n  display: flex;\n  font-size: ", ";\n  letter-spacing: 0.5px;\n"])), fontSize.fs12);
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var ValueWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight.bold);
var TooltipContent = function (_a) {
    var payload = _a.payload;
    return (React.createElement(Root, null, payload && payload.length > 0 && (React.createElement(PayloadWrapper, null, payload.map(function (entry) {
        var type = entry.type, name = entry.name, value = entry.value;
        return type === 'none' ? null : (React.createElement(LabelWrapper, { key: name },
            React.createElement(TextWrapper, null, "Miles:\u00A0"),
            React.createElement(ValueWrapper, null, value)));
    })))));
};
export default TooltipContent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
